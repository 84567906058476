import React, { PropsWithChildren } from 'react'
import ResponsiveText from './ResponsiveText'
import Text, { Props as TextProps } from './Text'

export interface Props extends Omit<TextProps, 'family'> {
  notResponsive?: boolean
}

const PrimaryText: React.FC<PropsWithChildren<Props>> = ({
  notResponsive,
  ...props
}) => {
  if (notResponsive) {
    return <Text {...props} family="primary" />
  }
  return <ResponsiveText {...props} family="primary" />
}

export default PrimaryText
