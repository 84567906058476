import React, { useCallback, useEffect, useState } from 'react'
import logoSource from '../../../assets/excelerate-logo.png'
import EnterEmailAndPasswordStep from './EnterEmailAndPasswordStep'
import EnterEmailStep from './EnterEmailStep'
import AdminAPI from '../../../services/adminApi'
import InlineError from '../../atoms/InlineError/InlineError'
import { useAppDispatch, useAppSelector } from '../../../store'
import { useNavigate } from 'react-router-dom'
import { userLogin } from '../../../redux/auth/authActions'
import APIError from '../../../errors/APIError'
import { EventResponse } from '../../../types/adminApi/events'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'

const getErrorMessage = (error: Error | APIError) => {
  if (
    'errors' in error &&
    error.errors &&
    error.errors[0] != null &&
    typeof error.errors[0] != 'string' &&
    error.errors[0].path
  ) {
    return error.errors[0].message
  }
  return error.message
}

interface LoginDetails {
  email: string
  password: string
  eventId: string | null
  isFetchingEvents: boolean
  events: EventResponse[]
  errorFetchingEvents: Error | null
  error: Error | null
}

interface Props {}

const Login: React.FC<Props> = () => {
  const { isLoading, isLoggedIn, error } = useAppSelector(state => state.auth)
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  // redirect authenticated user to main app
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/')
    }
  }, [navigate, isLoggedIn])

  const [
    { email, password, eventId, events, isFetchingEvents, errorFetchingEvents },
    setDetails,
  ] = useState<LoginDetails>({
    email: '',
    password: '',
    eventId: null,
    isFetchingEvents: false,
    events: [],
    errorFetchingEvents: null,
    error: null,
  })

  const mergeDetails = (data: Partial<LoginDetails>) =>
    setDetails(prevState => ({ ...prevState, ...data }))

  const handleInput = useCallback((value: string, name: string) => {
    mergeDetails({
      errorFetchingEvents: null,
      [name]: value,
    })
  }, [])

  const handleSelectEvent = useCallback((eventId: string | null) => {
    mergeDetails({ eventId })
  }, [])

  const fetchEvents = useCallback(async () => {
    try {
      mergeDetails({
        isFetchingEvents: true,
        events: [],
        errorFetchingEvents: null,
      })

      const events = await AdminAPI.getEventsForParticipant(email)
      const eventId = events.length === 1 ? events[0].id : null
      mergeDetails({
        isFetchingEvents: false,
        events,
        eventId,
      })
    } catch (e: any) {
      let error = e
      if (e.message === 'Invalid url path parameters') {
        error = new Error('email: should be a valid email address')
      }
      mergeDetails({
        isFetchingEvents: false,
        errorFetchingEvents: error,
      })
    }
  }, [email])

  const handleSignInWithDifferentAccount = useCallback(() => {
    mergeDetails({
      eventId: null,
      email: '',
      password: '',
      events: [],
    })
  }, [])

  const handleLogin = useCallback(() => {
    if (eventId) {
      dispatch(userLogin({ email, password, eventId }))
    }
  }, [dispatch, email, eventId, password])

  return (
    <div className="flex h-screen items-center justify-center bg-[#112540] py-12 px-4 tablet:px-6 desktop:px-8">
      <VerticalGroup className="w-72">
        <VerticalGroup gap={4}>
          {errorFetchingEvents && (
            <InlineError message={errorFetchingEvents.message} />
          )}
          {error && <InlineError message={getErrorMessage(error)} />}
          <div>
            <img
              className="mx-auto w-56"
              src={logoSource}
              alt="Excelerate Consulting"
            />
          </div>
        </VerticalGroup>
        {!events?.length ? (
          <EnterEmailStep
            email={email}
            onInput={handleInput}
            onNext={fetchEvents}
            buttonEnabled={!isFetchingEvents && email.length > 0}
          />
        ) : (
          <EnterEmailAndPasswordStep
            eventId={eventId}
            events={events}
            email={email}
            password={password}
            onInput={handleInput}
            onLogin={handleLogin}
            onSelectEvent={handleSelectEvent}
            onSignInWithDifferentAccount={handleSignInWithDifferentAccount}
            buttonEnabled={
              !isLoading &&
              !isFetchingEvents &&
              email.length > 0 &&
              password.length > 0 &&
              !!eventId
            }
          />
        )}
      </VerticalGroup>
    </div>
  )
}

export default Login
