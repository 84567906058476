import { useMemo } from 'react'
import { useAppSelector } from '../store'
import React from 'react'
import { Colour } from '../types/theme'
import { IconType } from '../components/atoms/Icon/Icon'
import dateFormat from 'dateformat'

interface EventStatus {
  title: string
  message?: string
  colour: Colour
  enableButton: boolean
  icon: IconType
}

export const useEventStatus = () => {
  const { game, event, deviceStatusChecked } = useAppSelector(state => ({
    game: state.game,
    event: state.event?.details,
    deviceStatusChecked:
      state.device && state.device.registered
        ? state.device.deviceStatusChecked
        : !!state.device?.error,
  }))

  const {
    state: gameState,
    roundType,
    nextRoundStartTime,
    gameStartTime,
  } = game || {}

  const { gameStarted, eventClosed } = useMemo(() => {
    return {
      gameStarted: game && game.currentRound > 0,
      eventClosed: event?.eventClosed,
    }
  }, [event?.eventClosed, game])

  const eventStatus: EventStatus = React.useMemo(() => {
    if (eventClosed) {
      return {
        title: 'This event has now closed',
        colour: 'error',
        enableButton: false,
        icon: 'locked',
      }
    }

    if (!deviceStatusChecked) {
      return {
        title: 'Checking status of the event...',
        colour: 'primary',
        enableButton: false,
        icon: 'notStarted',
      }
    }

    if (!gameStarted) {
      if (roundType === 'scheduled' && gameStartTime) {
        return {
          title: 'This event has not yet commenced',
          message: `This event will commence at: ${dateFormat(
            gameStartTime,
            'hh:MMtt',
          )} on ${dateFormat(gameStartTime, 'dd mmmm yyyy')}`,
          colour: 'primary',
          enableButton: false,
          icon: 'notStarted',
        }
      }
      return {
        title: 'This event has not yet commenced',
        colour: 'purple',
        enableButton: false,
        icon: 'checking',
      }
    } else if (gameState === 'locked') {
      if (roundType === 'scheduled' && nextRoundStartTime) {
        return {
          title: 'This event will resume shortly',
          message: `This event will resume at: ${dateFormat(
            nextRoundStartTime,
            'hh:MMtt',
          )} on ${dateFormat(nextRoundStartTime, 'dd mmmm yyyy')}`,
          colour: 'orange',
          enableButton: false,
          icon: 'willResume',
        }
      }
      return {
        title: 'This event will resume shortly',
        colour: 'orange',
        enableButton: false,
        icon: 'willResume',
      }
    }
    return {
      title: "Let's Go!",
      message: 'This event has commenced, use the button below to enter',
      enableButton: true,
      icon: 'ready',
      colour: 'success',
    }
  }, [
    deviceStatusChecked,
    eventClosed,
    gameStartTime,
    gameStarted,
    gameState,
    nextRoundStartTime,
    roundType,
  ])

  return eventStatus
}
