import { Spacing } from '../types/theme'

export interface PaddingClasses extends Record<Spacing, string> {}

export const paddingClasses: PaddingClasses = {
  0: 'p-0',
  0.5: 'p-0.5',
  1: 'p-1',
  1.5: 'p-1.5',
  2: 'p-2',
  2.5: 'p-2.5',
  3: 'p-3',
  3.5: 'p-3.5',
  4: 'p-4',
  5: 'p-5',
  6: 'p-6',
  7: 'p-7',
  8: 'p-8',
  9: 'p-9',
  10: 'p-10',
  11: 'p-11',
  23: 'p-23',
  14: 'p-14',
  16: 'p-16',
  20: 'p-20',
  24: 'p-24',
  28: 'p-28',
  32: 'p-32',
  36: 'p-36',
  40: 'p-40',
  44: 'p-44',
  48: 'p-48',
  52: 'p-52',
  56: 'p-56',
  60: 'p-60',
  64: 'p-64',
  72: 'p-72',
  80: 'p-80',
  96: 'p-96',
}

export const paddingLeftClasses: PaddingClasses = {
  0: 'pl-0',
  0.5: 'pl-0.5',
  1: 'pl-1',
  1.5: 'pl-1.5',
  2: 'pl-2',
  2.5: 'pl-2.5',
  3: 'pl-3',
  3.5: 'pl-3.5',
  4: 'pl-4',
  5: 'pl-5',
  6: 'pl-6',
  7: 'pl-7',
  8: 'pl-8',
  9: 'pl-9',
  10: 'pl-10',
  11: 'pl-11',
  23: 'pl-23',
  14: 'pl-14',
  16: 'pl-16',
  20: 'pl-20',
  24: 'pl-24',
  28: 'pl-28',
  32: 'pl-32',
  36: 'pl-36',
  40: 'pl-40',
  44: 'pl-44',
  48: 'pl-48',
  52: 'pl-52',
  56: 'pl-56',
  60: 'pl-60',
  64: 'pl-64',
  72: 'pl-72',
  80: 'pl-80',
  96: 'pl-96',
}

export const paddingRightClasses: PaddingClasses = {
  0: 'pr-0',
  0.5: 'pr-0.5',
  1: 'pr-1',
  1.5: 'pr-1.5',
  2: 'pr-2',
  2.5: 'pr-2.5',
  3: 'pr-3',
  3.5: 'pr-3.5',
  4: 'pr-4',
  5: 'pr-5',
  6: 'pr-6',
  7: 'pr-7',
  8: 'pr-8',
  9: 'pr-9',
  10: 'pr-10',
  11: 'pr-11',
  23: 'pr-23',
  14: 'pr-14',
  16: 'pr-16',
  20: 'pr-20',
  24: 'pr-24',
  28: 'pr-28',
  32: 'pr-32',
  36: 'pr-36',
  40: 'pr-40',
  44: 'pr-44',
  48: 'pr-48',
  52: 'pr-52',
  56: 'pr-56',
  60: 'pr-60',
  64: 'pr-64',
  72: 'pr-72',
  80: 'pr-80',
  96: 'pr-96',
}

export const paddingYClasses: PaddingClasses = {
  0: 'py-0',
  0.5: 'py-0.5',
  1: 'py-1',
  1.5: 'py-1.5',
  2: 'py-2',
  2.5: 'py-2.5',
  3: 'py-3',
  3.5: 'py-3.5',
  4: 'py-4',
  5: 'py-5',
  6: 'py-6',
  7: 'py-7',
  8: 'py-8',
  9: 'py-9',
  10: 'py-10',
  11: 'py-11',
  23: 'py-23',
  14: 'py-14',
  16: 'py-16',
  20: 'py-20',
  24: 'py-24',
  28: 'py-28',
  32: 'py-32',
  36: 'py-36',
  40: 'py-40',
  44: 'py-44',
  48: 'py-48',
  52: 'py-52',
  56: 'py-56',
  60: 'py-60',
  64: 'py-64',
  72: 'py-72',
  80: 'py-80',
  96: 'py-96',
}
