import React from 'react'
import dateFormat from 'dateformat'
import { Combobox } from '@headlessui/react'
import classNames from 'classnames'
import Modal from '../../atoms/Modal/Modal'
import { EventResponse } from '../../../types/adminApi/events'

interface Props {
  events: EventResponse[]
  onClose: () => void
  onSelectEvent: (eventId: string) => void
}

const formatEventName = (e: EventResponse) => {
  let endBit = ''
  if (e.streamName) {
    endBit = ` - ${e.streamName}`
  }
  return `${e.gameName} (${dateFormat(e.date, 'dd mmm yyyy')}${endBit})`
}

const EventOptionsAlert: React.FC<Props> = ({
  events,
  onClose,
  onSelectEvent,
}) => {
  return (
    <Modal
      show
      onClose={onClose}
      title="You are registered for multiple events. Select one:"
    >
      <Combobox
        onChange={(item: string) => {
          onSelectEvent(item)
        }}
      >
        {events.length > 0 && (
          <Combobox.Options
            static
            className="max-h-96 scroll-py-3 overflow-y-auto p-3"
          >
            {events.map(event => (
              <Combobox.Option
                key={event.id}
                value={event.id}
                className={({ active }) =>
                  classNames('flex cursor-pointer select-none rounded-xl p-3', {
                    'bg-primary-400': active,
                  })
                }
              >
                {({ active }) => (
                  <>
                    <div className="ml-4 flex-auto">
                      <p
                        className={classNames(
                          'text-sm font-medium',
                          active ? 'text-gray-900' : 'text-gray-700',
                        )}
                      >
                        {formatEventName(event)}
                      </p>
                    </div>
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </Combobox>
    </Modal>
  )
}

export default EventOptionsAlert
