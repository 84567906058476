import React, { useCallback } from 'react'

import BrandedPage from '../../organisms/Branding/BrandedPage/BrandedPage'
import PrimaryText from '../../atoms/Text/PrimaryText'
import Tabs from '../../atoms/Tabs/Tabs'
import Tab from '../../atoms/Tabs/Tab'
import BrandedHorizontalGroup from '../../organisms/Branding/BrandedHorizontalGroup/BrandedHorizontalGroup'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import Logo from '../../atoms/Logo/Logo'
import Clock from '../../organisms/Header/Clock/Clock'
import { useAppSelector } from '../../../store'
import { selectBranding } from '../../organisms/Branding/selectors'
import BrandedVerticalGroup from '../../organisms/Branding/BrandedVerticalGroup/BrandedVerticalGroup'
import Page from '../../atoms/Page/Page'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import DemoSettingsSummaryMetrics from './DemoSettingsSummaryMetrics/DemoSettingsSummaryMetrics'
import { useNavigate } from 'react-router-dom'
import Clickable from '../../atoms/Clickable/Clickable'
import BrandedButton from '../../organisms/Branding/BrandedButton/BrandedButton'
import DemoSettingsFinancialMetrics from './DemoSettingsFinancialMetrics/DemoSettingsFinancialMetrics'
import DemoSettingsFundingOptions from './DemoSettingsFundingOptions/DemoSettingsFundingOptions'
import DemoSettingsCapitalMethod from './DemoSettingsCapitalMethod/DemoSettingsCapitalMethod'
import DemoSettingsImpacts from './DemoSettingsImpacts/DemoSettingsImpacts'

interface Props {}

type DemoTabs =
  | 'summary'
  | 'financials'
  | 'wsf_method'
  | 'capital_method'
  | 'impacts'

const TABS: Array<{ label: string; value: DemoTabs }> = [
  { label: 'Funding Options', value: 'wsf_method' },
  { label: 'Capital Options', value: 'capital_method' },
  { label: 'Summary', value: 'summary' },
  { label: 'Our Financials', value: 'financials' },
  { label: 'Impacts', value: 'impacts' },
]

const DemoSettings: React.FC<Props> = () => {
  const navigate = useNavigate()
  const branding = useAppSelector(selectBranding)
  const [currentTab, setTab] = React.useState('wsf_method')

  const handleLogoClick = useCallback(() => {
    navigate('/')
  }, [navigate])

  return (
    <BrandedPage noPadding className="h-screen">
      <BrandedHorizontalGroup center className="h-20" fullWidth>
        <HorizontalGroup
          verticalCenter
          between
          center
          className="w-full px-4 laptop:container"
        >
          <HorizontalGroup className="tablet:w-1/2" gap={2} verticalCenter>
            <Clickable onClick={handleLogoClick}>
              <Logo
                data-test="team-logo"
                height={16}
                textColour={branding.headingTextColour}
              />
            </Clickable>
          </HorizontalGroup>
          <Clock
            data-test="clock"
            textColour={branding.headingTextColour ?? 'white'}
          />
        </HorizontalGroup>
      </BrandedHorizontalGroup>
      <BrandedVerticalGroup full>
        <Page>
          <VerticalGroup fullWidth full center gap={2}>
            <PrimaryText weight="bold" size="lg">
              Demo Settings
            </PrimaryText>
            <Tabs>
              {TABS.map(t => (
                <Tab
                  key={t.value as string}
                  tab={t}
                  onClick={() => setTab(t.value)}
                  active={currentTab === t.value}
                />
              ))}
            </Tabs>
            {currentTab === 'summary' && <DemoSettingsSummaryMetrics />}
            {currentTab === 'financials' && <DemoSettingsFinancialMetrics />}
            {currentTab === 'wsf_method' && <DemoSettingsFundingOptions />}
            {currentTab === 'capital_method' && <DemoSettingsCapitalMethod />}
            {currentTab === 'impacts' && <DemoSettingsImpacts />}
            <BrandedHorizontalGroup
              verticalCenter
              center
              className="fixed bottom-0 z-10 h-20 w-full bg-gray-900"
            >
              <BrandedButton
                className="border border-white"
                onClick={handleLogoClick}
              >
                OK
              </BrandedButton>
            </BrandedHorizontalGroup>
          </VerticalGroup>
          <div className="h-20"></div>
        </Page>
      </BrandedVerticalGroup>
    </BrandedPage>
  )
}

export default DemoSettings
