import { PeerInsightsResponse } from '../../../../../types/gameApi/peerInsights'

export const data: PeerInsightsResponse = {
  marketShare: [
    {
      type: 'HOME_LOANS',
      name: 'HOME LOANS',
      teams: [
        {
          team: 3,
          type: 'HOME_LOANS',
          name: 'Gamma Bank',
          values: {
            balances: 184166,
            marketShare: 1289,
            interestRate: 468,
            commission: 18,
            npat: 883,
          },
          lastValues: {
            balances: 162304,
            marketShare: 1250,
            interestRate: 488,
            commission: 15,
            npat: 1080,
          },
        },
        {
          team: 1,
          type: 'HOME_LOANS',
          name: 'Alpha Bank',
          values: {
            balances: 182855,
            marketShare: 1280,
            interestRate: 476,
            commission: 18,
            npat: 886,
          },
          lastValues: {
            balances: 162304,
            marketShare: 1250,
            interestRate: 488,
            commission: 15,
            npat: 1080,
          },
        },
        {
          team: 5,
          type: 'HOME_LOANS',
          name: 'Sigma Bank',
          values: {
            balances: 180892,
            marketShare: 1266,
            interestRate: 468,
            commission: 11,
            npat: 182,
          },
          lastValues: {
            balances: 162304,
            marketShare: 1250,
            interestRate: 488,
            commission: 15,
            npat: 1080,
          },
        },
        {
          team: 7,
          type: 'HOME_LOANS',
          name: 'PC Bank 1',
          values: {
            balances: 179430,
            marketShare: 1256,
            interestRate: 481,
            commission: 16,
            npat: 964,
          },
          lastValues: {
            balances: 162304,
            marketShare: 1250,
            interestRate: 488,
            commission: 15,
            npat: 1080,
          },
        },
        {
          team: 6,
          type: 'HOME_LOANS',
          name: 'Omega Bank',
          values: {
            balances: 177611,
            marketShare: 1243,
            interestRate: 497,
            commission: 20,
            npat: 1102,
          },
          lastValues: {
            balances: 162304,
            marketShare: 1250,
            interestRate: 488,
            commission: 15,
            npat: 1080,
          },
        },
        {
          team: 2,
          type: 'HOME_LOANS',
          name: 'Beta Bank',
          values: {
            balances: 176802,
            marketShare: 1237,
            interestRate: 477,
            commission: 17,
            npat: 900,
          },
          lastValues: {
            balances: 162304,
            marketShare: 1250,
            interestRate: 488,
            commission: 15,
            npat: 1080,
          },
        },
        {
          team: 4,
          type: 'HOME_LOANS',
          name: 'Delta Bank',
          values: {
            balances: 173578,
            marketShare: 1215,
            interestRate: 498,
            commission: 10,
            npat: 1167,
          },
          lastValues: {
            balances: 162304,
            marketShare: 1250,
            interestRate: 488,
            commission: 15,
            npat: 1080,
          },
        },
        {
          team: 8,
          type: 'HOME_LOANS',
          name: 'PC Bank 2',
          values: {
            balances: 173014,
            marketShare: 1211,
            interestRate: 498,
            commission: 14,
            npat: 1158,
          },
          lastValues: {
            balances: 162304,
            marketShare: 1250,
            interestRate: 488,
            commission: 15,
            npat: 1080,
          },
        },
      ],
    },
    {
      type: 'BUSINESS_LOANS',
      name: 'BUSINESS LOANS',
      teams: [
        {
          team: 3,
          type: 'BUSINESS_LOANS',
          name: 'Gamma Bank',
          values: {
            balances: 65110,
            marketShare: 1350,
            interestRate: 598,
            npat: 799,
          },
          lastValues: {
            balances: 56814,
            marketShare: 1250,
            interestRate: 618,
            npat: 844,
          },
        },
        {
          team: 5,
          type: 'BUSINESS_LOANS',
          name: 'Sigma Bank',
          values: {
            balances: 64593,
            marketShare: 1340,
            interestRate: 602,
            npat: 541,
          },
          lastValues: {
            balances: 56814,
            marketShare: 1250,
            interestRate: 618,
            npat: 844,
          },
        },
        {
          team: 7,
          type: 'BUSINESS_LOANS',
          name: 'PC Bank 1',
          values: {
            balances: 62209,
            marketShare: 1290,
            interestRate: 622,
            npat: 840,
          },
          lastValues: {
            balances: 56814,
            marketShare: 1250,
            interestRate: 618,
            npat: 844,
          },
        },
        {
          team: 6,
          type: 'BUSINESS_LOANS',
          name: 'Omega Bank',
          values: {
            balances: 58648,
            marketShare: 1216,
            interestRate: 625,
            npat: 806,
          },
          lastValues: {
            balances: 56814,
            marketShare: 1250,
            interestRate: 618,
            npat: 844,
          },
        },
        {
          team: 4,
          type: 'BUSINESS_LOANS',
          name: 'Delta Bank',
          values: {
            balances: 58124,
            marketShare: 1205,
            interestRate: 628,
            npat: 830,
          },
          lastValues: {
            balances: 56814,
            marketShare: 1250,
            interestRate: 618,
            npat: 844,
          },
        },
        {
          team: 8,
          type: 'BUSINESS_LOANS',
          name: 'PC Bank 2',
          values: {
            balances: 58037,
            marketShare: 1204,
            interestRate: 623,
            npat: 817,
          },
          lastValues: {
            balances: 56814,
            marketShare: 1250,
            interestRate: 618,
            npat: 844,
          },
        },
        {
          team: 1,
          type: 'BUSINESS_LOANS',
          name: 'Alpha Bank',
          values: {
            balances: 57974,
            marketShare: 1202,
            interestRate: 643,
            npat: 871,
          },
          lastValues: {
            balances: 56814,
            marketShare: 1250,
            interestRate: 618,
            npat: 844,
          },
        },
        {
          team: 2,
          type: 'BUSINESS_LOANS',
          name: 'Beta Bank',
          values: {
            balances: 57265,
            marketShare: 1188,
            interestRate: 638,
            npat: 861,
          },
          lastValues: {
            balances: 56814,
            marketShare: 1250,
            interestRate: 618,
            npat: 844,
          },
        },
      ],
    },
    {
      type: 'DEPOSITS',
      name: 'DEPOSITS',
      teams: [
        {
          team: 1,
          type: 'DEPOSITS',
          name: 'Alpha Bank',
          values: {
            balances: 166856,
            marketShare: 1289,
            interestRate: 274,
            branches: 815,
            npat: 784,
          },
          lastValues: {
            balances: 155467,
            marketShare: 1250,
            interestRate: 263,
            branches: 800,
            npat: 933,
          },
        },
        {
          team: 3,
          type: 'DEPOSITS',
          name: 'Gamma Bank',
          values: {
            balances: 162849,
            marketShare: 1258,
            interestRate: 273,
            branches: 820,
            npat: 705,
          },
          lastValues: {
            balances: 155467,
            marketShare: 1250,
            interestRate: 263,
            branches: 800,
            npat: 933,
          },
        },
        {
          team: 2,
          type: 'DEPOSITS',
          name: 'Beta Bank',
          values: {
            balances: 162487,
            marketShare: 1256,
            interestRate: 273,
            branches: 815,
            npat: 779,
          },
          lastValues: {
            balances: 155467,
            marketShare: 1250,
            interestRate: 263,
            branches: 800,
            npat: 933,
          },
        },
        {
          team: 7,
          type: 'DEPOSITS',
          name: 'PC Bank 1',
          values: {
            balances: 161806,
            marketShare: 1250,
            interestRate: 270,
            branches: 809,
            npat: 807,
          },
          lastValues: {
            balances: 155467,
            marketShare: 1250,
            interestRate: 263,
            branches: 800,
            npat: 933,
          },
        },
        {
          team: 6,
          type: 'DEPOSITS',
          name: 'Omega Bank',
          values: {
            balances: 161710,
            marketShare: 1250,
            interestRate: 271,
            branches: 805,
            npat: 789,
          },
          lastValues: {
            balances: 155467,
            marketShare: 1250,
            interestRate: 263,
            branches: 800,
            npat: 933,
          },
        },
        {
          team: 5,
          type: 'DEPOSITS',
          name: 'Sigma Bank',
          values: {
            balances: 161606,
            marketShare: 1249,
            interestRate: 271,
            branches: 817,
            npat: 1216,
          },
          lastValues: {
            balances: 155467,
            marketShare: 1250,
            interestRate: 263,
            branches: 800,
            npat: 933,
          },
        },
        {
          team: 8,
          type: 'DEPOSITS',
          name: 'PC Bank 2',
          values: {
            balances: 158293,
            marketShare: 1223,
            interestRate: 258,
            branches: 795,
            npat: 948,
          },
          lastValues: {
            balances: 155467,
            marketShare: 1250,
            interestRate: 263,
            branches: 800,
            npat: 933,
          },
        },
        {
          team: 4,
          type: 'DEPOSITS',
          name: 'Delta Bank',
          values: {
            balances: 157877,
            marketShare: 1220,
            interestRate: 258,
            branches: 780,
            npat: 968,
          },
          lastValues: {
            balances: 155467,
            marketShare: 1250,
            interestRate: 263,
            branches: 800,
            npat: 933,
          },
        },
      ],
    },
    {
      type: 'CREDIT_CARDS',
      name: 'CREDIT CARDS',
      teams: [
        {
          team: 3,
          type: 'CREDIT_CARDS',
          name: 'Gamma Bank',
          values: {
            customers: 1773186,
            marketShare: 1317,
            annualFee: 230,
            loyaltyPoints: 145,
            npat: 298,
          },
          lastValues: {
            customers: 1796523,
            marketShare: 1250,
            annualFee: 250,
            loyaltyPoints: 125,
            npat: 451,
          },
        },
        {
          team: 5,
          type: 'CREDIT_CARDS',
          name: 'Sigma Bank',
          values: {
            customers: 1737141,
            marketShare: 1291,
            annualFee: 234,
            loyaltyPoints: 137,
            npat: 242,
          },
          lastValues: {
            customers: 1796523,
            marketShare: 1250,
            annualFee: 250,
            loyaltyPoints: 125,
            npat: 451,
          },
        },
        {
          team: 6,
          type: 'CREDIT_CARDS',
          name: 'Omega Bank',
          values: {
            customers: 1708854,
            marketShare: 1269,
            annualFee: 255,
            loyaltyPoints: 131,
            npat: 332,
          },
          lastValues: {
            customers: 1796523,
            marketShare: 1250,
            annualFee: 250,
            loyaltyPoints: 125,
            npat: 451,
          },
        },
        {
          team: 1,
          type: 'CREDIT_CARDS',
          name: 'Alpha Bank',
          values: {
            customers: 1675718,
            marketShare: 1245,
            annualFee: 275,
            loyaltyPoints: 150,
            npat: 297,
          },
          lastValues: {
            customers: 1796523,
            marketShare: 1250,
            annualFee: 250,
            loyaltyPoints: 125,
            npat: 451,
          },
        },
        {
          team: 7,
          type: 'CREDIT_CARDS',
          name: 'PC Bank 1',
          values: {
            customers: 1661924,
            marketShare: 1235,
            annualFee: 254,
            loyaltyPoints: 137,
            npat: 312,
          },
          lastValues: {
            customers: 1796523,
            marketShare: 1250,
            annualFee: 250,
            loyaltyPoints: 125,
            npat: 451,
          },
        },
        {
          team: 2,
          type: 'CREDIT_CARDS',
          name: 'Beta Bank',
          values: {
            customers: 1651665,
            marketShare: 1227,
            annualFee: 270,
            loyaltyPoints: 145,
            npat: 316,
          },
          lastValues: {
            customers: 1796523,
            marketShare: 1250,
            annualFee: 250,
            loyaltyPoints: 125,
            npat: 451,
          },
        },
        {
          team: 8,
          type: 'CREDIT_CARDS',
          name: 'PC Bank 2',
          values: {
            customers: 1625790,
            marketShare: 1208,
            annualFee: 255,
            loyaltyPoints: 120,
            npat: 369,
          },
          lastValues: {
            customers: 1796523,
            marketShare: 1250,
            annualFee: 250,
            loyaltyPoints: 125,
            npat: 451,
          },
        },
        {
          team: 4,
          type: 'CREDIT_CARDS',
          name: 'Delta Bank',
          values: {
            customers: 1621435,
            marketShare: 1205,
            annualFee: 260,
            loyaltyPoints: 115,
            npat: 384,
          },
          lastValues: {
            customers: 1796523,
            marketShare: 1250,
            annualFee: 250,
            loyaltyPoints: 125,
            npat: 451,
          },
        },
      ],
    },
  ],
  data: [
    {
      enabled: true,
      type: 'NPS',
      data: [
        {
          team: 1,
          name: 'Alpha Bank',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 13,
            },
          ],
        },
        {
          team: 6,
          name: 'Omega Bank',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 6,
            },
          ],
        },
        {
          team: 7,
          name: 'PC Bank 1',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 6,
            },
          ],
        },
        {
          team: 3,
          name: 'Gamma Bank',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 5,
            },
          ],
        },
        {
          team: 5,
          name: 'Sigma Bank',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 4,
            },
          ],
        },
        {
          team: 2,
          name: 'Beta Bank',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 1,
            },
          ],
        },
        {
          team: 4,
          name: 'Delta Bank',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 1,
            },
          ],
        },
        {
          team: 8,
          name: 'PC Bank 2',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 0,
            },
          ],
        },
      ],
      businessTypes: [
        {
          type: 'HOME_LOANS',
          name: 'HOME LOANS',
          data: [
            {
              team: 1,
              name: 'Alpha Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 15,
                },
              ],
            },
            {
              team: 7,
              name: 'PC Bank 1',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 7,
                },
              ],
            },
            {
              team: 6,
              name: 'Omega Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 6,
                },
              ],
            },
            {
              team: 3,
              name: 'Gamma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 5,
                },
              ],
            },
            {
              team: 5,
              name: 'Sigma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 5,
                },
              ],
            },
            {
              team: 2,
              name: 'Beta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 1,
                },
              ],
            },
            {
              team: 4,
              name: 'Delta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 1,
                },
              ],
            },
            {
              team: 8,
              name: 'PC Bank 2',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 0,
                },
              ],
            },
          ],
        },
        {
          type: 'BUSINESS_LOANS',
          name: 'BUSINESS LOANS',
          data: [
            {
              team: 1,
              name: 'Alpha Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 14,
                },
              ],
            },
            {
              team: 7,
              name: 'PC Bank 1',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 7,
                },
              ],
            },
            {
              team: 3,
              name: 'Gamma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 6,
                },
              ],
            },
            {
              team: 5,
              name: 'Sigma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 6,
                },
              ],
            },
            {
              team: 6,
              name: 'Omega Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 6,
                },
              ],
            },
            {
              team: 4,
              name: 'Delta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 2,
                },
              ],
            },
            {
              team: 2,
              name: 'Beta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 0,
                },
              ],
            },
            {
              team: 8,
              name: 'PC Bank 2',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 0,
                },
              ],
            },
          ],
        },
        {
          type: 'DEPOSITS',
          name: 'DEPOSITS',
          data: [
            {
              team: 1,
              name: 'Alpha Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 11,
                },
              ],
            },
            {
              team: 6,
              name: 'Omega Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 6,
                },
              ],
            },
            {
              team: 3,
              name: 'Gamma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 4,
                },
              ],
            },
            {
              team: 2,
              name: 'Beta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 3,
                },
              ],
            },
            {
              team: 7,
              name: 'PC Bank 1',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 3,
                },
              ],
            },
            {
              team: 5,
              name: 'Sigma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 1,
                },
              ],
            },
            {
              team: 8,
              name: 'PC Bank 2',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 1,
                },
              ],
            },
            {
              team: 4,
              name: 'Delta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: -2,
                },
              ],
            },
          ],
        },
        {
          type: 'CREDIT_CARDS',
          name: 'CREDIT CARDS',
          data: [
            {
              team: 1,
              name: 'Alpha Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 13,
                },
              ],
            },
            {
              team: 7,
              name: 'PC Bank 1',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 6,
                },
              ],
            },
            {
              team: 3,
              name: 'Gamma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 5,
                },
              ],
            },
            {
              team: 6,
              name: 'Omega Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 5,
                },
              ],
            },
            {
              team: 5,
              name: 'Sigma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 4,
                },
              ],
            },
            {
              team: 2,
              name: 'Beta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 2,
                },
              ],
            },
            {
              team: 4,
              name: 'Delta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 1,
                },
              ],
            },
            {
              team: 8,
              name: 'PC Bank 2',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 0,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      enabled: true,
      type: 'Complaints',
      data: [
        {
          team: 8,
          name: 'PC Bank 2',
          value: 768,
          last: 700,
        },
        {
          team: 4,
          name: 'Delta Bank',
          value: 761,
          last: 700,
        },
        {
          team: 2,
          name: 'Beta Bank',
          value: 756,
          last: 700,
        },
        {
          team: 7,
          name: 'PC Bank 1',
          value: 706,
          last: 700,
        },
        {
          team: 5,
          name: 'Sigma Bank',
          value: 673,
          last: 700,
        },
        {
          team: 1,
          name: 'Alpha Bank',
          value: 671,
          last: 700,
        },
        {
          team: 6,
          name: 'Omega Bank',
          value: 656,
          last: 700,
        },
        {
          team: 3,
          name: 'Gamma Bank',
          value: 649,
          last: 700,
        },
      ],
    },
    {
      enabled: true,
      type: 'TSR',
      data: [
        {
          team: 6,
          name: 'Omega Bank',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 1122,
            },
          ],
        },
        {
          team: 1,
          name: 'Alpha Bank',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 1055,
            },
          ],
        },
        {
          team: 7,
          name: 'PC Bank 1',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 1000,
            },
          ],
        },
        {
          team: 2,
          name: 'Beta Bank',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 945,
            },
          ],
        },
        {
          team: 5,
          name: 'Sigma Bank',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 898,
            },
          ],
        },
        {
          team: 4,
          name: 'Delta Bank',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 897,
            },
          ],
        },
        {
          team: 3,
          name: 'Gamma Bank',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 794,
            },
          ],
        },
        {
          team: 8,
          name: 'PC Bank 2',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 784,
            },
          ],
        },
      ],
    },
    {
      enabled: true,
      type: 'EmployeeEngagement',
      data: [
        {
          team: 5,
          name: 'Sigma Bank',
          value: 5972,
        },
        {
          team: 6,
          name: 'Omega Bank',
          value: 5707,
        },
        {
          team: 2,
          name: 'Beta Bank',
          value: 5657,
        },
        {
          team: 7,
          name: 'PC Bank 1',
          value: 5657,
        },
        {
          team: 1,
          name: 'Alpha Bank',
          value: 5394,
        },
        {
          team: 4,
          name: 'Delta Bank',
          value: 5319,
        },
        {
          team: 3,
          name: 'Gamma Bank',
          value: 5118,
        },
        {
          team: 8,
          name: 'PC Bank 2',
          value: 4697,
        },
      ],
    },
    {
      enabled: true,
      type: 'Community',
      data: [
        {
          team: 1,
          name: 'Alpha Bank',
          value: 5000,
        },
        {
          team: 7,
          name: 'PC Bank 1',
          value: 5000,
        },
        {
          team: 2,
          name: 'Beta Bank',
          value: 4500,
        },
        {
          team: 4,
          name: 'Delta Bank',
          value: 4500,
        },
        {
          team: 8,
          name: 'PC Bank 2',
          value: 4500,
        },
        {
          team: 3,
          name: 'Gamma Bank',
          value: 4400,
        },
        {
          team: 5,
          name: 'Sigma Bank',
          value: 4400,
        },
        {
          team: 6,
          name: 'Omega Bank',
          value: 4400,
        },
      ],
    },
    {
      enabled: true,
      type: 'Rating',
      data: [
        {
          team: 6,
          name: 'Omega Bank',
          value: 1127,
          rating: 'A',
        },
        {
          team: 2,
          name: 'Beta Bank',
          value: 1084,
          rating: 'A',
        },
        {
          team: 7,
          name: 'PC Bank 1',
          value: 1060,
          rating: 'A',
        },
        {
          team: 8,
          name: 'PC Bank 2',
          value: 1060,
          rating: 'A',
        },
        {
          team: 1,
          name: 'Alpha Bank',
          value: 1053,
          rating: 'A',
        },
        {
          team: 3,
          name: 'Gamma Bank',
          value: 1029,
          rating: 'BBB',
        },
        {
          team: 4,
          name: 'Delta Bank',
          value: 1003,
          rating: 'BBB',
        },
        {
          team: 5,
          name: 'Sigma Bank',
          value: 968,
          rating: 'BBB',
        },
      ],
    },
    {
      enabled: false,
      type: 'Customers',
      data: [
        {
          team: 3,
          name: 'Gamma Bank',
          value: 3454316,
        },
        {
          team: 1,
          name: 'Alpha Bank',
          value: 3413226,
        },
        {
          team: 5,
          name: 'Sigma Bank',
          value: 3397924,
        },
        {
          team: 7,
          name: 'PC Bank 1',
          value: 3368489,
        },
        {
          team: 2,
          name: 'Beta Bank',
          value: 3359246,
        },
        {
          team: 6,
          name: 'Omega Bank',
          value: 3345144,
        },
        {
          team: 8,
          name: 'PC Bank 2',
          value: 3297642,
        },
        {
          team: 4,
          name: 'Delta Bank',
          value: 3293444,
        },
      ],
    },
    {
      enabled: false,
      type: 'LIE',
      data: [
        {
          team: 1,
          name: 'Alpha Bank',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 20,
            },
          ],
        },
        {
          team: 3,
          name: 'Gamma Bank',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 20,
            },
          ],
        },
        {
          team: 4,
          name: 'Delta Bank',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 20,
            },
          ],
        },
        {
          team: 5,
          name: 'Sigma Bank',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 20,
            },
          ],
        },
        {
          team: 6,
          name: 'Omega Bank',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 20,
            },
          ],
        },
        {
          team: 7,
          name: 'PC Bank 1',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 20,
            },
          ],
        },
        {
          team: 2,
          name: 'Beta Bank',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 19,
            },
          ],
        },
        {
          team: 8,
          name: 'PC Bank 2',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 19,
            },
          ],
        },
      ],
    },
    {
      enabled: false,
      type: 'RegulatorActions',
      data: [
        {
          team: 1,
          name: 'Alpha Bank',
          value: 0,
        },
        {
          team: 2,
          name: 'Beta Bank',
          value: 0,
        },
        {
          team: 3,
          name: 'Gamma Bank',
          value: 0,
        },
        {
          team: 4,
          name: 'Delta Bank',
          value: 0,
        },
        {
          team: 5,
          name: 'Sigma Bank',
          value: 0,
        },
        {
          team: 6,
          name: 'Omega Bank',
          value: 0,
        },
        {
          team: 7,
          name: 'PC Bank 1',
          value: 0,
        },
        {
          team: 8,
          name: 'PC Bank 2',
          value: 0,
        },
      ],
    },
    {
      enabled: false,
      type: 'RiskIncidents',
      data: [
        {
          team: 4,
          name: 'Delta Bank',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 747,
            },
          ],
        },
        {
          team: 3,
          name: 'Gamma Bank',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 742,
            },
          ],
        },
        {
          team: 8,
          name: 'PC Bank 2',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 737,
            },
          ],
        },
        {
          team: 2,
          name: 'Beta Bank',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 727,
            },
          ],
        },
        {
          team: 7,
          name: 'PC Bank 1',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 718,
            },
          ],
        },
        {
          team: 6,
          name: 'Omega Bank',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 708,
            },
          ],
        },
        {
          team: 1,
          name: 'Alpha Bank',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 706,
            },
          ],
        },
        {
          team: 5,
          name: 'Sigma Bank',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 702,
            },
          ],
        },
      ],
    },
    {
      enabled: false,
      type: 'ROE',
      data: [
        {
          team: 4,
          name: 'Delta Bank',
          value: 2087,
          last: 2103,
        },
        {
          team: 8,
          name: 'PC Bank 2',
          value: 1940,
          last: 2103,
        },
        {
          team: 7,
          name: 'PC Bank 1',
          value: 1728,
          last: 2103,
        },
        {
          team: 6,
          name: 'Omega Bank',
          value: 1700,
          last: 2103,
        },
        {
          team: 1,
          name: 'Alpha Bank',
          value: 1651,
          last: 2103,
        },
        {
          team: 2,
          name: 'Beta Bank',
          value: 1646,
          last: 2103,
        },
        {
          team: 3,
          name: 'Gamma Bank',
          value: 1486,
          last: 2103,
        },
        {
          team: 5,
          name: 'Sigma Bank',
          value: 1266,
          last: 2103,
        },
      ],
    },
  ],
}
