import React from 'react'
import { BACKGROUND_COLOUR_CLASSES } from '../../../lib/colourClasses'
import {
  EconomicScenarioNewsConfig,
  EconomicScenarioNewsRoundResponse,
  ScenarioLogoType,
} from '../../../types/gameApi/news'
import { Colour } from '../../../types/theme'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import { Icon } from '../../atoms/Icon/Icon'
import PrimaryText from '../../atoms/Text/PrimaryText'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'

interface ScenarioProps {
  logoType: ScenarioLogoType
  heading: string
  description: string
  colour: Colour
}

const BreakingNewsScenario: React.FC<ScenarioProps> = ({
  logoType,
  heading,
  description,
  colour,
}) => {
  return (
    <VerticalGroup>
      <HorizontalGroup verticalCenter gap={2}>
        <div className={`${BACKGROUND_COLOUR_CLASSES[colour]} rounded-xl p-2`}>
          <Icon colour="white" type={logoType} />
        </div>
        <PrimaryText size="sm" weight="bold">
          {heading}
        </PrimaryText>
      </HorizontalGroup>
      <PrimaryText isHtml paddingY={1} size="sm">
        {description}
      </PrimaryText>
    </VerticalGroup>
  )
}
interface Props {
  news: EconomicScenarioNewsRoundResponse
  newsConfig: EconomicScenarioNewsConfig
}

const BreakingNewsScenarios: React.FC<Props> = ({ news, newsConfig }) => {
  return (
    <VerticalGroup data-test="news-scenario">
      <PrimaryText paddingY={1} weight="bold" size="base" colour="primary">
        {newsConfig.marketViewSectionHeading}
      </PrimaryText>
      <BreakingNewsScenario
        logoType={newsConfig.marketView1Logo}
        heading={newsConfig.marketView1Heading}
        description={news.marketView1Text}
        colour="primary"
      />

      <BreakingNewsScenario
        logoType={newsConfig.marketView2Logo}
        heading={newsConfig.marketView2Heading}
        description={news.marketView2Text}
        colour="orange"
      />
    </VerticalGroup>
  )
}

export default BreakingNewsScenarios
