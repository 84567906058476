import React, { FC } from 'react'

import HorizontalGroup, {
  Props as HorizontalGroupProps,
} from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { useAppSelector } from '../../../../store'
import { selectBranding } from '../selectors'

interface Props extends HorizontalGroupProps {}

const BrandedHorizontalGroup: FC<Props> = props => {
  const branding = useAppSelector(selectBranding)
  const style: React.CSSProperties = props.style ?? {}
  if (branding.headingBackgroundColour) {
    style.backgroundColor = branding.headingBackgroundColour
  }
  return <HorizontalGroup {...props} style={style} />
}

export default BrandedHorizontalGroup
