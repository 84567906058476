import React from 'react'

import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import WaitingRoomGameInfoHeader from './WaitingRoomGameInfoHeader/WaitingRoomGameInfoHeader'

import Card from '../../atoms/Card/Card'
import WaitingRoomTeamInfoCard from './WaitingRoomTeamInfoCard/WaitingRoomTeamInfoCard'
import WaitingRoomEventInfoCard from './WaitingRoomEventInfoCard/WaitingRoomEventInfoCard'
import WaitingRoomSimulationResultsCard from './WaitingRoomSimulationResultsCard/WaitingRoomSimulationResultsCard'
import WaitinRoomResourceCard from './WaitinRoomResourceCard/WaitinRoomResourceCard'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import WaitingRoomCeoTransfer from './WaitingRoomCeoTransfer/WaitingRoomCeoTransfer'
import BrandedVerticalGroup from '../../organisms/Branding/BrandedVerticalGroup/BrandedVerticalGroup'
import Page from '../../atoms/Page/Page'
import { RootState } from '../../../store'

interface Props {
  event: RootState['event']['details']
  game: RootState['game']
  team: RootState['team']
  device: RootState['device']
  deviceStatusChecked: boolean
  showCeo: boolean
  logoutAction: () => void
  onSettingsButtonPressed: () => void
  onEnterEventButtonPressed: () => void
  resources?: RootState['resources']['resources'] | null
}

export const WaitingRoom: React.FC<Props> = ({
  event,
  game,
  team,
  device,
  showCeo,
  logoutAction,
  onSettingsButtonPressed,
  onEnterEventButtonPressed,
  resources,
}) => {
  return (
    <BrandedVerticalGroup center className="flex h-screen">
      <WaitingRoomCeoTransfer />
      <WaitingRoomGameInfoHeader
        clientName={event?.client ?? ''}
        gameName={event?.gameName}
        onLogout={logoutAction}
        onSettingsButtonPressed={onSettingsButtonPressed}
      />

      <Page className="tablet:container">
        <VerticalGroup>
          <VerticalGroup className="monitor:pt-10" gap={4}>
            <VerticalGroup fullWidth>
              <Card className="w-full p-4 laptop:px-8 laptop:py-10">
                <VerticalGroup
                  gap={4}
                  verticalCenter
                  fullWidth
                  className="divide-y tablet:flex tablet:flex-row tablet:divide-y-0"
                >
                  <div className="tablet:w-5/12">
                    {team.id != null && (
                      <WaitingRoomTeamInfoCard
                        participantName={event?.firstName ?? ''}
                        team={{ id: team.id, name: team.name ?? '' }}
                        registered={device.registered}
                        isCeo={device.isCeo}
                        showCeo={showCeo}
                      />
                    )}
                  </div>

                  <div className="tablet:w-7/12">
                    <WaitingRoomEventInfoCard
                      onEnterEventButtonPressed={onEnterEventButtonPressed}
                    />
                  </div>
                </VerticalGroup>
              </Card>
            </VerticalGroup>
            {process.env.REACT_APP_ENABLE_RESULTS === 'true' && (
              <VerticalGroup fullWidth full center className="">
                <VerticalGroup
                  className="laptop:grid laptop:grid-cols-[3fr,4fr] laptop:gap-4"
                  gap={5}
                  fullWidth
                >
                  <HorizontalGroup className="h-full w-full laptop:col-span-1">
                    <WaitingRoomSimulationResultsCard
                      game={game}
                      device={device}
                    />
                  </HorizontalGroup>
                  <HorizontalGroup className="h-full w-full laptop:col-span-1">
                    <WaitinRoomResourceCard resources={resources} />
                  </HorizontalGroup>
                </VerticalGroup>
              </VerticalGroup>
            )}
            {process.env.REACT_APP_ENABLE_RESULTS !== 'true' && (
              <HorizontalGroup fullWidth center>
                <HorizontalGroup fullWidth>
                  <HorizontalGroup className="w-full">
                    <WaitinRoomResourceCard resources={resources} />
                  </HorizontalGroup>
                </HorizontalGroup>
              </HorizontalGroup>
            )}
          </VerticalGroup>
        </VerticalGroup>
        <HorizontalGroup
          className="pl-8"
          style={{ height: '32px' }}
          verticalCenter
        >
          {/* <PrimaryText>
            Version: {appVersion} - {process.env.REACT_APP_PACKAGE_VERSION}
          </PrimaryText> */}
        </HorizontalGroup>
      </Page>
    </BrandedVerticalGroup>
  )
}
