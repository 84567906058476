import { thousandSeparator } from '../../../lib/formatters'
import { ProductType } from '../../../types/gameApi/business'

export const formatPrice1 = (value: number, type?: ProductType) => {
  if (type === 'CREDIT_CARDS') {
    return `$${thousandSeparator(value)}`
  }
  return `${(value / 100).toFixed(2)}%`
}

export const formatVolume = (val: number, type?: ProductType) => {
  if (type === 'CREDIT_CARDS') {
    return thousandSeparator((val / 1000000).toFixed(3))
  }
  return thousandSeparator(parseInt(String(val)))
}
