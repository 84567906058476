import { useMemo } from 'react'
import {
  paddingClasses,
  paddingLeftClasses,
  paddingRightClasses,
  paddingYClasses,
} from '../lib/commonClasses'
import { Spacing } from '../types/theme'

export interface Props {
  padding?: Spacing
  paddingLeft?: Spacing
  paddingRight?: Spacing
  paddingY?: Spacing
}

export const useCommonClasses = (props: Props): string[] => {
  return useMemo(() => {
    const classes: string[] = []
    if (props.padding) {
      classes.push(paddingClasses[props.padding])
    }
    if (props.paddingLeft) {
      classes.push(paddingLeftClasses[props.paddingLeft])
    }
    if (props.paddingRight) {
      classes.push(paddingRightClasses[props.paddingRight])
    }
    if (props.paddingY) {
      classes.push(paddingYClasses[props.paddingY])
    }
    return classes
  }, [props])
}
