import React from 'react'
import { charAt } from './constants'
import HorizontalGroup from '../../../../atoms/HorizontalGroup/HorizontalGroup'
import SecondaryText from '../../../../atoms/Text/SecondaryText'
import { Icon } from '../../../../atoms/Icon/Icon'
import classNames from 'classnames'

export type Field = {
  id: string
  type: string
  config: {
    type: string
    label: string
  }
}

interface Props {
  field: Field
  index: number
  selected: boolean
  onClick: (field: Field) => void
}
const CustomPulseKeyframes = `
  @keyframes custom-pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
`

export const ChoiceField = ({ field, index, selected, onClick }: Props) => {
  return (
    <HorizontalGroup
      verticalCenter
      gap={2}
      fullWidth
      onClick={() => onClick(field)}
      className={classNames(
        'w-20 cursor-pointer rounded-md border border-primary-500 bg-white p-3 hover:bg-primary-200 tablet:min-w-[22rem]',
        { 'custom-pulse': selected },
      )}
      style={{
        animation: selected
          ? 'custom-pulse 0.2s cubic-bezier(0.4, 0, 0.6, 1) 2'
          : undefined,
      }}
    >
      <style>{CustomPulseKeyframes}</style>
      <HorizontalGroup
        verticalCenter
        className={classNames(
          'flex h-6 w-6 justify-center rounded-md border border-primary-500',
          {
            'bg-primary': selected,
          },
        )}
      >
        <SecondaryText weight="bold" colour={selected ? 'white' : 'primary'}>
          {charAt(index)}
        </SecondaryText>
      </HorizontalGroup>
      <HorizontalGroup
        verticalCenter
        className="w-48 tablet:w-80 tablet:break-words"
      >
        <SecondaryText size="sm" colour="primary" isHtml>
          {field.config.label}
        </SecondaryText>
      </HorizontalGroup>

      {selected && (
        <HorizontalGroup verticalCenter>
          <Icon type="positive" size={6} colour="primary" />
        </HorizontalGroup>
      )}
    </HorizontalGroup>
  )
}
