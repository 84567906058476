import React, { useCallback, useEffect, useMemo } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { RatingField } from './Field/RatingField'
import {
  useCharacterLimitValidator,
  WrittenResponseField,
} from './Field/WrittenResponseField'
import {
  Answer,
  AssessmentQuestion,
  CurrentAnswer,
  RatingField as RatingFieldType,
  WrittenResponseField as WrittenResponseFieldType,
} from '../../../../types/gameApi/assessments'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import QuestionTitle from './QuestionTitle'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import QuestionContainer from './QuestionContainer'

interface Props {
  questionIndex: number
  question: AssessmentQuestion
  currentAnswer?: CurrentAnswer
}
const setFieldValue = (
  currentValue: Answer[],
  field: { id: string },
  value: string,
  onChange: (values: Answer[]) => void,
) => {
  if (!currentValue || currentValue.length === 0) {
    return
  }

  onChange(
    currentValue.map(answer => {
      if (answer.fieldId === field.id) {
        return { fieldId: field.id, value }
      }

      return answer
    }),
  )
}

const getFieldValue = (currentValue: Answer[], field: { id: string }) => {
  if (!currentValue || currentValue.length === 0) {
    return ''
  }

  const answer = currentValue.find(answer => answer.fieldId === field.id)
  return answer ? answer.value : ''
}

const onChangeHandler = (
  currentValue: Answer[],
  field: { id: string },
  onChange: (values: Answer[]) => void,
) => {
  if (currentValue && currentValue.length > 0) {
    if (currentValue.find(answer => answer.fieldId === field.id)) {
      onChange(currentValue.filter(answer => answer.fieldId !== field.id))
    } else {
      onChange([{ fieldId: field.id }])
    }
  } else {
    onChange([{ fieldId: field.id }])
  }
}

export const Rating: React.FC<Props> = ({
  question,
  questionIndex,
  currentAnswer,
}) => {
  const { control, getValues, setValue } = useFormContext()
  const { fields, id } = question

  const choiceFields = useMemo(() => {
    return fields.filter(
      field => field.type === 'rating' && field.config.label !== 'N/A',
    )
  }, [fields]) as RatingFieldType[]

  const naField = useMemo(() => {
    return fields.find(
      field => field.type === 'rating' && field.config.label === 'N/A',
    )
  }, [fields]) as RatingFieldType

  const otherField = useMemo(() => {
    return fields.find(field => field.type === 'written_response')
  }, [fields]) as WrittenResponseFieldType

  const characterLimitValidator = useCharacterLimitValidator(
    otherField,
    getFieldValue,
  )

  const getCurrentValue = useCallback(
    (currentValue: Answer[]): string | null => {
      if (!currentValue || currentValue.length === 0) {
        return null
      }

      const selectedField = currentValue[0].fieldId
      return selectedField
    },
    [],
  )

  const validate = useCallback(
    (answer: Answer[]) => {
      if (!question.mandatory) {
        return true
      }
      return answer.length > 0
    },
    [question.mandatory],
  )

  useEffect(() => {
    if (
      typeof currentAnswer !== 'undefined' &&
      typeof getValues()[id] === 'undefined'
    ) {
      setValue(id, currentAnswer.answer)
    }
  }, [currentAnswer, id, setValue, getValues])

  return (
    <Controller
      name={id}
      control={control}
      shouldUnregister={false}
      rules={{
        validate: {
          required: validate,
          characterLimit: characterLimitValidator,
        },
      }}
      render={({ field: { onChange, value }, fieldState }) => {
        return (
          <QuestionContainer>
            <QuestionTitle
              hasQuestionIndex
              questionIndex={questionIndex}
              title={question.question}
            />
            <VerticalGroup fullWidth center verticalCenter>
              <VerticalGroup
                className="tablet:flex-row"
                gap={2}
                fullWidth
                between
                center
                verticalCenter
              >
                {choiceFields.map(field => (
                  <RatingField
                    key={field.id}
                    field={field}
                    selected={getCurrentValue(value)}
                    onClick={field => {
                      onChangeHandler(value, field, onChange)
                    }}
                  />
                ))}
                {otherField && (
                  <RatingField
                    field={{
                      id: otherField.id,
                      type: 'rating',
                      config: {
                        type: 'rating',
                        label: 'Other',
                        rating: -1,
                      },
                    }}
                    selected={getCurrentValue(value)}
                    onClick={field => {
                      onChangeHandler(value, field, onChange)
                    }}
                  />
                )}
                {naField && (
                  <RatingField
                    field={naField}
                    selected={getCurrentValue(value)}
                    onClick={field => {
                      onChangeHandler(value, field, onChange)
                    }}
                  />
                )}
              </VerticalGroup>
            </VerticalGroup>
            {otherField && (
              <WrittenResponseField
                hidden={getCurrentValue(value) !== otherField.id}
                value={getFieldValue(value, otherField) || ''}
                onChange={v => {
                  setFieldValue(value, otherField, v, onChange)
                }}
                characterLimit={otherField.config.characterLimit}
              />
            )}
            {fieldState.error && fieldState.error.type === 'characterLimit' && (
              <SecondaryText colour="error" size="xs">
                {fieldState.error.message}
              </SecondaryText>
            )}
            {fieldState.error && fieldState.error.type === 'required' && (
              <SecondaryText colour="error" size="xs">
                should select at least one option
              </SecondaryText>
            )}
            {fieldState.error && !fieldState.error.type && (
              <SecondaryText colour="error" size="xs">
                {fieldState.error.message}
              </SecondaryText>
            )}
          </QuestionContainer>
        )
      }}
    />
  )
}
