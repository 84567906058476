import { Colour } from '../types/theme'

export const colours: Record<Colour, string> = {
  success: '#36BD85',
  error: '#D70005',
  primary: '#27A4F2',
  orange: '#F9AA3C',
  pink: '#E876E1',
  green: '#53C0A5',
  purple: '#9549D6',
  darkblue: '#148ADE',
  gray: '',
  white: '',
  black: '',
  lightgray: '',
}
