import React from 'react'

import Swiper from '../../../atoms/Swiper/Swiper'
import { CostsResponse } from '../../../../types/gameApi/costs'
import { DecisionMap } from '../CostsContainer'
import { CostsData } from '../Data'
import { FTECostPerDivision } from '../calculations'
import { Colour } from '../../../../types/theme'
import { SwiperSlide } from 'swiper/react'
// import CostsDivisionCardCarousel from './CostsDivisionCardCarousel'
import Card from '../../../atoms/Card/Card'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { Swiper as SwiperClass } from 'swiper'
import CostsDivisionCard from './CostsDivisionCard'

interface CostCarouselProps {
  data: CostsResponse
  decisions: DecisionMap
  enableChanges: boolean
  onChangeValue: (val: Partial<DecisionMap>) => void
  costData: CostsData
  peopleAndCostImpactPerDivision: FTECostPerDivision[]
  onSelectDivision: (divisionType: string) => void
  selectedDivision: string | null
  linkedDivisions: (keyof DecisionMap)[]
  setLinkedDivisions: (linkedDivisions: (keyof DecisionMap)[]) => void
}
const colours: Colour[] = ['primary', 'orange', 'pink', 'green', 'purple']
const CostCarousel: React.FC<CostCarouselProps> = ({
  costData,
  data,
  enableChanges,
  peopleAndCostImpactPerDivision,
  decisions,
  onChangeValue,
  selectedDivision,
  onSelectDivision,
  linkedDivisions,
  setLinkedDivisions,
}) => {
  const { divisions, impactValues } = costData
  const handleSlideChange = (swiper: SwiperClass) => {
    const newSelectedDivision = divisions[swiper.activeIndex].type
    onSelectDivision(newSelectedDivision)
  }
  return (
    <Card className="relative h-full w-full">
      <HorizontalGroup
        fullWidth
        className="z-10 bg-transparent"
        center
        verticalCenter
        fullHeight
      >
        <Swiper
          fixedArrows
          className="h-full w-full"
          handleSlideChange={handleSlideChange}
        >
          <HorizontalGroup>
            <div className="relative">
              {divisions.map((division, i) => {
                const fteChange = peopleAndCostImpactPerDivision[i]?.fteChange
                const changeInCost =
                  peopleAndCostImpactPerDivision[i]?.changeInCost

                return (
                  <SwiperSlide key={i} className="h-full w-full">
                    <CostsDivisionCard
                      linkedDivisions={linkedDivisions}
                      setLinkedDivisions={setLinkedDivisions}
                      decisions={decisions}
                      enableChanges={enableChanges}
                      fteChange={fteChange}
                      changeInCost={changeInCost}
                      division={division}
                      CostsData={costData}
                      data={data}
                      colour={colours[i % colours.length]}
                      onChangeValue={onChangeValue}
                      effectiveDecision={
                        impactValues.effectiveDecisions[division.type]
                      }
                      selectedDivision={selectedDivision}
                      onSelectDivision={onSelectDivision}
                    />
                  </SwiperSlide>
                )
              })}
            </div>
          </HorizontalGroup>
        </Swiper>
      </HorizontalGroup>
    </Card>
  )
}

export default CostCarousel
