import { AssessmentResponse } from '../../../../../types/gameApi/assessments'

export const data: AssessmentResponse = {
  gameId: 'abcde',
  roundId: 4,
  groupId: '3747964f-8934-4f46-b255-ed5aacaa170b',
  assessmentId: 'cb643464-a955-440f-9315-6e9d7dbfd7e9',
  questions: [
    {
      id: '270938e0-e47a-49f7-80f4-3f56913f4d1d',
      type: 'written_response',
      question: 'Please tell us your first and last name',
      mandatory: true,
      fields: [
        {
          id: 'ef807ad0-775f-4082-ab37-0800517406a5',
          type: 'written_response',
          config: {
            type: 'written_response',
            label: '',
            hasCharacterLimit: false,
          },
        },
      ],
    },
    {
      id: '9f6a00db-a981-45fb-8de6-12c3fa73516f',
      type: 'written_response',
      question:
        "What key insight(s) have you gained through attending today's training?",
      mandatory: true,
      fields: [
        {
          id: 'bfaab2d8-e105-469f-8cc5-3c4a0630ec0e',
          type: 'written_response',
          config: {
            type: 'written_response',
            label: '',
            hasCharacterLimit: false,
          },
        },
      ],
    },
    {
      id: 'a2c086e2-f6d9-4a28-af35-cbd8980ea121',
      type: 'written_response',
      question:
        'Write down a commitment that you will add to your personal development plan as a result of insights/knowledge gained from this training?',
      mandatory: true,
      fields: [
        {
          id: 'bfaab2d8-e105-469f-8cc5-3c4a0630ec01',
          type: 'written_response',
          config: {
            type: 'written_response',
            label: '',
            hasCharacterLimit: false,
          },
        },
      ],
    },
  ],
}
