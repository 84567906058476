import { createAsyncThunk } from '@reduxjs/toolkit'
import APIError from '../../errors/APIError'
import gameAPI from '../../services/gameApi'
import { RootState } from '../../store'
import {
  EconomicScenarioNewsParams,
  EconomicScenarioNewsResponse,
} from '../../types/gameApi/news'
import ThunkCancelledError from '../../errors/ThunkCancelledError'

export const fetchNews = createAsyncThunk<
  EconomicScenarioNewsResponse,
  EconomicScenarioNewsParams,
  { rejectValue: Error | APIError; state: RootState }
>('news/fetch', async (blah, { rejectWithValue, getState, requestId }) => {
  const state = getState()
  if (state.news.news) {
    return state.news.news
  }
  try {
    if (state.news.requestInProgress !== requestId) {
      throw new ThunkCancelledError()
    }
    const response = await gameAPI.getBreakingNews()

    return response
  } catch (error: any) {
    return rejectWithValue(error)
  }
})
