import React from 'react'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import OurFinancials from './components/pages/OurFinancials/OurFinancials'
import ErrorPage from './components/pages/Error/Error'
import Layout from './components/pages/Layout/Layout'
import Login from './components/pages/Login/Login'
import Summary from './components/pages/Summary/Summary'
import { ProtectedRoute } from './ProtectedRoute'
import HelpManual from './components/pages/HelpManual/HelpManual'
import ExcoDecisions from './components/pages/ExcoDecisions/ExcoDecisions'
import SpecialProjects from './components/pages/SpecialProjects/SpecialProjects'
import Costs from './components/pages/Costs/CostsContainer'
import Businesses from './components/pages/Businesses/BusinessesContainer'
import Treasury from './components/pages/Treasury/TreasuryContainer'
import DealRoom from './components/pages/DealRoom/DealRoomContainer'
import PeerInsights from './components/pages/PeerInsights/PeerInsights'
import Assessments from './components/pages/Assessments/Assessments'
import Team from './components/pages/Team/Team'
import DemoSettings from './components/pages/DemoSettings/DemoSettings'
import WaitingRoomBrandingPreview from './components/pages/WaitingRoom/WaitingRoomBrandingPreview'
import AssessmentPreview from './components/pages/Assessments/AssessmentPreview/AssessmentPreview'
import ConnectivityTest from './components/pages/ConnectivityTest/ConnectivityTest'
import BreakingNewsPreview from './components/pages/BreakingNews/BreakingNewsPreview/BreakingNewsPreview'
import BreakingNewsContainer from './components/pages/BreakingNews/BreakingNewsContainer'

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <></>,
      },
      {
        path: 'summary',
        element: <Summary />,
      },
      {
        path: 'breaking-news',
        element: <BreakingNewsContainer />,
      },
      {
        path: 'peer-insights',
        element: <PeerInsights />,
      },
      {
        path: 'our-financials',
        element: <OurFinancials />,
      },
      {
        path: 'help-manual',
        element: <HelpManual />,
      },
      {
        path: 'exco-decisions',
        element: <ExcoDecisions />,
      },
      {
        path: 'special-projects',
        element: <SpecialProjects />,
      },
      {
        path: 'costs',
        element: <Costs />,
      },
      {
        path: 'businesses',
        element: <Businesses />,
      },
      {
        path: 'treasury',
        element: <Treasury />,
      },
      {
        path: 'deal-room',
        element: <DealRoom />,
      },
      {
        path: 'assessments',
        element: <Assessments />,
      },
      {
        path: 'team',
        element: <Team />,
      },
      {
        path: 'demo-settings',
        element: <DemoSettings />,
      },
      {
        path: 'waiting-room',
        element: <Navigate to="/" replace />,
      },
    ],
  },
  {
    path: '/branding-preview',
    element: <WaitingRoomBrandingPreview />,
  },
  {
    path: '/assessment-preview',
    element: <AssessmentPreview />,
  },
  {
    path: '/news-preview',
    element: <BreakingNewsPreview />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
  {
    path: '/connectivity',
    element: <ConnectivityTest />,
  },
  {
    path: '/connectivity/:externalId',
    element: <ConnectivityTest />,
  },
])

const Router = () => {
  return <RouterProvider router={router} />
}

export default Router
