import React, {
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react'
import {
  Answer,
  CurrentAnswers,
  AssessmentQuestion,
} from '../../../types/gameApi/assessments'
import { useSpringRef, useTransition } from '@react-spring/web'
import { FormProvider, useForm } from 'react-hook-form'
import { usePrevious } from 'react-use'
import { Question } from './Question'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import Button from '../../atoms/Button/Button'

import { Icon } from '../../atoms/Icon/Icon'

type Direction = 'prev' | 'next'

interface Props {
  assessmentId: string
  questions: AssessmentQuestion[]
  currentAnswers: CurrentAnswers
  onSubmit: (answers: Record<string, Answer[]>) => void
  onSubmitLastQuest: () => void
}

interface ReIndexedQuestion {
  question: AssessmentQuestion
  index: number
}

const answerForQuestion = (
  question: AssessmentQuestion,
  answers: CurrentAnswers,
) => {
  return answers.find(answer => answer.questionId === question.id)
}

const isEmptyResponseBeforeTransition = (
  currentQuestion: AssessmentQuestion,
  value: any,
) => {
  if (!currentQuestion.mandatory) {
    return false
  }

  if (
    currentQuestion.type === 'image' ||
    currentQuestion.type === 'written_text'
  ) {
    return false
  }

  if (!value) {
    return true
  }

  if (
    currentQuestion.type === 'written_response' ||
    currentQuestion.type === 'short_written_response'
  ) {
    return typeof value[0].value === 'undefined' || value[0].value.length === 0
  }

  if (Array.isArray(value)) {
    return value.length === 0
  }

  return false
}

export const AnimatedQuestions = ({
  assessmentId,
  questions,
  currentAnswers,
  onSubmit,
  onSubmitLastQuest,
}: Props) => {
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const [isNextBlocked, setBlockNext] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)
  const [currentQuesitonIndex, set] = useState(0)
  const prevQuestionIndex = usePrevious(currentQuesitonIndex)

  const currentQuestionIndexRef = useRef(currentQuesitonIndex)

  useEffect(() => {
    currentQuestionIndexRef.current = currentQuesitonIndex
  }, [currentQuesitonIndex])

  const startAutoSubmitTimer = useCallback(() => {
    const timer = setInterval(async () => {
      const currentQuestion = questions[currentQuestionIndexRef.current]

      if (
        isEmptyResponseBeforeTransition(
          currentQuestion,
          methods.getValues()[currentQuestion.id],
        )
      ) {
        return
      }

      const results = {
        [currentQuestion.id]: methods.getValues()[currentQuestion.id],
      }
      await onSubmit(results)
    }, 5000)

    return timer
  }, [methods, onSubmit, questions])

  useEffect(() => {
    const timer = startAutoSubmitTimer()

    return () => {
      clearInterval(timer)
      const currentQuestion = questions[currentQuestionIndexRef.current]

      if (
        !isEmptyResponseBeforeTransition(
          currentQuestion,
          methods.getValues()[currentQuestion.id],
        )
      ) {
        const results = {
          [currentQuestion.id]: methods.getValues()[currentQuestion.id],
        }
        onSubmit(results)
      }
    }
  }, [startAutoSubmitTimer, methods, onSubmit, questions])

  const onNext = useCallback(
    async (e: any) => {
      e.preventDefault()

      if (isNextBlocked) {
        return
      }

      const currentQuestion = questions[currentQuesitonIndex]

      if (
        isEmptyResponseBeforeTransition(
          currentQuestion,
          methods.getValues()[currentQuestion.id],
        )
      ) {
        methods.setError(currentQuestion.id, {
          message: 'A response is required',
        })
        setBlockNext(true)
        await new Promise(resolve => setTimeout(resolve, 2000))
        setBlockNext(false)
        return
      }

      const results = {
        [currentQuestion.id]: methods.getValues()[currentQuestion.id],
      }
      await onSubmit(results)

      await new Promise(resolve => setTimeout(resolve, 1000))
      set(state => (state + 1 > questions.length - 1 ? state : state + 1))
    },
    [isNextBlocked, questions, currentQuesitonIndex, methods, onSubmit],
  )

  const onSubmitLastQuestion = useCallback(
    (e: any) => {
      onNext(e)
      onSubmitLastQuest()
    },
    [onNext, onSubmitLastQuest],
  )

  const onPrev = useCallback(
    (e: any) => {
      e.preventDefault()

      if (isNextBlocked) {
        return
      }

      set(state => {
        if (state === 0) {
          return state
        }
        return state - 1
      })
    },
    [isNextBlocked],
  )

  const numPrevIndex = Number(prevQuestionIndex)

  const direction: Direction = isNaN(numPrevIndex)
    ? 'next'
    : numPrevIndex < currentQuesitonIndex
    ? 'next'
    : 'prev'

  const transRef = useSpringRef()

  const transitions = useTransition(currentQuesitonIndex, {
    ref: transRef,
    keys: null,
    from: {
      opacity: 0,
      transform:
        direction === 'next'
          ? 'translate3d(0,100%,0)'
          : 'translate3d(0,-100%,0)',
    },
    enter: { opacity: 1, transform: 'translate3d(0,0%,0)' },
    leave: {
      opacity: 0,
      transform:
        direction === 'next' ? 'translate3d(0,-50%,0)' : 'translate3d(0,50%,0)',
    },
    config: {
      duration: 500,
    },
    onRest: () => setIsAnimating(false),
    onStart: () => setIsAnimating(true),
  })

  useLayoutEffect(() => {
    if (isAnimating) {
      document.body.classList.add('disable-scroll')
    } else {
      document.body.classList.remove('disable-scroll')
    }
  }, [isAnimating])

  useEffect(() => {
    transRef.start()
  }, [currentQuesitonIndex, transRef])

  const numberOfNonIndexedQuestions = (
    reIndexedQuetions: ReIndexedQuestion[],
  ) => {
    return reIndexedQuetions.filter(({ question }) =>
      ['image', 'written_text'].includes(question.type),
    ).length
  }

  const reIndexedQuestions = useMemo(() => {
    return questions.reduce((acc: ReIndexedQuestion[], question, index) => {
      return [
        ...acc,
        { question, index: index - numberOfNonIndexedQuestions(acc) },
      ]
    }, [])
  }, [questions])

  return (
    <FormProvider {...methods}>
      <form
        className="container mt-10 flex h-full w-full flex-col flex-wrap items-center justify-center tablet:mt-0"
        onSubmit={e => {
          e.preventDefault()

          if (!methods.formState.isValid) {
            const hasErrorField = questions.some((q, idx) => {
              if (
                methods.formState.errors[q.id] ||
                isEmptyResponseBeforeTransition(q, methods.getValues()[q.id])
              ) {
                set(idx)
                return true
              }
              return false
            })

            if (!hasErrorField) {
              methods.handleSubmit(onSubmit)(e)
            }
            return
          }

          methods.handleSubmit(onSubmit)(e)
        }}
      >
        <div className="w-full px-6 tablet:px-20">
          {transitions((style, i) => {
            const reIndexedQuestion = reIndexedQuestions[i]
            return (
              <Question
                style={style}
                question={reIndexedQuestion.question}
                currentAnswer={answerForQuestion(
                  reIndexedQuestion.question,
                  currentAnswers,
                )}
                questionIndex={reIndexedQuestion.index}
                assessmentId={assessmentId}
                isLastQuestion={i === reIndexedQuestions.length - 1}
                onNextQuestion={onNext}
                onSubmitLastQuestion={onSubmitLastQuestion}
              />
            )
          })}
        </div>
        <nav className="fixed bottom-0 mx-[-1rem] flex w-full justify-center bg-gray-100 p-2 tablet:bottom-4 tablet:right-4 tablet:justify-end tablet:bg-transparent tablet:pr-4">
          <HorizontalGroup gap={2}>
            <Button size="small" onClick={onPrev}>
              <Icon size={10} type="chevronUp" colour="white"></Icon>
            </Button>
            <Button size="small" onClick={onNext}>
              <Icon size={10} type="chevron" colour="white"></Icon>
            </Button>
          </HorizontalGroup>
        </nav>
      </form>
    </FormProvider>
  )
}
