import React, { useCallback, useState } from 'react'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import Text from '../../../atoms/Text/Text'
import { Icon } from '../../../atoms/Icon/Icon'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../../store'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import { startTour } from '../../../../redux/guidedTours/guidedToursSlice'
import SubheadingDropDown from '../Components/SubheadingDropDown'
import { useMenuTranslations } from '../../../../hooks/useMenuTranslations'
import Button from '../../../atoms/Button/Button'
interface Props {}
const SummaryHelp: React.FC<Props> = () => {
  const [expandedSubheading, setExpandedSubheading] = useState('')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const translations = useMenuTranslations()

  const handleSummaryTour = useCallback(() => {
    dispatch(startTour('summary'))
    navigate('/summary')
  }, [dispatch, navigate])

  return (
    <VerticalGroup gap={2}>
      <Text center size="lg">
        {translations.summary} Guide
      </Text>{' '}
      <VerticalGroup gap={6}>
        <HorizontalGroup center>
          <Button
            buttonType="secondary"
            full
            size="xl"
            onClick={handleSummaryTour}
          >
            <Icon type="guide" colour="primary" size={4} />
            <Text colour="primary">Start Walkthrough</Text>
          </Button>
        </HorizontalGroup>
        <VerticalGroup gap={6}>
          <SubheadingDropDown
            title={'KEY METRICS OVERVIEW'}
            content={
              "The Summary Dashboard contains a balanced scorecard of your bank's key performance metrics. This scorecard allows you to quickly assess your current ranking and progress on each metric."
            }
            hint={
              'Pay special attention to the metrics containing the trophy icons. These "winning metrics" are used in the simulation to determine which banks are "winning'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
          <SubheadingDropDown
            title={'METRIC TILE BREAKDOWN'}
            content={
              'Each summary tile contains the following information: Summary Metric Name, Summary Metric Value, Summary Metric Value Change, Competitive Ranking, Competitive Ranking, Change, and Winning Metric indicator (if applicable).'
            }
            hint={
              'Understand your Competitive Ranking in detail by using the Peer Insights. If you are winning, competitors might be very close behind. If you are coming last, it might not take much to dramatically improve your ranking.'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
        </VerticalGroup>
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default SummaryHelp
