import React, { useCallback } from 'react'

import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'
// import { FontSize, FontWeight, TextColour } from '../../../../types/theme'
import Table from '../../../atoms/Table/Table'
import TableHeader from '../../../atoms/Table/TableHeader'
import TableSection from '../../../atoms/Table/TableSection'
import TableRow from '../../../atoms/Table/TableRow'
import TableCell from '../../../atoms/Table/TableCell'
import TableHeaderCell from '../../../atoms/Table/TableHeaderCell'
import TableText from '../../../atoms/Table/TableText'

import {
  Business,
  BusinessInterest,
  BusinessRates,
  BusinessVolume,
  ProductType,
} from '../../../../types/gameApi/business'
import ValueChangerWithPercentageChange from '../../../molecules/ValueChanger/ValueChangerWithPercentageChange'
import { BusinessDecision, DecisionMap } from '../BusinessesContainer'
import { formatPrice1, formatVolume } from '../formatters'
import { TextColour } from '../../../../types/theme'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import TableFooter from '../../../atoms/Table/TableFooter'
import { ItemData, NimAndNII } from './types'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
// import Button from '../../../atoms/Button/Button'
import Page from '../../../atoms/Page/Page'
import { titleCase } from '../../../../lib/text'
import Button from '../../../atoms/Button/Button'

export interface Props {
  data: Business[]
  rates: BusinessRates
  volume: BusinessVolume
  interest: BusinessInterest
  decisions: DecisionMap
  onChangeValue: (
    type: string,
    field: keyof BusinessDecision,
    value: number,
  ) => void
  assetData: ItemData[]
  liabilityData: ItemData[]
  netInterestIncome: NimAndNII
  nim: NimAndNII
  enableChanges: boolean
  setShowModal: (val: boolean) => void
}
export const renderPercentage = (percentage: string, isLiability?: boolean) => {
  const isNegative = percentage.indexOf('-') !== -1
  const isZero = percentage === '0.00%' || percentage === '0.0%'

  const negativeColour: TextColour = isLiability ? 'success' : 'error'
  const positiveColour: TextColour = isLiability ? 'error' : 'success'
  return (
    <SecondaryText
      size="sm"
      colour={isNegative && !isZero ? negativeColour : positiveColour}
    >
      {!isZero && !isNegative ? '+' : ''}
      {percentage}
    </SecondaryText>
  )
}

export const estimateIncome = (
  price: number,
  volumeNow: number,
  volumePrev: number,
  type: ProductType,
) => {
  const averageVolume = (volumeNow + volumePrev) / 2
  if (type === 'CREDIT_CARDS') {
    return (price * averageVolume) / 1000000
  }
  if (type === 'DEPOSITS') {
    return (price / 100 / 100) * averageVolume * -1
  }
  return (price / 100 / 100) * averageVolume
}

interface RowProps {
  onChangeValue: (
    type: string,
    field: keyof BusinessDecision,
    value: number,
  ) => void
  data: ItemData
  enableChanges: boolean
  setShowModal: (val: boolean) => void
  idPrefix?: string
}

const Row: React.FC<RowProps> = ({ data, onChangeValue, enableChanges }) => {
  const handleDecrement = useCallback(
    (priceType: keyof BusinessDecision) => (val: number) => {
      if (data.type) {
        onChangeValue(data.type, priceType, val - 1)
      }
    },
    [onChangeValue, data.type],
  )

  const handleIncrement = useCallback(
    (priceType: keyof BusinessDecision) => (val: number) => {
      if (data.type) {
        onChangeValue(data.type, priceType, val + 1)
      }
    },
    [onChangeValue, data.type],
  )

  const handleDecrementVolume = useCallback(
    (priceType: keyof BusinessDecision) => (val: number) => {
      if (data.type) {
        onChangeValue(
          data.type,
          priceType,
          (data.volume.incrementValue ?? val) - data.volume.prevValue * 0.01,
        )
      }
    },
    [
      onChangeValue,
      data.type,
      data.volume.incrementValue,
      data.volume.prevValue,
    ],
  )

  const handleIncrementVolume = useCallback(
    (priceType: keyof BusinessDecision) => (val: number) => {
      if (data.type) {
        onChangeValue(
          data.type,
          priceType,
          (data.volume.incrementValue ?? val) + data.volume.prevValue * 0.01,
        )
      }
    },
    [
      onChangeValue,
      data.type,
      data.volume.incrementValue,
      data.volume.prevValue,
    ],
  )

  return (
    <TableRow noDivideX className="h-14">
      <TableCell noRightBorder className="text-center">
        <TableText>{titleCase(data.name)}</TableText>
      </TableCell>

      <TableCell align="center">
        <ValueChangerWithPercentageChange
          idPrefix="advanced-price1Now"
          enableChanges={!data.rate.disableChanges && enableChanges}
          value={data.rate.value}
          min={data.rate.min}
          max={data.rate.max}
          formatValue={val => formatPrice1(val)}
          onIncrement={handleIncrement('price1Now')}
          onDecrement={handleDecrement('price1Now')}
          percentageChange={() => {
            return renderPercentage(
              data.rate.percentageChange,
              data.isLiability,
            )
          }}
        />
      </TableCell>
      <TableCell align="center">
        <div className="text-center">
          <ValueChangerWithPercentageChange
            enableChanges={!data.volume.disableChanges && enableChanges}
            value={data.volume.value}
            min={data.volume.min ?? 0}
            formatValue={val => formatVolume(val)}
            percentageChange={() =>
              renderPercentage(data.volume.percentageChange)
            }
            onIncrement={handleIncrementVolume('volumeNow')}
            onDecrement={handleDecrementVolume('volumeNow')}
            idPrefix={`${data.id}-volumeNow`}
          />
        </div>
      </TableCell>
      <TableCell align="center">
        <ValueChangerWithPercentageChange
          enableChanges={false}
          value={data.interest.value}
          formatValue={val => formatVolume(val)}
          percentageChange={() =>
            renderPercentage(data.interest.percentageChange, data.isLiability)
          }
        />
      </TableCell>
    </TableRow>
  )
}

const BusinessAdvancedModeTable: React.FC<Props> = ({
  assetData,
  liabilityData,
  nim,
  netInterestIncome,
  onChangeValue,
  enableChanges,
  setShowModal,
}) => {
  return (
    <Page className="w-full">
      <Table data-test="advanced-table" className="whitespace-nowrap">
        <TableHeader noDivideX>
          <TableHeaderCell align="left">
            <TranslatedText
              weight="bold"
              value="businessDecisions.screen3.table1Column1Header"
              default="ASSETS"
              family="secondary"
              size="xs"
              transform="upper"
            />
          </TableHeaderCell>
          <TableHeaderCell>
            <TranslatedText
              weight="bold"
              value="businessDecisions.screen3.table1Column2Header"
              default="RATE"
              family="secondary"
              size="xs"
              transform="upper"
              center
            />
          </TableHeaderCell>
          <TableHeaderCell>
            <HorizontalGroup gap={3} reverseRow verticalCenter>
              {enableChanges && (
                <Button
                  data-test="reset-advanced"
                  size="xs"
                  rounded="full"
                  buttonType="bordered"
                  iconType="reset"
                  onClick={() => setShowModal(true)}
                ></Button>
              )}
              <TableText weight="bold">
                <TranslatedText
                  weight="bold"
                  value="businessDecisions.screen3.table1Column3Header"
                  default="FORECAST VOLUME"
                  family="secondary"
                  size="xs"
                  transform="upper"
                  center
                />
              </TableText>
            </HorizontalGroup>
          </TableHeaderCell>
          <TableHeaderCell>
            <TranslatedText
              whitespace="wrap"
              weight="bold"
              value="businessDecisions.screen3.table1Column4Header"
              default="FORECAST INTEREST"
              family="secondary"
              size="xs"
              transform="upper"
              center
            />
          </TableHeaderCell>
        </TableHeader>
        <TableSection>
          {assetData.map((ass, i) => (
            <Row
              key={ass.name + i}
              data={ass}
              onChangeValue={onChangeValue}
              enableChanges={enableChanges}
              setShowModal={setShowModal}
              idPrefix={`${ass.id}-volumeNow`}
            />
          ))}
        </TableSection>
        <TableHeader>
          <TableHeaderCell align="left" colSpan={4}>
            <TranslatedText
              weight="bold"
              value="businessDecisions.screen3.table2Column1Header"
              default="LIABILITIES"
              family="secondary"
              size="xs"
              transform="upper"
            />
          </TableHeaderCell>
        </TableHeader>
        <TableSection>
          {liabilityData.map((liab, i) => (
            <Row
              key={liab.name + i}
              data={liab}
              onChangeValue={onChangeValue}
              enableChanges={enableChanges}
              setShowModal={setShowModal}
              idPrefix={`${liab.id}-volumeNow`}
            />
          ))}
        </TableSection>
        <TableSection>
          <TableFooter noDivideX>
            <TableText size="xs" whitespace="wrap" weight="bold">
              <TableCell noRightBorder align="left">
                NET INTEREST MARGIN
              </TableCell>
            </TableText>

            <TableCell align="center">
              <ValueChangerWithPercentageChange
                idPrefix="advanced-nim"
                enableChanges={false}
                value={nim.now}
                formatValue={val => formatPrice1(val)}
                percentageChange={() => {
                  return renderPercentage(nim.change)
                }}
              />
            </TableCell>

            <TableCell align="center">
              <TableText size="xs" weight="bold" whitespace="wrap">
                NET INTEREST INCOME
              </TableText>
            </TableCell>

            <TableCell align="center">
              <TableText size="xs" whitespace="wrap">
                <ValueChangerWithPercentageChange
                  enableChanges={false}
                  value={netInterestIncome.now}
                  formatValue={val => formatVolume(val)}
                  percentageChange={() => {
                    return renderPercentage(netInterestIncome.change)
                  }}
                />
              </TableText>
            </TableCell>
          </TableFooter>
        </TableSection>
      </Table>
    </Page>
  )
}

export default BusinessAdvancedModeTable
