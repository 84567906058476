import React, { PropsWithChildren } from 'react'
import { ChartProps } from '../SummaryMetricMapper'
import { percentage } from '../../../../../lib/formatters'
import StackedBarChart from './StackedBarChart'

const CommunityTrustChart: React.FC<PropsWithChildren<ChartProps>> = ({
  children,
  ...rest
}) => {
  return (
    <StackedBarChart
      header={children}
      formatter={val => percentage(val, 0)}
      legend={[]}
      showTotal
      ignoreDiff
      {...rest}
    />
  )
}

export default CommunityTrustChart
