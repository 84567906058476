import { createSlice } from '@reduxjs/toolkit'
import { fetchExco, fetchExcoBackground, submitExco } from './excoActions'
import APIError from '../../errors/APIError'
import { ExcoResponse } from '../../types/gameApi/exco'
import { reset } from '../game/gameSlice'
import { logout } from '../auth/authSlice'
import ThunkCancelledError from '../../errors/ThunkCancelledError'

interface ExcoState {
  isLoading: boolean
  isLoaded: boolean
  isLoadingBackground: boolean
  exco: {
    [roundId: number]: ExcoResponse
  }
  error: Error | APIError | null
  submitError: Error | APIError | null
  isSubmitting: boolean
  submittingOption: number | null
  requestInProgress: string | null
}

const initialState: ExcoState = {
  isLoading: false,
  isLoaded: false,
  isLoadingBackground: false,
  exco: {},
  error: null,
  submitError: null,
  isSubmitting: false,
  submittingOption: null,
  requestInProgress: null,
}

const excoSlice = createSlice({
  name: 'exco',
  initialState,
  reducers: {
    clearError: state => {
      state.submitError = null
    },
  },
  extraReducers: builder => {
    builder
      .addCase(reset, () => {
        return initialState
      })
      .addCase(logout, () => {
        return initialState
      })
      .addCase(fetchExco.pending, (state, { meta }) => {
        if (!state.isLoading) {
          state.requestInProgress = meta.requestId
          state.isLoading = true
          state.error = null
        }
      })
      .addCase(fetchExco.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isLoaded = true
        state.error = null
        state.exco[payload.roundId] = payload.exco
        state.requestInProgress = null
      })
      .addCase(fetchExco.rejected, (state, { payload }) => {
        if (payload instanceof ThunkCancelledError) {
          return
        }
        state.isLoading = false
        state.error = payload ?? null
        state.requestInProgress = null
      })
      .addCase(submitExco.pending, (state, { meta }) => {
        state.isSubmitting = true
        state.submittingOption = meta.arg.decision
        state.submitError = null
      })
      .addCase(submitExco.fulfilled, (state, { payload }) => {
        state.isSubmitting = false
        state.submitError = null
        state.exco[payload.roundId].decision = payload.decision
        state.submittingOption = null
      })
      .addCase(submitExco.rejected, (state, { payload }) => {
        state.isSubmitting = false
        state.submitError = payload ?? null
        // state.submittingOption = null
      })
      .addCase(fetchExcoBackground.pending, (state, { meta }) => {
        if (!state.isLoading && !state.isLoadingBackground) {
          state.requestInProgress = meta.requestId
          state.isLoadingBackground = true
        }
      })
      .addCase(fetchExcoBackground.fulfilled, (state, { payload }) => {
        state.isLoadingBackground = false
        if (process.env.REACT_APP_DEMO_MODE !== 'true') {
          state.exco[payload.roundId] = payload.exco
        }
        state.requestInProgress = null
        state.error = null
      })
      .addCase(fetchExcoBackground.rejected, (state, { payload }) => {
        if (payload instanceof ThunkCancelledError) {
          return
        }
        state.isLoadingBackground = false
        state.requestInProgress = null
      })
  },
})

export const { clearError } = excoSlice.actions

export default excoSlice.reducer
