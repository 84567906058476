import { CostsResponse } from '../../../../../types/gameApi/costs'
import { data as round1Data } from './1'
import { data as round2Data } from './2'
import { data as round3Data } from './3'
import { data as round4Data } from './4'

const costsMockData: { [roundId: number]: CostsResponse } = {
  1: round1Data,
  2: round2Data,
  3: round3Data,
  4: round4Data,
}

export const getCostsData = (roundId: number): CostsResponse => {
  if (costsMockData[roundId]) {
    return costsMockData[roundId]
  }
  throw new Error(`No mock costs data for round ${roundId}`)
}
