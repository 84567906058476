import React, { CSSProperties } from 'react'
import { animated, AnimatedProps } from '@react-spring/web'
import { MultipleChoice } from './MultipleChoice'
import { Checkboxes } from './Checkboxes'
import { ImageQuestion } from './ImageQuestion'
import { WrittenText } from './WrittenText'
import { WrittenResponse } from './WrittenResponse'
import { ShortWrittenResponse } from './ShortWrittenResponse'
import { Rating } from './Rating'
import {
  AssessmentQuestion,
  CurrentAnswer,
} from '../../../../types/gameApi/assessments'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import Button from '../../../atoms/Button/Button'

type QuestionProps = AnimatedProps<{
  style: CSSProperties
  assessmentId: string
  question: AssessmentQuestion
  currentAnswer?: CurrentAnswer
  questionIndex: number
  isLastQuestion: boolean
  onNextQuestion: (e: any) => void
  onSubmitLastQuestion: (e: any) => void
}>

export const Question = ({
  style,
  assessmentId,
  question,
  questionIndex,
  isLastQuestion,
  currentAnswer,
  onNextQuestion,
  onSubmitLastQuestion,
}: QuestionProps) => {
  return (
    <animated.div
      className="will-change-opacity text-shadow flex h-full w-full items-center justify-center will-change-transform"
      style={{ ...style }}
    >
      <VerticalGroup
        between
        gap={5}
        className="w-full pb-1 tablet:min-h-[340px] tablet:w-[800px] tablet:gap-5"
      >
        {question.type === 'multiple_choices' && (
          <MultipleChoice
            currentAnswer={currentAnswer}
            questionIndex={questionIndex as number}
            question={question}
          />
        )}
        {question.type === 'image' && (
          <ImageQuestion
            question={question}
            assessmentId={assessmentId as string}
          />
        )}
        {question.type === 'checkboxes' && (
          <Checkboxes
            questionIndex={questionIndex as number}
            currentAnswer={currentAnswer}
            question={question}
            onNext={onNextQuestion}
          />
        )}
        {question.type === 'rating' && (
          <Rating
            question={question}
            questionIndex={questionIndex as number}
            currentAnswer={currentAnswer}
          />
        )}
        {question.type === 'written_response' && (
          <WrittenResponse
            question={question}
            questionIndex={questionIndex as number}
            currentAnswer={currentAnswer}
          />
        )}
        {question.type === 'short_written_response' && (
          <ShortWrittenResponse
            question={question}
            questionIndex={questionIndex as number}
            currentAnswer={currentAnswer}
          />
        )}
        {question.type === 'written_text' && (
          <WrittenText question={question} />
        )}

        {!isLastQuestion && <Button onClick={onNextQuestion}>OK</Button>}

        {isLastQuestion && (
          <Button onClick={onSubmitLastQuestion}>Submit</Button>
        )}
      </VerticalGroup>
    </animated.div>
  )
}
