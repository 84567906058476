import React from 'react'
import { FinancialsResponse } from '../../../../types/gameApi/financials'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import ProfitAndLossMetrics from './ProfitAndLossMetrics'
import ProfitAndLossTable from './ProfitAndLossTable'

interface Props {
  financials: FinancialsResponse
  roundId: number
}

const ProfitAndLoss: React.FC<Props> = ({ financials, roundId }) => {
  return (
    <HorizontalGroup
      hidden
      fullWidth
      gap={4}
      className="flex flex-col-reverse gap-4 desktop:h-full desktop:flex-row"
    >
      <HorizontalGroup
        data-test="profit-table"
        className="flex-grow tablet:w-4/6"
      >
        <ProfitAndLossTable financials={financials} roundId={roundId} />
      </HorizontalGroup>
      <HorizontalGroup data-test="metrics" className="flex-grow">
        <ProfitAndLossMetrics financials={financials} />
      </HorizontalGroup>
    </HorizontalGroup>
  )
}

export default ProfitAndLoss
