import React, { useCallback, useState } from 'react'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import Text from '../../../atoms/Text/Text'
import { Icon } from '../../../atoms/Icon/Icon'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../../store'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import { startTour } from '../../../../redux/guidedTours/guidedToursSlice'
import SubheadingDropDown from '../Components/SubheadingDropDown'
import { changeTab } from '../../../../redux/peer-insights/peerInsightsSlice'
import { useMenuTranslations } from '../../../../hooks/useMenuTranslations'
import Button from '../../../atoms/Button/Button'
interface Props {}
const PeerInsightsHelp: React.FC<Props> = () => {
  const [expandedSubheading, setExpandedSubheading] = useState('')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const translations = useMenuTranslations()

  const handlePeerInsightsTour = useCallback(() => {
    dispatch(startTour('peer-insights'))
    dispatch(changeTab('market-share'))
    navigate('/peer-insights')
  }, [dispatch, navigate])

  return (
    <VerticalGroup gap={2}>
      <Text center size="lg">
        {translations.peerInsights} Guide
      </Text>{' '}
      <VerticalGroup gap={6}>
        <HorizontalGroup center>
          <Button
            buttonType="secondary"
            full
            size="xl"
            onClick={handlePeerInsightsTour}
          >
            <Icon type="guide" colour="primary" size={4} />
            <Text colour="primary">Start Walkthrough</Text>
          </Button>
        </HorizontalGroup>
        <VerticalGroup gap={6}>
          <SubheadingDropDown
            title={'MARKET SHARE & PRICING SUMMARY'}
            content={
              'This screen provides an overview of the market competition for each product. Here you can find information for each bank including: Product Balances, Market Share, Pricing, and Profit. You can use this information to partially understand why each bank is gaining or losing market share.'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
          <SubheadingDropDown
            title={'SUMMARY METRICS'}
            content={
              "This dashboard shows your bank's Summary Performance Metrics compared to your competitors. This allows you to understand your competitive position relative to that of your competitors."
            }
            hint={
              'Focus not only on improving under-performing winning metrics, but also ensure you maintain focus on winning metrics that you are performing well on.'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
        </VerticalGroup>
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default PeerInsightsHelp
