/* eslint-disable react/jsx-key */
import React from 'react'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'

import {
  argRange,
  Args,
  ArgsWithBid,
  DealRoomMathConfig,
  formatDollar,
  PlayerDeterminedText,
} from './utils'

const ARG_X_MIN = 5
const ARG_X_MAX = 15
const ARG_X_STEP = 1
const ARG_Y_MIN = 8
const ARG_Y_MAX = 15
const ARG_Y_STEP = 0.5

function calculateBalmainBank(input: Args): {
  result: string
  results: string[]
}
function calculateBalmainBank(input: ArgsWithBid): {
  result: string
  estimatedReturn: number
  results: string[]
}
function calculateBalmainBank(input: ArgsWithBid | Args) {
  const { getAssumptions } = balmainBank
  const assumptions = getAssumptions(input).map(a => a.value)

  const Step1 = assumptions[1]
  const Step2 = (Step1 * assumptions[2]) / 100
  const Step3 = (Step2 * assumptions[3]) / 100
  const Step4 = Step2 + Step3
  const Step5 = Step4 * (1 - assumptions[5] / 100)
  const Step6 = Step5 * (1 - assumptions[6] / 100)
  const Step7 = Step6 * (1 + assumptions[7] / 100)
  const Step8 = (Step7 * assumptions[8]) / 100
  const Step9 = Step8 / ((assumptions[9] - 3) / 100)
  const Step10 = Step9 * (assumptions[10] / 100)

  const results = [
    formatDollar(Step1),
    formatDollar(Step2),
    formatDollar(Step3),
    formatDollar(Step4),
    formatDollar(Step5),
    formatDollar(Step6),
    formatDollar(Step7),
    formatDollar(Step8),
    formatDollar(Step9),
    formatDollar(Step10),
  ]

  if ('bid' in input) {
    const { bid } = input
    const estimatedReturn = (Step8 * assumptions[10]) / 100 / bid + 3 / 100
    return { results, result: Step10.toFixed(0), estimatedReturn }
  }

  return { results, result: Step10.toFixed(0) }
}

export const balmainBank: DealRoomMathConfig = {
  defaultArgX: 15,
  defaultArgY: 11,
  calculate: calculateBalmainBank,
  presetX: argRange(ARG_X_MIN, ARG_X_MAX, ARG_X_STEP),
  presetY: argRange(ARG_Y_MIN, ARG_Y_MAX, ARG_Y_STEP),
  titleX: 'ESTIMATED SYNERGIES ACHIEVED',
  titleY: 'REQUIRED RATE OF RETURN %',
  header1: 'Valuation Range: Synergies vs. Required Returns',
  header2: 'BALMAIN BANK BID CALCULATOR',
  getAssumptions({ argX, argY, assumptions }) {
    return [
      {
        label: '',
        denomination: '',
        value: assumptions?.[0]?.value ?? NaN,
        numberFormat: 'integer',
      },
      {
        label: 'Forecast Lending Assets:',
        denomination: 'm',
        value: assumptions?.[1]?.value ?? 100000,
        numberFormat: 'integer',
      },
      {
        label: 'Net Interest Margin:',
        denomination: '%',
        value: assumptions?.[2]?.value ?? 1.75,
        numberFormat: 'decimal',
      },
      {
        label: 'Other Banking Income:',
        denomination: '%',
        value: assumptions?.[3]?.value ?? 15,
        numberFormat: 'integer',
      },
      {
        label: '',
        denomination: '',
        value: assumptions?.[4]?.value ?? NaN,
        numberFormat: 'integer',
      },
      {
        label: 'Cost To Income Ratio:',
        denomination: '%',
        value: assumptions?.[5]?.value ?? 40,
        numberFormat: 'integer',
      },
      {
        label: 'Tax Rate:',
        denomination: '%',
        value: assumptions?.[6]?.value ?? 30,
        numberFormat: 'integer',
      },
      {
        label: 'Synergies (% of NPAT):',
        range: [ARG_X_MIN, ARG_X_MAX],
        denomination: '%',
        value: argX ?? assumptions?.[7]?.value ?? this.defaultArgX,
        numberFormat: 'integer',
        isKeyAssumption1: true,
      },
      {
        label: 'Dividend Payout Ratio:',
        denomination: '%',
        value: assumptions?.[8]?.value ?? 80,
        numberFormat: 'integer',
      },
      {
        label: 'Discount Rate:',
        range: [ARG_Y_MIN, ARG_Y_MAX],
        denomination: '%',
        value: argY ?? assumptions?.[9]?.value ?? this.defaultArgY,
        numberFormat: 'decimal',
        isKeyAssumption2: true,
      },
      {
        label: 'Stake Acquired:',
        denomination: '%',
        value: assumptions?.[10]?.value ?? 45,
        numberFormat: 'integer',
      },
    ]
  },
  getFormulas(input) {
    const { results } = this.calculate(input)
    const assumptions = (input.assumptions ?? this.getAssumptions(input)).map(
      a => a.value,
    )
    results.unshift('') // Insert an '' at the beginning so that the index aligns
    return [
      <p>{formatDollar(assumptions[1])}</p>,
      <p>
        {results[1]} x {assumptions[2]}% = {results[2]}
      </p>,
      <p>
        {results[2]} x {assumptions[3]}% = {results[3]}
      </p>,
      <p>
        {results[2]} + {results[3]} = {results[4]}
      </p>,
      <p>
        {results[4]} x (1 - {assumptions[5]}%) = {results[5]}
      </p>,
      <p>
        {results[5]} x (1 - {assumptions[6]}%) = {results[6]}
      </p>,
      <p>
        {results[6]} x (1 +{' '}
        <PlayerDeterminedText>{assumptions[7]}%</PlayerDeterminedText>) ={' '}
        {results[7]}
      </p>,
      <p>
        {results[7]} x {assumptions[8]}% = {results[8]}
      </p>,
      <p>
        {results[8]} / (
        <PlayerDeterminedText>{assumptions[9]}%</PlayerDeterminedText> - 3%) ={' '}
        {results[9]}
      </p>,
      <p>
        {results[9]} x {assumptions[10]}% = {results[10]}
      </p>,
      <p>
        <strong>{results[10]}</strong>
      </p>,
    ]
  },
  explanations: [
    { text: 'Lending Assets' },
    {
      text: 'Net Interest Income',
      subtext: 'Lending Assets x NIM%',
    },
    { text: 'Other Banking Income' },
    { text: 'Total Revenue' },
    {
      text: 'Calculate Net Profit Before Tax',
      subtext: 'Revenue - Operating Expenses',
    },
    {
      text: 'Calculate Net Profit After Tax (NPAT)',
      subtext: 'Net Profit Before Tax x (1 - Tax Rate)',
    },
    {
      text: 'Calculate NPAT Including Synergies',
      subtext: (
        <>
          NPAT x (1 +{' '}
          <PlayerDeterminedText>Estimated Synergies*</PlayerDeterminedText>)
        </>
      ),
    },
    {
      text: 'Calculate Dividend Payable (Forcast)',
    },
    {
      text: 'Calculate Value Of 100% Of The Company Today',
      subtext: (
        <>
          Forcast Dividend / (
          <PlayerDeterminedText>Discount Rate*</PlayerDeterminedText> - Growth
          Rate)
        </>
      ),
    },
    {
      text: 'Calculate Value Of Stake Required',
      subtext: 'Company Value x Stake Acquired',
    },
  ],
  tips: [
    'Decide The Amount Of Synergies That Will Be Achieved?',
    'Determine The Appropriate Level Of Return Required For The Company (12% In Example)',
  ],
  latestFinancials: [
    {
      key: 'Lending Assets',
      value: '100,000m',
    },
    {
      key: 'Net Interest Margin',
      value: '1.75%',
    },
    {
      key: 'Other Banking Income',
      value: '15% of Nll',
    },
    {
      key: 'Cost To Income Ratio',
      value: '40%',
    },
    {
      key: 'Tax Rate',
      value: '30%',
    },
    {
      key: 'Synergies',
      value: '5-20%',
    },
    {
      key: 'Dividend Payout Ratio',
      value: '80%',
    },
    {
      key: 'Average Growth Rate',
      value: '3%',
    },
    {
      key: 'Stake',
      value: '45%',
    },
  ],
  valuation: [
    <HorizontalGroup verticalCenter gap={8} fullWidth>
      <SecondaryText>Value =</SecondaryText>
      <VerticalGroup center>
        <SecondaryText>Next Year&apos;s Dividend</SecondaryText>
        <hr />
        <SecondaryText>Required Return - Growth Rate</SecondaryText>
      </VerticalGroup>
    </HorizontalGroup>,
  ],
}
