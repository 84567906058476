import React, { Fragment, useMemo } from 'react'
import BarChart, {
  createLegend,
} from '../../../organisms/Charts/BarChart/BarChart'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'

import classNames from 'classnames'
import { Bar, LabelList, ReferenceLine } from 'recharts'
import { colours } from '../../../../constants/colours'
import { useTranslatedText } from '../../../../hooks/useTranslatedText'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import TreasuryBalanceSheetChartCalc from './TreasuryBalanceSheetChartCalc'
import Card from '../../../atoms/Card/Card'
import { thousandSeparator } from '../../../../lib/formatters'
import { titleCase } from '../../../../lib/text'

interface Props {
  allOtherAssetsForecast: number
  cashForecastWithAutoWSF: number
  wholesaleFundingForecast: number
  depositsForecast: number
  shareholdersFundsForecast: number
  forecastEirAssetPercentage: number
  forecastEirAssetPercentageChange: number
  forecastEirMargin: number
  marginChange: number
  forecastEirCostPercentage: number
  forecastEirCostPercentageChange: number
}

const AssetsLabel = (props: any) => {
  const {
    x,
    y,
    width,
    height,
    value: { cash, allOtherAssets, bar },
  } = props
  if (bar !== 'cash' && bar !== 'allOtherAssets') {
    return null
  }
  if (cash === 0 && allOtherAssets === 0) {
    return null
  }
  const threshold = 0.1
  const cashAsPercentageOfTotalAssets = cash / (cash + allOtherAssets)
  const cashDrawnOnAssetsBar = cashAsPercentageOfTotalAssets < threshold / 2

  return (
    <g>
      {cashDrawnOnAssetsBar && bar == 'allOtherAssets' && (
        <text
          id="tour-cash"
          x={x + width / 2}
          y={y + height - 14}
          fill="#fff"
          textAnchor="middle"
          fontSize={12}
          fontFamily="Open Sans"
        >
          <tspan>{'Cash '}</tspan>
          <tspan fontWeight="bold">{thousandSeparator(cash)}</tspan>
        </text>
      )}
      {!cashDrawnOnAssetsBar && bar == 'cash' && (
        <text
          id="tour-cash"
          x={x + width / 2}
          y={y + 5 + height / 2}
          fill="#fff"
          textAnchor="middle"
          fontSize={12}
          fontFamily="Open Sans"
        >
          <tspan>{'Cash '}</tspan>
          <tspan data-test="cash-forecast" fontWeight="bold">
            {thousandSeparator(cash)}
          </tspan>
        </text>
      )}
      {bar === 'allOtherAssets' && (
        <text
          x={x + width / 2}
          y={y + height / 2}
          fill="#fff"
          textAnchor="middle"
          fontSize={12}
          fontFamily="Open Sans"
        >
          <tspan>{'All Other Assets '}</tspan>
          <tspan
            data-test="all-other-forecast"
            x={x + width / 2}
            fontWeight="bold"
            dy={16}
          >
            {thousandSeparator(allOtherAssets)}
          </tspan>
        </text>
      )}
    </g>
  )
}

const LiabilitiesLabel = (props: any) => {
  const depositsLabel = useTranslatedText(
    'businessDecisions.products.DEPOSITS',
    'deposits',
  )
  const {
    x,
    y,
    width,
    height,
    value: { shareholdersFunds, deposits, wholesaleFunding, bar },
  } = props
  if (bar === 'cash' || bar === 'allOtherAssets') {
    return null
  }

  if (shareholdersFunds === 0 && deposits === 0 && wholesaleFunding === 0) {
    return null
  }

  const threshold = 0.1
  const shFundsAsPercentageOfTotalLiabilities =
    shareholdersFunds / (shareholdersFunds + deposits + wholesaleFunding)
  const shDrawnOnDepositsBar =
    shFundsAsPercentageOfTotalLiabilities < threshold / 2

  return (
    <g>
      {shDrawnOnDepositsBar && bar == 'deposits' && (
        <text
          x={x + width / 2}
          y={y + height - 14}
          fill="#fff"
          textAnchor="middle"
          fontSize={12}
          fontFamily="Open Sans"
        >
          <tspan>{'SH Funds '}</tspan>
          <tspan fontWeight="bold">
            {thousandSeparator(shareholdersFunds)}
          </tspan>
        </text>
      )}
      {!shDrawnOnDepositsBar && bar == 'shareholdersFunds' && (
        <text
          x={x + width / 2}
          y={y + 5 + height / 2}
          fill="#fff"
          textAnchor="middle"
          fontSize={12}
          fontFamily="Open Sans"
        >
          <tspan>{'SH Funds '}</tspan>
          <tspan data-test="shareholders-Funds" fontWeight="bold">
            {thousandSeparator(shareholdersFunds)}
          </tspan>
        </text>
      )}
      {bar === 'deposits' && (
        <text
          x={x + width / 2}
          y={y + height / 2}
          fill="#fff"
          textAnchor="middle"
          fontSize={12}
          fontFamily="Open Sans"
        >
          <tspan>{titleCase(depositsLabel)}</tspan>
          <tspan
            data-test="deposits"
            x={x + width / 2}
            fontWeight="bold"
            dy={16}
          >
            {thousandSeparator(deposits)}
          </tspan>
        </text>
      )}
      {bar === 'wholesaleFunding' && (
        <text
          x={x + width / 2}
          y={y + height / 2}
          fill="#fff"
          textAnchor="middle"
          fontSize={12}
          fontFamily="Open Sans"
        >
          <tspan>{'WS Funding '}</tspan>
          <tspan
            data-test="wholesale-Funding"
            x={x + width / 2}
            fontWeight="bold"
            dy={16}
          >
            {thousandSeparator(wholesaleFunding)}
          </tspan>
        </text>
      )}
    </g>
  )
}

const TreasuryBalanceSheetChart: React.FC<Props> = ({
  allOtherAssetsForecast,
  cashForecastWithAutoWSF,
  wholesaleFundingForecast,
  depositsForecast,
  shareholdersFundsForecast,
  forecastEirAssetPercentage,
  forecastEirAssetPercentageChange,
  forecastEirMargin,
  marginChange,
  forecastEirCostPercentage,
  forecastEirCostPercentageChange,
}) => {
  const assetsLabel = useTranslatedText(
    'treasury.screen1.chartBar1Label',
    'ASSETS',
  )
  const fundingLabel = useTranslatedText(
    'treasury.screen1.chartBar2Label',
    'FUNDING',
  )
  const chartData = useMemo(() => {
    const data = [
      {
        name: assetsLabel,
        allOtherAssets: allOtherAssetsForecast,
        cash: cashForecastWithAutoWSF,
        wholesaleFunding: 0,
        deposits: 0,
        shareholdersFunds: 0,
        total: cashForecastWithAutoWSF + allOtherAssetsForecast,
      },
      {
        name: fundingLabel,
        allOtherAssets: 0,
        cash: 0,
        wholesaleFunding: wholesaleFundingForecast,
        deposits: depositsForecast,
        shareholdersFunds: parseInt(shareholdersFundsForecast.toFixed(0)),
        total: 0,
      },
    ]

    const yaxisInterval = 25000
    const actualMaxValue = Math.max(
      allOtherAssetsForecast + cashForecastWithAutoWSF,
      wholesaleFundingForecast + depositsForecast + shareholdersFundsForecast,
    )
    const maxValue =
      Math.ceil(Math.abs(actualMaxValue) / yaxisInterval) * yaxisInterval

    return {
      data,
      minValue: 0,
      maxValue,
      numberOfTicks: maxValue / yaxisInterval + 1,
    }
  }, [
    allOtherAssetsForecast,
    assetsLabel,
    cashForecastWithAutoWSF,
    depositsForecast,
    fundingLabel,
    shareholdersFundsForecast,
    wholesaleFundingForecast,
  ])

  const barsComponent = (
    <Fragment>
      <Bar
        dataKey="cash"
        stackId="a"
        fill={colours.pink}
        isAnimationActive={false}
      >
        <LabelList position="bottom" content={createLegend()}></LabelList>
        <LabelList
          position="middle"
          content={AssetsLabel}
          valueAccessor={(data: any) => ({
            cash: data.payload.cash,
            allOtherAssets: data.payload.allOtherAssets,
            bar: 'cash',
          })}
        ></LabelList>
      </Bar>
      <Bar
        dataKey="allOtherAssets"
        stackId="a"
        fill={colours.orange}
        isAnimationActive={false}
      >
        <LabelList
          position="middle"
          content={AssetsLabel}
          valueAccessor={(data: any) => ({
            cash: data.payload.cash,
            allOtherAssets: data.payload.allOtherAssets,
            bar: 'allOtherAssets',
          })}
        ></LabelList>
      </Bar>
      <Bar
        dataKey="shareholdersFunds"
        stackId="a"
        fill={colours.green}
        isAnimationActive={false}
      >
        <LabelList
          position="middle"
          content={LiabilitiesLabel}
          valueAccessor={(data: any) => ({
            shareholdersFunds: data.payload.shareholdersFunds,
            deposits: data.payload.deposits,
            wholesaleFunding: data.payload.wholesaleFunding,
            bar: 'shareholdersFunds',
          })}
        ></LabelList>
      </Bar>

      <Bar
        dataKey="deposits"
        stackId="a"
        fill={colours.purple}
        isAnimationActive={false}
      >
        <LabelList
          position="middle"
          content={LiabilitiesLabel}
          valueAccessor={(data: any) => ({
            shareholdersFunds: data.payload.shareholdersFunds,
            deposits: data.payload.deposits,
            wholesaleFunding: data.payload.wholesaleFunding,
            bar: 'deposits',
          })}
        ></LabelList>
      </Bar>
      <Bar
        dataKey="wholesaleFunding"
        stackId="a"
        fill={colours.primary}
        isAnimationActive={false}
      >
        <LabelList
          position="middle"
          content={LiabilitiesLabel}
          valueAccessor={(data: any) => ({
            shareholdersFunds: data.payload.shareholdersFunds,
            deposits: data.payload.deposits,
            wholesaleFunding: data.payload.wholesaleFunding,
            bar: 'wholesaleFunding',
          })}
        ></LabelList>
      </Bar>
    </Fragment>
  )

  return (
    <Card className="h-full p-3">
      <VerticalGroup fullWidth className="h-full p-2">
        <TranslatedText
          id="tour-forecast"
          weight="bold"
          center
          value="treasury.screen1.chartHeader"
          default="FORECAST ASSETS & FUNDING"
        />
        <VerticalGroup
          fullWidth
          className={classNames('flex h-96 tablet:h-full tablet:grow')}
        >
          <BarChart
            barsComponent={barsComponent}
            data={chartData.data}
            minYAxisvalue={chartData.minValue}
            maxYAxisvalue={chartData.maxValue}
            numberOfTicks={chartData.numberOfTicks}
            hideYAxis
            hideGrid
            xPadding={1}
            barCategoryGap={20}
            margin={{
              left: 0,
              top: 20,
              right: 0,
              bottom: 40,
            }}
          >
            <ReferenceLine
              y={cashForecastWithAutoWSF + allOtherAssetsForecast}
              stroke="#D70005"
              strokeDasharray="4 8"
              strokeWidth={2}
            />
          </BarChart>
        </VerticalGroup>
        <HorizontalGroup fullWidth around className="px-12">
          <VerticalGroup center verticalCenter>
            <TreasuryBalanceSheetChartCalc
              data-test="asset-yield"
              heading="treasury.screen1.impact1Label"
              headingDefault="Asset Yield"
              value={forecastEirAssetPercentage}
              valueChange={forecastEirAssetPercentageChange}
            />
          </VerticalGroup>
          <div>
            <TreasuryBalanceSheetChartCalc
              data-test="margin"
              heading="treasury.screen1.impact2Label"
              headingDefault="Margin"
              value={forecastEirMargin}
              valueChange={marginChange}
            />
          </div>
          <div>
            <TreasuryBalanceSheetChartCalc
              data-test="funding-cost"
              heading="treasury.screen1.impact3Label"
              headingDefault="Funding Cost"
              value={forecastEirCostPercentage}
              valueChange={forecastEirCostPercentageChange}
              isCost
            />
          </div>
        </HorizontalGroup>
      </VerticalGroup>
    </Card>
  )
}

export default TreasuryBalanceSheetChart
