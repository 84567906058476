import React, { ReactNode, useCallback, useState } from 'react'
import { Icon, IconType } from '../Icon/Icon'
import Modal from '../Modal/Modal'
import HtmlText from '../Text/HtmlText'
import Clickable from '../Clickable/Clickable'
import { Colour, Spacing } from '../../../types/theme'
import classNames from 'classnames'
import {
  InfoClickEvent,
  addInfoClickEvent,
} from '../../../lib/googleTagManager'

interface InfoPopupProps {
  className?: string
  title: string
  body: string | ReactNode
  iconColour?: Colour
  iconSize?: Spacing
  iconType?: IconType
  analyticsParams?: InfoClickEvent
  id?: string
}

const InfoPopup: React.FC<InfoPopupProps> = ({
  className,
  title,
  body,
  iconColour = 'black',
  iconSize,
  iconType,
  analyticsParams,
  id,
}) => {
  const [showModal, setShowModal] = useState(false)

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      setShowModal(true)
      if (analyticsParams) {
        addInfoClickEvent(analyticsParams)
      }
    },
    [analyticsParams],
  )

  return (
    <>
      <Clickable onClick={handleClick}>
        <Icon
          id={id}
          className={classNames(className)}
          type={iconType || 'info'}
          colour={iconColour}
          size={iconSize ?? 4}
        />
      </Clickable>
      <Modal
        show={showModal}
        title={title}
        onClose={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        cancelButtonText="Close"
        preventDefault={true}
      >
        <div className="pt-4">
          {typeof body === 'string' && <HtmlText size="sm">{body}</HtmlText>}
          {typeof body !== 'string' && body}
        </div>
      </Modal>
    </>
  )
}

export default InfoPopup
