import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import APIError from '../../errors/APIError'
import { initialise } from '../common/commonActions'
import { userLogin } from '../auth/authActions'
import { logout } from '../auth/authSlice'
import { reset } from '../game/gameSlice'
import {
  acceptCEOTransferRequest,
  getCEOTransferRequest,
} from '../changeCeo/changeCeoActions'
import { teamChanged } from '../team/teamSlice'

interface DeviceState {
  registered: boolean
  registering: boolean
  deviceStatusChecked: boolean
  isCeo: boolean
  error: Error | APIError | null
}

const initialState: DeviceState = {
  registered: false,
  registering: false,
  deviceStatusChecked: false,
  isCeo: false,
  error: null,
}

const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    deviceStatusChecked: state => {
      state.deviceStatusChecked = true
    },
    unregister: state => {
      state.registered = false
    },
    updateDeviceCeoStatus: (state, action: PayloadAction<boolean>) => {
      state.isCeo = action.payload
    },
    updateDeviceStatus: (state, action: PayloadAction<{ isCeo: boolean }>) => {
      state.isCeo = action.payload.isCeo
      state.registered = true
    },
  },
  extraReducers: builder => {
    builder
      .addCase(reset, () => {
        return initialState
      })
      .addCase(logout, () => {
        return initialState
      })
      .addCase(initialise.fulfilled, (state, { payload }) => {
        state.registered = true
        state.registering = false
        state.isCeo = payload.device.isMaster
        state.error = null
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.registered = true
        state.registering = false
        state.isCeo = payload.device.isMaster
        state.error = null
      })
      .addCase(getCEOTransferRequest.fulfilled, (state, { payload }) => {
        if (payload.result.status === 'accepted') {
          state.isCeo = false
        }
      })
      .addCase(acceptCEOTransferRequest.fulfilled, (state, { meta }) => {
        if (meta.arg.action === 'accept') {
          state.isCeo = true
        }
      })
      .addCase(teamChanged, state => {
        state.registered = false
        state.registering = false
      })
  },
})

export const {
  deviceStatusChecked,
  unregister,
  updateDeviceCeoStatus,
  updateDeviceStatus,
} = deviceSlice.actions

export default deviceSlice.reducer
