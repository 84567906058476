import React, { useCallback, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  useCharacterLimitValidator,
  ShortWrittenResponseField,
} from './Field/ShortWrittenResponseField'
import {
  AssessmentQuestion,
  CurrentAnswer,
  Answer,
  ShortWrittenResponseField as ShortWrittenResponseFieldType,
} from '../../../../types/gameApi/assessments'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import QuestionTitle from './QuestionTitle'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import QuestionContainer from './QuestionContainer'

interface Props {
  question: AssessmentQuestion
  questionIndex: number
  currentAnswer?: CurrentAnswer
}

const setFieldValue = (
  currentValue: Answer[],
  field: { id: string },
  value: string,
  onChange: (values: Answer[]) => void,
) => {
  if (!currentValue || currentValue.length === 0) {
    return
  }

  onChange(
    currentValue.map(answer => {
      if (answer.fieldId === field.id) {
        return { fieldId: field.id, value }
      }

      return answer
    }),
  )
}

const getFieldValue = (currentValue: Answer[], field: { id: string }) => {
  if (!currentValue || currentValue.length === 0) {
    return ''
  }

  const answer = currentValue.find(answer => answer.fieldId === field.id)
  return answer ? answer.value : ''
}

export const ShortWrittenResponse: React.FC<Props> = ({
  question,
  questionIndex,
  currentAnswer,
}) => {
  const { control, setValue, getValues, formState } = useFormContext()
  const { id } = question

  const field = question.fields[0] as ShortWrittenResponseFieldType
  const characterLimitValidation = useCharacterLimitValidator(
    field,
    getFieldValue,
  )

  useEffect(() => {
    if (formState.dirtyFields[id]) return
    if (
      typeof currentAnswer !== 'undefined' &&
      typeof getValues()[id]?.value === 'undefined'
    ) {
      setValue(id, currentAnswer.answer)
    }
  }, [currentAnswer, id, setValue, getValues, formState])

  const mandatoryValidation = useCallback(
    (value: Answer[]) => {
      if (!question.mandatory) {
        return true
      }

      if (
        value[0] &&
        (!value[0].value ||
          value[0].value.length === 0 ||
          (typeof value[0].value === 'string' &&
            value[0].value.trim().length === 0))
      ) {
        return 'Please enter a response'
      }

      return true
    },
    [question.mandatory],
  )

  return (
    <Controller
      name={id}
      control={control}
      shouldUnregister={false}
      defaultValue={[{ fieldId: field.id, value: undefined }]}
      rules={{
        validate: {
          characterLimit: characterLimitValidation,
          mandatory: mandatoryValidation,
        },
      }}
      render={({ field: { onChange, value }, fieldState }) => {
        return (
          <QuestionContainer>
            <QuestionTitle
              hasQuestionIndex
              questionIndex={questionIndex}
              title={question.question}
            />
            <VerticalGroup gap={2} fullWidth>
              <SecondaryText>{field.config.label}</SecondaryText>
              <VerticalGroup gap={2} fullWidth>
                <ShortWrittenResponseField
                  placeholder="Type your answer here"
                  onChange={newValue => {
                    setFieldValue(value, field, newValue, onChange)
                  }}
                  value={getFieldValue(value, field) || ''}
                  characterLimit={field.config.characterLimit}
                />
                {fieldState.error && (
                  <SecondaryText colour="error" size="xs">
                    {fieldState.error.message}
                  </SecondaryText>
                )}
              </VerticalGroup>
            </VerticalGroup>
          </QuestionContainer>
        )
      }}
    />
  )
}
