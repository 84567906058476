import React from 'react'
import { CostsResponse } from '../../../../types/gameApi/costs'
import { DecisionMap } from '../CostsContainer'

import CostsDivisionCard from './CostsDivisionCard'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { Colour } from '../../../../types/theme'
import { FTECostPerDivision } from '../calculations'
import { CostsData } from '../Data'

interface CostsDivisionProps {
  data: CostsResponse
  decisions: DecisionMap
  enableChanges: boolean
  onChangeValue: (val: Partial<DecisionMap>) => void
  costData: CostsData
  peopleAndCostImpactPerDivision: FTECostPerDivision[]
  onSelectDivision: (divisionType: string) => void
  selectedDivision: string | null
  linkedDivisions: (keyof DecisionMap)[]
  setLinkedDivisions: (linkedDivisions: (keyof DecisionMap)[]) => void
}
const colours: Colour[] = ['primary', 'orange', 'pink', 'green', 'purple']
const CostsDivision: React.FC<CostsDivisionProps> = ({
  costData,
  data,
  enableChanges,
  peopleAndCostImpactPerDivision,
  decisions,
  onChangeValue,
  selectedDivision,
  onSelectDivision,
  linkedDivisions,
  setLinkedDivisions,
}) => {
  const { divisions, impactValues } = costData

  return (
    <HorizontalGroup fullWidth gap={2}>
      {divisions.map((division, i) => {
        const fteChange = peopleAndCostImpactPerDivision[i]?.fteChange

        const changeInCost = peopleAndCostImpactPerDivision[i]?.changeInCost

        return (
          <CostsDivisionCard
            id={i === 0 ? 'tour-costs-division' : undefined}
            linkedDivisions={linkedDivisions}
            setLinkedDivisions={setLinkedDivisions}
            decisions={decisions}
            enableChanges={enableChanges}
            fteChange={fteChange}
            changeInCost={changeInCost}
            key={i}
            division={division}
            CostsData={costData}
            data={data}
            colour={colours[i % colours.length]}
            onChangeValue={onChangeValue}
            effectiveDecision={impactValues.effectiveDecisions[division.type]}
            selectedDivision={selectedDivision}
            onSelectDivision={onSelectDivision}
          />
        )
      })}
    </HorizontalGroup>
  )
}

export default CostsDivision
