import React, { PropsWithChildren, useMemo } from 'react'
import { reportingPercentage } from '../../../../../lib/formatters'
import { LineChartData } from '../../../../organisms/Charts/LineChart/types'
import { getYAxisDataV2 } from '../../../../organisms/Charts/yAxis'
import { ChartProps } from '../SummaryMetricMapper'
import LineChartWithTeamSelector from './LineChartWithTeamSelector'
import { mapHistoricalData } from './mappers'

const ShareholderReturnsChart: React.FC<PropsWithChildren<ChartProps>> = ({
  data,
  teamId,
  children,
}) => {
  const lines = data.map(t => ({
    name: t.name,
    key: `team${t.team}`,
  }))

  const mappedData: Array<LineChartData> = useMemo(
    () => mapHistoricalData(data, val => val / 100),
    [data],
  )

  const { minValue, maxValue, numberOfTicks, yAxisDp } = getYAxisDataV2({
    isPercentage: true,
    pastRound1: true,
    data: mappedData,
  })

  return (
    <LineChartWithTeamSelector
      header={children}
      data={mappedData}
      formatter={(val: number) => reportingPercentage(val)}
      yAxisFormatter={(val: number) => reportingPercentage(val, yAxisDp)}
      minYAxisvalue={minValue}
      maxYAxisvalue={maxValue}
      numberOfTicks={numberOfTicks}
      lines={lines}
      teamId={teamId}
    />
  )
}

export default ShareholderReturnsChart
