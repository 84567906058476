import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'
import Modal from 'react-modal'
import { Icon } from '../../atoms/Icon/Icon'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const customStyles: Modal.Styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 60,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    border: 'none',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '8px',
    border: 'none',
    padding: 0,
    overflow: 'visible',
    width: 'auto',
  },
}

const MinimalModal: React.FC<PropsWithChildren<Props>> = ({
  isOpen,
  onClose,
  children,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <div>
        <div style={{ width: 'auto', overflowX: 'auto' }}>{children}</div>
        <button
          className={classNames('close-button')}
          onClick={onClose}
          aria-label="Close"
          style={{
            position: 'absolute',
            right: -40,
            top: 10,
            background: 'none',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          <Icon size={6} type="close" colour="white" className="m-0" />
        </button>
      </div>
    </Modal>
  )
}

export default MinimalModal
