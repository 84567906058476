import { useMemo } from 'react'
import { TreasuryResponse } from '../../../../types/gameApi/treasury'
import { DecisionMap } from '../TreasuryContainer'
import {
  calculateForecastValues,
  calculateRiskWeightedAssets,
  calculateForecastDeductions,
  calculateCapitalMovement,
  calculateCapitalRatioEstimate,
} from '../calculations'

export interface CapitalManagmentData {
  targetShareCapitalMovement: number
  denomination: string
  retainedNpat: number
  dividendsReinvestedHelpTitle: string
  dividendsReinvestedHelpBody: string
  dividendsReinvestedValue: number
  totalNpatRetained: number
  additionalShareIssuesHelpTitle: string
  additionalShareIssuesHelpBody: string
  additionalShareIssues: number
  openingBalances: OpeningBalances
  closingShareholdersFunds: number
  regulatoryDeductions: number
  netRegulatoryCapital: number
  totalRiskWeightedAsset: number
  capitalRatioEstimate: number
  capitalSliderMin: number
  capitalSliderMax: number
  band: string | null
}
export interface OpeningBalances {
  shareholdersFunds: number
}
export const useGetData = ({
  data,
  decisions,
}: {
  data: TreasuryResponse
  decisions: DecisionMap
}): CapitalManagmentData => {
  return useMemo(() => {
    const {
      capitalBands,
      denomination,
      openingBalances,
      capitalSliderMin,
      capitalSliderMax,
      dividendsReinvestedHelpTitle,
      dividendsReinvestedHelpBody,
      additionalShareIssuesHelpTitle,
      additionalShareIssuesHelpBody,
    } = data

    const assetForecasts = calculateForecastValues(
      false,
      openingBalances,
      decisions,
    )

    const { totalRiskWeightedAsset } = calculateRiskWeightedAssets(
      assetForecasts,
      decisions,
    )

    const { totalCapitalDeductions } = calculateForecastDeductions(
      assetForecasts,
      decisions,
    )

    const targetShareCapitalMovement = calculateCapitalMovement(
      totalRiskWeightedAsset,
      -1 * totalCapitalDeductions,
      decisions,
      openingBalances,
    )

    const {
      retainedNpat,
      dividendsReinvestedValue,
      totalNpatRetained,
      additionalShareIssues,
      closingShareholdersFunds,
      regulatoryDeductions,
      netRegulatoryCapital,
      capitalRatioEstimate,
      band,
    } = calculateCapitalRatioEstimate({
      openingBalances,
      targetShareCapitalMovement,
      totalRiskWeightedAsset,
      totalCapitalDeductions,
      capitalBands,
      decisions,
    })

    return {
      capitalBands,
      denomination,
      openingBalances,
      capitalSliderMin,
      capitalSliderMax,
      dividendsReinvestedHelpTitle,
      dividendsReinvestedHelpBody,
      additionalShareIssuesHelpTitle,
      additionalShareIssuesHelpBody,
      assetForecasts,
      totalRiskWeightedAsset,
      totalCapitalDeductions,
      targetShareCapitalMovement,
      retainedNpat,
      dividendsReinvestedValue,
      totalNpatRetained,
      additionalShareIssues,
      closingShareholdersFunds,
      regulatoryDeductions,
      netRegulatoryCapital,
      capitalRatioEstimate,
      band,
    }
  }, [data, decisions])
}
