import React, { PropsWithChildren, ReactNode } from 'react'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import { Icon, IconType } from '../../atoms/Icon/Icon'
import PrimaryText from '../../atoms/Text/PrimaryText'
import SecondaryText from '../../atoms/Text/SecondaryText'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import Input from '../../atoms/Input/Input'
import TextArea from '../../atoms/Input/TextArea'
import { Colour } from '../../../types/theme'

interface StrategyCardProps extends React.HTMLAttributes<HTMLDivElement> {
  heading: string
  strategy?: string
  name?: string
  inputBox?: boolean
  cardHeight?: 'fit'
  themeColor: string
  onValueChange?: (value: string) => void
  textArea?: boolean
  placeholder?: string
  icon: IconType
  iconColour?: Colour
  readOnly?: ReactNode
}

const StrategyCard: React.FC<PropsWithChildren<StrategyCardProps>> = ({
  heading,
  strategy,
  name,
  themeColor,
  inputBox,
  cardHeight,
  onValueChange,
  placeholder,
  textArea,
  icon,
  iconColour,
  readOnly,
  ...rest
}) => {
  const cardHeightClass = cardHeight === 'fit' ? 'h-fit' : 'h-96'

  return (
    <VerticalGroup {...rest} center>
      <HorizontalGroup
        verticalCenter
        center
        fullWidth
        className={`h-12 w-[95%] tablet:w-[86%] bg-${themeColor} rounded-full`}
      >
        <HorizontalGroup className="" center verticalCenter>
          <HorizontalGroup
            center
            verticalCenter
            className={`z-20 h-16 w-16 border-2 bg-white border-${themeColor} rounded-full`}
          >
            <Icon colour={iconColour} type={icon} size={8}></Icon>
          </HorizontalGroup>
        </HorizontalGroup>
        <HorizontalGroup
          fullWidth
          verticalCenter
          className={`bg-${themeColor} mr-4`}
        >
          <PrimaryText paddingLeft={4} weight="bold" colour="white">
            {heading}
          </PrimaryText>
        </HorizontalGroup>
      </HorizontalGroup>
      <div
        className={`w-[83%] border-2 shadow-xl tablet:w-4/5 ${cardHeightClass} border-t-0 border-${themeColor} rounded-lg rounded-t-none bg-white p-4`}
      >
        {readOnly ? (
          <div className="">{readOnly}</div>
        ) : inputBox ? (
          <Input
            data-test="bank-name-input"
            value={name}
            onValueChange={onValueChange}
            className="h-full w-full"
            maxLength={8}
          />
        ) : textArea ? (
          <TextArea
            data-test="strategy-input"
            fullHeight
            value={strategy}
            onValueChange={onValueChange}
            maxLength={1000}
            rows={6}
            placeholder={placeholder}
          />
        ) : (
          <div className="h-80 overflow-auto">
            <SecondaryText whitespace="preline">{strategy}</SecondaryText>
          </div>
        )}
      </div>
    </VerticalGroup>
  )
}

export default StrategyCard
