import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import {
  fetchBusinesses,
  updateBusinessDecisionsBackground,
  fetchBusinessesBackground,
} from '../../../redux/businesses/businessActions'

import InlineError from '../../atoms/InlineError/InlineError'
import CentredSpinner from '../../molecules/CentredSpinner/CentredSpinner'
import Businesses from './Businesses'
import { v4 as uuidv4 } from '../../../lib/uuid'
import { RootState } from '../../../store'
import { Business } from '../../../types/gameApi/business'
import Page from '../../atoms/Page/Page'

const anyVersionsChanged = (
  newItems: Business[],
  oldItems: Business[],
  deviceId: string,
) => {
  let hasChange = false
  newItems.forEach((newIt, i) => {
    if (
      newIt.version !== oldItems[i].version &&
      newIt.updatedBy &&
      newIt.updatedBy !== deviceId
    ) {
      hasChange = true
    }
  })
  return hasChange
}

const mapState = (state: RootState) => {
  const selectedRound = state.game.selectedRound
  return {
    currentTab: state.businesses.currentTab,
    enableChanges: state.game.selectedRound === state.game.currentRound,
    teamNumber: state.team.id ?? 0,
    selectedRound,
    roundNumber: state.game.currentRound,
    isLoading: state.businesses.isLoading,
    isLoaded: state.businesses.isLoaded,
    error: state.businesses.error,
    showAdvanced: state.businesses.showAdvanced,
    items: state.businesses.data[selectedRound]?.businesses ?? [],
    rates: state.businesses.data[selectedRound]?.rates,
    volume: state.businesses.data[selectedRound]?.volume,
    cashRateChange: state.businesses.data[selectedRound]?.cashRateChange,
    interest: state.businesses.data[selectedRound]?.interest,
    currentBusiness: state.businesses.currentBusiness,
    growthRates: state.businesses.data[selectedRound]?.growthRates,
  }
}

const mapDispatch = {
  fetchBusinesses,
  updateBusinessDecisionsBackground,
  fetchBusinessesBackground,
}

const connector = connect(mapState, mapDispatch)

// The inferred type will look like:
// {isOn: boolean, toggleOn: () => void}
type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {}

export interface BusinessDecision {
  businessId: number
  price1Now: number
  price2Now: number
  volumeNow: number
}

type State = {
  decisions: DecisionMap | null
  readyForDisplay: boolean
  uniqueDeviceId: string
}

export type DecisionMap = Record<string, BusinessDecision>

class BusinessesContainer extends Component<Props, State> {
  lastSynced: DecisionMap = {}
  private interval: NodeJS.Timeout | null = null
  constructor(props: Props) {
    super(props)
    this.state = {
      decisions: null,
      readyForDisplay: false,
      uniqueDeviceId: uuidv4(),
    }
    this.lastSynced = {}
  }

  componentDidMount() {
    if (this.props.items && this.props.items.length) {
      this.setReadyForDisplay()
      this.props.fetchBusinessesBackground({
        roundId: this.props.selectedRound,
        teamId: this.props.teamNumber,
      })
    } else {
      this.props.fetchBusinesses({
        roundId: this.props.selectedRound,
        teamId: this.props.teamNumber,
      })
    }
    this.pollChanges()
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
      this.checkAndUpdate()
      this.lastSynced = {}
    }
  }

  pollChanges = () => {
    this.interval = setInterval(() => {
      this.checkAndUpdate()
      this.props.fetchBusinessesBackground({
        roundId: this.props.selectedRound,
        teamId: this.props.teamNumber,
      })
    }, 5000)
  }

  setReadyForDisplay = () => {
    this.setState({
      readyForDisplay: true,
      decisions: this.props.items.reduce<DecisionMap>((result, item) => {
        this.lastSynced[item.type] = {
          businessId: item.id,
          price1Now: item.price1Now,
          price2Now: item.price2Now,
          volumeNow: item.volumeNow,
        }
        result[item.type] = {
          businessId: item.id,
          price1Now: item.price1Now,
          price2Now: item.price2Now,
          volumeNow: item.volumeNow,
        }
        return result
      }, {}),
    })
  }

  checkAndUpdate = () => {
    const changes = []
    for (const business of Object.keys(this.state.decisions || {})) {
      const decision = this.state.decisions?.[business]
      const lastSynced = this.lastSynced[business]
      if (decision && lastSynced) {
        const newPrice1Now = parseInt(String(decision.price1Now))
        if (newPrice1Now !== lastSynced.price1Now) {
          changes.push({
            businessId: decision.businessId,
            price1Now: newPrice1Now,
          })
          lastSynced.price1Now = newPrice1Now
        }
        const newPrice2Now = parseInt(String(decision.price2Now))
        if (newPrice2Now !== lastSynced.price2Now) {
          changes.push({
            businessId: decision.businessId,
            price2Now: newPrice2Now,
          })
          lastSynced.price2Now = newPrice2Now
        }
        const newVolumeNow = parseInt(String(decision.volumeNow))
        if (newVolumeNow !== lastSynced.volumeNow) {
          changes.push({
            businessId: decision.businessId,
            volumeNow: newVolumeNow,
          })
          lastSynced.volumeNow = newVolumeNow
        }
      }
    }

    if (changes.length) {
      this.props.updateBusinessDecisionsBackground({
        teamId: this.props.teamNumber,
        roundId: this.props.selectedRound,
        decisions: changes,
        deviceId: this.state.uniqueDeviceId,
      })
    }
  }

  retry = () => {
    this.props.fetchBusinesses({
      roundId: this.props.selectedRound,
      teamId: this.props.teamNumber,
    })
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.selectedRound !== prevProps.selectedRound ||
      this.props.roundNumber !== prevProps.roundNumber ||
      this.props.teamNumber !== prevProps.teamNumber
    ) {
      this.lastSynced = {}
      return this.props.fetchBusinesses({
        roundId: this.props.selectedRound,
        teamId: this.props.teamNumber,
      })
    }
    const shouldUpdateDecisions =
      (this.props.items.length && !prevProps.items.length) ||
      (this.props.items.length &&
        !prevState.readyForDisplay &&
        !this.state.readyForDisplay) ||
      (this.props.items.length &&
        prevProps.items.length &&
        anyVersionsChanged(
          this.props.items,
          prevProps.items,
          prevState.uniqueDeviceId,
        )) ||
      (this.props.items.length && Object.keys(this.lastSynced).length === 0)

    if (shouldUpdateDecisions) {
      this.setReadyForDisplay()
    }
  }

  handleChangeValue = (
    type: string,
    field: keyof BusinessDecision,
    value: number,
  ) => {
    if (
      !this.state.decisions ||
      (this.state.decisions && this.state.decisions[type][field] === value)
    ) {
      return
    }
    this.setState({
      decisions: {
        ...this.state.decisions,
        [type]: {
          ...this.state.decisions[type],
          [field]: value,
        },
      },
    })
  }

  render() {
    if (this.props.error) {
      return (
        <Page full>
          <InlineError
            message={this.props.error.message}
            onRetry={
              !this.props.items || !this.props.items.length
                ? this.retry
                : undefined
            }
          />
        </Page>
      )
    }
    if (
      this.props.isLoading ||
      !this.state.readyForDisplay ||
      !this.props.items ||
      !this.state.decisions
    ) {
      return <CentredSpinner />
    }
    if (!this.props.items.length) {
      return (
        <InlineError
          message={`No decisions have been uploaded for round ${this.props.selectedRound}`}
        />
      )
    }
    return (
      <Businesses
        enableChanges={this.props.enableChanges}
        businesses={this.props.items}
        rates={this.props.rates}
        volume={this.props.volume}
        interest={this.props.interest}
        decisions={this.state.decisions}
        onChangeValue={this.handleChangeValue}
        cashRateChange={this.props.cashRateChange}
        growthRates={this.props.growthRates}
      />
    )
  }
}

export default connector(BusinessesContainer)
