import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { extractSearchParams } from '../../lib/extractQueryParams'

interface QueryParams {
  clientName?: string
  gameName?: string
  headingBackgroundColour?: string
  mainBackgroundColour?: string
  buttonBackgroundColour?: string
  headingTextColour?: string
  mainTextColour?: string
  buttonTextColour?: string
  logo?: string
}

const {
  clientName,
  gameName,
  headingBackgroundColour,
  mainBackgroundColour,
  buttonBackgroundColour,
  headingTextColour,
  mainTextColour,
  buttonTextColour,
  logo,
} = extractSearchParams<QueryParams>()

interface BrandingPreview {
  clientName?: string
  gameName?: string
  buttonBackgroundColour?: string
  headingBackgroundColour?: string
  mainBackgroundColour?: string
  headingTextColour?: string
  mainTextColour?: string
  buttonTextColour?: string
  eventLogo?: string
}

const initialState: BrandingPreview = {
  clientName: clientName,
  gameName: gameName,
  buttonBackgroundColour: buttonBackgroundColour,
  headingBackgroundColour: headingBackgroundColour,
  mainBackgroundColour: mainBackgroundColour,
  headingTextColour: headingTextColour,
  mainTextColour: mainTextColour,
  buttonTextColour: buttonTextColour,
  eventLogo: logo,
}

const brandingPreviewSlice = createSlice({
  name: 'brandingPreview',
  initialState,
  reducers: {
    setBrandingPreview: (state, action: PayloadAction<BrandingPreview>) => {
      state.buttonBackgroundColour = action.payload.buttonBackgroundColour
      state.headingBackgroundColour = action.payload.headingBackgroundColour
      state.mainBackgroundColour = action.payload.mainBackgroundColour
      state.headingTextColour = action.payload.headingTextColour
      state.mainTextColour = action.payload.mainTextColour
      state.buttonTextColour = action.payload.buttonTextColour
      state.eventLogo = action.payload.eventLogo
    },
  },
})

export const { setBrandingPreview } = brandingPreviewSlice.actions

export default brandingPreviewSlice.reducer
