import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

interface ColumnDimensions {
  width: number
  height: number
  top: number
  left: number
}

interface ColumnInfo {
  id: string
  ref: React.RefObject<HTMLTableCellElement>
}

interface SectionInfo {
  ref: React.RefObject<HTMLTableSectionElement>
}

interface FloatingDivProps {
  id: string
  top: number
  left: number
  width: number
  height: number
}

const FloatingDiv: React.FC<FloatingDivProps> = ({
  id,
  top,
  left,
  width,
  height,
}) => (
  <div
    id={id}
    style={{
      position: 'absolute',
      top: `${top}px`,
      left: `${left}px`,
      width: `${width}px`,
      height: `${height}px`,
      pointerEvents: 'none',
    }}
  />
)

interface DivisionalProfitAndLossTourWrapperProps {
  children: (props: {
    columnInfo: ColumnInfo[]
    sectionInfo: SectionInfo
    tableRef: React.RefObject<HTMLTableElement>
    setSectionRef: (el: HTMLTableSectionElement | null) => void
  }) => React.ReactNode
}
const DivisionalProfitAndLossTourWrapper: React.FC<
  DivisionalProfitAndLossTourWrapperProps
> = ({ children }) => {
  const tableRef = useRef<HTMLTableElement>(null)
  const [columnDimensions, setColumnDimensions] = useState<ColumnDimensions[]>(
    [],
  )
  const firstColumn = useRef<HTMLTableCellElement>(null)
  const [highlightedSection, setHighlightedSection] =
    useState<HTMLTableSectionElement | null>(null)

  const columnInfo = useMemo<ColumnInfo[]>(
    () => [{ id: 'tour-table-details', ref: firstColumn }],
    [],
  )

  const sectionInfo = useMemo<SectionInfo>(
    () => ({ ref: { current: highlightedSection } }),
    [highlightedSection],
  )

  const setSectionRef = useCallback((el: HTMLTableSectionElement | null) => {
    setHighlightedSection(el)
  }, [])

  const updateDimensions = useCallback(() => {
    if (!tableRef.current || !highlightedSection) return
    const tableRect = tableRef.current.getBoundingClientRect()
    const sectionRect = highlightedSection.getBoundingClientRect()

    const newDimensions: ColumnDimensions[] = columnInfo.map(({ ref }) => {
      if (!ref.current) return { width: 0, height: 0, top: 0, left: 0 }

      const columnRect = ref.current.getBoundingClientRect()
      return {
        width: columnRect.width,
        height: sectionRect.height,
        top: sectionRect.top - tableRect.top,
        left: columnRect.left - tableRect.left,
      }
    })

    setColumnDimensions(newDimensions)
  }, [columnInfo, highlightedSection])

  useEffect(() => {
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [updateDimensions])

  return (
    <div className="h-full w-full" style={{ position: 'relative' }}>
      {children({ columnInfo, sectionInfo, tableRef, setSectionRef })}
      {columnInfo.map(({ id }, index) => (
        <FloatingDiv key={id} id={id} {...columnDimensions[index]} />
      ))}
    </div>
  )
}

export default DivisionalProfitAndLossTourWrapper
