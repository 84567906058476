import React from 'react'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import { IconType } from '../../../atoms/Icon/Icon'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import MetricCardIconBox from '../../../organisms/MetricCard/MetricCardIconBox'
import { Colour } from '../../../../types/theme'

interface Props {
  heading: string
  icon: IconType
  percentage: number | string | null
  iconColour?: Colour
  backgroundColour?: Colour
}

const DealRoomMetric: React.FC<Props> = ({ heading, icon, percentage }) => {
  return (
    <HorizontalGroup verticalCenter gap={3}>
      <MetricCardIconBox
        size="small"
        iconType={icon}
        iconColour="white"
        backgroundColour="orange"
      />
      <VerticalGroup id="tour-current-shareholder-returns" between>
        <SecondaryText size="xs" weight="bold">
          {heading}
        </SecondaryText>
        {percentage == null && (
          <SecondaryText weight="bold" size="xl">
            N/A
          </SecondaryText>
        )}
        {percentage != null && (
          <HorizontalGroup gap={1}>
            <SecondaryText weight="bold" size="xl">
              {percentage}
            </SecondaryText>
            <SecondaryText size="lg">%</SecondaryText>
          </HorizontalGroup>
        )}
      </VerticalGroup>
    </HorizontalGroup>
  )
}

export default DealRoomMetric
