import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from 'react'

import { useAppDispatch, useAppSelector } from '../../../store'
import BrandedPage from '../../organisms/Branding/BrandedPage/BrandedPage'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import CentredSpinner from '../../molecules/CentredSpinner/CentredSpinner'
import { logout } from '../../../redux/auth/authSlice'

import { useLocation, useNavigate } from 'react-router-dom'
import { sendRemoteAction } from '../../../lib/remoteActions'
import { WaitingRoom } from './WaitingRoom'
import { useMessageListener } from '../../../hooks/useMessageListener'
import { closePublishedResults } from '../../../redux/results/resultsSlice'

// const showWaitingRoom = false

const WaitingRoomContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const {
    device,
    team,
    game,
    event,
    deviceStatusChecked,
    showCeo,
    isTablet,
    testMode,
    automateRedirectTo,
    resultsCastUrl,
    publishedResultsUrl,
  } = useAppSelector(state => ({
    isTablet: state.auth.isTablet,
    game: state.game,
    team: state.team,
    event: state.event?.details,
    device: state.device,
    deviceStatusChecked:
      state.device && state.device.registered
        ? state.device.deviceStatusChecked
        : !!state.device?.error,
    showCeo: state.game.shouldRotateCeo && !state.game.maskParticipants,
    testMode:
      state.game.automate.testModeScreen || state.game.automate.testModeWidgets,
    automateRedirectTo: state.game.automateRedirectTo,
    resultsCastUrl: state.game.resultsCastUrl,
    publishedResultsUrl: state.results.currentResultsUrl,
  }))

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (
      testMode &&
      automateRedirectTo &&
      location.pathname !== automateRedirectTo
    ) {
      navigate(automateRedirectTo)
    }
  }, [automateRedirectTo, location.pathname, testMode, navigate])

  const logoutAction = useCallback(() => {
    if (isTablet) {
      sendRemoteAction({ name: 'logout' })
      return
    }
    dispatch(logout())
  }, [dispatch, isTablet])

  const onEnterEventButtonPressed = useCallback(() => {
    navigate('/team')
  }, [navigate])

  const onSettingsButtonPressed = useCallback(() => {
    navigate('/demo-settings')
  }, [navigate])

  const showWaitingRoom = useMemo(() => {
    if (testMode) {
      return false
    }
    if (location.pathname === '/') {
      return true
    }
    if (!device.registered) {
      return true
    }
    if (game.finishTime === null) {
      return true
    }
    if (game.state === 'locked') {
      return true
    }
    return false
  }, [
    device.registered,
    game.finishTime,
    game.state,
    location.pathname,
    testMode,
  ])

  const messageHandler = useMemo(() => {
    return {
      closePublishedResults: () => {
        dispatch(closePublishedResults())
      },
    }
  }, [dispatch])

  useMessageListener({ handler: messageHandler })

  useEffect(() => {
    if (showWaitingRoom) {
      if (location.pathname !== '/') {
        navigate('/')
      }
    }
  }, [location.pathname, navigate, showWaitingRoom])

  if (!showWaitingRoom) {
    return <>{children}</>
  }

  if (!device || !game) {
    return (
      <BrandedPage noPadding full>
        <VerticalGroup center>
          {/* <WaitingRoomHeader onLogout={logout} onSettingsButtonPressed={onSettingsButtonPressed} /> */}
          <CentredSpinner />
        </VerticalGroup>
      </BrandedPage>
    )
  }

  if (resultsCastUrl) {
    return (
      <div style={{ height: '100vh', width: '100%' }}>
        <iframe
          src={resultsCastUrl}
          title="preview"
          data-test="preview-iframe"
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </div>
    )
  }

  if (publishedResultsUrl) {
    return (
      <div style={{ height: '100vh', width: '100%' }}>
        <iframe
          src={publishedResultsUrl}
          title="preview"
          data-test="preview-iframe"
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </div>
    )
  }
  return (
    <WaitingRoom
      event={event}
      game={game}
      team={team}
      device={device}
      deviceStatusChecked={deviceStatusChecked}
      showCeo={showCeo}
      logoutAction={logoutAction}
      onSettingsButtonPressed={onSettingsButtonPressed}
      onEnterEventButtonPressed={onEnterEventButtonPressed}
    />
  )
}

export default WaitingRoomContainer
