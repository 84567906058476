import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store'
import Tabs from '../../atoms/Tabs/Tabs'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'

import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import Page from '../../atoms/Page/Page'
import Tab from '../../atoms/Tabs/Tab'
import MobilePageHeader from '../../organisms/MobilePageHeader/MobilePageHeader'
import DropDown from '../../atoms/DropDown/DropDown'

import {
  BusinessTabs,
  changeTab,
  changeBusiness,
  toggleShowAdvanced,
} from '../../../redux/businesses/businessSlice'
import BusinessDecisions from './BusinessDecisions/BusinessDecisions'
import BusinessPriceVolumeTradeOff from './BusinessPriceVolumeTradeOff/BusinessPriceVolumeTradeOff'
import {
  Business,
  BusinessInterest,
  BusinessRates,
  BusinessVolume,
  GrowthRate,
  ProductType,
} from '../../../types/gameApi/business'
import { BusinessDecision, DecisionMap } from './BusinessesContainer'
import Toggle from '../../atoms/Toggle/Toggle'
import BusinessAdvancedMode from './BusinessAdvancedMode/BusinessAdvancedMode'
import TabChanger from '../../atoms/TabChanger/TabChanger'
import Modal from '../../atoms/Modal/Modal'
import PrimaryText from '../../atoms/Text/PrimaryText'
import InAppHelpContent from '../../organisms/InAppHelp/InAppHelpContent'
import { calculateSignedPercentage } from './utils'
import MultiInAppHelp, {
  Candidate,
} from '../../organisms/InAppHelp/MultiInAppHelp'
import { useIsMobile } from '../../../hooks/useIsMobile'
import Text from '../../atoms/Text/Text'
import { titleCase } from '../../../lib/text'
import { useTranslatedText } from '../../../hooks/useTranslatedText'
import { percentage } from '../../../lib/formatters'
import { useSearchParams } from 'react-router-dom'
import { reportFeatureUsage } from '../../../lib/googleTagManager'
import BusinessesTour from './BusinessesTour'

interface Props {
  businesses: Business[]
  rates: BusinessRates
  volume: BusinessVolume
  interest: BusinessInterest
  decisions: DecisionMap
  cashRateChange?: number
  growthRates?: GrowthRate[]
  onChangeValue: (
    type: string,
    field: keyof BusinessDecision,
    value: number,
  ) => void
  enableChanges: boolean
}

const TABS: Array<{ label: string; value: BusinessTabs; id: string }> = [
  {
    label: 'BUSINESS DECISIONS',
    value: 'decisions',
    id: 'tour-business-decisions',
  },
  {
    label: 'PRICE/VOLUME TRADE OFF',
    value: 'price-volume-trade-off',
    id: 'tour-price-volume-trade-off',
  },
]

const Businesses: React.FC<Props> = ({
  enableChanges,
  businesses,
  decisions,
  onChangeValue,
  rates,
  volume,
  interest,
  cashRateChange,
  growthRates,
}) => {
  const { currentTab, currentBusiness, showAdvanced, teamId, roundId } =
    useAppSelector(state => ({
      currentTab: state.businesses.currentTab,
      currentBusiness: state.businesses.currentBusiness,
      showAdvanced: state.businesses.showAdvanced,
      teamId: state.event.details?.team ?? 0,
      roundId: state.game.selectedRound,
    }))

  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    const queryTab = searchParams.get('tab')
    const advanced = searchParams.get('advanced') === 'true'
    if (queryTab && TABS.some(tab => tab.value === queryTab)) {
      dispatch(changeTab(queryTab as BusinessTabs))
    }
    if (advanced && !showAdvanced) {
      dispatch(toggleShowAdvanced(true))
    }
  }, [dispatch, searchParams, showAdvanced])

  const setTab = useCallback(
    (tab: BusinessTabs) => {
      dispatch(changeTab(tab))
      setSearchParams({ tab, advanced: showAdvanced ? 'true' : 'false' })
    },
    [dispatch, setSearchParams, showAdvanced],
  )

  const changeCurrentBusiness = useCallback(
    (type: ProductType) => {
      dispatch(changeBusiness(type))
    },
    [dispatch],
  )

  const toggleAdvanced = useCallback(
    (val: boolean) => {
      dispatch(toggleShowAdvanced(val))
      setSearchParams({ tab: currentTab, advanced: val ? 'true' : 'false' })
      if (val) {
        reportFeatureUsage({
          page: 'business',
          feature: 'advanced',
          action: 'enabled',
        })
      }
    },
    [currentTab, dispatch, setSearchParams],
  )
  const [showResetModal, setShowResetModal] = useState(false)
  const handleConfirmReset = useCallback(() => {
    setShowResetModal(false)
    const updatedDecisions = { ...decisions }

    businesses.forEach(business => {
      updatedDecisions[business.type].volumeNow = business.volumeNowOriginal
    })

    Object.keys(updatedDecisions).forEach(type => {
      onChangeValue(type, 'volumeNow', updatedDecisions[type].volumeNow)
    })
  }, [businesses, decisions, onChangeValue])

  const handleCancelReset = useCallback(() => {
    setShowResetModal(false)
  }, [])
  const handleReset = useCallback(() => {
    setShowResetModal(true)
  }, [])

  const isMobile = useIsMobile()
  const breakingNewsText = useTranslatedText(
    'mainMenu.newsButton',
    'Breaking News',
  )
  const candidates = useMemo(() => {
    const results: Candidate[] = []
    if (businesses && growthRates) {
      for (const product of businesses) {
        const decisionHasBeenMade =
          decisions[product.type].volumeNow !== product.volumeNowOriginal
        if (!decisionHasBeenMade) {
          continue
        }
        const volumeDifference = calculateSignedPercentage(
          decisions[product.type].volumeNow,
          product.volumePrev,
        )
        const roundedChangeInVolume = Math.round(volumeDifference * 100)
        const growthRateForProduct = growthRates.find(
          gr => gr.type === product.type,
        )
        if (growthRateForProduct) {
          const volumeDifferenceFromGrowthRate = Math.abs(
            roundedChangeInVolume - growthRateForProduct.growthRate,
          )
          if (volumeDifferenceFromGrowthRate > 500) {
            const target = isMobile
              ? `#mobile-${product.type}-volumeNow-percentage`
              : `#${product.type}-volumeNow-percentage`
            const growthRateFromPercentage =
              growthRateForProduct.growthRateFrom / 100
            const growthRateToPercentage = percentage(
              growthRateForProduct.growthRateTo,
              0,
            )
            results.push({
              storageKey: `round-${roundId}-${product.type}-growth-rate-help`,
              analytics: {
                type: 'dynamic',
                params: {
                  category: 'business',
                  label: 'growth-rate-help',
                  action: 'open',
                  value: product.type,
                },
              },
              steps: [
                {
                  target,
                  content: (
                    <InAppHelpContent
                      isWarning
                      heading="Check Your Growth Rates"
                    >
                      <Text left>
                        {`Your forecast growth rate for ${titleCase(
                          product.name,
                        )} is very different to the (${growthRateFromPercentage} - ${growthRateToPercentage}) growth rate of the overall market (see ${breakingNewsText}). Please review and ensure you are comfortable with this difference.`}
                      </Text>
                    </InAppHelpContent>
                  ),
                },
              ],
            })
          }
        }
      }
    }
    return results
  }, [businesses, growthRates, decisions, isMobile, roundId, breakingNewsText])

  return (
    <div className="h-full">
      <div className="fixed left-0 top-11 z-20 w-full bg-gray-100">
        <MobilePageHeader>
          <DropDown<BusinessTabs>
            paddingLeft
            items={TABS}
            selectedItem={currentTab}
            onSelectedItemChange={item => {
              setTab(item.value)
            }}
          />
        </MobilePageHeader>
      </div>
      <Page full>
        <Modal
          show={showResetModal}
          onClose={handleCancelReset}
          title="Reset Volume Estimate?"
          onOk={handleConfirmReset}
          onCancel={handleCancelReset}
          okButtonText="Yes"
          cancelButtonText="No"
        >
          <PrimaryText>
            Are you sure you want to reset the Volume Estimate values?
          </PrimaryText>
        </Modal>

        <TabChanger
          currentTab={currentTab as string}
          tabs={TABS.map(t => t.value as string)}
          setTab={tab => setTab(tab as BusinessTabs)}
        />
        <div className="h-10 tablet:hidden"></div>
        <VerticalGroup full gap={4}>
          <HorizontalGroup between className="hidden tablet:flex" fullWidth>
            <Tabs>
              {TABS.map(t => (
                <Tab
                  key={t.value as string}
                  tab={t}
                  onClick={() => setTab(t.value)}
                  active={currentTab === t.value}
                  id={t.id}
                />
              ))}
            </Tabs>
            {currentTab === 'price-volume-trade-off' && (
              <Toggle
                id="tour-advanced-mode-toggle"
                data-test="toggle"
                label="Advanced Mode"
                enabled={showAdvanced}
                toggleEnabled={toggleAdvanced}
              />
            )}
          </HorizontalGroup>
          <div className="fixed top-20 z-10 mx-[-1rem] w-full bg-gray-100 pl-4 tablet:hidden">
            {currentTab === 'price-volume-trade-off' && (
              <Toggle
                label="Advanced Mode"
                enabled={showAdvanced}
                toggleEnabled={toggleAdvanced}
              />
            )}
          </div>
          {currentTab === 'decisions' && (
            <BusinessDecisions
              enableChanges={enableChanges}
              data={businesses}
              teamId={teamId}
              decisions={decisions}
              onChangeValue={onChangeValue}
              roundId={roundId}
              cashRateChange={cashRateChange}
            />
          )}
          {currentTab === 'price-volume-trade-off' &&
            currentBusiness &&
            showAdvanced && (
              <BusinessAdvancedMode
                handleReset={handleReset}
                setShowModal={setShowResetModal}
                roundId={roundId}
                data={businesses}
                rates={rates}
                volume={volume}
                interest={interest}
                decisions={decisions}
                onChangeValue={onChangeValue}
                enableChanges={enableChanges}
              />
            )}
          {currentTab === 'price-volume-trade-off' &&
            currentBusiness &&
            !showAdvanced && (
              <BusinessPriceVolumeTradeOff
                setShowModal={setShowResetModal}
                handleReset={handleReset}
                currentBusiness={currentBusiness}
                data={businesses}
                decisions={decisions}
                onChangeValue={onChangeValue}
                changeCurrentBusiness={changeCurrentBusiness}
                enableChanges={enableChanges}
              />
            )}
        </VerticalGroup>
        {currentTab === 'price-volume-trade-off' && (
          <MultiInAppHelp
            isWarning
            disableOverlayClose
            candidates={candidates}
          />
        )}
      </Page>
      {!isMobile && <BusinessesTour setTab={setTab} />}
    </div>
  )
}

export default Businesses
