import React from 'react'
import {
  LineChart as _LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  // Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts'
import createCustomLabel from './CustomLineChartLabel'
// import CustomTooltip from './CustomToolTip'
import { LineChartData } from './types'

export interface LineData {
  name: string
  key: string
  colour?: string
  label?: boolean
  zIndex?: number
  getLabelId?: null | ((index: number) => string | null)
}

export interface Props {
  animate?: boolean
  data: LineChartData[]
  formatter?: (val: number) => string | number
  // toolTipFormatter?: (val: number) => string | number
  yAxisFormatter?: (val: number) => string | number
  maxYAxisvalue: number
  minYAxisvalue: number
  numberOfTicks: number
  lines: LineData[]
  // tooltipOrdering?: 'highest' | 'lowest'
}

const LineChart: React.FC<Props> = ({
  animate,
  data,
  lines,
  formatter,
  yAxisFormatter,
  maxYAxisvalue,
  minYAxisvalue,
  numberOfTicks,
  // tooltipOrdering = 'highest',
  // toolTipFormatter,
}) => {
  return (
    <ResponsiveContainer width="100%" height="100%" debounce={1}>
      <_LineChart
        data={data}
        margin={{
          top: 20,
          right: 50,
          bottom: 10,
        }}
      >
        <CartesianGrid vertical={false} />
        <ReferenceLine y={0} stroke="#000" />
        <XAxis
          fontFamily="Open Sans"
          fontSize={12}
          dataKey="name"
          tickLine={false}
          padding={{ left: 75, right: 75 }}
        />
        <YAxis
          fontFamily="Open Sans"
          fontSize={12}
          axisLine={false}
          tickLine={false}
          minTickGap={0}
          tickMargin={0}
          tickCount={numberOfTicks + 1}
          tickFormatter={
            yAxisFormatter
              ? (tick: any) => yAxisFormatter(tick)
              : formatter
              ? tick => formatter(tick)
              : tick => tick
          }
          domain={[minYAxisvalue, maxYAxisvalue]}
        />
        {/* <Tooltip
          formatter={
            toolTipFormatter
              ? (tick: any) => toolTipFormatter(tick)
              : formatter
              ? (tick: any) => formatter(tick)
              : (tick: any) => tick
          }
          content={CustomTooltip(tooltipOrdering)}
        /> */}
        {lines.map((l, idx) => (
          <Line
            key={`line-item-${idx}`}
            name={l.name}
            type="natural"
            dataKey={l.key}
            stroke={l.colour}
            strokeWidth={2}
            animationDuration={animate ? 1000 : 0}
            animationBegin={animate ? idx * 1000 : 0}
            // @ts-expect-error this works - trust me
            label={
              l.label
                ? createCustomLabel({ formatter, getLabelId: l.getLabelId })
                : undefined
            }
          />
        ))}
      </_LineChart>
    </ResponsiveContainer>
  )
}

export default LineChart
