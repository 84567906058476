import React, { useMemo } from 'react'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import BarChart from '../../../organisms/Charts/BarChart/BarChart'
import { Business, ProductType } from '../../../../types/gameApi/business'
import { DecisionMap } from '../BusinessesContainer'
import { getChartData } from './getChartData'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'
import PrimaryText from '../../../atoms/Text/PrimaryText'

import classNames from 'classnames'
import Tab, { TabType } from '../../../atoms/Tabs/Tab'
import { formatPrice1 } from '../formatters'
import { formatVolume } from '../formatters'

export type ChartType = 'price' | 'volume'

interface Props {
  data: Business[]
  currentBusiness: ProductType
  decisions: DecisionMap
  selectedChart: ChartType
  setSelectedChart: (val: ChartType) => void
}

const BusinessPriceVolumeCharts: React.FC<Props> = ({
  data,
  currentBusiness,
  decisions,
  selectedChart,
  setSelectedChart,
}) => {
  const selectedItem = data.find(it => it.type === currentBusiness)
  const priceData = useMemo(() => {
    if (!selectedItem) {
      throw new Error('no selected item')
    }
    return getChartData({
      selectedItem,
      field: 'price1',
      currentValue: decisions[currentBusiness].price1Now,
      labelFormatter: val => formatPrice1(val, selectedItem.type),
      chartMin: selectedItem.slider1Min,
    })
  }, [currentBusiness, decisions, selectedItem])

  const volumeData = useMemo(() => {
    if (!selectedItem) {
      throw new Error('no selected item')
    }
    return getChartData({
      selectedItem,
      field: 'volume',
      currentValue: decisions[currentBusiness].volumeNow,
      labelFormatter: formatVolume,
      chartMin: decisions[currentBusiness].volumeNow * 0.9,
    })
  }, [currentBusiness, decisions, selectedItem])
  // Add a state to track the selected chart

  // Add a function to handle button clicks
  const handleButtonClick = (chart: 'price' | 'volume') => {
    setSelectedChart(chart)
  }
  const priceTab: TabType<'price'> = { value: 'price', label: 'PRICE' }
  const volumeTab: TabType<'volume'> = { value: 'volume', label: 'VOLUME' }
  return (
    <>
      {/* Add toggle buttons */}
      <HorizontalGroup className="flex tablet:hidden">
        <HorizontalGroup
          between
          verticalCenter
          className="z-20 w-11/12 bg-gray-100 pb-2"
        >
          <PrimaryText size="lg" weight="bold">
            {selectedItem?.name}
          </PrimaryText>
          <HorizontalGroup className="" gap={4}>
            <Tab
              tab={priceTab}
              active={selectedChart === 'price'}
              onClick={handleButtonClick}
            />
            <Tab
              tab={volumeTab}
              active={selectedChart === 'volume'}
              onClick={handleButtonClick}
            />
          </HorizontalGroup>
        </HorizontalGroup>
      </HorizontalGroup>
      <VerticalGroup className="h-full w-full">
        <HorizontalGroup
          data-test="business-charts"
          center
          className={classNames(
            'flex h-48 w-11/12 bg-white py-4 tablet:h-full tablet:w-full',
          )}
        >
          <VerticalGroup
            fullWidth
            className={classNames('tablet:flex', {
              flex: selectedChart === 'price',
              hidden: selectedChart !== 'price',
            })}
          >
            <HorizontalGroup center gap={1} className="hidden tablet:flex">
              <PrimaryText weight="bold">{selectedItem?.name}:</PrimaryText>

              <TranslatedText
                weight="bold"
                transform="upper"
                value="businessDecisions.screen2.priceHeading"
                default="PRICE"
              />
            </HorizontalGroup>

            <BarChart
              data={priceData.data}
              minYAxisvalue={priceData.minValue}
              maxYAxisvalue={priceData.maxValue}
              numberOfTicks={priceData.numberOfTicks}
              hideYAxis
              hideGrid
            />
          </VerticalGroup>

          <VerticalGroup
            fullWidth
            className={classNames('tablet:flex', {
              flex: selectedChart === 'volume',
              hidden: selectedChart !== 'volume',
            })}
          >
            <HorizontalGroup center gap={1} className="hidden tablet:flex">
              <PrimaryText weight="bold">{selectedItem?.name}:</PrimaryText>
              <TranslatedText
                weight="bold"
                transform="upper"
                value="businessDecisions.screen2.volumeHeading"
                default="VOLUME"
              />
            </HorizontalGroup>
            <BarChart
              data={volumeData.data}
              minYAxisvalue={volumeData.minValue}
              maxYAxisvalue={volumeData.maxValue}
              numberOfTicks={volumeData.numberOfTicks}
              hideYAxis
              hideGrid
            />
          </VerticalGroup>
        </HorizontalGroup>
      </VerticalGroup>
    </>
  )
}

export default BusinessPriceVolumeCharts
