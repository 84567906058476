import React from 'react'
import { useAppSelector } from '../../../../store'

import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import Image from '../../../atoms/Image/Image'

import { selectBranding } from '../../../organisms/Branding/selectors'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'

import BrandedHorizontalGroup from '../../../organisms/Branding/BrandedHorizontalGroup/BrandedHorizontalGroup'
import BrandedText from '../../../organisms/Branding/BrandedText/BrandedText'
import BrandedIcon from '../../../organisms/Branding/BrandedIcon/BrandedIcon'

interface Props {
  gameName?: string
  clientName?: string
  onLogout: () => void
  onSettingsButtonPressed: () => void
}

const WaitingRoomGameInfoHeader: React.FC<Props> = ({
  gameName,
  clientName,
  onLogout,
  onSettingsButtonPressed,
}) => {
  const branding = useAppSelector(selectBranding)
  return (
    <BrandedHorizontalGroup center className="h-20 bg-gray-900" fullWidth>
      <HorizontalGroup
        verticalCenter
        between
        center
        className="w-full px-4 laptop:container"
      >
        <HorizontalGroup className="tablet:w-1/2" gap={2} verticalCenter>
          <Image src={branding.eventLogo ?? ''} alt="Logo" className="h-16" />
          <VerticalGroup>
            <BrandedText weight="bold" colour="white">
              {clientName}
            </BrandedText>
            <BrandedText colour="white" size="xs">
              {gameName}
            </BrandedText>
          </VerticalGroup>
        </HorizontalGroup>
        <HorizontalGroup gap={8}>
          {process.env.REACT_APP_DEMO_MODE === 'true' && (
            <button onClick={onSettingsButtonPressed}>
              <BrandedIcon size={7} colour="white" type="setting" />
            </button>
          )}

          <button onClick={onLogout}>
            <BrandedIcon size={7} colour="white" type="power" />
          </button>
        </HorizontalGroup>
      </HorizontalGroup>
    </BrandedHorizontalGroup>
  )
}

export default WaitingRoomGameInfoHeader
