import React from 'react'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import MobilePageHeader from '../../organisms/MobilePageHeader/MobilePageHeader'
import BreakingNewsEconomy from './BreakingNewsEconomy'
import BreakingNewsMarketOutlook from './BreakingNewsMarketOutlook'
import BreakingNewsScenarios from './BreakingNewsScenarios'
import BreakingNewsWholesaleFunding from './BreakingNewsWholesaleFunding'
import { CreditRating } from '../../../types/gameApi/enums'
import {
  EconomicScenarioNewsConfig,
  EconomicScenarioNewsRoundResponse,
} from '../../../types/gameApi/news'
import { useIsMobile } from '../../../hooks/useIsMobile'
import BreakingNewsTour from './BreakingNewsTour'

interface Props {
  roundId: number
  newsConfig: EconomicScenarioNewsConfig
  data: EconomicScenarioNewsRoundResponse
  creditRating: CreditRating
  defaultReferenceRateName?: string
}

const BreakingNews: React.FC<Props> = ({
  data,
  newsConfig,
  roundId,
  creditRating,
  defaultReferenceRateName,
}) => {
  const isMobile = useIsMobile()
  return (
    <>
      <VerticalGroup className="laptop:h-full laptop:w-full laptop:gap-3">
        <MobilePageHeader />

        <VerticalGroup
          gap={4}
          className="divide-gray-300 laptop:h-full laptop:flex-row laptop:divide-x-2"
        >
          <VerticalGroup
            gap={2}
            full
            className="relative h-[90%] divide-y-2 divide-gray-300 laptop:w-[58%]"
          >
            <div className="h-full pr-4">
              <BreakingNewsEconomy news={data} newsConfig={newsConfig} />
            </div>
            <div
              id={isMobile ? 'market-outlook' : undefined}
              className="laptop:hidden"
            >
              <BreakingNewsMarketOutlook marketOutlook={data.marketOutlook} />
            </div>

            <div id="wholesale-funding" className="laptop:h-full laptop:pr-4">
              <BreakingNewsWholesaleFunding
                roundId={roundId}
                wholesaleFundingCost={data.wholesaleFundingCosts}
                referenceRate={data.referenceRate}
                currentCreditRating={creditRating}
                defaultReferenceRateName={defaultReferenceRateName}
              />
            </div>
          </VerticalGroup>
          <VerticalGroup
            gap={2}
            className="relative divide-y-2 divide-gray-300 laptop:w-[42%] laptop:border-l-2"
          >
            <div
              id={!isMobile ? 'market-outlook' : undefined}
              className="hidden laptop:block laptop:pl-4"
            >
              <BreakingNewsMarketOutlook marketOutlook={data.marketOutlook} />
            </div>

            <div id="scenarios" className="laptop:pl-4">
              <BreakingNewsScenarios newsConfig={newsConfig} news={data} />
            </div>
          </VerticalGroup>
        </VerticalGroup>
      </VerticalGroup>
      <BreakingNewsTour />
    </>
  )
}

export default BreakingNews
