import React, { useCallback } from 'react'
import { Business } from '../../../../types/gameApi/business'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import { getBusinessColour, getIconForBusiness } from '../../../../lib/icons'
import {
  formatPrice1,
  formatPrice2,
  getDataRanges,
  getPrice1Change,
  getPrice2Change,
  getPrice2ValueChangerType,
} from './formatters'
import BusinessDecisionitemPrice from './BusinessDecisionItemPrice'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { BusinessDecision } from '../BusinessesContainer'
import { titleCase } from '../../../../lib/text'

interface Props {
  data: Business
  index: number
  decisions: BusinessDecision
  onChangeValue: (
    type: string,
    field: keyof BusinessDecision,
    value: number,
  ) => void
  enableChanges: boolean
  isFirstItem: boolean
}

const BusinessDecisionitem: React.FC<Props> = ({
  data,
  index,
  decisions,
  onChangeValue,
  enableChanges,
  isFirstItem,
}) => {
  const {
    price1Prev,
    price2Prev,
    type,
    slider1Min,
    slider1Max,
    slider2Min,
    slider2Max,
    price1Type,
    price2Type,
    price1TypeInfoBody,
    price2TypeInfoBody,
  } = data
  const { price1Now, price2Now } = decisions
  const { value: price1ChangeValue, units: price1ChangeUnits } =
    getPrice1Change(price1Now, price1Prev, type)
  const { value: price2ChangeValue, units: price2ChangeUnits } =
    getPrice2Change(price2Now, price2Prev, type)
  const price1DataRanges = getDataRanges(
    price1Now,
    price1Prev,
    slider1Min,
    slider1Max,
  )
  const price2DataRanges = getDataRanges(
    price2Now,
    price2Prev,
    slider2Min,
    slider2Max,
  )
  const colour = getBusinessColour(index)
  const onChange = useCallback(
    (priceType: keyof BusinessDecision) => (val: number) => {
      onChangeValue(type, priceType, val)
    },
    [onChangeValue, type],
  )

  return (
    <div
      data-test={`business-item-${data.type}`}
      className="h-[93%] monitor:h-full"
    >
      <HorizontalGroup
        center
        verticalCenter
        className="rounded-t-xl border border-b-0 border-gray-300 bg-white pt-2"
      >
        <SecondaryText transform="upper" weight="bold" size="lg">
          {data.name}
        </SecondaryText>
      </HorizontalGroup>
      <HorizontalGroup className="relative h-full">
        <div
          id="business2"
          className="h-[90%] w-full overflow-hidden rounded-bl-xl border border-r-0 border-t-0 border-gray-300 bg-white"
        >
          <BusinessDecisionitemPrice
            id={isFirstItem ? 'tour-item-price' : undefined}
            data-test="business-price-1"
            infoBody={price1TypeInfoBody}
            enableChanges={enableChanges}
            modalChanger={{
              heading: `${titleCase(data.name)}: ${price1Type}`,
            }}
            colour={colour}
            donut={{
              min: price1DataRanges.min,
              max: price1DataRanges.max,
              value: price1DataRanges.value,
            }}
            min={slider1Min}
            max={slider1Max}
            value={decisions.price1Now}
            changeValue={price1ChangeValue}
            changeUnits={price1ChangeUnits}
            previousPrice={formatPrice1(price1Prev, type)}
            formatter={val => formatPrice1(val, type)}
            priceType={price1Type}
            previousPriceRaw={price1Prev}
            showSlider={false}
            valueChangeType={type !== 'CREDIT_CARDS' ? 'percentage' : 'dollar'}
            onChange={onChange('price1Now')}
            buttonIdPrefix={`${type}-price1-`}
            analyticsLabel={`${type}-price`}
          />
        </div>
        <div className="absolute left-[50%] top-[10px] h-[calc(100%-60px)] w-px bg-gray-200"></div>
        <div className="h-[90%] w-full overflow-hidden rounded-br-xl border border-l-0 border-t-0 border-gray-300 bg-white">
          <BusinessDecisionitemPrice
            id={isFirstItem ? 'tour-item-secondary-decision' : undefined}
            data-test="business-price-2"
            infoBody={price2TypeInfoBody}
            enableChanges={enableChanges}
            modalChanger={{
              heading: `${titleCase(data.name)}: ${price2Type}`,
            }}
            icon={getIconForBusiness(type)}
            colour={colour}
            donut={{
              min: price2DataRanges.min,
              max: price2DataRanges.max,
              value: price2DataRanges.value,
            }}
            min={slider2Min}
            max={slider2Max}
            value={decisions.price2Now}
            changeValue={price2ChangeValue}
            changeUnits={price2ChangeUnits}
            increments={type === 'HOME_LOANS' ? [1, 5] : undefined}
            previousPrice={formatPrice2(price2Prev, type)}
            formatter={val => formatPrice2(val, type)}
            priceType={price2Type}
            previousPriceRaw={price2Prev}
            showSlider={
              type === 'BUSINESS_LOANS' || type === 'WEALTH_MANAGEMENT'
            }
            valueChangeType={getPrice2ValueChangerType(type)}
            onChange={onChange('price2Now')}
            buttonIdPrefix={`${type}-price2-`}
            analyticsLabel={`${type}-secondary`}
          />
        </div>
      </HorizontalGroup>
    </div>
  )
}

export default BusinessDecisionitem
