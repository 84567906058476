import React, { useMemo } from 'react'
import {
  TreasuryBusinessDecision,
  TreasuryResponse,
} from '../../../../types/gameApi/treasury'
import { DecisionMap } from '../TreasuryContainer'
import { calculateAssetsAndForecasts } from '../calculations'
import TreasuryBalanceSheetChart from './TreasuryBalanceSheetChart'
import TreasuryBalanceSheetDecisions from './TreasuryBalanceSheetDecisions'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import MultiInAppHelp, {
  Candidate,
} from '../../../organisms/InAppHelp/MultiInAppHelp'
import InAppHelpContent from '../../../organisms/InAppHelp/InAppHelpContent'
import Text from '../../../atoms/Text/Text'
import { calculateSignedPercentage } from '../../Businesses/utils'
import { useTranslatedText } from '../../../../hooks/useTranslatedText'
import { percentage } from '../../../../lib/formatters'

interface Props {
  data: TreasuryResponse
  decisions: DecisionMap
  enableChanges: boolean
  onChangeValue: (val: Partial<DecisionMap>) => void
  selectedRound: number
  businessDecisions: TreasuryBusinessDecision[]
}

const TreasuryBalanceSheet: React.FC<Props> = ({
  data,
  decisions,
  enableChanges,
  onChangeValue,
  selectedRound,
  businessDecisions,
}) => {
  const {
    allOtherAssetsForecast,
    cashForecastWithAutoWSF,
    wholesaleFundingForecast,
    depositsForecast,
    shareholdersFundsForecast,
    forecastEirAssetPercentage,
    forecastEirAssetPercentageChange,
    forecastEirMargin,
    marginChange,
    forecastEirCostPercentage,
    forecastEirCostPercentageChange,
    targetShareCapitalMovement,
  } = calculateAssetsAndForecasts({ data, decisions })
  const breakingNewsText = useTranslatedText(
    'mainMenu.newsButton',
    'Breaking News',
  )
  const candidates = useMemo(() => {
    const results: Candidate[] = []
    let hasExceededAssetGrowthRate = false
    let hasExceededDepositGrowthRate = false
    let depositDecision: TreasuryBusinessDecision | undefined

    if (businessDecisions) {
      for (const decision of businessDecisions) {
        if (decision.business_type === 'DEPOSITS') {
          const decisionHasBeenMade =
            decision.volume_now !== decision.volume_now_original
          if (!decisionHasBeenMade) {
            continue
          }
          const volumeDifference = calculateSignedPercentage(
            decision.volume_now,
            decision.volume_prev,
          )
          const roundedChangeInVolume = Math.round(volumeDifference * 100)
          const volumeDifferenceFromGrowthRate = Math.abs(
            roundedChangeInVolume - decision.growthRate,
          )
          if (volumeDifferenceFromGrowthRate > 500) {
            hasExceededDepositGrowthRate = true
            depositDecision = decision
            break
          }
        } else {
          const decisionHasBeenMade =
            decision.volume_now !== decision.volume_now_original
          if (!decisionHasBeenMade) {
            continue
          }
          const volumeDifference = calculateSignedPercentage(
            decision.volume_now,
            decision.volume_prev,
          )
          const roundedChangeInVolume = Math.round(volumeDifference * 100)
          const volumeDifferenceFromGrowthRate = Math.abs(
            roundedChangeInVolume - decision.growthRate,
          )
          if (volumeDifferenceFromGrowthRate > 500) {
            hasExceededAssetGrowthRate = true
            break
          }
        }
      }
      if (hasExceededAssetGrowthRate) {
        const target = '#treasury-balance-sheet-asset-growth'
        results.push({
          storageKey: `round-${selectedRound}-asset-treasury-growth-rate-help`,
          analytics: {
            type: 'dynamic',
            params: {
              category: 'treasury',
              label: 'growth-rate-help',
              action: 'open',
              value: 'ASSETS',
            },
          },
          steps: [
            {
              target,
              content: (
                <InAppHelpContent isWarning heading="Check Your Growth Rate">
                  <Text left>
                    {`Your forecast Asset growth rate is very different to the growth rate of the overall market (see ${breakingNewsText}). Please review and ensure you are comfortable with this difference.`}
                  </Text>
                </InAppHelpContent>
              ),
            },
          ],
        })
      }

      if (hasExceededDepositGrowthRate && depositDecision) {
        const target = '#treasury-balance-sheet-deposit-growth'
        const growthRateFromPercentage = depositDecision.growthRateFrom / 100
        const growthRateToPercentage = percentage(
          depositDecision.growthRateTo,
          0,
        )

        results.push({
          storageKey: `round-${selectedRound}-deposits-treasury-growth-rate-help`,
          analytics: {
            type: 'dynamic',
            params: {
              category: 'treasury',
              label: 'growth-rate-help',
              action: 'open',
              value: 'DEPOSITS',
            },
          },
          steps: [
            {
              target,
              content: (
                <InAppHelpContent isWarning heading="Check Your Growth Rate">
                  <Text left>
                    {`Your forecast Deposit growth rate is very different to the growth rate (${growthRateFromPercentage} to ${growthRateToPercentage}) of the overall market (see ${breakingNewsText}). Please review and ensure you are comfortable with this difference.`}
                  </Text>
                </InAppHelpContent>
              ),
            },
          ],
        })
      }
    }

    return results
  }, [businessDecisions, selectedRound, breakingNewsText])

  return (
    <VerticalGroup full fullWidth gap={3} className="gap-4 laptop:flex-row">
      <div className="laptop:w-1/2">
        <TreasuryBalanceSheetChart
          allOtherAssetsForecast={allOtherAssetsForecast}
          cashForecastWithAutoWSF={cashForecastWithAutoWSF}
          wholesaleFundingForecast={wholesaleFundingForecast}
          depositsForecast={depositsForecast}
          shareholdersFundsForecast={shareholdersFundsForecast}
          forecastEirAssetPercentage={forecastEirAssetPercentage}
          forecastEirAssetPercentageChange={forecastEirAssetPercentageChange}
          forecastEirMargin={forecastEirMargin}
          marginChange={marginChange}
          forecastEirCostPercentage={forecastEirCostPercentage}
          forecastEirCostPercentageChange={forecastEirCostPercentageChange}
        />
      </div>
      <div className="laptop:w-1/2">
        <TreasuryBalanceSheetDecisions
          enableChanges={enableChanges}
          decisions={decisions}
          data={data}
          targetShareCapitalMovement={targetShareCapitalMovement}
          onChangeValue={onChangeValue}
        />
      </div>
      <MultiInAppHelp isWarning disableOverlayClose candidates={candidates} />
    </VerticalGroup>
  )
}

export default TreasuryBalanceSheet
