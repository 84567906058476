import React from 'react'
import PrimaryText from '../../../atoms/Text/PrimaryText'

type Props = {
  hasQuestionIndex: boolean
  questionIndex?: number
  title: string
}

const QuestionTitle: React.FC<Props> = ({
  hasQuestionIndex,
  title,
  questionIndex = -1,
}) => {
  if (!hasQuestionIndex) {
    return (
      <PrimaryText size="xl" isHtml>
        {title}
      </PrimaryText>
    )
  }

  return (
    <div className="relative">
      <PrimaryText size="xl" isHtml>
        {title}
      </PrimaryText>
      <PrimaryText
        size="xl"
        className="absolute top-0 -left-8"
        colour="primary"
      >
        {questionIndex + 1}.
      </PrimaryText>
    </div>
  )
}

export default QuestionTitle
