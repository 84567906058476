import React, { useCallback, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'
import { useAppSelector } from '../../../store'
import WarningModal from '../../atoms/Modal/WarningModal'

const WarningAlert: React.FC = () => {
  const { warningMessage } = useAppSelector(state => ({
    warningMessage: state.game.warningMessage,
  }))
  const [showModal, setShowModal] = useState(!!warningMessage)

  const previousValue = usePrevious(warningMessage)

  useEffect(() => {
    if (previousValue === null && warningMessage !== null) {
      setShowModal(true)
    } else if (previousValue !== null && warningMessage === null) {
      setShowModal(false)
    }
  }, [previousValue, warningMessage])

  const onClose = useCallback(() => {
    setShowModal(false)
  }, [])

  const isVisible = !!(showModal && warningMessage)

  return (
    <WarningModal
      showModal={isVisible}
      onClose={onClose}
      message={warningMessage?.message ?? ''}
      heading={warningMessage?.title ?? ''}
    />
  )
}

export default WarningAlert
