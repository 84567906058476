import React from 'react'
import SummaryHelp from '../SummaryHelp/SummaryHelp'
import BreakingNewsHelp from '../BreakingNewsHelp/BreakingNewsHelp'
import PeerInsightsHelp from '../PeerInsightsHelp/PeerInsightsHelp'
import OurFinancialsHelp from '../OurFinancialsHelp/OurFinancialsHelp'
import HelpManualHelp from '../HelpManualHelp/HelpManualHelp'
import ExcoDecisionsHelp from '../ExcoDecisionsHelp/ExcoDecisionsHelp'
import SpecialProjectsHelp from '../SpecialProjectsHelp/SpecialProjectsHelp'
import CostsHelp from '../CostsHelp/CostsHelp'
import BusinessesHelp from '../BusinessesHelp/BusinessesHelp'
import TreasuryHelp from '../TreasuryHelp/TreasuryHelp'
import DealRoomHelp from '../DealRoomHelp/DealRoomHelp'

interface Props {
  selectedItem: string
}

const SelectedItemContent: React.FC<Props> = ({ selectedItem }) => {
  switch (selectedItem) {
    case 'summary':
      return <SummaryHelp />
    case 'breaking-news':
      return <BreakingNewsHelp />
    case 'peer-insights':
      return <PeerInsightsHelp />
    case 'our-financials':
      return <OurFinancialsHelp />
    case 'help-manual':
      return <HelpManualHelp />
    case 'exco-decisions':
      return <ExcoDecisionsHelp />
    case 'special-projects':
      return <SpecialProjectsHelp />

    case 'costs':
      return <CostsHelp />
    case 'businesses':
      return <BusinessesHelp />
    case 'treasury':
      return <TreasuryHelp />
    case 'deal-room':
      return <DealRoomHelp />
    default:
      return null
  }
}

export default SelectedItemContent
