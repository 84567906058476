import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import APIError from '../../errors/APIError'
import {
  BroadcastMessage,
  DeviceStatus,
  DeviceStatusResponse,
  Results,
  RoundSetup,
  RoundSetupBase,
  WarningMessage,
} from '../../types/gameApi/device'
import { submitRound } from './gameActions'
import { addVariables } from '../../lib/googleTagManager'

const LOCAL_STORAGE_PREFIX = 'dismissedBroadcastMessage:'

export interface GameState {
  gameId: string | null
  gameName: string | null
  appTitle: string | null
  stream: string | null
  gameDate: string | null
  logo: string | null
  splashScreenTitle: string | null
  roundType: string | null
  shouldRotateCeo: boolean
  maskParticipants: boolean
  selectedRound: number
  currentRound: number
  currentRoundSetup: RoundSetupBase
  roundsSetup: RoundSetup[]
  totalRounds: number
  state: DeviceStatus
  finishTime: string | null
  nextRoundStartTime: string | null
  gameStartTime: string | null
  warningMessage: WarningMessage | null
  broadcastMessage: BroadcastMessage | null
  submitError: Error | APIError | null
  results: Results[]
  automate: {
    testModeScreen: boolean
    menuTimeMax: number
    menuTimeMin: number
    testModeWidgets: boolean
    widgetTimeMin: number
    widgetTimeMax: number
    widgetMaxPricingChange: number
  }
  automateRedirectTo: string | null
  resultsCastUrl: string | null
  dismissedBroadcastMessages: Record<string, boolean>
}
const dismissedBroadcastMessagesFromLocalStorage = Object.keys(
  localStorage,
).reduce<Record<string, boolean>>((acc, key) => {
  if (key.startsWith(LOCAL_STORAGE_PREFIX)) {
    acc[key.replace(LOCAL_STORAGE_PREFIX, '')] = true
  }
  return acc
}, {})
const initialState: GameState = {
  gameId: null,
  gameName: null,
  appTitle: null,
  stream: null,
  gameDate: null,
  logo: null,
  splashScreenTitle: null,
  roundType: null,
  shouldRotateCeo: true,
  maskParticipants: false,
  selectedRound: 0,
  currentRound: 0,
  currentRoundSetup: {
    insightsEnabled: false,
    dealsEnabled: false,
    excoEnabled: false,
    specialProjectsEnabled: false,
    surveyEnabled: false,
    operatingCostsEnabled: false,
    startTime: '',
    finishTime: '',
    time: 0,
  },
  roundsSetup: [],
  totalRounds: 1,
  state: 'locked',
  finishTime: null,
  nextRoundStartTime: null,
  gameStartTime: null,
  warningMessage: null,
  broadcastMessage: null,
  submitError: null,
  results: [],
  automate: {
    testModeScreen: false,
    menuTimeMax: 0,
    menuTimeMin: 0,
    testModeWidgets: false,
    widgetTimeMin: 0,
    widgetTimeMax: 0,
    widgetMaxPricingChange: 0,
  },
  automateRedirectTo: null,
  resultsCastUrl: null,
  dismissedBroadcastMessages: dismissedBroadcastMessagesFromLocalStorage,
}

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    changeAutomateRedirect: (state, action: PayloadAction<string>) => {
      state.automateRedirectTo = action.payload
    },
    changeFinishTime: (
      state,
      action: PayloadAction<string | null | undefined>,
    ) => {
      state.finishTime = action.payload ?? null
    },
    changeGameState: (state, action: PayloadAction<DeviceStatusResponse>) => {
      if (state.gameId !== action.payload.gameId) {
        addVariables({
          roundType: action.payload.roundType,
        })
      }
      if (state.currentRound !== action.payload.currentRound) {
        addVariables({
          currentRound: action.payload.currentRound,
        })
      }
      state.gameId = action.payload.gameId
      state.gameName = action.payload.gameName
      state.appTitle = action.payload.appTitle
      state.stream = action.payload.stream
      state.gameDate = action.payload.gameDate
      state.logo = action.payload.logo
      state.splashScreenTitle = action.payload.splashScreenTitle
      state.state = action.payload.status
      state.selectedRound =
        action.payload.currentRound === state.currentRound
          ? state.selectedRound
          : action.payload.currentRound
      state.currentRound = action.payload.currentRound
      state.currentRoundSetup = action.payload.currentRoundSetup
      state.roundsSetup = action.payload.roundsSetup
      state.totalRounds = action.payload.totalRounds
      state.warningMessage = action.payload.warningMessage ?? null
      state.broadcastMessage = action.payload.broadcastMessage ?? null
      state.roundType = action.payload.roundType
      state.shouldRotateCeo = action.payload.shouldRotateCeo
      state.maskParticipants = action.payload.maskParticipants
      state.nextRoundStartTime = action.payload.nextRoundStartTime
      state.gameStartTime = action.payload.gameStartTime
      state.automate = action.payload.automate || state.automate
      state.results = action.payload.results
      state.resultsCastUrl = action.payload.resultsCastUrl
    },
    updateSelectedRound: (state, action: PayloadAction<number>) => {
      state.selectedRound = action.payload
    },
    reset: () => initialState,
    dismissBroadcastMessage: (state, action: PayloadAction<string>) => {
      state.dismissedBroadcastMessages = {
        ...state.dismissedBroadcastMessages,
        [action.payload]: true,
      }
      localStorage.setItem(`${LOCAL_STORAGE_PREFIX}${action.payload}`, 'true')
      state.broadcastMessage = null
    },
  },
  extraReducers: builder => {
    builder
      // .addCase(submitRound.pending, () => {

      // })
      .addCase(submitRound.fulfilled, state => {
        state.state = 'locked'
      })
      .addCase(submitRound.rejected, (state, { payload }) => {
        state.submitError = payload ?? null
      })
  },
})

export const {
  changeAutomateRedirect,
  changeGameState,
  reset,
  changeFinishTime,
  updateSelectedRound,
  dismissBroadcastMessage,
} = gameSlice.actions

export default gameSlice.reducer
