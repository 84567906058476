import React, { FC, ReactNode, useCallback } from 'react'
import Clickable from '../../atoms/Clickable/Clickable'
import { Icon } from '../../atoms/Icon/Icon'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import SecondaryText from '../../atoms/Text/SecondaryText'
import classNames from 'classnames'

type Props = {
  value: number
  percentageChange?: () => ReactNode
  formatValue: (value: number) => string
  onDecrement?: (value: number) => void
  onIncrement?: (value: number) => void
  min?: number
  max?: number
  verticalText?: boolean
  fontBold?: boolean
  enableChanges: boolean
  idPrefix?: string
}

const ValueChangerWithPercentageChange: FC<Props> = ({
  value,
  percentageChange,
  formatValue,
  onDecrement,
  onIncrement,
  min,
  max,
  enableChanges,
  verticalText,
  fontBold,
  idPrefix,
}) => {
  const minDisabled = !enableChanges || (min != null && value === min)
  const maxDisabled = !enableChanges || (max != null && value === max)

  const handleDecrement = useCallback(() => {
    if (!minDisabled && !!onDecrement) onDecrement(value)
  }, [minDisabled, onDecrement, value])

  const handleIncrement = useCallback(() => {
    if (!maxDisabled && !!onIncrement) onIncrement(value)
  }, [maxDisabled, onIncrement, value])

  return (
    <HorizontalGroup between center gap={2} className="laptop:w-60">
      {enableChanges && (
        <Clickable
          data-test="minus-button"
          disabled={minDisabled}
          onClick={handleDecrement}
          className=""
        >
          <Icon
            className="h-6 w-6 laptop:h-8 laptop:w-8 desktop:h-10 desktop:w-10"
            type="minus"
            disabled={minDisabled}
            colour="primary"
          />
        </Clickable>
      )}

      <div
        className={classNames('flex items-center justify-center gap-2', {
          'flex w-full flex-row items-center justify-center': !verticalText,
          'w-full flex-col gap-0': verticalText,
        })}
      >
        <div
          id={idPrefix ? `${idPrefix}-all-percentage` : undefined}
          className={classNames({
            'flex w-fit justify-center space-x-2 px-1': !verticalText,
            'flex w-8 justify-end': !verticalText && percentageChange,
            'flex flex-col items-center justify-center':
              verticalText && percentageChange,
            'flex justify-center': !percentageChange,
          })}
        >
          <div
            className={classNames({
              'w-12': !verticalText,
              'flex w-fit justify-end': !verticalText && percentageChange,
              'flex justify-center': !percentageChange,
            })}
          >
            <SecondaryText
              id={idPrefix ? `${idPrefix}-percentage-normal` : undefined}
              size="sm"
              weight={fontBold ? 'bold' : 'normal'}
              className="laptop:text-base"
            >
              {formatValue(value)}
            </SecondaryText>
          </div>
          {percentageChange && (
            <div
              className={classNames({
                'tablet:w-full': !verticalText,
              })}
            >
              <SecondaryText
                id={idPrefix ? `${idPrefix}-percentage` : undefined}
                size="sm"
                className="laptop:text-base"
                weight={fontBold ? 'bold' : 'normal'}
              >
                {percentageChange()}
              </SecondaryText>
            </div>
          )}
        </div>
      </div>

      {enableChanges && (
        <Clickable
          data-test="plus-button"
          disabled={maxDisabled}
          onClick={handleIncrement}
        >
          <Icon
            className="h-6 w-6 laptop:h-8 laptop:w-8 desktop:h-10 desktop:w-10"
            type="plus"
            disabled={maxDisabled}
            colour="primary"
          />
        </Clickable>
      )}
    </HorizontalGroup>
  )
}

export default ValueChangerWithPercentageChange
