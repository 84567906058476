import React, { useCallback, useEffect, useMemo } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { ChoiceField } from './Field/ChoiceField'
import {
  useCharacterLimitValidator,
  WrittenResponseField,
} from './Field/WrittenResponseField'
import {
  Answer,
  AssessmentQuestion,
  CurrentAnswer,
  MultipleChoiceField,
  WrittenResponseField as WrittenResponseFieldType,
} from '../../../../types/gameApi/assessments'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import QuestionTitle from './QuestionTitle'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import QuestionContainer from './QuestionContainer'

interface Props {
  questionIndex: number
  question: AssessmentQuestion
  currentAnswer?: CurrentAnswer
}

const onChangeHandler = (
  currentValue: Answer[],
  field: { id: string },
  onChange: (values: Answer[]) => void,
) => {
  if (currentValue && currentValue.length > 0) {
    if (currentValue.find(answer => answer.fieldId === field.id)) {
      onChange(currentValue.filter(answer => answer.fieldId !== field.id))
    } else {
      onChange([...currentValue, { fieldId: field.id }])
    }
  } else {
    onChange([{ fieldId: field.id }])
  }
}

const setFieldValue = (
  currentValue: Answer[],
  field: { id: string },
  value: string,
  onChange: (values: Answer[]) => void,
) => {
  if (!currentValue || currentValue.length === 0) {
    return
  }

  onChange(
    currentValue.map(answer => {
      if (answer.fieldId === field.id) {
        return { fieldId: field.id, value }
      }

      return answer
    }),
  )
}

const getFieldValue = (currentValue: Answer[], field: { id: string }) => {
  if (!currentValue || currentValue.length === 0) {
    return ''
  }

  const answer = currentValue.find(answer => answer.fieldId === field.id)
  return answer ? answer.value : ''
}

const hasField = (currentValue: Answer[], field: { id: string }) => {
  if (!currentValue || currentValue.length === 0) {
    return false
  }

  return !!currentValue.find(answer => answer.fieldId === field.id)
}

export const MultipleChoice: React.FC<Props> = ({
  question,
  questionIndex,
  currentAnswer,
}) => {
  const { control, setValue, getValues } = useFormContext()
  const { fields, id } = question

  const choiceFields = useMemo(() => {
    return fields.filter(field => field.type === 'multiple_choices')
  }, [fields]) as MultipleChoiceField[]

  const otherField = useMemo(() => {
    return fields.find(field => field.type === 'written_response')
  }, [fields]) as WrittenResponseFieldType

  const validate = useCallback(
    (value: Answer[]) => {
      if (!question.mandatory) {
        return true
      }

      if (typeof value === 'undefined' || value.length === 0) {
        return 'Should have at least have one choice selected'
      }

      return true
    },
    [question.mandatory],
  )

  useEffect(() => {
    if (
      typeof currentAnswer !== 'undefined' &&
      typeof getValues()[id] === 'undefined'
    ) {
      setValue(id, currentAnswer.answer)
    }
  }, [currentAnswer, id, setValue, getValues])

  const otherFieldWithinLimit = useCharacterLimitValidator(
    otherField,
    getFieldValue,
  )

  return (
    <Controller
      name={id}
      control={control}
      shouldUnregister={false}
      rules={{
        validate: {
          required: validate,
          otherFieldWithinLimit: otherFieldWithinLimit,
        },
      }}
      render={({
        field: { onChange, value },
        fieldState: { error, invalid },
      }) => {
        return (
          <QuestionContainer>
            <QuestionTitle
              hasQuestionIndex
              questionIndex={questionIndex}
              title={question.question}
            />
            <VerticalGroup gap={2}>
              {choiceFields.map((field, index) => (
                <ChoiceField
                  key={field.id}
                  index={index}
                  field={field}
                  selected={hasField(value, field)}
                  onClick={field => {
                    onChangeHandler(value, field, onChange)
                  }}
                />
              ))}
              {otherField && (
                <ChoiceField
                  key={otherField.id}
                  index={choiceFields.length}
                  field={{
                    id: otherField.id,
                    type: 'multiple_choices',
                    config: {
                      type: 'multiple_choices',
                      label: 'Other',
                    },
                  }}
                  selected={hasField(value, otherField)}
                  onClick={field => {
                    onChangeHandler(value, field, onChange)
                  }}
                />
              )}
              {value && otherField && hasField(value, otherField) && (
                <WrittenResponseField
                  placeholder="Type your other answer here"
                  onChange={newValue => {
                    setFieldValue(value, otherField, newValue, onChange)
                  }}
                  value={getFieldValue(value, otherField) || ''}
                  characterLimit={otherField.config.characterLimit}
                />
              )}
            </VerticalGroup>
            {invalid && error && (
              <SecondaryText colour="error" size="xs">
                {error.message}
              </SecondaryText>
            )}
          </QuestionContainer>
        )
      }}
    />
  )
}
