import React, { useCallback, useState, useEffect } from 'react'
import Joyride, { ACTIONS, CallBackProps, EVENTS, Step } from 'react-joyride'
import { CustomTourSteps } from './components/CustomTourComponents'

export interface GuidedTourStep extends Step {
  onClick: () => void
}

export type Props = {
  steps: Array<Partial<GuidedTourStep>>
  onClose?: () => void
  mode?: 'floater' | 'beacon'
  isWarning?: boolean
  disableScrolling?: boolean
}

const GuidedTour: React.FC<Props> = ({
  steps,
  onClose,
  mode = 'floater',
  isWarning = false,
  disableScrolling = false,
}) => {
  const [run, setRun] = useState(true)
  const [stepIndex, setStepIndex] = useState(0)
  const [overlayHeight, setOverlayHeight] = useState('100vh')

  useEffect(() => {
    const updateOverlayHeight = () => {
      const height = Math.max(
        document.documentElement.scrollHeight,
        window.innerHeight,
      )
      setOverlayHeight(`${height}px`)
    }

    updateOverlayHeight()
    window.addEventListener('resize', updateOverlayHeight)
    window.addEventListener('scroll', updateOverlayHeight)

    return () => {
      window.removeEventListener('resize', updateOverlayHeight)
      window.removeEventListener('scroll', updateOverlayHeight)
    }
  }, [])

  const handleJoyrideCallback = useCallback(
    (data: CallBackProps) => {
      const { type, step, action } = data
      if (type === EVENTS.STEP_AFTER) {
        const { index } = data
        console.log(`Step ${index + 1} completed (${step.target})`)
        setStepIndex(index + 1)
      }
      if (type === EVENTS.TOUR_END) {
        setRun(false)
        if (onClose) {
          onClose()
        }
      }
      if (action === ACTIONS.CLOSE) {
        setRun(false)
        if (onClose) {
          onClose()
        }
      }
    },
    [onClose],
  )
  const handleNextClick = useCallback(() => {
    if (stepIndex < steps.length - 1) {
      const currentStep = steps[stepIndex]
      if (currentStep.onClick) {
        currentStep.onClick()
      }
      setStepIndex(stepIndex + 1)
    } else {
      setRun(false)
      if (onClose) {
        onClose()
      }
    }
  }, [stepIndex, steps, onClose])

  const handleSkipClick = useCallback(() => {
    setRun(false)
    if (onClose) {
      onClose()
    }
  }, [onClose])

  return (
    <Joyride
      disableScrolling={disableScrolling}
      styles={{
        overlay: {
          height: overlayHeight,
        },
      }}
      steps={CustomTourSteps({
        steps,
        stepIndex,
        handleSkipClick,
        handleNextClick,
        mode,
        isWarning,
      })}
      run={run}
      callback={handleJoyrideCallback}
      stepIndex={stepIndex}
    />
  )
}

export default GuidedTour
