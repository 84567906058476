import React from 'react'
import Button from '../Button/Button'
import { Icon } from '../Icon/Icon'
import classNames from 'classnames'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  direction: 'previous' | 'next'
  onClick: () => void
  disabled?: boolean
  arrowPosition?: 'normal' | 'fixed'
}

const CustomNavigationArrow: React.FC<Props> = ({
  direction,
  onClick,
  disabled = false,
  arrowPosition = 'normal',
  ...rest
}) => {
  const classes = classNames('p-1', {
    'absolute left-6 z-10':
      arrowPosition === 'fixed' && direction === 'previous',
    'absolute right-6 z-10': arrowPosition === 'fixed' && direction === 'next',
  })

  const iconColour = disabled ? 'lightgray' : 'primary'

  return (
    <div {...rest} className={classes}>
      <Button
        size="xs"
        buttonType="secondary"
        onClick={onClick}
        disabled={disabled}
        data-test={direction === 'previous' ? 'prevButton' : 'nextButton'}
      >
        <Icon
          size={6}
          colour={iconColour}
          type={direction === 'previous' ? 'chevronLeft' : 'chevronRight'}
        ></Icon>
      </Button>
    </div>
  )
}

export default CustomNavigationArrow
