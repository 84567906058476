import { BusinessResponse } from '../../../../../types/gameApi/business'

export const data: BusinessResponse = {
  businesses: [
    {
      history: [
        {
          year: 0,
          price1: 488,
          volume: 162304,
        },
        {
          year: 1,
          price1: 476,
          volume: 182855,
        },
        {
          year: 2,
          price1: 504,
          volume: 211667,
        },
      ],
      id: 1,
      price1Prev: 504,
      price1Now: 484,
      price1NowOriginal: 504,
      slider1Min: 404,
      slider1Max: 604,
      price2Prev: 18,
      price2Now: 18,
      price2NowOriginal: 18,
      slider2Min: 13,
      slider2Max: 23,
      volumePrev: 211667,
      volumeNow: 215518,
      volumeNowOriginal: 213402,
      incomePrev: 9942,
      type: 'HOME_LOANS',
      name: 'HOME LOANS',
      price1Type: 'Interest Rate',
      price1TypeInfoBody:
        'Lower interest rates might attract more customers, but may also mean less income. The reverse is also true',
      price2Type: 'Broker Commissions',
      price2TypeInfoBody:
        'The annual investment aims to simplify broker interactions with your bank. It includes dedicated broker channel support, ongoing process simplification, transparent credit decision-making, clear commission structures, and ongoing education. Increased investment in brokers will raise operating costs. Customers acquired through brokers tend to be price-sensitive, potentially less loyal, and prone to higher loss rates during stressful periods.',
      version: 3,
      updatedBy: '7430fd3e-65da-42ea-b8a5-699600cc1a4f',
    },
    {
      history: [
        {
          year: 0,
          price1: 618,
          volume: 56814,
        },
        {
          year: 1,
          price1: 643,
          volume: 57974,
        },
        {
          year: 2,
          price1: 667,
          volume: 61122,
        },
      ],
      id: 2,
      price1Prev: 667,
      price1Now: 652,
      price1NowOriginal: 667,
      slider1Min: 567,
      slider1Max: 767,
      price2Prev: 1,
      price2Now: 1,
      price2NowOriginal: 1,
      slider2Min: -1,
      slider2Max: 1,
      volumePrev: 61122,
      volumeNow: 57839,
      volumeNowOriginal: 59062,
      incomePrev: 3972,
      type: 'BUSINESS_LOANS',
      name: 'BUSINESS LOANS',
      price1Type: 'Interest Rate',
      price1TypeInfoBody:
        'Lower interest rates might attract more customers, but may also mean less income. The reverse is also true',
      price2Type: 'Lending Criteria',
      price2TypeInfoBody:
        'Tighter lending criteria restricts lending to better quality customers, which may reduce future losses. This may also limit growth. The opposite is true for loose lending criteria.',
      version: 4,
      updatedBy: '7430fd3e-65da-42ea-b8a5-699600cc1a4f',
    },
    {
      history: [
        {
          year: 0,
          price1: 263,
          volume: 116600,
        },
        {
          year: 1,
          price1: 274,
          volume: 125142,
        },
        {
          year: 2,
          price1: 299,
          volume: 135004,
        },
      ],
      id: 3,
      price1Prev: 299,
      price1Now: 269,
      price1NowOriginal: 299,
      slider1Min: 199,
      slider1Max: 399,
      price2Prev: 815,
      price2Now: 820,
      price2NowOriginal: 815,
      slider2Min: 765,
      slider2Max: 865,
      volumePrev: 135004,
      volumeNow: 144453,
      volumeNowOriginal: 145804,
      incomePrev: -3889,
      type: 'DEPOSITS',
      name: 'DEPOSITS',
      price1Type: 'Interest Rate',
      price1TypeInfoBody:
        'Higher interest rates attract more customers as customers often like to leave their money where it is earning them good rates. Paying higher interest rates costs your bank more.',
      price2Type: 'Branches',
      price2TypeInfoBody:
        'Improving customers’ access through a larger network may attract more customers. Each new branch costs 1m to fit-out and 500k p.a. to maintain (leasing). Reducing the size of the physical network reduces ongoing leasing costs, however expect 1 additional customer complaint per branch closed.',
      version: 4,
      updatedBy: '7430fd3e-65da-42ea-b8a5-699600cc1a4f',
    },
    {
      history: [
        {
          year: 0,
          price1: 250,
          volume: 1796523,
        },
        {
          year: 1,
          price1: 275,
          volume: 1675718,
        },
        {
          year: 2,
          price1: 275,
          volume: 1597671,
        },
      ],
      id: 4,
      price1Prev: 275,
      price1Now: 275,
      price1NowOriginal: 275,
      slider1Min: 175,
      slider1Max: 375,
      price2Prev: 150,
      price2Now: 150,
      price2NowOriginal: 150,
      slider2Min: 100,
      slider2Max: 200,
      volumePrev: 1597671,
      volumeNow: 1377991,
      volumeNowOriginal: 1377991,
      incomePrev: 450,
      type: 'CREDIT_CARDS',
      name: 'CREDIT CARDS',
      price1Type: 'Annual Card Fee',
      price1TypeInfoBody:
        'Lower annual fees attract more customers. Lower fees may generate less income.',
      price2Type: 'Reward Points',
      price2TypeInfoBody:
        'Paying higher reward points attracts more customers however paying higher reward points will increase costs.',
      version: 0,
      updatedBy: null,
    },
  ],
  rates: {
    creditCards: {
      ratePrev: 1235,
      rateNow: 1235,
    },
    cash: {
      ratePrev: 275,
      rateNow: 250,
    },
    wsf: {
      ratePrev: 392,
      rateNow: 369.89933107528,
    },
  },
  volume: {
    creditCards: {
      volumePrevPrev: 12265.500525181142,
      volumePrev: 11565,
      groupBalanceSheetValue: 11565,
    },
    cash: {
      volumePrevPrev: 18464,
      volumePrev: 13471,
      volumeNow: 13265,
    },
    savings: {
      volumePrevPrev: 125142,
      volumePrev: 135004,
    },
    transactions: {
      volumePrevPrev: 41714,
      volumePrev: 45001,
    },
    wsf: {
      volumePrevPrev: 99311,
      volumePrev: 112411,
      volumeNow: 99571,
    },
  },
  interest: {
    cash: {
      interestPrevPrev: 443,
      interestPrev: 453,
    },
    nim: {
      interestPrev: 274,
    },
    returnOnAssets: {
      interestPrev: 556,
    },
    costOfFunds: {
      interestPrev: 288,
    },
  },
}
