/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect } from 'react'
import { extractSearchParams } from '../../../../lib/extractQueryParams'
import useAPIRequest from '../../../../hooks/useAPIRequest'
import AdminAPI from '../../../../services/adminApi'
import Page from '../../../atoms/Page/Page'
import CentredSpinner from '../../../molecules/CentredSpinner/CentredSpinner'
import BreakingNews from '../BreakingNews'
import InlineError from '../../../atoms/InlineError/InlineError'
import {
  EconomicScenarioNewsMarketOutlook,
  EconomicScenarioNewsResponse,
  EconomicScenarioNewsRoundResponse,
} from '../../../../types/gameApi/news'
import {
  CentralBank,
  Currency,
  Market,
  ReferenceRate,
  Regulator,
  referenceRateLookup,
} from '@excelerateconsulting/banksim-common'

const {
  scenarioId,
  roundId: roundIdQuery,
  token,
  product,
  country,
  currency,
  centralBank,
  regulator,
  referenceRate,
} = extractSearchParams<{
  scenarioId: string
  roundId: string
  product: string
  token: string
  country: Market
  currency: Currency
  centralBank: CentralBank
  regulator: Regulator
  referenceRate: ReferenceRate
}>()

interface Props {}

const BreakingNewsPreview: React.FC<Props> = () => {
  const callback = useCallback(async () => {
    const scenario = await AdminAPI.getScenarioPreview({
      scenarioId,
      token,
      country,
      currency,
      centralBank,
      regulator,
      referenceRate,
    })
    if (!scenario) {
      throw new Error(`Scenario ${scenarioId} not found`)
    }
    return scenario
  }, [])

  const [{ inProgress, data }, doAPIRequest] =
    useAPIRequest<EconomicScenarioNewsResponse>(callback, null)

  useEffect(() => {
    doAPIRequest()
  }, [doAPIRequest])

  if (inProgress || !data) {
    return <CentredSpinner />
  }

  const roundId = parseInt(roundIdQuery)

  const newsForRoundApi = data.rounds.find(r => r.roundId === roundId)

  if (!newsForRoundApi) {
    return <InlineError message={`No news for round ${roundId}`} />
  }

  const enabledBusinesses = [
    'HOME_LOANS',
    'BUSINESS_LOANS',
    'DEPOSITS',
    product,
  ]

  const marketOutlook: EconomicScenarioNewsMarketOutlook[] =
    newsForRoundApi.marketOutlook.filter(mo =>
      enabledBusinesses.some(b => b === mo.productType),
    )

  const newsData: EconomicScenarioNewsRoundResponse = {
    ...newsForRoundApi,
    marketOutlook,
  }

  const creditRating = 'A'

  return (
    <div className="flex tablet:h-screen">
      <main className="flex-1 overflow-y-auto">
        <Page full>
          <BreakingNews
            roundId={roundId}
            newsConfig={data.newsConfig}
            data={newsData}
            creditRating={creditRating}
            defaultReferenceRateName={referenceRateLookup[referenceRate]}
          />
        </Page>
      </main>
    </div>
  )
}

export default BreakingNewsPreview
