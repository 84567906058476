import React, { useCallback } from 'react'
import InAppHelpContent from '../../organisms/InAppHelp/InAppHelpContent'
import Text from '../../atoms/Text/Text'
import GuidedTour, {
  GuidedTourStep,
} from '../../organisms/InAppHelp/GuidedTour'
import { useAppDispatch, useAppSelector } from '../../../store'
import { finishTour } from '../../../redux/guidedTours/guidedToursSlice'

const SpecialProjectsTour: React.FC = () => {
  const dispatch = useAppDispatch()
  const currentTour = useAppSelector(state => state.guidedTours.currentTour)

  const steps: Array<Partial<GuidedTourStep>> = [
    {
      target: '#tour-projects-List',
      content: (
        <InAppHelpContent heading="Projects List">
          <Text left>
            {
              'View all investment projects available to your bank. Only one project can be started each year.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },

    {
      target: '#tour-project-name',
      content: (
        <InAppHelpContent heading={'Project Name'}>
          <Text left>
            {
              "Displays the project's name, focus area, and expected completion time."
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-project-description',
      content: (
        <InAppHelpContent heading="Project Description">
          <Text left>
            {'Read detailed information about each project here.'}
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-project-costs',
      content: (
        <InAppHelpContent heading="Project Costs">
          <Text left>
            {
              "Shows the total expected cost of the project and any ongoing costs after completion. Costs are typically spread over the project's duration."
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-project-impacts',
      content: (
        <InAppHelpContent heading="Project Impacts">
          <Text left>
            {
              'Visually see how the project could positively or negatively affect various aspects of your bank.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-sort-projects',
      content: (
        <InAppHelpContent heading={'Sort Projects'}>
          <Text left>
            {
              'Use the sort function to quickly find projects which enhance your area of focus.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-select-project',
      content: (
        <InAppHelpContent heading={'Select A Project'}>
          <Text left>
            {'Click here to choose the project or switch to a different one.'}
          </Text>
        </InAppHelpContent>
      ),
    },
  ]

  const handleCloseTour = useCallback(() => {
    dispatch(finishTour())
  }, [dispatch])

  if (currentTour !== 'special-projects') {
    return null
  }

  return <GuidedTour disableScrolling steps={steps} onClose={handleCloseTour} />
}

export default SpecialProjectsTour
