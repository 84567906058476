import { useCallback, useEffect } from 'react'

interface Params {
  handler: Record<string, (data: any) => void>
}

export const useMessageListener = ({ handler }: Params) => {
  // Define message listener
  const messageListener = useCallback(
    (event: MessageEvent) => {
      // Log message data
      try {
        if (event.data) {
          const data = JSON.parse(event.data)
          const actionName = data.payload?.name
          if (data.type === 'action' && actionName && handler[actionName]) {
            handler[actionName](data.payload)
          }
        }
      } catch (error) {
        console.error('Error parsing message data:', error)
      }
    },
    [handler],
  )
  // Add message listener on mount
  useEffect(() => {
    window.addEventListener('message', messageListener)
    // Remove message listener on cleanup
    return () => {
      window.removeEventListener('message', messageListener)
    }
  }, [messageListener])
}
