import { createAsyncThunk } from '@reduxjs/toolkit'
import adminAPI from '../../services/adminApi'
import APIError from '../../errors/APIError'
import {
  ResourceRequest,
  ResourceResponse,
} from '../../types/adminApi/resources'

export const fetchResources = createAsyncThunk<
  ResourceResponse,
  ResourceRequest,
  { rejectValue: Error | APIError }
>(
  'resources/fetch',
  async ({ eventId, participantId }, { rejectWithValue }) => {
    try {
      const response = await adminAPI.getResources({
        eventId,
        participantId,
      })

      return response
    } catch (error: any) {
      // TODO https://excelerateconsulting.atlassian.net/browse/RU-8
      return rejectWithValue(error)
    }
  },
)
