import classNames from 'classnames'
import React, { forwardRef } from 'react'

interface Props extends React.ThHTMLAttributes<HTMLTableCellElement> {
  align?: 'left' | 'center' | 'right'
}

const TableHeaderCell = forwardRef<
  HTMLTableCellElement,
  React.PropsWithChildren<Props>
>(({ children, className, align = 'center', ...rest }, ref) => {
  return (
    <th ref={ref} className={classNames('py-2 px-3', className)} {...rest}>
      <div
        className={classNames('flex', {
          'justify-start': align === 'left',
          'justify-center': align === 'center',
          'justify-end': align === 'right',
        })}
      >
        {children}
      </div>
    </th>
  )
})

TableHeaderCell.displayName = 'TableHeaderCell'

export default TableHeaderCell
