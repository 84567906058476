interface RemoteAction {
  name: string
  args?: { [key: string]: any }
}

export const sendRemoteAction = (action: RemoteAction) => {
  const message = {
    type: 'action',
    payload: action,
  }

  if (
    action.name === 'logout' &&
    typeof window.ReactNativeWebView !== 'undefined'
  ) {
    window.ReactNativeWebView.postMessage(JSON.stringify(message))
  } else if (typeof window.ReactNativeWebView !== 'undefined') {
    window.ReactNativeWebView.postMessage(JSON.stringify(message))
  } else if (window.parent === window) {
    // TODO: update origin for security
    window.postMessage(JSON.stringify(message), '*')
  } else if (window.parent) {
    window.parent.postMessage(JSON.stringify(message), '*')
  }
}
