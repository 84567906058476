import { CheckCircleIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import React, { useCallback } from 'react'
import { ExcoOption } from '../../../types/gameApi/exco'
import Card from '../../atoms/Card/Card'
import Clickable from '../../atoms/Clickable/Clickable'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import PrimaryText from '../../atoms/Text/PrimaryText'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import HtmlText from '../../atoms/Text/HtmlText'
import Spinner from '../../atoms/Spinner/Spinner'

interface Props {
  data: ExcoOption
  onSelect: (decision: number) => void
  canSelect: boolean
  active: boolean
  isSubmitting: boolean
  id?: string
}

const ExcoDecisionsOption: React.FC<Props> = ({
  data,
  onSelect,
  canSelect,
  active,
  isSubmitting,
  id,
}) => {
  const onClick = useCallback(() => {
    onSelect(data.id)
  }, [onSelect, data.id])

  return (
    <Clickable
      id={id}
      data-test="exco-option"
      onClick={canSelect ? onClick : undefined}
    >
      <Card className="p-5" active={active} data-test="exco-option-card">
        <VerticalGroup gap={4}>
          <HorizontalGroup between fullWidth>
            <PrimaryText data-test="decision-title" weight="bold">
              {data.title}
            </PrimaryText>
            {isSubmitting ? (
              <Spinner />
            ) : (
              <CheckCircleIcon
                className={classNames(
                  'h-6 w-6',
                  active ? 'text-primary' : 'text-gray-300',
                )}
              />
            )}
          </HorizontalGroup>

          <HtmlText family="primary" size="sm" className="text-left">
            {data.description}
          </HtmlText>
        </VerticalGroup>
      </Card>
    </Clickable>
  )
}

export default ExcoDecisionsOption
