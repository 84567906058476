import React, { PropsWithChildren } from 'react'

export interface Props {
  className?: string
}

const HideIfMobile: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  return <span className="hidden tablet:flex">{children}</span>
}

export default HideIfMobile
