import React, { useCallback, useEffect, useState } from 'react'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import Text from '../../../atoms/Text/Text'
import { Icon } from '../../../atoms/Icon/Icon'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { useAppDispatch, useAppSelector } from '../../../../store'
import SubheadingDropDown from '../Components/SubheadingDropDown'
import { useNavigate } from 'react-router-dom'
import { fetchNews } from '../../../../redux/news/newsActions'
import { startTour } from '../../../../redux/guidedTours/guidedToursSlice'
import { useMenuTranslations } from '../../../../hooks/useMenuTranslations'
import Button from '../../../atoms/Button/Button'

interface Props {}

const BreakingNewsHelp: React.FC<Props> = () => {
  const dispatch = useAppDispatch()
  const translations = useMenuTranslations()
  const { isLoading, news } = useAppSelector(state => ({
    isLoading: state.news.isLoading,
    news: state.news.news,
  }))
  useEffect(() => {
    if (!news && !isLoading) {
      dispatch(fetchNews({}))
    }
  }, [dispatch, news, isLoading])

  const [expandedSubheading, setExpandedSubheading] = useState('')
  const navigate = useNavigate()
  const handleBreakingNewsTour = useCallback(() => {
    dispatch(startTour('news'))
    navigate('/breaking-news')
  }, [dispatch, navigate])

  return (
    <VerticalGroup gap={2} className="p-2" full>
      <Text center size="lg">
        {translations.news} Guide
      </Text>
      <HorizontalGroup center>
        <Button
          buttonType="secondary"
          full
          size="xl"
          onClick={handleBreakingNewsTour}
        >
          <Icon type="guide" colour="primary" size={4} />
          <Text colour="primary">Start Walkthrough</Text>
        </Button>
      </HorizontalGroup>
      <VerticalGroup className="p-2" full gap={8}>
        <SubheadingDropDown
          title={news?.newsConfig.mainSectionHeading || ''}
          content={`${
            news?.newsConfig.mainSectionHeading || ''
          } section provides an overview of the current economic situation, highlighting key trends such as growth in the mortgage market, home and business lending, and the impact of wholesale funding spreads, cash rate changes, and unemployment levels on the banking industry.`}
          hint="Keep an eye on the economic indicators mentioned in this section to make informed decisions."
          expandedSubheading={expandedSubheading}
          setExpandedSubheading={setExpandedSubheading}
        />
        <SubheadingDropDown
          title="MARKET OUTLOOK"
          content="The Market Outlook section provides an overview of the expected growth and losses for various banking products and services. It covers home loans, business loans, deposits, and credit cards. The figures indicate the projected growth percentages and basis point changes for each category. This information helps in understanding the market trends and making informed decisions."
          hint="Analyze the projected growth and basis point changes to identify potential opportunities or risks."
          expandedSubheading={expandedSubheading}
          setExpandedSubheading={setExpandedSubheading}
        />
        <SubheadingDropDown
          title="WHOLESALE FUNDING COSTS"
          content="The Wholesale Funding Costs section presents a table showcasing the credit spreads for different credit ratings over a 5-year period. It includes ratings from AAA to B and the corresponding spreads in percentages, indicating how the spreads are expected to change over time."
          hint="Consider the credit spreads when making funding decisions to optimize costs and manage risks."
          expandedSubheading={expandedSubheading}
          setExpandedSubheading={setExpandedSubheading}
        />
        <SubheadingDropDown
          title={news?.newsConfig.marketViewSectionHeading || ''}
          content={`The ${
            news?.newsConfig.marketViewSectionHeading || ''
          } section provides insights into the current economic scenario, contrasting the characteristics of bull and bear markets. It highlights the implications for banks with portfolios weighted towards mortgages and risky asset classes, emphasizing the need for informed decision-making based on market sentiments.`}
          hint="Adapt your strategy based on the prevailing market sentiment to capitalize on opportunities and mitigate risks."
          expandedSubheading={expandedSubheading}
          setExpandedSubheading={setExpandedSubheading}
        />
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default BreakingNewsHelp
