import React, { useCallback, useState } from 'react'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import Text from '../../../atoms/Text/Text'
import { Icon } from '../../../atoms/Icon/Icon'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { useAppDispatch } from '../../../../store'
import SubheadingDropDown from '../Components/SubheadingDropDown'
import { useNavigate } from 'react-router-dom'
import { startTour } from '../../../../redux/guidedTours/guidedToursSlice'
import { useMenuTranslations } from '../../../../hooks/useMenuTranslations'
import Button from '../../../atoms/Button/Button'

interface Props {}

const ExcoDecisionsHelp: React.FC<Props> = () => {
  const dispatch = useAppDispatch()
  const [expandedSubheading, setExpandedSubheading] = useState('')
  const navigate = useNavigate()
  const translations = useMenuTranslations()
  const handleExcoTour = useCallback(() => {
    dispatch(startTour('exco-decisions'))
    navigate('/exco-decisions')
  }, [dispatch, navigate])

  return (
    <VerticalGroup gap={2} className="p-2" full>
      <Text center size="lg">
        {translations.exco} Guide
      </Text>
      <HorizontalGroup center>
        <Button buttonType="secondary" full size="xl" onClick={handleExcoTour}>
          <Icon type="guide" colour="primary" size={4} />
          <Text colour="primary">Start Walkthrough</Text>
        </Button>
      </HorizontalGroup>
      <VerticalGroup className="p-2" full gap={8}>
        <SubheadingDropDown
          title={'SCENARIO'}
          content={
            'This section provides an overview of the current situation or challenge being faced. It will describe the context, key details, and any relevant background information.'
          }
          hint="Carefully review the information provided, but also consider what extra information is required. Ask your coach for more information if required."
          expandedSubheading={expandedSubheading}
          setExpandedSubheading={setExpandedSubheading}
        />
        <SubheadingDropDown
          title="OPTIONS"
          content="This section presents the available options or choices in response to the scenario. Each option will have its own implications, advantages, and disadvantages that should be considered carefully."
          hint="Carefully consider what could be wrong with each option. Consider how material these risks could become."
          expandedSubheading={expandedSubheading}
          setExpandedSubheading={setExpandedSubheading}
        />
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default ExcoDecisionsHelp
