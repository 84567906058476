import { useReducer, useCallback } from 'react'
import { useErrorHandler } from './useErrorHandler'
import APIError from '../errors/APIError'

interface State<TData> {
  data: TData | null
  inProgress: boolean
  completed: boolean
  error: Error | null
}

type Action<TData> =
  | { type: 'API_REQUEST_IN_PROGRESS' }
  | { type: 'API_REQUEST_SUCCESS'; data: TData }
  | { type: 'API_REQUEST_FAILURE'; data: Error }
  | { type: 'CLEAR_ERROR' }

const createDataFetchReducer =
  <TData>() =>
  (state: State<TData>, action: Action<TData>): State<TData> => {
    switch (action.type) {
      case 'API_REQUEST_IN_PROGRESS': {
        return { ...state, inProgress: true, completed: false, error: null }
      }
      case 'API_REQUEST_SUCCESS': {
        return {
          ...state,
          inProgress: false,
          completed: true,
          data: action.data,
        }
      }
      case 'API_REQUEST_FAILURE': {
        return {
          ...state,
          inProgress: false,
          completed: true,
          error: action.data,
        }
      }
      case 'CLEAR_ERROR': {
        return { ...state, error: null }
      }
    }
  }

const useAPIRequest = <TData>(
  func: () => Promise<TData>,
  initialData: Partial<State<TData>> | null,
): [State<TData>, (onComplete?: () => void) => void, () => void] => {
  const reducer = createDataFetchReducer<TData>()
  const [setError] = useErrorHandler()
  const [state, dispatch] = useReducer(reducer, {
    inProgress: false,
    error: null,
    data: null,
    completed: false,
    ...initialData,
  })

  const clearError = useCallback(() => {
    dispatch({ type: 'CLEAR_ERROR' })
  }, [dispatch])

  const doAPIRequest = useCallback(
    (onComplete?: () => void) => {
      const fetchData = async () => {
        dispatch({ type: 'API_REQUEST_IN_PROGRESS' })

        try {
          const result = await func()
          dispatch({ type: 'API_REQUEST_SUCCESS', data: result })
          onComplete?.()
        } catch (e) {
          if (e instanceof APIError) {
            if (e.type === 'unauthenticated') {
              setError(e)
              return
            }
          }
          dispatch({ type: 'API_REQUEST_FAILURE', data: e as Error })
        }
      }
      fetchData()
    },
    [func, setError],
  )

  return [state, doAPIRequest, clearError]
}

export const useAPIRequestRunner = <TData>(
  initialData?: Partial<State<TData>>,
) => {
  const reducer = createDataFetchReducer<TData>()
  const [setError] = useErrorHandler()
  const [state, dispatch] = useReducer(reducer, {
    inProgress: false,
    error: null,
    data: null,
    completed: false,
    ...initialData,
  })

  const clearError = useCallback(() => {
    dispatch({ type: 'CLEAR_ERROR' })
  }, [dispatch])

  const run = useCallback(
    async (promise: Promise<TData>) => {
      dispatch({ type: 'API_REQUEST_IN_PROGRESS' })

      try {
        const result = await promise
        dispatch({ type: 'API_REQUEST_SUCCESS', data: result })
        return result
      } catch (e) {
        if (e instanceof APIError) {
          if (e.type === 'unauthenticated') {
            setError(e)
            return
          }
        }
        dispatch({ type: 'API_REQUEST_FAILURE', data: e as Error })
      }
    },
    [setError],
  )

  return { state, run, clearError }
}

export default useAPIRequest
