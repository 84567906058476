import React from 'react'
import {
  FinancialSummaryItemType,
  FinancialSummaryItem,
} from '../../../../types/gameApi/financials'
import { IconType } from '../../../atoms/Icon/Icon'

import { Colour } from '../../../../types/theme'

import { BACKGROUND_COLOUR_LIGHT_SHADE_CLASSES } from '../../../../lib/colourClasses'
import ValueChangeText from '../../../molecules/ValueChangeText/ValueChangeText'
import { formatItemValue } from '../../../organisms/FinacialSummaryItem/formatter'
import MetricCard from '../../../organisms/MetricCard/MetricCard'
import MetricCardIconBox from '../../../organisms/MetricCard/MetricCardIconBox'

import MetricChangePill from '../../../organisms/MetricCard/MetricChangePill'
import PrimaryText from '../../../atoms/Text/PrimaryText'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { getMetricColours } from '../../../../lib/icons'
import InfoPopup from '../../../atoms/InfoPopup/InfoPopup'
interface Props {
  index: number
  item: FinancialSummaryItem
  id?: string
}

const iconNameMap: Record<FinancialSummaryItemType, IconType> = {
  CTIR: 'cet1',
  COF: 'funds',
  CET1: 'capital',
  NPAT_GROWTH: 'npat',
  NIM: 'nim',
  RemediationAndFines: 'remediation',
  LIE: 'lie',
  TSR: 'share',
  ROE: 'roefin',
  Complaints: 'complaints',
  EmployeeEngagement: 'employment',
}
const configuration = {
  NPAT_GROWTH: {
    unit: '%',
    changeUnit: 'bps',
    isCost: false,
  },
  NIM: {
    unit: '%',
    changeUnit: 'bps',
    isCost: false,
  },
  COF: {
    unit: '%',
    changeUnit: 'bps',
    isCost: true,
  },
  CTIR: {
    unit: '%',
    changeUnit: 'bps',
    isCost: true,
  },
  LIE: {
    unit: '%',
    changeUnit: 'bps',
    isCost: true,
  },
  CET1: {
    unit: '%',
    changeUnit: 'bps',
    isCost: false,
  },
  TSR: {
    unit: '%',
    changeUnit: 'bps',
    isCost: false,
  },
  ROE: {
    unit: '%',
    changeUnit: 'bps',
    isCost: false,
  },
  Complaints: {
    unit: '',
    changeUnit: '',
    isCost: true,
  },
  EmployeeEngagement: {
    unit: '%',
    changeUnit: '%',
    isCost: false,
  },
  RemediationAndFines: {
    unit: 'm',
    changeUnit: 'm',
    isCost: true,
  },
}

const FinancialsItem: React.FC<Props> = ({ item, index, id }) => {
  const colour: Colour = getMetricColours(index)

  const { unit, changeUnit, isCost } = configuration[item.type]
  const { valueString } = formatItemValue(item)

  return (
    <MetricCard
      data-test={`financial-item-${item.type}`}
      fullHeight={false}
      className="h-full w-full"
      colour="white"
      id={id}
    >
      <VerticalGroup
        center
        fullWidth
        className={`${BACKGROUND_COLOUR_LIGHT_SHADE_CLASSES[colour]} h-full rounded-t-lg`}
      >
        <HorizontalGroup reverseRow between fullWidth className="h-7 p-2">
          <InfoPopup
            id="tour-help-icon"
            iconSize={5}
            iconColour={colour}
            title={item.helpHeading}
            body={item.helpBody}
            analyticsParams={{
              category: 'financials',
              action: 'open',
              label: item.type,
            }}
          />
        </HorizontalGroup>
        <VerticalGroup verticalCenter className="h-full" fullWidth>
          <VerticalGroup fullWidth gap={2} verticalCenter center className=" ">
            <MetricCardIconBox
              size="large"
              iconType={iconNameMap[item.type]}
              iconColour="white"
              backgroundColour={colour}
            />
            <PrimaryText
              id="tour-heading"
              paddingY={2}
              size="xl"
              weight="semibold"
            >
              {item.heading}
            </PrimaryText>
          </VerticalGroup>
        </VerticalGroup>
      </VerticalGroup>
      <VerticalGroup className="h-full py-5" center verticalCenter gap={2}>
        <PrimaryText
          id="tour-value-string"
          data-test="value-string"
          size="4xl"
          colour={colour}
          weight="bold"
          notResponsive
        >
          {valueString}
          {valueString !== 'None' && (
            <PrimaryText weight="light" size="lg" notResponsive>
              {unit}
            </PrimaryText>
          )}
        </PrimaryText>
        <MetricChangePill
          id="tour-metric-change"
          data-test="value-change"
          backgroundColour="lightgray"
        >
          <ValueChangeText
            dp={item.type === 'EmployeeEngagement' ? 0 : 2}
            isCost={isCost}
            changeUnit={changeUnit}
            value={item.valueChange}
            textColour="black"
            removePlusSign
            removeMinusSign
            notResponsive
          />
        </MetricChangePill>
      </VerticalGroup>
    </MetricCard>
  )
}

export default FinancialsItem
