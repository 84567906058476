import React, { useEffect } from 'react'

import dateFormat from 'dateformat'

import openNewTab from '../../../../lib/openNewTab'
import { RootState, useAppDispatch, useAppSelector } from '../../../../store'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import Card from '../../../atoms/Card/Card'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import CentredSpinner from '../../../molecules/CentredSpinner/CentredSpinner'
import Clickable from '../../../atoms/Clickable/Clickable'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import { fetchResources } from '../../../../redux/resources/resourcesActions'
import BrandedText from '../../../organisms/Branding/BrandedText/BrandedText'

interface Props {
  resources?: RootState['resources']['resources'] | null
}

const WaitingRoomResourceCard: React.FC<Props> = ({ resources }) => {
  const { isLoaded, fetchedResources, eventDetails } = useAppSelector(
    state => ({
      isLoaded: resources && resources.length ? true : state.resources.isLoaded,
      fetchedResources:
        resources && resources.length ? resources : state.resources.resources,
      eventDetails: state.event.details,
    }),
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (eventDetails && (!resources || !resources.length)) {
      dispatch(
        fetchResources({
          participantId: eventDetails.participantId,
          eventId: eventDetails.eventId,
        }),
      )
    }
  }, [dispatch, eventDetails, resources])

  return (
    <VerticalGroup fullWidth full>
      <Card className="h-full p-4 tablet:p-8">
        <VerticalGroup full gap={4}>
          <BrandedText colourField="mainTextColour" weight="bold" size="lg">
            Resources
          </BrandedText>

          <HorizontalGroup fullWidth between verticalCenter className="">
            <BrandedText
              colourField="mainTextColour"
              family="secondary"
              weight="bold"
              size="sm"
            >
              Resource Name
            </BrandedText>
            <BrandedText
              colourField="mainTextColour"
              family="secondary"
              weight="bold"
              size="sm"
            >
              Date Uploaded
            </BrandedText>
          </HorizontalGroup>

          {!isLoaded && <CentredSpinner />}
          {isLoaded &&
            fetchedResources &&
            !!fetchedResources.length &&
            fetchedResources.map(r => (
              <HorizontalGroup
                key={r.href + r.name}
                between
                verticalCenter
                className="pb-8"
              >
                <Clickable onClick={() => openNewTab(r.href)}>
                  <SecondaryText colour="primary">{r.name}</SecondaryText>
                </Clickable>
                <SecondaryText>
                  {dateFormat(r.uploadDate, 'dd-mmm-yyyy')}
                </SecondaryText>
              </HorizontalGroup>
            ))}
          {isLoaded && (!fetchedResources || !fetchedResources.length) && (
            <HorizontalGroup className="" fullWidth center verticalCenter>
              <SecondaryText padding={4} colour="gray">
                No Resources Available
              </SecondaryText>
            </HorizontalGroup>
          )}
        </VerticalGroup>
      </Card>
    </VerticalGroup>
  )
}

export default WaitingRoomResourceCard
