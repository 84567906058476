import { AssessmentResponse } from '../../../../types/adminApi/assessments'
import { AssessmentResponse as GameAssessmentResponse } from '../../../../types/gameApi/assessments'

const mapData = (
  assessmentId: string,
  groupId: string,
  data: AssessmentResponse,
): GameAssessmentResponse => {
  const group = data.groups.find(g => g.id === groupId)
  if (group == null) {
    throw new Error(`Group ${groupId} not found in assessment ${assessmentId}`)
  }
  return {
    gameId: 'fake',
    roundId: 0,
    groupId,
    assessmentId,
    questions: group.questions ?? [],
  }
}

export { mapData }
