import classNames from 'classnames'
import React, { useCallback } from 'react'
import {
  Project,
  ProjectImpact,
  ProjectOption,
} from '../../../types/gameApi/projects'
import Card from '../../atoms/Card/Card'
import Clickable from '../../atoms/Clickable/Clickable'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import Image from '../../atoms/Image/Image'
import PrimaryText from '../../atoms/Text/PrimaryText'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import StatusLabel from './StatusLabel'

import ImpactValueChanger from './ImpactValueChanger'
import { PROJECT_TYPE_DURATION } from './numberOfYears'
import { Colour } from '../../../types/theme'

interface Props {
  project: Project
  active: boolean
  projectStatus: 'selected' | 'inProgress' | 'completed' | null
  onClick: (id: string) => void
  selectedOption?: ProjectOption
  selectedImpact?: ProjectImpact
  roundId: number
  currentRound: number
}

const SpecialProjectThumbnail: React.FC<Props> = ({
  project,
  active,
  projectStatus,
  onClick,
  selectedOption,
  selectedImpact,
}) => {
  const handleClick = useCallback(() => {
    onClick(project.id.toString())
  }, [onClick, project.id])

  const getSelectedOptionLabel = (): string => {
    return selectedOption?.label || ''
  }
  const matchingImpact = project.impacts.find(
    impact => impact.id === selectedImpact?.id,
  )
  const numberOfYears = PROJECT_TYPE_DURATION[project.type]

  const getStatusLabel = (): { label: string; colour: Colour } | null => {
    switch (projectStatus) {
      case 'completed':
        return { label: 'Completed', colour: 'success' }
      case 'inProgress':
        return { label: 'In Progress', colour: 'orange' }
      case 'selected':
        return { label: 'Selected', colour: 'primary' }
      default:
        return null
    }
  }

  const statusLabel = getStatusLabel()

  return (
    <div className="relative w-full">
      <Clickable onClick={handleClick}>
        <Card active={active} className="relative w-full">
          <div className="relative">
            {statusLabel && (
              <>
                <StatusLabel
                  label={statusLabel.label}
                  colour={statusLabel.colour}
                />
                <div className="absolute inset-0 rounded-lg bg-black opacity-30" />
              </>
            )}

            <Image
              className={classNames('w-full rounded laptop:h-36 laptop:w-72', {
                'border-primary': active,
              })}
              src={project.imageUrl}
              objectFit="cover"
            />

            {selectedOption && (
              <StatusLabel
                position="bottom"
                label={getSelectedOptionLabel()}
                colour="primary"
              />
            )}
          </div>
        </Card>
        <VerticalGroup className="w-full">
          <HorizontalGroup between center fullWidth>
            <PrimaryText
              size="sm"
              colour={active ? 'primary' : 'black'}
              weight="bold"
            >
              {project.name}
            </PrimaryText>
            <PrimaryText colour={active ? 'primary' : 'black'} weight="bold">
              {project.cost}
            </PrimaryText>
          </HorizontalGroup>
        </VerticalGroup>
        <HorizontalGroup gap={2} fullWidth between={!!project.ongoingCost}>
          <HorizontalGroup gap={3}>
            <PrimaryText size="xs" colour={active ? 'primary' : undefined}>
              {project.areaOfFocus}
            </PrimaryText>

            <HorizontalGroup gap={3} verticalCenter>
              <PrimaryText
                className="opacity-30"
                size="xs"
                colour={active ? 'primary' : undefined}
              >
                I
              </PrimaryText>
              <PrimaryText size="xs" colour={active ? 'primary' : undefined}>
                {numberOfYears} Year{numberOfYears > 1 && 's'}
              </PrimaryText>
            </HorizontalGroup>
          </HorizontalGroup>
          {!!project.ongoingCost && (
            <PrimaryText size="xs" colour={active ? 'primary' : undefined}>
              +{project.ongoingCost} p.a
            </PrimaryText>
          )}
        </HorizontalGroup>

        <HorizontalGroup gap={2}>
          <PrimaryText colour={active ? 'primary' : undefined} size="xs">
            {matchingImpact?.name}
          </PrimaryText>
          <HorizontalGroup center gap={1}>
            {matchingImpact && (
              <ImpactValueChanger
                textSize="xs"
                value={matchingImpact.value}
                iconSize={4}
              />
            )}
          </HorizontalGroup>
        </HorizontalGroup>
      </Clickable>
    </div>
  )
}

export default SpecialProjectThumbnail
