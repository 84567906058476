import React, { useCallback, useEffect, useMemo } from 'react'
import { fetchNews } from '../../../redux/news/newsActions'
import { useAppDispatch, useAppSelector } from '../../../store'
import CentredSpinner from '../../molecules/CentredSpinner/CentredSpinner'
import { fetchSummary } from '../../../redux/summary/summaryActions'
import { CreditRating } from '../../../types/gameApi/enums'
import { fetchBusinesses } from '../../../redux/businesses/businessActions'
import BreakingNews from './BreakingNews'
import { EconomicScenarioNewsRoundResponse } from '../../../types/gameApi/news'
import Page from '../../atoms/Page/Page'
import InlineError from '../../atoms/InlineError/InlineError'

interface Props {}

const BreakingNewsContainer: React.FC<Props> = () => {
  const {
    isLoading,
    error,
    news,
    summary,
    summaryLoading,
    summaryError,
    businesses,
    businessesLoading,
    businessesError,
    teamId,
    roundId,
  } = useAppSelector(state => ({
    isLoading: state.news.isLoading,
    error: state.news.error,
    news: state.news.news,
    summaryLoading: state.summary.isLoading,
    summary: state.summary.summaries,
    summaryError: state.summary.error,
    businessesLoading: state.businesses.isLoading,
    businesses: state.businesses.data,
    businessesError: state.businesses.error,
    teamId: state.event.details?.team ?? 0,
    roundId: state.game.selectedRound,
  }))

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (teamId > 0 && roundId > 0 && !news && !isLoading && !error) {
      dispatch(fetchNews({}))
    }
    if (
      teamId > 0 &&
      roundId > 0 &&
      !summary[roundId] &&
      !summaryLoading &&
      !summaryError
    ) {
      dispatch(fetchSummary({ roundId, teamId }))
    }
    if (
      teamId > 0 &&
      roundId > 0 &&
      !businesses[roundId] &&
      !businessesLoading &&
      !businessesError
    ) {
      dispatch(fetchBusinesses({ roundId, teamId }))
    }
  }, [
    businesses,
    businessesLoading,
    businessesError,
    dispatch,
    isLoading,
    news,
    roundId,
    summary,
    summaryLoading,
    summaryError,
    teamId,
    error,
  ])

  const onRetry = useCallback(() => {
    if (error) {
      dispatch(fetchNews({}))
    }
    if (businessesError) {
      dispatch(fetchBusinesses({ roundId, teamId }))
    }
    if (summaryError) {
      dispatch(fetchSummary({ roundId, teamId }))
    }
  }, [businessesError, summaryError, dispatch, error, roundId, teamId])

  const errorToDisplay = useMemo(
    () => error ?? businessesError ?? summaryError,
    [businessesError, error, summaryError],
  )

  const newsForRound: EconomicScenarioNewsRoundResponse | null =
    news?.rounds.find(r => r.roundId === roundId) ?? null

  if (errorToDisplay) {
    return (
      <Page className="laptop:h-full">
        <InlineError message={errorToDisplay.message} onRetry={onRetry} />
      </Page>
    )
  }

  if (
    isLoading ||
    !news ||
    !newsForRound ||
    !summary[roundId] ||
    !businesses[roundId]
  ) {
    return <CentredSpinner />
  }

  const businessForRounds = businesses[roundId]
  const data: EconomicScenarioNewsRoundResponse = {
    ...newsForRound,
    marketOutlook: newsForRound.marketOutlook.filter(mo =>
      businessForRounds.businesses.some(b => b.type === mo.productType),
    ),
  }
  const creditRating = summary[roundId].find(s => s.type === 'Rating')
  const creditRatingValue: CreditRating = creditRating?.value
    ? (creditRating?.value.value as unknown as CreditRating)
    : 'A'

  return (
    <Page className="laptop:h-full">
      <BreakingNews
        data={data}
        newsConfig={news.newsConfig}
        creditRating={creditRatingValue}
        roundId={roundId}
      />
    </Page>
  )
}

export default BreakingNewsContainer
