import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchFinancials } from './financialsActions'
import APIError from '../../errors/APIError'
import { FinancialsResponse } from '../../types/gameApi/financials'
import { reset } from '../game/gameSlice'
import { logout } from '../auth/authSlice'
import ThunkCancelledError from '../../errors/ThunkCancelledError'

export type FinancialsTabs =
  | 'summary'
  | 'profit-and-loss'
  | 'division-profit-and-loss'
  | 'balance-sheet'
interface FinancialsState {
  currentTab: FinancialsTabs
  isLoading: boolean
  isLoaded: boolean
  financials: {
    [roundId: number]: FinancialsResponse
  }
  requestInProgress: string | null
  error: Error | APIError | null
}

const initialState: FinancialsState = {
  currentTab: 'summary',
  isLoading: false,
  isLoaded: false,
  financials: {},
  requestInProgress: null,
  error: null,
}

const financialsSlice = createSlice({
  name: 'financials',
  initialState,
  reducers: {
    changeTab: (state, action: PayloadAction<FinancialsTabs>) => {
      state.currentTab = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(reset, () => {
        return initialState
      })
      .addCase(logout, () => {
        return initialState
      })
      .addCase(fetchFinancials.pending, (state, { meta }) => {
        if (!state.isLoading) {
          state.requestInProgress = meta.requestId
          state.isLoading = true
          state.error = null
        }
      })
      .addCase(fetchFinancials.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isLoaded = true
        state.error = null
        state.financials[payload.roundId] = payload.financials
        state.requestInProgress = null
      })
      .addCase(fetchFinancials.rejected, (state, { payload }) => {
        if (payload instanceof ThunkCancelledError) {
          return
        }
        state.isLoading = false
        state.error = payload ?? null
        state.requestInProgress = null
      })
  },
})

export const { changeTab } = financialsSlice.actions

export default financialsSlice.reducer
