import React, { useMemo } from 'react'
import { ValueGetter } from '../types'
import SecondaryText from '../../../../atoms/Text/SecondaryText'
import {
  Answer,
  WrittenResponseField as WrittenResponseFieldType,
} from '../../../../../types/gameApi/assessments'
import AssessmentTextArea from '../../AssessmentTextArea/AssessmentTextArea'

interface Props {
  onChange: (value: string) => void
  value: string
  characterLimit?: number
  placeholder?: string
  hidden?: boolean
}

export const WrittenResponseField: React.FC<Props> = ({
  onChange,
  value,
  characterLimit,
  placeholder,
  hidden = false,
}) => {
  if (hidden) {
    return null
  }
  return (
    <>
      <AssessmentTextArea
        placeholder={placeholder}
        onValueChange={onChange}
        value={value}
        style={{
          visibility: hidden ? 'hidden' : 'visible',
        }}
      />
      {typeof characterLimit === 'number' && (
        <SecondaryText
          size="sm"
          style={{
            visibility: hidden ? 'hidden' : 'visible',
          }}
        >
          {value.length ?? 0}/{characterLimit} characters
        </SecondaryText>
      )}
    </>
  )
}

export const createCharacterLimitValidator =
  (field: WrittenResponseFieldType, getter: ValueGetter) =>
  (answer: Answer[]) => {
    if (!field) {
      return true
    }

    const value = getter(answer, { id: field.id })
    const length = (value && value.length) || 0

    if (
      field.config.hasCharacterLimit &&
      field.config.characterLimit &&
      length > field.config.characterLimit
    ) {
      return `Should have less than ${field.config.characterLimit} characters`
    }

    return true
  }

export const useCharacterLimitValidator = (
  field: WrittenResponseFieldType,
  getter: ValueGetter,
) => {
  const otherFieldWithinLimit = useMemo(() => {
    return createCharacterLimitValidator(field, getter)
  }, [field, getter])

  return otherFieldWithinLimit
}
