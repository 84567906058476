import classNames from 'classnames'
import React from 'react'

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  full?: boolean
  center?: boolean
  noPadding?: boolean
}

const Page: React.FC<Props> = ({ children, className, full, noPadding }) => {
  return (
    <div
      className={classNames(className, {
        'p-4': !noPadding,
        'h-full': full,
        'w-full': full,
      })}
    >
      {children}
    </div>
  )
}

export default Page
