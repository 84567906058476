import {
  Business,
  BusinessRates,
  BusinessVolume,
  BusinessInterest,
} from '../../../../types/gameApi/business'
import { BusinessDecision, DecisionMap } from '../BusinessesContainer'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import BusinessAdvancedModeTable from './BusinessAdvancedModeTable'

import React, { useMemo } from 'react'
import AssetLiabilitySwitch from './AssetLiabilitySwitch'
import { getData } from './data'

interface Props {
  roundId: number
  data: Business[]
  rates: BusinessRates
  volume: BusinessVolume
  interest: BusinessInterest
  decisions: DecisionMap
  onChangeValue: (
    type: string,
    field: keyof BusinessDecision,
    value: number,
  ) => void
  enableChanges: boolean

  handleReset: () => void

  setShowModal: (val: boolean) => void
}

const BusinessAdvancedMode: React.FC<Props> = ({
  data,
  roundId,
  decisions,
  rates,
  volume,
  interest,
  onChangeValue,
  enableChanges,
  setShowModal, // Add this line back
  handleReset,
  // Add this line back
}) => {
  const { assetData, liabilityData, netInterestIncome, nim } = useMemo(() => {
    return getData({ data, roundId, decisions, rates, volume, interest })
  }, [data, decisions, interest, rates, roundId, volume])

  return (
    <VerticalGroup>
      <div className="hidden tablet:flex">
        <BusinessAdvancedModeTable
          data={data}
          decisions={decisions}
          rates={rates}
          volume={volume}
          interest={interest}
          onChangeValue={onChangeValue}
          assetData={assetData}
          liabilityData={liabilityData}
          netInterestIncome={netInterestIncome}
          nim={nim}
          enableChanges={enableChanges}
          setShowModal={setShowModal}
        />
      </div>

      <AssetLiabilitySwitch
        handleReset={handleReset}
        assetData={assetData}
        liabilityData={liabilityData}
        onChangeValue={onChangeValue}
        netInterestIncome={netInterestIncome}
        nim={nim}
        enableChanges={enableChanges}
      />
    </VerticalGroup>
  )
}

export default BusinessAdvancedMode
