import { configureStore, Store } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import authReducer from './redux/auth/authSlice'
import eventReducer from './redux/event/eventSlice'
import summaryReducer from './redux/summary/summarySlice'
import financialsReducer from './redux/financials/financialsSlice'
import deviceReducer from './redux/device/deviceSlice'
import teamReducer from './redux/team/teamSlice'
import gameReducer from './redux/game/gameSlice'
import helpReducer from './redux/help/helpSlice'
import contentReducer from './redux/content/contentSlice'
import excoReducer from './redux/exco/excoSlice'
import projectsReducer from './redux/projects/projectsSlice'
import newsReducer from './redux/news/newsSlice'
import businessesReducer from './redux/businesses/businessSlice'
import peerInsightsReducer from './redux/peer-insights/peerInsightsSlice'
import dealroomReducer from './redux/dealroom/dealroomSlice'
import treasuryReducer from './redux/treasury/treasurySlice'
import resourcesReducer from './redux/resources/resourcesSlice'
import assessmentsReducer from './redux/assessments/assessmentsSlice'
import changeCeoReducer from './redux/changeCeo/changeCeoSlice'
import costsReducer from './redux/costs/costsSlice'
import demoReducer from './redux/demo/demoSlice'
import brandingPreviewReducer from './redux/brandingPreview/brandingPreviewSlice'
import guidedTourReducer from './redux/guidedTours/guidedToursSlice'
import inAppHelpReducer from './redux/inAppHelp/inAppHelpSlice'
import resultsReducer from './redux/results/resultsSlice'

const store = configureStore({
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    auth: authReducer,
    event: eventReducer,
    summary: summaryReducer,
    financials: financialsReducer,
    device: deviceReducer,
    team: teamReducer,
    game: gameReducer,
    help: helpReducer,
    content: contentReducer,
    exco: excoReducer,
    projects: projectsReducer,
    news: newsReducer,
    peerInsights: peerInsightsReducer,
    businesses: businessesReducer,
    dealroom: dealroomReducer,
    treasury: treasuryReducer,
    resources: resourcesReducer,
    assessments: assessmentsReducer,
    changeCeo: changeCeoReducer,
    costs: costsReducer,
    demo: demoReducer,
    brandingPreview: brandingPreviewReducer,
    guidedTours: guidedTourReducer,
    inAppHelp: inAppHelpReducer,
    results: resultsReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export type AppStore = Store<RootState>

export default store
