import React from 'react'
import { ProductType } from '../../../../types/gameApi/business'
import {
  FinancialsDivisionProfitAndLossBusiness,
  FinancialsResponse,
} from '../../../../types/gameApi/financials'
import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'
import { formatValue, formatPercentage } from '../../../../lib/formatters'
import ValueChangeText from '../../../molecules/ValueChangeText/ValueChangeText'
import { FontWeight, TextColour } from '../../../../types/theme'
import classNames from 'classnames'
import Table from '../../../atoms/Table/Table'
import TableHeader from '../../../atoms/Table/TableHeader'
import TableHeaderCell from '../../../atoms/Table/TableHeaderCell'
import TableSection from '../../../atoms/Table/TableSection'
import TableRow from '../../../atoms/Table/TableRow'
import TableCell from '../../../atoms/Table/TableCell'
import TableText from '../../../atoms/Table/TableText'
import { getBusinessColour } from '../../../../lib/icons'
import DivisionalProfitAndLossTourWrapper from '../OurFinancialsTour/DivisionalProfitAndLossTourWrapper'

interface Props {
  className?: string
  financials: FinancialsResponse
  roundId: number
  selectedBusiness?: ProductType
}

interface RowProps {
  businesses: FinancialsDivisionProfitAndLossBusiness[]
  translate: string
  defaultField: string
  valueField: keyof FinancialsDivisionProfitAndLossBusiness
  changeField: keyof FinancialsDivisionProfitAndLossBusiness
  isCost?: boolean
  changeUnit?: string
  weight?: FontWeight
  colour?: TextColour
  highlight?: boolean
}

const Row: React.FC<RowProps> = ({
  businesses,
  translate,
  defaultField,
  valueField,
  changeField,
  isCost,
  changeUnit,
  weight,
  colour,
  highlight,
}) => (
  <TableRow highlight={highlight}>
    <TableCell paddingY={1} noRightBorder>
      <TranslatedText
        value={translate}
        default={defaultField}
        weight={weight}
        colour={colour}
        family="secondary"
        size="xs"
      />
    </TableCell>
    {businesses.map((b, i) => (
      <>
        <TableCell
          paddingY={1}
          noRightBorder
          align="center"
          colour={!highlight ? getBusinessColour(i) : undefined}
        >
          <TableText weight={weight} colour={colour}>
            {changeUnit === 'bps'
              ? formatPercentage(Number(b[valueField]))
              : formatValue(Number(b[valueField]))}
          </TableText>
        </TableCell>
        <TableCell paddingY={1} align="center">
          <ValueChangeText
            removeArrow
            removePlusSign
            value={Number(b[changeField])}
            dashIfZero
            dp={1}
            changeUnit={changeUnit ?? '%'}
            isCost={isCost}
            weight={weight}
            family="secondary"
            size="xs"
          />
        </TableCell>
      </>
    ))}
  </TableRow>
)

const DivisionalProfitAndLossTable: React.FC<Props> = ({
  className,
  financials,
  roundId,
  selectedBusiness,
}) => {
  const {
    creditCardsEnabled,
    institutionalBankingEnabled,
    fundsMgmtIncomeEnabled,
  } = financials
  const businessesToDisplay =
    financials.divisionProfitAndLoss.businesses.filter(b =>
      selectedBusiness ? b.type === selectedBusiness : true,
    )

  return (
    <DivisionalProfitAndLossTourWrapper>
      {({ columnInfo, tableRef, setSectionRef }) => (
        <Table
          ref={tableRef}
          className={classNames('h-full w-full', className)}
        >
          <TableHeader noDivideX>
            <TableHeaderCell ref={columnInfo[0].ref} rowSpan={2} align="left">
              <TableText weight="bold">
                PROFIT & LOSS ({financials.denomination})
              </TableText>
            </TableHeaderCell>
            {businessesToDisplay.map(b => (
              <TableHeaderCell key={b.businessId} colSpan={2}>
                <TableText weight="bold">{b.businessName}</TableText>
              </TableHeaderCell>
            ))}
          </TableHeader>
          <TableHeader noDivideX>
            {businessesToDisplay.map(b => (
              <>
                <TableHeaderCell key={b.businessId}>
                  <TranslatedText
                    center
                    value="general.year"
                    default="YEAR"
                    family="secondary"
                    suffix={` ${roundId - 1}`}
                    weight="bold"
                    size="xs"
                  />
                </TableHeaderCell>
                <TableHeaderCell key={b.businessId}>
                  <TranslatedText
                    center
                    value="general.change"
                    default="CHANGE"
                    family="secondary"
                    weight="bold"
                    size="xs"
                  />
                </TableHeaderCell>
              </>
            ))}
          </TableHeader>
          <TableSection>
            <Row
              businesses={businessesToDisplay}
              translate="ourFinancials.divisionalProfitLoss.interestIncome"
              defaultField="Interest Income"
              valueField="interestIncome"
              changeField="interestIncomeChange"
            />
            <Row
              businesses={businessesToDisplay}
              translate="ourFinancials.divisionalProfitLoss.interestExpense"
              defaultField="Interest Expense"
              valueField="interestExpense"
              changeField="interestExpenseChange"
              isCost
            />
          </TableSection>
          {/*  i want to haver over this section  */}
          <TableSection ref={setSectionRef}>
            <Row
              businesses={businessesToDisplay}
              translate="ourFinancials.divisionalProfitLoss.netInterestIncome"
              defaultField="NET INTEREST INCOME"
              valueField="netInterestIncome"
              changeField="netInterestIncomeChange"
              weight="bold"
            />
            <Row
              businesses={businessesToDisplay}
              translate="ourFinancials.divisionalProfitLoss.netInterestMargin"
              defaultField="Net Interest Margin %"
              valueField="nim"
              changeField="nimChange"
              changeUnit="bps"
              colour="primary"
            />
            <Row
              businesses={businessesToDisplay}
              translate="ourFinancials.divisionalProfitLoss.brokerCommissions"
              defaultField="Broker Commissions"
              valueField="brokerCommissions"
              changeField="brokerCommissionsChange"
              isCost
            />
            <Row
              businesses={businessesToDisplay}
              translate="ourFinancials.divisionalProfitLoss.cardTransactionIncome"
              defaultField="Transaction & Fee Income"
              valueField="cardIncome"
              changeField="cardIncomeChange"
            />
            {creditCardsEnabled && (
              <Row
                businesses={businessesToDisplay}
                translate="ourFinancials.divisionalProfitLoss.creditCardFees"
                defaultField="Credit Card Fees"
                valueField="cardFees"
                changeField="cardFeesChange"
              />
            )}
            {creditCardsEnabled && (
              <Row
                businesses={businessesToDisplay}
                translate="ourFinancials.divisionalProfitLoss.creditCardRewardCosts"
                defaultField="Credit Card Reward Costs"
                valueField="rewardCosts"
                changeField="rewardCostsChange"
                isCost
              />
            )}
            {institutionalBankingEnabled && (
              <Row
                businesses={businessesToDisplay}
                translate="ourFinancials.divisionalProfitLoss.institutionalBankingFees"
                defaultField="Institutional Banking Fees"
                valueField="institutionalFees"
                changeField="institutionalFeesChange"
              />
            )}
          </TableSection>

          {/*  */}
          <TableSection>
            <Row
              businesses={businessesToDisplay}
              translate="ourFinancials.divisionalProfitLoss.otherBankingIncome"
              defaultField="OTHER BANKING INCOME"
              valueField="totalOtherBankingIncome"
              changeField="totalOtherBankingIncomeChange"
              weight="bold"
            />
            {fundsMgmtIncomeEnabled && (
              <Row
                businesses={businessesToDisplay}
                translate="ourFinancials.divisionalProfitLoss.fundsIncome"
                defaultField="Funds Management Income"
                valueField="fundsMgmtIncome"
                changeField="fundsMgmtIncomeChange"
              />
            )}
          </TableSection>
          <TableSection>
            <Row
              businesses={businessesToDisplay}
              translate="ourFinancials.divisionalProfitLoss.totalIncome"
              defaultField="TOTAL INCOME"
              valueField="totalIncome"
              changeField="totalIncomeChange"
              weight="bold"
            />
            <Row
              businesses={businessesToDisplay}
              translate="ourFinancials.divisionalProfitLoss.operatingExpenses"
              defaultField="Operational Expenses"
              valueField="opex"
              changeField="opexChange"
              isCost
            />
          </TableSection>
          <TableSection>
            <Row
              businesses={businessesToDisplay}
              translate="ourFinancials.divisionalProfitLoss.costToIncomeRatio"
              defaultField="Cost to Income Ratio"
              valueField="ctir"
              changeField="ctirChange"
              isCost
              changeUnit="bps"
              colour="primary"
            />
            <Row
              businesses={businessesToDisplay}
              translate="ourFinancials.divisionalProfitLoss.loanImpairmentExpense"
              defaultField="Loan Impairment Expense"
              valueField="lie"
              changeField="lieChange"
              isCost
            />
            <Row
              businesses={businessesToDisplay}
              translate="ourFinancials.divisionalProfitLoss.loanImpairmentRatio"
              defaultField="Loan Impairment Expense %"
              valueField="lieRate"
              changeField="lieRateChange"
              isCost
              changeUnit="bps"
              colour="primary"
            />
          </TableSection>
          <TableSection>
            <Row
              businesses={businessesToDisplay}
              translate="ourFinancials.divisionalProfitLoss.netProfitBeforeTax"
              defaultField="Net Profit Before Tax"
              valueField="netProfitBeforeTax"
              changeField="netProfitBeforeTaxChange"
            />
            <Row
              businesses={businessesToDisplay}
              translate="ourFinancials.divisionalProfitLoss.taxExpense"
              defaultField="Tax Expense"
              valueField="tax"
              changeField="taxChange"
              isCost
            />
          </TableSection>
          <TableSection>
            <Row
              businesses={businessesToDisplay}
              translate="ourFinancials.divisionalProfitLoss.netProfitAfterTax"
              defaultField="NET PROFIT AFTER TAX"
              valueField="netProfitAfterTax"
              changeField="netProfitAfterTaxChange"
              weight="bold"
              highlight
            />
          </TableSection>
        </Table>
      )}
    </DivisionalProfitAndLossTourWrapper>
  )
}

export default DivisionalProfitAndLossTable
