import React from 'react'
import { LabelProps } from 'recharts'
import { colours } from '../../../../constants/colours'

interface CustomLabelProps extends LabelProps {
  value: string | number
  index: number
}

const createCustomLabel = ({
  formatter,
  getLabelId,
}: {
  formatter?: (val: number) => string | number
  getLabelId?: null | ((index: number) => string | null)
}) => {
  const CustomLabel: React.FC<CustomLabelProps> = props => {
    const { x, y, value } = props
    const labelId = getLabelId
      ? getLabelId(props.index) ?? undefined
      : undefined
    const pillWidth = 61
    const xToUse = Number(x) ?? 0
    const yToUse = Number(y) ?? 0
    return (
      <>
        {/* draw a line connecting the label to the chart */}
        <line
          x1={x}
          y1={yToUse - 10}
          x2={x}
          y2={y}
          stroke="gray"
          strokeWidth="1"
        />
        {/* draw the pill-shaped label box */}
        <rect
          id={labelId}
          x={xToUse - pillWidth / 2}
          y={yToUse - 35}
          width={pillWidth}
          height={26}
          rx="13"
          fill={colours.orange}
        />
        {/* display the label text in the box */}
        <text
          x={x}
          y={yToUse - 17}
          textAnchor="middle"
          fill="#fff"
          fontFamily="Open Sans"
          fontSize={14}
          fontWeight={700}
        >
          {formatter ? formatter(Number(value)) : value}
        </text>
      </>
    )
  }
  return CustomLabel
}

export default createCustomLabel
