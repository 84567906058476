import { BusinessResponse } from '../../../../../types/gameApi/business'

export const data: BusinessResponse = {
  businesses: [
    {
      history: [
        {
          year: 0,
          price1: 488,
          volume: 162304,
        },
      ],
      id: 1,
      price1Prev: 488,
      price1Now: 476,
      price1NowOriginal: 488,
      slider1Min: 388,
      slider1Max: 588,
      price2Prev: 15,
      price2Now: 18,
      price2NowOriginal: 15,
      slider2Min: 10,
      slider2Max: 20,
      volumePrev: 162304,
      volumeNow: 181741,
      volumeNowOriginal: 178495,
      incomePrev: 7768,
      type: 'HOME_LOANS',
      name: 'HOME LOANS',
      price1Type: 'Interest Rate',
      price1TypeInfoBody:
        'Lower interest rates might attract more customers, but may also mean less income. The reverse is also true',
      price2Type: 'Broker Commissions',
      price2TypeInfoBody:
        'The annual investment aims to simplify broker interactions with your bank. It includes dedicated broker channel support, ongoing process simplification, transparent credit decision-making, clear commission structures, and ongoing education. Increased investment in brokers will raise operating costs. Customers acquired through brokers tend to be price-sensitive, potentially less loyal, and prone to higher loss rates during stressful periods.',
      version: 3,
      updatedBy: 'a83409c4-5951-4bb5-bd18-7a0f97c97e51',
    },
    {
      history: [
        {
          year: 0,
          price1: 618,
          volume: 56814,
        },
      ],
      id: 2,
      price1Prev: 618,
      price1Now: 643,
      price1NowOriginal: 618,
      slider1Min: 518,
      slider1Max: 718,
      price2Prev: 0,
      price2Now: 1,
      price2NowOriginal: 0,
      slider2Min: -1,
      slider2Max: 1,
      volumePrev: 56814,
      volumeNow: 58961,
      volumeNowOriginal: 60098,
      incomePrev: 3443,
      type: 'BUSINESS_LOANS',
      name: 'BUSINESS LOANS',
      price1Type: 'Interest Rate',
      price1TypeInfoBody:
        'Lower interest rates might attract more customers, but may also mean less income. The reverse is also true',
      price2Type: 'Lending Criteria',
      price2TypeInfoBody:
        'Tighter lending criteria restricts lending to better quality customers, which may reduce future losses. This may also limit growth. The opposite is true for loose lending criteria.',
      version: 4,
      updatedBy: 'a83409c4-5951-4bb5-bd18-7a0f97c97e51',
    },
    {
      history: [
        {
          year: 0,
          price1: 263,
          volume: 116600,
        },
      ],
      id: 3,
      price1Prev: 263,
      price1Now: 274,
      price1NowOriginal: 263,
      slider1Min: 163,
      slider1Max: 363,
      price2Prev: 800,
      price2Now: 815,
      price2NowOriginal: 800,
      slider2Min: 750,
      slider2Max: 850,
      volumePrev: 116600,
      volumeNow: 123596,
      volumeNowOriginal: 121264,
      incomePrev: -3037,
      type: 'DEPOSITS',
      name: 'DEPOSITS',
      price1Type: 'Interest Rate',
      price1TypeInfoBody:
        'Higher interest rates attract more customers as customers often like to leave their money where it is earning them good rates. Paying higher interest rates costs your bank more.',
      price2Type: 'Branches',
      price2TypeInfoBody:
        'Improving customers’ access through a larger network may attract more customers. Each new branch costs 1m to fit-out and 500k p.a. to maintain (leasing). Reducing the size of the physical network reduces ongoing leasing costs, however expect 1 additional customer complaint per branch closed.',
      version: 3,
      updatedBy: 'a83409c4-5951-4bb5-bd18-7a0f97c97e51',
    },
    {
      history: [
        {
          year: 0,
          price1: 250,
          volume: 1796523,
        },
      ],
      id: 4,
      price1Prev: 250,
      price1Now: 275,
      price1NowOriginal: 250,
      slider1Min: 150,
      slider1Max: 350,
      price2Prev: 125,
      price2Now: 150,
      price2NowOriginal: 125,
      slider2Min: 75,
      slider2Max: 175,
      volumePrev: 1796523,
      volumeNow: 1751610,
      volumeNowOriginal: 1733645,
      incomePrev: 457,
      type: 'CREDIT_CARDS',
      name: 'CREDIT CARDS',
      price1Type: 'Annual Card Fee',
      price1TypeInfoBody:
        'Lower annual fees attract more customers. Lower fees may generate less income.',
      price2Type: 'Reward Points',
      price2TypeInfoBody:
        'Paying higher reward points attracts more customers however paying higher reward points will increase costs.',
      version: 3,
      updatedBy: 'a83409c4-5951-4bb5-bd18-7a0f97c97e51',
    },
  ],
  rates: {
    creditCards: {
      rateNow: 1235,
      ratePrev: 1235,
    },
    cash: {
      rateNow: 250,
      ratePrev: 250,
    },
    wsf: {
      ratePrev: 361,
      rateNow: 365.57961070481053,
    },
  },
  volume: {
    creditCards: {
      volumePrevPrev: 13040,
      groupBalanceSheetValue: 12580,
      volumePrev: 12580,
    },
    cash: {
      volumePrevPrev: 15242,
      volumePrev: 15547,
      volumeNow: 17084,
    },
    savings: {
      volumePrevPrev: 114314,
      volumePrev: 116600,
    },
    transactions: {
      volumePrevPrev: 38105,
      volumePrev: 38867,
    },
    wsf: {
      volumePrevPrev: 81399,
      volumePrev: 86512,
      volumeNow: 99311,
    },
  },
  interest: {
    cash: {
      interestPrevPrev: 412,
      interestPrev: 416,
    },
    nim: {
      interestPrev: 294,
    },
    returnOnAssets: {
      interestPrev: 543,
    },
    costOfFunds: {
      interestPrev: 255,
    },
  },
}
