import { IconType } from '../components/atoms/Icon/Icon'
import { ProductType } from '../types/gameApi/business'
import { SummaryType } from '../types/gameApi/summary'
import { Colour, TableBgColour } from '../types/theme'

const PRODUCT_TO_ICON_MAP: Record<ProductType, IconType> = {
  HOME_LOANS: 'mortgages',
  BUSINESS_LOANS: 'lending',
  DEPOSITS: 'deposits',
  CREDIT_CARDS: 'card',
  INSTITUTIONAL_BANKING: 'institutionalBanking',
  WEALTH_MANAGEMENT: 'wealthManagement',
  OFFSHORE_BANK: 'offshoreBanking',
}

export const getIconForBusiness = (type: ProductType): IconType =>
  PRODUCT_TO_ICON_MAP[type]

const BUSINESS_COLOURS: TableBgColour[] = ['orange', 'primary', 'pink', 'green']

export const getBusinessColour = (index: number): TableBgColour =>
  BUSINESS_COLOURS[index % BUSINESS_COLOURS.length]

const SUMMARY_METRIC_TO_ICON_MAP: Record<SummaryType, IconType> = {
  TSR: 'loan',
  NPS: 'nps',
  ROE: 'roe',
  Customers: 'customers',
  LIE: 'credit',
  Rating: 'rating',
  EmployeeEngagement: 'customers',
  Community: 'leaf',
  RiskIncidents: 'danger',
  Complaints: 'dislike',
  RegulatorActions: 'gavel',
}

export const getIconForSummaryMetric = (type: SummaryType): IconType =>
  SUMMARY_METRIC_TO_ICON_MAP[type]

const METRIC_COLOURS: Colour[] = [
  'orange',
  'primary',
  'pink',
  'green',
  'purple',
  'darkblue',
]
export const getMetricColours = (index: number): Colour =>
  METRIC_COLOURS[index % METRIC_COLOURS.length]
