export const extractQueryParams = <T>() => {
  const url = window.location.href
  const regex = /[?&]([^=#]+)=([^&#]*)/g
  const params: any = {}
  let match

  while ((match = regex.exec(url))) {
    params[match[1]] = decodeURIComponent(match[2])
  }

  // window.history.replaceState({}, document.title, window.location.href.replace(window.location.search, ''));

  return params as T
}

export const extractSearchParams = <T>() => {
  const url = new URL(window.location.href)
  const searchParams = new URLSearchParams(url.search)

  const params: { [key: string]: string } = {}

  // Convert the iterable to an array
  const keysArray = Array.from(searchParams.keys())

  for (const key of keysArray) {
    params[key] = searchParams.get(key) ?? ''
  }

  return params as T
}
