import React from 'react'
import { TreasuryResponse } from '../../../../types/gameApi/treasury'
import { DecisionMap } from '../TreasuryContainer'
import TreasuryWSFProfileManageFunding from './TreasuryWSFProfileManageFunding'
import LandscapeMobilePage from '../../../atoms/LandscapeMobilePage/LandscapeMobilePage'
import TreasuryWSFProfileChart from './TreasuryWSFProfileChart'

import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import Legend from './Legend'

interface Props {
  data: TreasuryResponse
  decisions: DecisionMap
  enableChanges: boolean
  onChangeValue: (val: Partial<DecisionMap>) => void
  handleClose: () => void
}

const TreasuryWSFProfileMobile: React.FC<Props> = ({
  handleClose,
  ...props
}) => {
  return (
    <div className=" ">
      <LandscapeMobilePage handleClose={handleClose}>
        <div className="absolute top-0 left-0 h-full w-full pt-4">
          <HorizontalGroup className="pl-2">
            <TreasuryWSFProfileManageFunding {...props} />
          </HorizontalGroup>

          <HorizontalGroup className="h-56 p-3">
            <TreasuryWSFProfileChart {...props} />
            <div className="h-56 pt-4">
              <Legend />
            </div>
          </HorizontalGroup>
        </div>
      </LandscapeMobilePage>
    </div>
  )
}

export default TreasuryWSFProfileMobile
