import React, { useEffect, useState, useCallback, useMemo } from 'react'

import {
  fetchProjects,
  fetchProjectsBackground,
  removeProjectDecision,
  updateProjectDecision,
} from '../../../redux/projects/projectsActions'
import { useAppDispatch, useAppSelector } from '../../../store'
import Page from '../../atoms/Page/Page'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import CentredSpinner from '../../molecules/CentredSpinner/CentredSpinner'
import MobilePageHeader from '../../organisms/MobilePageHeader/MobilePageHeader'
import SpecialProjectThumbnail from './SpecialProjectThumbnail'
import PrimaryText from '../../atoms/Text/PrimaryText'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import ProjectSelector from './ProjectSelector'
import Modal from '../../atoms/Modal/Modal'
import classNames from 'classnames'
import { Combobox } from '@headlessui/react'
import {
  Project,
  ProjectImpact,
  ProjectOption,
  ProjectType,
} from '../../../types/gameApi/projects'
import InlineError from '../../atoms/InlineError/InlineError'
import { usePrevious } from 'react-use'
import Spinner from '../../atoms/Spinner/Spinner'

import SortModal from './SortModal'
import ProjectSelectModal from './ProjectSelectModal'
import ProjectDetails from './ProjectDeatails'
import { PROJECT_TYPE_DURATION } from './numberOfYears'
import { useIsFeatureEnabledForRound } from '../../../hooks/useIsFeatureEnabled'
import SpecialProjectsTour from './SpecialProjectsTour'
import ErrorModal from '../../atoms/Modal/ErrorModal'
import { clearError } from '../../../redux/projects/projectsSlice'

const SpecialProjects = () => {
  const [isProductModalOpen, setIsProductModalOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedImpact, setSelectedImpact] = useState<
    ProjectImpact | undefined
  >(undefined)
  const [sortByDeliveryTime, setSortByDeliveryTime] = useState(false)
  const [selectedProjectId, setSelectedProjectId] = useState<number | null>(
    null,
  )
  const [allProjectsVisible, setAllProjectsVisible] = useState(true)

  const {
    isSubmitting,
    isLoading,
    error,
    submitError,
    deleteError,
    projects,
    teamId,
    roundId,
    canSelect,
    demoEnabled,
    demoEnabledImpacts,
    currentRound,
  } = useAppSelector(state => ({
    isSubmitting: state.projects.isSubmitting || state.projects.isDeleting,
    isLoading: state.projects.isLoading,
    error: state.projects.error,
    projects: state.projects.projects,
    submitError: state.projects.submitError,
    deleteError: state.projects.deleteError,
    teamId: state.event.details?.team ?? 0,
    roundId: state.game.selectedRound,
    canSelect: state.game.selectedRound === state.game.currentRound,
    demoEnabled: state.demo.enabled,
    demoEnabledImpacts: state.demo.enabledImpacts,
    currentRound: state.game.currentRound,
  }))

  const isProjectsEnabled = useIsFeatureEnabledForRound('special-projects')
  const previousVals = usePrevious({ isSubmitting })
  const dispatch = useAppDispatch()

  const fetchProjectsData = useCallback(() => {
    dispatch(fetchProjects({ roundId, teamId }))
  }, [dispatch, roundId, teamId])

  const GetProjectStatus = useCallback(
    (
      project: Project | undefined,
      viewingRoundId: number,
    ): 'completed' | 'selected' | 'inProgress' | null => {
      if (!project || project.usedInRound == null) {
        return null
      }

      const projectDuration =
        PROJECT_TYPE_DURATION[project.type as ProjectType] || 0
      const projectEndRound = project.usedInRound + projectDuration

      if (viewingRoundId < project.usedInRound) {
        return null
      }

      if (viewingRoundId >= projectEndRound) {
        return 'completed'
      }

      if (viewingRoundId === project.usedInRound) {
        return 'selected'
      }

      return 'inProgress'
    },
    [],
  )

  const SpecialProjects = useMemo(() => {
    if (!projects[roundId]) return []

    return projects[roundId].map(project => ({
      ...project,
      status: GetProjectStatus(project, roundId),
    }))
  }, [projects, roundId, GetProjectStatus])

  useEffect(() => {
    if (!isLoading && !error && !projects[roundId] && isProjectsEnabled) {
      fetchProjectsData()
    }
    const interval = setInterval(() => {
      dispatch(fetchProjectsBackground({ teamId, roundId }))
    }, 5000)
    return () => clearInterval(interval)
  }, [
    dispatch,
    error,
    fetchProjectsData,
    isLoading,
    projects,
    roundId,
    teamId,
    isProjectsEnabled,
  ])

  useEffect(() => {
    if (SpecialProjects.length > 0 && selectedProjectId == null) {
      setSelectedProjectId(SpecialProjects[0].id)
    }
  }, [SpecialProjects, selectedProjectId])

  useEffect(() => {
    if (previousVals?.isSubmitting && !isSubmitting) {
      setIsModalOpen(false)
      setIsProductModalOpen(false)
    }
  }, [error, isSubmitting, previousVals?.isSubmitting])

  const handleSelectClick = () => {
    if (!SpecialProjects.find(p => p.usedInRound === roundId)) {
      handleOKClick()
    } else {
      setIsModalOpen(true)
    }
  }

  const handleOKClick = useCallback(() => {
    if (selectedProjectId) {
      const selectedProject = SpecialProjects.find(
        p => p.id === selectedProjectId,
      )

      if (
        selectedProject?.options &&
        selectedProject?.usedInRound !== roundId
      ) {
        setIsProductModalOpen(true)
      } else {
        if (selectedProject?.usedInRound === roundId) {
          dispatch(
            removeProjectDecision({
              roundId,
              teamId,
            }),
          )
        } else {
          dispatch(
            updateProjectDecision({
              roundId,
              teamId,
              projectId: selectedProjectId,
            }),
          )
        }
      }
    }
  }, [dispatch, SpecialProjects, roundId, selectedProjectId, teamId])

  const handleOptionClick = (option: ProjectOption) => {
    if (selectedProjectId) {
      dispatch(
        updateProjectDecision({
          roundId,
          teamId,
          projectId: selectedProjectId,
          businessId: option.value,
        }),
      )
    }
  }

  if (error) {
    return (
      <Page full>
        <InlineError
          message={error.message}
          onRetry={!projects[roundId] ? fetchProjectsData : null}
        />
      </Page>
    )
  }

  if (isLoading || !projects[roundId] || !selectedProjectId) {
    return <CentredSpinner />
  }

  const sortedProjects = [...SpecialProjects].sort((a, b) => {
    if (sortByDeliveryTime) {
      const deliveryTimeA = PROJECT_TYPE_DURATION[a.type] || 0
      const deliveryTimeB = PROJECT_TYPE_DURATION[b.type] || 0
      return deliveryTimeB - deliveryTimeA
    } else {
      if (!selectedImpact || !a.impacts || !b.impacts) {
        return 0
      }
      const impactA = a.impacts.find(
        impact => impact.id === selectedImpact.id,
      ) || { value: 0 }
      const impactB = b.impacts.find(
        impact => impact.id === selectedImpact.id,
      ) || { value: 0 }
      return impactB.value - impactA.value
    }
  })

  const selectedProject = sortedProjects.find(p => p.id === selectedProjectId)
  const activeProjectIsTheSelectedProject =
    selectedProject?.usedInRound === roundId

  const allImpacts =
    SpecialProjects[0]?.impacts.filter(i => {
      if (demoEnabled) {
        return demoEnabledImpacts.includes(i.id)
      }
      return i.enabled
    }) ?? []
  return (
    <HorizontalGroup className="h-full w-full">
      {isProductModalOpen && (
        <Modal
          title="Select product to market"
          show={isProductModalOpen}
          onClose={() => setIsProductModalOpen(false)}
          showFooter
        >
          <Combobox
            onChange={(item: ProjectOption) => {
              handleOptionClick(item)
            }}
          >
            {selectedProject?.options && (
              <Combobox.Options
                static
                className="flex max-h-96 w-96 flex-col gap-5 overflow-y-auto p-3"
              >
                {selectedProject.options.map((option, index) => (
                  <Combobox.Option
                    key={index}
                    value={option}
                    className={({ active }) =>
                      classNames(
                        'flex cursor-pointer select-none rounded-xl p-3',
                        {
                          'bg-primary-400': active,
                        },
                      )
                    }
                  >
                    {({ active }) => (
                      <div className="ml-4 flex-auto">
                        <HorizontalGroup fullWidth verticalCenter between>
                          <PrimaryText
                            colour={active ? 'white' : 'black'}
                            size="sm"
                          >
                            {option.label}
                          </PrimaryText>
                          {active && isSubmitting && <Spinner colour="white" />}
                        </HorizontalGroup>
                      </div>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}
          </Combobox>
        </Modal>
      )}
      <div
        className={classNames(
          'hidden h-screen w-auto gap-3 overflow-y-auto border-t bg-white px-4 py-3 laptop:flex laptop:flex-col',
        )}
        id="tour-projects-List"
      >
        <SortModal
          allImpacts={allImpacts}
          selectedImpact={selectedImpact}
          setSelectedImpact={setSelectedImpact}
          setSortByDeliveryTime={setSortByDeliveryTime}
        />

        {sortedProjects.map((project: Project) => (
          <SpecialProjectThumbnail
            currentRound={currentRound}
            roundId={roundId}
            key={project.id}
            project={project}
            active={selectedProjectId === project.id}
            projectStatus={GetProjectStatus(project, roundId)}
            onClick={() => {
              setSelectedProjectId(Number(project.id))
              setAllProjectsVisible(false)
            }}
            selectedOption={project.options?.find(
              (option: ProjectOption) =>
                option.value === selectedProject?.businessId,
            )}
            selectedImpact={project.impacts.find(
              (impact: ProjectImpact) => impact.name === selectedImpact?.name,
            )}
          />
        ))}
      </div>
      <VerticalGroup className="laptop:w-[73%] monitor:w-[82%]">
        {submitError && (
          <ErrorModal
            showModal={!!submitError}
            heading="An error occurred"
            message={`An error occurred while selecting the project. (${submitError.message}). Please try again`}
            onClose={() => dispatch(clearError())}
          />
        )}
        {deleteError && (
          <ErrorModal
            showModal={!!deleteError}
            heading="An error occurred"
            message={`An error occurred while unselecting the project. (${deleteError.message}). Please try again`}
            onClose={() => dispatch(clearError())}
          />
        )}
        <MobilePageHeader />

        {selectedProject && (
          <ProjectDetails
            project={selectedProject}
            demoEnabled={demoEnabled}
            demoEnabledImpacts={demoEnabledImpacts}
          />
        )}
        {isModalOpen && (
          <ProjectSelectModal
            isModalOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onCancel={() => setIsModalOpen(false)}
            isSubmitting={isSubmitting}
            activeProjectIsTheSelectedProject={
              activeProjectIsTheSelectedProject
            }
            handleOKClick={handleOKClick}
          />
        )}

        <ProjectSelector
          canSelect={!isSubmitting && canSelect}
          projectStatus={GetProjectStatus(selectedProject, roundId)}
          isSubmitting={isSubmitting}
          onHandleSelectClick={handleSelectClick}
          onHandleViewAllProjectsClick={() => setAllProjectsVisible(true)}
          currentRound={currentRound}
          roundId={roundId}
        />

        {allProjectsVisible && (
          <VerticalGroup
            gap={4}
            className="z-1 fixed left-0 bottom-0 h-full w-full overflow-y-auto bg-white p-4 pt-24 tablet:max-h-[50vh] laptop:hidden"
          >
            <SortModal
              setSortByDeliveryTime={setSortByDeliveryTime}
              allImpacts={allImpacts}
              selectedImpact={selectedImpact}
              setSelectedImpact={setSelectedImpact}
            />
            <div className="grid grid-cols-1 gap-5 tablet:grid-cols-3">
              {sortedProjects.map((project: Project) => (
                <SpecialProjectThumbnail
                  currentRound={currentRound}
                  roundId={roundId}
                  key={project.id}
                  project={project}
                  active={selectedProjectId === project.id}
                  projectStatus={GetProjectStatus(project, roundId)}
                  onClick={() => {
                    setSelectedProjectId(Number(project.id))
                    setAllProjectsVisible(false)
                  }}
                  selectedOption={project.options?.find(
                    (option: ProjectOption) =>
                      option.value === selectedProject?.businessId,
                  )}
                  selectedImpact={project.impacts.find(
                    (impact: ProjectImpact) =>
                      impact.name === selectedImpact?.name,
                  )}
                />
              ))}
            </div>
          </VerticalGroup>
        )}
      </VerticalGroup>
      <SpecialProjectsTour />
    </HorizontalGroup>
  )
}

export default SpecialProjects
