import React from 'react'
import {
  AssessmentQuestion,
  WrittenTextField,
} from '../../../../types/gameApi/assessments'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import QuestionTitle from './QuestionTitle'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import QuestionContainer from './QuestionContainer'

interface Props {
  question: AssessmentQuestion
}

export const WrittenText: React.FC<Props> = ({ question }) => {
  const field = question.fields[0] as WrittenTextField
  return (
    <QuestionContainer>
      <QuestionTitle hasQuestionIndex={false} title={question.question} />
      <VerticalGroup gap={2}>
        <SecondaryText className="w-full break-words" size="lg" isHtml>
          {field.config.text}
        </SecondaryText>
      </VerticalGroup>
    </QuestionContainer>
  )
}
