import React, { useCallback } from 'react'
import { PeerInsightsMetricTabs } from '../../../../redux/peer-insights/peerInsightsSlice'
import { Colour } from '../../../../types/theme'
import Clickable from '../../../atoms/Clickable/Clickable'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { Icon } from '../../../atoms/Icon/Icon'
import PrimaryText from '../../../atoms/Text/PrimaryText'
import {
  getIconForSummaryMetric,
  getMetricColours,
} from '../../../../lib/icons'

import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import { MappedMetric } from './SummaryMetricMapper'

interface Props {
  index: number
  metric: MappedMetric
  active?: boolean
  onClick?: (tab: PeerInsightsMetricTabs) => void
  isLandscapeMobile?: boolean
  colour?: Colour
  id?: string
}

const SummaryMetricTab: React.FC<Props> = ({
  index,
  metric,
  onClick,
  isLandscapeMobile,
  id,

  active,
}) => {
  const colour: Colour = getMetricColours(index)

  const selectMetric = useCallback(() => {
    onClick && onClick(metric.type)
  }, [metric.type, onClick])

  const Group = isLandscapeMobile ? HorizontalGroup : VerticalGroup

  return (
    <Clickable id={id} onClick={selectMetric}>
      {!isLandscapeMobile && (
        <div className="hidden rounded-xl tablet:block">
          <Group
            className={` ${
              active ? ` bg-${colour} border-0 text-white` : 'bg-white'
            } h-22 min-w-[150px] max-w-[160px] gap-2 rounded-xl border border-primary p-1 text-start leading-tight`}
          >
            {' '}
            <VerticalGroup gap={1} between className="h-full w-full p-1">
              <HorizontalGroup
                id="test2"
                gap={1}
                className="h-10"
                verticalCenter
              >
                <div className="w-8">
                  <Icon
                    size={8}
                    type={getIconForSummaryMetric(metric.type)}
                    colour={active ? 'white' : colour}
                  />
                </div>

                <PrimaryText className="leading-none" size="sm">
                  {metric.text}
                </PrimaryText>
              </HorizontalGroup>

              <HorizontalGroup gap={2} className="pl-9">
                <PrimaryText size="sm" weight="bold">
                  {metric.teamValue}
                </PrimaryText>
                <PrimaryText className="opacity-40">I</PrimaryText>
                <PrimaryText size="sm">{metric.teamRank}</PrimaryText>
              </HorizontalGroup>
            </VerticalGroup>
          </Group>
        </div>
      )}
      {isLandscapeMobile && (
        <HorizontalGroup verticalCenter gap={1}>
          <Icon
            className="h-full w-4"
            type={getIconForSummaryMetric(metric.type)}
            colour={active ? 'white' : colour}
          />
          <PrimaryText size="sm">{metric.text}</PrimaryText>
          <PrimaryText className="opacity-40">I</PrimaryText>
          <HorizontalGroup verticalCenter className=" " gap={1}>
            <PrimaryText size="sm" weight="bold">
              {metric.teamValue}
            </PrimaryText>
            <PrimaryText className="opacity-40">I</PrimaryText>
            <PrimaryText size="sm">{metric.teamRank}</PrimaryText>
          </HorizontalGroup>
        </HorizontalGroup>
      )}
    </Clickable>
  )
}

export default SummaryMetricTab
