import { createAsyncThunk } from '@reduxjs/toolkit'
import adminAPI from '../../services/adminApi'
import gameAPI from '../../services/gameApi'
import { AuthDetails, setAuthDetails } from '../../lib/localStorage'
import APIError from '../../errors/APIError'
import { LoginParams } from '../../types/adminApi/auth'
import { getOrRegisterDevice } from '../device/deviceActions'
import { DeviceResponse } from '../../types/gameApi/device'
import { ParticipantDetailsResponse } from '../../types/adminApi/events'
import { ContentResponse } from '../../types/gameApi/content'
import { addVariables } from '../../lib/googleTagManager'

export const userLogin = createAsyncThunk<
  {
    auth: AuthDetails
    device: DeviceResponse
    event: ParticipantDetailsResponse
    content: ContentResponse
  },
  LoginParams,
  { rejectValue: Error | APIError }
>(
  'auth/login',
  async (
    { email, password, eventId, isTablet, isPreviewMode },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await adminAPI.login({ eventId, email, password })

      const participantId = data.id

      // TODO https://excelerateconsulting.atlassian.net/browse/RU-1
      const authDetails: AuthDetails = {
        eventId,
        participantId,
        email,
        token: data.token,
        isTablet: isTablet ?? false,
        isPreviewMode: isPreviewMode ?? false,
      }
      setAuthDetails(authDetails)
      adminAPI.setToken(data.token)
      gameAPI.setToken(data.token)
      gameAPI.setGameId(eventId)
      adminAPI.setEventId(eventId)

      const eventResponse = await adminAPI.getParticipantDetails({
        eventId,
        participantId,
      })

      const deviceResponse = await getOrRegisterDevice({
        deviceId: participantId,
        team: eventResponse.team,
        isMaster: eventResponse.isCeo,
      })

      const contentResponse = await gameAPI.getContent()

      addVariables({
        eventId,
        participantId,
        isTablet,
      })

      return {
        auth: authDetails,
        event: eventResponse,
        device: deviceResponse,
        content: contentResponse,
      }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)
