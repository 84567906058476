import React, { PropsWithChildren } from 'react'
import SecondaryText, { Props as TextProps } from '../Text/SecondaryText'

interface Props extends Omit<TextProps, 'family'> {}

const TableText: React.FC<PropsWithChildren<Props>> = props => {
  return <SecondaryText size="xs" {...props} />
}

export default TableText
