import React from 'react'
import { FinancialsDivisionProfitAndLossBusiness } from '../../../../types/gameApi/financials'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { Icon, IconType } from '../../../atoms/Icon/Icon'
import PrimaryText from '../../../atoms/Text/PrimaryText'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import ValueChangeText from '../../../molecules/ValueChangeText/ValueChangeText'
import MetricChangePill from '../../../organisms/MetricCard/MetricChangePill'
import { getBusinessColour, getMetricColours } from '../../../../lib/icons'
import { BACKGROUND_COLOUR_LIGHT_SHADE_CLASSES } from '../../../../lib/colourClasses'
import { Colour } from '../../../../types/theme'
import MetricCard from '../../../organisms/MetricCard/MetricCard'
interface Props {
  index: number
  item: FinancialsDivisionProfitAndLossBusiness
  iconType: IconType
  id?: string
}

const ProfitAndLossMetric: React.FC<Props> = ({
  index,
  item,
  iconType,
  id,
}) => {
  const value = (item.roe / 100).toFixed(1)
  const colour = getBusinessColour(index)
  const bgcolour: Colour = getMetricColours(index)
  return (
    <MetricCard id={id} colour={'white'} noShadow>
      <VerticalGroup
        full
        center
        verticalCenter
        className={`${BACKGROUND_COLOUR_LIGHT_SHADE_CLASSES[bgcolour]} w-full rounded-lg rounded-b-none py-1`}
      >
        <Icon type={iconType} colour={colour} size={8} />

        <PrimaryText weight="bold" size="sm">
          {item.businessName}
        </PrimaryText>
      </VerticalGroup>
      <HorizontalGroup className="items-center pb-2 desktop:flex-col" center>
        <PrimaryText weight="bold" size="xl">
          {value}
          <PrimaryText size="sm">%</PrimaryText>
        </PrimaryText>
        <MetricChangePill backgroundColour="lightgray" className="px-1">
          <ValueChangeText
            dp={1}
            value={Number(item.roeChange)}
            isCost={false}
            changeUnit="%"
            textColour="black"
            removePlusSign
            removeMinusSign
            size="xs"
          />
        </MetricChangePill>
      </HorizontalGroup>
    </MetricCard>
  )
}

export default ProfitAndLossMetric
