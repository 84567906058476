import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { reset } from '../game/gameSlice'
import { logout } from '../auth/authSlice'

interface HelpState {
  currentPage: number
  totalPages: number
  files: string[]
}

const initialState: HelpState = {
  currentPage: 0,
  totalPages: 0,
  files: [],
}

const helpSlice = createSlice({
  name: 'help',
  initialState,
  reducers: {
    changePage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload
    },
    setTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload
    },
    setFiles: (state, action: PayloadAction<string[]>) => {
      state.files = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(reset, () => {
        return initialState
      })
      .addCase(logout, () => {
        return initialState
      })
  },
})

export const { changePage, setTotalPages, setFiles } = helpSlice.actions

export default helpSlice.reducer
