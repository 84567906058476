import { CostsResponse } from '../../../../../types/gameApi/costs'

export const data: CostsResponse = {
  totalPeopleCosts: 1779,
  defaultPayrise: 200,
  divisions: [
    {
      id: 1,
      name: 'IT & OPERATIONS',
      type: 'IT_OPERATIONS',
      enabled: true,
      maxValue: 25,
      minValue: -25,
      costSplit: 3500,
      complaints: -200,
      riskIncidents: -175,
      employeeEngagement: 0,
      customerSatisfaction: 300,
      fteCosts: 208,
      fteNewCosts: 424,
      value_change: 1100,
      value_change_original: 1300,
    },
    {
      id: 2,
      name: 'FRONT LINE',
      type: 'FRONT_LINE',
      enabled: true,
      maxValue: 25,
      minValue: -25,
      costSplit: 4000,
      complaints: -200,
      riskIncidents: 0,
      employeeEngagement: 0,
      customerSatisfaction: 500,
      fteCosts: 104,
      fteNewCosts: 159,
      value_change: 1100,
      value_change_original: 1100,
    },
    {
      id: 3,
      name: 'RISK & COMPLIANCE',
      type: 'RISK_COMPLIANCE',
      enabled: true,
      maxValue: 25,
      minValue: -25,
      costSplit: 1500,
      complaints: 0,
      riskIncidents: -250,
      employeeEngagement: -1000,
      customerSatisfaction: 0,
      fteCosts: 229,
      fteNewCosts: 1061,
      value_change: 1200,
      value_change_original: 1700,
    },
    {
      id: 4,
      name: 'HR & TRAINING',
      type: 'HR_TRAINING',
      enabled: true,
      maxValue: 25,
      minValue: -25,
      costSplit: 1000,
      complaints: 0,
      riskIncidents: 0,
      employeeEngagement: 1500,
      customerSatisfaction: 0,
      fteCosts: 218,
      fteNewCosts: 1167,
      value_change: 1000,
      value_change_original: 1100,
    },
    {
      id: 5,
      name: 'SALARY GROWTH',
      type: 'SALARY_GROWTH',
      enabled: true,
      maxValue: 10,
      minValue: -10,
      costSplit: 1,
      complaints: 0,
      riskIncidents: 0,
      employeeEngagement: 1,
      customerSatisfaction: 0,
      fteCosts: null,
      fteNewCosts: null,
      value_change: 200,
      value_change_original: 200,
    },
  ],
  npsChange: 14,
  npsOpening: 3400,
  npsClosingScore: 4059,
  employeeEngagementChange: 125,
  employeeEngagementOpening: 5901,
  employeeEngagementClosingScore: 6342,
  employeeEngagementSalaryIncrease: 1000,
  employeeEngagementSalaryDecrease: 2000,
  openingRoundEmployeeEngagement: 5000,
  outputGain: 700,
  outputLoss: 1200,
  complaintsChange: 290,
  complaintsOpening: 585,
  complaintsClosing: 552,
  openingRoundRiskIncidents: 750,
  riskIncidentsChange: 250,
  riskIncidentsOpening: 633,
  riskIncidentsClosing: 534,
  communityTrustChange: 0,
  communityTrustChangeSetup: 250,
  communityTrustChangeThreshold: 100,
  communityTrustOpening: 5250,
  communityTrustClosing: 5750,
  regulatorActions: 0,
  regulatorActionsOpening: 0,
  regulatorActionsClosing: 0,
  regulatorImpact: 1500,
  fteChangeDivision1: 329,
  fteChangeDivision2: 753,
  fteChangeDivision3: 140,
  fteChangeDivision4: 82,
  salaryChange: 0,
  version: 20,
  updatedBy: '150981d1-fb27-4a69-b4b9-50075541a965',
}
