import { createSlice } from '@reduxjs/toolkit'
import { fetchResources } from './resourcesActions'
import APIError from '../../errors/APIError'
import { ResourceResponse } from '../../types/adminApi/resources'
import { reset } from '../game/gameSlice'
import { logout } from '../auth/authSlice'

interface ResourceState {
  isLoading: boolean
  isLoaded: boolean
  error: Error | APIError | null
  resources: ResourceResponse | null
}

const initialState: ResourceState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  resources: null,
}

const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(reset, () => {
        return initialState
      })
      .addCase(logout, () => {
        return initialState
      })
      .addCase(fetchResources.pending, state => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchResources.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isLoaded = true
        state.resources = payload
      })
      .addCase(fetchResources.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload ?? null
      })
  },
})

export default resourcesSlice.reducer
