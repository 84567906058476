import { createAsyncThunk } from '@reduxjs/toolkit'
import gameAPI from '../../services/gameApi'
import APIError from '../../errors/APIError'
import {
  ExcoRequestParams,
  ExcoResponse,
  ExcoSubmitRequestParams,
} from '../../types/gameApi/exco'
import { RootState } from '../../store'
import ThunkCancelledError from '../../errors/ThunkCancelledError'

export const fetchExco = createAsyncThunk<
  { roundId: number; exco: ExcoResponse },
  ExcoRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'exco/fetch',
  async ({ teamId, roundId }, { rejectWithValue, getState, requestId }) => {
    const state = getState()
    try {
      if (state.exco.requestInProgress !== requestId) {
        throw new ThunkCancelledError()
      }
      const exco = await gameAPI.getExco({ teamId, roundId })

      return { roundId, exco }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)

export const fetchExcoBackground = createAsyncThunk<
  { roundId: number; exco: ExcoResponse },
  ExcoRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'exco/fetchBackground',
  async ({ teamId, roundId }, { rejectWithValue, getState, requestId }) => {
    const state = getState()
    try {
      if (state.exco.requestInProgress !== requestId) {
        throw new ThunkCancelledError()
      }
      const exco = await gameAPI.getExco({ teamId, roundId })

      return { roundId, exco }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)

export const submitExco = createAsyncThunk<
  { roundId: number; decision: number },
  ExcoSubmitRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>('exco/submit', async ({ teamId, roundId, decision }, { rejectWithValue }) => {
  try {
    await gameAPI.submitExco({ teamId, roundId, decision })

    return { roundId, decision }
  } catch (error: any) {
    return rejectWithValue(error)
  }
})
