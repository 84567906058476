import React, { useCallback } from 'react'
import { CostsDivision, CostsResponse } from '../../../../types/gameApi/costs'
import Card from '../../../atoms/Card/Card'
import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import InfoPopup from '../../../atoms/InfoPopup/InfoPopup'
import Donut from '../../../atoms/Donut/Donut'
import { Icon } from '../../../atoms/Icon/Icon'
import { Colour } from '../../../../types/theme'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { divisionConfigMap } from './DivisionConfig'
import ValueChangeText from '../../../molecules/ValueChangeText/ValueChangeText'
import ValueChangerWithInput from '../../../molecules/ValueChanger/ValueChangerWithInput'
import { DecisionMap } from '../CostsContainer'
import { percentage } from '../../../../lib/formatters'
import { CostsData } from '../Data'
import classNames from 'classnames'
import Clickable from '../../../atoms/Clickable/Clickable'

interface CostsDivisionCardProps {
  division: CostsDivision
  CostsData: CostsData
  colour: Colour
  changeInCost: number
  data: CostsResponse
  enableChanges: boolean
  decisions: DecisionMap
  onChangeValue: (val: Partial<DecisionMap>) => void
  fteChange: number
  effectiveDecision: number
  selectedDivision: string | null
  onSelectDivision: (divisionType: string) => void
  linkedDivisions: (keyof DecisionMap)[]
  setLinkedDivisions: (linkedDivisions: (keyof DecisionMap)[]) => void
  id?: string
}

const CostsDivisionCard: React.FC<CostsDivisionCardProps> = ({
  changeInCost,
  division,
  decisions,
  colour,
  enableChanges,
  onChangeValue,
  fteChange,
  selectedDivision,
  onSelectDivision,
  linkedDivisions,
  setLinkedDivisions,
  effectiveDecision,
  id,
}) => {
  const { icon, body } = divisionConfigMap[division.type]

  const handleLinkClick = () => {
    if (linkedDivisions.length === 4) {
      setLinkedDivisions([])
    } else {
      setLinkedDivisions([
        'IT_OPERATIONS',
        'FRONT_LINE',
        'RISK_COMPLIANCE',
        'HR_TRAINING',
      ] as (keyof DecisionMap)[])
    }
  }

  const handleChangeValue = useCallback(
    (newValue: number) => {
      const newValues: Partial<DecisionMap> = {}
      const oldValue = decisions[division.type]
      const change = newValue - oldValue

      if (linkedDivisions.includes(division.type)) {
        linkedDivisions.forEach(divisionType => {
          const oldValue = decisions[divisionType]
          const newDivisionValue = oldValue + change
          const limitedNewValue = Math.max(
            division.minValue * 100,
            Math.min(division.maxValue * 100, newDivisionValue),
          )
          newValues[divisionType] = limitedNewValue
        })
      } else {
        newValues[division.type] = newValue
      }

      onChangeValue(newValues)
    },
    [
      onChangeValue,
      decisions,
      division.type,
      division.minValue,
      division.maxValue,
      linkedDivisions,
    ],
  )

  const handleReset = useCallback(() => {
    onChangeValue({ [division.type]: division.value_change_original })
  }, [onChangeValue, division.type, division.value_change_original])

  return (
    <Card
      id={id}
      onClick={() => onSelectDivision(division.type)}
      active={selectedDivision === division.type}
      className={classNames('h-full flex-grow py-2 desktop:py-1')}
    >
      <VerticalGroup full fullWidth gap={3} center>
        <VerticalGroup className="w-full" gap={1} center>
          <HorizontalGroup fullHeight gap={2}>
            <TranslatedText
              weight="bold"
              value={`operatingCosts.businessfunctions.${division.type}.name`}
              default={division.name}
            />
            <InfoPopup
              iconColour="primary"
              title={division.name}
              body={body}
              analyticsParams={{
                category: 'costs',
                action: 'open',
                label: division.type,
              }}
            />
          </HorizontalGroup>
          <SecondaryText size="xs" colour="gray">
            {division.type === 'SALARY_GROWTH' ? 'Staff Pay Rises' : 'Output'}
          </SecondaryText>
          <HorizontalGroup className="w-36 desktop:w-32">
            <Donut
              value={decisions[division.type]}
              min={division.minValue * 100}
              max={division.maxValue * 100}
              colour={colour}
            >
              <Icon size={10} type={icon} colour={colour} />
              <ValueChangeText
                dp={0}
                dashIfZero
                weight="bold"
                size="xl"
                center
                removeArrow
                value={effectiveDecision}
                isCost
                textColour="black"
                changeUnit="%"
                idPrefix={id}
              />
            </Donut>
          </HorizontalGroup>
        </VerticalGroup>
        <VerticalGroup full>
          <HorizontalGroup fullWidth verticalCenter fullHeight center gap={3}>
            <VerticalGroup center>
              <SecondaryText colour="gray" size="xs">
                Previous Investment
              </SecondaryText>
              <ValueChangeText
                textColour="black"
                size="base"
                removeArrow
                dashIfZero
                value={division.value_change_original / 100}
                isCost
                changeUnit="% "
              />
            </VerticalGroup>
          </HorizontalGroup>
          <HorizontalGroup
            verticalCenter
            center
            gap={3}
            fullWidth
            fullHeight
            className="desktop:flex-col desktop:items-center desktop:gap-3"
          >
            <VerticalGroup
              center
              between
              verticalCenter
              gap={3}
              className="h-full pb-2 desktop:h-fit desktop:gap-2 desktop:pb-0"
            >
              <HorizontalGroup
                className=""
                gap={4}
                center
                verticalCenter
                fullWidth
              >
                {division.type !== 'SALARY_GROWTH' && (
                  <Clickable onClick={handleLinkClick}>
                    <Icon
                      idPrefix={id}
                      size={4}
                      type={linkedDivisions.length === 4 ? 'lock' : 'unlock'}
                    />
                  </Clickable>
                )}
                {division.type == 'SALARY_GROWTH' && (
                  <div className="h-4 w-4"></div>
                )}
                <SecondaryText colour="gray" size="xs">
                  Investment
                </SecondaryText>
                <Clickable onClick={handleReset}>
                  <HorizontalGroup
                    center
                    verticalCenter
                    className="border-gray h-7 w-7 rounded-full border desktop:h-8 desktop:w-8"
                  >
                    <Icon
                      responsiveSize="w-2 laptop:w-3"
                      type="reset"
                      colour="black"
                    />
                  </HorizontalGroup>
                </Clickable>
              </HorizontalGroup>

              <div className="order-1 desktop:order-none">
                <ValueChangerWithInput
                  buttonSize="medium"
                  inputSize="small"
                  modalProps={{
                    heading: division.name,
                  }}
                  type="percentage"
                  value={decisions[division.type]}
                  formatValue={(value: number) => percentage(value, 0)}
                  onChange={handleChangeValue}
                  min={division.minValue * 100}
                  max={division.maxValue * 100}
                  enableChanges={enableChanges}
                  increments={[1, 5]}
                  step={100}
                  buttonIdPrefix={id}
                />
              </div>
            </VerticalGroup>
            <VerticalGroup
              verticalCenter
              between
              gap={5}
              className="order-3 h-full w-20 py-3 pt-1.5 desktop:order-none desktop:gap-0 desktop:py-0"
              center
            >
              <SecondaryText whitespace="nowrap" colour="gray" size="xs">
                Cost Impact
              </SecondaryText>

              <ValueChangeText
                size="base"
                removeArrow
                negativeInBrackets
                dashIfZero
                value={Number(changeInCost.toFixed(0))}
                isCost
                changeUnit=" m"
                idPrefix={`${id}-cost-change`}
              />
            </VerticalGroup>
            <VerticalGroup
              verticalCenter
              between
              gap={5}
              className="order-first w-20 py-3 pt-1.5 desktop:order-none desktop:gap-0 desktop:py-0"
              center
              full
            >
              <SecondaryText whitespace="nowrap" colour="gray" size="xs">
                People Impact
              </SecondaryText>
              <ValueChangeText
                whitespace="nowrap"
                size="base"
                center
                removeArrow
                negativeInBrackets
                dashIfZero
                value={Number(fteChange.toFixed(0))}
                isCost
                changeUnit=" FTE"
                textColour="black"
                idPrefix={`${id}-fte-change`}
              />
            </VerticalGroup>
          </HorizontalGroup>
        </VerticalGroup>
      </VerticalGroup>
    </Card>
  )
}

export default CostsDivisionCard
