import React, { Fragment } from 'react'

import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'
import { FontSize, FontWeight, TextColour } from '../../../../types/theme'
import Table from '../../../atoms/Table/Table'
import TableHeader from '../../../atoms/Table/TableHeader'
import TableSection from '../../../atoms/Table/TableSection'
import TableRow from '../../../atoms/Table/TableRow'
import TableCell from '../../../atoms/Table/TableCell'
import TableHeaderCell from '../../../atoms/Table/TableHeaderCell'
import TableText from '../../../atoms/Table/TableText'
import {
  PeerInsightsMarketShare,
  PeerInsightsMarketShareTeam,
} from '../../../../types/gameApi/peerInsights'
import { useTranslatedText } from '../../../../hooks/useTranslatedText'
import {
  formatHeader,
  getTypeUnitLabel,
  getValue,
  getChangeColor,
  displayChange,
  getChange,
  getTotals,
} from './formatters'
import TableFooter from '../../../atoms/Table/TableFooter'
import MarketShareTableTourWrapper from '../PeerInsightsTour/MarketShareTableTourWrapper'

interface Props {
  data: PeerInsightsMarketShare
  teamId: number
}

interface RowProps {
  team: PeerInsightsMarketShareTeam
  headings: string[]
  weight?: FontWeight
  colour?: TextColour
  size?: FontSize
  highlight?: boolean
}

const Row: React.FC<RowProps> = ({
  team,
  headings,
  weight,
  colour,
  highlight,
}) => (
  <TableRow highlight={highlight}>
    <TableCell
      className={`sticky left-0 ${highlight ? 'bg-orange-200' : 'bg-white'}`}
    >
      <TableText padding={2}>{team.name}</TableText>
    </TableCell>
    {headings.map(heading => (
      <Fragment key={heading}>
        <TableCell align="center">
          <TableText center weight={weight} colour={colour}>
            {/* @ts-expect-error indexing */}
            {getValue(heading, team.values[heading])}
          </TableText>
        </TableCell>
        {team.lastValues && (
          <TableCell align="center">
            <TableText
              colour={getChangeColor(
                heading,
                // @ts-expect-error trust
                team.values[heading],
                // @ts-expect-error trust
                team.lastValues[heading],
              )}
            >
              {displayChange(
                // @ts-expect-error trust
                getChange(
                  heading,
                  // @ts-expect-error trust
                  team.values[heading],
                  // @ts-expect-error trust
                  team.lastValues[heading],
                ),
              )}
            </TableText>
          </TableCell>
        )}
      </Fragment>
    ))}
  </TableRow>
)

const MarketShareTable: React.FC<Props> = ({ data, teamId }) => {
  // const data = marketShare
  const headings = Object.keys(data.teams[0].values).reduce<string[]>(
    (acc, key) => {
      if (data.teams[0].type === 'HOME_LOANS' && key === 'commission') {
        return acc
      }
      return [...acc, key]
    },
    [],
  )
  const denomination = useTranslatedText('general.denomination', '$ AUDm')

  return (
    <MarketShareTableTourWrapper>
      {({ columnInfo, tableRef }) => (
        <Table
          data-test="market-table"
          className="overflow-x-auto whitespace-nowrap"
          ref={tableRef}
        >
          <TableHeader>
            <TableHeaderCell
              className="sticky left-0 border border-r-gray-300 bg-gray-200"
              rowSpan={2}
              align="left"
            >
              <TableText weight="bold">{denomination}</TableText>
            </TableHeaderCell>
            {headings.map((heading, index) => (
              <TableHeaderCell
                key={heading}
                colSpan={2}
                ref={columnInfo[index]?.ref}
              >
                <TableText weight="bold">{formatHeader(heading)}</TableText>
              </TableHeaderCell>
            ))}
          </TableHeader>
          <TableHeader>
            {headings.map(heading => (
              <Fragment key={heading}>
                <TableHeaderCell>
                  <TableText weight="bold">
                    {getTypeUnitLabel(heading, denomination)}
                  </TableText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <TranslatedText
                    weight="bold"
                    value="general.change"
                    default="Change"
                    family="secondary"
                    size="xs"
                  />
                </TableHeaderCell>
              </Fragment>
            ))}
          </TableHeader>
          <TableSection>
            {data.teams.map((team, i) => (
              <Row
                key={i}
                highlight={team.team === teamId}
                team={team}
                headings={headings}
              />
            ))}
          </TableSection>
          <TableSection>
            <TableFooter>
              <TableCell className="sticky left-0 bg-gray-200">
                <TableText weight="bold">Total Market</TableText>
              </TableCell>
              {headings.map((heading, index) => (
                <Fragment key={heading}>
                  <TableCell
                    align="center"
                    id={index === 2 ? 'tour-market-average' : undefined}
                  >
                    <TableText center weight={'bold'}>
                      {getTotals(heading, data.teams).value}
                    </TableText>
                  </TableCell>
                  <TableCell align="center">
                    <TableText weight="bold">
                      {getTotals(heading, data.teams).change}
                    </TableText>
                  </TableCell>
                </Fragment>
              ))}
            </TableFooter>
          </TableSection>
        </Table>
      )}
    </MarketShareTableTourWrapper>
  )
}

export default MarketShareTable
