import { TreasuryResponse } from '../../../../../types/gameApi/treasury'

export const data: TreasuryResponse = {
  wsfMethod: 1,
  capitalMethod: 1,
  capitalBands: [
    {
      bandName: 'AAA',
      bandValue: 1250,
    },
    {
      bandName: 'AA',
      bandValue: 1150,
    },
    {
      bandName: 'A',
      bandValue: 1050,
    },
    {
      bandName: 'BBB',
      bandValue: 950,
    },
    {
      bandName: 'BB',
      bandValue: 850,
    },
    {
      bandName: 'B',
      bandValue: 800,
    },
  ],
  capitalBuffer: 10,
  dividendsReinvestedHelpTitle: 'What Is A Dividend Reinvestment Plan?',
  dividendsReinvestedHelpBody:
    '\n\nA dividend reinvestment plan (DRP) is a program where a company offers its shareholders the option to automatically use their cash dividends to purchase additional shares instead of receiving the dividend in cash. This benefits both the investor and the company, as the investor receives more shares and the company can raise equity without incurring the cost of a public share offering.\n\n',
  additionalShareIssuesHelpTitle: 'What Are Share Issues & Buybacks?',
  additionalShareIssuesHelpBody:
    "\n\nShares issued refers to a company issuing shares to raise capital, while share buyback means a company repurchasing some of its own shares, resulting in a reduction of the total number of outstanding shares. Both methods impact a bank's equity and are important tools for companies to manage their capital position.\n\n",
  creditCardsEnabled: true,
  institutionalBankingEnabled: false,
  offshoreBankEnabled: false,
  wealthManagementEnabled: false,
  denomination: 'AUD $M',
  team: 1,
  rates: {
    cashYield: 275,
    assetYield: 535,
  },
  capital: {
    cet1Ratio: 1053,
    opRiskRatio: 2000,
  },
  wsfSliderMin: -19900,
  fundingProfile: {
    wsfYr1: 16840,
    wsfYr2: 19514,
    wsfYr3: 19857,
    wsfYr4: 23200,
    wsfYr5: 0,
    assetsYr1: 37594,
    assetsYr2: 37594,
    assetsYr3: 37594,
    assetsYr4: 37594,
    assetsYr5: 114265,
    depositsYr1: 21392,
    depositsYr2: 21392,
    depositsYr3: 21392,
    depositsYr4: 21392,
    depositsYr5: 81289,
  },
  openingBalances: {
    cash: 18464,
    npat: 2717,
    fixedCT: 10000,
    wealthCT: 0,
    homeLoans: 182855,
    offshoreCT: 0,
    creditCards: 12127,
    fixedAssets: 11684,
    homeLoansCT: 3500,
    investments: 0,
    offshoreBank: 0,
    wealthAssets: 0,
    businessLoans: 57974,
    creditCardsCT: 9850,
    investmentsCT: -1,
    wsfRepayments: -19900,
    savingAccounts: 125142,
    businessLoansCT: 8000,
    wholesaleFunding: 99311,
    shareholdersFunds: 16937,
    transactionAccounts: 41714,
    institutionalBanking: 0,
    institutionalBankingCT: 0,
    allOtherAssets: 264640,
  },
  capitalSliderMax: 1203,
  capitalSliderMin: 903,
  marginCalculation: {
    wsfRateYr1: 39,
    wsfRateYr2: 63,
    wsfRateYr3: 87,
    wsfRateYr4: 110,
    wsfRateYr5: 134,
    existingFundingCosts: 3497,
    creditCardInterestRate: 1235,
  },
  allOtherAssetsPercentage: 1000,
  depositGrowthChangePercentage: 500,
  wsfChange: 13100,
  capitalRatioChange: 1065,
  allOtherAssetsPercentageOriginal: 0,
  depositGrowthChangePercentageOriginal: 500,
  wsfChangeOriginal: -19900,
  capitalRatioChangeOriginal: 1053,
  version: 10,
  wsf1: 0,
  wsf2: 3300,
  wsf3: 3300,
  wsf4: 3300,
  wsf5: 23100,
  desiredCreditRating: 'A',
  desiredCreditRatingOriginal: 'A',
  homeLoansRisk: 3500,
  businessLoansRisk: 8000,
  creditCardsRisk: 9850,
  institutionalBankingRisk: 0,
  offshoreBankRisk: 0,
  wealthRisk: 0,
  investmentsRisk: -1,
  fixedAssetsRisk: 10000,
  homeLoansRiskOriginal: 3500,
  businessLoansRiskOriginal: 8000,
  creditCardsRiskOriginal: 9850,
  institutionalBankingRiskOriginal: 0,
  offshoreBankRiskOriginal: 0,
  wealthRiskOriginal: 0,
  investmentsRiskOriginal: -1,
  fixedAssetsRiskOriginal: 10000,
  homeLoansGrowth: 1200,
  businessLoansGrowth: 800,
  creditCardsGrowth: -600,
  institutionalBankingGrowth: 0,
  offshoreBankGrowth: 0,
  wealthGrowth: 0,
  investmentsGrowth: 0,
  fixedAssetsGrowth: 0,
  dividendsReinvested: 0,
  groupNpat: 2717,
  additionalSharesIssued: 0,
  opexRisk: 2000,
  opexRiskOriginal: 2000,
  updatedBy: 'a3c1f6e9-3a8e-487b-8b85-641cf814381a',
  businessDecisions: [
    {
      business_id: 1,
      business_type: 'HOME_LOANS',
      price1_now: 504,
      price2_now: 18,
      volume_now: 204753,
      growthRate: 0,
      growthRateFrom: 0,
      growthRateTo: 0,
      volume_now_original: 0,
      volume_prev: 0,
    },
    {
      business_id: 2,
      business_type: 'BUSINESS_LOANS',
      price1_now: 667,
      price2_now: 1,
      volume_now: 62432,
      growthRate: 0,
      growthRateFrom: 0,
      growthRateTo: 0,
      volume_now_original: 0,
      volume_prev: 0,
    },
    {
      business_id: 3,
      business_type: 'DEPOSITS',
      price1_now: 299,
      price2_now: 815,
      volume_now: 131399,
      growthRate: 0,
      growthRateFrom: 0,
      growthRateTo: 0,
      volume_now_original: 0,
      volume_prev: 0,
    },
    {
      business_id: 4,
      business_type: 'CREDIT_CARDS',
      price1_now: 275,
      price2_now: 150,
      volume_now: 1579364,
      growthRate: 0,
      growthRateFrom: 0,
      growthRateTo: 0,
      volume_now_original: 0,
      volume_prev: 0,
    },
  ],
  nim: 276,
  costOfFunds: 264,
}
