import React, { PropsWithChildren } from 'react'
import { ChartProps } from '../SummaryMetricMapper'
import StackedBarChart from './StackedBarChart'
import { percentage } from '../../../../../lib/formatters'

// const ratingToColour: Record<CreditRating, string> = {
//   AAA: '#67D0B6',
//   AA: '#92DEA3',
//   A: '#C3E9A6',
//   BBB: '#FBF19B',
//   BB: '#FFE089',
//   B: '#FFBF85',
// }

const MiddleLabel = (type: string): React.FC => {
  const middleLabel = (props: any) => {
    const {
      x,
      y,
      width,
      height,
      value: { value, selectedTeamValue },
    } = props
    const valToUse = value ?? selectedTeamValue
    if (valToUse == null) {
      return null
    }
    if (type === 'value' && !value) {
      return null
    }
    if (type === 'selectedTeamValue' && !selectedTeamValue) {
      return null
    }

    return (
      <g>
        <rect
          x={x + width / 2 - 25}
          y={y + height / 2 - 15}
          width={50}
          height={20}
          fill="#fff"
        ></rect>
        <text
          x={x + width / 2}
          y={y + height / 2}
          fill="#000"
          textAnchor="middle"
          fontWeight="bold"
          fontSize={12}
          fontFamily="Open Sans"
        >
          {percentage(valToUse, 2)}
        </text>
      </g>
    )
  }
  return middleLabel
}

const TopLabel = (type: string): React.FC => {
  const topLabel = (props: any) => {
    const {
      x,
      y,
      width,
      height,
      value: { value, selectedTeamValue, raw },
    } = props
    const valToUse = value ?? selectedTeamValue
    if (valToUse == null) {
      return null
    }
    if (type === 'value' && !value) {
      return null
    }
    if (type === 'selectedTeamValue' && !selectedTeamValue) {
      return null
    }

    return (
      <g>
        <text
          x={x + width / 2}
          y={
            (valToUse < 0 ? y + height : y) +
            (valToUse < 0 ? -10 : valToUse < 200 ? -15 : -5)
          }
          fill={'#000'}
          textAnchor="middle"
          fontWeight="bold"
          fontSize={12}
          fontFamily="Open Sans"
        >
          {raw.rating}
        </text>
      </g>
    )
  }
  return topLabel
}

const CreditRatingChart: React.FC<PropsWithChildren<ChartProps>> = ({
  children,
  ...rest
}) => {
  return (
    <StackedBarChart
      header={children}
      legend={[]}
      formatter={val => percentage(val, 2)}
      ignoreDiff
      middleLabel={MiddleLabel}
      topLabel={TopLabel}
      chartTitle="CET1 Ratio & Credit Rating"
      {...rest}
    />
  )
}

export default CreditRatingChart
