import { useMemo } from 'react'
import { MAX_MOBILE_WIDTH } from '../constants/screens'
import { useWindowSize } from './useWindowSize'

export const useIsMobile = (): boolean => {
  const windowSize = useWindowSize()
  return useMemo(() => {
    const isWidthLessThanMaxMobileWidth =
      windowSize.width != null && windowSize.width <= MAX_MOBILE_WIDTH
    const isHeightGreaterThanWidth =
      windowSize.height != null &&
      windowSize.width != null &&
      windowSize.height > windowSize.width
    return isWidthLessThanMaxMobileWidth && isHeightGreaterThanWidth
  }, [windowSize])
}
