import React, { useCallback } from 'react'
import InAppHelpContent from '../../organisms/InAppHelp/InAppHelpContent'
import Text from '../../atoms/Text/Text'
import GuidedTour, {
  GuidedTourStep,
} from '../../organisms/InAppHelp/GuidedTour'
import { useAppDispatch, useAppSelector } from '../../../store'
import { finishTour } from '../../../redux/guidedTours/guidedToursSlice'
import { DealroomTabs } from '../../../redux/dealroom/dealroomSlice'
import { DealRoomMathConfig } from './configs/utils'

interface Props {
  setTab: (tab: DealroomTabs) => void
  config: DealRoomMathConfig
  hasTabs: boolean
}

const DealRoomTour: React.FC<Props> = ({ setTab, config, hasTabs }) => {
  const { presetX = [], presetY = [], titleX = '', titleY = '' } = config || {}
  const sixthPresetX = presetX[5]
  const sixthPresetY = presetY[5]
  const dispatch = useAppDispatch()
  const currentTour = useAppSelector(state => state.guidedTours.currentTour)
  const ValuationTabChange = useCallback(() => {
    setTab('valuation')
  }, [setTab])
  const PricingMatrixTabChange = useCallback(() => {
    setTab('pricing-matrix')
  }, [setTab])

  const commonSteps: Array<Partial<GuidedTourStep>> = [
    {
      target: '#tour-deal-info',
      content: (
        <InAppHelpContent heading={'Deal Description'}>
          <Text left>
            {
              'Read the deal description thoroughly. Ensure you understand the risks and consider if the deal aligns with your strategy before submitting a bid. '
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-deal-bid',
      content: (
        <InAppHelpContent heading="Bidding?">
          <Text left>
            {'To submit a bid, enable bidding using this toggle.'}
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-current-shareholder-returns',
      content: (
        <InAppHelpContent heading="Current Shareholder Returns">
          <Text left>
            {
              "This shows your Bank's current Shareholder Return. To create value, ensure the return on this deal exceeds the current Shareholder Return."
            }
          </Text>
        </InAppHelpContent>
      ),
    },
  ]

  const tabSteps: Array<Partial<GuidedTourStep>> = [
    {
      target: '#tour-deal-room-overview',
      content: (
        <InAppHelpContent heading="Deal Overview">
          <Text left>
            {
              'This screen provides background information on the deal and allows you to submit your bid.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-deal-info',
      content: (
        <InAppHelpContent heading={'Deal Description'}>
          <Text left>
            {
              'Read the deal description thoroughly. Ensure you understand the risks and consider if the deal aligns with your strategy before submitting a bid. '
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-deal-bid',
      content: (
        <InAppHelpContent heading="Bidding?">
          <Text left>
            {'To submit a bid, enable bidding using this toggle.'}
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-current-shareholder-returns',
      content: (
        <InAppHelpContent heading="Current Shareholder Returns">
          <Text left>
            {
              "This shows your Bank's current Shareholder Return. To create value, ensure the return on this deal exceeds the current Shareholder Return."
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-valuation',
      content: (
        <InAppHelpContent heading="Valuation">
          <Text left>
            {
              'This screen performs a valuation of the target company based on the provided assumptions. Note that not all assumptions will perfectly predict the future.'
            }
          </Text>
        </InAppHelpContent>
      ),
      onClick: ValuationTabChange,
    },
    {
      target: '#tour-assumptions',
      content: (
        <InAppHelpContent heading={'Assumptions'}>
          <Text left>
            {'You can change these assumptions only in the CEO interface.'}
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-key-assumption-1',
      content: (
        <InAppHelpContent heading={'Key Assumption 1'}>
          <Text left>
            {
              'Pay special attention to this assumption as it significantly impacts the valuation.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-key-assumption-2',
      content: (
        <InAppHelpContent heading={'Key Assumption 2'}>
          <Text left>
            {
              'Pay special attention to this assumption as it significantly impacts the valuation.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-reset',
      content: (
        <InAppHelpContent heading={'Reset'}>
          <Text left>
            {'Use this button to reset all assumptions if needed.'}
          </Text>
        </InAppHelpContent>
      ),
      onClick: PricingMatrixTabChange,
    },
    {
      target: '#tour-matrix-key-assumption',
      content: (
        <InAppHelpContent heading={'Key Assumption #1'}>
          <Text left>
            {
              'Find a range of possible outcomes for the first key valuation assumption here.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-matrix-key-assumption-2',
      content: (
        <InAppHelpContent heading={'Key Assumption #2'}>
          <Text left>
            {
              'Find a range of possible outcomes for the second key valuation assumption here.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-select-bid',
      content: (
        <InAppHelpContent heading={'Select A Bid Price'}>
          <Text left>
            {`You can select a bid price from the table based on the key assumptions. The highlighted bid price assumes a ${titleX} of ${sixthPresetX}%, and a ${titleY} of ${sixthPresetY}%.`}
          </Text>
        </InAppHelpContent>
      ),
    },
  ]

  const steps: Array<Partial<GuidedTourStep>> = hasTabs ? tabSteps : commonSteps
  const handleCloseTour = useCallback(() => {
    dispatch(finishTour())
  }, [dispatch])

  if (currentTour !== 'deal-room') {
    return null
  }

  return <GuidedTour disableScrolling steps={steps} onClose={handleCloseTour} />
}

export default DealRoomTour
