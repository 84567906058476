import React, { useState, useCallback, useEffect } from 'react'
import Modal from '../../atoms/Modal/Modal'
import TextArea from '../../atoms/Input/TextArea'
import Text from '../../atoms/Text/Text'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'

interface ReportIssueModalProps {
  show: boolean
  onClose: () => void
  onSubmit: (description: string) => Promise<void>
}

const ReportIssueModal: React.FC<ReportIssueModalProps> = ({
  show,
  onClose,
  onSubmit,
}) => {
  const [debugDescription, setDebugDescription] = useState<string>('')
  const [isSendingDebug, setIsSendingDebug] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)

  useEffect(() => {
    if (show) {
      setDebugDescription('')
      setShowSuccess(false)
      setIsSendingDebug(false)
    }
  }, [show])

  const handleDescriptionChange = useCallback((value: string) => {
    setDebugDescription(value)
  }, [])

  const handleClose = useCallback((): void => {
    onClose()
  }, [onClose])

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (!debugDescription.trim()) return
    setIsSendingDebug(true)
    try {
      await onSubmit(debugDescription)
      setShowSuccess(true)
    } catch (error) {
      console.error('Failed to send debug info:', error)
    } finally {
      setIsSendingDebug(false)
    }
  }, [debugDescription, onSubmit])

  const modalContent = showSuccess ? (
    <HorizontalGroup
      verticalCenter
      className="w-full justify-center rounded-md bg-green-100 p-4"
    >
      <Text colour="success">Issue reported successfully!</Text>
    </HorizontalGroup>
  ) : (
    <>
      <Text>
        {
          "Please describe the issue you're experiencing in detail. Include what you were trying to do when the problem occurred and any error messages you saw."
        }
      </Text>
      <TextArea
        height="48"
        name="debugDescription"
        value={debugDescription}
        onValueChange={handleDescriptionChange}
        placeholder="Describe the issue..."
        disabled={isSendingDebug}
      />
    </>
  )

  return (
    <Modal
      title="Report Technical Issue"
      cancelButtonText="Close"
      okButtonText={
        isSendingDebug ? 'Submitting...' : showSuccess ? 'Done' : 'Submit'
      }
      show={show}
      onOk={showSuccess ? handleClose : handleSubmit}
      onClose={handleClose}
    >
      <VerticalGroup gap={4} full className="pt-4">
        {modalContent}
      </VerticalGroup>
    </Modal>
  )
}

export default ReportIssueModal
