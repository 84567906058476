import React, { FC } from 'react'
import ResponsiveText, {
  Props as TextProps,
} from '../../../atoms/Text/ResponsiveText'
import { useAppSelector } from '../../../../store'
import { selectBranding } from '../selectors'

interface Props extends TextProps {
  colourField?: keyof ReturnType<typeof selectBranding>
}

const BrandedText: FC<Props> = props => {
  const { colourField, ...rest } = props
  const branding = useAppSelector(selectBranding)
  const style: React.CSSProperties = props.style ?? {}
  const fieldInBranding = branding[colourField ?? 'headingTextColour']
  if (fieldInBranding) {
    style.color = fieldInBranding
  }
  return <ResponsiveText {...rest} style={style} />
}

export default BrandedText
