import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store'
import RadioInput from '../../../atoms/RadioButtons/RadioInput'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { setEnabledFinancialyMetrics } from '../../../../redux/demo/demoSlice'
import { FinancialSummaryItemType } from '../../../../types/gameApi/financials'

interface Props {}

const SUMMARY_TYPES: Array<{ label: string; value: FinancialSummaryItemType }> =
  [
    { label: 'NPAT_GROWTH', value: 'NPAT_GROWTH' },
    { label: 'NIM', value: 'NIM' },
    { label: 'COF', value: 'COF' },
    { label: 'CTIR', value: 'CTIR' },
    { label: 'LIE', value: 'LIE' },
    { label: 'CET1', value: 'CET1' },
    { label: 'TSR', value: 'TSR' },
    { label: 'ROE', value: 'ROE' },
    { label: 'Complaints', value: 'Complaints' },
    { label: 'EmployeeEngagement', value: 'EmployeeEngagement' },
    { label: 'RemediationAndFines', value: 'RemediationAndFines' },
  ]

const DemoSettingsFinancialMetrics: React.FC<Props> = () => {
  const enabledFinancialMetrics = useAppSelector(
    state => state.demo.enabledFinancialMetrics,
  )
  const dispatch = useAppDispatch()

  const updateSummaryTypes = useCallback(
    (metric: FinancialSummaryItemType, enabled: boolean) => {
      const newEnabledFinancialMetrics = enabled
        ? [...enabledFinancialMetrics, metric]
        : [...enabledFinancialMetrics.filter(m => m !== metric)]
      dispatch(setEnabledFinancialyMetrics(newEnabledFinancialMetrics))
    },
    [dispatch, enabledFinancialMetrics],
  )

  return (
    <VerticalGroup gap={2}>
      {SUMMARY_TYPES.map(t => (
        <HorizontalGroup verticalCenter gap={2} key={t.value}>
          <RadioInput
            key={t.value}
            id={t.value}
            name={t.label}
            value={t.value}
            checked={enabledFinancialMetrics.includes(t.value)}
            onClick={() => {
              updateSummaryTypes(
                t.value,
                !enabledFinancialMetrics.includes(t.value),
              )
            }}
          />
          <label htmlFor={`${t.value}`}>{t.label}</label>
        </HorizontalGroup>
      ))}
    </VerticalGroup>
  )
}

export default DemoSettingsFinancialMetrics
