import { ExcoResponse } from '../../../../../types/gameApi/exco'

export const data: ExcoResponse = {
  id: 5,
  name: 'A Rock And A Hard Place',
  description:
    '<p>Our physical environment is changing along with global environmental policies and public sentiment. There are targets in place to significantly reduce carbon emissions by 2050 and businesses are expected to work towards them. Your bank is a member of the Net Zero Banking Alliance (NZBA) and has publicly committed to achieving the aims of the agreement.</p><p></p><p>A large coal mining company found a lucrative coal deposit and needs financial assistance for equipment and infrastructure to start mining. The miner has approached several banks and is seeking $25bn in loans. The mine is situated close to a small town that has been facing financial difficulties since the closure of a nearby gold mine five years ago. Your bank has existing loans to numerous local businesses and residents in the town and is worried about potential losses that could arise from lending to this community (expected to be ~$100m).</p><p></p><p>The mining loan is to be syndicated equally amongst all banks that chose to lend to the miner. The miner is offering an attractive interest rate of 9.9%. The media and lobbyists are currently unaware of the new mining project.</p>',
  options: [
    {
      id: 1,
      title: 'Pass',
      description:
        '\n\nYour Bank will not participate in the very profitable opportunity and the mining project may not proceed without adequate support. Without the mining project, many of the local businesses and residents may default on their loans.\n\n',
    },
    {
      id: 2,
      title: 'Do The Deal',
      description:
        '\n\nParticipate in the syndicate and lend a portion of the loan facility. The new mining operations will likely turn around the declining financial trend of the local town in the short-medium term and delay/reduce the losses you were expecting from the town. However, this action is unhelpful in achieving your NZBA commitments.\n\n',
    },
    {
      id: 3,
      title: 'Green Loan',
      description:
        '\n\nDon’t participate in the syndicate but offer a separate “green” loan instead. This loan will be at a low rate of 3% however the miner will need to achieve certain carbon initiatives over the next 10 years in order to receive this ongoing interest rate. Lending at 3% will cause you to lose money but perhaps your expected lending losses to the town’s people will be lower if the mine commences operations. \n\n',
    },
  ],
  decision: 1,
  imageUrl:
    'https://test-images.banksim.co/exco/exco-58a4e1090864b5d45afe8650cfc0a487.jpg',
}
