import React, { useEffect, useRef, useState } from 'react'
import { Assumption, Explanation } from '../configs/utils'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import TableText from '../../../atoms/Table/TableText'
import NumericInput from '../../../atoms/Input/NumericInput'
import Button from '../../../atoms/Button/Button'
import Table from '../../../atoms/Table/Table'
import TableHeader from '../../../atoms/Table/TableHeader'
import TableCell from '../../../atoms/Table/TableCell'
import TableRow from '../../../atoms/Table/TableRow'
import ValuationCard from './DealRoomValuationCard'
import PrimaryText from '../../../atoms/Text/PrimaryText'
import { Icon } from '../../../atoms/Icon/Icon'
import Page from '../../../atoms/Page/Page'
import TableHeaderCell from '../../../atoms/Table/TableHeaderCell'
import classNames from 'classnames'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import ErrorModal from '../../../atoms/Modal/ErrorModal'

interface Props {
  explanations: Explanation[]
  formulas: React.ReactNode[]
  assumptions: ReadonlyArray<Assumption>
  tips: string[]
  latestFinancials: Array<{
    key: string
    value: string
  }>
  valuation: React.ReactNode[]
  onAssumptionUpdate: (index: number, value: number) => void
  resetAssumptions: () => void
  canBid: boolean
}

const DealRoomValuationCalculation: React.FC<Props> = ({
  explanations,
  formulas,
  assumptions,
  onAssumptionUpdate,
  canBid,
  resetAssumptions,
}) => {
  const [error, setError] = useState<string | null>()
  const lastFormula = formulas[formulas.length - 1]

  const lastColumnRef = useRef<HTMLTableCellElement>(null)
  const tableRef = useRef<HTMLTableElement>(null)
  const [lastColumnDimensions, setLastColumnDimensions] = useState({
    width: 0,
    height: 0,
    top: 0,
  })

  useEffect(() => {
    const updateDimensions = () => {
      if (lastColumnRef.current && tableRef.current) {
        const lastColumnRect = lastColumnRef.current.getBoundingClientRect()
        const tableRect = tableRef.current.getBoundingClientRect()
        const lastRow = tableRef.current.querySelector('tr:last-child')
        const lastRowHeight = lastRow
          ? lastRow.getBoundingClientRect().height
          : 0

        setLastColumnDimensions({
          width: lastColumnRect.width,
          height:
            tableRect.height -
            lastRowHeight -
            lastColumnRect.top +
            tableRect.top,
          top: lastColumnRect.top - tableRect.top,
        })
      }
    }

    updateDimensions()
    window.addEventListener('resize', updateDimensions)

    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  return (
    <VerticalGroup center fullWidth>
      {error && (
        <ErrorModal
          showModal={!!error}
          heading="Invalid Input"
          message={error}
          onClose={() => setError(null)}
        />
      )}
      <Page full className="hidden tablet:block">
        <div style={{ position: 'relative' }}>
          <Table style={{ position: 'relative' }} ref={tableRef} noDivideY>
            <TableHeader noDivideX>
              <TableHeaderCell align="left">Evaluation Steps</TableHeaderCell>
              <TableHeaderCell align="left">Calculation</TableHeaderCell>
              <TableHeaderCell
                className="w-56"
                align="left"
                ref={lastColumnRef}
              >
                Assumption
              </TableHeaderCell>
            </TableHeader>
            {explanations.map((explanation, index) => {
              const assumption = assumptions[index + 1]

              return (
                <TableRow className="divide-x-0" key={index}>
                  <TableCell className="align-top">
                    {/* <VerticalGroup>{explanations[index]}</VerticalGroup> */}
                    <VerticalGroup>
                      <SecondaryText colour="gray" size="xs" notResponsive>
                        Step {index + 1}
                      </SecondaryText>
                      <SecondaryText size="sm" weight="bold" notResponsive>
                        {explanation.text}
                      </SecondaryText>
                    </VerticalGroup>
                  </TableCell>
                  <TableCell className="align-top">
                    <VerticalGroup>
                      {/* because the formulas sometimes have a <sup> element we can't use our normal text component */}
                      <span className="font-secondary text-xs text-gray-400">
                        {explanation.subtext}&nbsp;
                      </span>
                      <div className="text-sm">{formulas[index]}</div>
                    </VerticalGroup>
                  </TableCell>
                  <TableCell className="align-top">
                    {assumption != null && !isNaN(assumption.value) ? (
                      <HorizontalGroup
                        fullWidth
                        verticalCenter
                        key={`row-${index}`}
                      >
                        <VerticalGroup gap={1}>
                          <HorizontalGroup gap={1}>
                            <SecondaryText
                              colour="gray"
                              size="xs"
                              notResponsive
                            >
                              {assumption.label}
                            </SecondaryText>
                            {assumption.range != null && (
                              <SecondaryText
                                colour="error"
                                size="xs"
                                notResponsive
                              >
                                ({assumption.range[0]} - {assumption.range[1]}
                                {assumption.denomination})
                              </SecondaryText>
                            )}
                          </HorizontalGroup>
                          <HorizontalGroup gap={2} verticalCenter>
                            <HorizontalGroup className="h-8">
                              <NumericInput
                                id={
                                  assumption.isKeyAssumption1
                                    ? 'tour-key-assumption-1'
                                    : assumption.isKeyAssumption2
                                    ? 'tour-key-assumption-2'
                                    : ''
                                }
                                type={assumption.numberFormat || 'integer'}
                                disabled={!canBid}
                                key={`input-${index}`}
                                value={
                                  assumption.numberFormat === 'decimal'
                                    ? assumption.value * 100
                                    : assumption.value
                                }
                                onChange={value => {
                                  if (value > 0) {
                                    onAssumptionUpdate(
                                      index + 1,
                                      assumption.numberFormat === 'decimal'
                                        ? value / 100
                                        : value,
                                    )
                                  }
                                }}
                                onError={setError}
                                validateInput={val => {
                                  if (val <= 0) {
                                    return `${assumption.label.replace(
                                      ':',
                                      '',
                                    )} must be greater than 0`
                                  }
                                }}
                              />
                            </HorizontalGroup>

                            <TableText size="xs">
                              {assumption.denomination}
                            </TableText>
                          </HorizontalGroup>
                        </VerticalGroup>
                      </HorizontalGroup>
                    ) : null}
                  </TableCell>
                </TableRow>
              )
            })}
            <TableRow className="last-row hidden divide-x-0 desktop:table-row">
              <TableCell align="left" className="bg-gray-200">
                <VerticalGroup verticalCenter>
                  <TableText weight="bold">Valuation</TableText>
                </VerticalGroup>
              </TableCell>

              <TableCell align="left" className="bg-gray-200">
                <div id="tour-current-valuation">{lastFormula}</div>
              </TableCell>
              <TableCell align="left" className="bg-gray-200">
                {canBid && (
                  <VerticalGroup
                    fullWidth
                    verticalCenter
                    className="items-start"
                  >
                    <HorizontalGroup gap={1} fullWidth>
                      <TableText>Use Default Assumptions</TableText>
                    </HorizontalGroup>

                    <HorizontalGroup
                      id="tour-reset"
                      reverseRow
                      className="valuation-reset"
                    >
                      <Button
                        size="large"
                        rounded="full"
                        buttonType="secondary"
                        onClick={resetAssumptions}
                      >
                        <PrimaryText>Reset</PrimaryText>
                        <Icon colour="black" type="reset"></Icon>
                      </Button>
                    </HorizontalGroup>
                  </VerticalGroup>
                )}
              </TableCell>
            </TableRow>
          </Table>
          <div
            style={{
              position: 'absolute',
              top: `${lastColumnDimensions.top}px`,
              right: 0,
              width: `${lastColumnDimensions.width}px`,
              height: `${lastColumnDimensions.height}px`,
              pointerEvents: 'none',
            }}
            id="tour-assumptions"
          />
        </div>
      </Page>
      <HorizontalGroup
        verticalCenter
        between
        className="fixed bottom-0 h-20 w-full bg-white px-4 desktop:hidden"
      >
        <HorizontalGroup gap={2}>
          <TableText>Valuation:</TableText>
          <TableText>{lastFormula}</TableText>
        </HorizontalGroup>
        {canBid && (
          <HorizontalGroup reverseRow className="valuation-reset">
            <Button
              size="small"
              rounded="full"
              buttonType="secondary"
              onClick={resetAssumptions}
            >
              <PrimaryText>Reset</PrimaryText>
              <Icon colour="black" type="reset"></Icon>
            </Button>
          </HorizontalGroup>
        )}
      </HorizontalGroup>

      <VerticalGroup gap={4} center className="w-full tablet:hidden">
        {explanations.map((explanation, index) => {
          const assumption = assumptions[index + 1]
          return (
            <ValuationCard
              key={index}
              explanation={explanation}
              formula={formulas[index]}
              totalCards={formulas.length}
              assumption={
                assumption != null && !isNaN(assumption.value)
                  ? assumption
                  : null
              }
              index={index}
              canBid={canBid}
              onAssumptionUpdate={onAssumptionUpdate}
              resetAssumptions={resetAssumptions}
            />
          )
        })}
        <VerticalGroup fullWidth>
          <div
            className={classNames(
              'fixed bottom-0 z-10 h-20 w-full rounded-none border-t bg-white px-4',
            )}
          >
            <HorizontalGroup verticalCenter fullHeight fullWidth between>
              <HorizontalGroup verticalCenter between className="bg-white">
                <HorizontalGroup gap={1}>
                  <PrimaryText>Valuation:</PrimaryText>
                  <PrimaryText>{lastFormula}</PrimaryText>
                </HorizontalGroup>
              </HorizontalGroup>
              {canBid && (
                <HorizontalGroup reverseRow className="valuation-reset">
                  <Button
                    size="small"
                    rounded="full"
                    buttonType="secondary"
                    onClick={resetAssumptions}
                  >
                    <PrimaryText>Reset</PrimaryText>
                    <Icon colour="black" type="reset"></Icon>
                  </Button>
                </HorizontalGroup>
              )}
            </HorizontalGroup>
          </div>
        </VerticalGroup>
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default DealRoomValuationCalculation
