import React, { useCallback } from 'react'
import InAppHelpContent from '../../organisms/InAppHelp/InAppHelpContent'
import Text from '../../atoms/Text/Text'
import GuidedTour, {
  GuidedTourStep,
} from '../../organisms/InAppHelp/GuidedTour'
import { useAppDispatch, useAppSelector } from '../../../store'
import { finishTour } from '../../../redux/guidedTours/guidedToursSlice'
import { titleCase } from '../../../lib/text'

const BreakingNewsTour: React.FC = () => {
  const dispatch = useAppDispatch()
  const currentTour = useAppSelector(state => state.guidedTours.currentTour)
  const { news } = useAppSelector(state => ({
    news: state.news.news,
  }))

  const steps: Array<Partial<GuidedTourStep>> = [
    {
      target: '#breaking-news',
      content: (
        <InAppHelpContent heading="Welcome Message">
          <Text left>
            {
              'Here, you can access essential updates on economic changes and new regulations affecting your business. Use this information to keep informed and adjust your strategies to maintain a competitive edge.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },

    {
      target: '#economy',
      content: (
        <InAppHelpContent
          heading={titleCase(news?.newsConfig.mainSectionHeading || '')}
        >
          <Text left>
            {
              "This shows what we expect to happen in the economy over the next year. It covers important economic indicators, trends, and factors that might affect your bank's performance. Use this information to understand the economy better and make informed decisions."
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#market-outlook',
      content: (
        <InAppHelpContent heading="Market Outlook ">
          <Text left>
            {
              'This section shows future growth predictions for your banking products and possible losses in the next 12 months. Use these insights to make informed business decisions and consider how this will impact treasury funding requirements'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#wholesale-funding',
      content: (
        <InAppHelpContent heading="Wholesale Funding">
          <Text left>
            {
              "This table shows the interest rate cost of the money we borrow from the market to lend to customers. The amount we pay for this money depends on our bank's credit rating, how long the deal lasts, and the current economic conditions."
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#wholesale-funding-reference-rate',
      content: (
        <InAppHelpContent heading="Wholesale Funding Reference Rate">
          <Text left>
            {
              'This section displays the current market reference rate. This rate is important as it helps determine the interest rate banks charge each other. Any changes in this rate can impact your bank’s cost of borrowing. Monitor this rate to better manage your borrowing costs and adjust your pricing and funding strategies.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#scenarios',
      content: (
        <InAppHelpContent
          heading={titleCase(news?.newsConfig.marketViewSectionHeading || '')}
        >
          <Text left>
            {
              'In the market, people have different opinions: some are optimistic, others are pessimistic. They can’t all be right at the same time. This section shows both viewpoints to help you think about future market trends. Use this information to question your assumptions, test your strategies, and prepare for various possible market outcomes.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
  ]

  const handleCloseTour = useCallback(() => {
    dispatch(finishTour())
  }, [dispatch])

  if (currentTour !== 'news') {
    return null
  }

  return <GuidedTour steps={steps} onClose={handleCloseTour} />
}

export default BreakingNewsTour
