import React from 'react'
import { TreasuryResponse } from '../../../../types/gameApi/treasury'
import { DecisionMap } from '../TreasuryContainer'
import { useGetData } from './data'
import TreasuryCapitalManagementShareCapital from './TreasuryCapitalManagementShareCapital'
import TreasuryCapitalManagementForcastCapital from './TreasuryCapitalManagementForcastCapital'

import DividensReinvested from './DividensReinvested'
import TargetCapitalRatio from './TargetCapitalRatio'
import CapitalRatioEstimated from './CapitalRatioEstimated'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'

interface Props {
  data: TreasuryResponse
  decisions: DecisionMap
  enableChanges: boolean
  onChangeValue: (val: Partial<DecisionMap>) => void
}

const TreasuryCapitalManagement: React.FC<Props> = props => {
  const capitalManagementData = useGetData({
    data: props.data,
    decisions: props.decisions,
  })

  return (
    <VerticalGroup gap={3} fullWidth>
      <VerticalGroup
        gap={3}
        className="tablet:flex tablet:flex-row tablet:gap-3"
      >
        <div className="tablet:w-1/2">
          <DividensReinvested
            {...props}
            capitalManagmentData={capitalManagementData}
          />
        </div>
        <div className="tablet:w-1/2">
          <TargetCapitalRatio
            capitalManagmentData={capitalManagementData}
            {...props}
          />
        </div>
      </VerticalGroup>
      <VerticalGroup
        gap={3}
        className="w-full desktop:flex desktop:flex-row desktop:gap-3"
      >
        <div className="desktop:w-1/2">
          <TreasuryCapitalManagementShareCapital
            {...props}
            capitalManagmentData={capitalManagementData}
          />
        </div>
        <div className="desktop:w-1/2">
          <TreasuryCapitalManagementForcastCapital
            capitalManagmentData={capitalManagementData}
            {...props}
          />
        </div>
      </VerticalGroup>
      <CapitalRatioEstimated
        capitalManagmentData={capitalManagementData}
        {...props}
      />
    </VerticalGroup>
  )
}

export default TreasuryCapitalManagement
