import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { reset } from '../game/gameSlice'
import { logout } from '../auth/authSlice'

const LOCAL_STORAGE_PREFIX = 'inAppHelp:'

interface InAppHelpState {
  currentInAppHelp: string | null
  dismissedInAppHelp: Record<string, boolean>
}

const dismissedInAppHelpFromLocalstorage = Object.keys(localStorage).reduce<
  Record<string, boolean>
>((acc, key) => {
  if (key.startsWith(LOCAL_STORAGE_PREFIX)) {
    acc[key.replace(LOCAL_STORAGE_PREFIX, '')] = true
  }
  return acc
}, {})

const initialState: InAppHelpState = {
  currentInAppHelp: null,
  dismissedInAppHelp: dismissedInAppHelpFromLocalstorage,
}

const inAppHelpSlice = createSlice({
  name: 'inAppHelp',
  initialState,
  reducers: {
    startInAppHelp: (state, action: PayloadAction<string>) => {
      state.currentInAppHelp = action.payload
    },
    finishInAppHelp: (state, action: PayloadAction<string>) => {
      state.dismissedInAppHelp = {
        ...state.dismissedInAppHelp,
        [action.payload]: true,
      }
      localStorage.setItem(`${LOCAL_STORAGE_PREFIX}${action.payload}`, 'true')
      state.currentInAppHelp = null
    },
  },
  extraReducers: builder => {
    builder
      .addCase(reset, () => {
        return initialState
      })
      .addCase(logout, () => {
        return initialState
      })
  },
})

export const { startInAppHelp, finishInAppHelp } = inAppHelpSlice.actions

export default inAppHelpSlice.reducer
