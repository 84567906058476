import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Joyride, { CallBackProps, EVENTS, Step } from 'react-joyride'
import { useAppDispatch, useAppSelector } from '../../../store'
import { CustomTourSteps } from './components/CustomTourComponents'
import {
  finishInAppHelp,
  startInAppHelp,
} from '../../../redux/inAppHelp/inAppHelpSlice'
import {
  DynamicInAppHelpClickEvent,
  StaticInAppHelpClickEvent,
  addDynamicInAppHelpClickEvent,
  addStaticInAppHelpClickEvent,
} from '../../../lib/googleTagManager'

export type Props = {
  steps: Array<Partial<Step>>
  storageKey: string
  mode?: 'floater' | 'beacon'
  disableOverlayClose?: boolean
  isWarning?: boolean
  beaconColour?: string
  disableScrolling?: boolean
  analytics?:
    | {
        type: 'static'
        params: StaticInAppHelpClickEvent
      }
    | {
        type: 'dynamic'
        params: DynamicInAppHelpClickEvent
      }
}

export interface InAppHelpState {
  eventId: string
  currentRound: number
}

const InAppHelp: React.FC<Props> = ({
  steps,
  storageKey,
  mode = 'floater',
  disableOverlayClose = false,
  isWarning = false,
  analytics,
  beaconColour,
  disableScrolling = false,
}) => {
  const [run, setRun] = useState(false)
  const [stepIndex, setStepIndex] = useState(0)

  const { eventId, currentInAppHelp, dismissedInAppHelp } = useAppSelector(
    state => ({
      eventId: state.event.details?.eventId,
      currentInAppHelp: state.inAppHelp.currentInAppHelp,
      dismissedInAppHelp: state.inAppHelp.dismissedInAppHelp,
    }),
  )

  const dispatch = useAppDispatch()

  const fullStorageKey = useMemo(
    () => `${eventId}-${storageKey}`,
    [eventId, storageKey],
  )

  useEffect(() => {
    if (
      !dismissedInAppHelp[fullStorageKey] &&
      currentInAppHelp !== fullStorageKey
    ) {
      if (mode !== 'beacon') {
        dispatch(startInAppHelp(fullStorageKey))
      }
      if (analytics && analytics.type === 'dynamic') {
        addDynamicInAppHelpClickEvent(analytics.params)
      }
      setRun(true)
    }
  }, [
    analytics,
    currentInAppHelp,
    dismissedInAppHelp,
    dispatch,
    fullStorageKey,
    mode,
  ])

  const handleJoyrideCallback = useCallback(
    (data: CallBackProps) => {
      const { type } = data
      if (mode === 'beacon' && type === EVENTS.TOOLTIP && data.index === 0) {
        dispatch(startInAppHelp(fullStorageKey))

        if (analytics && analytics.type === 'static') {
          addStaticInAppHelpClickEvent(analytics.params)
        }
      }
      if (type === EVENTS.STEP_AFTER) {
        const { index } = data
        setStepIndex(index + 1)
      }
      if (type === EVENTS.TOUR_END) {
        dispatch(finishInAppHelp(fullStorageKey))
      }
    },
    [analytics, dispatch, fullStorageKey, mode],
  )

  const handleNextClick = useCallback(() => {
    if (stepIndex < steps.length - 1) {
      setStepIndex(stepIndex + 1)
    } else {
      setRun(false)
      dispatch(finishInAppHelp(fullStorageKey))
    }
  }, [stepIndex, steps.length, dispatch, fullStorageKey])

  const handleSkipClick = useCallback(() => {
    setRun(false)
    dispatch(finishInAppHelp(fullStorageKey))
  }, [dispatch, fullStorageKey])

  return (
    <Joyride
      disableScrolling={disableScrolling}
      disableOverlayClose={disableOverlayClose}
      steps={CustomTourSteps({
        steps,
        stepIndex,
        handleSkipClick,
        handleNextClick,
        mode,
        isWarning,
      })}
      run={run}
      callback={handleJoyrideCallback}
      continuous={true}
      stepIndex={stepIndex}
      floaterProps={{
        styles: {
          wrapper: {
            zIndex: 40,
          },
        },
      }}
      styles={{
        options: {
          primaryColor: beaconColour || '#28A4F2',
        },
      }}
    />
  )
}

export default InAppHelp
