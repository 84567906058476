import React from 'react'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { CostsData } from '../Data'
import Card from '../../../atoms/Card/Card'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import { Icon } from '../../../atoms/Icon/Icon'
import { DecisionMap } from '../CostsContainer'
import ValueChangeText from '../../../molecules/ValueChangeText/ValueChangeText'

interface CostsTotalImpactProps {
  onChangeValue: (val: Partial<DecisionMap>) => void
  costData: CostsData
}

const CostsTotalImpact: React.FC<CostsTotalImpactProps> = ({ costData }) => {
  return (
    <Card className="h-full rounded-none py-1 desktop:rounded-lg desktop:px-10 desktop:py-8">
      <VerticalGroup
        className="desktop:gap-5"
        verticalCenter
        center
        full
        fullWidth
      >
        <SecondaryText className="desktop:text-xl" size="base" weight="bold">
          TOTAL IMPACT
        </SecondaryText>
        <HorizontalGroup
          className="tablet:gap-10 desktop:flex-col desktop:gap-6"
          gap={6}
        >
          <HorizontalGroup fullWidth verticalCenter gap={2}>
            <Icon
              className="hidden w-8 desktop:flex"
              type="costs"
              colour="primary"
              size={14}
            />
            <Icon
              className="w-8 desktop:hidden"
              type="costs"
              colour="primary"
              size={10}
            />

            <VerticalGroup className="justify-end" full gap={1}>
              <SecondaryText size="xs">COST</SecondaryText>
              <ValueChangeText
                className="desktop:text-xl"
                isCost
                size="base"
                weight="bold"
                value={Number(costData.totalChangeInCost.toFixed(0))}
                changeUnit=" m"
                removeArrow
              ></ValueChangeText>
            </VerticalGroup>
          </HorizontalGroup>

          <HorizontalGroup verticalCenter fullWidth gap={2}>
            <Icon
              className="hidden desktop:flex"
              type="people"
              colour="primary"
              size={14}
            />
            <Icon
              className="desktop:hidden"
              type="people"
              colour="primary"
              size={10}
            />
            <VerticalGroup className="justify-end" full gap={1}>
              <SecondaryText size="xs">PEOPLE</SecondaryText>
              <ValueChangeText
                className="desktop:text-xl"
                whitespace="nowrap"
                size="base"
                weight="bold"
                colour="black"
                value={Number(costData.totalFTEChange.toFixed(0))}
                changeUnit=" FTE"
                removeArrow
              ></ValueChangeText>
            </VerticalGroup>
          </HorizontalGroup>
        </HorizontalGroup>
      </VerticalGroup>
    </Card>
  )
}

export default CostsTotalImpact
