import React, { FC } from 'react'
import { useAppSelector } from '../../../../store'
import { selectBranding } from '../selectors'
import Button, { Props as ButtonProps } from '../../../atoms/Button/Button'

interface Props extends ButtonProps {}

const BrandedButton: FC<Props> = props => {
  const branding = useAppSelector(selectBranding)
  const style: React.CSSProperties = {}
  if (branding.buttonBackgroundColour && props.onClick && !props.disabled) {
    if (props.buttonType === 'secondary') {
      style.borderColor = branding.buttonBackgroundColour
      style.color = branding.buttonBackgroundColour
    } else {
      style.backgroundColor = branding.buttonBackgroundColour
      if (branding.buttonTextColour) {
        style.color = branding.buttonTextColour
      }
    }
  }
  return <Button {...props} style={style} />
}

export default BrandedButton
