import React from 'react'
import { Range } from 'react-range'
import { Icon } from '../Icon/Icon'

export interface Props {
  className?: string
  min: number
  max: number
  value: number
  step: number
  onChange: (val: number) => void
  disabled?: boolean
}

const _Slider: React.FC<Props> = ({
  min,
  max,
  value,
  step,
  onChange,
  disabled,
}) => {
  return (
    <Range
      step={step}
      min={min}
      max={max}
      values={[value]}
      onChange={values => onChange(values[0])}
      disabled={disabled}
      renderTrack={({ props, children }) => (
        <div
          {...props}
          className="h-1 w-full bg-primary"
          style={{
            ...props.style,
          }}
        >
          {children}
        </div>
      )}
      renderThumb={({ props }) => (
        <div
          className="flex h-6 w-6 items-center justify-center rounded-full bg-primary laptop:h-8 laptop:w-8"
          {...props}
          // style={{
          //   ...props.style,
          // }}
        >
          <div>
            <Icon
              type="businesses"
              colour="white"
              className="h-4 w-4 laptop:h-6 laptop:w-6"
            />
          </div>
        </div>
      )}
      renderMark={({ props }) => (
        <div
          className="h-3 w-3 rounded-full border-2 border-primary bg-white laptop:h-4 laptop:w-4"
          {...props}
          style={{
            ...props.style,
          }}
        />
      )}
    />
  )
}
export default _Slider
