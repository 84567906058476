import React, { useCallback, useEffect, useMemo } from 'react'

import { fetchSummary } from '../../../redux/summary/summaryActions'
import { useAppDispatch, useAppSelector } from '../../../store'
import Page from '../../atoms/Page/Page'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import CentredSpinner from '../../molecules/CentredSpinner/CentredSpinner'
import MobilePageHeader from '../../organisms/MobilePageHeader/MobilePageHeader'

import SummaryItem from './SummaryItem'
import { fetchBusinesses } from '../../../redux/businesses/businessActions'
import { fetchTreasury } from '../../../redux/treasury/treasuryActions'
import InlineError from '../../atoms/InlineError/InlineError'
import SummaryTour from './SummaryTour'
import InAppHelp from '../../organisms/InAppHelp/InAppHelp'
import InAppHelpContent from '../../organisms/InAppHelp/InAppHelpContent'
import Text from '../../atoms/Text/Text'

interface Props {}

const Summary: React.FC<Props> = () => {
  const dispatch = useAppDispatch()
  const {
    isLoading,
    summaries,
    error,
    teamId,
    roundId,
    isTablet,
    businessData,
    businessLoading,
    treasuryData,
    treasuryLoading,
    demoEnabled,
    demoEnabledSummaryMetrics,
  } = useAppSelector(state => {
    return {
      isLoading: state.summary.isLoading,
      summaries: state.summary.summaries,
      error: state.summary.error,
      teamId: state.event.details?.team ?? 0,
      roundId: state.game.selectedRound,
      isTablet: !state.auth.isTablet,
      businessData: state.businesses.data,
      businessLoading: state.businesses.isLoading,
      treasuryData: state.treasury.data,
      treasuryLoading: state.treasury.isLoading,
      demoEnabled: state.demo.enabled,
      demoEnabledSummaryMetrics: state.demo.enabledSummaryMetrics,
    }
  })

  const summariesData = useMemo(
    () => summaries[roundId] ?? [],
    [summaries, roundId],
  )

  const anyMetricHasAnExplanation = useMemo(() => {
    return summariesData.some(
      s => s.explanation?.length && s.explanation.length > 0,
    )
  }, [summariesData])

  const enabledMetrics = useMemo(() => {
    return summariesData.filter(s => {
      if (demoEnabled) {
        return demoEnabledSummaryMetrics.includes(s.type)
      }
      return s.enabled
    })
  }, [summariesData, demoEnabled, demoEnabledSummaryMetrics])

  const hasNPSMetric = useMemo(() => {
    return enabledMetrics.some(metric => metric.type === 'NPS')
  }, [enabledMetrics])

  const numTiles = enabledMetrics.length
  const tilesPerRow = 3
  const remainder = numTiles % tilesPerRow
  const tilesNeeded = remainder > 0 ? tilesPerRow - remainder : 0
  const invisibleTiles = useMemo(
    () => Array(tilesNeeded).fill(null),
    [tilesNeeded],
  )

  const fetchSummaryData = useCallback(() => {
    dispatch(fetchSummary({ roundId, teamId }))
  }, [dispatch, roundId, teamId])

  useEffect(() => {
    if (!isLoading && summariesData.length === 0 && !error) {
      fetchSummaryData()
    }
  }, [isLoading, summariesData.length, error, fetchSummaryData])

  useEffect(() => {
    if (teamId > 0 && roundId > 0 && isTablet) {
      if (!businessData[roundId] && !businessLoading) {
        dispatch(fetchBusinesses({ teamId, roundId }))
      }
    }
  }, [businessData, businessLoading, dispatch, isTablet, roundId, teamId])

  useEffect(() => {
    if (teamId > 0 && roundId > 0 && isTablet) {
      if (!treasuryData[roundId] && !treasuryLoading) {
        dispatch(fetchTreasury({ teamId, roundId }))
      }
    }
  }, [dispatch, isTablet, roundId, teamId, treasuryData, treasuryLoading])

  const placeChangeSteps = useMemo(
    () => [
      {
        placement: 'right' as const,
        target: '#place-change-info',
        content: (
          <InAppHelpContent heading="Change Indicator">
            <Text left>
              {
                ' Click on the change indicator to see a detailed breakdown of what affected your metric this round.'
              }
            </Text>
          </InAppHelpContent>
        ),
      },
    ],
    [],
  )

  if (!error && (isLoading || summariesData.length === 0)) {
    return <CentredSpinner />
  }

  return (
    <Page full>
      {error && (
        <InlineError message={error.message} onRetry={fetchSummaryData} />
      )}
      <VerticalGroup full gap={4}>
        <MobilePageHeader />
        <div className="grid h-full grid-cols-1 gap-4 tablet:grid-cols-2 laptop:grid-cols-3">
          {enabledMetrics.map((s, index) => (
            <SummaryItem
              index={index}
              key={s.type}
              item={s}
              numTiles={numTiles}
              showExplanation={anyMetricHasAnExplanation}
            />
          ))}

          {invisibleTiles.map((_, index) => (
            <div
              key={`invisible-tile-${index}`}
              className="invisible w-full flex-grow tablet:w-80 monitor:w-96"
            />
          ))}
        </div>
      </VerticalGroup>
      <SummaryTour />

      {roundId === 2 && anyMetricHasAnExplanation && hasNPSMetric && (
        <InAppHelp
          disableScrolling
          beaconColour="white"
          mode="beacon"
          steps={placeChangeSteps}
          storageKey="place-change-help"
        />
      )}
    </Page>
  )
}

export default Summary
