export interface DebugInfo {
  userInput: string
  screenSize: {
    width: number
    height: number
    pixelRatio: number
  }
  windowSize: {
    width: number
    height: number
  }
  deviceInfo: {
    platform?: string
    userAgent: string
    deviceType: 'mobile' | 'tablet' | 'desktop'
    language?: string
  }
  browserInfo: {
    name: string
    version: string
    zoom: number
  }
  pageInfo: {
    currentUrl: string
    referrer: string
    loadTime: number
  }
  sessionInfo: {
    timestamp: string
    timeOnPage: number
  }
}

export const collectDebugInfo = (userInput: string): DebugInfo => {
  return {
    userInput,
    screenSize: {
      width: window.screen.width,
      height: window.screen.height,
      pixelRatio: window.devicePixelRatio,
    },
    windowSize: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    deviceInfo: {
      platform: navigator.platform,
      userAgent: navigator.userAgent,
      deviceType: detectDeviceType(),
      language: navigator.language,
    },
    browserInfo: {
      name: getBrowserName(),
      version: getBrowserVersion(),
      zoom: window.devicePixelRatio,
    },
    pageInfo: {
      currentUrl: window.location.href,
      referrer: document.referrer,
      loadTime: performance.now(),
    },
    sessionInfo: {
      timestamp: new Date().toISOString(),
      timeOnPage: Math.round(performance.now() / 1000),
    },
  }
}

const detectDeviceType = (): 'mobile' | 'tablet' | 'desktop' => {
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet'
  }
  if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua,
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}

const getBrowserName = (): string => {
  const ua = navigator.userAgent
  if (ua.includes('Firefox')) return 'Firefox'
  if (ua.includes('Chrome')) return 'Chrome'
  if (ua.includes('Safari')) return 'Safari'
  if (ua.includes('Edge')) return 'Edge'
  if (ua.includes('Opera')) return 'Opera'
  return 'ua'
}

const getBrowserVersion = (): string => {
  const ua = navigator.userAgent
  const browserName = getBrowserName()
  const match = ua.match(new RegExp(`${browserName}\\/([0-9.]+)`))
  return match ? match[1] : 'Unknown'
}
