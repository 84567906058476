import { Colour, Shade, TextColour } from '../types/theme'

export interface ShadeClasses extends Record<Shade, string> {
  default: string
}

export interface ColourClasses extends Record<Colour, ShadeClasses> {}

export const THEME_COLOURS: Colour[] = [
  'success',
  'error',
  'primary',
  'orange',
  'pink',
  'green',
  'purple',
  'darkblue',
  'gray',
  'white',
  'black',
  'lightgray',
]

export const THEME_TEXT_COLOURS: TextColour[] = [
  'success',
  'error',
  'primary',
  'white',
  'black',
]

export const THEME_SHADES: Shade[] = [
  50, 100, 200, 300, 400, 500, 600, 700, 800, 900,
]

const defaultShadeClasses: ShadeClasses = {
  default: '',
  50: '',
  100: '',
  200: '',
  300: '',
  400: '',
  500: '',
  600: '',
  700: '',
  800: '',
  900: '',
}

const getDefaultColourClasses = (): ColourClasses => ({
  success: { ...defaultShadeClasses },
  error: { ...defaultShadeClasses },
  primary: { ...defaultShadeClasses },
  orange: { ...defaultShadeClasses },
  pink: { ...defaultShadeClasses },
  green: { ...defaultShadeClasses },
  purple: { ...defaultShadeClasses },
  darkblue: { ...defaultShadeClasses },
  gray: { ...defaultShadeClasses },
  white: { ...defaultShadeClasses },
  black: { ...defaultShadeClasses },
  lightgray: { ...defaultShadeClasses },
})

export const getBackgroundColours = (): ColourClasses => {
  return THEME_COLOURS.reduce<ColourClasses>((acc, colour) => {
    acc[colour].default = `bg-${colour}`
    for (const shade of THEME_SHADES) {
      acc[colour][shade] = `bg-${colour}-${shade}`
    }
    return acc
  }, getDefaultColourClasses())
}
export const getBorderColours = (): ColourClasses => {
  return THEME_COLOURS.reduce<ColourClasses>((acc, colour) => {
    acc[colour].default = `border-${colour}`
    for (const shade of THEME_SHADES) {
      acc[colour][shade] = `border-${colour}-${shade}`
    }
    return acc
  }, getDefaultColourClasses())
}
export const getTextColours = (): ColourClasses => {
  return THEME_COLOURS.reduce<ColourClasses>((acc, colour) => {
    acc[colour].default = `text-${colour}`
    for (const shade of THEME_SHADES) {
      acc[colour][shade] = `text-${colour}-${shade}`
    }
    return acc
  }, getDefaultColourClasses())
}

export const BACKGROUND_COLOUR_CLASSES: Record<Colour, string> = {
  success: 'bg-success',
  error: 'bg-error',
  primary: 'bg-primary',
  orange: 'bg-orange',
  pink: 'bg-pink',
  green: 'bg-green',
  purple: 'bg-purple',
  darkblue: 'bg-darkblue',
  gray: 'bg-gray',
  white: 'bg-white',
  black: 'bg-black',
  lightgray: 'bg-lightgray',
}

export const BACKGROUND_COLOUR_LIGHT_SHADE_CLASSES: Record<Colour, string> = {
  success: 'bg-success-50',
  error: 'bg-error-50',
  primary: 'bg-primary-50',
  orange: 'bg-orange-100',
  pink: 'bg-pink-200',
  green: 'bg-green-50',
  purple: 'bg-purple-50',
  darkblue: 'bg-darkblue-50',
  gray: 'bg-gray-50',
  white: 'bg-white-50',
  black: 'bg-black-50',
  lightgray: 'bg-lightgray-50',
}

export const BACKGROUND_COLOUR_SHADE_CLASSES: Record<Colour, string> = {
  success: 'bg-success-300',
  error: 'bg-error-300',
  primary: 'bg-primary-300',
  orange: 'bg-orange-300 ',
  pink: 'bg-pink-300',
  green: 'bg-green-300',
  purple: 'bg-purple-300',
  darkblue: 'bg-darkblue-300',
  gray: 'bg-gray-300',
  white: 'bg-white-300',
  black: 'bg-black-300',
  lightgray: 'bg-lightgray-300',
}

export const DARKER_BACKGROUND_COLOUR_SHADE_CLASSES: Record<Colour, string> = {
  success: 'bg-success-400',
  error: 'bg-error-400',
  primary: 'bg-primary-400',
  orange: 'bg-orange-400 ',
  pink: 'bg-pink-400',
  green: 'bg-green-400',
  purple: 'bg-purple-400',
  darkblue: 'bg-darkblue-400',
  gray: 'bg-gray-400',
  white: 'bg-white-400',
  black: 'bg-black-400',
  lightgray: 'bg-lightgray-400',
}
