import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

interface Props extends React.HTMLAttributes<HTMLTableRowElement> {
  highlight?: boolean
  transparent?: boolean
  divideY?: boolean
  noDivideX?: boolean
}

const TableRow: React.FC<PropsWithChildren<Props>> = ({
  children,
  className,
  highlight,
  transparent,
  divideY,
  noDivideX = false,
  ...rest
}) => {
  return (
    <tr
      className={classNames(
        {
          'divide-x-0': noDivideX,
          'divide-y': divideY,
        },
        {
          'bg-orange-200': highlight,
          'bg-white': !highlight && !transparent,
          'bg-transparent': transparent,
          'cursor-pointer': !!rest.onClick,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </tr>
  )
}

export default TableRow
