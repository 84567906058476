import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { Colour } from '../../../types/theme'
import { colours } from '../../../constants/colours'

interface DonutProps {
  className?: string
  colour: Colour
  // height?: number;
  min: number
  max: number
  value: number
  stroke?: number
}

const backgroundColour = '#DDE2E5'

const Donut: React.FC<PropsWithChildren<DonutProps>> = ({
  className,
  children,
  colour,
  // height = 200,
  min,
  max,
  value,
  stroke = 6,
}) => {
  const hexColour = colours[colour]
  let progress = 0
  let progressColor = hexColour
  let backgroundColor = backgroundColour

  if (value > 0) {
    progress = (value / max) * 0.5
  }
  if (value < 0) {
    progress = 1 - (value / min) * 0.5
    progressColor = backgroundColour
    backgroundColor = hexColour
  }

  return (
    // <div style={{ height, width: height }}>
    <CircularProgressbarWithChildren
      className={classNames(className)}
      value={progress * 100}
      styles={buildStyles({
        pathColor: progressColor,
        trailColor: backgroundColor,
        strokeLinecap: 'butt',
        pathTransitionDuration: 0,
      })}
      strokeWidth={stroke}
    >
      {children}
    </CircularProgressbarWithChildren>
    // </div>
  )
}

export default Donut
