import { ProductType } from '../../../types/gameApi/business'

export const calculateSignedPercentage = (
  value: number,
  prevValue: number,
  type?: ProductType,
) => {
  let signedPrevValue = prevValue
  if (type === 'DEPOSITS') {
    if (signedPrevValue > 0) {
      signedPrevValue = prevValue * -1
    }
  }
  const percentage = ((value - signedPrevValue) / prevValue) * 100

  return percentage
}
