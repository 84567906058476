import React, { PropsWithChildren } from 'react'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'

const QuestionContainer: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <VerticalGroup gap={4} className="tablet:gap-8" start>
      {children}
    </VerticalGroup>
  )
}

export default QuestionContainer
