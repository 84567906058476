import React from 'react'

export interface State {
  bid: number | null
  assumptionOverrides: number[]
  argx?: number | null
  argy?: number | null
  bidEnabled?: boolean
}

export const initState: State = {
  bid: null,
  assumptionOverrides: [],
}

/**
 * Action
 */
interface UpdateBid {
  type: 'UpdateBid'
  payload: number
}

interface UpdateArgs {
  type: 'UpdateArgs'
  payload: { argx?: number | null; argy?: number | null }
}

interface UpdateAssumptionOverride {
  type: 'UpdateAssumptionOverride'
  payload: {
    index: number
    value: number
  }
}

interface UpdateAssumptionOverrides {
  type: 'UpdateAssumptionOverrides'
  payload: number[]
}

interface UpdateBidEnabled {
  type: 'UpdateBidEnabled'
  payload: boolean | undefined
}

type Actions =
  | UpdateBid
  | UpdateArgs
  | UpdateAssumptionOverride
  | UpdateBidEnabled
  | UpdateAssumptionOverrides

/**
 * Reducer
 */
export const reducer: React.Reducer<State, Actions> = (state, action) => {
  switch (action.type) {
    case 'UpdateBid':
      return state.bid === action.payload
        ? state
        : {
            ...state,
            bid: action.payload,
          }
    case 'UpdateBidEnabled':
      return {
        ...state,
        bidEnabled: action.payload,
      }
    case 'UpdateArgs':
      return {
        ...state,
        ...action.payload,
      }
    case 'UpdateAssumptionOverride': {
      const overridesCopy = [...state.assumptionOverrides]
      overridesCopy[action.payload.index] = action.payload.value
      return {
        ...state,
        assumptionOverrides: overridesCopy,
      }
    }
    case 'UpdateAssumptionOverrides':
      return {
        ...state,
        assumptionOverrides: action.payload,
      }
    default:
      throw new Error('Unsupported action type')
  }
}
