import React from 'react'
import {
  EconomicScenarioNewsConfig,
  EconomicScenarioNewsRoundResponse,
} from '../../../types/gameApi/news'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import PrimaryText from '../../atoms/Text/PrimaryText'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'

interface Props {
  news: EconomicScenarioNewsRoundResponse
  newsConfig: EconomicScenarioNewsConfig
}

const BreakingNewsEconomy: React.FC<Props> = ({ news, newsConfig }) => {
  return (
    <VerticalGroup>
      <VerticalGroup id="economy" data-test="the-economy" gap={2}>
        <PrimaryText size="base" weight="bold" colour="primary">
          {newsConfig.mainSectionHeading}
        </PrimaryText>
        <VerticalGroup gap={1}>
          <HorizontalGroup
            verticalCenter
            className="h-5 w-fit border-l-8 border-orange bg-orange-100 p-0.5"
          >
            <PrimaryText size="sm" weight="bold">
              {newsConfig.mainSectionTitle}
            </PrimaryText>
          </HorizontalGroup>
          <VerticalGroup gap={3}>
            <PrimaryText isHtml size="sm">
              {news.mainSectionText}
            </PrimaryText>

            <HorizontalGroup
              verticalCenter
              className="h-5 w-fit border-l-8 border-orange bg-orange-100 p-0.5"
            >
              <PrimaryText size="sm" weight="bold">
                {newsConfig.mainSectionSecondaryTitle}
              </PrimaryText>
            </HorizontalGroup>
          </VerticalGroup>
          <HorizontalGroup between>
            <PrimaryText isHtml size="sm" className="italic">
              {news.mainSectionSecondaryText}
            </PrimaryText>
            <PrimaryText size="sm" whitespace="nowrap" className="italic">
              <HorizontalGroup fullWidth className="justify-end">
                {news.mainSectionSecondaryTextAuthor &&
                  news.mainSectionSecondaryTextAuthor.length > 0 && (
                    <PrimaryText
                      isHtml
                    >{`-- ${news.mainSectionSecondaryTextAuthor}`}</PrimaryText>
                  )}
              </HorizontalGroup>
            </PrimaryText>
          </HorizontalGroup>
        </VerticalGroup>
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default BreakingNewsEconomy
