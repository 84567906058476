import {
  ProductType,
  Business,
  BusinessVolumeValues,
  RateValues,
  InterestValues,
} from '../../../../types/gameApi/business'
import { DecisionMap } from '../BusinessesContainer'
import { formatPrice1 } from '../formatters'

export interface InterestIncome {
  averagePrev: number
  averageNow: number
  interestPrev: number
  interestNow: number
}

export const getInterestIncomeForecastFromBusinessDecisions = (
  type: ProductType,
  data: Business[],
  roundId: number,
  decisions: DecisionMap,
): InterestIncome | null => {
  const item = data.find(i => i.type === type)
  if (!item) {
    return null
  }
  const averagePrev =
    roundId === 1
      ? item.history[0].volume
      : (item.history[roundId - 1].volume + item.history[roundId - 2].volume) /
        2
  const averageNow = (decisions[type].volumeNow + item.volumePrev) / 2
  const interestPrev = item.incomePrev
  const interestNow = (decisions[type].price1Now * averageNow) / 10000
  return { averagePrev, averageNow, interestPrev, interestNow }
}

export const getInterestIncomeForecastFromVolume = (
  volumeNow: number,
  volume: BusinessVolumeValues,
  rates: RateValues,
) => {
  const averagePrev =
    volume.volumePrevPrev == null
      ? volume.volumePrev
      : (volume.volumePrev + volume.volumePrevPrev) / 2
  const averageNow = (volumeNow + volume.volumePrev) / 2
  const interestPrev = (rates.ratePrev * averagePrev) / 10000
  const interestNow = (rates.rateNow * averageNow) / 10000
  return { averagePrev, averageNow, interestPrev, interestNow }
}

export const getInterestIncomeForecastFromInterest = (
  volumeNow: number,
  volume: BusinessVolumeValues,
  rates: RateValues,
  interest: InterestValues,
) => {
  const averagePrev =
    volume.volumePrevPrev == null
      ? volume.volumePrev
      : (volume.volumePrev + volume.volumePrevPrev) / 2
  const averageNow = (volumeNow + volume.volumePrev) / 2
  const interestPrev = interest.interestPrev
  const interestNow = (rates.rateNow * averageNow) / 10000
  return { averagePrev, averageNow, interestPrev, interestNow }
}

export const calculateAbsolutePercentage = (
  value: number,
  prevValue: number,
  type: ProductType | null,
  numberOfDp = 2,
) => {
  if (value === prevValue) {
    return '0.00%'
  }
  let signedPrevValue = prevValue
  if (type === 'DEPOSITS') {
    if (signedPrevValue > 0) {
      signedPrevValue = prevValue * -1
    }
  }
  const percentage = (value - prevValue) / 100
  const result = `${percentage.toFixed(numberOfDp)}%`
  if (result === '-0.00%') {
    return '0.00%'
  }
  return result
}

export const getRateItemFromBusinessDecisions = (
  type: ProductType,
  data: Business[],
  decisions: DecisionMap,
) => {
  const item = data.find(i => i.type === type)
  if (!item) {
    return null
  }
  const rateItem = {
    value: decisions[item.type].price1Now,
    min: item.slider1Min,
    max: item.slider1Max,
    formatValue: (val: number) => formatPrice1(val, item.type),

    percentageChange: calculateAbsolutePercentage(
      decisions[item.type].price1Now,
      item.price1Prev,
      null,
      2,
    ),
  }
  return rateItem
}

export const calculatePercentage = (
  value: number,
  prevValue: number,
  type?: ProductType,
  numberOfDp = 2,
) => {
  let signedPrevValue = prevValue
  if (type === 'DEPOSITS') {
    if (signedPrevValue > 0) {
      signedPrevValue = prevValue * -1
    }
  }
  if (prevValue === 0) {
    return '0.00%'
  }
  const percentage = ((value - signedPrevValue) / prevValue) * 100
  const result = `${percentage.toFixed(numberOfDp)}%`
  if (result === '-0.00%') {
    return '0.00%'
  }
  return result
}
