import React, { useCallback, useState } from 'react'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import Text from '../../../atoms/Text/Text'
import { Icon } from '../../../atoms/Icon/Icon'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../../store'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import { startTour } from '../../../../redux/guidedTours/guidedToursSlice'
import SubheadingDropDown from '../Components/SubheadingDropDown'
import { changeTab } from '../../../../redux/dealroom/dealroomSlice'
import { useMenuTranslations } from '../../../../hooks/useMenuTranslations'
import Button from '../../../atoms/Button/Button'

interface Props {}
const DealRoomHelp: React.FC<Props> = () => {
  const [expandedSubheading, setExpandedSubheading] = useState('')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const translations = useMenuTranslations()

  const handleDealRoomTour = useCallback(() => {
    dispatch(startTour('deal-room'))
    dispatch(changeTab('overview'))
    navigate('/deal-room')
  }, [dispatch, navigate])

  return (
    <VerticalGroup gap={2}>
      <Text center size="lg">
        {translations.dealRoom} Guide
      </Text>{' '}
      <VerticalGroup gap={6}>
        <HorizontalGroup center>
          <Button
            buttonType="secondary"
            full
            size="xl"
            onClick={handleDealRoomTour}
          >
            <Icon type="guide" colour="primary" size={4} />
            <Text colour="primary">Start Walkthrough</Text>
          </Button>
        </HorizontalGroup>
        <VerticalGroup gap={6}>
          <SubheadingDropDown
            title={'OVERVIEW'}
            content={
              "The Deal Room Overview contains a brief summary of the transaction, including potential synergies and risks. Each deal has unique characteristics, returns, and risks. Carefully review the details of each deal to determine if it aligns with your bank's strategic objectives and risk appetite. Deals will be automatically funded if you are the successful winner. "
            }
            hint={
              " Do not only consider the benefits of the deal but also fully consider the risk implications. Pass on deals that don't meet your criteria, as your resources are limited."
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
          <SubheadingDropDown
            title={'PRICING MATRIX'}
            content={
              'The Pricing Matrix screen can be used to quickly make a bid considering the most sensitive assumptions. This screen shows various Bid Prices that could be paid and considers the assumptions you have used in your Valuation, but also different combinations of the two most sensitive assumptions. Quickly make a bid by selecting the intersection of two assumptions.'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
          <SubheadingDropDown
            title={'VALUATION'}
            content={
              'The Valuation calculates the value of the transaction based on the latest information available. Assumptions may change after the transaction is undertaken, no one can perfectly predict the future. Use judgment to adjust any assumptions in the CEO device. Pay close attention to the two assumptions with red font in their details as these are likely to be the most sensitive value drivers.'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
        </VerticalGroup>
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default DealRoomHelp
