import { percentage, thousandSeparator } from '../../../lib/formatters'
import { FinancialSummaryItem } from '../../../types/gameApi/financials'

export const formatItemValue = (item: FinancialSummaryItem) => {
  const heading = item.type
  const value = item.value
  switch (heading) {
    case 'RemediationAndFines':
      return {
        valueString:
          value === 0 ? 'None' : `${thousandSeparator(formatNegative(value))}`,
      }
    case 'Complaints':
      return {
        valueString:
          value === 0 ? 'None' : `${thousandSeparator(formatNegative(value))}`,
      }
    case 'EmployeeEngagement':
      return {
        valueString:
          value === 0
            ? 'None'
            : `${formatNegative(percentage(value, 0, true))}`,
      }
    default:
      return {
        valueString:
          value === 0
            ? 'None'
            : `${formatNegative(percentage(value, 2, true))}`,
      }
  }
}

const formatNegative = (value: string | number) => {
  if (Number(value) < 0) {
    value = `(${value.toString().replace('-', '')})`
  }
  return value
}
