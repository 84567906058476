import { IconType } from '../../../atoms/Icon/Icon'

interface IconConfig {
  icon: IconType
  body: string
}

export const divisionConfigMap: Record<string, IconConfig> = {
  IT_OPERATIONS: {
    icon: 'it',
    body: 'Relates to your IT processes & infrastructure supporting your Banking operations. Invest to simplify processes, decommission legacy systems, provide new system functionality, and improve the customer experience.',
  },
  FRONT_LINE: {
    icon: 'frontline',
    body: 'These staff deal with customers directly. More frontline means shorter wait times, resolution of complaints, and assisting to meet their financial needs.',
  },
  RISK_COMPLIANCE: {
    icon: 'risk',
    body: 'These staff are Risk and Compliance professionals which partner with the rest of the business to drive better risk outcomes. Increasing investment here will reduce outstanding risk incidents, improve and document processes, and ensure compliance with the relevant laws and regulations.',
  },
  HR_TRAINING: {
    icon: 'hr',
    body: 'This function supports the development of staff across all areas. Increasing HR & Training will improve the effectiveness of your people by investing in programs to strengthen their skills and capabilities. Engagement will also increase as you invest in their career and personal development.',
  },
  SALARY_GROWTH: {
    icon: 'salary',
    body: 'Adjusting salary growth will impact all functions. Large pay increases can quickly add up but can also help to impact employee engagement levels. A certain amount of salary growth is usually built into the existing cost base.',
  },
}
