import React, { PropsWithChildren } from 'react'
import {
  BarChart as _BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  ReferenceLine,
  ResponsiveContainer,
  Text,
} from 'recharts'
interface Props {
  animate?: boolean
  data: any[]
  formatter?: (val: number) => string | number
  maxYAxisvalue: number
  minYAxisvalue: number
  numberOfTicks: number
  withoutCpuTeams?: boolean
  hideYAxis?: boolean
  hideGrid?: boolean
  barsComponent?: any
  xPadding?: number
  stackOffset?: 'sign'
  barCategoryGap?: number
  margin?: {
    left: number
    top: number
    right: number
    bottom: number
  }
}

export const createLegend = (): React.FC => {
  const Legend: React.FC<any> = props => {
    const { x, y, width, height, value, name } = props
    if (value == null) {
      return null
    }
    return (
      <g>
        <text
          x={x + width / 2}
          y={y + height + 20}
          fill={'black'}
          textAnchor="middle"
          fontSize={12}
          fontFamily="Open Sans"
        >
          {name}
        </text>
      </g>
    )
  }
  return Legend
}

const YAxisTick = ({ y, tickFormatter, payload: { value } }: any) => {
  return (
    <Text
      x={0}
      y={y}
      textAnchor="start"
      verticalAnchor="middle"
      fontSize={12}
      fill={'black'}
      fontFamily="Open Sans"
    >
      {tickFormatter(value)}
    </Text>
  )
}

const BarChart: React.FC<PropsWithChildren<Props>> = ({
  animate,
  data,
  formatter,
  maxYAxisvalue,
  minYAxisvalue,
  numberOfTicks,
  hideYAxis,
  hideGrid,
  barsComponent,
  xPadding,
  stackOffset,
  barCategoryGap,
  margin,
  children,
}) => {
  // const x = window.matchMedia('(max-width: 1010px)')

  // const bars = useMemo(() => {
  //   return data.map((d, idx) => {
  //     return (
  //       <Bar
  //         key={`bar-item-${idx}`}
  //         name={d.name}
  //         type="natural"
  //         dataKey={d.key}
  //         fill={d.colour}
  //         animationDuration={animate ? 1000 : 0}
  //         animationBegin={animate ? idx * 1000 : 0}
  //         barSize={500}
  //       >
  //         <LabelList position="top" content={d.topLabel}></LabelList>
  //         {d.hasMiddleLabel && (
  //           <LabelList position="middle" content={d.content}></LabelList>
  //         )}
  //         <LabelList
  //           position="bottom"
  //           content={createLegend(d.name, d.colour)}
  //         ></LabelList>
  //       </Bar>
  //     )
  //   })
  // }, [animate, data])

  // const x = window.matchMedia('(max-width: 1010px)')

  return (
    <ResponsiveContainer width="100%" height="100%" debounce={1}>
      <_BarChart
        data={data}
        margin={
          margin ?? {
            left: -10,
            top: 20,
            right: 10,
            bottom: 40,
          }
        }
        stackOffset={stackOffset}
        // hacky way of handling media query for bar width
        // barCategoryGap={x.matches ? -55 : -25}
        // maxBarSize={x.matches ? -25 : -65}
        // hacky way of handling media query for bar width
        // barCategoryGap={x.matches ? -55 : -25}
        // maxBarSize={x.matches ? -25 : -65}
        barCategoryGap={barCategoryGap}
      >
        {!hideGrid && <CartesianGrid vertical={false} />}
        <ReferenceLine y={0} stroke="#000" />
        <XAxis
          dataKey="name"
          tickLine={false}
          // interval={0}
          padding={{ left: xPadding ?? 75, right: xPadding ?? 75 }}
          hide={true}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickCount={numberOfTicks + 1}
          tickFormatter={formatter ? tick => formatter(tick) : tick => tick}
          domain={[minYAxisvalue ?? 'auto', maxYAxisvalue ?? 'auto']}
          hide={hideYAxis}
          tick={<YAxisTick />}
        />
        {/* <Legend wrapperStyle={{ bottom: 20, left: 0, right: 0 }} /> */}
        {/* {bars} */}
        {barsComponent}
        {!barsComponent && (
          <Bar
            // key={`bar-item-${idx}`}
            // name={}
            type="natural"
            dataKey={'value'}
            // fill={d.colour}
            animationDuration={animate ? 1000 : 0}
            animationBegin={animate ? 0 * 1000 : 0}
            barSize={500}
            isAnimationActive={animate ? true : false}
          >
            <LabelList position="top" content={data[0].topLabel}></LabelList>
            {data[0].hasMiddleLabel && (
              <LabelList
                position="middle"
                content={data[0].content}
              ></LabelList>
            )}
            <LabelList position="bottom" content={createLegend()}></LabelList>
          </Bar>
        )}
        {children}
      </_BarChart>
    </ResponsiveContainer>
  )
}

export default BarChart
