const AUTH_STORAGE_KEY = 'authDetails:3'

export interface AuthDetails {
  token: string
  eventId: string
  email: string
  participantId: string
  isTablet: boolean
  isPreviewMode: boolean
}

export const getAuthDetails = () => {
  const data = localStorage.getItem(AUTH_STORAGE_KEY)
  if (data) {
    return JSON.parse(data) as AuthDetails
  }
  return null
}

export const setAuthDetails = (data: AuthDetails) => {
  if (process.env.REACT_APP_DEMO_MODE === 'true') {
    return
  }
  localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(data))
}

export const removeAuthDetails = () => localStorage.removeItem(AUTH_STORAGE_KEY)
