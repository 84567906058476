import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store'
import RadioInput from '../../../atoms/RadioButtons/RadioInput'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { setCapitalMethod } from '../../../../redux/demo/demoSlice'

interface Props {}

const CAPITAL_METHODS: Array<{ label: string; value: number }> = [
  { label: 'Capital Ratio + DRP', value: 1 },
  { label: 'Credit Rating Only', value: 2 },
  { label: 'Auto-managed', value: 3 },
]

const DemoSettingsCapitalMethod: React.FC<Props> = () => {
  const capitalMethod = useAppSelector(state => state.demo.capitalMethod)
  const dispatch = useAppDispatch()

  const updateCapitalMethod = useCallback(
    (value: number) => {
      dispatch(setCapitalMethod(value))
    },
    [dispatch],
  )

  return (
    <VerticalGroup gap={2}>
      {CAPITAL_METHODS.map(t => (
        <HorizontalGroup verticalCenter gap={2} key={t.value}>
          <RadioInput
            key={t.value}
            id={String(t.value)}
            name={t.label}
            value={String(t.value)}
            checked={t.value === capitalMethod}
            onClick={() => {
              updateCapitalMethod(t.value)
            }}
          />
          <label htmlFor={`${t.value}`}>{t.label}</label>
        </HorizontalGroup>
      ))}
    </VerticalGroup>
  )
}

export default DemoSettingsCapitalMethod
