import React, { useEffect, useState } from 'react'
import { Icon } from '../../../atoms/Icon/Icon'
import PrimaryText from '../../../atoms/Text/PrimaryText'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { useAppSelector } from '../../../../store'
import { parseTimeLeft } from './formatter'
import classNames from 'classnames'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import InAppHelp from '../../InAppHelp/InAppHelp'
import InAppHelpContent from '../../InAppHelp/InAppHelpContent'
import BulletPoint from '../../../atoms/BulletPoint/BulletPoint'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  textColour?: string
  currentRound?: number
}

const Clock: React.FC<Props> = ({ textColour, currentRound, ...rest }) => {
  const finishTime = useAppSelector(state => state.game.finishTime)
  const [state, setstate] = useState(parseTimeLeft(finishTime ?? ''))

  useEffect(() => {
    const interval = setInterval(() => {
      setstate(parseTimeLeft(finishTime ?? ''))
    }, 1000)
    return () => clearInterval(interval)
  }, [finishTime])

  const colour = textColour
    ? undefined
    : state.diffSeconds < 10
    ? 'error'
    : state.diffSeconds < 60
    ? 'orange'
    : 'gray'

  const timeClass = classNames('tabular-nums', {
    'tablet:whitespace-pre-line': state.displayType === 'D',
  })
  const iconSizeClass = 'w-8 laptop:w-10 desktop:w-14'

  const clockSteps = [
    {
      target: '.clock-tour',
      content: (
        <InAppHelpContent heading="Time Remaining">
          <BulletPoint>
            Total amount of time available to review all information and also
            make your decisions.
          </BulletPoint>
          <BulletPoint>
            All decisions must be made before the time runs out.
          </BulletPoint>
        </InAppHelpContent>
      ),
    },
  ]

  return (
    <div className="clock-tour">
      <HorizontalGroup
        {...rest}
        verticalCenter
        gap={2}
        className="text-[10px]leading-none laptop:text-sm desktop:text-base"
      >
        <Icon
          responsiveSize={iconSizeClass}
          type="clock"
          className={classNames({
            'fill-error stroke-error': !textColour && state.diffSeconds < 10,
            'fill-orange stroke-orange':
              !textColour && state.diffSeconds >= 10 && state.diffSeconds < 60,
            'fill-gray-400 stroke-gray-400':
              !textColour && state.diffSeconds >= 60,
          })}
          style={{ fill: textColour, stroke: textColour }}
        ></Icon>
        <VerticalGroup gap={0.5}>
          {state.displayType === 'D' && (
            <PrimaryText
              whitespace="nowrap"
              colour={colour}
              className="mr-2 text-[10px] leading-none laptop:text-sm"
              style={{ color: textColour }}
            >
              Round will close:
            </PrimaryText>
          )}
          <PrimaryText
            colour={colour}
            className={timeClass}
            size={state.displayType === 'D' ? 'xs' : '2xl'}
            style={{ color: textColour }}
          >
            {state.time}
          </PrimaryText>
        </VerticalGroup>
        {currentRound === 1 && (
          <InAppHelp steps={clockSteps} storageKey="clock-help" />
        )}
      </HorizontalGroup>
    </div>
  )
}
export default Clock
