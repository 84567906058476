import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Assumption, DealRoomMathConfig } from '../configs/utils'
import { changeBannerVisibility } from '../../../../redux/dealroom/dealroomSlice'

import { useDealRoomBidAndRateMapping } from '../configs'
import { thousandSeparator } from '../../../../lib/formatters'

import { NewArgsSelectedParams } from '../types'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import Table from '../../../atoms/Table/Table'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import TableHeader from '../../../atoms/Table/TableHeader'
import TableHeaderCell from '../../../atoms/Table/TableHeaderCell'
import TableSection from '../../../atoms/Table/TableSection'
import TableRow from '../../../atoms/Table/TableRow'
import TableCell from '../../../atoms/Table/TableCell'
import Banner from '../../../atoms/Banner/Banner'
import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'

import Page from '../../../atoms/Page/Page'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { useAppDispatch, useAppSelector } from '../../../../store'

interface Props {
  config: DealRoomMathConfig
  onDataSelected?: (params: NewArgsSelectedParams) => void
  bid: number | null
  argx: number | null
  argy: number | null
  dealRoomType: string
  assumptions?: ReadonlyArray<Assumption>
  canBid?: boolean
}

const DealRoomValuationRange: React.FC<Props> = ({
  onDataSelected,
  config,
  bid,
  argx,
  argy,
  dealRoomType,
  assumptions,
  canBid,
}) => {
  const { presetX, presetY, titleX, titleY, header1 } = config
  const { rateToBid, bidToRate } = useDealRoomBidAndRateMapping(
    dealRoomType,
    assumptions,
  )
  const hideBanner = useAppSelector(state => state.dealroom.hideBanner)
  const dispatch = useAppDispatch()

  const [showBanner, setShowBanner] = useState(true)

  const permanentlyHideBanner = useCallback(() => {
    dispatch(changeBannerVisibility(true))
  }, [dispatch])

  const temporarilyHideBanner = useCallback(() => {
    setShowBanner(false)
  }, [])
  const isSelected = (x: number, y: number) => {
    if (argx && argy) {
      return x === argx && y === argy
    }
    if (bid && bidToRate.has(bid.toString())) {
      // if user hasn't select anything and we can compare the bid value to check which one is selected
      const val = bidToRate.get(bid.toString())
      if (!val) {
        return false
      }
      const [selectedX, selectedY] = val
      return selectedX === x && selectedY === y
    }
    return false
  }
  const tableRef = useRef<HTMLTableElement>(null)

  const firstColumn = useRef<HTMLTableCellElement>(null)

  const [lastColumnDimensions, setLastColumnDimensions] = useState({
    width: 0,
    height: 0,
    top: 0,
  })

  useEffect(() => {
    const updateDimensions = () => {
      if (firstColumn.current && tableRef.current) {
        const lastColumnRect = firstColumn.current.getBoundingClientRect()
        const tableRect = tableRef.current.getBoundingClientRect()

        setLastColumnDimensions({
          width: lastColumnRect.width,
          height: tableRect.height - lastColumnRect.top + tableRect.top,
          top: lastColumnRect.top - tableRect.top,
        })
      }
    }

    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  return (
    <Page>
      <VerticalGroup gap={2} center>
        {!hideBanner && showBanner && (
          <Banner
            className="w-full tablet:w-11/12 desktop:w-10/12"
            type="info"
            dismissText="Got it, don't show again"
            onDismiss={permanentlyHideBanner}
            onClose={temporarilyHideBanner}
          >
            <SecondaryText whitespace="wrap">
              <VerticalGroup>
                <SecondaryText size="sm">{header1}</SecondaryText>
                <SecondaryText size="xs">
                  Select your desired bid from the options below
                </SecondaryText>
              </VerticalGroup>
            </SecondaryText>
          </Banner>
        )}

        <HorizontalGroup className="relative" fullWidth verticalCenter>
          <div className="h-10 w-6 origin-bottom-left translate-x-10 translate-y-16 -rotate-90 transform">
            <SecondaryText whitespace="nowrap">{titleY}</SecondaryText>
          </div>

          <VerticalGroup gap={2} fullWidth>
            <HorizontalGroup fullWidth>
              <div className="absolute tablet:w-1/12">
                <TranslatedText
                  whitespace="nowrap"
                  value="general.denomination"
                  default="AUD $M"
                />
              </div>
              <HorizontalGroup center verticalCenter fullWidth>
                <SecondaryText>{titleX}</SecondaryText>
              </HorizontalGroup>
            </HorizontalGroup>
            <div style={{ position: 'relative' }}>
              <Table
                ref={tableRef}
                className="overflow-x-scroll whitespace-nowrap rounded-lg bg-gradient-to-tr from-success via-yellow-500 to-error"
              >
                <TableHeader id="tour-matrix-key-assumption" noBorder noDivideX>
                  <TableHeaderCell
                    ref={firstColumn}
                    className="sticky left-0 bg-gray-200"
                  ></TableHeaderCell>
                  {presetX.map((x, index) => (
                    <TableHeaderCell key={index}>{x}%</TableHeaderCell>
                  ))}
                </TableHeader>
                <TableSection>
                  {presetY.map((y, indx) => (
                    <TableRow divideY transparent key={indx}>
                      <TableCell
                        className="sticky left-0 bg-gray-200"
                        align="center"
                      >
                        <SecondaryText center weight="bold">
                          {y}%
                        </SecondaryText>
                      </TableCell>
                      {presetX.map((x, index) => {
                        const result = rateToBid.get(`${x}-${y}`)
                        return (
                          <TableCell
                            id={
                              index === 5 && indx === 5
                                ? 'tour-select-bid'
                                : undefined
                            }
                            align="center"
                            key={index}
                            cellSelect={isSelected(x, y)}
                            onClick={() => {
                              canBid &&
                                onDataSelected &&
                                onDataSelected({
                                  argx: x,
                                  argy: y,
                                  bid: Number(result),
                                })
                            }}
                          >
                            {thousandSeparator(result ?? 0)}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))}
                </TableSection>
              </Table>
              <div
                style={{
                  position: 'absolute',
                  top: `${lastColumnDimensions.top}px`,
                  left: 0,
                  width: `${lastColumnDimensions.width}px`,
                  height: `${lastColumnDimensions.height}px`,
                  pointerEvents: 'none',
                }}
                id="tour-matrix-key-assumption-2"
              />
            </div>
          </VerticalGroup>
        </HorizontalGroup>
      </VerticalGroup>
    </Page>
  )
}

export default DealRoomValuationRange
