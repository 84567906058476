import { createAsyncThunk } from '@reduxjs/toolkit'
import adminAPI from '../../services/adminApi'
import gameApi from '../../services/gameApi'
import APIError from '../../errors/APIError'
import { RootState } from '../../store'
import {
  TeamParticipantsRequestParams,
  TeamParticipantsResponse,
} from '../../types/adminApi/events'
import {
  AcceptEOTransferRequestParams,
  CEOTransfer,
  CancelEOTransferRequestParams,
  CreateCEOTransferRequestParams,
  GetCEOTransferRequestParams,
  GetCEOTransferResponse,
} from '../../types/gameApi/ceoTransfer'
import wait from '../../lib/wait'

export const fetchParticipants = createAsyncThunk<
  { participants: TeamParticipantsResponse },
  TeamParticipantsRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>('changeCeo/fetchParticipants', async ({ teamId }, { rejectWithValue }) => {
  try {
    const participants = await adminAPI.getParticipants({ teamId })

    return { participants }
  } catch (error: any) {
    return rejectWithValue(error)
  }
})

export const createCEOTransferRequest = createAsyncThunk<
  { success: boolean },
  CreateCEOTransferRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'changeCeo/createRequest',
  async ({ participantId, to }, { rejectWithValue }) => {
    try {
      await gameApi.createCEOTransferRequest({ participantId, to })

      return { success: true }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)

export const acceptCEOTransferRequest = createAsyncThunk<
  { success: boolean },
  AcceptEOTransferRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'changeCeo/acceptRequest',
  async ({ participantId, requestId, action }, { rejectWithValue }) => {
    try {
      await gameApi.acceptDeclineCEOTransferRequest({
        participantId,
        requestId,
        action,
      })

      return { success: true }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)

export const cancelCEOTransferRequest = createAsyncThunk<
  { success: boolean },
  CancelEOTransferRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'changeCeo/cancelRequest',
  async ({ participantId, requestId }, { rejectWithValue }) => {
    try {
      await gameApi.cancelCEOTransferRequest({ participantId, requestId })

      return { success: true }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)

export const getCEOTransferRequest = createAsyncThunk<
  { result: GetCEOTransferResponse },
  GetCEOTransferRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'changeCeo/getCEORequest',
  async ({ participantId, requestId }, { rejectWithValue, getState }) => {
    try {
      let status = 'pending'
      let response: CEOTransfer | null = null
      while (
        status === 'pending' &&
        getState().changeCeo.ceoTransferRequestStatusInProgress
      ) {
        await wait(1000)
        response = await gameApi.getCEOTransferRequest({
          participantId,
          requestId,
        })
        status = response.status
      }

      if (!response) {
        throw new Error('Invalid state')
      }

      return { result: response }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)
