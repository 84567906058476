import { createAsyncThunk } from '@reduxjs/toolkit'
import adminAPI from '../../services/adminApi'
import APIError from '../../errors/APIError'
import {
  ParticipantDetailsRequest,
  ParticipantDetailsResponse,
} from '../../types/adminApi/events'

export const fetchEventDetails = createAsyncThunk<
  ParticipantDetailsResponse,
  ParticipantDetailsRequest,
  { rejectValue: Error | APIError }
>(
  'participant/fetch',
  async ({ eventId, participantId }, { rejectWithValue }) => {
    try {
      const response = await adminAPI.getParticipantDetails({
        eventId,
        participantId,
      })

      return response
    } catch (error: any) {
      // TODO https://excelerateconsulting.atlassian.net/browse/RU-8
      return rejectWithValue(error)
    }
  },
)
