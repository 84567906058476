import React from 'react'
import Input, { Props as InputProps } from '../../atoms/Input/Input'

interface Props extends InputProps {}

const LoginInput: React.FC<Props> = props => {
  return (
    <Input
      className="h-10"
      bgColor="white"
      required
      padding={4}
      textSize="xs"
      {...props}
    />
  )
}

export default LoginInput
