import React from 'react'
import { getBusinessColour, getIconForBusiness } from '../../../lib/icons'
import {
  EconomicScenarioNewsMarketGrowthOrLoss,
  EconomicScenarioNewsMarketOutlook,
} from '../../../types/gameApi/news'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import { Icon } from '../../atoms/Icon/Icon'
import PrimaryText from '../../atoms/Text/PrimaryText'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import { BACKGROUND_COLOUR_LIGHT_SHADE_CLASSES } from '../../../lib/colourClasses'
import { percentage } from '../../../lib/formatters'
import TranslatedText from '../../atoms/TranslatedText/TranslatedText'

interface Props {
  marketOutlook: EconomicScenarioNewsMarketOutlook[]
}

const formatGrowth = (val?: EconomicScenarioNewsMarketGrowthOrLoss | null) => {
  if (val == null) {
    return (
      <PrimaryText size="xs" colour="black">
        N/A
      </PrimaryText>
    )
  }
  const fromColor = val.setFrom >= 0 ? 'success' : 'error'
  const toColor = val.setTo >= 0 ? 'success' : 'error'
  const toTextColor = fromColor === toColor ? toColor : 'black'
  return (
    <HorizontalGroup gap={1}>
      <PrimaryText size="xs" colour={val.setFrom >= 0 ? 'success' : 'error'}>
        {percentage(val.setFrom, 0, true)}
      </PrimaryText>
      <PrimaryText size="xs" colour={toTextColor}>
        to
      </PrimaryText>
      <PrimaryText size="xs" colour={val.setTo >= 0 ? 'success' : 'error'}>
        {percentage(val.setTo, 0)}
      </PrimaryText>
    </HorizontalGroup>
  )
}

const formatLoss = (val?: EconomicScenarioNewsMarketGrowthOrLoss | null) => {
  if (val == null) {
    return (
      <PrimaryText size="xs" colour="black">
        N/A
      </PrimaryText>
    )
  }

  return (
    <HorizontalGroup>
      <PrimaryText size="xs" colour="error">
        {val.setFrom}
      </PrimaryText>
      {val.setFrom !== val.setTo && (
        <>
          <PrimaryText size="xs" colour="error">
            -
          </PrimaryText>
          <PrimaryText size="xs" colour="error">
            {val.setTo}
          </PrimaryText>
        </>
      )}
      <PrimaryText size="xs" colour="error">
        bps
      </PrimaryText>
    </HorizontalGroup>
  )
}
const BreakingNewsMarketOutlook: React.FC<Props> = ({ marketOutlook }) => {
  return (
    <VerticalGroup>
      <VerticalGroup data-test="market-outlook" gap={1}>
        <PrimaryText size="base" colour="primary" weight="bold">
          MARKET OUTLOOK
        </PrimaryText>
        <div className="grid grid-cols-2 grid-rows-2 gap-1 pb-2 tablet:flex tablet:w-full tablet:justify-evenly laptop:grid">
          {marketOutlook.map((market, index) => {
            const colour = getBusinessColour(index)
            return (
              <VerticalGroup
                data-test={`market-item-${market.productType}`}
                verticalCenter
                key={market.productType}
                className={`${BACKGROUND_COLOUR_LIGHT_SHADE_CLASSES[colour]} flex-1 rounded-xl p-1 py-2 monitor:p-2`}
              >
                <HorizontalGroup
                  gap={1}
                  verticalCenter
                  className="monitor:gap-2"
                >
                  <Icon
                    className="h-8 w-8 monitor:h-10 monitor:w-10"
                    colour={colour}
                    type={getIconForBusiness(market.productType)}
                  />
                  <TranslatedText
                    weight="bold"
                    size="xs"
                    value={`businessDecisions.products.${market.productType}`}
                    default={market.productType}
                  />
                </HorizontalGroup>
                <HorizontalGroup className="tablet:pl-9 monitor:pl-12" gap={4}>
                  <VerticalGroup className=" ">
                    <PrimaryText size="xs">
                      <div className={`text-${colour}`}>GROWTH</div>
                    </PrimaryText>
                    <PrimaryText weight="bold" size="xs" colour="success">
                      <div>{formatGrowth(market.growth)}</div>
                    </PrimaryText>
                  </VerticalGroup>
                  <VerticalGroup>
                    <PrimaryText size="xs">
                      <div className={`text-${colour}`}>LOSSES</div>
                    </PrimaryText>
                    <PrimaryText weight="bold" size="xs" colour="error">
                      {formatLoss(market.losses)}
                    </PrimaryText>
                  </VerticalGroup>
                </HorizontalGroup>
              </VerticalGroup>
            )
          })}
        </div>
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default BreakingNewsMarketOutlook
