import React from 'react'
import Spinner from '../../atoms/Spinner/Spinner'

interface Props {}

const CentredSpinner: React.FC<Props> = () => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <Spinner size={8} colour="primary" />
    </div>
  )
}

export default CentredSpinner
