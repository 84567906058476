import { ProjectType } from '../../../types/gameApi/projects'

export const PROJECT_TYPE_DURATION: Record<ProjectType, number> = {
  'project-offshore': 1,
  'project-right-size': 1,
  'project-harvest': 3,
  'project-mcfinsey': 1,
  'project-switch': 1,
  'project-lightspeed': 1,
  'project-digital': 2,
  'project-genius': 1,
  'project-nitro': 1,
  'project-big-data': 3,
  'project-care-bear': 2,
  'project-tighten': 1,
  'project-fix-it': 1,
  'project-roadshow': 1,
  'project-sustain': 1,
  'project-shield': 1,
  'project-simple': 1,
  'project-fusion': 2,
}
