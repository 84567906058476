import TagManager from 'react-gtm-module'

type Category =
  | 'general'
  | 'summary'
  | 'financials'
  | 'costs'
  | 'business'
  | 'waitingroom'
  | 'teamstrategy'
  | 'news'
  | 'peerinsights'
  | 'helpmanual'
  | 'exco'
  | 'specialprojects'
  | 'treasury'
  | 'dealroom'
  | 'assessments'

const enabled = !!process.env.REACT_APP_GTM_ID

interface BaseEventParams {
  event: string
  category: Category
  action: string
  label: string
  subLabel: string
  value: number | string
}

const addToDataLayer = (params: Partial<BaseEventParams>) => {
  if (!enabled) {
    console.log(params)
    return
  }
  TagManager.dataLayer({
    dataLayer: {
      event: params.event ?? null,
      category: params.category ?? null,
      action: params.action ?? null,
      label: params.label ?? null,
      subLabel: params.subLabel ?? null,
      value: params.value ?? null,
    },
  })
}

interface Variables {
  eventId: string
  participantId: string
  currentRound: number
  roundType: string
  isTablet: boolean
}

export const addVariables = (variables: Partial<Variables>) => {
  if (!enabled) {
    return
  }
  TagManager.dataLayer({
    dataLayer: {
      ...variables,
      environment: process.env.REACT_APP_ENV,
    },
  })
}

export interface InfoClickEvent {
  category: Category
  action: 'open'
  label: string
  value?: string
}

export const addInfoClickEvent = (params: InfoClickEvent) => {
  addToDataLayer({
    event: 'info-click',
    ...params,
  })
}

export interface StaticInAppHelpClickEvent {
  category: Category
  action: 'open'
  label: string
  value?: string
}

export const addStaticInAppHelpClickEvent = (
  params: StaticInAppHelpClickEvent,
) => {
  addToDataLayer({
    event: 'inapphelp-click-static',
    ...params,
  })
}

export interface DynamicInAppHelpClickEvent {
  category: Category
  action: 'open'
  label: string
  value: string
}

export const addDynamicInAppHelpClickEvent = (
  params: DynamicInAppHelpClickEvent,
) => {
  addToDataLayer({
    event: 'inapphelp-click-dynamic',
    ...params,
  })
}

interface LastPageViewEvent {
  event: 'pageviewduration'
  category: Category | null
  label: string | null
  subLabel: string | null
  time: number | null
}

let lastPageViewEvent: LastPageViewEvent = {
  event: 'pageviewduration',
  category: null,
  label: null,
  subLabel: null,
  time: null,
}

const getTimeAsSecondsTo2dp = (timeInMilliseconds: number) => {
  const differenceInMilliseconds = new Date().getTime() - timeInMilliseconds
  const differenceInSeconds = differenceInMilliseconds / 1000
  return Math.round(differenceInSeconds * 100) / 100
}

const pathToPageMapping: Record<string, Category> = {
  '/': 'waitingroom',
  '/team': 'teamstrategy',
  '/summary': 'summary',
  '/breaking-news': 'news',
  '/peer-insights': 'peerinsights',
  '/our-financials': 'financials',
  '/help-manual': 'helpmanual',
  '/exco-decisions': 'exco',
  '/special-projects': 'specialprojects',
  '/costs': 'costs',
  '/businesses': 'business',
  '/treasury': 'treasury',
  '/deal-room': 'dealroom',
  '/assessments': 'assessments',
}

const tabMapping: { [key: string]: Record<string, string> } = {
  peerinsights: {
    '': 'market-share',
    'market-share': 'market-share',
    metrics: 'metrics',
  },
  financials: {
    '': 'summary',
    summary: 'summary',
    'profit-and-loss': 'profit-and-loss',
    'division-profit-and-loss': 'division-profit-and-loss',
    'balance-sheet': 'balance-sheet',
  },
  business: {
    '': 'decisions',
    decisions: 'decisions',
    'price-volume-trade-off': 'price-volume-trade-off',
  },
  treasury: {
    '': 'balance-sheet',
    'balance-sheet': 'balance-sheet',
    'wsf-profile': 'wsf-profile',
    'risk-weighted-assets': 'risk-weighted-assets',
    'capital-management': 'capital-management',
  },
  dealroom: {
    '': 'overview',
    overview: 'overview',
    'pricing-matrix': 'pricing-matrix',
    valuation: 'valuation',
  },
}

const subTabMapping: { [key: string]: Record<string, Record<string, string>> } =
  {
    peerinsights: {
      'market-share': {
        '': 'HOME_LOANS',
      },
    },
  }

export const registerPageView = (
  gameEnabled: boolean,
  page: string,
  tab = '',
  subTab = '',
  advanced?: boolean,
) => {
  const category = pathToPageMapping[page] ?? page
  let label = tabMapping[category]?.[tab] ?? null
  if (category === 'business' && tab === 'price-volume-trade-off') {
    label = advanced ? `${label}-advanced` : `${label}-basic`
  }

  const mappedSubLabel = subTabMapping[category]?.[label]?.[subTab]
  const subLabel = mappedSubLabel
    ? mappedSubLabel
    : subTab && subTab != ''
    ? subTab
    : null
  if (lastPageViewEvent.category && lastPageViewEvent.time) {
    addToDataLayer({
      event: 'pageviewduration',
      category: lastPageViewEvent.category,
      label: lastPageViewEvent.label ?? undefined,
      subLabel: lastPageViewEvent.subLabel ?? undefined,
      value: getTimeAsSecondsTo2dp(lastPageViewEvent.time),
    })
  }
  if (gameEnabled) {
    lastPageViewEvent = {
      event: 'pageviewduration',
      category,
      label,
      subLabel,
      time: new Date().getTime(),
    }
  } else {
    lastPageViewEvent = {
      event: 'pageviewduration',
      category: null,
      label: null,
      subLabel: null,
      time: null,
    }
  }
  addToDataLayer({
    event: 'pageview',
    category,
  })
}

interface FeatureUsageParams {
  page: Category
  feature: string
  action: string
  value?: string
}

export const reportFeatureUsage = (params: FeatureUsageParams) => {
  addToDataLayer({
    event: 'featureusage',
    category: params.page,
    action: params.action,
    label: params.feature,
    value: params.value,
  })
}
