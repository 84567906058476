const wait = async (duration: number, shouldReject = false): Promise<void> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (shouldReject) {
        reject(new Error('Timeout error'))
      } else {
        resolve()
      }
    }, duration)
  })
}

export default wait
