import classNames from 'classnames'
import React from 'react'
import Clickable from '../Clickable/Clickable'
import HorizontalGroup from '../HorizontalGroup/HorizontalGroup'
import { Icon, IconType } from '../Icon/Icon'
import PrimaryText from '../Text/PrimaryText'

export interface TabType<T> {
  icon?: IconType
  value: T
  label: string
}
export interface Props<T>
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'> {
  className?: string
  tab: TabType<T>
  active: boolean
  onClick: (newTab: T) => void
  id?: string
}

function SecondaryTab<T>({
  className,
  tab,
  active,
  onClick,
  id,
  ...rest
}: Props<T>) {
  return (
    <Clickable
      id={id}
      key={tab.value as string}
      onClick={() => onClick(tab.value)}
    >
      <HorizontalGroup
        {...rest}
        gap={2}
        verticalCenter
        className={classNames(
          'rounded-full border border-primary p-1 hover:rounded-full hover:bg-primary-50 desktop:p-3',
          className,
        )}
      >
        {tab.icon && (
          <Icon
            type={tab.icon}
            colour={active ? 'primary' : 'black'}
            size={6}
          />
        )}
        <PrimaryText
          size="xs"
          weight={active ? 'bold' : 'normal'}
          colour={active ? 'primary' : 'black'}
        >
          {tab.label}
        </PrimaryText>
      </HorizontalGroup>
    </Clickable>
  )
}

export default SecondaryTab
