import { RootState } from '../../../store'

export const selectBranding = (state: RootState) => {
  return {
    buttonBackgroundColour:
      state.brandingPreview.buttonBackgroundColour ??
      state.event?.details?.buttonBackgroundColour,
    headingBackgroundColour:
      state.brandingPreview.headingBackgroundColour ??
      state.event?.details?.headingBackgroundColour,
    mainBackgroundColour:
      state.brandingPreview.mainBackgroundColour ??
      state.event?.details?.mainBackgroundColour,
    headingTextColour:
      state.brandingPreview.headingTextColour ??
      state.event?.details?.headingTextColour,
    mainTextColour:
      state.brandingPreview.mainTextColour ??
      state.event?.details?.mainTextColour,
    buttonTextColour:
      state.brandingPreview.buttonTextColour ??
      state.event?.details?.buttonTextColour,
    eventLogo:
      state.brandingPreview.eventLogo ?? state.event?.details?.eventLogo,
  }
}
