import React, { PropsWithChildren } from 'react'
import { ChartProps } from '../SummaryMetricMapper'
import StackedBarChart from './StackedBarChart'

const CustomersChart: React.FC<PropsWithChildren<ChartProps>> = ({
  children,
  ...rest
}) => {
  return (
    <StackedBarChart
      header={children}
      legend={[]}
      formatter={val => Math.round((val / 1000000) * 10) / 10 + 'm'}
      showTotal
      ignoreDiff
      {...rest}
    />
  )
}

export default CustomersChart
