import React from 'react'

interface RadioInputProps {
  id: string
  name: string
  value: string
  checked: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: () => void
}

const RadioInput: React.FC<RadioInputProps> = ({
  id,
  name,
  value,
  checked,
  onChange,
  onClick,
}) => (
  <input
    type="radio"
    id={id}
    name={name}
    value={value}
    onChange={onChange}
    onClick={onClick}
    checked={checked}
    className="h-5 w-5"
  />
)

export default RadioInput
