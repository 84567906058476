import React from 'react'
import Card from '../../../atoms/Card/Card'
import { percentage } from '../../../../lib/formatters'
import { TreasuryResponse } from '../../../../types/gameApi/treasury'
import { DecisionMap } from '../TreasuryContainer'
import { CapitalManagmentData } from './data'
import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'
import ValueChangerWithInput from '../../../molecules/ValueChanger/ValueChangerWithInput'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'

import MetricCardIconBox from '../../../organisms/MetricCard/MetricCardIconBox'

interface DividensReinvestedProps {
  data: TreasuryResponse
  decisions: DecisionMap
  onChangeValue: (val: Partial<DecisionMap>) => void
  enableChanges: boolean
  capitalManagmentData: CapitalManagmentData
}

const DividensReinvested: React.FC<DividensReinvestedProps> = ({
  decisions,
  enableChanges,
  onChangeValue,
}) => {
  const onDividendsReinvestedChange = (val: number) => {
    onChangeValue({ dividendsReinvested: val })
  }
  return (
    <Card className="px-6 py-3">
      <HorizontalGroup className="h-12" fullWidth between verticalCenter>
        <HorizontalGroup gap={2} verticalCenter>
          <MetricCardIconBox
            size="small"
            iconType={'dividendsReinvested'}
            iconColour={'white'}
            iconSize={10}
            backgroundColour={'orange'}
          />

          <div className="">
            <TranslatedText
              size="base"
              weight="bold"
              center
              value="treasury.screen4.slider1Label"
              default="Dividends Reinvested"
            />
          </div>
        </HorizontalGroup>
        <div>
          <ValueChangerWithInput
            inputSize="small"
            {...{
              modalProps: {
                heading: 'Dividends Reinvested',
              },
              type: 'percentage',

              value: decisions.dividendsReinvested,
              step: 100,
              formatValue: percentage,
              onChange: onDividendsReinvestedChange,
              min: 0,
              max: 2500,
              enableChanges,
              increments: [1, 5],
              buttonIdPrefix: 'tour-dividends-reinvested',
            }}
          />
        </div>
      </HorizontalGroup>
    </Card>
  )
}

export default DividensReinvested
