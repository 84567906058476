import React from 'react'
import FinancialsItem from './FinancialsItem'
import { FinancialSummaryItem } from '../../../../types/gameApi/financials'

interface Props {
  summaryItems: FinancialSummaryItem[]
}

const Page1: React.FC<Props> = ({ summaryItems }) => {
  const numTiles = summaryItems.length
  const tilesPerRow = 3
  const remainder = numTiles % tilesPerRow
  const tilesNeeded = remainder === 0 ? 0 : tilesPerRow - remainder
  const invisibleTiles = Array(tilesNeeded).fill(null)

  return (
    <div className="grid h-full grid-cols-1 gap-4 tablet:grid-cols-2 laptop:grid-cols-3">
      {summaryItems.map((s, index) => (
        <FinancialsItem
          id={index === 0 ? 'tour-metric-tile' : ''}
          index={index}
          key={s.type}
          item={s}
        />
      ))}

      {invisibleTiles.map((_, index) => (
        <div
          key={`invisible-tile-${index}`}
          className="invisible w-80 flex-grow monitor:w-96"
        />
      ))}
    </div>
  )
}

export default Page1
