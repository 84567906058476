import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'
import { Spacing } from '../../../types/theme'
import HorizontalGroup from '../HorizontalGroup/HorizontalGroup'

export interface Props {
  className?: string
  gap?: Spacing
}

function Tabs({ children, className, gap = 1 }: PropsWithChildren<Props>) {
  return (
    <HorizontalGroup gap={gap} className={classNames(className)}>
      {children}
    </HorizontalGroup>
  )
}

export default Tabs
