import { BusinessResponse } from '../../../../../types/gameApi/business'

export const data: BusinessResponse = {
  businesses: [
    {
      history: [
        {
          year: 0,
          price1: 488,
          volume: 162304,
        },
        {
          year: 1,
          price1: 476,
          volume: 182855,
        },
        {
          year: 2,
          price1: 504,
          volume: 211667,
        },
        {
          year: 3,
          price1: 484,
          volume: 221396,
        },
      ],
      id: 1,
      price1Prev: 484,
      price1Now: 484,
      price1NowOriginal: 484,
      slider1Min: 384,
      slider1Max: 584,
      price2Prev: 18,
      price2Now: 18,
      price2NowOriginal: 18,
      slider2Min: 13,
      slider2Max: 23,
      volumePrev: 221396,
      volumeNow: 227794,
      volumeNowOriginal: 227794,
      incomePrev: 10480,
      type: 'HOME_LOANS',
      name: 'HOME LOANS',
      price1Type: 'Interest Rate',
      price1TypeInfoBody:
        'Lower interest rates might attract more customers, but may also mean less income. The reverse is also true',
      price2Type: 'Broker Commissions',
      price2TypeInfoBody:
        'The annual investment aims to simplify broker interactions with your bank. It includes dedicated broker channel support, ongoing process simplification, transparent credit decision-making, clear commission structures, and ongoing education. Increased investment in brokers will raise operating costs. Customers acquired through brokers tend to be price-sensitive, potentially less loyal, and prone to higher loss rates during stressful periods.',
      version: 0,
      updatedBy: null,
    },
    {
      history: [
        {
          year: 0,
          price1: 618,
          volume: 56814,
        },
        {
          year: 1,
          price1: 643,
          volume: 57974,
        },
        {
          year: 2,
          price1: 667,
          volume: 61122,
        },
        {
          year: 3,
          price1: 652,
          volume: 57809,
        },
      ],
      id: 2,
      price1Prev: 652,
      price1Now: 652,
      price1NowOriginal: 652,
      slider1Min: 552,
      slider1Max: 752,
      price2Prev: 1,
      price2Now: 1,
      price2NowOriginal: 1,
      slider2Min: -1,
      slider2Max: 1,
      volumePrev: 57809,
      volumeNow: 59711,
      volumeNowOriginal: 59711,
      incomePrev: 3877,
      type: 'BUSINESS_LOANS',
      name: 'BUSINESS LOANS',
      price1Type: 'Interest Rate',
      price1TypeInfoBody:
        'Lower interest rates might attract more customers, but may also mean less income. The reverse is also true',
      price2Type: 'Lending Criteria',
      price2TypeInfoBody:
        'Tighter lending criteria restricts lending to better quality customers, which may reduce future losses. This may also limit growth. The opposite is true for loose lending criteria.',
      version: 0,
      updatedBy: null,
    },
    {
      history: [
        {
          year: 0,
          price1: 263,
          volume: 116600,
        },
        {
          year: 1,
          price1: 274,
          volume: 125142,
        },
        {
          year: 2,
          price1: 299,
          volume: 135004,
        },
        {
          year: 3,
          price1: 269,
          volume: 149256,
        },
      ],
      id: 3,
      price1Prev: 269,
      price1Now: 269,
      price1NowOriginal: 269,
      slider1Min: 169,
      slider1Max: 369,
      price2Prev: 820,
      price2Now: 820,
      price2NowOriginal: 820,
      slider2Min: 770,
      slider2Max: 870,
      volumePrev: 149256,
      volumeNow: 158211,
      volumeNowOriginal: 158211,
      incomePrev: -3823,
      type: 'DEPOSITS',
      name: 'DEPOSITS',
      price1Type: 'Interest Rate',
      price1TypeInfoBody:
        'Higher interest rates attract more customers as customers often like to leave their money where it is earning them good rates. Paying higher interest rates costs your bank more.',
      price2Type: 'Branches',
      price2TypeInfoBody:
        'Improving customers’ access through a larger network may attract more customers. Each new branch costs 1m to fit-out and 500k p.a. to maintain (leasing). Reducing the size of the physical network reduces ongoing leasing costs, however expect 1 additional customer complaint per branch closed.',
      version: 0,
      updatedBy: null,
    },
    {
      history: [
        {
          year: 0,
          price1: 250,
          volume: 1796523,
        },
        {
          year: 1,
          price1: 275,
          volume: 1675718,
        },
        {
          year: 2,
          price1: 275,
          volume: 1597671,
        },
        {
          year: 3,
          price1: 275,
          volume: 1392344,
        },
      ],
      id: 4,
      price1Prev: 275,
      price1Now: 275,
      price1NowOriginal: 275,
      slider1Min: 175,
      slider1Max: 375,
      price2Prev: 150,
      price2Now: 150,
      price2NowOriginal: 150,
      slider2Min: 100,
      slider2Max: 200,
      volumePrev: 1392344,
      volumeNow: 1307063,
      volumeNowOriginal: 1307063,
      incomePrev: 411,
      type: 'CREDIT_CARDS',
      name: 'CREDIT CARDS',
      price1Type: 'Annual Card Fee',
      price1TypeInfoBody:
        'Lower annual fees attract more customers. Lower fees may generate less income.',
      price2Type: 'Reward Points',
      price2TypeInfoBody:
        'Paying higher reward points attracts more customers however paying higher reward points will increase costs.',
      version: 0,
      updatedBy: null,
    },
  ],
  rates: {
    creditCards: {
      ratePrev: 1235,
      rateNow: 1235,
    },
    cash: {
      ratePrev: 250,
      rateNow: 250,
    },
    wsf: {
      ratePrev: 370,
      rateNow: 372.6614363307182,
    },
  },
  volume: {
    creditCards: {
      volumePrevPrev: 11429.695944066962,
      volumePrev: 10091,
      groupBalanceSheetValue: 10091,
    },
    cash: {
      volumePrevPrev: 13471,
      volumePrev: 14715,
      volumeNow: 0,
    },
    savings: {
      volumePrevPrev: 135004,
      volumePrev: 149256,
    },
    transactions: {
      volumePrevPrev: 45001,
      volumePrev: 49752,
    },
    wsf: {
      volumePrevPrev: 112411,
      volumePrev: 99571,
      volumeNow: 72757,
    },
  },
  interest: {
    cash: {
      interestPrevPrev: 453,
      interestPrev: 378,
    },
    nim: {
      interestPrev: 277,
    },
    returnOnAssets: {
      interestPrev: 534,
    },
    costOfFunds: {
      interestPrev: 262,
    },
  },
}
