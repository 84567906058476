import { createSlice } from '@reduxjs/toolkit'
import { fetchCosts, fetchCostsBackground } from './costsActions'
import APIError from '../../errors/APIError'
import { reset } from '../game/gameSlice'
import { logout } from '../auth/authSlice'
import { CostsResponse } from '../../types/gameApi/costs'
import ThunkCancelledError from '../../errors/ThunkCancelledError'

interface CostsState {
  isLoading: boolean
  isLoaded: boolean
  isLoadingBackground: boolean
  data: {
    [roundId: number]: CostsResponse
  }
  error: Error | APIError | null
  submitError: Error | APIError | null
  requestInProgress: string | null
}

const initialState: CostsState = {
  isLoading: false,
  isLoaded: false,
  isLoadingBackground: false,
  requestInProgress: null,
  data: {},
  error: null,
  submitError: null,
}

const costsSlice = createSlice({
  name: 'costs',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(reset, () => {
        return initialState
      })
      .addCase(logout, () => {
        return initialState
      })
      .addCase(fetchCosts.pending, (state, { meta }) => {
        if (!state.isLoading) {
          state.requestInProgress = meta.requestId
          state.isLoading = true
          state.error = null
        }
      })
      .addCase(fetchCosts.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isLoaded = true
        state.error = null
        state.data[payload.roundId] = payload.costs
        state.requestInProgress = null
      })
      .addCase(fetchCosts.rejected, (state, { payload }) => {
        if (payload instanceof ThunkCancelledError) {
          return
        }
        state.isLoading = false
        state.error = payload ?? null
        state.requestInProgress = null
      })
      .addCase(fetchCostsBackground.pending, (state, { meta }) => {
        if (!state.isLoading && !state.isLoadingBackground) {
          state.requestInProgress = meta.requestId
          state.isLoadingBackground = true
        }
      })
      .addCase(fetchCostsBackground.fulfilled, (state, { payload }) => {
        state.isLoadingBackground = false
        state.data[payload.roundId] = payload.costs
        state.requestInProgress = null
        if (state.error) {
          state.error = null
        }
      })
      .addCase(fetchCostsBackground.rejected, (state, { payload }) => {
        if (payload instanceof ThunkCancelledError) {
          return
        }
        state.isLoadingBackground = false
        state.requestInProgress = null
      })
  },
})

export default costsSlice.reducer
