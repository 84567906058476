import React from 'react'
import Image from '../../atoms/Image/Image'
import { useAppSelector } from '../../../store'
import Swiper from '../../atoms/Swiper/Swiper' // Import your custom Swiper component
import { SwiperSlide } from 'swiper/react'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import LandscapeMobilePage from '../../atoms/LandscapeMobilePage/LandscapeMobilePage'
import Page from '../../atoms/Page/Page'
import { useNavigate } from 'react-router-dom'

const HelpManual = () => {
  const { files } = useAppSelector(state => ({
    totalPages: state.help.totalPages,
    files: state.help.files,
  }))
  const navigate = useNavigate()
  const handleClose = () => {
    navigate('/summary')
  }

  return (
    <Page className="flex h-full items-center justify-center text-center">
      <Swiper enableFractionPagination={true} className="h-full w-full">
        {files.map((file, i) => (
          <SwiperSlide key={i} className="h-full w-full">
            <HorizontalGroup fullHeight fullWidth center verticalCenter>
              <Image src={file} className="h-full w-full object-contain" />
            </HorizontalGroup>
          </SwiperSlide>
        ))}
      </Swiper>

      <LandscapeMobilePage handleClose={handleClose}>
        <HorizontalGroup center verticalCenter fullWidth fullHeight>
          <Swiper enableFractionPagination={true} className="h-full w-full">
            {files.map((file, i) => (
              <SwiperSlide className="h-full w-full" key={i}>
                <HorizontalGroup center fullHeight fullWidth>
                  <Image src={file} className="h-full w-full object-contain" />
                </HorizontalGroup>
              </SwiperSlide>
            ))}
          </Swiper>
        </HorizontalGroup>
      </LandscapeMobilePage>
    </Page>
  )
}

export default HelpManual
