import { createAsyncThunk } from '@reduxjs/toolkit'
import gameAPI from '../../services/gameApi'
import APIError from '../../errors/APIError'
import { RootState } from '../../store'
import {
  ProjectsRequestParams,
  ProjectsResponse,
  ProjectUpdateRequestParams,
} from '../../types/gameApi/projects'
import ThunkCancelledError from '../../errors/ThunkCancelledError'

export const fetchProjects = createAsyncThunk<
  { roundId: number; projects: ProjectsResponse },
  ProjectsRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'projects/fetch',
  async ({ teamId, roundId }, { rejectWithValue, getState, requestId }) => {
    const state = getState()
    try {
      if (state.projects.requestInProgress !== requestId) {
        throw new ThunkCancelledError()
      }
      const projects = await gameAPI.getProjects({ teamId, roundId })

      return { roundId, projects }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)

export const fetchProjectsBackground = createAsyncThunk<
  { roundId: number; projects: ProjectsResponse },
  ProjectsRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'projects/fetchBackground',
  async ({ teamId, roundId }, { rejectWithValue, getState, requestId }) => {
    const state = getState()
    try {
      if (state.projects.requestInProgress !== requestId) {
        throw new ThunkCancelledError()
      }
      const projects = await gameAPI.getProjects({ teamId, roundId })

      return { roundId, projects }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)

export const updateProjectDecision = createAsyncThunk<
  { roundId: number; projectId: number; businessId?: number },
  ProjectUpdateRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'projects/updateDecision',
  async ({ teamId, roundId, projectId, businessId }, { rejectWithValue }) => {
    try {
      await gameAPI.updateProjectDecision({
        teamId,
        roundId,
        projectId,
        businessId,
      })

      return { roundId, projectId, businessId }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)

export const removeProjectDecision = createAsyncThunk<
  { roundId: number },
  ProjectsRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'projects/removeDecision',
  async ({ teamId, roundId }, { rejectWithValue }) => {
    try {
      await gameAPI.deleteProjectDecision({ teamId, roundId })

      return { roundId }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)
