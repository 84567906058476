import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

interface ColumnDimensions {
  width: number
  height: number
  top: number
  left: number
}

interface ColumnInfo {
  id: string
  ref: React.RefObject<HTMLTableCellElement>
}

interface FloatingDivProps {
  id: string
  top: number
  left: number
  width: number
  height: number
}

const FloatingDiv: React.FC<FloatingDivProps> = ({
  id,
  top,
  left,
  width,
  height,
}) => (
  <div
    id={id}
    style={{
      position: 'absolute',
      top: `${top}px`,
      left: `${left}px`,
      width: `${width}px`,
      height: `${height}px`,
      pointerEvents: 'none',
    }}
  />
)

interface ProfitAndLossTableTourWrapperProps {
  children: (props: {
    columnInfo: ColumnInfo[]
    tableRef: React.RefObject<HTMLTableElement>
  }) => React.ReactNode
}

const ProfitAndLossTableTourWrapper: React.FC<
  ProfitAndLossTableTourWrapperProps
> = ({ children }) => {
  const tableRef = useRef<HTMLTableElement>(null)
  const [columnDimensions, setColumnDimensions] = useState<ColumnDimensions[]>(
    [],
  )

  const yearColumnRef = useRef<HTMLTableCellElement>(null)
  const changeColumnRef = useRef<HTMLTableCellElement>(null)

  const columnInfo = useMemo<ColumnInfo[]>(
    () => [
      { id: 'tour-profit-loss-financials', ref: yearColumnRef },
      { id: 'tour-profit-loss-change', ref: changeColumnRef },
    ],
    [],
  )

  const updateDimensions = useCallback(() => {
    if (!tableRef.current) return
    const tableRect = tableRef.current.getBoundingClientRect()

    const newDimensions: ColumnDimensions[] = columnInfo.map(({ ref }) => {
      if (!ref.current) return { width: 0, height: 0, top: 0, left: 0 }

      const columnRect = ref.current.getBoundingClientRect()
      return {
        width: columnRect.width,
        height: tableRect.height - columnRect.top + tableRect.top,
        top: columnRect.top - tableRect.top,
        left: columnRect.left - tableRect.left,
      }
    })

    setColumnDimensions(newDimensions)
  }, [columnInfo])

  useEffect(() => {
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [updateDimensions])

  return (
    <div className="h-full w-full" style={{ position: 'relative' }}>
      {children({ columnInfo, tableRef })}
      {columnInfo.map(({ id }, index) => (
        <FloatingDiv key={id} id={id} {...columnDimensions[index]} />
      ))}
    </div>
  )
}

export default ProfitAndLossTableTourWrapper
