import React from 'react'

import { balmainBank } from './balmainBank'
import { broker } from './broker'
import { dextech } from './dextech'
import { neobank } from './neoBank'
import { offshoreBankConfig } from './offshoreBank'

import { Assumption, DealRoomMathConfig } from './utils'

export const configs: Record<string, DealRoomMathConfig> = {
  'offshore-bank': offshoreBankConfig,
  'regional-bank': balmainBank,
  'home-loan-broker': broker,
  'payments-partner': dextech,
  neobank,
}

export function useDealRoomBidAndRateMapping(
  type: string | undefined,
  assumptions?: ReadonlyArray<Assumption>,
) {
  const maps = React.useMemo(() => {
    const rateToBid = new Map<string, string>()
    const bidToRate = new Map<string, [number, number]>()
    if (!type || !configs[type]) {
      return { rateToBid, bidToRate }
    }
    const { calculate, presetX, presetY } = configs[type]

    const [centerIndexX, centerIndexY] = [
      presetX.length / 2,
      presetY.length / 2,
    ]

    for (let i = 0; i < presetX.length; i++) {
      for (let j = 0; j < presetY.length; j++) {
        const x = presetX[i]
        const y = presetY[j]
        const { result: bid } = calculate({
          argX: x,
          argY: y,
          assumptions,
        })

        rateToBid.set(`${x}-${y}`, bid)
        if (bidToRate.has(bid)) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const [currentX, currentY] = bidToRate.get(bid)!
          const currentIndexX = presetX.indexOf(currentX)
          const currentIndexY = presetX.indexOf(currentY)

          const currentDistance = Math.sqrt(
            Math.pow(currentIndexX - centerIndexX, 2) +
              Math.pow(currentIndexY - centerIndexY, 2),
          )

          const newDistance = Math.sqrt(
            Math.pow(i - centerIndexX, 2) + Math.pow(j - centerIndexY, 2),
          )

          // If there is duplicate values, we store the one closet to the center of the table
          if (newDistance < currentDistance) {
            bidToRate.set(bid, [x, y])
          }
        } else {
          bidToRate.set(bid, [x, y])
        }
      }
    }

    return { bidToRate, rateToBid }
  }, [type, assumptions])

  return maps
}
