import React, { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store'
import Modal from '../../../atoms/Modal/Modal'
import { formatParticipantName } from './formatters'
import {
  acceptCEOTransferRequest,
  fetchParticipants,
} from '../../../../redux/changeCeo/changeCeoActions'
import SecondaryText from '../../../atoms/Text/SecondaryText'

interface Props {}

const WaitingRoomCeoTransferAlert: React.FC<Props> = () => {
  const {
    isLoading,
    isLoaded,
    teamId,
    ceoTransferRequestReceived,
    participants,
    participantId,
  } = useAppSelector(state => ({
    isLoading: state.changeCeo.isLoading,
    isLoaded: state.changeCeo.isLoaded,
    teamId: state.team.id,
    participants: state.changeCeo.participants,
    participantId: state.auth.participantId ?? '',
    ceoTransferRequestReceived: state.changeCeo.ceoTransferRequestReceived,
  }))

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isLoading && !isLoaded && !!teamId && teamId > 0) {
      dispatch(fetchParticipants({ teamId }))
    }
  }, [dispatch, isLoaded, isLoading, teamId])

  const handleNo = useCallback(() => {
    dispatch(
      acceptCEOTransferRequest({
        participantId,
        requestId: ceoTransferRequestReceived?.id ?? '',
        action: 'decline',
      }),
    )
  }, [ceoTransferRequestReceived?.id, dispatch, participantId])

  const handleYes = useCallback(() => {
    dispatch(
      acceptCEOTransferRequest({
        participantId,
        requestId: ceoTransferRequestReceived?.id ?? '',
        action: 'accept',
      }),
    )
  }, [ceoTransferRequestReceived?.id, dispatch, participantId])

  const modalBody = useMemo(() => {
    const participant = participants.find(
      p => p.id === ceoTransferRequestReceived?.from,
    )
    if (participant) {
      return `${formatParticipantName(
        participant,
      )} wants to promote you to CEO.`
    }
    return ''
  }, [ceoTransferRequestReceived?.from, participants])

  if (!modalBody) {
    return null
  }

  return (
    <Modal
      show={!!ceoTransferRequestReceived}
      onClose={() => {
        console.log('modal close')
      }}
      title="Accept CEO transfer"
      onCancel={handleNo}
      cancelButtonText="NO"
      onOk={handleYes}
      okButtonText="YES"
    >
      <div className="pt-4">
        <SecondaryText>{modalBody}</SecondaryText>
        <SecondaryText>Do you accept?</SecondaryText>
      </div>
    </Modal>
  )
}

export default WaitingRoomCeoTransferAlert
