import React, { useCallback } from 'react'
import { Business, ProductType } from '../../../../types/gameApi/business'
import { BusinessDecision, DecisionMap } from '../BusinessesContainer'
import ValueChangerWithPercentageChange from '../../../molecules/ValueChanger/ValueChangerWithPercentageChange'
import { formatPrice1, formatVolume } from '../formatters'
import {
  calculatePercentage,
  renderPercentage,
} from './BusinessPriceVolumeTable'
import PrimaryText from '../../../atoms/Text/PrimaryText'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import Card from '../../../atoms/Card/Card'

import { thousandSeparator } from '../../../../lib/formatters'
import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'

interface BusinessCardProps {
  item: Business
  decisions: DecisionMap
  estimatedIncome: number
  active: boolean
  millionsTranslation: string
  volumeTranslation: string
  estimatedIncomeTranslation: string
  onChangeValue: (
    type: string,
    field: keyof BusinessDecision,
    value: number,
  ) => void
  changeCurrentBusiness: (type: ProductType) => void
  enableChanges: boolean
  idPrefix?: string
}

const BusinessCard: React.FC<BusinessCardProps> = ({
  item,
  decisions,
  active,
  estimatedIncome,
  millionsTranslation,
  volumeTranslation,
  estimatedIncomeTranslation,
  onChangeValue,
  changeCurrentBusiness,
  enableChanges,
  idPrefix,
}) => {
  const handleDecrement = useCallback(
    (priceType: keyof BusinessDecision) => (val: number) => {
      onChangeValue(item.type, priceType, val - 1)
    },
    [onChangeValue, item.type],
  )

  const handleIncrement = useCallback(
    (priceType: keyof BusinessDecision) => (val: number) => {
      onChangeValue(item.type, priceType, val + 1)
    },
    [onChangeValue, item.type],
  )

  const selectBusiness = useCallback(() => {
    if (!active) {
      changeCurrentBusiness(item.type)
    }
  }, [active, changeCurrentBusiness, item.type])

  const handleDecrementVolume = useCallback(
    (priceType: keyof BusinessDecision) => (val: number) => {
      onChangeValue(item.type, priceType, val - item.volumePrev * 0.01)
    },
    [onChangeValue, item.type, item.volumePrev],
  )

  const handleIncrementVolume = useCallback(
    (priceType: keyof BusinessDecision) => (val: number) => {
      onChangeValue(item.type, priceType, val + item.volumePrev * 0.01)
    },
    [onChangeValue, item.type, item.volumePrev],
  )

  return (
    <Card className="py-3 px-4" onClick={selectBusiness} active={active}>
      <VerticalGroup gap={2}>
        <VerticalGroup gap={2} center>
          <PrimaryText size="sm" weight="bold">
            {item.name}
          </PrimaryText>
          <VerticalGroup center>
            <PrimaryText
              size="xs"
              weight="normal"
              colour="gray"
              className="uppercase"
            >
              {estimatedIncomeTranslation} ({millionsTranslation})
            </PrimaryText>

            <VerticalGroup
              gap={1}
              center
              fullWidth
              className="flex items-center"
            >
              <PrimaryText weight="bold">
                <PrimaryText size="sm" weight="bold">
                  {thousandSeparator(parseInt(String(estimatedIncome)))}
                </PrimaryText>
              </PrimaryText>
              <PrimaryText size="sm" weight="bold">
                {renderPercentage(
                  calculatePercentage(
                    estimatedIncome,
                    item.incomePrev,
                    item.type,
                    1,
                  ),
                  item.type,
                )}
              </PrimaryText>
            </VerticalGroup>
          </VerticalGroup>
        </VerticalGroup>

        <HorizontalGroup around fullWidth className="h-16">
          <VerticalGroup center gap={4}>
            <TranslatedText
              colour="gray"
              value="businessDecisions.screen2.priceHeading"
              default="PRICE"
              size="xs"
              transform="upper"
            />
            <VerticalGroup center verticalCenter>
              <ValueChangerWithPercentageChange
                fontBold
                verticalText
                enableChanges={enableChanges}
                value={decisions[item.type].price1Now}
                min={item.slider1Min}
                max={item.slider1Max}
                formatValue={val => formatPrice1(val, item.type)}
                onIncrement={handleIncrement('price1Now')}
                onDecrement={handleDecrement('price1Now')}
              />
            </VerticalGroup>
          </VerticalGroup>

          <VerticalGroup gap={2} center>
            <PrimaryText size="xs" colour="gray">
              {volumeTranslation} ({millionsTranslation})
            </PrimaryText>
            <VerticalGroup center verticalCenter>
              <ValueChangerWithPercentageChange
                fontBold
                verticalText
                enableChanges={enableChanges}
                value={decisions[item.type].volumeNow}
                min={0}
                formatValue={val => formatVolume(val, item.type)}
                percentageChange={() =>
                  renderPercentage(
                    calculatePercentage(
                      decisions[item.type].volumeNow,
                      item.volumePrev,
                      undefined,
                      1,
                    ),
                  )
                }
                onIncrement={handleIncrementVolume('volumeNow')}
                onDecrement={handleDecrementVolume('volumeNow')}
                idPrefix={idPrefix}
              />
            </VerticalGroup>
          </VerticalGroup>
        </HorizontalGroup>
      </VerticalGroup>
    </Card>
  )
}

export default BusinessCard
