import React from 'react'
import { getIconForBusiness } from '../../../../lib/icons'
import { FinancialsResponse } from '../../../../types/gameApi/financials'

import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import ProfitAndLossMetric from './ProfitAndLossMetric'

interface Props {
  financials: FinancialsResponse
}

const ProfitAndLossMetrics: React.FC<Props> = ({ financials }) => {
  const businesses = financials.divisionProfitAndLoss.businesses
  return (
    <VerticalGroup fullWidth full>
      <TranslatedText
        className="pb-1 leading-none"
        weight="bold"
        center
        value="ourFinancials.groupProfitLoss.roeHeader"
        default={'RETURN ON EQUITY BY DIVISION'}
        notResponsive
      />
      <div className="grid w-full grid-cols-2 gap-2 desktop:flex desktop:h-full desktop:flex-col">
        {businesses.map((b, i) => (
          <ProfitAndLossMetric
            id={i === 0 ? 'tour-return-equity-tile' : ''}
            index={i}
            key={b.businessId}
            item={b}
            iconType={getIconForBusiness(b.type)}
          />
        ))}
      </div>
    </VerticalGroup>
  )
}

export default ProfitAndLossMetrics
