import React, { PropsWithChildren } from 'react'
import { ChartProps } from '../SummaryMetricMapper'
import { percentage } from '../../../../../lib/formatters'
import StackedBarChart from './StackedBarChart'

const legend = [
  { title: 'ROE Increase', isCost: false },
  { title: 'ROE Reduction', isCost: true },
]

const ReturnOnEquityChart: React.FC<PropsWithChildren<ChartProps>> = ({
  children,
  ...rest
}) => {
  return (
    <StackedBarChart
      header={children}
      formatter={percentage}
      legend={legend}
      {...rest}
    />
  )
}

export default ReturnOnEquityChart
