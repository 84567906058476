import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'
import { Icon, IconType } from '../../atoms/Icon/Icon'
import { Colour, Spacing } from '../../../types/theme'
import {
  BACKGROUND_COLOUR_CLASSES,
  BACKGROUND_COLOUR_LIGHT_SHADE_CLASSES,
  BACKGROUND_COLOUR_SHADE_CLASSES,
} from '../../../lib/colourClasses'

interface Props {
  className?: string
  iconType: IconType
  iconColour: Colour
  backgroundColour: Colour
  useShades?: boolean
  size?: 'small' | 'large' | 'responsive'
  iconSize?: Spacing
  useLightShades?: boolean
}
const MetricCardIconBox: React.FC<PropsWithChildren<Props>> = ({
  className,
  iconType,
  iconColour,
  backgroundColour,
  useShades = false,
  useLightShades = false,
  size = 'medium',
  iconSize,
}) => {
  const backgroundClass = useLightShades
    ? BACKGROUND_COLOUR_LIGHT_SHADE_CLASSES[backgroundColour]
    : useShades
    ? BACKGROUND_COLOUR_SHADE_CLASSES[backgroundColour]
    : BACKGROUND_COLOUR_CLASSES[backgroundColour]

  return (
    <div
      className={classNames(
        className,
        'flex items-center justify-center rounded-xl',
        {
          'h-12 w-12 p-2': size === 'small',
          'p-3': size === 'large',
          'h-full w-16 laptop:h-20 laptop:w-20': size === 'responsive',
        },
        backgroundClass,
      )}
    >
      <Icon colour={iconColour} type={iconType} size={iconSize} />
    </div>
  )
}

export default MetricCardIconBox
