import { get, put, doDelete, post } from '../../../lib/request'
import { getSummaryData } from './mocks/summary'
import { AssessmentRequest } from '../../../types/gameApi/assessments'
import { BusinessRequestParams } from '../../../types/gameApi/business'
import {
  AcceptEOTransferRequestParams,
  AcceptEOTransferResponse,
  CancelEOTransferRequestParams,
  CancelEOTransferResponse,
  CreateCEOTransferRequestParams,
  CreateCEOTransferResponse,
  GetCEOTransferRequestParams,
  GetCEOTransferResponse,
} from '../../../types/gameApi/ceoTransfer'
import { CostsRequestParams } from '../../../types/gameApi/costs'
import { DealroomRequestParams } from '../../../types/gameApi/dealroom'
import { RegisterDeviceParams } from '../../../types/gameApi/device'
import { ExcoRequestParams } from '../../../types/gameApi/exco'
import { FinancialsRequestParams } from '../../../types/gameApi/financials'
import { PeerInsightsRequestParams } from '../../../types/gameApi/peerInsights'
import { ProjectsRequestParams } from '../../../types/gameApi/projects'
import { SummaryRequestParams } from '../../../types/gameApi/summary'
import {
  RegisterTeamRequestParams,
  RegisterTeamResponse,
  SubmitRoundRequestParams,
  SubmitRoundResponse,
} from '../../../types/gameApi/team'
import { TreasuryRequestParams } from '../../../types/gameApi/treasury'
import { getContentData } from './mocks/content'
import {
  getDeviceData,
  getDeviceStatusData,
  getRegisterDeviceData,
} from './mocks/device'
import { getBusinessData } from './mocks/businesses'
import { getTreasuryData } from './mocks/treasury'
import { getPeerInsightsData } from './mocks/peerInsights'
import { getFinancialsData } from './mocks/financials'
import { getExcoData } from './mocks/exco'
import { getProjectData } from './mocks/projects'
import { getCostsData } from './mocks/costs'
import { getDealData } from './mocks/deals'
import { getAssessmentData } from './mocks/assessments'
import { getNewsData } from './mocks/news'

const HOST = process.env.REACT_APP_GAME_API_URL?.includes('localhost')
  ? process.env.REACT_APP_GAME_API_URL.replace(
      'localhost',
      process.env.REACT_APP_LOCAL_IP ?? 'localhost',
    )
  : process.env.REACT_APP_GAME_API_URL

class GameAPI {
  token: string | null
  gameId: string | null

  constructor() {
    this.token = null
    this.gameId = null
  }

  headers = () => ({
    Authorization: `Bearer ${this.token}`,
  })

  setToken = (token: string | null) => {
    this.token = token
  }

  setGameId = (gameId: string | null) => {
    this.gameId = gameId
  }

  getDevice = async (deviceId: string) => {
    return getDeviceData(deviceId)
  }

  getContent = async () => {
    return getContentData()
  }

  registerDevice = async ({ deviceId }: RegisterDeviceParams) => {
    return getRegisterDeviceData(deviceId)
  }

  getDeviceStatus = async () => {
    return getDeviceStatusData()
  }

  getSummary = async ({ roundId }: SummaryRequestParams) => {
    return getSummaryData(roundId)
  }

  getFinancials = async ({ roundId }: FinancialsRequestParams) => {
    return getFinancialsData(roundId)
  }

  getExco = async ({ roundId }: ExcoRequestParams) => {
    return getExcoData(roundId)
  }

  submitExco = async () => {
    return {}
  }

  getBusinesses = async ({ roundId }: BusinessRequestParams) => {
    return getBusinessData(roundId)
  }

  updateBusinessDecisions = async () => {
    return {}
  }

  getTreasury = async ({ roundId }: TreasuryRequestParams) => {
    return getTreasuryData(roundId)
  }

  updateTreasuryDecisions = async () => {
    return {}
  }

  getCosts = async ({ roundId }: CostsRequestParams) => {
    return getCostsData(roundId)
  }

  updateCostsDecisions = async () => {
    return {}
  }

  getProjects = async ({ roundId }: ProjectsRequestParams) => {
    return getProjectData(roundId)
  }

  updateProjectDecision = async () => {
    return {}
  }

  deleteProjectDecision = async () => {
    return {}
  }

  getPeerInsights = async ({ roundId }: PeerInsightsRequestParams) => {
    return getPeerInsightsData(roundId)
  }

  registerTeam = async ({
    teamId,
    name,
    strategy,
  }: RegisterTeamRequestParams) => {
    const response = await put<RegisterTeamResponse>({
      url: `${HOST}/api/games/${this.gameId}/setup/team`,
      headers: this.headers(),
      body: { teamId, name, strategy },
    })
    return response.data
  }

  submitRound = async ({ teamId, roundId }: SubmitRoundRequestParams) => {
    const response = await put<SubmitRoundResponse>({
      url: `${HOST}/api/games/${this.gameId}/rounds/${roundId}/teams/${teamId}/submit`,
      headers: this.headers(),
      body: {},
    })
    return response.data
  }

  getDealRoom = async ({ roundId }: DealroomRequestParams) => {
    return getDealData(roundId)
  }

  bidOnDeal = async () => {
    return {}
  }

  getAssessment = async ({ roundId }: AssessmentRequest) => {
    return getAssessmentData(roundId)
  }

  submitAnswerForQuestion = async () => {
    return {}
  }

  getCurrentAnwers = async () => {
    return []
  }

  createCEOTransferRequest = async ({
    participantId,
    to,
  }: CreateCEOTransferRequestParams) => {
    const response = await post<CreateCEOTransferResponse>({
      url: `${HOST}/api/games/${this.gameId}/device/${participantId}/ceotransfer`,
      body: { to },
      headers: this.headers(),
    })
    return response.data
  }

  acceptDeclineCEOTransferRequest = async ({
    participantId,
    requestId,
    action,
  }: AcceptEOTransferRequestParams) => {
    const response = await put<AcceptEOTransferResponse>({
      url: `${HOST}/api/games/${this.gameId}/device/${participantId}/ceotransfer/${requestId}`,
      body: { action },
      headers: this.headers(),
    })
    return response.data
  }

  getCEOTransferRequest = async ({
    participantId,
    requestId,
  }: GetCEOTransferRequestParams) => {
    const response = await get<GetCEOTransferResponse>({
      url: `${HOST}/api/games/${this.gameId}/device/${participantId}/ceotransfer/${requestId}`,
      headers: this.headers(),
    })

    return response.data
  }

  cancelCEOTransferRequest = async ({
    participantId,
    requestId,
  }: CancelEOTransferRequestParams) => {
    const response = await doDelete<CancelEOTransferResponse>({
      url: `${HOST}/api/games/${this.gameId}/device/${participantId}/ceotransfer/${requestId}`,
      headers: this.headers(),
    })

    return response.data
  }

  getBreakingNews = async () => {
    return getNewsData()
  }

  ping = async () => {
    const response = await get<any>({
      url: `${HOST}/`,
    })
    if (response.data.ok !== true) {
      throw new Error('Server responded, but status is not ok.')
    }

    return response.data
  }
}

const api = new GameAPI()

export default api
