import { AssessmentResponse } from '../../../../../types/gameApi/assessments'
import { data as round4Data } from './4'

const assessmentMockData: { [roundId: number]: AssessmentResponse } = {
  4: round4Data,
}

export const getAssessmentData = (roundId: number): AssessmentResponse => {
  if (assessmentMockData[roundId]) {
    return assessmentMockData[roundId]
  }
  throw new Error(`No mock assessment data for round ${roundId}`)
}
