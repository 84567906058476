import React, { PropsWithChildren } from 'react'
import ResponsiveText from './ResponsiveText'
import Text, { Props as TextProps } from './Text'

export interface Props extends Omit<TextProps, 'family'> {
  notResponsive?: boolean
}

const SecondaryText: React.FC<PropsWithChildren<Props>> = ({
  notResponsive,
  ...props
}) => {
  if (notResponsive) {
    return <Text {...props} family="secondary" />
  }
  return <ResponsiveText {...props} family="secondary" />
}

export default SecondaryText
