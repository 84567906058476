import React, { useState } from 'react'

import { TreasuryResponse } from '../../../../types/gameApi/treasury'
import { DecisionMap } from '../TreasuryContainer'
import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import { formatPercentage, formatValue } from '../../../../lib/formatters'
import Card from '../../../atoms/Card/Card'
import SecondaryText from '../../../atoms/Text/SecondaryText'

import NumericInput from '../../../atoms/Input/NumericInput'
import InfoPopup from '../../../atoms/InfoPopup/InfoPopup'
import ErrorModal from '../../../atoms/Modal/ErrorModal'
import { CapitalManagmentData } from './data'

interface TreasuryCapitalManagementShareCapitalProps {
  data: TreasuryResponse
  decisions: DecisionMap
  onChangeValue: (val: Partial<DecisionMap>) => void
  enableChanges: boolean
  capitalManagmentData: CapitalManagmentData
}

const TreasuryCapitalManagementShareCapital: React.FC<
  TreasuryCapitalManagementShareCapitalProps
> = ({ decisions, enableChanges, onChangeValue, capitalManagmentData }) => {
  const {
    targetShareCapitalMovement,
    denomination,
    retainedNpat,
    dividendsReinvestedHelpTitle,
    dividendsReinvestedHelpBody,
    dividendsReinvestedValue,
    totalNpatRetained,
    additionalShareIssuesHelpTitle,
    additionalShareIssuesHelpBody,
    additionalShareIssues,
  } = capitalManagmentData
  const [error, setError] = useState<string | null>(null)
  const handleGroupNpatChange = (newGroupNpat: number) => {
    onChangeValue({ groupNpat: newGroupNpat })
  }
  const handleError = (error: string) => {
    setError(error)
  }

  return (
    <div>
      <Card className="p-5">
        <VerticalGroup gap={6}>
          <HorizontalGroup fullWidth verticalCenter center gap={2}>
            <TranslatedText
              size="base"
              weight="bold"
              center
              value="treasury.screen4.table1Header"
              default="SHARE CAPITAL ISSUED"
            />
            <SecondaryText size="xs" colour="gray">
              ({denomination})
            </SecondaryText>
          </HorizontalGroup>
          <VerticalGroup gap={4}>
            <HorizontalGroup between fullWidth>
              <TranslatedText
                size="sm"
                value="treasury.screen4.table1Row1"
                default="TARGET SHARE CAPITAL MOVEMENT"
              />
              <SecondaryText size="sm">
                {formatValue(Number(targetShareCapitalMovement.toFixed(0)))}
              </SecondaryText>
            </HorizontalGroup>
            <div className="w-full border-b"></div>
            <TranslatedText
              size="sm"
              weight="bold"
              value="treasury.screen4.table1Row2"
              default="FUNDED BY"
            />
            <HorizontalGroup verticalCenter between>
              <TranslatedText
                size="sm"
                colour="primary"
                weight="bold"
                whitespace="nowrap"
                value="treasury.screen4.table1Row2"
                default="ESTIMATED GROUP NPAT"
              />
              <div className="w-20">
                <NumericInput
                  id="tour-group-npat"
                  disabled={!enableChanges}
                  size="sm"
                  paddingY={2}
                  value={decisions.groupNpat}
                  onChange={handleGroupNpatChange}
                  type="integer"
                  onError={handleError}
                  validateInput={function (value: number): string | undefined {
                    if (value < -99000 || value > 99000) {
                      return 'Group NPAT must be between -99000 and 99000'
                    }
                    return undefined
                  }}
                />
              </div>
            </HorizontalGroup>
            <HorizontalGroup fullWidth between>
              <HorizontalGroup gap={2}>
                <TranslatedText
                  size="sm"
                  value="treasury.screen4.table1Row4"
                  default="RETAINED NPAT"
                />
              </HorizontalGroup>
              <SecondaryText size="sm">
                {formatValue(Number(retainedNpat.toFixed(0)))}
              </SecondaryText>
            </HorizontalGroup>
            <div className="w-full border-b"></div>
            <HorizontalGroup between>
              <HorizontalGroup gap={2}>
                <TranslatedText
                  size="sm"
                  weight="bold"
                  colour="primary"
                  value="treasury.screen4.table1Row5"
                  default="DIVIDENDS REINVESTED"
                />
                <InfoPopup
                  title={dividendsReinvestedHelpTitle}
                  body={dividendsReinvestedHelpBody}
                  iconSize={4}
                />
              </HorizontalGroup>
              <HorizontalGroup gap={4}>
                <SecondaryText size="sm" colour="primary">
                  {formatPercentage(decisions.dividendsReinvested)}
                </SecondaryText>

                <SecondaryText size="sm">
                  {formatValue(Number(dividendsReinvestedValue.toFixed(0)))}
                </SecondaryText>
              </HorizontalGroup>
            </HorizontalGroup>
            <div className="w-full border-b"></div>
            <HorizontalGroup between>
              <TranslatedText
                size="sm"
                value="treasury.screen4.table1Row6"
                default="TOTAL NPAT RETAINED"
              />
              <SecondaryText size="sm">
                {formatValue(Number(totalNpatRetained.toFixed(0)))}
              </SecondaryText>
            </HorizontalGroup>
            <div className="w-full border-b"></div>
            <HorizontalGroup verticalCenter between>
              <VerticalGroup>
                <TranslatedText
                  size="sm"
                  colour="primary"
                  weight="bold"
                  value="treasury.screen4.table1Row7a"
                  default="ADDITIONAL SHARE ISSUES /"
                />

                <HorizontalGroup verticalCenter gap={2}>
                  <TranslatedText
                    size="sm"
                    colour="primary"
                    weight="bold"
                    value="treasury.screen4.table1Row7b"
                    default="(BUYBACKS)"
                  />
                  <InfoPopup
                    title={additionalShareIssuesHelpTitle}
                    body={additionalShareIssuesHelpBody}
                    iconSize={4}
                  />
                </HorizontalGroup>
              </VerticalGroup>

              <SecondaryText
                id="tour-additional-share-issues"
                size="sm"
                weight="bold"
                colour="primary"
              >
                {formatValue(Number(additionalShareIssues.toFixed(0)))}
              </SecondaryText>
            </HorizontalGroup>
          </VerticalGroup>
        </VerticalGroup>
      </Card>
      {error && (
        <ErrorModal
          heading="Validation Error"
          message={error}
          showModal={!!error}
          onClose={() => setError(null)}
        />
      )}
    </div>
  )
}

export default TreasuryCapitalManagementShareCapital
