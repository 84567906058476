import React, { useMemo } from 'react'
import InAppHelp, { Props as InAppHelpProps } from './InAppHelp'
import { useAppSelector } from '../../../store'

export interface Candidate {
  storageKey: string
  analytics?: InAppHelpProps['analytics']
  steps: InAppHelpProps['steps']
}

interface Props {
  candidates: Candidate[]
  disableOverlayClose?: boolean
  isWarning?: boolean
}

const MultiInAppHelp: React.FC<Props> = ({
  candidates,
  disableOverlayClose = false,
  isWarning = false,
}) => {
  const { eventId, dismissedInAppHelp } = useAppSelector(state => ({
    eventId: state.event.details?.eventId,
    dismissedInAppHelp: state.inAppHelp.dismissedInAppHelp,
  }))

  const currentCandidate = useMemo(() => {
    return candidates.find(
      c => !dismissedInAppHelp[`${eventId}-${c.storageKey}`],
    )
  }, [candidates, dismissedInAppHelp, eventId])

  if (!currentCandidate) {
    return null
  }

  return (
    <InAppHelp
      disableOverlayClose={disableOverlayClose}
      steps={currentCandidate.steps}
      analytics={currentCandidate.analytics}
      storageKey={currentCandidate.storageKey}
      mode="floater"
      isWarning={isWarning}
    />
  )
}

export default MultiInAppHelp
