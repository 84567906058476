import React from 'react'
import {
  AssessmentQuestion,
  ImageField,
} from '../../../../types/gameApi/assessments'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import Image from '../../../atoms/Image/Image'

const HOST = process.env.REACT_APP_ADMIN_API_URL

interface Props {
  question: AssessmentQuestion
  assessmentId: string
}

export const ImageQuestion: React.FC<Props> = ({ question, assessmentId }) => {
  const imageField = question.fields.find(field => field.type === 'image')

  if (!imageField) {
    return null
  }

  const {
    config: { imageId, nickName, imageUrl },
  } = imageField as ImageField

  if (typeof imageId !== 'undefined') {
    return (
      <VerticalGroup verticalCenter center>
        <Image
          // showLoadingPlaceHolder
          alt={nickName}
          src={`${HOST}/api/assessments/${assessmentId}/images/${imageId}`}
        />
      </VerticalGroup>
    )
  }

  return (
    <VerticalGroup verticalCenter center>
      <Image
        // showLoadingPlaceHolder
        alt={nickName}
        src={imageUrl ?? ''}
      />
    </VerticalGroup>
  )
}
