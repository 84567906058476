import React, { useCallback } from 'react'
import classNames from 'classnames'
import { Colour, Spacing } from '../../../types/theme'
import { BACKGROUND_COLOUR_SHADE_CLASSES } from '../../../lib/colourClasses'
import { useCommonClasses } from '../../../hooks/useCommonClasses'

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  centerText?: boolean
  bgColor?: Colour
  fontWeight?: 'bold'
  noPadding?: boolean
  onValueChange?: (value: string, name: string) => void
  onStartInput?: () => void
  onFinishInput?: () => void
  textSize?: 'xs' | 'sm' | 'md' | 'lg'
  hideArrows?: boolean
  padding?: Spacing
}

const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      label,
      centerText,
      bgColor,
      fontWeight,
      noPadding,
      padding,
      onValueChange,
      onStartInput,
      onFinishInput,
      textSize,
      hideArrows,
      ...rest
    },
    ref,
  ) => {
    Input.displayName = 'Input'
    const onChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onValueChange && onValueChange(event.target.value, event.target.name)
      },
      [onValueChange],
    )

    const backgroundClass = bgColor
      ? BACKGROUND_COLOUR_SHADE_CLASSES[bgColor]
      : ''

    const textSizeClass = textSize ? `text-${textSize}` : ''
    const commonClasses = useCommonClasses({ padding })

    return (
      <>
        {label && (
          <label htmlFor={rest.name} className="sr-only">
            {label}
          </label>
        )}
        <input
          {...rest}
          ref={ref}
          data-lpignore="true"
          onChange={onChange}
          className={classNames(
            className,
            commonClasses,
            'relative block w-full appearance-none rounded-md border focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-primary',
            {
              'text-center': centerText,
              [backgroundClass]: backgroundClass,
              'font-bold': fontWeight === 'bold',
              'px-3 py-3': !noPadding && !padding,
              [textSizeClass]: textSizeClass,
              'hide-number-arrows': hideArrows,
              'border-primary-300 bg-primary-50':
                !rest.disabled && !backgroundClass,
              'border-gray-300 bg-lightgray-400': rest.disabled,
            },
          )}
          onClick={onStartInput}
          onKeyUp={event => {
            if (event.key === 'Enter') {
              // @ts-expect-error not sure
              document.activeElement.blur()
              onFinishInput && onFinishInput()
            }
          }}
        />
      </>
    )
  },
)

export default Input
