import React from 'react'
import { Icon } from '../../atoms/Icon/Icon'
import {
  formatValueChange,
  getValueChangeColour,
} from '../../../lib/formatters'
import { Colour, FontFamily, TextColour } from '../../../types/theme'
import PrimaryText, { Props as TextProps } from '../../atoms/Text/PrimaryText'
import SecondaryText from '../../atoms/Text/SecondaryText'

export interface ValueChangeTextProps extends TextProps {
  dp?: number
  value: number
  isCost?: boolean
  changeUnit?: string
  dashIfZero?: boolean
  largeThreshold?: number
  removePlusSign?: boolean
  removeMinusSign?: boolean
  removeArrow?: boolean
  removePercentage?: boolean
  negativeInBrackets?: boolean
  textColour?: TextColour
  iconColour?: Colour
  family?: FontFamily
  prefix?: string
  idPrefix?: string
}

const ValueChangeText: React.FC<ValueChangeTextProps> = ({
  dp = 2,
  value,
  isCost,
  changeUnit,
  dashIfZero,
  largeThreshold,
  removePlusSign,
  removeMinusSign,
  removeArrow,
  removePercentage,
  negativeInBrackets,
  textColour,
  iconColour,
  family,
  prefix,
  idPrefix,
  ...rest
}: ValueChangeTextProps) => {
  let valueChange = ''
  if (
    value === Number.MAX_SAFE_INTEGER ||
    (largeThreshold && Math.abs(value) > largeThreshold)
  ) {
    valueChange = 'LARGE'
  } else {
    valueChange = formatValueChange({
      valueChange: value,
      changeUnit: changeUnit,
      dashIfZero: dashIfZero,
      dp: dp,
      prefix,
    })
    if (removePlusSign) {
      valueChange = valueChange.replace('+', '')
    }
    if (removeMinusSign && value < 0) {
      valueChange = valueChange.replace('-', '')
    }
    if (negativeInBrackets && value < 0) {
      valueChange = `(${valueChange.replace('-', '')})`
    }
    if (removePercentage) {
      valueChange = valueChange.replace('%', '')
    }
  }

  const colourBasedOnChange =
    valueChange === '-'
      ? 'black'
      : getValueChangeColour({ value, isCost: isCost })

  const iconType = value >= 0 ? 'up' : 'down' // Determine which icon to show based on the sign of the value change

  const Component = family === 'secondary' ? SecondaryText : PrimaryText
  return (
    <div
      id={idPrefix ? `${idPrefix}-value` : undefined}
      className="flex items-center gap-x-1"
    >
      {!removeArrow && value != 0 && (
        <Icon
          className="h-4 w-4"
          type={iconType}
          colour={iconColour ?? colourBasedOnChange}
        />
      )}
      {/* Show the icon before the value change text */}
      <Component colour={textColour ?? colourBasedOnChange} {...rest}>
        {valueChange}
      </Component>
    </div>
  )
}

export default ValueChangeText
