import { FinancialsResponse } from '../../../../../types/gameApi/financials'

export const data: FinancialsResponse = {
  denomination: 'AUD $M',
  denominationShort: null,
  fundsMgmtIncomeEnabled: false,
  dealRoomEnabled: true,
  specialProjectsEnabled: true,
  creditCardsEnabled: true,
  institutionalBankingEnabled: false,
  offshoreBankEnabled: false,
  groupBalanceSheet: {
    cash: 15547,
    cashCT: 0,
    fixedCT: 10000,
    savings: 116600,
    wealthCT: 0,
    cet1Ratio: 1060,
    costOfCBF: 750,
    costOfWSF: 361,
    homeLoans: 162304,
    offshoreCT: 0,
    creditCards: 12580,
    fixedAssets: 10702,
    homeLoansCT: 3500,
    investments: 0,
    opRiskRatio: 2000,
    offshoreBank: 0,
    shareCapital: 7528,
    transactions: 38867,
    wealthAssets: 0,
    businessLoans: 56814,
    costOfSavings: 263,
    creditCardsCT: 10000,
    investmentsCT: -1,
    businessLoansCT: 8000,
    retainedEarnings: 8441,
    wholesaleFunding: 86512,
    costOfTransaction: 0,
    centralBankFunding: 0,
    institutionalBanking: 0,
    institutionalBankingCT: 0,
    fixedAssetsCT: 10000,
    wealthAssetsCT: 0,
    offshoreBankCT: 0,
    totalLendingAssets: 231698,
    totalOtherAssets: 26249,
    totalAssets: 257947,
    totalLiabilities: 241979,
    netAssets: 15968,
    totalShareHoldersEquity: 15969,
    cashChange: 200,
    homeLoansChange: 400,
    businessLoansChange: 401,
    creditCardsChange: -352,
    institutionalBankingChange: 0,
    totalLendingAssetsChange: 356,
    fixedAssetsChange: 356,
    wealthAssetsChange: 0,
    offshoreBankChange: 0,
    investmentsChange: 0,
    totalOtherAssetsChange: 263,
    totalAssetsChange: 346,
    transactionsChange: 199,
    savingsChange: 199,
    wholesaleFundingChange: 628,
    centralBankFundingChange: 0,
    totalLiabilitiesChange: 349,
    netAssetsChange: 315,
    retainedEarningsChange: 1086,
    shareCapitalChange: -428,
    totalShareHoldersEquityChange: 316,
    cashCA: 0,
    homeLoansCA: 6021,
    businessLoansCA: 4817,
    creditCardsCA: 1333,
    institutionalBankingCA: 0,
    fixedAssetsCA: 1134,
    wealthAssetsCA: 0,
    offshoreBankCA: 0,
    investmentsCA: 0,
    totalLendingAssetsCA: 12171,
    creditRisk: 13305,
    operationalRisk: 2661,
    totalCapital: 15966,
  },
  groupProfitAndLoss: {
    lie: -483,
    tax: -1417,
    opex: -3016,
    interestIncome: 13210,
    fundsMgmtIncome: 0,
    interestExpense: -6066,
    specialProjects: 0,
    otherBankingIncome: 1080,
    interestOnCashIncome: 416,
    investmentExperience: 0,
    netInterestIncome: 7144,
    netInterestMargin: 294,
    netInterestMarginChange: -3,
    totalIncome: 8224,
    totalOperatingIncome: 8224,
    totalOperatingExpenses: -3016,
    costToIncomeRatio: 3668,
    costToIncomeRatioChange: -27,
    liePercentage: 21,
    liePercentageChange: -1,
    netProfitBeforeTax: 4725,
    netProfitAfterTax: 3308,
    interestIncomeChange: 363,
    interestExpenseChange: 627,
    netInterestIncomeChange: 149,
    otherBankingIncomeChange: 37,
    fundsMgmtIncomeChange: 0,
    totalIncomeChange: 134,
    investmentExperienceChange: 0,
    totalOperatingIncomeChange: 134,
    opexChange: 60,
    specialProjectsChange: 0,
    totalOperatingExpensesChange: 60,
    lieChange: -162,
    netProfitBeforeTaxChange: 214,
    taxChange: 208,
    netProfitAfterTaxChange: 216,
  },
  divisionProfitAndLoss: {
    businesses: [
      {
        lie: -35,
        nim: 145,
        roe: 1517,
        tax: -463,
        ctir: 3289,
        opex: -773,
        lieRate: 2,
        cardFees: 0,
        businessId: 1,
        cardIncome: 223,
        rewardCosts: 0,
        interestIncome: 7768,
        fundsMgmtIncome: 0,
        interestExpense: -5462,
        brokerCommissions: -179,
        institutionalFees: 0,
        businessName: 'HOME LOANS',
        type: 'HOME_LOANS',
        netInterestIncome: 2306,
        totalOtherBankingIncome: 44,
        totalIncome: 2350,
        totalOperatingExpenses: -773,
        netProfitBeforeTax: 1542,
        netProfitAfterTax: 1079,
        interestIncomeChange: 494,
        interestExpenseChange: 575,
        netInterestIncomeChange: 308,
        nimChange: 0,
        lieChange: 294,
        taxChange: 404,
        ctirChange: -61,
        opexChange: 117,
        lieRateChange: 0,
        cardFeesChange: 0,
        businessIdChange: 0,
        cardIncomeChange: 324,
        rewardCostsChange: 0,
        fundsMgmtIncomeChange: 0,
        brokerCommissionsChange: 287,
        institutionalFeesChange: 0,
        totalOtherBankingIncomeChange: 476,
        totalIncomeChange: 311,
        totalOperatingExpensesChange: 117,
        netProfitBeforeTaxChange: 411,
        netProfitAfterTaxChange: 415,
        roeChange: 13,
      },
      {
        lie: -124,
        nim: 296,
        roe: 1482,
        tax: -362,
        ctir: 3471,
        opex: -707,
        lieRate: 22,
        cardFees: 0,
        businessId: 2,
        cardIncome: 390,
        rewardCosts: 0,
        interestIncome: 3443,
        fundsMgmtIncome: 0,
        interestExpense: -1797,
        brokerCommissions: 0,
        institutionalFees: 0,
        businessName: 'BUSINESS LOANS',
        type: 'BUSINESS_LOANS',
        netInterestIncome: 1646,
        totalOtherBankingIncome: 390,
        totalIncome: 2036,
        totalOperatingExpenses: -707,
        netProfitBeforeTax: 1205,
        netProfitAfterTax: 843,
        interestIncomeChange: 452,
        interestExpenseChange: 576,
        netInterestIncomeChange: 319,
        nimChange: 1,
        lieChange: 247,
        taxChange: 432,
        ctirChange: -57,
        opexChange: 158,
        lieRateChange: 0,
        cardFeesChange: 0,
        businessIdChange: 0,
        cardIncomeChange: 290,
        rewardCostsChange: 0,
        fundsMgmtIncomeChange: 0,
        brokerCommissionsChange: 0,
        institutionalFeesChange: 0,
        totalOtherBankingIncomeChange: 290,
        totalIncomeChange: 314,
        totalOperatingExpensesChange: 158,
        netProfitBeforeTaxChange: 414,
        netProfitAfterTaxChange: 407,
        roeChange: 16,
      },
      {
        lie: 0,
        nim: 129,
        roe: 6960,
        tax: -400,
        ctir: 4046,
        opex: -906,
        lieRate: 0,
        cardFees: 0,
        businessId: 3,
        cardIncome: 250,
        rewardCosts: 0,
        interestIncome: 5416,
        fundsMgmtIncome: 0,
        interestExpense: -3427,
        brokerCommissions: 0,
        institutionalFees: 0,
        businessName: 'DEPOSITS',
        type: 'DEPOSITS',
        netInterestIncome: 1989,
        totalOtherBankingIncome: 250,
        totalIncome: 2239,
        totalOperatingExpenses: -906,
        netProfitBeforeTax: 1333,
        netProfitAfterTax: 933,
        interestIncomeChange: 445,
        interestExpenseChange: 590,
        netInterestIncomeChange: 205,
        nimChange: 0,
        lieChange: 0,
        taxChange: 282,
        ctirChange: -41,
        opexChange: 100,
        lieRateChange: 0,
        cardFeesChange: 0,
        businessIdChange: 0,
        cardIncomeChange: 204,
        rewardCostsChange: 0,
        fundsMgmtIncomeChange: 0,
        brokerCommissionsChange: 0,
        institutionalFeesChange: 0,
        totalOtherBankingIncomeChange: 204,
        totalIncomeChange: 205,
        totalOperatingExpensesChange: 100,
        netProfitBeforeTaxChange: 277,
        netProfitAfterTaxChange: 275,
        roeChange: 5,
      },
      {
        lie: -324,
        nim: 939,
        roe: 2860,
        tax: -193,
        ctir: 3945,
        opex: -631,
        lieRate: 253,
        cardFees: 457,
        businessId: 4,
        cardIncome: 453,
        rewardCosts: -515,
        interestIncome: 1582,
        fundsMgmtIncome: 0,
        interestExpense: -379,
        brokerCommissions: 0,
        institutionalFees: 0,
        businessName: 'CREDIT CARDS',
        type: 'CREDIT_CARDS',
        netInterestIncome: 1203,
        totalOtherBankingIncome: 395,
        totalIncome: 1598,
        totalOperatingExpenses: -631,
        netProfitBeforeTax: 643,
        netProfitAfterTax: 450,
        interestIncomeChange: -353,
        interestExpenseChange: -52,
        netInterestIncomeChange: -444,
        nimChange: -9,
        lieChange: -357,
        taxChange: -676,
        ctirChange: 98,
        opexChange: -171,
        lieRateChange: 0,
        cardFeesChange: -358,
        businessIdChange: 0,
        cardIncomeChange: -341,
        rewardCostsChange: -337,
        fundsMgmtIncomeChange: 0,
        brokerCommissionsChange: 0,
        institutionalFeesChange: 0,
        totalOtherBankingIncomeChange: -365,
        totalIncomeChange: -425,
        totalOperatingExpensesChange: -171,
        netProfitBeforeTaxChange: -694,
        netProfitAfterTaxChange: -702,
        roeChange: -74,
      },
    ],
  },
  summary: [
    {
      type: 'NPAT_GROWTH',
      index: 1,
      enabled: true,
      heading: 'NPAT Growth',
      helpBody:
        "\n\nNet Profit After Tax (NPAT) is the profit a company makes after deducting all the expenses and taxes. This amount can be distributed to shareholders. NPAT Growth is the percentage increase or decrease in the company's annual NPAT from one year to the next, expressed as an annualised rate.\n\n",
      helpHeading: 'NPAT Growth',
      value: 210,
      valueChange: 142,
    },
    {
      type: 'NIM',
      index: 2,
      enabled: true,
      heading: 'Net Interest Margin',
      helpBody:
        "\n\nNet Interest Margin (NIM) is a measure of a financial institution's profitability. It calculates the difference between the interest earned on loans and other interest-earning assets, and the interest paid on deposits and other interest-bearing liabilities, and expresses it as a percentage of the total interest-earning assets. A higher NIM indicates that a financial institution is earning more from its interest-earning activities relative to its asset base.\n\n",
      helpHeading: 'NIM%',
      value: 294,
      valueChange: -3,
    },
    {
      type: 'COF',
      index: 3,
      enabled: true,
      heading: 'Cost of Funds',
      helpBody:
        '\n\nCost of Funds (COF) is the cost a bank incurs when it borrows money. It is calculated by dividing the total interest expense paid by the bank by the total amount of money borrowed. This cost is expressed as an annualised percentage and gives an idea of the cost of borrowing for the bank.\n\n',
      helpHeading: 'Cost of Funds',
      value: 255,
      valueChange: 9,
    },
    {
      type: 'CTIR',
      index: 4,
      enabled: true,
      heading: 'Cost To Income Ratio',
      helpBody:
        "\n\nCost To Income Ratio is a financial metric used to evaluate a company's cost efficiency. It compares a company's total operating expenses to its total operating income over a period, typically a year. The ratio is expressed as a percentage, and a lower percentage is better as it indicates that a company is spending less to generate income.\n\n",
      helpHeading: 'Cost To Income Ratio',
      value: 3668,
      valueChange: -27,
    },
    {
      type: 'LIE',
      index: 5,
      enabled: true,
      heading: 'Loan Impairment',
      helpBody:
        '\n\nLoan Impairment Expense (LIE) is the portion of money lent to customers that is expected to not be repaid, mainly because of bad loans. LIE is calculated by dividing the Loan Impairment Expense by the Average Loan Balances and expressed as an annual percentage. It is an important metric for banks and financial institutions to measure the risk and potential losses from loan defaults.\n\n',
      helpHeading: 'Loan Impairment %',
      value: 21,
      valueChange: -1,
    },
    {
      type: 'CET1',
      index: 6,
      enabled: true,
      heading: 'Capital Ratio',
      helpBody:
        "\n\nThe Capital Ratio or Common Equity Tier 1 Ratio (CET1) is a metric used to evaluate a bank's financial strength and its capacity to absorb losses. It is calculated using specific items listed on the bank's balance sheet. The Capital Ratio is expressed as a percentage and is typically reported annually.\n\n",
      helpHeading: 'CET1 Ratio',
      value: 1060,
      valueChange: 0,
    },
    {
      type: 'TSR',
      index: 0,
      enabled: false,
      heading: 'Total Shareholder Return',
      helpBody:
        "\n\nTotal Shareholder Return (TSR) is a way to measure a company's financial performance over time by combining dividends and share price appreciation. It is calculated by dividing the total return by the initial investment and expressing it as an annual percentage. In simpler terms, it shows the return on investment a shareholder has received from holding a company's shares over a period of time.\n\n",
      helpHeading: 'What Is Shareholder Return?',
      value: 1000,
      valueChange: -47,
    },
    {
      type: 'ROE',
      index: 0,
      enabled: false,
      heading: 'Return On Equity',
      helpBody:
        "\n\nReturn on Equity (ROE) is a financial metric that indicates how well a company is utilising the money its shareholders have invested to generate profits. It measures the percentage of profit a company generates for each dollar of shareholder's equity. A higher ROE indicates better profitability, while a lower ROE indicates poorer profitability. ROE is expressed as an annual percentage.\n\n",
      helpHeading: 'What Is Return On Equity?',
      value: 2103,
      valueChange: -3,
    },
    {
      type: 'Complaints',
      index: 0,
      enabled: false,
      heading: 'Complaints',
      helpBody:
        '\n\n\n\nOutstanding customer complaints" are complaints from dissatisfied customers that have not been resolved. To reduce outstanding customer complaints a company can either reduce the cause of complaints or increase the resources used to address complaints. By doing so, the number of outstanding complaints can be reduced.\n\n\n\n',
      helpHeading: 'Outstanding Customer Complaints?',
      value: 700,
      valueChange: 0,
    },
    {
      type: 'EmployeeEngagement',
      index: 0,
      enabled: false,
      heading: 'Employee Engagement',
      helpBody:
        '\n\nEmployee engagement refers to how dedicated and enthusiastic employees are about their jobs and the organization they work for, and how much extra effort they are willing to put in beyond their basic job requirements. It is influenced by the type of work they do, opportunities for career growth, and their compensation. In short, engaged employees are highly motivated and committed to their work and the success of their organisation.\n\n',
      helpHeading: 'What Is Employee Engagement?',
      value: 5000,
      valueChange: 0,
    },
    {
      type: 'RemediationAndFines',
      index: 0,
      enabled: false,
      heading: 'Remediation And Fines',
      helpBody:
        '\n\nRemediation refers to the total cost incurred by a company to correct or compensate for harm caused to its customers due to a breach of standards or expectations. Fines, on the other hand, refer to the total amount of money a company has been required to pay as a penalty to regulatory authorities for violating laws or regulations.\n\n',
      helpHeading: 'What Is Remediation & Fines?',
      value: 0,
      valueChange: 0,
    },
  ],
}
