import React, { useCallback } from 'react'

import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'
// import { FontSize, FontWeight, TextColour } from '../../../../types/theme'
import Table from '../../../atoms/Table/Table'
import TableHeader from '../../../atoms/Table/TableHeader'
import TableSection from '../../../atoms/Table/TableSection'
import TableRow from '../../../atoms/Table/TableRow'
import TableCell from '../../../atoms/Table/TableCell'
import TableHeaderCell from '../../../atoms/Table/TableHeaderCell'
import TableText from '../../../atoms/Table/TableText'

import { Business, ProductType } from '../../../../types/gameApi/business'
import ValueChangerWithPercentageChange from '../../../molecules/ValueChanger/ValueChangerWithPercentageChange'
import { BusinessDecision, DecisionMap } from '../BusinessesContainer'
import { formatPrice1, formatVolume } from '../formatters'
import { TextColour } from '../../../../types/theme'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import { thousandSeparator } from '../../../../lib/formatters'
import TableFooter from '../../../atoms/Table/TableFooter'
import Button from '../../../atoms/Button/Button'

import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { titleCase } from '../../../../lib/text'
import ValueChangerWithInput, {
  ValueChangeInputType,
  ValueChangeModalProps,
} from '../../../molecules/ValueChanger/ValueChangerWithInput'
import { calculateSignedPercentage } from '../utils'

interface Props {
  data: Business[]
  currentBusiness: ProductType
  decisions: DecisionMap
  millionsTranslation: string
  volumeTranslation: string
  estimatedIncomeTranslation: string
  onChangeValue: (
    type: string,
    field: keyof BusinessDecision,
    value: number,
  ) => void
  changeCurrentBusiness: (type: ProductType) => void
  setShowModal: (val: boolean) => void
  enableChanges: boolean
  idPrefix?: string
}

export const calculatePercentage = (
  value: number,
  prevValue: number,
  type?: ProductType,
  numberOfDp = 2,
) => {
  const percentage = calculateSignedPercentage(value, prevValue, type)
  const result = `${percentage.toFixed(numberOfDp)}%`
  if (result === '-0.00%') {
    return '0.00%'
  }
  return result
}

export const renderPercentage = (percentage: string, type?: ProductType) => {
  const isNegative = percentage.indexOf('-') !== -1
  const isZero = percentage === '0.00%' || percentage === '0.0%'

  const negativeColour: TextColour = type === 'DEPOSITS' ? 'success' : 'error'
  const positiveColour: TextColour = type === 'DEPOSITS' ? 'error' : 'success'
  return (
    <SecondaryText
      size="sm"
      className="desktop:text-base"
      colour={isNegative ? negativeColour : positiveColour}
    >
      {!isZero && !isNegative ? '+' : ''}
      {percentage}
    </SecondaryText>
  )
}

export const estimateIncome = (
  price: number,
  volumeNow: number,
  volumePrev: number,
  type: ProductType,
) => {
  const averageVolume = (volumeNow + volumePrev) / 2
  if (type === 'CREDIT_CARDS') {
    return (price * averageVolume) / 1000000
  }
  if (type === 'DEPOSITS') {
    return (price / 100 / 100) * averageVolume * -1
  }
  return (price / 100 / 100) * averageVolume
}

interface RowProps {
  item: Business
  // headings: string[]
  // weight?: FontWeight
  // colour?: TextColour
  // size?: FontSize
  highlight?: boolean
  decisions: DecisionMap
  estimatedIncome: number
  onChangeValue: (
    type: string,
    field: keyof BusinessDecision,
    value: number,
  ) => void
  changeCurrentBusiness: (type: ProductType) => void
  enableChanges: boolean
  modalChanger: ValueChangeModalProps
  idPrefix?: string
}

const Row: React.FC<RowProps> = ({
  item,
  // headings,
  // weight,
  // colour,
  highlight,
  decisions,
  estimatedIncome,
  onChangeValue,
  changeCurrentBusiness,
  enableChanges,
  // idPrefix,
}) => {
  const inputType: ValueChangeInputType =
    item.type === 'CREDIT_CARDS' ? 'dollar' : 'percentage'
  const selectBusiness = useCallback(() => {
    if (!highlight) {
      changeCurrentBusiness(item.type)
    }
  }, [highlight, changeCurrentBusiness, item.type])

  const handleDecrementVolume = useCallback(
    (priceType: keyof BusinessDecision) => (val: number) => {
      onChangeValue(item.type, priceType, val - item.volumePrev * 0.01)
    },
    [onChangeValue, item.type, item.volumePrev],
  )

  const handleIncrementVolume = useCallback(
    (priceType: keyof BusinessDecision) => (val: number) => {
      onChangeValue(item.type, priceType, val + item.volumePrev * 0.01)
    },
    [onChangeValue, item.type, item.volumePrev],
  )
  return (
    <TableRow
      noDivideX
      highlight={highlight}
      className=""
      onClick={selectBusiness}
    >
      <TableCell noRightBorder className="text-center">
        <TableText>{titleCase(item.name)}</TableText>
      </TableCell>

      <TableCell align="center">
        <ValueChangerWithInput
          buttonIdPrefix={`${item.type}-price1Now`}
          previousValue={Number(item.price1Prev)}
          enableChanges={enableChanges} // Pass enableChanges prop
          value={decisions[item.type].price1Now}
          min={item.slider1Min}
          max={item.slider1Max}
          formatValue={val => formatPrice1(val, item.type)}
          onChange={val => onChangeValue(item.type, 'price1Now', val)}
          modalProps={{
            heading: `${titleCase(item.name)}: Price`,
            extraData: undefined,
          }}
          type={inputType}
        />
      </TableCell>
      <TableCell align="center">
        <div className="text-center">
          <ValueChangerWithPercentageChange
            enableChanges={enableChanges}
            value={decisions[item.type].volumeNow}
            min={0}
            formatValue={val => formatVolume(val, item.type)}
            percentageChange={() =>
              renderPercentage(
                calculatePercentage(
                  decisions[item.type].volumeNow,
                  item.volumePrev,
                  undefined,
                  1,
                ),
              )
            }
            onDecrement={handleDecrementVolume('volumeNow')}
            onIncrement={handleIncrementVolume('volumeNow')}
            idPrefix={`${item.type}-volumeNow`}
          />
        </div>
      </TableCell>
      <TableCell align="center">
        <ValueChangerWithPercentageChange
          enableChanges={false} // This one is intentionally set to false
          value={estimatedIncome}
          formatValue={val => thousandSeparator(parseInt(String(val)))}
          percentageChange={() =>
            renderPercentage(
              calculatePercentage(
                estimatedIncome,
                item.incomePrev,
                item.type,
                1,
              ),
              item.type,
            )
          }
          onIncrement={() => undefined}
          onDecrement={() => undefined}
          idPrefix={`${item.type}-estimated-income`}
        />
      </TableCell>
    </TableRow>
  )
}
const BusinessPriceVolumeTable: React.FC<Props> = ({
  data,
  currentBusiness,
  decisions,
  millionsTranslation,
  volumeTranslation,
  estimatedIncomeTranslation,
  onChangeValue,
  changeCurrentBusiness,
  setShowModal,
  enableChanges,
  // idPrefix,
}) => {
  const estimatedIncomes = data.map(it =>
    estimateIncome(
      decisions[it.type].price1Now,
      decisions[it.type].volumeNow,
      it.volumePrev,
      it.type,
    ),
  )
  const totalEstimatedIncome = estimatedIncomes.reduce((result, val) => {
    result += val
    return result
  }, 0)
  const totalPreviousIncome = data.reduce((result, it) => {
    result += it.incomePrev
    return result
  }, 0)

  return (
    <div data-test="business-table" className="w-full">
      <Table className="whitespace-nowrap">
        <TableHeader noDivideX>
          <TableHeaderCell align="left">
            <TranslatedText
              weight="bold"
              value="businessDecisions.screen2.businessHeading"
              default="BUSINESS"
              family="secondary"
              size="xs"
              transform="upper"
            />
          </TableHeaderCell>
          <TableHeaderCell>
            <TranslatedText
              weight="bold"
              value="businessDecisions.screen2.priceHeading"
              default="PRICE"
              family="secondary"
              size="xs"
              transform="upper"
              center
            />
          </TableHeaderCell>
          <TableHeaderCell>
            <HorizontalGroup gap={3} reverseRow verticalCenter>
              {enableChanges && (
                <Button
                  data-test="reset-button"
                  size="xs"
                  rounded="full"
                  buttonType="bordered"
                  iconType="reset"
                  onClick={() => setShowModal(true)}
                ></Button>
              )}
              <TableText weight="bold">
                {volumeTranslation} ({millionsTranslation})
              </TableText>
            </HorizontalGroup>
          </TableHeaderCell>
          <TableHeaderCell>
            <TableText weight="bold">
              {estimatedIncomeTranslation} ({millionsTranslation})
            </TableText>
          </TableHeaderCell>
        </TableHeader>
        <TableSection className=" ">
          {data.map((item, i) => (
            <Row
              key={i}
              idPrefix={`${item.type}-volumeNow`}
              highlight={item.type === currentBusiness}
              item={item}
              decisions={decisions}
              estimatedIncome={estimatedIncomes[i]}
              onChangeValue={onChangeValue}
              changeCurrentBusiness={changeCurrentBusiness}
              enableChanges={enableChanges}
              modalChanger={{
                heading: `${titleCase(item.name)}: Volume`,
              }}
            />
          ))}
        </TableSection>
        <TableSection>
          <TableFooter noDivideX>
            <TableCell noRightBorder colSpan={3}>
              <TableText weight="bold">TOTAL</TableText>
            </TableCell>

            <TableCell align="center">
              <TableText weight="bold">
                <ValueChangerWithPercentageChange
                  fontBold
                  enableChanges={false}
                  value={totalEstimatedIncome}
                  formatValue={val => thousandSeparator(parseInt(String(val)))}
                  percentageChange={() =>
                    renderPercentage(
                      calculatePercentage(
                        totalEstimatedIncome,
                        totalPreviousIncome,
                        undefined,
                        1,
                      ),
                    )
                  }
                  onIncrement={() => undefined}
                  onDecrement={() => undefined}
                />
              </TableText>
            </TableCell>
          </TableFooter>
        </TableSection>
      </Table>
    </div>
  )
}

export default BusinessPriceVolumeTable
