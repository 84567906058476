import { CostsResponse } from '../../../../../types/gameApi/costs'

export const data: CostsResponse = {
  totalPeopleCosts: 1744,
  defaultPayrise: 200,
  divisions: [
    {
      id: 1,
      name: 'IT & OPERATIONS',
      type: 'IT_OPERATIONS',
      enabled: true,
      maxValue: 25,
      minValue: -25,
      costSplit: 3500,
      complaints: -200,
      riskIncidents: -175,
      employeeEngagement: 0,
      customerSatisfaction: 300,
      fteCosts: 212,
      fteNewCosts: 432,
      value_change: 1100,
      value_change_original: 1100,
    },
    {
      id: 2,
      name: 'FRONT LINE',
      type: 'FRONT_LINE',
      enabled: true,
      maxValue: 25,
      minValue: -25,
      costSplit: 4000,
      complaints: -200,
      riskIncidents: 0,
      employeeEngagement: 0,
      customerSatisfaction: 500,
      fteCosts: 106,
      fteNewCosts: 162,
      value_change: 1100,
      value_change_original: 1100,
    },
    {
      id: 3,
      name: 'RISK & COMPLIANCE',
      type: 'RISK_COMPLIANCE',
      enabled: true,
      maxValue: 25,
      minValue: -25,
      costSplit: 1500,
      complaints: 0,
      riskIncidents: -250,
      employeeEngagement: -1000,
      customerSatisfaction: 0,
      fteCosts: 233,
      fteNewCosts: 1081,
      value_change: 1200,
      value_change_original: 1200,
    },
    {
      id: 4,
      name: 'HR & TRAINING',
      type: 'HR_TRAINING',
      enabled: true,
      maxValue: 25,
      minValue: -25,
      costSplit: 1000,
      complaints: 0,
      riskIncidents: 0,
      employeeEngagement: 1500,
      customerSatisfaction: 0,
      fteCosts: 223,
      fteNewCosts: 1189,
      value_change: 1000,
      value_change_original: 1000,
    },
    {
      id: 5,
      name: 'SALARY GROWTH',
      type: 'SALARY_GROWTH',
      enabled: true,
      maxValue: 10,
      minValue: -10,
      costSplit: 1,
      complaints: 0,
      riskIncidents: 0,
      employeeEngagement: 1,
      customerSatisfaction: 0,
      fteCosts: null,
      fteNewCosts: null,
      value_change: 200,
      value_change_original: 200,
    },
  ],
  npsChange: 15,
  npsOpening: 5700,
  npsClosingScore: 6371,
  employeeEngagementChange: 125,
  employeeEngagementOpening: 6342,
  employeeEngagementClosingScore: 6783,
  employeeEngagementSalaryIncrease: 1000,
  employeeEngagementSalaryDecrease: 2000,
  openingRoundEmployeeEngagement: 5000,
  outputGain: 700,
  outputLoss: 1200,
  complaintsChange: 165,
  complaintsOpening: 522,
  complaintsClosing: 358,
  openingRoundRiskIncidents: 750,
  riskIncidentsChange: 150,
  riskIncidentsOpening: 534,
  riskIncidentsClosing: 330,
  communityTrustChange: 0,
  communityTrustChangeSetup: 250,
  communityTrustChangeThreshold: 100,
  communityTrustOpening: 5750,
  communityTrustClosing: 6750,
  regulatorActions: 0,
  regulatorActionsOpening: 0,
  regulatorActionsClosing: 0,
  regulatorImpact: 1500,
  fteChangeDivision1: 317,
  fteChangeDivision2: 724,
  fteChangeDivision3: 135,
  fteChangeDivision4: 78,
  salaryChange: 0,
  version: 9,
  updatedBy: 'ffad0814-282a-4aa9-a6e8-dfc325dc6291',
}
