import React, { useCallback, useMemo } from 'react'
import PrimaryText from '../../atoms/Text/PrimaryText'
import Clickable from '../../atoms/Clickable/Clickable'
import { TextColour } from '../../../types/theme'
import classNames from 'classnames'

export type RoundButtonState = 'curr' | 'prev' | 'next' | 'wrap'

type Props = {
  label: string
  round: number
  state: RoundButtonState
  onButtonPress: (round: number) => void
  className?: string
}

const RoundSelectButton: React.FC<Props> = ({
  label,
  state,
  round,
  onButtonPress,
  className,
}) => {
  const onClick = useCallback(() => {
    if (state === 'prev' || state === 'curr') {
      onButtonPress(round)
    }
  }, [onButtonPress, round, state])

  const colour: TextColour = useMemo(() => {
    if (state === 'curr') return 'primary'
    if (state === 'next' || state === 'wrap') return 'gray'
    return 'black'
  }, [state])

  return (
    <Clickable
      onClick={onClick}
      disabled={state === 'next'}
      className={className}
    >
      <PrimaryText
        className="hidden flex-col items-center laptop:flex"
        colour={colour}
      >
        <PrimaryText size="base" weight={state === 'curr' ? 'bold' : undefined}>
          {label}
        </PrimaryText>
        <div
          className={classNames('mx-auto mt-1 h-0.5 w-5 bg-primary', {
            invisible: state !== 'curr',
          })}
        ></div>
      </PrimaryText>
    </Clickable>
  )
}

export default RoundSelectButton
