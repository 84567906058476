import React, { Fragment, PropsWithChildren } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import SecondaryText from '../Text/SecondaryText'
import Spinner from '../Spinner/Spinner'

interface Props {
  title?: string
  inProgress?: boolean
}

const SelectMenu: React.FC<PropsWithChildren<Props>> = ({
  title,
  inProgress,
  children,
}) => {
  return (
    <Listbox>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">{title}</Listbox.Label>
          <div className="relative h-12 w-44">
            <div className="inline-flex h-12 w-44 flex-grow rounded-md shadow-sm">
              <div className="inline-flex w-32 items-center justify-center gap-x-1.5 rounded-l-md border border-r-0 border-primary bg-white px-2 py-2 text-black shadow-sm">
                {/* <CheckIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" /> */}
                <SecondaryText size="sm">{title}</SecondaryText>
              </div>
              <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md border border-primary bg-white p-2 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2 focus:ring-offset-gray-50">
                <span className="sr-only">{title}</span>
                {!inProgress && (
                  <ChevronDownIcon
                    className="h-5 w-5 text-primary"
                    aria-hidden="true"
                  />
                )}
                {inProgress && <Spinner />}
              </Listbox.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute left-0 z-10 mt-2 w-72 origin-top-left divide-y divide-gray-200 overflow-hidden rounded-md border border-gray-200 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {children}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

export default SelectMenu
