import React, { useState } from 'react'
import Page from '../../atoms/Page/Page'
import logoText from '../../../assets/excelerate-text-logo.png'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import Button from '../../atoms/Button/Button'
import PrimaryText from '../../atoms/Text/PrimaryText'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import { Icon } from '../../atoms/Icon/Icon'
import CentredSpinner from '../../molecules/CentredSpinner/CentredSpinner'
import useAPIRequest from '../../../hooks/useAPIRequest'
import AdminAPI from '../../../services/adminApi'
import GameAPI from '../../../services/gameApi'
import wait from '../../../lib/wait'
import Image from '../../atoms/Image/Image'
import LoginInput from '../Login/LoginInput'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'
const ConnectivityCheck: React.FC = () => {
  const { externalId } = useParams()
  const [isChecking, setIsChecking] = useState(false)
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [hasChecked, setHasChecked] = useState(false)
  const imageUrl =
    'https://images.banksim.co/connectivity/logo-1336fbe3caa8d135895a0fa9b9a47d87.png'

  const [gameApiState, checkGameAPI] = useAPIRequest(GameAPI.ping, null)
  const [adminApiState, checkAdminAPI] = useAPIRequest(AdminAPI.ping, null)
  const [imageLoadError, setImageLoadError] = useState<boolean>(false)
  const [checksStarted, setChecksStarted] = useState(false)
  const [clientDetails, setClientDetails] = useState({
    clientName: '',
    name: '',
    email: '',
  })
  const [emailError, setEmailError] = useState<string | null>(null)
  const handleClientDetailsInput = (value: string, name: string) => {
    setClientDetails(prevState => ({ ...prevState, [name]: value }))
    if (name === 'email') {
      if (!isValidEmail(value)) {
        setEmailError('Please enter a valid email address')
      } else {
        setEmailError(null)
      }
    }
  }
  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }
  const MAX_WAIT_DURATION = 5000

  const timeout = (ms: number) => {
    return new Promise<never>((_, reject) =>
      setTimeout(() => reject(new Error('Timeout')), ms),
    )
  }

  const performConnectivityCheck = async () => {
    setIsChecking(true)
    setChecksStarted(true)

    setCurrentStep(1)
    await Promise.race([checkGameAPI(), timeout(MAX_WAIT_DURATION)])
    await wait(1000)

    setCurrentStep(2)
    await Promise.race([checkAdminAPI(), timeout(MAX_WAIT_DURATION)])
    await wait(1000)

    setCurrentStep(3)
    await wait(1000)

    setIsChecking(false)
    setHasChecked(true)

    const results = {
      name: clientDetails.name,
      email: clientDetails.email,
      clientName: clientDetails.clientName,
      gameApiResult: gameApiState.error ? 'failed' : 'success',
      adminApiResult: adminApiState.error ? 'failed' : 'success',
      imageApiResult: imageLoadError ? 'failed' : 'success',
      externalId: externalId ?? null,
    }

    try {
      await AdminAPI.sendConnectivityResults(results)
    } catch (error) {
      console.error('Failed to send connectivity results:', error)
    }
  }

  return (
    <Page noPadding full>
      <div className="flex h-screen items-center justify-center bg-[#112540] tablet:px-6 desktop:px-8">
        <VerticalGroup className="w-fit p-2" gap={6} center>
          <VerticalGroup center gap={4}>
            <Image
              className="mx-auto w-32"
              src={imageUrl}
              onError={() => setImageLoadError(true)}
            />
            <Image className="mx-auto w-80" src={logoText} />
            {!checksStarted && (
              <>
                {emailError && (
                  <PrimaryText colour="error" className="text-sm">
                    {emailError}
                  </PrimaryText>
                )}
                <LoginInput
                  value={clientDetails.email}
                  onValueChange={(value, name) =>
                    handleClientDetailsInput(value, name)
                  }
                  placeholder="Email"
                  name="email"
                />

                <LoginInput
                  bgColor="white"
                  value={clientDetails.name}
                  onValueChange={(value, name) =>
                    handleClientDetailsInput(value, name)
                  }
                  placeholder="Name"
                  name="name"
                />

                <LoginInput
                  value={clientDetails.clientName}
                  onValueChange={(value, name) =>
                    handleClientDetailsInput(value, name)
                  }
                  placeholder="Client Name"
                  name="clientName"
                />
              </>
            )}
          </VerticalGroup>
          <Button
            full
            className={classNames({
              'cursor-not-allowed opacity-40':
                !clientDetails.clientName ||
                !clientDetails.name ||
                !clientDetails.email ||
                !!emailError ||
                isChecking,
            })}
            disabled={
              !clientDetails.clientName ||
              !clientDetails.name ||
              !clientDetails.email ||
              !!emailError ||
              isChecking
            }
            onClick={() => {
              if (isChecking) {
                setIsChecking(false)
                return
              }
              performConnectivityCheck()
            }}
          >
            Run Checks
          </Button>

          <VerticalGroup className="" fullWidth gap={2}>
            {currentStep >= 1 ? (
              gameApiState.inProgress ? (
                <PrimaryText colour="white">Checking Game API...</PrimaryText>
              ) : gameApiState.error ? (
                <HorizontalGroup
                  className="pr-1"
                  verticalCenter
                  between
                  fullWidth
                >
                  <PrimaryText colour="error">
                    Error: {gameApiState.error.message}
                  </PrimaryText>
                  <Icon className="" type="exclude" colour="error" size={8} />
                </HorizontalGroup>
              ) : (
                <HorizontalGroup gap={2} verticalCenter between>
                  <PrimaryText colour="white">
                    Game API connected successfully!
                  </PrimaryText>
                  <Icon type="uptick" colour="success" size={10} />
                </HorizontalGroup>
              )
            ) : null}

            {currentStep >= 2 ? (
              adminApiState.inProgress ? (
                <PrimaryText colour="white">Checking Admin API...</PrimaryText>
              ) : adminApiState.error ? (
                <HorizontalGroup
                  className="pr-1"
                  verticalCenter
                  between
                  fullWidth
                >
                  <PrimaryText colour="error">
                    Error: {adminApiState.error.message}
                  </PrimaryText>
                  <Icon className="" type="exclude" colour="error" size={8} />
                </HorizontalGroup>
              ) : (
                <HorizontalGroup gap={2} verticalCenter between>
                  <PrimaryText colour="white">
                    Admin API connected successfully!
                  </PrimaryText>
                  <Icon type="uptick" colour="success" size={10} />
                </HorizontalGroup>
              )
            ) : null}

            {currentStep >= 3 ? (
              imageLoadError ? (
                <HorizontalGroup gap={3} verticalCenter between fullWidth>
                  <PrimaryText colour="error">
                    Error: Failed to load image from Image API.
                  </PrimaryText>
                  <Icon className="" type="exclude" colour="error" size={8} />
                </HorizontalGroup>
              ) : (
                <VerticalGroup gap={2} verticalCenter between>
                  <HorizontalGroup gap={4} verticalCenter between fullWidth>
                    <PrimaryText colour="white">
                      Image API accessed successfully!
                    </PrimaryText>
                    <Icon type="uptick" colour="success" size={10} />
                  </HorizontalGroup>
                  <Image
                    hidden
                    className="mx-auto w-32"
                    src={imageUrl}
                    onError={() => setImageLoadError(true)}
                  />
                </VerticalGroup>
              )
            ) : null}
          </VerticalGroup>
          {isChecking ? (
            <CentredSpinner />
          ) : hasChecked ? (
            !gameApiState.error && !adminApiState.error && !imageLoadError ? (
              <VerticalGroup gap={2}>
                <HorizontalGroup
                  fullWidth
                  center
                  className="rounded-xl bg-success p-2"
                >
                  <PrimaryText>All checks completed successfully.</PrimaryText>
                </HorizontalGroup>
                <HorizontalGroup
                  fullWidth
                  center
                  className="bg- rounded-xl p-2"
                >
                  <VerticalGroup gap={1} className="text-center">
                    <PrimaryText colour="white">
                      {'Check your email for a message from Excelerate.'}
                    </PrimaryText>
                  </VerticalGroup>
                </HorizontalGroup>
              </VerticalGroup>
            ) : (
              <HorizontalGroup
                fullWidth
                center
                className="rounded-xl bg-error p-2"
              >
                <PrimaryText colour="black">
                  checks not completed successfully.
                </PrimaryText>
              </HorizontalGroup>
            )
          ) : null}
        </VerticalGroup>
      </div>
    </Page>
  )
}

export default ConnectivityCheck
