import React, { useCallback, useState } from 'react'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import Text from '../../../atoms/Text/Text'
import { Icon } from '../../../atoms/Icon/Icon'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../../store'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import { startTour } from '../../../../redux/guidedTours/guidedToursSlice'
import SubheadingDropDown from '../Components/SubheadingDropDown'
import { changeTab } from '../../../../redux/financials/financialsSlice'
import { useMenuTranslations } from '../../../../hooks/useMenuTranslations'
import Button from '../../../atoms/Button/Button'
interface Props {}
const OurFinancialsHelp: React.FC<Props> = () => {
  const [expandedSubheading, setExpandedSubheading] = useState('')
  const translations = useMenuTranslations()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleOurFinancialsTour = useCallback(() => {
    dispatch(startTour('our-financials'))
    dispatch(changeTab('summary'))
    navigate('/our-financials')
  }, [dispatch, navigate])

  return (
    <VerticalGroup gap={2}>
      <Text center size="lg">
        {translations.ourFinancials} Guide
      </Text>{' '}
      <VerticalGroup gap={6}>
        <HorizontalGroup center>
          <Button
            buttonType="secondary"
            full
            size="xl"
            onClick={handleOurFinancialsTour}
          >
            <Icon type="guide" colour="primary" size={4} />
            <Text colour="primary">Start Walkthrough</Text>
          </Button>
        </HorizontalGroup>
        <VerticalGroup gap={6}>
          <SubheadingDropDown
            title={'FINANCIAL SUMMARY'}
            content={
              'This screen displays key financial metrics for your bank and shows how they are changing.'
            }
            hint={
              "Use this overview to quickly understand your bank's financial performance. Pay attention to metrics as they change and use the Our Financials information to more deeply understand the reasons for these changes. Lastly, develop a plan to address any important issues."
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
          <SubheadingDropDown
            title={'PROFIT & LOSS'}
            content={
              "This screen displays your bank's profit and loss for the previous year/round and the return on equity for each product."
            }
            hint={
              'Review the Profit & Loss for early warning signs. Consider what decisions can be made to improve the Return on Equity of each product.'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
          <SubheadingDropDown
            title={'PROFIT & LOSS DIVISION'}
            content={
              'This screen displays the profit and loss of each product for the previous year/round.'
            }
            hint={
              'Details on financial performance can be found on this screen which are not available elsewhere in the simulation.'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
          <SubheadingDropDown
            title={'BALANCE SHEET'}
            content={
              'This screen shows the Balance Sheet for your entire bank at the end of the previous year/round and how Regulatory Capital (Shareholders’ Funds) is deployed across your bank'
            }
            hint={
              'Monitor your balance sheet strength closely, particularly your cash balance. Also, ensure you have sufficient funding to support the growth in your assets.'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
        </VerticalGroup>
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default OurFinancialsHelp
