import React, { useCallback, useState } from 'react'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import Text from '../../../atoms/Text/Text'
import { Icon } from '../../../atoms/Icon/Icon'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../../store'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import { startTour } from '../../../../redux/guidedTours/guidedToursSlice'
import SubheadingDropDown from '../Components/SubheadingDropDown'
import { useMenuTranslations } from '../../../../hooks/useMenuTranslations'
import Button from '../../../atoms/Button/Button'
import {
  changeTab,
  toggleShowAdvanced,
} from '../../../../redux/businesses/businessSlice'

interface Props {}

const BusinessesHelp: React.FC<Props> = () => {
  const [expandedSubheading, setExpandedSubheading] = useState('')
  const translations = useMenuTranslations()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleBusinessesHelpTour = useCallback(() => {
    dispatch(toggleShowAdvanced(false))
    dispatch(startTour('businesses'))
    navigate('/businesses')
    dispatch(changeTab('decisions'))
  }, [dispatch, navigate])

  return (
    <VerticalGroup gap={2}>
      <Text center size="lg">
        {translations.businesses} Guide
      </Text>{' '}
      <VerticalGroup gap={6}>
        <HorizontalGroup center>
          <Button
            buttonType="secondary"
            full
            size="xl"
            onClick={handleBusinessesHelpTour}
          >
            <Icon type="guide" colour="primary" size={4} />
            <Text colour="primary">Start Walkthrough</Text>
          </Button>
        </HorizontalGroup>
        <VerticalGroup gap={6}>
          <SubheadingDropDown
            title={'BUSINESS DECISIONS'}
            content={
              'This screen contains the core financial products and services offered by your bank. Here you can make pricing and secondary decisions to grow your businesses. Each section contains a primary pricing decision and a secondary decision. Both decisions can be used to attract and retain customers, however not all secondary decisions are visible to your competitors.'
            }
            hint={
              "Aggressive discounting may attract customers quickly but could hurt short-term profitability. Additionally, those customers may not be loyal long term customers. Review your competitors' rates and be ready to adapt your pricing accordingly."
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />

          <SubheadingDropDown
            title={'PRICE & VOLUME TRADE-OFF'}
            content={
              'The purpose of the calculations on this screen is to help you quantify and measure the impact of your business pricing decisions. The pricing decision changes on this screen synchronize with the Business Decisions screen, however you need to adjust the volume estimates yourself based on the decisions you have made. The graphs here show the change in pricing and volumes compared to previous periods.'
            }
            hint={
              'The more accurate your volume forecasts are, the more efficient your funding position will be. This will result in stronger margins.'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />

          <SubheadingDropDown
            title={'PRICE & VOLUME TRADE-OFF (ADVANCED MODE)'}
            content={
              "Advanced Mode is available for those that want a detailed forecast of their bank’s net interest margins.  This view provides a comprehensive view of your bank's interest earning assets and interest bearing liabilities. Use this mode to fine-tune your pricing strategy and achieve desired Net Interest Margin outcomes."
            }
            hint={
              'Pay close attention to the relationship between assets and liabilities. Balancing high-yielding assets with cost-effective funding sources can significantly improve your net interest margin. Watch for opportunities to optimize your product mix and pricing strategies.'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
        </VerticalGroup>
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default BusinessesHelp
