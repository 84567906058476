import React, { useCallback } from 'react'
import InAppHelpContent from '../../organisms/InAppHelp/InAppHelpContent'
import Text from '../../atoms/Text/Text'
import GuidedTour, {
  GuidedTourStep,
} from '../../organisms/InAppHelp/GuidedTour'
import { useAppDispatch, useAppSelector } from '../../../store'
import { finishTour } from '../../../redux/guidedTours/guidedToursSlice'

const ExcoTour: React.FC = () => {
  const dispatch = useAppDispatch()
  const currentTour = useAppSelector(state => state.guidedTours.currentTour)

  const steps: Array<Partial<GuidedTourStep>> = [
    {
      target: '#tour-scenario',
      content: (
        <InAppHelpContent heading="The Scenario">
          <Text left>
            {
              'Carefully read the scenario details. Note the provided facts, any assumptions you are making, and potential risks. Do any assumptions need to be confirmed/explored with your coach?'
            }
          </Text>
        </InAppHelpContent>
      ),
    },

    {
      target: '#tour-exco-decision',
      content: (
        <InAppHelpContent heading={'Options'}>
          <Text left>
            {
              "Review each option thoroughly before deciding. Consider what might go wrong and whether you're comfortable with those outcomes."
            }
          </Text>
        </InAppHelpContent>
      ),
    },
  ]

  const handleCloseTour = useCallback(() => {
    dispatch(finishTour())
  }, [dispatch])

  if (currentTour !== 'exco-decisions') {
    return null
  }

  return <GuidedTour steps={steps} onClose={handleCloseTour} />
}

export default ExcoTour
