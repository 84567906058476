/* eslint-disable react/jsx-key */
import React from 'react'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'

import {
  argRange,
  Args,
  ArgsWithBid,
  DealRoomMathConfig,
  formatDollar,
  PlayerDeterminedText,
} from './utils'

const ARG_X_MIN = 20
const ARG_X_MAX = 30
const ARG_X_STEP = 1
const ARG_Y_MIN = 11
const ARG_Y_MAX = 25
const ARG_Y_STEP = 1

function calculateDextech(input: Args): { result: string; results: string[] }
function calculateDextech(input: ArgsWithBid): {
  result: string
  estimatedReturn: number
  results: string[]
}
function calculateDextech(input: ArgsWithBid | Args) {
  const { getAssumptions } = dextech
  const assumptions = getAssumptions(input).map(a => a.value)

  const Step1 = assumptions[1]
  const Step2 = (Step1 * assumptions[2]) / 100
  const Step3 = Step1 - Step2
  const Step4 = Step3 * (1 - assumptions[4] / 100)
  const Step5 = Step4 * Math.pow(1 + assumptions[5] / 100, 10)
  const Step6 = Step5 * 15
  const Step7 = Step6 / Math.pow(1 + assumptions[7] / 100, 10)
  const Step8 = (Step7 * assumptions[8]) / 100

  const results = [
    formatDollar(Step1),
    formatDollar(Step2),
    formatDollar(Step3),
    formatDollar(Step4, 1),
    formatDollar(Step5),
    formatDollar(Step6),
    formatDollar(Step7),
    formatDollar(Step8),
  ]

  if ('bid' in input) {
    const { bid } = input
    const estimatedReturn = Math.pow(Step6 / bid, 1 / 10) - 1
    return { result: Step8.toFixed(0), estimatedReturn, results }
  }

  return { result: Step8.toFixed(0), results }
}

const defaultArgX = 25
const defaultArgY = 18

export const dextech: DealRoomMathConfig = {
  defaultArgX,
  defaultArgY,
  calculate: calculateDextech,
  presetX: argRange(ARG_X_MIN, ARG_X_MAX, ARG_X_STEP),
  presetY: argRange(ARG_Y_MIN, ARG_Y_MAX, ARG_Y_STEP),
  titleX: 'Estimated Average Growth Rate',
  titleY: 'Required Rate of Return %',
  header1: 'Valuation Range: Growth Rates vs. Required Returns',
  header2: 'DETECH BID CALCULATOR',
  getAssumptions({ argX, argY, assumptions }) {
    return [
      { label: '', denomination: '', value: assumptions?.[0]?.value ?? NaN },
      {
        label: 'Revenue This Year:',
        denomination: 'm',
        value: assumptions?.[1]?.value ?? 100,
        numberFormat: 'integer',
      },
      {
        label: 'Cost To Income Ratio:',
        denomination: '%',
        value: assumptions?.[2]?.value ?? 70,
        numberFormat: 'integer',
      },
      { label: '', denomination: '', value: assumptions?.[3]?.value ?? NaN },

      {
        label: 'Tax Rate:',
        denomination: '%',
        value: assumptions?.[4]?.value ?? 35,
        numberFormat: 'integer',
      },
      {
        label: 'Growth Rate:',
        range: [ARG_X_MIN, ARG_X_MAX],
        denomination: '%',
        value: argX ?? assumptions?.[5]?.value ?? this.defaultArgX,
        numberFormat: 'integer',
        isKeyAssumption1: true,
      },
      {
        label: 'Exit Multiple:',
        denomination: 'x',
        value: assumptions?.[6]?.value ?? 15,
        numberFormat: 'integer',
      },
      {
        label: 'Discount Rate:',
        range: [ARG_Y_MIN, ARG_Y_MAX],
        denomination: '%',
        value: argY ?? assumptions?.[7]?.value ?? this.defaultArgY,
        numberFormat: 'integer',
        isKeyAssumption2: true,
      },
      {
        label: 'Stake Acquired:',
        denomination: '%',
        value: assumptions?.[8]?.value ?? 100,
        numberFormat: 'integer',
      },
    ]
  },
  explanations: [
    { text: 'Revenue (Most Recent Year)' },
    {
      text: 'Calculate Total Operating Expenses',
      subtext: 'Revenue x Cost to Income %',
    },
    {
      text: 'Calculate Net Profit Before Tax',
      subtext: 'Revenue - Operating Expenses',
    },
    {
      text: 'Calculate Net Profit After Tax (NPAT)',
      subtext: 'Net Profit Before Tax x (1 - Tax Rate)',
    },
    {
      text: 'Calculate NPAT in 10 Years Time',
      subtext: (
        <>
          NPAT x (1 + <PlayerDeterminedText>Growth Rate*</PlayerDeterminedText>)
          <sup>^10</sup>
        </>
      ),
    },
    {
      text: 'Calculate Exit Value in Year 10',
      subtext: 'NPAT in Year 10 Exit Multiple (15x)',
    },
    {
      text: 'Calculate Company Value Today',
      subtext: (
        <>
          Yr 10 Value / (1 +{' '}
          <PlayerDeterminedText>Discount Rate*</PlayerDeterminedText>)
          <sup>^10</sup>
        </>
      ),
    },
    {
      text: 'Calculate Value of Stake Acquired',
      subtext: 'Company Value x Stake Acquired',
    },
  ],
  getFormulas(input) {
    const { results } = this.calculate(input)
    const assumptions = (input.assumptions ?? this.getAssumptions(input)).map(
      a => a.value,
    )
    results.unshift('') // Insert an '' at the beginning so that the index aligns
    return [
      <p>{formatDollar(assumptions[1])}</p>,
      <p>
        {results[1]} x ({assumptions[2]}%) = {results[2]}
      </p>,
      <p>
        {results[1]} - {results[2]} = {results[3]}
      </p>,
      <p>
        {results[3]} x (1 - {assumptions[4]})% = {results[4]}
      </p>,
      <p>
        {results[4]} x (1 +{' '}
        <PlayerDeterminedText>{assumptions[5]}%</PlayerDeterminedText>)
        <sup>^10</sup> = {results[5]}
      </p>,
      <p>
        {results[5]} x {assumptions[6]} = {results[6]}
      </p>,
      <p>
        {results[6]} / (1 +{' '}
        <PlayerDeterminedText>{assumptions[7]}%</PlayerDeterminedText>)
        <sup>^10</sup> = {results[7]}
      </p>,
      <p>
        {results[7]} x {assumptions[8]}% = {results[8]}
      </p>,
      <p>
        <strong>{results[8]}</strong>
      </p>,
    ]
  },
  tips: [
    'Decide how much growth will occur over the next 10 years (25% in the example)',
    'Determine the appropriate level of return required for the asset (15% in example)',
    'Double check your calculations / get a coach to sense check',
  ],
  latestFinancials: [
    {
      key: 'Revenue',
      value: '100m',
    },
    {
      key: 'Cost to Income Ratio',
      value: '70%',
    },
    {
      key: 'Tax Rate',
      value: '35%',
    },
    {
      key: 'Average Growth Rate',
      value: '20-30%',
    },
    {
      key: 'Year 10 Exit PE Multiple',
      value: '20-30%',
    },
    {
      key: 'Stake',
      value: '100%',
    },
  ],
  valuation: [
    '1. Calculate Year 10 NPAT',
    '2. Calculate Year 10 Valuation',
    "3. Convert to today's value using:",
    <VerticalGroup center fullWidth className="mt-2 pl-8 pr-8">
      <SecondaryText>Year 10 Valuation</SecondaryText>
      <hr />
      <SecondaryText>
        (1 + Required Return)<sup>10</sup>
      </SecondaryText>
    </VerticalGroup>,
  ],
}
