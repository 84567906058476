import React, { FC, HTMLAttributes, useCallback, useState } from 'react'
import ErrorModal from '../../atoms/Modal/ErrorModal'
import ValueChangerModal, {
  Props as ValueChangerModalProps,
} from './ValueChangerModal'
import ValueChangerButtons, { ButtonSizes } from './ValueChangerButtons'
import classNames from 'classnames'
import { Icon } from '../../atoms/Icon/Icon'
import Clickable from '../../atoms/Clickable/Clickable'

export type ValueChangeInputType =
  | 'percentage'
  | 'dollar'
  | 'integer'
  | 'decimal'

export type ValueChangeModalProps = {
  heading: string
  extraData?: any
}
export type InputSize = 'small' | 'large' | 'full'
export type Props = {
  modalProps: ValueChangeModalProps
  type: ValueChangeInputType
  value: number
  previousValue?: number
  step?: number
  formatValue: (value: number) => string
  onChange: (value: number) => void
  min?: number
  max?: number
  enableChanges: boolean
  increments?: number[]
  incrementFormatter?: (val: number) => string
  inputSize?: InputSize
  tableText?: boolean
  CustomModal?: React.FC<ValueChangerModalProps>
  hideButtons?: boolean
  showEditIcon?: boolean
  modalButtonSize?: ButtonSizes
  buttonSize?: ButtonSizes
  buttonIdPrefix?: string
} & Omit<HTMLAttributes<HTMLDivElement>, 'onChange'>

const ValueChangerWithInput: FC<Props> = ({
  modalProps,
  type,
  value,
  previousValue,
  step,
  formatValue,
  onChange,
  min,
  max,
  enableChanges,
  increments,
  inputSize = 'small',
  tableText = false,
  CustomModal,
  hideButtons,
  showEditIcon,
  incrementFormatter,
  modalButtonSize,
  buttonSize,
  buttonIdPrefix,
  ...rest
}) => {
  const [error, setError] = useState<string | null>()
  const [showInputModal, setShowInputModal] = useState(false)

  const validateInput = useCallback(
    (input: number) => {
      if ((min != null && input < min) || (max != null && input > max)) {
        return `Input must be between ${formatValue(
          min ?? 0,
        )} and ${formatValue(max ?? 0)}`
      }
    },
    [formatValue, max, min],
  )
  const inputSizeClasses = {
    small: 'w-20',
    large: 'w-32 py-1',
    full: 'w-full',
  }
  return (
    <ValueChangerButtons
      value={value}
      onChange={onChange}
      min={min}
      max={max}
      enableChanges={enableChanges}
      step={step}
      hideButtons={hideButtons}
      buttonSize={buttonSize}
      idPrefix={buttonIdPrefix}
    >
      {error && (
        <ErrorModal
          showModal={!!error}
          heading="Invalid Input"
          message={error}
          onClose={() => setError(null)}
        />
      )}
      {showInputModal &&
        (CustomModal ? (
          <CustomModal
            heading={modalProps.heading}
            onClose={() => setShowInputModal(false)}
            type={type}
            value={value}
            previousValue={previousValue}
            onChange={onChange}
            min={min}
            max={max}
            step={step}
            enableChanges={enableChanges}
            increments={increments}
            incrementFormatter={incrementFormatter}
            // onError={setError}
            validateInput={validateInput}
            formatValue={formatValue}
            extraData={modalProps.extraData}
          />
        ) : (
          <ValueChangerModal
            heading={modalProps.heading}
            onClose={() => setShowInputModal(false)}
            type={type}
            value={value}
            previousValue={previousValue}
            onChange={onChange}
            min={min}
            max={max}
            step={step}
            enableChanges={enableChanges}
            increments={increments}
            incrementFormatter={incrementFormatter}
            // onError={setError}
            validateInput={validateInput}
            formatValue={formatValue}
            extraData={modalProps.extraData}
            buttonSize={modalButtonSize}
          />
        ))}

      {!showEditIcon && (
        <div
          id={buttonIdPrefix ? `${buttonIdPrefix}-input` : undefined}
          {...rest}
          className={classNames(
            `flex items-center justify-center px-6 font-secondary tablet:py-1 ${inputSizeClasses[inputSize]} appearance-none rounded-md border text-center font-bold focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-primary-500 tablet:text-sm`,
            {
              'cursor-pointer': enableChanges,
              'border-primary-300 bg-primary-50': enableChanges,
              'border-gray-300 bg-lightgray-300': !enableChanges,
              'text-xs': tableText,
              'laptop:text-base': !tableText,
            },
          )}
          onClick={() => {
            if (enableChanges) {
              setShowInputModal(true)
            }
          }}
        >
          {formatValue(value)}
        </div>
      )}
      {showEditIcon && (
        <Clickable
          onClick={() => {
            if (enableChanges) {
              setShowInputModal(true)
            }
          }}
        >
          <Icon type="pencil"></Icon>
        </Clickable>
      )}
    </ValueChangerButtons>
  )
}

export default ValueChangerWithInput
