import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { reset } from '../game/gameSlice'
import { logout } from '../auth/authSlice'
import { SummaryType } from '../../types/gameApi/summary'
import { FinancialSummaryItemType } from '../../types/gameApi/financials'

interface DemoState {
  enabled: boolean
  enabledSummaryMetrics: SummaryType[]
  enabledFinancialMetrics: FinancialSummaryItemType[]
  wsfMethod: number
  capitalMethod: number
  enabledImpacts: number[]
}

const initialState: DemoState = {
  enabled: process.env.REACT_APP_DEMO_MODE === 'true',
  enabledSummaryMetrics: ['TSR', 'NPS', 'ROE', 'Customers', 'LIE', 'Rating'],
  enabledFinancialMetrics: ['NPAT_GROWTH', 'NIM', 'COF', 'CTIR', 'LIE', 'CET1'],
  wsfMethod: 1,
  capitalMethod: 1,
  enabledImpacts: [1, 2, 3, 4, 5],
}

const demoSlice = createSlice({
  name: 'demo',
  initialState,
  reducers: {
    setEnabledSummaryMetrics: (state, action: PayloadAction<SummaryType[]>) => {
      state.enabledSummaryMetrics = action.payload
    },
    setEnabledFinancialyMetrics: (
      state,
      action: PayloadAction<FinancialSummaryItemType[]>,
    ) => {
      state.enabledFinancialMetrics = action.payload
    },
    setWsfMethod: (state, action: PayloadAction<number>) => {
      state.wsfMethod = action.payload
    },
    setCapitalMethod: (state, action: PayloadAction<number>) => {
      state.capitalMethod = action.payload
    },
    setEnabledImpacts: (state, action: PayloadAction<number[]>) => {
      state.enabledImpacts = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(reset, () => {
        return initialState
      })
      .addCase(logout, () => {
        return initialState
      })
  },
})

export const {
  setEnabledSummaryMetrics,
  setEnabledFinancialyMetrics,
  setWsfMethod,
  setCapitalMethod,
  setEnabledImpacts,
} = demoSlice.actions

export default demoSlice.reducer
