import React, { useMemo } from 'react'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import Button, { ButtonType } from '../../atoms/Button/Button'
import classNames from 'classnames'
import PrimaryText from '../../atoms/Text/PrimaryText'
import Spinner from '../../atoms/Spinner/Spinner'

interface ProjectSelectorProps {
  canSelect: boolean
  projectStatus: 'selected' | 'inProgress' | 'completed' | null
  isSubmitting: boolean
  onHandleSelectClick: () => void
  onHandleViewAllProjectsClick: () => void
  className?: string
  currentRound: number
  roundId: number
}

const ProjectSelector: React.FC<ProjectSelectorProps> = ({
  canSelect,
  projectStatus,
  isSubmitting,
  onHandleSelectClick,
  onHandleViewAllProjectsClick,
  className,
  currentRound,
  roundId,
}) => {
  const isHistoricalView = roundId < currentRound

  const buttonConfig = useMemo(() => {
    const getLabel = () => {
      switch (projectStatus) {
        case 'completed':
          return 'COMPLETED'
        case 'inProgress':
          return 'IN PROGRESS'
        case 'selected':
          return 'UNSELECT'
        default:
          return 'SELECT'
      }
    }

    const getType = (): ButtonType => {
      switch (projectStatus) {
        case 'completed':
          return 'success'
        case 'inProgress':
          return 'orange'
        case 'selected':
          return isHistoricalView ? 'primary' : 'error'
        default:
          return 'primary'
      }
    }

    return {
      label: getLabel(),
      type: getType(),
    }
  }, [projectStatus, isHistoricalView])

  return (
    <>
      <div className={classNames('h-28', className)}></div>
      <HorizontalGroup
        gap={2}
        fullWidth
        verticalCenter
        className="fixed bottom-0 flex h-20 justify-around bg-white px-2 laptop:bg-gray-100"
      >
        {isSubmitting && (
          <Button
            disabled
            className="block flex-grow laptop:flex-grow-0"
            rounded="full"
            size="large"
            buttonType="primary"
          >
            <Spinner />
          </Button>
        )}
        {canSelect && (
          <Button
            id="tour-select-project"
            disabled={
              projectStatus == 'completed' ||
              isHistoricalView ||
              projectStatus === 'inProgress'
            }
            className="block flex-grow laptop:flex-grow-0"
            rounded="full"
            size="large"
            buttonType={buttonConfig.type}
            onClick={onHandleSelectClick}
          >
            <PrimaryText weight="bold" size="lg">
              {buttonConfig.label}
            </PrimaryText>
          </Button>
        )}
        <PrimaryText size="sm">
          <Button
            className="laptop:hidden"
            rounded="full"
            buttonType="secondary"
            size="large"
            text="View All Projects"
            onClick={onHandleViewAllProjectsClick}
          ></Button>
        </PrimaryText>
      </HorizontalGroup>
    </>
  )
}

export default ProjectSelector
