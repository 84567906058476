import React, { useCallback, useEffect, useMemo } from 'react'
import { getIconForBusiness } from '../../../../lib/icons'
import { titleCase } from '../../../../lib/text'
import {
  changeBusiness,
  PeerInsightsBusinessTabs,
} from '../../../../redux/peer-insights/peerInsightsSlice'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { ProductType } from '../../../../types/gameApi/business'
import { PeerInsightsMarketShare } from '../../../../types/gameApi/peerInsights'
import { BottomTabType } from '../../../atoms/BottomTabs/BottomTab'
import BottomTabs from '../../../atoms/BottomTabs/BottomTabs'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import SecondaryTab from '../../../atoms/Tabs/SecondaryTab'
import Tabs from '../../../atoms/Tabs/Tabs'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import MarketShareTable from './MarketShareTable'
import { useSearchParams } from 'react-router-dom'
// import PeerInsightsTour from '../../../organisms/JoyRide/PeerInsightsTour'

// import MarketShareTable from './MarketShareTable'

interface Props {
  marketShare: PeerInsightsMarketShare[]
  teamId: number
}

const MarketShare: React.FC<Props> = ({ marketShare, teamId }) => {
  const { currentBusiness } = useAppSelector(state => ({
    currentBusiness: state.peerInsights.currentBusiness,
  }))

  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const setBusiness = useCallback(
    (tab: PeerInsightsBusinessTabs) => {
      dispatch(changeBusiness(tab))
      setSearchParams({ tab: 'market-share', subtab: tab })
    },
    [dispatch, setSearchParams],
  )

  const activeBusinessData = useMemo(
    () => marketShare.find(({ type }) => type === currentBusiness),
    [currentBusiness, marketShare],
  )

  const marketShareTabs: BottomTabType<ProductType>[] = marketShare.map(ms => ({
    label: titleCase(ms.name),
    value: ms.type,
    icon: getIconForBusiness(ms.type),
  }))

  useEffect(() => {
    const querySubTab = searchParams.get('subtab')
    if (querySubTab && marketShareTabs.some(tab => tab.value === querySubTab)) {
      dispatch(changeBusiness(querySubTab as PeerInsightsBusinessTabs))
    }
  }, [searchParams, dispatch, marketShareTabs])

  return (
    <VerticalGroup>
      <Tabs>
        <HorizontalGroup fullWidth between className="hidden p-2 laptop:flex">
          {marketShareTabs.map((t, index) => (
            <SecondaryTab
              id={index === 0 ? 'tour-product' : ''}
              key={t.value as string}
              tab={t}
              onClick={() => setBusiness(t.value)}
              active={currentBusiness === t.value}
              data-active={currentBusiness === t.value}
              data-test-tabs={t.value}
            />
          ))}
        </HorizontalGroup>
      </Tabs>

      <HorizontalGroup className="pb-4" fullWidth>
        {activeBusinessData && (
          <MarketShareTable data={activeBusinessData} teamId={teamId} />
        )}
      </HorizontalGroup>
      <div>
        <BottomTabs
          className="laptop:hidden"
          tabs={marketShareTabs}
          selectedTab={currentBusiness}
          onChange={setBusiness}
        />
      </div>
    </VerticalGroup>
  )
}

export default MarketShare
