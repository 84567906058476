import React, { useCallback, useState } from 'react'
import { TreasuryResponse } from '../../../../types/gameApi/treasury'
import { DecisionMap } from '../TreasuryContainer'
import { useTranslatedText } from '../../../../hooks/useTranslatedText'
import TreasuryRiskWeightedAssetsTable from './TreasuryRiskWeightedAssetsTable.tsx'
import {
  AssetOrDeductionItem,
  TreasuryRiskWeightedAssetsTranslations,
} from './types'
import { useGetData } from './data'
import HideIfMobile from '../../../atoms/HideIfMobile/HideIfMobile'
import ShowIfMobile from '../../../atoms/HideIfMobile/ShowIfMobile'
import TreasuryRiskWeightedAssetsCards from './TreasuryRiskWeightedAssetsCards'
import {
  calculateForecastValues,
  calculateTotalOpeningBalance,
} from '../calculations'
import Modal from '../../../atoms/Modal/Modal'

interface Props {
  data: TreasuryResponse
  decisions: DecisionMap
  enableChanges: boolean
  onChangeValue: (val: Partial<DecisionMap>) => void
}

const TreasuryRiskWeightedAssets: React.FC<Props> = ({
  data,
  decisions,
  enableChanges,
  onChangeValue,
}) => {
  const percentageTranslation = useTranslatedText('general.percentage', '%')
  const forecastRiskWeightedTranslation = useTranslatedText(
    'treasury.screen3.table1Column1Header',
    'FORECAST RISK WEIGHTED ASSETS',
  )
  const currentBalanceTranslation = useTranslatedText(
    'treasury.screen3.table1Column2Header',
    'CURRENT\nBALANCE',
  )
  const growthTranslation = useTranslatedText(
    'treasury.screen3.table1Column3Header',
    'GROWTH',
  )
  const forecastBalanceTranslation = useTranslatedText(
    'treasury.screen3.table1Column4Header',
    'FORECAST\nBALANCE',
  )
  const riskWeightTranslation = useTranslatedText(
    'treasury.screen3.table1Column5Header',
    'RISK WEIGHT',
  )
  const riskWeightedAssetsTranslation = useTranslatedText(
    'treasury.screen3.table1Column6Header',
    'RISK WEIGHTED\nASSETS',
  )

  const forecastCapitalDeductionsTranslation = useTranslatedText(
    'treasury.screen3.table2Column1Header',
    'FORECAST CAPITAL DEDUCTIONS',
  )
  const deductionsCurrentBalanceTranslation = useTranslatedText(
    'treasury.screen3.table2Column2Header',
    'CURRENT\nBALANCE',
  )
  const deductionsGrowthTranslation = useTranslatedText(
    'treasury.screen3.table2Column3Header',
    'GROWTH',
  )
  const deductionsForecastBalanceTranslation = useTranslatedText(
    'treasury.screen3.table2Column4Header',
    'FORECAST\nBALANCE',
  )
  const forecastDeductionsTranslation = useTranslatedText(
    'treasury.screen3.table2Column5Header',
    'FORECAST\nDEDUCTIONS',
  )

  const translations: TreasuryRiskWeightedAssetsTranslations = {
    percentage: percentageTranslation,
    assets: {
      forecastRiskWeightedAssets: forecastRiskWeightedTranslation,
      currentBalance: currentBalanceTranslation,
      growth: growthTranslation,
      forecastBalance: forecastBalanceTranslation,
      riskWeight: riskWeightTranslation,
      riskWeightedAssets: riskWeightedAssetsTranslation,
    },
    deductions: {
      forecastCapitalDeductions: forecastCapitalDeductionsTranslation,
      currentBalance: deductionsCurrentBalanceTranslation,
      growth: deductionsGrowthTranslation,
      forecastBalance: deductionsForecastBalanceTranslation,
      forecastDeductions: forecastDeductionsTranslation,
    },
  }

  const onGrowthChange = useCallback(
    ({
      growthField,
      value,
    }: {
      growthField: keyof DecisionMap
      value: number
    }) => {
      const valuesToChange: Partial<DecisionMap> = { [growthField]: value }
      if (growthField.endsWith('Growth')) {
        const { totalForecast } = calculateForecastValues(
          false,
          data.openingBalances,
          {
            ...decisions,
            [growthField]: value,
          },
        )
        const totalOpeningBalance = calculateTotalOpeningBalance(
          data.openingBalances,
        )

        const totalAssetGrowth = parseInt(
          // @ts-expect-error it's fine
          ((totalForecast / totalOpeningBalance - 1) * 100).toFixed(0) * 100,
        )
        valuesToChange.allOtherAssetsPercentage = totalAssetGrowth
      }
      onChangeValue(valuesToChange)
      // const { totalForecast } = this.calculateAssetForecast(false, this.tempType, val)
      // const totalOpeningBalance = this.calculateTotalOpeningBalance()
      // const totalAssetGrowth = parseInt((((totalForecast / totalOpeningBalance) - 1) * 100).toFixed(0) * 100)
      // valuesToChange.allOtherAssetsPercentage = totalAssetGrowth
    },
    [data.openingBalances, decisions, onChangeValue],
  )

  const { assets, deductions, riskWeightedAssets, forecastDeductions } =
    useGetData({ data, decisions })

  const [showResetModal, setShowResetModal] = useState(false)

  const handleCancelReset = useCallback(() => {
    setShowResetModal(false)
  }, [])

  const handleConfirmReset = useCallback(() => {
    setShowResetModal(false)
    const updatedDecisions: Partial<DecisionMap> = {}
    assets.forEach((item: AssetOrDeductionItem) => {
      updatedDecisions[item.riskField] = item.riskFieldOriginal as any
    })
    updatedDecisions.opexRisk = data.opexRiskOriginal
    onChangeValue(updatedDecisions)
  }, [assets, data, onChangeValue])

  const handleOpenResetModal = useCallback(() => {
    setShowResetModal(true)
  }, [])

  return (
    <>
      <Modal
        show={showResetModal}
        onClose={handleCancelReset}
        title="Reset Risk Weight?"
        onOk={handleConfirmReset}
        onCancel={handleCancelReset}
        okButtonText="Yes"
        cancelButtonText="No"
      >
        Are you sure you want to reset the Risk Weights?
      </Modal>
      <HideIfMobile>
        <TreasuryRiskWeightedAssetsTable
          data={data}
          translations={translations}
          assets={assets}
          enableChanges={enableChanges}
          deductions={deductions}
          riskWeightedAssets={riskWeightedAssets}
          forecastDeductions={forecastDeductions}
          decisions={decisions}
          onGrowthChange={onGrowthChange}
          onChangeValue={onChangeValue}
          handleOpenResetModal={handleOpenResetModal}
        />
      </HideIfMobile>
      <ShowIfMobile className="">
        <div className="w-full">
          <TreasuryRiskWeightedAssetsCards
            data={data}
            translations={translations}
            assets={assets}
            enableChanges={enableChanges}
            deductions={deductions}
            riskWeightedAssets={riskWeightedAssets}
            forecastDeductions={forecastDeductions}
            decisions={decisions}
            onGrowthChange={onGrowthChange}
            onChangeValue={onChangeValue}
            handleOpenResetModal={handleOpenResetModal}
          />
        </div>
      </ShowIfMobile>
    </>
  )
}

export default TreasuryRiskWeightedAssets
