import React, { useCallback, useMemo } from 'react'
import InAppHelpContent from '../../../organisms/InAppHelp/InAppHelpContent'
import Text from '../../../atoms/Text/Text'
import GuidedTour, {
  GuidedTourStep,
} from '../../../organisms/InAppHelp/GuidedTour'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { finishTour } from '../../../../redux/guidedTours/guidedToursSlice'
import { FinancialsTabs } from '../../../../redux/financials/financialsSlice'
import { formatValue, percentage } from '../../../../lib/formatters'
import { useTranslatedText } from '../../../../hooks/useTranslatedText'

interface Props {
  setTab: (tab: FinancialsTabs) => void
}
const OurFinancialsTour: React.FC<Props> = ({ setTab }) => {
  const dispatch = useAppDispatch()
  const currentTour = useAppSelector(state => state.guidedTours.currentTour)
  const { financials, roundId } = useAppSelector(state => ({
    financials: state.financials.financials,
    roundId: state.game.selectedRound,
  }))
  const data = financials[roundId].groupBalanceSheet
  const deductionText = useTranslatedText(
    'ourFinancials.groupBalanceSheet.deduction',
    'Deduction',
  )

  const formatRisk = useCallback(
    (val: number) => {
      if (val === 0) {
        return '-'
      }
      if (val === -1) {
        return deductionText
      }
      return percentage(val, 0)
    },
    [deductionText],
  )

  const ProfitLossTabChange = useCallback(() => {
    setTab('profit-and-loss')
  }, [setTab])
  const ProfitLossDivisionTabChange = useCallback(() => {
    setTab('division-profit-and-loss')
  }, [setTab])
  const balanceSheetTabChange = useCallback(() => {
    setTab('balance-sheet')
  }, [setTab])

  const steps: Array<Partial<GuidedTourStep>> = useMemo(() => {
    const possibleSteps: Array<Partial<GuidedTourStep> | undefined> = [
      {
        target: '#tour-summary',
        content: (
          <InAppHelpContent heading="Financial Summary">
            <Text left>
              {
                'This screen displays key financial metrics for your bank and their changes.'
              }
            </Text>
          </InAppHelpContent>
        ),
      },

      {
        target: '#tour-metric-tile',
        content: (
          <InAppHelpContent heading={'Metric Tiles'}>
            <Text left>{'Each metric is presented on a separate tile.'}</Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-heading',
        content: (
          <InAppHelpContent heading="Metric Name">
            <Text left>{'The name of the metric.'}</Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-help-icon',
        content: (
          <InAppHelpContent heading="Information">
            <Text left>
              {
                'Detailed explanation of the metric. Ask your coach for more information if needed.'
              }
            </Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-value-string',
        content: (
          <InAppHelpContent heading="Metric Value">
            <Text left>
              {
                'The current value at the end of the last round/year. This will update in the next round/year.'
              }
            </Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-metric-change',
        content: (
          <InAppHelpContent heading="Metric Change">
            <Text left>
              {
                'The change in the metric value compared to the previous period.'
              }
            </Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-profit-loss',
        content: (
          <InAppHelpContent heading="Profit & Loss">
            <Text left>
              {
                "This screen displays your bank's profit and loss for the previous year/round and the return on equity for each product."
              }
            </Text>
          </InAppHelpContent>
        ),
        onClick: ProfitLossTabChange,
      },
      {
        target: '#tour-profit-loss-financials',

        content: (
          <InAppHelpContent heading="Financials">
            <Text left>
              {
                'Values for each line item in the Profit & Loss for the previous year/round. '
              }
            </Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-profit-loss-change',

        content: (
          <InAppHelpContent heading="Change">
            <Text left>
              {'Changes in each profit item over the past year/round.'}
            </Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-return-equity-tile',

        content: (
          <InAppHelpContent heading="Change">
            <Text left>
              {
                'Tiles show the Return on Equity for each product and the change over the previous year/round.'
              }
            </Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-profit-loss-division',

        content: (
          <InAppHelpContent heading="Profit & Loss By Division">
            <Text left>
              {
                'This screen displays the profit and loss for each product for the previous year/round.'
              }
            </Text>
          </InAppHelpContent>
        ),
        onClick: ProfitLossDivisionTabChange,
      },
      {
        target: '#tour-table-details',

        content: (
          <InAppHelpContent heading="More Detail">
            <Text left>
              {
                'Provides more detailed information on profit items compared to the Group Profit & Loss.'
              }
            </Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-balance-sheet',

        content: (
          <InAppHelpContent heading="Balance Sheet">
            <Text left>
              {
                'This screen shows the Balance Sheet for your entire bank at the end of the previous year/round and how Regulatory Capital (Shareholders’ Funds) is allocated'
              }
            </Text>
          </InAppHelpContent>
        ),
        onClick: balanceSheetTabChange,
      },

      {
        target: '#tour-balance-sheet-items',

        content: (
          <InAppHelpContent heading="Balance Sheet Items">
            <Text left>
              {
                'Balances of all your bank’s assets, liabilities, and equity at the end of the previous year/round.'
              }
            </Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-balance-sheet-change',

        content: (
          <InAppHelpContent heading="Change">
            <Text left>
              {'Changes in balances over the previous year/round.'}
            </Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-balance-sheet-risk',

        content: (
          <InAppHelpContent heading="Risk Weight/Deduction">
            <Text left>
              {`Shows the capital treatment for each asset. The asset's balance is multiplied by this number to determine its risk-weighted asset value. Capital Deductions require ${financials[roundId].denomination}1 of equity for every ${financials[roundId].denomination}1 of asset value, making them capital intensive.`}
            </Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-balance-sheet-capital',
        content: (
          <InAppHelpContent heading="Capital Allocation">
            <Text left>
              {
                'Shows the amount of Shareholders’ Funds allocated to each asset. Values are calculated by multiplying each risk-weighted asset value by your bank’s Capital Ratio.'
              }
            </Text>
            <Text left>{`Example: Home Loan calculation: ${formatValue(
              data.homeLoans,
            )} x ${formatRisk(data.homeLoansCT)} x ${percentage(
              data.cet1Ratio,
              2,
            )} = ${formatValue(data.homeLoansCA)}.`}</Text>
            <Text left>
              {
                'Hint: Products with higher capital requirements should maintain higher margins to generate sufficient returns.'
              }
            </Text>
          </InAppHelpContent>
        ),
      },
    ]

    const enabledSteps: Array<Partial<GuidedTourStep>> = possibleSteps.filter(
      s => s !== undefined,
    ) as Array<Partial<GuidedTourStep>>

    return enabledSteps
  }, [
    ProfitLossTabChange,
    ProfitLossDivisionTabChange,
    balanceSheetTabChange,
    roundId,
    financials,
    data,
    formatRisk,
  ])

  const handleCloseTour = useCallback(() => {
    dispatch(finishTour())
  }, [dispatch])

  if (currentTour !== 'our-financials') {
    return null
  }

  return <GuidedTour disableScrolling steps={steps} onClose={handleCloseTour} />
}

export default OurFinancialsTour
