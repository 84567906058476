import React, { useCallback, useState } from 'react'
import Modal from '../../../atoms/Modal/Modal'
import NumericInput from '../../../atoms/Input/NumericInput'
import InlineError from '../../../atoms/InlineError/InlineError'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import ValueChangerButtons from '../../../molecules/ValueChanger/ValueChangerButtons'
import { Props as ValueChangeProps } from '../../../molecules/ValueChanger/ValueChangerModal'
import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'
import { CapitalManagmentData } from './data'
import { DecisionMap } from '../TreasuryContainer'
import CapitalRatioEsitmatedModalDetail from './CapitalRatioEsitmatedModalDetail'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { useGetData } from './data'
import { TreasuryResponse } from '../../../../types/gameApi/treasury'
import { formatValue, roundToFixed } from '../../../../lib/formatters'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import { Icon } from '../../../atoms/Icon/Icon'
interface Props extends ValueChangeProps {}

interface Props extends ValueChangeProps {}

const TargetCapitalRatioModal: React.FC<Props> = ({
  heading,
  onClose,
  type,
  value,
  onChange,
  min,
  max,
  step,
  enableChanges,
  increments,
  validateInput,
  extraData,
}) => {
  const [error, setError] = useState<string | null>(null)
  const [valueToUse, setValueToUse] = useState(value)

  const onValueChange = useCallback((val: number) => {
    setValueToUse(val)
  }, [])

  const onSave = useCallback(() => {
    onChange(valueToUse)
    onClose()
  }, [onChange, onClose, valueToUse])

  const { decisions, capitalManagmentData, data } = extraData as {
    decisions: DecisionMap
    capitalManagmentData: CapitalManagmentData
    data: TreasuryResponse
  }
  const { band } = capitalManagmentData

  const updatedData = useGetData({
    data,
    decisions: {
      ...decisions,
      capitalRatioChange: valueToUse,
    },
  })

  return (
    <Modal
      show
      title={heading}
      titlePosition="center"
      onClose={onClose}
      onOk={onSave}
      okButtonText="Save"
      buttonPosition="center"
    >
      <VerticalGroup gap={4} center className="w-full py-4 tablet:w-96">
        {error && <InlineError message={error} />}
        <CapitalRatioEsitmatedModalDetail
          decisions={decisions}
          capitalManagmentData={capitalManagmentData}
        />
        <VerticalGroup gap={1}>
          <TranslatedText
            size="xs"
            center
            value="treasury.screen4.table2Row9"
            default="Target Capital Ratio"
          />
          <ValueChangerButtons
            value={valueToUse}
            onChange={onValueChange}
            min={min}
            max={max}
            step={step}
            enableChanges={enableChanges}
            increments={increments}
            incrementFormatter={(val: number) => roundToFixed(val / 10, 2)}
            buttonSize="long"
          >
            <div className="w-24">
              <NumericInput
                size="xl"
                className="h-10 laptop:flex"
                type={type}
                value={valueToUse}
                onChange={onValueChange}
                onError={setError}
                validateInput={validateInput}
                submitTimeout={5000}
              />
            </div>
          </ValueChangerButtons>
        </VerticalGroup>
        <VerticalGroup gap={2} center>
          <TranslatedText
            size="sm"
            value="treasury.screen4.table1Row7a"
            default="Estimated Credit Rating"
          />
          <HorizontalGroup verticalCenter>
            <VerticalGroup>
              <div className="rounded-lg bg-green py-0.5 px-2">
                <SecondaryText weight="bold" colour="white">
                  {band}
                </SecondaryText>
              </div>
            </VerticalGroup>
            {band !== updatedData?.band && (
              <>
                <Icon type="arrowRight" colour="black" />
                <VerticalGroup>
                  <div className="rounded-lg bg-green py-0.5 px-2">
                    <SecondaryText weight="bold" colour="white">
                      {updatedData?.band}
                    </SecondaryText>
                  </div>
                </VerticalGroup>
              </>
            )}
          </HorizontalGroup>
        </VerticalGroup>
        <VerticalGroup gap={2} center>
          <TranslatedText
            size="sm"
            value="treasury.screen4.table1Row7a"
            default="SHARE ISSUES / (BUYBACKS)"
          />
          <SecondaryText size="base" weight="bold">
            {formatValue(Number(updatedData?.additionalShareIssues.toFixed(0)))}
          </SecondaryText>
        </VerticalGroup>
      </VerticalGroup>
    </Modal>
  )
}

export default TargetCapitalRatioModal
