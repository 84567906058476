import { DealroomResponse } from '../../../../../types/gameApi/dealroom'
import { data as round2Data } from './2'
import { data as round4Data } from './4'

const dealMockData: { [roundId: number]: DealroomResponse } = {
  2: round2Data,
  4: round4Data,
}

export const getDealData = (roundId: number): DealroomResponse => {
  if (dealMockData[roundId]) {
    return dealMockData[roundId]
  }
  throw new Error(`No mock deal data for round ${roundId}`)
}
