import React from 'react'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'

import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'

import MetricCardIconBox from '../../../organisms/MetricCard/MetricCardIconBox'

import BrandedButton from '../../../organisms/Branding/BrandedButton/BrandedButton'
import BrandedText from '../../../organisms/Branding/BrandedText/BrandedText'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import { useEventStatus } from '../../../../hooks/useEventStatus'

interface Props {
  onEnterEventButtonPressed: () => void
}

const WaitingRoomEventInfoCard: React.FC<Props> = ({
  onEnterEventButtonPressed,
}) => {
  const eventStatus = useEventStatus()

  return (
    <VerticalGroup
      className="pb-4 tablet:h-64 tablet:justify-end tablet:pb-0 desktop:pb-0"
      between
      full
      fullWidth
    >
      <VerticalGroup
        gap={4}
        className="h-44 pt-2 tablet:ml-1.5 tablet:border-l-2 tablet:pt-0 tablet:pl-7 monitor:gap-4"
      >
        <HorizontalGroup between fullWidth verticalCenter>
          <BrandedText colourField="mainTextColour" size="lg" weight="bold">
            Event Status
          </BrandedText>
        </HorizontalGroup>
        <VerticalGroup gap={4} className=" " verticalCenter fullWidth>
          <HorizontalGroup className="laptop:h-20" gap={2}>
            <MetricCardIconBox
              iconSize={14}
              useLightShades
              size="large"
              iconType={eventStatus.icon}
              iconColour={eventStatus.colour}
              backgroundColour={eventStatus.colour}
            ></MetricCardIconBox>
            <VerticalGroup className="bg-succes" verticalCenter>
              <BrandedText
                colourField="mainTextColour"
                family="secondary"
                weight="bold"
              >
                {eventStatus.title}
              </BrandedText>
              {eventStatus.message ? (
                <BrandedText
                  colourField="mainTextColour"
                  family="secondary"
                  size="sm"
                >
                  {eventStatus.message}
                </BrandedText>
              ) : (
                <BrandedText colourField="mainTextColour" family="secondary">
                  &nbsp;
                </BrandedText>
              )}
            </VerticalGroup>
          </HorizontalGroup>
          <VerticalGroup full>
            <BrandedButton
              size="xl"
              disabled={!eventStatus.enableButton}
              onClick={
                eventStatus.enableButton ? onEnterEventButtonPressed : undefined
              }
            >
              <SecondaryText size="sm">Enter Event</SecondaryText>
            </BrandedButton>
          </VerticalGroup>
        </VerticalGroup>
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default WaitingRoomEventInfoCard
