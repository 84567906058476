import React, { useCallback } from 'react'
import InAppHelpContent from '../../organisms/InAppHelp/InAppHelpContent'
import Text from '../../atoms/Text/Text'
import GuidedTour, {
  GuidedTourStep,
} from '../../organisms/InAppHelp/GuidedTour'
import { useAppDispatch, useAppSelector } from '../../../store'
import { finishTour } from '../../../redux/guidedTours/guidedToursSlice'

const CostsTour: React.FC = () => {
  const dispatch = useAppDispatch()
  const currentTour = useAppSelector(state => state.guidedTours.currentTour)

  const steps: Array<Partial<GuidedTourStep>> = [
    {
      target: '#tour-costs-division',
      content: (
        <InAppHelpContent heading="Operating Divisions">
          <Text left>
            {
              "These four divisions can be used to adjust operational resourcing and impact your bank's key summary metrics. Also, staff salaries can be adjusted in the last tile."
            }
          </Text>
        </InAppHelpContent>
      ),
    },

    {
      target: '#tour-costs-divisionplus-button',
      content: (
        <InAppHelpContent heading={'Increase Investment & Resources'}>
          <Text left>
            {
              "You can increase the investment and resourcing in each operating division to increase productivity and your bank's ability to address its issues. Increasing this will allow your bank to achieve more than it normally would. "
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-costs-division-input',
      content: (
        <InAppHelpContent heading="Investment Level">
          <Text left>
            {
              'This represents the increase in operational expenditure relative to the “maintenance” level of spending for bank. Setting this to zero simply means resetting back to “maintenance” levels of spending.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-costs-division-icon',
      content: (
        <InAppHelpContent heading="Synchronise Divisions">
          <Text left>
            {
              'Toggle this synchronisation button on or off. When enabled any change to a division will be applied to all divisions, allowing you to make rapid changes to all divisions simultaneously. '
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-costs-division-value',
      content: (
        <InAppHelpContent heading="Productivity">
          <Text left>
            {
              'This metric represents how much productivity has improved in this division. A positive number means your bank is achieving more than it was when you began running the bank.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-costs-division-cost-change-value',
      content: (
        <InAppHelpContent heading={'Cost Impact'}>
          <Text left>
            {
              'The additional cost of your decisions is shown here. This incremental cost is the cost this year above the “maintenance” level of operating costs your bank would normally occur. This can be changed in subsequent years.  '
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-costs-division-fte-change-value',
      content: (
        <InAppHelpContent heading={'People Impact'}>
          <Text left>
            {
              'The additional people required as a result of your decisions are shown here. These people are incremental to the “maintenance” level of employees your bank would normally require. This can be changed in subsequent years.  '
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-impacts',
      content: (
        <InAppHelpContent heading={'Metric Impacts'}>
          <Text left>
            {
              'This tile shows the expected change in your important metrics as a result of your current decisions. It also shows the forecast of each metric value at the end of the current year. Note that while these include the impact of projects selected in previous years, they do not include the project selected in the current year. '
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-total-impact',
      content: (
        <InAppHelpContent heading={'Total Impact'}>
          <Text left>
            {
              'This shows you the total additional operating costs and people you will require this year as a result of your decisions. These amounts are incremental to the “maintenance” levels your bank would expect in a normal year of operations.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
  ]

  const handleCloseTour = useCallback(() => {
    dispatch(finishTour())
  }, [dispatch])

  if (currentTour !== 'costs') {
    return null
  }

  return <GuidedTour disableScrolling steps={steps} onClose={handleCloseTour} />
}

export default CostsTour
