import React, { useState } from 'react'
import BusinessAdvanceModeCard from './BusinessAdvanceModeCard'
import { BusinessDecision } from '../BusinessesContainer'
import Tab, { TabType } from '../../../atoms/Tabs/Tab'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { ItemData, NimAndNII } from './types'
import ValueChangerWithPercentageChange from '../../../molecules/ValueChanger/ValueChangerWithPercentageChange'
import { formatPrice1, formatVolume } from '../formatters'
import { renderPercentage } from './BusinessAdvancedModeTable'
import PrimaryText from '../../../atoms/Text/PrimaryText'
import Card from '../../../atoms/Card/Card'
import Page from '../../../atoms/Page/Page'
import Button from '../../../atoms/Button/Button'
import { Icon } from '../../../atoms/Icon/Icon'

interface AssetLiabilitySwitchProps {
  assetData: ItemData[]
  liabilityData: ItemData[]
  onChangeValue: (
    type: string,
    field: keyof BusinessDecision,
    value: number,
  ) => void
  netInterestIncome: NimAndNII
  nim: NimAndNII
  enableChanges: boolean
  handleReset: () => void
}

const AssetLiabilitySwitch: React.FC<AssetLiabilitySwitchProps> = ({
  assetData,
  liabilityData,
  nim,
  netInterestIncome,
  onChangeValue,
  enableChanges,
  handleReset,
}) => {
  const [selectedCard, setSelectedCard] = useState<'liability' | 'asset'>(
    'asset',
  )

  const tabs: TabType<'liability' | 'asset'>[] = [
    { value: 'asset', label: 'ASSETS' },
    { value: 'liability', label: 'LIABILITIES' },
  ]

  return (
    <div className="tablet:hidden">
      <VerticalGroup gap={2}>
        <Page>
          <HorizontalGroup fullWidth>
            {tabs.map(tab => (
              <Tab
                key={tab.value}
                tab={tab}
                active={selectedCard === tab.value}
                onClick={setSelectedCard}
              />
            ))}
          </HorizontalGroup>
          <HorizontalGroup reverseRow fullHeight className="pb-2">
            {enableChanges && (
              <Button rounded="full" buttonType="secondary">
                <HorizontalGroup
                  verticalCenter
                  gap={2}
                  className="row-reverse flex"
                >
                  <Icon type="reset" colour="black" />
                  <PrimaryText onClick={handleReset} colour="black" size="sm">
                    Reset Volumes
                  </PrimaryText>
                </HorizontalGroup>
              </Button>
            )}
          </HorizontalGroup>
          {selectedCard === 'asset' && (
            <VerticalGroup gap={2}>
              {assetData.map((ass, i) => (
                <BusinessAdvanceModeCard
                  key={ass.name + i}
                  data={ass}
                  onChangeValue={onChangeValue}
                  enableChanges={enableChanges}
                  idPrefix={`${ass.type}-volumeNow`}
                />
              ))}
            </VerticalGroup>
          )}
          {selectedCard === 'liability' && (
            <VerticalGroup gap={2}>
              {liabilityData.map((liab, i) => (
                <BusinessAdvanceModeCard
                  key={liab.name + i}
                  data={liab}
                  onChangeValue={onChangeValue}
                  enableChanges={enableChanges}
                  idPrefix={`${liab.type}-volumeNow`}
                />
              ))}
            </VerticalGroup>
          )}
        </Page>
        <Card className="fixed bottom-0 mx-[-1.0rem] w-full rounded-none">
          <VerticalGroup gap={3} className="p-4">
            <HorizontalGroup between>
              <PrimaryText size="sm" weight="bold">
                FORECAST NET INTEREST MARGIN
              </PrimaryText>
              <PrimaryText>
                <ValueChangerWithPercentageChange
                  fontBold
                  enableChanges={false}
                  value={nim.now}
                  formatValue={val => formatPrice1(val)}
                  percentageChange={() => {
                    return renderPercentage(nim.change)
                  }}
                />
              </PrimaryText>
            </HorizontalGroup>
            <HorizontalGroup between>
              <PrimaryText size="sm" weight="bold">
                FORECAST NET INTEREST INCOME
              </PrimaryText>
              <PrimaryText>
                <ValueChangerWithPercentageChange
                  fontBold
                  enableChanges={false}
                  value={netInterestIncome.now}
                  formatValue={val => formatVolume(val)}
                  percentageChange={() => {
                    return renderPercentage(netInterestIncome.change)
                  }}
                />
              </PrimaryText>
            </HorizontalGroup>
          </VerticalGroup>
        </Card>
        <div className="h-20"></div>
      </VerticalGroup>
    </div>
  )
}

export default AssetLiabilitySwitch
