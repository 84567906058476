import { PropsWithChildren } from 'react'
import { percentage } from '../../../../lib/formatters'
import { PeerInsightsMetricTabs } from '../../../../redux/peer-insights/peerInsightsSlice'
import {
  PeerInsightsBusinessType,
  PeerInsightsDataValue,
} from '../../../../types/gameApi/peerInsights'
import { SummaryResponse } from '../../../../types/gameApi/summary'
import { IconType } from '../../../atoms/Icon/Icon'
import CommunityTrustChart from './charts/CommunityTrust'
import CreditRatingChart from './charts/CreditRating'
import CustomerComplaintsChart from './charts/CustomerComplaints'
import CustomersChart from './charts/Customers'
import CustomerSatisfactionChart from './charts/CustomerSatisfaction'
import EmployeeEngagementChart from './charts/EmployeeEngagement'
import LoanImpairmentChart from './charts/LoanImpairment'
import RegulatorSanctionsChart from './charts/RegulatorSanctions'
import ReturnOnEquityChart from './charts/ReturnOnEquity'
import RiskIncidentsnChart from './charts/RiskIncidents'
import ShareholderReturnsChart from './charts/ShareHolderReturns'
import { getIconForSummaryMetric } from '../../../../lib/icons'

const getTeamValuePercentage = (
  data: PeerInsightsDataValue[],
  teamId: number,
  roundId: number,
) => {
  const team = data.find(({ team }) => team === teamId)
  if (!team) return ''
  if (team.history) {
    const history = team.history.find(({ round }) => round === roundId)
    if (!history) return ''
    return percentage(history.value)
  } else if (Object.prototype.hasOwnProperty.call(team, 'value')) {
    // @ts-expect-error huh
    return percentage(team.value)
  } else {
    return ''
  }
}

export interface ChartProps {
  data: PeerInsightsDataValue[]
  teamId: number
  roundId: number
  businessTypes: PeerInsightsBusinessType[]
  isLandscapeMobile?: boolean
}

interface Params {
  type: PeerInsightsMetricTabs
  data: PeerInsightsDataValue[]
  summaries: SummaryResponse[]
  teamId: number
  roundId: number
  businessTypes?: PeerInsightsBusinessType[]
}

export interface MappedMetric {
  type: PeerInsightsMetricTabs
  logo: IconType
  text: string
  data: PeerInsightsDataValue[]
  teamValue: string
  teamRank: string | null
  component: React.FC<PropsWithChildren<ChartProps>>
  businessTypes?: PeerInsightsBusinessType[]
}

export const mapTypeToInsight = ({
  type,
  data,
  summaries,
  teamId,
  roundId,
  businessTypes,
}: Params): MappedMetric => {
  const summaryValue = summaries.find(s => s.type === type)
  if (!summaryValue) {
    throw new Error(`Summary ${type} not found`)
  }
  const summaryValueHeading = summaryValue?.heading
  switch (type) {
    case 'TSR':
      return {
        type,
        logo: getIconForSummaryMetric(type),
        text: summaryValueHeading,
        component: ShareholderReturnsChart,
        data,
        // yAxisOverride: summaryValue.heading,
        teamValue: summaryValue.value.value + summaryValue.value.suffix,
        teamRank: summaryValue.place.value
          ? summaryValue.place.value + summaryValue.place.ordinal
          : null,
      }
    case 'NPS':
      return {
        type,
        logo: getIconForSummaryMetric(type),
        text: summaryValueHeading,
        component: CustomerSatisfactionChart,
        data,
        teamValue: summaryValue.value.value + summaryValue.value.suffix,
        teamRank: summaryValue.place.value
          ? summaryValue.place.value + summaryValue.place.ordinal
          : null,
        businessTypes,
      }
    case 'ROE':
      return {
        type,
        logo: getIconForSummaryMetric(type),
        text: summaryValueHeading,
        component: ReturnOnEquityChart,
        data,
        teamValue: summaryValue.value.value + summaryValue.value.suffix,
        teamRank: summaryValue.place.value
          ? summaryValue.place.value + summaryValue.place.ordinal
          : null,
      }
    case 'Customers':
      return {
        type,
        logo: getIconForSummaryMetric(type),
        text: summaryValueHeading,
        component: CustomersChart,
        data,
        teamValue: summaryValue.value.value + summaryValue.value.suffix,
        teamRank: summaryValue.place.value
          ? summaryValue.place.value + summaryValue.place.ordinal
          : null,
      }
    case 'LIE':
      return {
        type,
        logo: getIconForSummaryMetric(type),
        text: summaryValueHeading,
        component: LoanImpairmentChart,
        data,
        teamValue: summaryValue.value.value + summaryValue.value.suffix,
        teamRank: summaryValue.place.value
          ? summaryValue.place.value + summaryValue.place.ordinal
          : null,
      }
    case 'Rating':
      return {
        type,
        logo: getIconForSummaryMetric(type),
        text: summaryValueHeading,
        component: CreditRatingChart,
        data,
        teamValue: getTeamValuePercentage(data, teamId, roundId),
        teamRank: summaryValue.value.value,
      }
    case 'Complaints':
      return {
        type,
        logo: getIconForSummaryMetric(type),
        text: summaryValueHeading,
        component: CustomerComplaintsChart,
        data,
        teamValue: summaryValue.value.value,
        teamRank: summaryValue.place.value
          ? summaryValue.place.value + summaryValue.place.ordinal
          : null,
      }
    case 'RegulatorActions':
      return {
        type,
        logo: getIconForSummaryMetric(type),
        text: summaryValueHeading,
        component: RegulatorSanctionsChart,
        data,
        teamValue: summaryValue.value.value,
        teamRank: summaryValue.place.value
          ? summaryValue.place.value + summaryValue.place.ordinal
          : null,
      }
    case 'RiskIncidents':
      return {
        type,
        logo: getIconForSummaryMetric(type),
        text: summaryValueHeading,
        component: RiskIncidentsnChart,
        data,
        teamValue: summaryValue.value.value,
        teamRank: summaryValue.place.value
          ? summaryValue.place.value + summaryValue.place.ordinal
          : null,
      }
    case 'EmployeeEngagement':
      return {
        type,
        logo: getIconForSummaryMetric(type),
        text: summaryValueHeading,
        component: EmployeeEngagementChart,
        data,
        teamValue: summaryValue.value.value + summaryValue.value.suffix,
        teamRank: summaryValue.place.value
          ? summaryValue.place.value + summaryValue.place.ordinal
          : null,
      }
    case 'Community':
      return {
        type,
        logo: getIconForSummaryMetric(type),
        text: summaryValueHeading,
        component: CommunityTrustChart,
        data,
        teamValue: summaryValue.value.value + summaryValue.value.suffix,
        teamRank: summaryValue.place.value
          ? summaryValue.place.value + summaryValue.place.ordinal
          : null,
      }
  }
}
