import React from 'react'
import { XCircleIcon } from '@heroicons/react/20/solid'
import Clickable from '../Clickable/Clickable'
import { Icon } from '../Icon/Icon'
import HorizontalGroup from '../HorizontalGroup/HorizontalGroup'
import { useAppSelector } from '../../../store'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  message: string
  submitError?: boolean
  onRetry?: (() => void) | null
}

const InlineError: React.FC<Props> = ({
  message,
  submitError,
  onRetry,
  ...rest
}) => {
  const isTablet = useAppSelector(state => state.auth.isTablet)

  const retryMessage = submitError
    ? 'Please retry'
    : isTablet
    ? '(Try refreshing.)'
    : '(Try refreshing your web browser)'

  return (
    <div {...rest} className="rounded-md bg-red-50 p-4">
      <HorizontalGroup between>
        <HorizontalGroup gap={3}>
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div>
            <h3 className="text-sm font-medium text-red-800">
              {message}
              {message.includes('Timeout error') ? ` ${retryMessage}` : ''}
            </h3>
          </div>
        </HorizontalGroup>
        {onRetry && (
          <Clickable onClick={onRetry}>
            <Icon type="refresh" colour="error" size={3} />
          </Clickable>
        )}
      </HorizontalGroup>
    </div>
  )
}

export default InlineError
