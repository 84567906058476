import React, { useCallback, useEffect } from 'react'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'

import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'

import MetricCardIconBox from '../../../organisms/MetricCard/MetricCardIconBox'
import SelectMenu from '../../../atoms/SelectMenu/SelectMenu'
import { useAppDispatch, useAppSelector } from '../../../../store'
import SelectMenuOption from '../../../atoms/SelectMenu/SelectMenuOption'
import { formatParticipantName } from '../WaitingRoomCeoTransfer/formatters'
import {
  createCEOTransferRequest,
  fetchParticipants,
} from '../../../../redux/changeCeo/changeCeoActions'
import ErrorModal from '../../../atoms/Modal/ErrorModal'
import { clear } from '../../../../redux/changeCeo/changeCeoSlice'
import InfoPopup from '../../../atoms/InfoPopup/InfoPopup'
import BrandedText from '../../../organisms/Branding/BrandedText/BrandedText'

interface Props {
  participantName?: string
  team?: { id: number; name: string }
  registered: boolean
  isCeo: boolean
  showCeo: boolean
}

const WaitingRoomTeamInfoCard: React.FC<Props> = ({
  participantName,
  team,
  registered,
  isCeo,
  showCeo,
}) => {
  const {
    isLoading,
    isLoaded,
    participants,
    participantId,
    teamId,
    createCEORequestInProgress,
    ceoTransferRequestError,
    ceoTransferRequestParticipantId,
    maskParticipants,
  } = useAppSelector(state => ({
    isLoading: state.changeCeo.isLoading,
    isLoaded: state.changeCeo.isLoaded,
    participants: state.changeCeo.participants,
    participantId: state.auth.participantId ?? '',
    maskParticipants: state.game.maskParticipants,
    teamId: state.team.id,
    createCEORequestInProgress: state.changeCeo.ceoTransferRequestInProgress,
    ceoTransferRequestError: state.changeCeo.ceoTransferRequestError,
    ceoTransferRequestParticipantId:
      state.changeCeo.ceoTransferRequestParticipantId,
  }))

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isLoading && !isLoaded && !!teamId && teamId > 0) {
      dispatch(fetchParticipants({ teamId }))
    }
  }, [dispatch, isLoaded, isLoading, teamId])

  const handlePartipantClick = useCallback(
    (newCeoId: string) => {
      dispatch(createCEOTransferRequest({ participantId, to: newCeoId }))
    },
    [dispatch, participantId],
  )

  const handleErrorClose = useCallback(() => {
    dispatch(clear())
  }, [dispatch])

  let teamMessage = ''
  if (team?.id) {
    teamMessage += `You are in Team ${team.id}`
  }
  if (team?.name) {
    teamMessage += `: ${String(team.name).toUpperCase()} BANK`
  }

  let error = ceoTransferRequestError?.message ?? null
  if (error && /does not exist/.test(error)) {
    error = 'Your teammate is not currently signed in to accept your request'
  }

  return (
    <VerticalGroup className="tablet:h-20">
      {error && (
        <ErrorModal
          showModal
          heading={'Error requesting a new CEO'}
          message={error}
          onClose={handleErrorClose}
        />
      )}
      <VerticalGroup full gap={7} className="desktop:gap-6">
        <VerticalGroup>
          <BrandedText
            colourField="mainTextColour"
            whitespace="nowrap"
            weight="bold"
            size="xl"
          >
            Welcome{maskParticipants ? '' : ` ${participantName}`},
          </BrandedText>

          <BrandedText
            colourField="mainTextColour"
            whitespace="nowrap"
            size="sm"
            className=""
          >
            {teamMessage}
          </BrandedText>
        </VerticalGroup>
        {registered && isCeo && showCeo && (
          <VerticalGroup className="laptop:pb-2" gap={4}>
            <BrandedText colourField="mainTextColour" weight="bold" size="lg">
              Change CEO
            </BrandedText>
            <HorizontalGroup fullWidth verticalCenter gap={2}>
              <MetricCardIconBox
                iconSize={14}
                useLightShades
                size="large"
                iconType="ceo"
                iconColour="primary"
                backgroundColour="primary"
              ></MetricCardIconBox>
              <BrandedText colourField="mainTextColour">
                You&apos;re currently the CEO
              </BrandedText>
              <InfoPopup
                iconColour="primary"
                title="What is a CEO?"
                body="As CEO, there will be certain decisions which can only be entered into your simulation interface"
              />
            </HorizontalGroup>

            <HorizontalGroup fullWidth>
              <SelectMenu title="Change CEO" inProgress={isLoading}>
                {isLoaded &&
                  participants
                    .filter(p => p.id !== participantId)
                    .map(participant => (
                      <SelectMenuOption
                        key={participant.id}
                        id={participant.id}
                        title={formatParticipantName(participant)}
                        onClick={handlePartipantClick}
                        onClickInProgress={
                          createCEORequestInProgress &&
                          participant.id === ceoTransferRequestParticipantId
                        }
                      />
                    ))}
              </SelectMenu>
            </HorizontalGroup>
          </VerticalGroup>
        )}
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default WaitingRoomTeamInfoCard
