import React from 'react'
import Card from '../../../atoms/Card/Card'
import { TreasuryResponse } from '../../../../types/gameApi/treasury'

import { CapitalManagmentData } from './data'
import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import { formatValue } from '../../../../lib/formatters'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'

interface TreasuryCapitalManagementForcastCapitalProps {
  data: TreasuryResponse

  enableChanges: boolean
  capitalManagmentData: CapitalManagmentData
}

const TreasuryCapitalManagementForcastCapital: React.FC<
  TreasuryCapitalManagementForcastCapitalProps
> = ({ capitalManagmentData }) => {
  const {
    // targetShareCapitalMovement,
    denomination,
    openingBalances,
    totalNpatRetained,
    closingShareholdersFunds,
    regulatoryDeductions,
    netRegulatoryCapital,
    totalRiskWeightedAsset,
    additionalShareIssues,
  } = capitalManagmentData
  return (
    <Card className="p-5">
      <VerticalGroup gap={5}>
        <HorizontalGroup center gap={2} verticalCenter>
          <TranslatedText
            size="base"
            weight="bold"
            center
            value="treasury.screen4.table2Header"
            default="FORECAST CAPITAL / RATIO"
          />
          <SecondaryText colour="gray" size="xs">
            ({denomination})
          </SecondaryText>
        </HorizontalGroup>
        <VerticalGroup gap={3}>
          <TranslatedText
            size="sm"
            weight="bold"
            value="treasury.screen4.table2Row1"
            default={"OPENING SHAREHOLDERS' FUNDS"}
          />
          <HorizontalGroup between>
            <TranslatedText
              size="sm"
              value="treasury.screen4.table2Row2"
              default={"OPENING SHAREHOLDERS' FUNDS"}
            />
            <SecondaryText size="sm">
              {formatValue(Number(openingBalances.shareholdersFunds))}
            </SecondaryText>
          </HorizontalGroup>
          <HorizontalGroup between verticalCenter>
            <HorizontalGroup className="w-64">
              <TranslatedText
                size="sm"
                value="treasury.screen4.table2Row3"
                default={'SHARE CAPITAL GROWTH (EARNINGS DRIVEN)'}
                prefix="+ "
                suffix=": *"
              />
            </HorizontalGroup>
            <div>{formatValue(Number(totalNpatRetained.toFixed(0)))}</div>
          </HorizontalGroup>
          <HorizontalGroup between>
            <TranslatedText
              size="sm"
              value="treasury.screen4.table2Row4"
              default={'SHARES ISSUED / (BOUGHT BACK)'}
              prefix="+ "
            />

            <SecondaryText size="sm">
              {formatValue(Number(additionalShareIssues.toFixed(0)))}
            </SecondaryText>
          </HorizontalGroup>
          <div className="w-full border-b"></div>
          <HorizontalGroup verticalCenter between>
            <HorizontalGroup className="w-72">
              <TranslatedText
                size="sm"
                weight="bold"
                value="treasury.screen4.table2Row5"
                default={"CLOSING SHAREHOLDERS' FUNDS (ESTIMATED)"}
                suffix=": *"
              />
            </HorizontalGroup>

            <SecondaryText size="sm" weight="bold">
              {formatValue(Number(closingShareholdersFunds.toFixed(0)))}
            </SecondaryText>
          </HorizontalGroup>
          <HorizontalGroup between>
            <TranslatedText
              size="sm"
              value="treasury.screen4.table2Row6"
              default={'REGULATORY CAPITAL DEDUCTIONS'}
              prefix="- "
            />

            <SecondaryText size="sm">
              {formatValue(Number(regulatoryDeductions.toFixed(0)))}
            </SecondaryText>
          </HorizontalGroup>
          <div className="w-full border-b"></div>
          <HorizontalGroup verticalCenter between>
            <HorizontalGroup className="w-64">
              <TranslatedText
                size="sm"
                weight="bold"
                value="treasury.screen4.table2Row7"
                default={'NET REGULATORY CAPITAL (ESTIMATED)'}
                suffix="*"
              />
            </HorizontalGroup>

            <SecondaryText size="sm" weight="bold">
              {formatValue(Number(netRegulatoryCapital.toFixed(0)))}
            </SecondaryText>
          </HorizontalGroup>
          <HorizontalGroup between>
            <TranslatedText
              size="sm"
              weight="bold"
              value="treasury.screen4.table2Row8"
              default={'TOTAL RISK WEIGHTED ASSETS'}
            />

            <SecondaryText size="sm" weight="bold">
              {formatValue(Number(totalRiskWeightedAsset.toFixed(0)))}
            </SecondaryText>
          </HorizontalGroup>
        </VerticalGroup>
      </VerticalGroup>
    </Card>
  )
}

export default TreasuryCapitalManagementForcastCapital
