import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchPeerInsights } from './peerInsightsActions'
import APIError from '../../errors/APIError'
import { PeerInsightsResponse } from '../../types/gameApi/peerInsights'
import { ProductType } from '../../types/gameApi/business'
import { reset } from '../game/gameSlice'
import { SummaryType } from '../../types/gameApi/summary'
import { logout } from '../auth/authSlice'
import ThunkCancelledError from '../../errors/ThunkCancelledError'

export type PeerInsightsTabs = 'market-share' | 'metrics'
export type PeerInsightsMetricTabs = SummaryType
export type PeerInsightsBusinessTabs = ProductType

interface PeerInsightsState {
  currentTab: PeerInsightsTabs
  currentBusiness: PeerInsightsBusinessTabs
  currentMetric: PeerInsightsMetricTabs | null
  isLoading: boolean
  isLoaded: boolean
  insights: {
    [roundId: number]: PeerInsightsResponse
  }
  requestInProgress: string | null
  error: Error | APIError | null
}

const initialState: PeerInsightsState = {
  currentTab: 'market-share',
  currentBusiness: 'HOME_LOANS',
  currentMetric: null,
  isLoading: false,
  isLoaded: false,
  insights: {},
  requestInProgress: null,
  error: null,
}

const peerInsightsSlice = createSlice({
  name: 'peerInsights',
  initialState,
  reducers: {
    changeTab: (state, action: PayloadAction<PeerInsightsTabs>) => {
      state.currentTab = action.payload
    },
    changeBusiness: (
      state,
      action: PayloadAction<PeerInsightsBusinessTabs>,
    ) => {
      state.currentBusiness = action.payload
    },
    changeMetric: (state, action: PayloadAction<PeerInsightsMetricTabs>) => {
      state.currentMetric = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(reset, () => {
        return initialState
      })
      .addCase(logout, () => {
        return initialState
      })
      .addCase(fetchPeerInsights.pending, (state, { meta }) => {
        if (!state.isLoading) {
          state.requestInProgress = meta.requestId
          state.isLoading = true
          state.error = null
        }
      })
      .addCase(fetchPeerInsights.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isLoaded = true
        state.error = null
        state.insights[payload.roundId] = payload.insights
        if (!state.currentMetric) {
          state.currentMetric = payload.insights.data.filter(
            d => d.enabled,
          )[0].type
        }
        state.requestInProgress = null
      })
      .addCase(fetchPeerInsights.rejected, (state, { payload }) => {
        if (payload instanceof ThunkCancelledError) {
          return
        }
        state.isLoading = false
        state.error = payload ?? null
        state.requestInProgress = null
      })
  },
})

export const { changeTab, changeBusiness, changeMetric } =
  peerInsightsSlice.actions

export default peerInsightsSlice.reducer
