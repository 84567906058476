import { ExcoResponse } from '../../../../../types/gameApi/exco'

export const data: ExcoResponse = {
  id: 12,
  name: 'Sleepy Advanced ATMs',
  description:
    '"Your bank is piloting two advanced ATMs in busy branches.  The advanced ATMs provide more banking services and functionality to customers 24/7. \nThe pilot has uncovered a problem. Each day during 6pm - 8pm if customers deposit funds, the product system does not reflect these funds in the customer\'s account until after the 2 hour window. \nIf customers withdraw money from their accounts during this time window, it could cause the system to think their account is overdrawn as the deposit will not yet be reflected. This may result in interest charges and overdrawn account fees.\nWhat course of action do you suggest:',
  options: [
    {
      id: 1,
      title: 'On Screen Message',
      description:
        '\n\nDuring 6pm-8pm, display an on-screen message alerting the customers to this issue, and informing them that funds will only be available on the following day.\n\n',
    },
    {
      id: 2,
      title: 'Rollout The ATMs',
      description:
        'Proceed with a national roll out. You feel the number of impacted customers will be small. You instruct your people to monitor and manually compensate any impacted customers. No further fix is planned to be implented.',
    },
    {
      id: 3,
      title: 'Delay The Rollout',
      description:
        'The system issue is due to be resolved in a scheduled system upgrade, but that cannot be scheduled until the end of this year. Without these advanced ATMs in place, some (1-5%) business lending customers may move to competitors during this time.',
    },
  ],
  decision: 3,
  imageUrl:
    'https://test-images.banksim.co/exco/exco-bc3a5ef02403b47e85c81fba21e52ac6.jpg',
}
