import classNames from 'classnames'
import React from 'react'
import Clickable from '../Clickable/Clickable'
import PrimaryText from '../Text/PrimaryText'

export interface TabType<T> {
  value: T
  label: string
}

export interface Props<T>
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'> {
  className?: string
  tab: TabType<T>
  active: boolean
  onClick: (newTab: T) => void
}

function Tab<T>({ className, tab, active, onClick, ...rest }: Props<T>) {
  return (
    <Clickable key={tab.value as string} onClick={() => onClick(tab.value)}>
      <div
        {...rest}
        className={classNames(
          'rounded-full border border-primary px-3 py-3',
          { 'bg-white': active },
          className,
        )}
      >
        <PrimaryText size="xs" weight={active ? 'bold' : 'normal'}>
          {tab.label}
        </PrimaryText>
      </div>
    </Clickable>
  )
}

export default Tab
