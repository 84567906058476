import React, { PropsWithChildren, ReactNode, useMemo, useState } from 'react'
import { ChartProps } from '../SummaryMetricMapper'
import { LineChartData } from '../../../../organisms/Charts/LineChart/types'
import { getYAxisDataV2 } from '../../../../organisms/Charts/yAxis'
import LineChartWithTeamSelector from './LineChartWithTeamSelector'
import { mapHistoricalData } from './mappers'
import HorizontalGroup from '../../../../atoms/HorizontalGroup/HorizontalGroup'
import SecondaryTab from '../../../../atoms/Tabs/SecondaryTab'
import { BottomTabType } from '../../../../atoms/BottomTabs/BottomTab'
import { ProductType } from '../../../../../types/gameApi/business'
import { titleCase } from '../../../../../lib/text'
import { getIconForBusiness } from '../../../../../lib/icons'
import DropDown from '../../../../atoms/DropDown/DropDown'

type CustomerSatisfactionFilterTypes = 'entire_bank' | ProductType

const ENTIRE_BANK_TAB: BottomTabType<CustomerSatisfactionFilterTypes> = {
  label: 'Entire Bank',
  value: 'entire_bank',
  icon: 'mortgages', // TODO Mitch add entire bank icon
}

const CustomerSatisfactionChart: React.FC<PropsWithChildren<ChartProps>> = ({
  data,
  teamId,
  businessTypes,
  isLandscapeMobile,
  children,
}) => {
  const [currentBusiness, setCurrentBusiness] =
    useState<CustomerSatisfactionFilterTypes>('entire_bank')
  const filteredData = useMemo(() => {
    return currentBusiness === 'entire_bank'
      ? data
      : businessTypes.find(bt => bt.type === currentBusiness)?.data ?? []
  }, [businessTypes, currentBusiness, data])

  const lines = filteredData.map(t => ({
    name: t.name,
    key: `team${t.team}`,
  }))

  const mappedData: Array<LineChartData> = useMemo(
    () => mapHistoricalData(filteredData),
    [filteredData],
  )

  const { minValue, maxValue, numberOfTicks } = getYAxisDataV2({
    pastRound1: true,
    data: mappedData,
  })

  const businessTabs: BottomTabType<CustomerSatisfactionFilterTypes>[] = [
    ENTIRE_BANK_TAB,
  ].concat(
    businessTypes.map(bt => ({
      label: titleCase(bt.name),
      value: bt.type,
      icon: getIconForBusiness(bt.type),
    })),
  )

  const secondaryNav: ReactNode = isLandscapeMobile ? null : (
    <HorizontalGroup fullWidth between className="hidden laptop:flex">
      {businessTabs.map((bt, index) => (
        <SecondaryTab
          id={index === 1 ? 'tour-metric-breakdown' : ''}
          key={bt.value}
          tab={bt}
          onClick={() => setCurrentBusiness(bt.value)}
          active={currentBusiness === bt.value}
        />
      ))}
    </HorizontalGroup>
  )

  const header = isLandscapeMobile ? (
    <HorizontalGroup gap={2}>
      {children}
      <DropDown<CustomerSatisfactionFilterTypes>
        items={businessTabs}
        selectedItem={currentBusiness}
        onSelectedItemChange={item => setCurrentBusiness(item.value)}
      />
    </HorizontalGroup>
  ) : (
    children
  )

  return (
    <LineChartWithTeamSelector
      heightOffset={80}
      header={header}
      secondaryNav={secondaryNav}
      data={mappedData}
      minYAxisvalue={minValue}
      maxYAxisvalue={maxValue}
      numberOfTicks={numberOfTicks}
      lines={lines}
      teamId={teamId}
    />
  )
}

export default CustomerSatisfactionChart
