import classNames from 'classnames'
import React from 'react'
import HorizontalGroup from '../HorizontalGroup/HorizontalGroup'
import BottomTab, { BottomTabType } from './BottomTab'

export interface Props<T> {
  className?: string
  tabs: BottomTabType<T>[]
  selectedTab: T
  onChange: (newTab: T) => void
}

function BottomTabs<T>({ className, tabs, selectedTab, onChange }: Props<T>) {
  return (
    <>
      <div className={classNames('h-16', className)}></div>
      <section
        id="bottom-navigation"
        className={classNames(
          'fixed left-0 bottom-0 h-16 w-full bg-white shadow',
          className,
        )}
      >
        <HorizontalGroup fullWidth around>
          {tabs.map((t: BottomTabType<T>) => (
            <BottomTab
              key={t.value as string}
              tab={t}
              onClick={() => onChange(t.value)}
              active={selectedTab === t.value}
            />
          ))}
        </HorizontalGroup>
      </section>
    </>
  )
}

export default BottomTabs
