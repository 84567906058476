import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store'
import RadioInput from '../../../atoms/RadioButtons/RadioInput'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import { SummaryType } from '../../../../types/gameApi/summary'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { setEnabledSummaryMetrics } from '../../../../redux/demo/demoSlice'

interface Props {}

const SUMMARY_TYPES: Array<{ label: string; value: SummaryType }> = [
  { label: 'TSR', value: 'TSR' },
  { label: 'NPS', value: 'NPS' },
  { label: 'ROE', value: 'ROE' },
  { label: 'Customers', value: 'Customers' },
  { label: 'LIE', value: 'LIE' },
  { label: 'Rating', value: 'Rating' },
  { label: 'EmployeeEngagement', value: 'EmployeeEngagement' },
  { label: 'Community', value: 'Community' },
  { label: 'RiskIncidents', value: 'RiskIncidents' },
  { label: 'Complaints', value: 'Complaints' },
  { label: 'RegulatorActions', value: 'RegulatorActions' },
]

const DemoSettingsSummaryMetrics: React.FC<Props> = () => {
  const enabledSummaryMetrics = useAppSelector(
    state => state.demo.enabledSummaryMetrics,
  )
  const dispatch = useAppDispatch()

  const updateSummaryTypes = useCallback(
    (metric: SummaryType, enabled: boolean) => {
      const newEnabledSummaryMetrics = enabled
        ? [...enabledSummaryMetrics, metric]
        : enabledSummaryMetrics.filter(m => m !== metric)
      dispatch(setEnabledSummaryMetrics(newEnabledSummaryMetrics))
    },
    [dispatch, enabledSummaryMetrics],
  )

  return (
    <VerticalGroup gap={2}>
      {SUMMARY_TYPES.map(t => (
        <HorizontalGroup verticalCenter gap={2} key={t.value}>
          <RadioInput
            key={t.value}
            id={t.value}
            name={t.label}
            value={t.value}
            checked={enabledSummaryMetrics.includes(t.value)}
            onClick={() => {
              updateSummaryTypes(
                t.value,
                !enabledSummaryMetrics.includes(t.value),
              )
            }}
          />
          <label htmlFor={`${t.value}`}>{t.label}</label>
        </HorizontalGroup>
      ))}
    </VerticalGroup>
  )
}

export default DemoSettingsSummaryMetrics
