import classNames from 'classnames'
import React from 'react'
import Clickable from '../Clickable/Clickable'
import { Icon, IconType } from '../Icon/Icon'
import PrimaryText from '../Text/PrimaryText'
import VerticalGroup from '../VerticalGroup/VerticalGroup'

export interface BottomTabType<T> {
  icon: IconType
  value: T
  label: string
}
export interface Props<T> {
  className?: string
  tab: BottomTabType<T>
  active: boolean
  onClick: (newTab: T) => void
}

function BottomTab<T>({ className, tab, active, onClick }: Props<T>) {
  return (
    <Clickable key={tab.value as string} onClick={() => onClick(tab.value)}>
      <VerticalGroup
        center
        full
        className={classNames({ 'font-bold text-primary': active }, className)}
      >
        <Icon
          className="h-6 w-6"
          type={tab.icon}
          colour={active ? 'primary' : 'black'}
        />
        <PrimaryText transform="capitalize" whitespace="nowrap" size="xs">
          {tab.label.toLowerCase()}
        </PrimaryText>
      </VerticalGroup>
    </Clickable>
  )
}

export default BottomTab
