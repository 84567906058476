import React, { FC, useCallback, useState } from 'react'
// import Clickable from '../../atoms/Clickable/Clickable'
// import { Icon } from '../../atoms/Icon/Icon'
// import Input from '../../atoms/Input/Input'
// import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
// import ErrorModal from '../../atoms/Modal/ErrorModal'
// import NumericInput from '../../atoms/Input/NumericInput'
import Modal from '../../../atoms/Modal/Modal'
import NumericInput from '../../../atoms/Input/NumericInput'
import InlineError from '../../../atoms/InlineError/InlineError'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import ValueChangerButtons from '../../../molecules/ValueChanger/ValueChangerButtons'
import { Props as ValueChangeProps } from '../../../molecules/ValueChanger/ValueChangerModal'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import { percentage } from '../../../../lib/formatters'
import { TreasuryResponse } from '../../../../types/gameApi/treasury'
import { DecisionMap } from '../TreasuryContainer'
import Button from '../../../atoms/Button/Button'

const MAX_WSF_CHANGE = 100000

const percentages: number[] = [0.1, 0.2, 0.25, 1 / 3, 0.5, 1]

interface Props extends ValueChangeProps {}

const TreasuryWSFProfileModal: FC<Props> = ({
  heading,
  onClose,
  type,
  value,
  // formatValue,
  // onDecrement,
  // onIncrement,
  onChange,
  min,
  max,
  step,
  enableChanges,
  // validateInput,
  extraData,
}) => {
  const [error, setError] = useState<string | null>()
  const [valueToUse, setValueToUse] = useState(value)

  const { data, decisions, year, onChangeAndMove } = extraData as {
    existingFunding: number
    data: TreasuryResponse
    decisions: DecisionMap
    year: 1 | 2 | 3 | 4 | 5
    onChangeAndMove: (value: number) => void
  }

  const validateInput = useCallback(
    (input: number) => {
      if (input < 0) {
        return 'Input must be a positive whole number'
      }

      const total =
        decisions.wsf1 +
        decisions.wsf2 +
        decisions.wsf3 +
        decisions.wsf4 +
        decisions.wsf5 -
        decisions[`wsf${year}`] +
        input
      const wsfChange = total - Math.abs(data.openingBalances.wsfRepayments)
      const amountOverMax =
        wsfChange -
        (MAX_WSF_CHANGE - Math.abs(data.openingBalances.wsfRepayments))
      if (amountOverMax > 0) {
        return 'Maximum wholesale funding movement exceeded'
      }
    },
    [data.openingBalances.wsfRepayments, decisions, year],
  )

  const onValueChange = useCallback((val: number) => {
    setValueToUse(val)
  }, [])

  const onSaveMoveFromYr5 = useCallback(() => {
    const diff = valueToUse - decisions[`wsf${year}`]
    if (diff > decisions.wsf5) {
      setError('Insufficient 5 year funding to move')
      return
    }
    onChangeAndMove(valueToUse)
    onClose()
  }, [decisions, onChangeAndMove, onClose, valueToUse, year])

  const onSaveNewFunding = useCallback(() => {
    onChange(valueToUse)
    onClose()
  }, [onChange, onClose, valueToUse])

  const handlePercentageClick = useCallback(
    (percentage: number) => () => {
      setError('')
      const total =
        decisions.wsf1 +
        decisions.wsf2 +
        decisions.wsf3 +
        decisions.wsf4 +
        decisions.wsf5
      const value = total * percentage
      setValueToUse(Math.floor(value))
    },
    [
      decisions.wsf1,
      decisions.wsf2,
      decisions.wsf3,
      decisions.wsf4,
      decisions.wsf5,
    ],
  )

  return (
    <Modal
      show
      title={heading}
      titlePosition="center"
      onClose={onClose}
      onOk={onSaveMoveFromYr5}
      okButtonText="Save (Moved from 5Yr)"
      buttonPosition="center"
      secondaryButton={{
        label: 'Save (New Funding)',
        onPress: onSaveNewFunding,
      }}
      buttonSize="responsive"
    >
      <VerticalGroup gap={4} center className="w-full py-4 tablet:w-96">
        {error && <InlineError message={error} />}
        {/* <VerticalGroup gap={1} center>
          <SecondaryText>
            Existing Wholesale Funding: {formatValue(existingFunding)}
          </SecondaryText>
        </VerticalGroup> */}
        <ValueChangerButtons
          value={valueToUse}
          onChange={onValueChange}
          min={min}
          max={max}
          step={step}
          enableChanges={enableChanges}
          increments={[1, 5]}
          incrementFormatter={(val: number) => `${val}B`}
          buttonSize="medium"
          hideButtons
        >
          <div className="w-24">
            <NumericInput
              className="h-10 laptop:flex laptop:text-base"
              type={type}
              value={valueToUse}
              onChange={onValueChange}
              onError={setError}
              validateInput={validateInput}
              submitTimeout={5000}
              // propogateOnChange
            />
          </div>
        </ValueChangerButtons>
        <VerticalGroup gap={1} center className="mt-2">
          <SecondaryText>% of Total New Funding</SecondaryText>
          <div className="grid grid-cols-3 gap-1">
            {percentages.map(p => (
              <Button
                key={p}
                buttonType="secondary"
                size="small"
                rounded="full"
                onClick={handlePercentageClick(p)}
              >
                <SecondaryText colour="black" size="xs">
                  {percentage(p * 10000, 0)}
                </SecondaryText>
              </Button>
            ))}
          </div>
        </VerticalGroup>
      </VerticalGroup>
    </Modal>
  )
}

export default TreasuryWSFProfileModal
