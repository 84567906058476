import { PeerInsightsResponse } from '../../../../../types/gameApi/peerInsights'

export const data: PeerInsightsResponse = {
  marketShare: [
    {
      type: 'HOME_LOANS',
      name: 'HOME LOANS',
      teams: [
        {
          team: 1,
          type: 'HOME_LOANS',
          name: 'Alpha Bank',
          values: {
            balances: 211667,
            marketShare: 1323,
            interestRate: 504,
            commission: 18,
            npat: 1092,
          },
          lastValues: {
            balances: 182855,
            marketShare: 1280,
            interestRate: 476,
            commission: 18,
            npat: 886,
          },
        },
        {
          team: 3,
          type: 'HOME_LOANS',
          name: 'Gamma Bank',
          values: {
            balances: 210040,
            marketShare: 1312,
            interestRate: 498,
            commission: 18,
            npat: 1056,
          },
          lastValues: {
            balances: 184166,
            marketShare: 1289,
            interestRate: 468,
            commission: 18,
            npat: 883,
          },
        },
        {
          team: 5,
          type: 'HOME_LOANS',
          name: 'Sigma Bank',
          values: {
            balances: 209215,
            marketShare: 1307,
            interestRate: 493,
            commission: 11,
            npat: -1537,
          },
          lastValues: {
            balances: 180892,
            marketShare: 1266,
            interestRate: 468,
            commission: 11,
            npat: 182,
          },
        },
        {
          team: 6,
          type: 'HOME_LOANS',
          name: 'Omega Bank',
          values: {
            balances: 202881,
            marketShare: 1268,
            interestRate: 522,
            commission: 25,
            npat: 1200,
          },
          lastValues: {
            balances: 177611,
            marketShare: 1243,
            interestRate: 497,
            commission: 20,
            npat: 1102,
          },
        },
        {
          team: 7,
          type: 'HOME_LOANS',
          name: 'PC Bank 1',
          values: {
            balances: 201352,
            marketShare: 1258,
            interestRate: 508,
            commission: 17,
            npat: 1129,
          },
          lastValues: {
            balances: 179430,
            marketShare: 1256,
            interestRate: 481,
            commission: 16,
            npat: 964,
          },
        },
        {
          team: 2,
          type: 'HOME_LOANS',
          name: 'Beta Bank',
          values: {
            balances: 192884,
            marketShare: 1205,
            interestRate: 507,
            commission: 17,
            npat: 1180,
          },
          lastValues: {
            balances: 176802,
            marketShare: 1237,
            interestRate: 477,
            commission: 17,
            npat: 900,
          },
        },
        {
          team: 4,
          type: 'HOME_LOANS',
          name: 'Delta Bank',
          values: {
            balances: 187289,
            marketShare: 1170,
            interestRate: 523,
            commission: 10,
            npat: 1203,
          },
          lastValues: {
            balances: 173578,
            marketShare: 1215,
            interestRate: 498,
            commission: 10,
            npat: 1167,
          },
        },
        {
          team: 8,
          type: 'HOME_LOANS',
          name: 'PC Bank 2',
          values: {
            balances: 184539,
            marketShare: 1153,
            interestRate: 533,
            commission: 13,
            npat: 1385,
          },
          lastValues: {
            balances: 173014,
            marketShare: 1211,
            interestRate: 498,
            commission: 14,
            npat: 1158,
          },
        },
      ],
    },
    {
      type: 'BUSINESS_LOANS',
      name: 'BUSINESS LOANS',
      teams: [
        {
          team: 3,
          type: 'BUSINESS_LOANS',
          name: 'Gamma Bank',
          values: {
            balances: 77363,
            marketShare: 1485,
            interestRate: 628,
            npat: 946,
          },
          lastValues: {
            balances: 65110,
            marketShare: 1350,
            interestRate: 598,
            npat: 799,
          },
        },
        {
          team: 5,
          type: 'BUSINESS_LOANS',
          name: 'Sigma Bank',
          values: {
            balances: 71282,
            marketShare: 1368,
            interestRate: 654,
            npat: 124,
          },
          lastValues: {
            balances: 64593,
            marketShare: 1340,
            interestRate: 602,
            npat: 541,
          },
        },
        {
          team: 7,
          type: 'BUSINESS_LOANS',
          name: 'PC Bank 1',
          values: {
            balances: 68924,
            marketShare: 1323,
            interestRate: 653,
            npat: 943,
          },
          lastValues: {
            balances: 62209,
            marketShare: 1290,
            interestRate: 622,
            npat: 840,
          },
        },
        {
          team: 6,
          type: 'BUSINESS_LOANS',
          name: 'Omega Bank',
          values: {
            balances: 62867,
            marketShare: 1206,
            interestRate: 650,
            npat: 817,
          },
          lastValues: {
            balances: 58648,
            marketShare: 1216,
            interestRate: 625,
            npat: 806,
          },
        },
        {
          team: 1,
          type: 'BUSINESS_LOANS',
          name: 'Alpha Bank',
          values: {
            balances: 61122,
            marketShare: 1173,
            interestRate: 667,
            npat: 879,
          },
          lastValues: {
            balances: 57974,
            marketShare: 1202,
            interestRate: 643,
            npat: 871,
          },
        },
        {
          team: 4,
          type: 'BUSINESS_LOANS',
          name: 'Delta Bank',
          values: {
            balances: 60954,
            marketShare: 1170,
            interestRate: 653,
            npat: 820,
          },
          lastValues: {
            balances: 58124,
            marketShare: 1205,
            interestRate: 628,
            npat: 830,
          },
        },
        {
          team: 8,
          type: 'BUSINESS_LOANS',
          name: 'PC Bank 2',
          values: {
            balances: 60064,
            marketShare: 1153,
            interestRate: 653,
            npat: 856,
          },
          lastValues: {
            balances: 58037,
            marketShare: 1204,
            interestRate: 623,
            npat: 817,
          },
        },
        {
          team: 2,
          type: 'BUSINESS_LOANS',
          name: 'Beta Bank',
          values: {
            balances: 58300,
            marketShare: 1119,
            interestRate: 668,
            npat: 968,
          },
          lastValues: {
            balances: 57265,
            marketShare: 1188,
            interestRate: 638,
            npat: 861,
          },
        },
      ],
    },
    {
      type: 'DEPOSITS',
      name: 'DEPOSITS',
      teams: [
        {
          team: 1,
          type: 'DEPOSITS',
          name: 'Alpha Bank',
          values: {
            balances: 180005,
            marketShare: 1325,
            interestRate: 299,
            branches: 815,
            npat: 952,
          },
          lastValues: {
            balances: 166856,
            marketShare: 1289,
            interestRate: 274,
            branches: 815,
            npat: 784,
          },
        },
        {
          team: 2,
          type: 'DEPOSITS',
          name: 'Beta Bank',
          values: {
            balances: 174085,
            marketShare: 1281,
            interestRate: 303,
            branches: 815,
            npat: 890,
          },
          lastValues: {
            balances: 162487,
            marketShare: 1256,
            interestRate: 273,
            branches: 815,
            npat: 779,
          },
        },
        {
          team: 6,
          type: 'DEPOSITS',
          name: 'Omega Bank',
          values: {
            balances: 172376,
            marketShare: 1269,
            interestRate: 296,
            branches: 818,
            npat: 844,
          },
          lastValues: {
            balances: 161710,
            marketShare: 1250,
            interestRate: 271,
            branches: 805,
            npat: 789,
          },
        },
        {
          team: 5,
          type: 'DEPOSITS',
          name: 'Sigma Bank',
          values: {
            balances: 172053,
            marketShare: 1266,
            interestRate: 296,
            branches: 817,
            npat: 2864,
          },
          lastValues: {
            balances: 161606,
            marketShare: 1249,
            interestRate: 271,
            branches: 817,
            npat: 1216,
          },
        },
        {
          team: 7,
          type: 'DEPOSITS',
          name: 'PC Bank 1',
          values: {
            balances: 169877,
            marketShare: 1250,
            interestRate: 293,
            branches: 811,
            npat: 915,
          },
          lastValues: {
            balances: 161806,
            marketShare: 1250,
            interestRate: 270,
            branches: 809,
            npat: 807,
          },
        },
        {
          team: 3,
          type: 'DEPOSITS',
          name: 'Gamma Bank',
          values: {
            balances: 167253,
            marketShare: 1231,
            interestRate: 283,
            branches: 820,
            npat: 1017,
          },
          lastValues: {
            balances: 162849,
            marketShare: 1258,
            interestRate: 273,
            branches: 820,
            npat: 705,
          },
        },
        {
          team: 8,
          type: 'DEPOSITS',
          name: 'PC Bank 2',
          values: {
            balances: 161708,
            marketShare: 1190,
            interestRate: 278,
            branches: 790,
            npat: 1088,
          },
          lastValues: {
            balances: 158293,
            marketShare: 1223,
            interestRate: 258,
            branches: 795,
            npat: 948,
          },
        },
        {
          team: 4,
          type: 'DEPOSITS',
          name: 'Delta Bank',
          values: {
            balances: 160801,
            marketShare: 1183,
            interestRate: 278,
            branches: 780,
            npat: 1148,
          },
          lastValues: {
            balances: 157877,
            marketShare: 1220,
            interestRate: 258,
            branches: 780,
            npat: 968,
          },
        },
      ],
    },
    {
      type: 'CREDIT_CARDS',
      name: 'CREDIT CARDS',
      teams: [
        {
          team: 3,
          type: 'CREDIT_CARDS',
          name: 'Gamma Bank',
          values: {
            customers: 1665864,
            marketShare: 1320,
            annualFee: 265,
            loyaltyPoints: 145,
            npat: 273,
          },
          lastValues: {
            customers: 1773186,
            marketShare: 1317,
            annualFee: 230,
            loyaltyPoints: 145,
            npat: 298,
          },
        },
        {
          team: 5,
          type: 'CREDIT_CARDS',
          name: 'Sigma Bank',
          values: {
            customers: 1628450,
            marketShare: 1291,
            annualFee: 264,
            loyaltyPoints: 137,
            npat: 99,
          },
          lastValues: {
            customers: 1737141,
            marketShare: 1291,
            annualFee: 234,
            loyaltyPoints: 137,
            npat: 242,
          },
        },
        {
          team: 6,
          type: 'CREDIT_CARDS',
          name: 'Omega Bank',
          values: {
            customers: 1615480,
            marketShare: 1280,
            annualFee: 271,
            loyaltyPoints: 146,
            npat: 223,
          },
          lastValues: {
            customers: 1708854,
            marketShare: 1269,
            annualFee: 255,
            loyaltyPoints: 131,
            npat: 332,
          },
        },
        {
          team: 1,
          type: 'CREDIT_CARDS',
          name: 'Alpha Bank',
          values: {
            customers: 1597671,
            marketShare: 1266,
            annualFee: 275,
            loyaltyPoints: 150,
            npat: 227,
          },
          lastValues: {
            customers: 1675718,
            marketShare: 1245,
            annualFee: 275,
            loyaltyPoints: 150,
            npat: 297,
          },
        },
        {
          team: 7,
          type: 'CREDIT_CARDS',
          name: 'PC Bank 1',
          values: {
            customers: 1553384,
            marketShare: 1231,
            annualFee: 271,
            loyaltyPoints: 140,
            npat: 245,
          },
          lastValues: {
            customers: 1661924,
            marketShare: 1235,
            annualFee: 254,
            loyaltyPoints: 137,
            npat: 312,
          },
        },
        {
          team: 2,
          type: 'CREDIT_CARDS',
          name: 'Beta Bank',
          values: {
            customers: 1553349,
            marketShare: 1231,
            annualFee: 270,
            loyaltyPoints: 145,
            npat: 318,
          },
          lastValues: {
            customers: 1651665,
            marketShare: 1227,
            annualFee: 270,
            loyaltyPoints: 145,
            npat: 316,
          },
        },
        {
          team: 8,
          type: 'CREDIT_CARDS',
          name: 'PC Bank 2',
          values: {
            customers: 1501558,
            marketShare: 1190,
            annualFee: 260,
            loyaltyPoints: 115,
            npat: 315,
          },
          lastValues: {
            customers: 1625790,
            marketShare: 1208,
            annualFee: 255,
            loyaltyPoints: 120,
            npat: 369,
          },
        },
        {
          team: 4,
          type: 'CREDIT_CARDS',
          name: 'Delta Bank',
          values: {
            customers: 1496092,
            marketShare: 1186,
            annualFee: 280,
            loyaltyPoints: 115,
            npat: 318,
          },
          lastValues: {
            customers: 1621435,
            marketShare: 1205,
            annualFee: 260,
            loyaltyPoints: 115,
            npat: 384,
          },
        },
      ],
    },
  ],
  data: [
    {
      enabled: true,
      type: 'NPS',
      data: [
        {
          team: 1,
          name: 'Alpha Bank',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 13,
            },
            {
              round: 3,
              value: 34,
            },
          ],
        },
        {
          team: 6,
          name: 'Omega Bank',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 6,
            },
            {
              round: 3,
              value: 23,
            },
          ],
        },
        {
          team: 5,
          name: 'Sigma Bank',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 4,
            },
            {
              round: 3,
              value: 19,
            },
          ],
        },
        {
          team: 7,
          name: 'PC Bank 1',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 6,
            },
            {
              round: 3,
              value: 13,
            },
          ],
        },
        {
          team: 3,
          name: 'Gamma Bank',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 5,
            },
            {
              round: 3,
              value: 12,
            },
          ],
        },
        {
          team: 4,
          name: 'Delta Bank',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 1,
            },
            {
              round: 3,
              value: 6,
            },
          ],
        },
        {
          team: 8,
          name: 'PC Bank 2',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 0,
            },
            {
              round: 3,
              value: 4,
            },
          ],
        },
        {
          team: 2,
          name: 'Beta Bank',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 1,
            },
            {
              round: 3,
              value: 2,
            },
          ],
        },
      ],
      businessTypes: [
        {
          type: 'HOME_LOANS',
          name: 'HOME LOANS',
          data: [
            {
              team: 1,
              name: 'Alpha Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 15,
                },
                {
                  round: 3,
                  value: 40,
                },
              ],
            },
            {
              team: 6,
              name: 'Omega Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 6,
                },
                {
                  round: 3,
                  value: 24,
                },
              ],
            },
            {
              team: 5,
              name: 'Sigma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 5,
                },
                {
                  round: 3,
                  value: 21,
                },
              ],
            },
            {
              team: 7,
              name: 'PC Bank 1',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 7,
                },
                {
                  round: 3,
                  value: 14,
                },
              ],
            },
            {
              team: 3,
              name: 'Gamma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 5,
                },
                {
                  round: 3,
                  value: 12,
                },
              ],
            },
            {
              team: 4,
              name: 'Delta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 1,
                },
                {
                  round: 3,
                  value: 8,
                },
              ],
            },
            {
              team: 8,
              name: 'PC Bank 2',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 0,
                },
                {
                  round: 3,
                  value: 2,
                },
              ],
            },
            {
              team: 2,
              name: 'Beta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 1,
                },
                {
                  round: 3,
                  value: 1,
                },
              ],
            },
          ],
        },
        {
          type: 'BUSINESS_LOANS',
          name: 'BUSINESS LOANS',
          data: [
            {
              team: 1,
              name: 'Alpha Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 14,
                },
                {
                  round: 3,
                  value: 36,
                },
              ],
            },
            {
              team: 6,
              name: 'Omega Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 6,
                },
                {
                  round: 3,
                  value: 25,
                },
              ],
            },
            {
              team: 5,
              name: 'Sigma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 6,
                },
                {
                  round: 3,
                  value: 21,
                },
              ],
            },
            {
              team: 7,
              name: 'PC Bank 1',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 7,
                },
                {
                  round: 3,
                  value: 15,
                },
              ],
            },
            {
              team: 3,
              name: 'Gamma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 6,
                },
                {
                  round: 3,
                  value: 14,
                },
              ],
            },
            {
              team: 4,
              name: 'Delta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 2,
                },
                {
                  round: 3,
                  value: 8,
                },
              ],
            },
            {
              team: 8,
              name: 'PC Bank 2',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 0,
                },
                {
                  round: 3,
                  value: 4,
                },
              ],
            },
            {
              team: 2,
              name: 'Beta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 0,
                },
                {
                  round: 3,
                  value: -1,
                },
              ],
            },
          ],
        },
        {
          type: 'DEPOSITS',
          name: 'DEPOSITS',
          data: [
            {
              team: 1,
              name: 'Alpha Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 11,
                },
                {
                  round: 3,
                  value: 28,
                },
              ],
            },
            {
              team: 6,
              name: 'Omega Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 6,
                },
                {
                  round: 3,
                  value: 20,
                },
              ],
            },
            {
              team: 5,
              name: 'Sigma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 1,
                },
                {
                  round: 3,
                  value: 13,
                },
              ],
            },
            {
              team: 7,
              name: 'PC Bank 1',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 3,
                },
                {
                  round: 3,
                  value: 8,
                },
              ],
            },
            {
              team: 3,
              name: 'Gamma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 4,
                },
                {
                  round: 3,
                  value: 7,
                },
              ],
            },
            {
              team: 8,
              name: 'PC Bank 2',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 1,
                },
                {
                  round: 3,
                  value: 5,
                },
              ],
            },
            {
              team: 2,
              name: 'Beta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 3,
                },
                {
                  round: 3,
                  value: 4,
                },
              ],
            },
            {
              team: 4,
              name: 'Delta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: -2,
                },
                {
                  round: 3,
                  value: 1,
                },
              ],
            },
          ],
        },
        {
          type: 'CREDIT_CARDS',
          name: 'CREDIT CARDS',
          data: [
            {
              team: 1,
              name: 'Alpha Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 13,
                },
                {
                  round: 3,
                  value: 33,
                },
              ],
            },
            {
              team: 6,
              name: 'Omega Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 5,
                },
                {
                  round: 3,
                  value: 22,
                },
              ],
            },
            {
              team: 5,
              name: 'Sigma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 4,
                },
                {
                  round: 3,
                  value: 19,
                },
              ],
            },
            {
              team: 7,
              name: 'PC Bank 1',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 6,
                },
                {
                  round: 3,
                  value: 14,
                },
              ],
            },
            {
              team: 3,
              name: 'Gamma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 5,
                },
                {
                  round: 3,
                  value: 12,
                },
              ],
            },
            {
              team: 4,
              name: 'Delta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 1,
                },
                {
                  round: 3,
                  value: 8,
                },
              ],
            },
            {
              team: 8,
              name: 'PC Bank 2',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 0,
                },
                {
                  round: 3,
                  value: 4,
                },
              ],
            },
            {
              team: 2,
              name: 'Beta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 2,
                },
                {
                  round: 3,
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      enabled: true,
      type: 'Complaints',
      data: [
        {
          team: 8,
          name: 'PC Bank 2',
          value: 794,
          last: 768,
        },
        {
          team: 2,
          name: 'Beta Bank',
          value: 763,
          last: 756,
        },
        {
          team: 4,
          name: 'Delta Bank',
          value: 697,
          last: 761,
        },
        {
          team: 7,
          name: 'PC Bank 1',
          value: 667,
          last: 706,
        },
        {
          team: 3,
          name: 'Gamma Bank',
          value: 595,
          last: 649,
        },
        {
          team: 1,
          name: 'Alpha Bank',
          value: 585,
          last: 671,
        },
        {
          team: 6,
          name: 'Omega Bank',
          value: 554,
          last: 656,
        },
        {
          team: 5,
          name: 'Sigma Bank',
          value: 549,
          last: 673,
        },
      ],
    },
    {
      enabled: true,
      type: 'TSR',
      data: [
        {
          team: 6,
          name: 'Omega Bank',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 1122,
            },
            {
              round: 3,
              value: 1193,
            },
          ],
        },
        {
          team: 1,
          name: 'Alpha Bank',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 1055,
            },
            {
              round: 3,
              value: 1169,
            },
          ],
        },
        {
          team: 7,
          name: 'PC Bank 1',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 1000,
            },
            {
              round: 3,
              value: 982,
            },
          ],
        },
        {
          team: 4,
          name: 'Delta Bank',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 897,
            },
            {
              round: 3,
              value: 938,
            },
          ],
        },
        {
          team: 2,
          name: 'Beta Bank',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 945,
            },
            {
              round: 3,
              value: 858,
            },
          ],
        },
        {
          team: 3,
          name: 'Gamma Bank',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 794,
            },
            {
              round: 3,
              value: 800,
            },
          ],
        },
        {
          team: 8,
          name: 'PC Bank 2',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 784,
            },
            {
              round: 3,
              value: 795,
            },
          ],
        },
        {
          team: 5,
          name: 'Sigma Bank',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 898,
            },
            {
              round: 3,
              value: 494,
            },
          ],
        },
      ],
    },
    {
      enabled: true,
      type: 'EmployeeEngagement',
      data: [
        {
          team: 6,
          name: 'Omega Bank',
          value: 6424,
        },
        {
          team: 7,
          name: 'PC Bank 1',
          value: 5998,
        },
        {
          team: 1,
          name: 'Alpha Bank',
          value: 5901,
        },
        {
          team: 4,
          name: 'Delta Bank',
          value: 5638,
        },
        {
          team: 5,
          name: 'Sigma Bank',
          value: 5624,
        },
        {
          team: 2,
          name: 'Beta Bank',
          value: 5525,
        },
        {
          team: 3,
          name: 'Gamma Bank',
          value: 5236,
        },
        {
          team: 8,
          name: 'PC Bank 2',
          value: 4395,
        },
      ],
    },
    {
      enabled: true,
      type: 'Community',
      data: [
        {
          team: 1,
          name: 'Alpha Bank',
          value: 5250,
        },
        {
          team: 7,
          name: 'PC Bank 1',
          value: 5000,
        },
        {
          team: 5,
          name: 'Sigma Bank',
          value: 4650,
        },
        {
          team: 6,
          name: 'Omega Bank',
          value: 4650,
        },
        {
          team: 2,
          name: 'Beta Bank',
          value: 4500,
        },
        {
          team: 4,
          name: 'Delta Bank',
          value: 4500,
        },
        {
          team: 8,
          name: 'PC Bank 2',
          value: 4500,
        },
        {
          team: 3,
          name: 'Gamma Bank',
          value: 4400,
        },
      ],
    },
    {
      enabled: true,
      type: 'Rating',
      data: [
        {
          team: 6,
          name: 'Omega Bank',
          value: 1112,
          rating: 'A',
        },
        {
          team: 2,
          name: 'Beta Bank',
          value: 1104,
          rating: 'A',
        },
        {
          team: 7,
          name: 'PC Bank 1',
          value: 1060,
          rating: 'A',
        },
        {
          team: 8,
          name: 'PC Bank 2',
          value: 1060,
          rating: 'A',
        },
        {
          team: 1,
          name: 'Alpha Bank',
          value: 1048,
          rating: 'BBB',
        },
        {
          team: 3,
          name: 'Gamma Bank',
          value: 983,
          rating: 'BBB',
        },
        {
          team: 4,
          name: 'Delta Bank',
          value: 978,
          rating: 'BBB',
        },
        {
          team: 5,
          name: 'Sigma Bank',
          value: 933,
          rating: 'BB',
        },
      ],
    },
    {
      enabled: false,
      type: 'Customers',
      data: [
        {
          team: 1,
          name: 'Alpha Bank',
          value: 3519862,
        },
        {
          team: 3,
          name: 'Gamma Bank',
          value: 3497018,
        },
        {
          team: 5,
          name: 'Sigma Bank',
          value: 3468138,
        },
        {
          team: 6,
          name: 'Omega Bank',
          value: 3414617,
        },
        {
          team: 7,
          name: 'PC Bank 1',
          value: 3397802,
        },
        {
          team: 2,
          name: 'Beta Bank',
          value: 3380949,
        },
        {
          team: 4,
          name: 'Delta Bank',
          value: 3237655,
        },
        {
          team: 8,
          name: 'PC Bank 2',
          value: 3235119,
        },
      ],
    },
    {
      enabled: false,
      type: 'LIE',
      data: [
        {
          team: 3,
          name: 'Gamma Bank',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 20,
            },
            {
              round: 3,
              value: 22,
            },
          ],
        },
        {
          team: 1,
          name: 'Alpha Bank',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 20,
            },
            {
              round: 3,
              value: 21,
            },
          ],
        },
        {
          team: 4,
          name: 'Delta Bank',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 20,
            },
            {
              round: 3,
              value: 21,
            },
          ],
        },
        {
          team: 5,
          name: 'Sigma Bank',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 20,
            },
            {
              round: 3,
              value: 21,
            },
          ],
        },
        {
          team: 6,
          name: 'Omega Bank',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 20,
            },
            {
              round: 3,
              value: 21,
            },
          ],
        },
        {
          team: 7,
          name: 'PC Bank 1',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 20,
            },
            {
              round: 3,
              value: 21,
            },
          ],
        },
        {
          team: 2,
          name: 'Beta Bank',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 19,
            },
            {
              round: 3,
              value: 20,
            },
          ],
        },
        {
          team: 8,
          name: 'PC Bank 2',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 19,
            },
            {
              round: 3,
              value: 20,
            },
          ],
        },
      ],
    },
    {
      enabled: false,
      type: 'RegulatorActions',
      data: [
        {
          team: 1,
          name: 'Alpha Bank',
          value: 0,
        },
        {
          team: 2,
          name: 'Beta Bank',
          value: 0,
        },
        {
          team: 3,
          name: 'Gamma Bank',
          value: 0,
        },
        {
          team: 4,
          name: 'Delta Bank',
          value: 0,
        },
        {
          team: 5,
          name: 'Sigma Bank',
          value: 0,
        },
        {
          team: 6,
          name: 'Omega Bank',
          value: 0,
        },
        {
          team: 7,
          name: 'PC Bank 1',
          value: 0,
        },
        {
          team: 8,
          name: 'PC Bank 2',
          value: 0,
        },
      ],
    },
    {
      enabled: false,
      type: 'RiskIncidents',
      data: [
        {
          team: 4,
          name: 'Delta Bank',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 747,
            },
            {
              round: 3,
              value: 738,
            },
          ],
        },
        {
          team: 3,
          name: 'Gamma Bank',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 742,
            },
            {
              round: 3,
              value: 733,
            },
          ],
        },
        {
          team: 8,
          name: 'PC Bank 2',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 737,
            },
            {
              round: 3,
              value: 732,
            },
          ],
        },
        {
          team: 2,
          name: 'Beta Bank',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 727,
            },
            {
              round: 3,
              value: 706,
            },
          ],
        },
        {
          team: 7,
          name: 'PC Bank 1',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 718,
            },
            {
              round: 3,
              value: 669,
            },
          ],
        },
        {
          team: 5,
          name: 'Sigma Bank',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 702,
            },
            {
              round: 3,
              value: 644,
            },
          ],
        },
        {
          team: 6,
          name: 'Omega Bank',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 708,
            },
            {
              round: 3,
              value: 637,
            },
          ],
        },
        {
          team: 1,
          name: 'Alpha Bank',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 706,
            },
            {
              round: 3,
              value: 633,
            },
          ],
        },
      ],
    },
    {
      enabled: false,
      type: 'ROE',
      data: [
        {
          team: 8,
          name: 'PC Bank 2',
          value: 2161,
          last: 1940,
        },
        {
          team: 4,
          name: 'Delta Bank',
          value: 2136,
          last: 2087,
        },
        {
          team: 2,
          name: 'Beta Bank',
          value: 1829,
          last: 1646,
        },
        {
          team: 3,
          name: 'Gamma Bank',
          value: 1783,
          last: 1486,
        },
        {
          team: 7,
          name: 'PC Bank 1',
          value: 1781,
          last: 1728,
        },
        {
          team: 6,
          name: 'Omega Bank',
          value: 1731,
          last: 1700,
        },
        {
          team: 1,
          name: 'Alpha Bank',
          value: 1642,
          last: 1651,
        },
        {
          team: 5,
          name: 'Sigma Bank',
          value: 855,
          last: 1266,
        },
      ],
    },
  ],
}
