import React from 'react'
import {
  AssetOrDeductionItem,
  ForecastDeductions,
  RiskWeightedAssets,
  TreasuryRiskWeightedAssetsTranslations,
} from './types'
import { formatValue, percentage } from '../../../../lib/formatters'
import ValueChangerWithInput from '../../../molecules/ValueChanger/ValueChangerWithInput'
import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'
import { DecisionMap } from '../TreasuryContainer'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import TableRow from '../../../atoms/Table/TableRow'
import TableCell from '../../../atoms/Table/TableCell'
import Table from '../../../atoms/Table/Table'
import TableHeader from '../../../atoms/Table/TableHeader'

import TableHeaderCell from '../../../atoms/Table/TableHeaderCell'
import TableText from '../../../atoms/Table/TableText'
import { titleCase } from '../../../../lib/text'
import { TreasuryResponse } from '../../../../types/gameApi/treasury'
import Button from '../../../atoms/Button/Button'
import { Icon } from '../../../atoms/Icon/Icon'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'

interface Props {
  data: TreasuryResponse
  decisions: DecisionMap
  translations: TreasuryRiskWeightedAssetsTranslations
  riskWeightedAssets: RiskWeightedAssets
  forecastDeductions: ForecastDeductions
  assets: AssetOrDeductionItem[]
  deductions: AssetOrDeductionItem[]
  enableChanges: boolean
  handleOpenResetModal: () => void

  onGrowthChange: (params: {
    growthField: keyof DecisionMap
    value: number
  }) => void
  onChangeValue: (val: Partial<DecisionMap>) => void
}

const AssetDeductionRow = ({
  item,
  enableChanges,
  onGrowthChange,
  onChangeValue,
}: {
  item: AssetOrDeductionItem
  data: TreasuryResponse
  enableChanges: boolean

  onGrowthChange: (params: {
    growthField: keyof DecisionMap
    value: number
  }) => void
  onChangeValue: (val: Partial<DecisionMap>) => void
}) => {
  return (
    <TableRow noDivideX>
      <TableCell noRightBorder>
        <TableText>{titleCase(item.name)}</TableText>
      </TableCell>
      <TableCell noRightBorder align="center">
        <TableText>{formatValue(item.currentBalance)}</TableText>
      </TableCell>
      <TableCell noRightBorder align="center">
        <ValueChangerWithInput
          tableText
          modalProps={{
            heading: 'GROWTH %',
          }}
          type="percentage"
          value={item.growth}
          formatValue={percentage}
          onChange={(val: number) => {
            onGrowthChange({ growthField: item.growthField, value: val })
          }}
          enableChanges={enableChanges}
          increments={[1, 5]}
          step={100}
          buttonIdPrefix="tour-growth-rates"
        />
      </TableCell>
      <TableCell noRightBorder align="center" className="w-20">
        <TableText>{formatValue(Math.floor(item.forecastBalance))}</TableText>
      </TableCell>
      <TableCell align="center">
        {item.riskWeight != null ? (
          <ValueChangerWithInput
            tableText
            modalProps={{
              heading: 'RISK WEIGHT %',
            }}
            type="percentage"
            value={item.riskWeight}
            formatValue={percentage}
            onChange={value => {
              onChangeValue({ [item.riskField]: value })
            }}
            enableChanges={enableChanges}
            increments={[1, 5]}
            step={100}
            previousValue={item.riskFieldOriginal}
            min={0}
            max={10000}
            buttonIdPrefix="tour-risk-weight"
          />
        ) : (
          <span>&nbsp;</span>
        )}
      </TableCell>
      <TableCell noRightBorder align="center">
        <TableText>{formatValue(Math.floor(item.total))}</TableText>
      </TableCell>
    </TableRow>
  )
}
const TreasuryRiskWeightedAssetsTable: React.FC<Props> = ({
  decisions,
  translations,
  assets,
  deductions,
  enableChanges,
  riskWeightedAssets,
  forecastDeductions,
  onGrowthChange,
  onChangeValue,
  data,
  handleOpenResetModal,
}) => {
  return (
    <VerticalGroup fullWidth gap={1}>
      <Table noDivideY>
        <TableHeader noDivideX>
          <TableHeaderCell className="text-start" align="left">
            <TableText weight="bold" whitespace="wrap">
              {translations.assets.forecastRiskWeightedAssets}
            </TableText>
          </TableHeaderCell>

          <TableHeaderCell id="test" className="text-center">
            <TableText center whitespace="wrap" weight="bold">
              {translations.assets.currentBalance}
            </TableText>
          </TableHeaderCell>
          <TableHeaderCell align="center">
            <TableText weight="bold">
              {translations.assets.growth} {translations.percentage}
            </TableText>
          </TableHeaderCell>
          <TableHeaderCell align="center">
            <TableText weight="bold" whitespace="wrap">
              {translations.assets.forecastBalance}*
            </TableText>
          </TableHeaderCell>
          <TableHeaderCell align="center">
            <HorizontalGroup
              className="w-44 justify-end pr-1"
              gap={2}
              verticalCenter
            >
              <TableText weight="bold">
                {translations.assets.riskWeight} {translations.percentage}
              </TableText>
              <Button
                onClick={handleOpenResetModal}
                rounded="full"
                size="xs"
                buttonType="bordered"
              >
                <Icon colour="black" type="reset" />
              </Button>
            </HorizontalGroup>
          </TableHeaderCell>
          <TableHeaderCell align="center">
            <TableText weight="bold" whitespace="wrap">
              {translations.assets.riskWeightedAssets}*
            </TableText>
          </TableHeaderCell>
        </TableHeader>

        {assets.map((asset, i) => (
          <AssetDeductionRow
            data={data}
            key={i}
            item={asset}
            enableChanges={enableChanges}
            onGrowthChange={onGrowthChange}
            onChangeValue={onChangeValue}
          />
        ))}
        <TableRow noDivideX className="border-t">
          <TableCell noRightBorder colSpan={4}>
            <TableText>
              <TranslatedText
                whitespace="wrap"
                value="treasury.screen3.table1Total1Label"
                default="Total Credit Risk Weighted Assets"
                suffix=" *"
                size="xs"
                family="secondary"
              />
            </TableText>
          </TableCell>
          <TableCell></TableCell>
          <TableCell noRightBorder align="center">
            <TableText>
              {formatValue(
                Math.floor(riskWeightedAssets.totalCreditRiskWeightedAsset),
              )}
            </TableText>
          </TableCell>
        </TableRow>
        <TableRow noDivideX>
          <TableCell noRightBorder colSpan={4}>
            <TranslatedText
              value="treasury.screen3.table1Total2Label"
              default="Operational Risk (as % of Total Credit Risk RWA)"
              suffix=" *"
              size="xs"
              family="secondary"
            />
          </TableCell>
          <TableCell align="center">
            <ValueChangerWithInput
              modalProps={{
                heading: 'RISK WEIGHT %',
              }}
              type="percentage"
              value={decisions.opexRisk}
              formatValue={percentage}
              onChange={value => {
                onChangeValue({ opexRisk: value })
              }}
              enableChanges={enableChanges}
              increments={[1, 5]}
              step={100}
              previousValue={data.opexRiskOriginal}
              min={0}
              max={10000}
            />
          </TableCell>

          <TableCell noRightBorder align="center">
            <TableText>
              {formatValue(
                Math.floor(riskWeightedAssets.operationalRiskWeightedAsset),
              )}
            </TableText>
          </TableCell>
        </TableRow>
        <TableRow noDivideX>
          <TableCell noRightBorder colSpan={4}>
            <TranslatedText
              weight="bold"
              value="treasury.screen3.table1Total3Label"
              default="TOTAL RISK WEIGHTED ASSETS ( ESTIMATED )"
              suffix="*"
              size="xs"
              family="secondary"
            />
          </TableCell>
          <TableCell></TableCell>
          <TableCell noRightBorder align="center">
            <TableText weight="bold" colour="primary">
              {formatValue(
                Math.floor(riskWeightedAssets.totalRiskWeightedAsset),
              )}
            </TableText>
          </TableCell>
        </TableRow>

        <TableHeader noDivideX>
          <TableHeaderCell className="text-start" align="left">
            <TableText weight="bold" whitespace="wrap">
              {translations.deductions.forecastCapitalDeductions}
            </TableText>
          </TableHeaderCell>

          <TableHeaderCell align="center" className="w-20">
            <TableText className="w-20" weight="bold" whitespace="wrap">
              {translations.deductions.currentBalance}{' '}
            </TableText>
          </TableHeaderCell>
          <TableHeaderCell align="center" className="w-20">
            <TableText className="w-20" weight="bold">
              {translations.deductions.growth} {translations.percentage}
            </TableText>
          </TableHeaderCell>
          <TableHeaderCell align="center">
            <TableText weight="bold" whitespace="wrap">
              {translations.deductions.forecastBalance}*{' '}
            </TableText>
          </TableHeaderCell>
          <TableHeaderCell>&nbsp;</TableHeaderCell>

          <TableHeaderCell align="center">
            <TableText weight="bold" whitespace="wrap">
              {translations.deductions.forecastDeductions}{' '}
            </TableText>
          </TableHeaderCell>
        </TableHeader>

        {deductions.map((deduction, i) => (
          <AssetDeductionRow
            data={data}
            key={i}
            item={deduction}
            enableChanges={enableChanges}
            onGrowthChange={onGrowthChange}
            onChangeValue={onChangeValue}
          />
        ))}

        <TableRow divideY>
          <TableCell className="border-t" colSpan={5}>
            <TranslatedText
              colour="primary"
              weight="bold"
              value="treasury.screen3.table2Total1Label"
              default="TOTAL CAPITAL DEDUCTIONS"
              suffix="*"
              size="xs"
              family="secondary"
            />
          </TableCell>
          <TableCell align="center">
            <TableText weight="bold" colour="primary">
              {formatValue(
                Math.floor(forecastDeductions.totalCapitalDeductions),
              )}
            </TableText>
          </TableCell>
        </TableRow>
      </Table>
      <TranslatedText
        colour="gray"
        value="treasury.screen3.table2FootNote"
        default="indicates estimates only which are subject to actual performance"
        prefix="* "
      />
    </VerticalGroup>
  )
}

export default TreasuryRiskWeightedAssetsTable
