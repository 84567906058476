import React, { useMemo, Fragment, ReactNode } from 'react'
import VerticalGroup from '../../../../atoms/VerticalGroup/VerticalGroup'
import { ChartProps } from '../SummaryMetricMapper'
import BarChart, {
  createLegend,
} from '../../../../organisms/Charts/BarChart/BarChart'
import ShowIfMobileLandscape from '../../../../atoms/HideIfMobile/ShowIfMobileLandscape'
import HorizontalGroup from '../../../../atoms/HorizontalGroup/HorizontalGroup'
import ChartContainer from './ChartContainer'
import { BarChartData } from '../../../../organisms/Charts/BarChart/types'
import { getYAxisDataV2 } from '../../../../organisms/Charts/yAxis'
import { colours } from '../../../../../constants/colours'
import { Bar, LabelList } from 'recharts'
import classNames from 'classnames'
import SecondaryText from '../../../../atoms/Text/SecondaryText'
import HideIfMobileLandscape from '../../../../atoms/HideIfMobile/HideIfMobileLandscape'

const TopLabel = (
  formatter: (val: any) => string,
  isCost?: boolean,
): React.FC => {
  const func = (props: any) => {
    const {
      x,
      y,
      width,
      height,
      value: { value, loss, gain, selectedTeamValue },
    } = props
    const valToUse = value ?? selectedTeamValue
    if (valToUse == null) {
      return null
    }
    return (
      <g>
        <text
          x={x + width / 2}
          y={
            (valToUse < 0 ? y + height : y) +
            (valToUse < 0 ? -10 : valToUse < 200 ? -15 : -5)
          }
          fill="#000"
          textAnchor="middle"
          fontWeight="bold"
          fontSize={12}
          fontFamily="Open Sans"
        >
          {loss > 0 && (
            <tspan fill={isCost ? colours.success : colours.error}>
              -{formatter(loss)}
            </tspan>
          )}{' '}
          {gain > 0 && (
            <tspan fill={isCost ? colours.error : colours.success}>
              +{formatter(gain)}
            </tspan>
          )}
        </text>
      </g>
    )
  }
  return func
}

const TotalLabel = (
  formatter: (val: any) => string,
  isLandscapeMobile?: boolean,
): React.FC => {
  const func = (props: any) => {
    const {
      x,
      y,
      width,
      height,
      value: { value, selectedTeamValue, gain, loss },
    } = props
    const valToUse = value ?? selectedTeamValue
    if (valToUse == null) {
      return null
    }
    const offset =
      loss > 0 ? (isLandscapeMobile ? 32 : 40) : valToUse < 200 ? -15 : -5
    return (
      <g>
        <text
          x={x + width / 2}
          y={(valToUse < 0 ? y + height : y) + (valToUse < 0 ? -10 : offset)}
          fill="#000"
          textAnchor="middle"
          fontWeight="bold"
          fontSize={12}
          fontFamily="Open Sans"
        >
          {formatter(valToUse + (gain ?? 0))}
        </text>
      </g>
    )
  }
  return func
}

const MiddleLabel = (
  formatter: (val: any) => string,
  field: 'gain' | 'loss',
): React.FC => {
  const middleLabel = (props: any) => {
    const {
      x,
      y,
      width,
      height,
      value: { value, loss, gain, selectedTeamValue },
    } = props
    const valToUse = value ?? selectedTeamValue
    if (valToUse == null) {
      return null
    }
    if (field === 'loss' && !loss) {
      return null
    }
    if (field === 'gain' && !gain) {
      return null
    }

    const diff = (loss ?? 0) + (gain ?? 0)

    const diffAsAPercentageOfTotal = (diff / (valToUse + diff)) * 100

    const heightOfValue =
      (height / diffAsAPercentageOfTotal) * (100 - diffAsAPercentageOfTotal)

    const yOffset = height === 0 ? y : height + heightOfValue / 2

    return (
      <g>
        <rect
          x={x + width / 2 - 25}
          y={y + yOffset - 15}
          width={50}
          height={20}
          fill="#fff"
        ></rect>
        <text
          x={x + width / 2}
          y={y + yOffset}
          fill="#000"
          textAnchor="middle"
          fontWeight="bold"
          fontSize={12}
          fontFamily="Open Sans"
        >
          {formatter(valToUse)}
        </text>
      </g>
    )
  }
  return middleLabel
}

interface Props extends ChartProps {
  header?: ReactNode
  teamId: number
  formatter: (val: number) => string
  isCost?: boolean
  showTotal?: boolean
  legend: Array<{ title: string; isCost: boolean }>
  ignoreDiff?: boolean
  middleLabel?: (type: string) => React.FC
  topLabel?: (type: string) => React.FC
  chartTitle?: string
}

const StackedBarChart: React.FC<Props> = ({
  header,
  roundId,
  data,
  teamId,
  isLandscapeMobile,
  formatter,
  isCost,
  showTotal,
  legend,
  ignoreDiff,
  middleLabel,
  topLabel,
  chartTitle,
}) => {
  const chartData = useMemo(() => {
    const results = data.map(d => {
      const value = d.value ?? 0
      const last = d.last ?? 0
      const diff = !ignoreDiff
        ? roundId === 1
          ? 0
          : value > last
          ? value - last
          : last - value
        : 0

      const actualVal =
        roundId === 1 ? value : d.last != null && value > last ? last : value

      // @ts-expect-error fill is undefined
      const result: BarChartData = {
        name: d.name
          .replace('PC Bank', 'PC')
          .replace(' Bank', '')
          .toUpperCase(),
        value: teamId !== d.team ? actualVal : null,
        selectedTeamValue: teamId === d.team ? actualVal : null,
        gain: value > last ? diff : null,
        loss: value < last ? diff : null,
        raw: d,
        teamId: d.team,
      }
      return result
    })

    const { minValue, maxValue, numberOfTicks } = getYAxisDataV2({
      pastRound1: true,
      min: 0,
      data: [{ name: 'fake0', value: 0 }].concat(
        results.map(r => ({
          name: 'dddd',
          value: Number(r.value) ?? 0,
        })),
      ),
    })
    return { minValue, maxValue, numberOfTicks, data: results }
  }, [data, roundId, ignoreDiff, teamId])

  const barsComponent = (
    <Fragment>
      <Bar
        dataKey={`value`}
        stackId="a"
        fill={colours.primary}
        animationDuration={500}
        animationBegin={0}
      >
        <LabelList position="bottom" content={createLegend()}></LabelList>
        {middleLabel && (
          <LabelList
            position="middle"
            content={middleLabel('value')}
            valueAccessor={(data: any) => data.payload}
          ></LabelList>
        )}
        {topLabel && (
          <LabelList
            position="top"
            content={topLabel('value')}
            valueAccessor={(data: any) => data.payload}
          ></LabelList>
        )}
      </Bar>
      <Bar
        dataKey={`selectedTeamValue`}
        stackId="a"
        fill={colours.orange}
        animationDuration={500}
        animationBegin={0}
      >
        {middleLabel && (
          <LabelList
            position="middle"
            content={middleLabel('selectedTeamValue')}
            valueAccessor={(data: any) => data.payload}
          ></LabelList>
        )}
        {topLabel && (
          <LabelList
            position="top"
            content={topLabel('selectedTeamValue')}
            valueAccessor={(data: any) => data.payload}
          ></LabelList>
        )}
      </Bar>
      <Bar
        dataKey={`gain`}
        stackId="a"
        fill={isCost ? 'rgb(255 123 126)' : 'rgb(173 225 212)'}
        animationDuration={500}
        animationBegin={500}
        // fillOpacity={0.2}
      >
        {!showTotal && !middleLabel && (
          <LabelList
            position="middle"
            content={MiddleLabel(formatter, 'gain')}
            valueAccessor={(data: any) => data.payload}
          ></LabelList>
        )}
      </Bar>

      <Bar
        dataKey={`loss`}
        stackId="a"
        fill={isCost ? 'rgb(173 225 212)' : 'rgb(255 123 126)'}
        // fillOpacity={0.2}
        stroke={isCost ? colours.green : colours.error}
        // @ts-expect-error yes it does have strokeDasharray
        strokeDasharray={[5, 5]}
        animationDuration={500}
        animationBegin={500}
      >
        {!topLabel && (
          <LabelList
            position="top"
            content={
              showTotal
                ? TotalLabel(formatter, isLandscapeMobile)
                : TopLabel(formatter, isCost)
            }
            valueAccessor={(data: any) => data.payload}
          ></LabelList>
        )}
        {!showTotal && !middleLabel && (
          <LabelList
            position="middle"
            content={MiddleLabel(formatter, 'loss')}
            valueAccessor={(data: any) => data.payload}
          ></LabelList>
        )}
      </Bar>
    </Fragment>
  )

  return (
    <VerticalGroup verticalStart gap={2}>
      <ShowIfMobileLandscape>
        <HorizontalGroup between fullWidth className="px-20">
          {header}
          <HorizontalGroup gap={4} verticalCenter>
            {legend.map(legendItem => (
              <HorizontalGroup key={legendItem.title} gap={1} verticalCenter>
                <div
                  className={classNames('h-3 w-3', {
                    'bg-error-100': legendItem.isCost,
                    'bg-green-200': !legendItem.isCost,
                  })}
                ></div>
                <SecondaryText size="xs">{legendItem.title}</SecondaryText>
              </HorizontalGroup>
            ))}
          </HorizontalGroup>
        </HorizontalGroup>
      </ShowIfMobileLandscape>
      <VerticalGroup gap={2}>
        <ChartContainer className={classNames({ 'mt-6': !isLandscapeMobile })}>
          <VerticalGroup full fullWidth>
            {chartTitle && (
              <SecondaryText center size="xs" weight="bold">
                {chartTitle}
              </SecondaryText>
            )}
            <BarChart
              data={chartData.data}
              barsComponent={barsComponent}
              minYAxisvalue={chartData.minValue}
              maxYAxisvalue={chartData.maxValue}
              numberOfTicks={chartData.numberOfTicks}
              // hideYAxis
              // hideGrid
              formatter={formatter}
              xPadding={isLandscapeMobile ? 25 : 25}
            />
          </VerticalGroup>
        </ChartContainer>
        <HideIfMobileLandscape>
          <HorizontalGroup center gap={4} verticalCenter>
            {legend.map(legendItem => (
              <HorizontalGroup key={legendItem.title} gap={1} verticalCenter>
                <div
                  className={classNames('h-3 w-3', {
                    'bg-error-100': legendItem.isCost,
                    'bg-green-200': !legendItem.isCost,
                  })}
                ></div>
                <SecondaryText size="xs">{legendItem.title}</SecondaryText>
              </HorizontalGroup>
            ))}
          </HorizontalGroup>
        </HideIfMobileLandscape>
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default StackedBarChart
