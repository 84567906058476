import { createAsyncThunk } from '@reduxjs/toolkit'
import gameAPI from '../../services/gameApi'
import APIError from '../../errors/APIError'
import {
  SummaryRequestParams,
  SummaryResponse,
} from '../../types/gameApi/summary'
import { RootState } from '../../store'
import ThunkCancelledError from '../../errors/ThunkCancelledError'

export const fetchSummary = createAsyncThunk<
  { roundId: number; summaries: SummaryResponse[] },
  SummaryRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'summary/fetch',
  async ({ teamId, roundId }, { rejectWithValue, getState, requestId }) => {
    const state = getState()
    if (state.summary.summaries[roundId]) {
      return { roundId, summaries: state.summary.summaries[roundId] }
    }
    try {
      if (state.summary.requestInProgress !== requestId) {
        throw new ThunkCancelledError()
      }
      const { summaries } = await gameAPI.getSummary({ teamId, roundId })

      return { roundId, summaries }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)
