import { naturalLog } from '../../../lib/maths'
import {
  CostsDivision,
  CostsDivisionType,
  CostsResponse,
} from '../../../types/gameApi/costs'
import { DecisionMap } from './CostsContainer'

const SALARY_GROWTH = 'SALARY_GROWTH'

export interface FTECostPerDivision {
  startingCost: number
  fteCosts: number
  fteNewCosts: number
  fteChange: number
  finalCost: number
  changeInCost: number
}

export const fteCostPerDivision = (
  data: CostsResponse,
  decisions: Record<CostsDivisionType, number>,
): FTECostPerDivision[] => {
  const nonSalaryGrowth = data.divisions
    .filter(d => d.type !== SALARY_GROWTH)
    .map(division => {
      if (division.type === SALARY_GROWTH) {
        throw new Error('Salary growth should not be in this list')
      }
      const totalPeopleCosts = data.totalPeopleCosts
      const divisionCostSplit = division.costSplit / 10000
      const startingCost = totalPeopleCosts * divisionCostSplit
      const fteCosts = division.fteCosts
      const fteNewCosts = division.fteNewCosts
      const notionalFTEOutput = (startingCost * 1000000) / (fteCosts * 1000)
      const extraEffort = decisions[division.type] / 100 / 100
      const extraFTERequired = notionalFTEOutput * extraEffort
      const fteAfterExtraEffort = notionalFTEOutput + extraFTERequired
      const fteChange = fteAfterExtraEffort - notionalFTEOutput
      const finalCost = fteChange * (fteNewCosts / 1000) + startingCost
      const changeInCost = finalCost - startingCost

      return {
        startingCost,
        fteCosts,
        fteNewCosts,
        fteChange,
        finalCost,
        changeInCost,
      }
    })

  const totalStartingCost = nonSalaryGrowth.reduce(
    (res, d) => res + d.startingCost,
    0,
  )
  const totalFinalCost =
    (nonSalaryGrowth.reduce((res, d) => res + d.finalCost, 0) *
      (1 + decisions[SALARY_GROWTH] / 100 / 100)) /
    (1 + data.defaultPayrise / 100 / 100)
  const totalChangeInCost = nonSalaryGrowth.reduce(
    (res, d) => res + d.changeInCost,
    0,
  )

  const salaryGrowthChangeInCost =
    totalFinalCost - totalStartingCost - totalChangeInCost

  return nonSalaryGrowth.concat({
    startingCost: 0,
    fteCosts: 0,
    fteNewCosts: 0,
    finalCost: 0,
    fteChange: 0,
    changeInCost: salaryGrowthChangeInCost,
  })
}

const getNpsImpactPerDecision = (
  id: number,
  data: CostsResponse,
  rawEffectiveProductivityDecisions: RawEffectiveProductivityDecisions,
) => {
  const division = data.divisions.find(d => d.id === id)
  if (!division) {
    throw new Error(`Division ${id} does not exist`)
  }
  const divisionMaxValue = naturalLog(division.maxValue)
  const divisionImpact =
    ((rawEffectiveProductivityDecisions[division.type].log / divisionMaxValue) *
      division.customerSatisfaction) /
    100

  return divisionImpact
}

export const getCustomerSatisfaction = (
  data: CostsResponse,
  rawEffectiveProductivityDecisions: RawEffectiveProductivityDecisions,
) => {
  const openingNps = data.npsOpening / 100
  const annualChange = data.npsChange / 100

  const division1Impact = getNpsImpactPerDecision(
    1,
    data,
    rawEffectiveProductivityDecisions,
  )
  const division2Impact = getNpsImpactPerDecision(
    2,
    data,
    rawEffectiveProductivityDecisions,
  )
  const division3Impact = getNpsImpactPerDecision(
    3,
    data,
    rawEffectiveProductivityDecisions,
  )
  const division4Impact = getNpsImpactPerDecision(
    4,
    data,
    rawEffectiveProductivityDecisions,
  )
  const division5Impact = getNpsImpactPerDecision(
    5,
    data,
    rawEffectiveProductivityDecisions,
  )

  const sumOfParts =
    openingNps +
    annualChange +
    division1Impact +
    division2Impact +
    division3Impact +
    division4Impact +
    division5Impact
  const closingNps = Math.min(sumOfParts, 100)
  const change = closingNps - openingNps

  return { change, value: closingNps }
}

const getImpactPerDecisionForField = (
  id: number,
  data: CostsResponse,
  rawEffectiveProductivityDecisions: RawEffectiveProductivityDecisions,
  field: keyof CostsDivision,
) => {
  const division = data.divisions.find(d => d.id === id)
  if (!division) {
    throw new Error(`Division ${id} does not exist`)
  }
  const divisionMaxValue = naturalLog(division.maxValue)
  const divisionImpact =
    (rawEffectiveProductivityDecisions[division.type].log / divisionMaxValue) *
    (division[field] as number)

  return divisionImpact
}

const getCustomerComplaints = (
  data: CostsResponse,
  rawEffectiveProductivityDecisions: RawEffectiveProductivityDecisions,
) => {
  const openingComplaints = data.complaintsOpening
  const annualChange = data.complaintsChange

  const division1Impact = getImpactPerDecisionForField(
    1,
    data,
    rawEffectiveProductivityDecisions,
    'complaints',
  )
  const division2Impact = getImpactPerDecisionForField(
    2,
    data,
    rawEffectiveProductivityDecisions,
    'complaints',
  )
  const division3Impact = getImpactPerDecisionForField(
    3,
    data,
    rawEffectiveProductivityDecisions,
    'complaints',
  )
  const division4Impact = getImpactPerDecisionForField(
    4,
    data,
    rawEffectiveProductivityDecisions,
    'complaints',
  )
  const division5Impact = getImpactPerDecisionForField(
    5,
    data,
    rawEffectiveProductivityDecisions,
    'complaints',
  )

  const sumOfParts =
    openingComplaints +
    annualChange +
    division1Impact +
    division2Impact +
    division3Impact +
    division4Impact +
    division5Impact
  const closingComplaints = Math.max(sumOfParts, 0)
  const change = closingComplaints - openingComplaints

  return { change, value: closingComplaints }
}

const getEmployeeEngagementImpactForDivision = (
  id: number,
  data: CostsResponse,
  decisions: Record<CostsDivisionType, number>,
) => {
  const division = data.divisions.find(d => d.id === id)
  if (!division) {
    throw new Error(`Division ${id} does not exist`)
  }
  const divisionEmployeeEngagementSetup = division.employeeEngagement
  const divisionDecision = naturalLog(decisions[division.type] / 100)
  const divisionMaxValue = naturalLog(division.maxValue)
  const divisionImpact =
    (divisionDecision / divisionMaxValue) * divisionEmployeeEngagementSetup

  return divisionImpact
}

const getSalaryImpact = (
  data: CostsResponse,
  decisions: Record<CostsDivisionType, number>,
) => {
  const salaryDecision = decisions[SALARY_GROWTH]
  let percentage = data.employeeEngagementSalaryIncrease
  if (salaryDecision < 0) {
    percentage = data.employeeEngagementSalaryDecrease
  }
  const decision = naturalLog(salaryDecision / 100 - data.defaultPayrise / 100)
  const maxValue = naturalLog(
    data.divisions.find(d => d.type === SALARY_GROWTH)?.maxValue ?? 0,
  )
  const impact = (decision / maxValue) * percentage
  return impact
}

const getEmployeeEngagement = (
  data: CostsResponse,
  decisions: Record<CostsDivisionType, number>,
) => {
  const openingEmployeeEngagement = data.employeeEngagementOpening
  const annualChange = data.employeeEngagementChange

  const division3Impact = getEmployeeEngagementImpactForDivision(
    3,
    data,
    decisions,
  )
  const division4Impact = getEmployeeEngagementImpactForDivision(
    4,
    data,
    decisions,
  )
  const salaryImpact = getSalaryImpact(data, decisions)
  const sumOfParts =
    openingEmployeeEngagement +
    annualChange +
    division3Impact +
    division4Impact +
    salaryImpact
  const closingEmployeeEngagement = Math.min(sumOfParts, 10000)
  const change = closingEmployeeEngagement - openingEmployeeEngagement

  return { change, value: closingEmployeeEngagement }
}

const getRiskIncidents = (
  data: CostsResponse,
  rawEffectiveProductivityDecisions: RawEffectiveProductivityDecisions,
) => {
  const openingRiskIncidents = data.riskIncidentsOpening
  const annualChange = data.riskIncidentsChange

  const division1Impact = getImpactPerDecisionForField(
    1,
    data,
    rawEffectiveProductivityDecisions,
    'riskIncidents',
  )
  const division2Impact = getImpactPerDecisionForField(
    2,
    data,
    rawEffectiveProductivityDecisions,
    'riskIncidents',
  )
  const division3Impact = getImpactPerDecisionForField(
    3,
    data,
    rawEffectiveProductivityDecisions,
    'riskIncidents',
  )
  const division4Impact = getImpactPerDecisionForField(
    4,
    data,
    rawEffectiveProductivityDecisions,
    'riskIncidents',
  )
  const division5Impact = getImpactPerDecisionForField(
    5,
    data,
    rawEffectiveProductivityDecisions,
    'riskIncidents',
  )

  const sumOfParts =
    openingRiskIncidents +
    annualChange +
    division1Impact +
    division2Impact +
    division3Impact +
    division4Impact +
    division5Impact
  const closingRiskIncidents = Math.max(sumOfParts, 0)
  const change = closingRiskIncidents - openingRiskIncidents

  return { change, value: closingRiskIncidents }
}

const getCommunityTrust = (
  data: CostsResponse,
  sanctionsIssued: number,
  changeInTrust: number,
) => {
  const openingCommunityTrust = data.communityTrustOpening
  const annualChange = data.communityTrustChange

  const sanctionsIssuedWithRegulatorImpact =
    sanctionsIssued * data.regulatorImpact
  const openingCommunityTrustWithChange = openingCommunityTrust + annualChange
  const sanctionsImpact = -Math.min(
    sanctionsIssuedWithRegulatorImpact,
    openingCommunityTrustWithChange,
  )
  const riskIncidentImpact = changeInTrust

  const sumOfParts =
    openingCommunityTrust + annualChange + sanctionsImpact + riskIncidentImpact
  const closingCommunityTrust = Math.max(Math.min(sumOfParts, 10000), 0)
  const change = closingCommunityTrust - openingCommunityTrust

  return { change, value: closingCommunityTrust }
}

const getRegulatorActions = (data: CostsResponse, sanctionsIssued: number) => {
  return {
    change: sanctionsIssued + data.regulatorActions,
    value:
      sanctionsIssued + data.regulatorActions + data.regulatorActionsOpening,
  }
}

type RawEffectiveProductivityDecisions = Record<
  keyof DecisionMap,
  { value: number; log: number }
>
interface ImpactChangeAndValue {
  change: number
  value: number
}
export interface ImpactValues {
  employeeEngagement: ImpactChangeAndValue
  nps: ImpactChangeAndValue
  complaints: ImpactChangeAndValue
  riskIncidents: ImpactChangeAndValue
  communityTrust: ImpactChangeAndValue
  regulatorActions: ImpactChangeAndValue
  effectiveDecisions: DecisionMap
}
export const getImpacts = (
  data: CostsResponse,
  decisions: Record<CostsDivisionType, number>,
): ImpactValues => {
  const employeeEngagement = getEmployeeEngagement(data, decisions)
  const engagementGain =
    (employeeEngagement.value - data.openingRoundEmployeeEngagement) /
    data.openingRoundEmployeeEngagement
  let outputGainSetup = data.outputGain
  if (engagementGain < 0) {
    outputGainSetup = data.outputLoss
  }
  const outputGain = outputGainSetup * engagementGain
  const outputChange = outputGain

  // @ts-expect-error hard to map
  const rawEffectiveProductivityDecisions: RawEffectiveProductivityDecisions =
    Object.keys(decisions).reduce<RawEffectiveProductivityDecisions>(
      // @ts-expect-error hard to map
      (acc, d: keyof Record<CostsDivisionType, number>) => {
        if (d === SALARY_GROWTH) {
          acc[d] = {
            value: decisions[d],
            log: naturalLog(decisions[d] / 100 - data.defaultPayrise * 100),
          }
        } else {
          acc[d] = {
            value: decisions[d] + outputChange,
            log: naturalLog((decisions[d] + outputChange) / 100),
          }
        }
        return acc
      },
      {} as RawEffectiveProductivityDecisions,
    )

  const nps = getCustomerSatisfaction(data, rawEffectiveProductivityDecisions)
  const complaints = getCustomerComplaints(
    data,
    rawEffectiveProductivityDecisions,
  )
  const riskIncidents = getRiskIncidents(
    data,
    rawEffectiveProductivityDecisions,
  )

  const commulativeChangeInRiskIncidents =
    riskIncidents.value - data.openingRoundRiskIncidents

  let changeInTrust = 0
  if (data.communityTrustChangeThreshold) {
    const signage = commulativeChangeInRiskIncidents < 0 ? 1 : -1
    changeInTrust =
      Math.floor(
        Math.abs(commulativeChangeInRiskIncidents) /
          data.communityTrustChangeThreshold,
      ) *
      data.communityTrustChangeSetup *
      signage
  }

  const riskIncidentsRatio =
    riskIncidents.value / data.openingRoundRiskIncidents
  const sanctionsIssued = Math.max(
    Math.floor(Math.exp(riskIncidentsRatio) - Math.exp(1)),
    0,
  )

  const regulatorActions = getRegulatorActions(data, sanctionsIssued)

  const communityTrust = getCommunityTrust(data, sanctionsIssued, changeInTrust)

  return {
    employeeEngagement,
    nps,
    complaints,
    riskIncidents,
    communityTrust,
    regulatorActions,
    // @ts-expect-error keyof
    effectiveDecisions: Object.keys(
      rawEffectiveProductivityDecisions,
      // @ts-expect-error keyof
    ).reduce<DecisionMap>((acc, d: keyof DecisionMap) => {
      acc[d] = rawEffectiveProductivityDecisions[d].value
      return acc
    }, {}),
  }
}
