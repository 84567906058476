import { DeviceStatusResponse } from '../../../../../types/gameApi/device'

export const data: DeviceStatusResponse = {
  teamId: 1,
  isMaster: true,
  gameId: 'abcde',
  gameName: 'Banking Simulation',
  appTitle: 'Excelerate',
  logo: 'https://test-images.banksim.co/brandings/images/e51cb2747d7c4d766aa62edc5a1f2548.png',
  stream: 'TURTLE',
  gameDate: '2023-08-09',
  roundType: 'facilitated',
  shouldRotateCeo: true,
  maskParticipants: false,
  splashScreenTitle: 'WEBSITE DEMO',
  client: 'Excelerate',
  currentRound: 4,
  currentRoundSetup: {
    insightsEnabled: true,
    dealsEnabled: true,
    excoEnabled: true,
    specialProjectsEnabled: true,
    surveyEnabled: true,
    operatingCostsEnabled: true,
    startTime: null,
    finishTime: null,
    time: 2700,
  },
  roundsSetup: [
    {
      round: 1,
      insightsEnabled: false,
      dealsEnabled: false,
      excoEnabled: true,
      specialProjectsEnabled: true,
      operatingCostsEnabled: true,
      surveyEnabled: false,
      startTime: null,
      finishTime: null,
      time: 2700,
    },
    {
      round: 2,
      insightsEnabled: true,
      dealsEnabled: true,
      excoEnabled: false,
      specialProjectsEnabled: true,
      operatingCostsEnabled: true,
      surveyEnabled: false,
      startTime: null,
      finishTime: null,
      time: 2700,
    },
    {
      round: 3,
      insightsEnabled: true,
      dealsEnabled: false,
      excoEnabled: true,
      specialProjectsEnabled: true,
      operatingCostsEnabled: true,
      surveyEnabled: false,
      startTime: null,
      finishTime: null,
      time: 2700,
    },
    {
      round: 4,
      insightsEnabled: true,
      dealsEnabled: true,
      excoEnabled: true,
      specialProjectsEnabled: true,
      operatingCostsEnabled: true,
      surveyEnabled: true,
      startTime: null,
      finishTime: null,
      time: 2700,
    },
    {
      round: 5,
      insightsEnabled: true,
      dealsEnabled: false,
      excoEnabled: false,
      specialProjectsEnabled: true,
      operatingCostsEnabled: true,
      surveyEnabled: false,
      startTime: null,
      finishTime: null,
      time: 2700,
    },
    {
      round: 6,
      insightsEnabled: true,
      dealsEnabled: false,
      excoEnabled: false,
      specialProjectsEnabled: true,
      operatingCostsEnabled: true,
      surveyEnabled: false,
      startTime: null,
      finishTime: null,
      time: 2700,
    },
  ],
  warningMessage: null,
  broadcastMessage: null,
  totalRounds: 5,
  team: {
    id: 1,
    name: 'Alpha',
    strategy:
      '✅ To be consistently #1 in customer satisfaction.\n✅ To focus on doing the right thing by all our customers (not just most of them).\n✅ To balance margin and volume outcomes for stable organic growth.\n✅ To deliver stable and increasing shareholder returns.\n✅ To invest prudently for the long term.',
  },
  finishTime: null,
  nextRoundStartTime: null,
  gameStartTime: null,
  status: 'inprogress',
  currentTime: '2023-08-10T00:18:23.369Z',
  filesToDownload: [
    {
      url: 'https://test-images.banksim.co/exco/exco-58a4e1090864b5d45afe8650cfc0a487.jpg',
      name: 'exco5.jpg',
      md5: '58a4e1090864b5d45afe8650cfc0a487',
    },
    {
      url: 'https://test-images.banksim.co/exco/exco-48aa3d3f2ad24797b873c6710f44f9d3.jpg',
      name: 'exco11.jpg',
      md5: '48aa3d3f2ad24797b873c6710f44f9d3',
    },
    {
      url: 'https://test-images.banksim.co/exco/exco-bc3a5ef02403b47e85c81fba21e52ac6.jpg',
      name: 'exco12.jpg',
      md5: 'bc3a5ef02403b47e85c81fba21e52ac6',
    },

    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/53f89a71b2680524f8c632213a4b65df.jpg',
      name: 'help1.png',
      md5: '53f89a71b2680524f8c632213a4b65df',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/b69252227fd1750c738359b7e655afd9.jpg',
      name: 'help2.png',
      md5: 'b69252227fd1750c738359b7e655afd9',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/9e1a92e3da632eba5ec9f617f40faa93.PNG',
      name: 'help3.png',
      md5: '9e1a92e3da632eba5ec9f617f40faa93',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/7b920722486658250bd51e12020dc3e0.PNG',
      name: 'help4.png',
      md5: '7b920722486658250bd51e12020dc3e0',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/eea5640459a7399edafcd89c12dca2e2.PNG',
      name: 'help5.png',
      md5: 'eea5640459a7399edafcd89c12dca2e2',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/c524eecbbb447f3c70d2389519d02da8.PNG',
      name: 'help6.png',
      md5: 'c524eecbbb447f3c70d2389519d02da8',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/e5d38b6a08f20c53f35ed7f2bae916e1.PNG',
      name: 'help7.png',
      md5: 'e5d38b6a08f20c53f35ed7f2bae916e1',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/31aeef204b1e7ca6b66aa069fe14ef17.PNG',
      name: 'help8.png',
      md5: '31aeef204b1e7ca6b66aa069fe14ef17',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/ef8624ec5e1a80729b93f06f079a5558.PNG',
      name: 'help9.png',
      md5: 'ef8624ec5e1a80729b93f06f079a5558',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/10df5420654156012480a640144d0d4f.PNG',
      name: 'help10.png',
      md5: '10df5420654156012480a640144d0d4f',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/61a92b83b977130acface0d32489df96.PNG',
      name: 'help11.png',
      md5: '61a92b83b977130acface0d32489df96',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/9ef99370bf604145ec39265a2ab6e353.PNG',
      name: 'help12.png',
      md5: '9ef99370bf604145ec39265a2ab6e353',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/545c79c789210ca32221a0ed7be4c876.PNG',
      name: 'help13.png',
      md5: '545c79c789210ca32221a0ed7be4c876',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/959ef1a7dddb0b2772f07d420d5e3aa7.PNG',
      name: 'help14.png',
      md5: '959ef1a7dddb0b2772f07d420d5e3aa7',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/c6b8de48028787db4add8c19a9392d6d.PNG',
      name: 'help15',
      md5: 'c6b8de48028787db4add8c19a9392d6d',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/8daaaa07bdc56f973c8515dce5339c1b.PNG',
      name: 'help16',
      md5: '8daaaa07bdc56f973c8515dce5339c1b',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/a29fa2b0b3da73c0c9c4e82891115d71.PNG',
      name: 'help17',
      md5: 'a29fa2b0b3da73c0c9c4e82891115d71',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/008d48b0b7da47a878f76292adac5d77.PNG',
      name: 'help18',
      md5: '008d48b0b7da47a878f76292adac5d77',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/083dbf6e580ebd5a33cf1430f1452b32.PNG',
      name: 'help19',
      md5: '083dbf6e580ebd5a33cf1430f1452b32',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/d41d7b64298f366c50bad47f8116e6c9.jpg',
      name: 'help20',
      md5: 'd41d7b64298f366c50bad47f8116e6c9',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/16d96097fa236da1a615134b090925ca.PNG',
      name: 'help21',
      md5: '16d96097fa236da1a615134b090925ca',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/938dae42a448b0c2f4cbcc55ad980983.PNG',
      name: 'help22',
      md5: '938dae42a448b0c2f4cbcc55ad980983',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/078635b5e9e4f85b4c609d70fc2d207f.PNG',
      name: 'help23',
      md5: '078635b5e9e4f85b4c609d70fc2d207f',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/18536223c58b6fff87fc3c6042a5db7d.PNG',
      name: 'help24',
      md5: '18536223c58b6fff87fc3c6042a5db7d',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/947e014ddea7ee585dfb9b4c37dac4f9.PNG',
      name: 'help25',
      md5: '947e014ddea7ee585dfb9b4c37dac4f9',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/c8dff848575a7db330903f7b1c25b593.PNG',
      name: 'help26',
      md5: 'c8dff848575a7db330903f7b1c25b593',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/469bd8c4adbcdf4ea192153231a66704.PNG',
      name: 'help27',
      md5: '469bd8c4adbcdf4ea192153231a66704',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/65cb70a77a57e4e896b8a1303bd848ec.PNG',
      name: 'help28',
      md5: '65cb70a77a57e4e896b8a1303bd848ec',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/9f206f756581bb3567b28aa85154efb9.jpg',
      name: 'help29',
      md5: '9f206f756581bb3567b28aa85154efb9',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/6839c1948bb89e165f66ff6c0f728e27.PNG',
      name: 'help30',
      md5: '6839c1948bb89e165f66ff6c0f728e27',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/e7e1a30ce9b9e95987f799bb62896e23.PNG',
      name: 'help31',
      md5: 'e7e1a30ce9b9e95987f799bb62896e23',
    },
    {
      url: 'https://test-images.banksim.co/manuals/e551ef81-d260-4e7a-9203-db9c56fb6d6b/b4a7db21b00283ad2af1e658e2343b34.jpg',
      name: 'help32',
      md5: 'b4a7db21b00283ad2af1e658e2343b34',
    },
    {
      url: 'https://test-images.banksim.co/news/vanilla_5_rounds/australia/CREDIT_CARDS/news-d49bd19af786fa07dcbd085b17c906b5.png',
      name: 'news1.png',
      md5: 'd49bd19af786fa07dcbd085b17c906b5',
    },
    {
      url: 'https://test-images.banksim.co/news/vanilla_5_rounds/australia/CREDIT_CARDS/news-723925693559914456cc052181ecddfa.png',
      name: 'news2.png',
      md5: '723925693559914456cc052181ecddfa',
    },
    {
      url: 'https://test-images.banksim.co/news/vanilla_5_rounds/australia/CREDIT_CARDS/news-3f6edb153b4fbec3c5a21e4999b0eb57.png',
      name: 'news3.png',
      md5: '3f6edb153b4fbec3c5a21e4999b0eb57',
    },
    {
      url: 'https://test-images.banksim.co/news/vanilla_5_rounds/australia/CREDIT_CARDS/news-a21ff214d49fe496eb2c7348ae0e749c.png',
      name: 'news4.png',
      md5: 'a21ff214d49fe496eb2c7348ae0e749c',
    },
    {
      url: 'https://test-images.banksim.co/news/vanilla_5_rounds/australia/CREDIT_CARDS/news-cc1e3aa99819d1782ff6744cf913a6e5.png',
      name: 'news5.png',
      md5: 'cc1e3aa99819d1782ff6744cf913a6e5',
    },
    {
      url: 'https://test-images.banksim.co/projects/be8bdb49a4577d7cc1e35504806c1262.jpg',
      name: 'project1.jpg',
      md5: 'be8bdb49a4577d7cc1e35504806c1262',
    },
    {
      url: 'https://test-images.banksim.co/projects/dde53634a321f3c10602389c6f242aba.jpg',
      name: 'project2.jpg',
      md5: 'dde53634a321f3c10602389c6f242aba',
    },
    {
      url: 'https://test-images.banksim.co/projects/dece1485fb5a5868ca76d4bba335f636.jpg',
      name: 'project3.jpg',
      md5: 'dece1485fb5a5868ca76d4bba335f636',
    },
    {
      url: 'https://test-images.banksim.co/projects/d1103d91e22a384ba41dcae3ec4a7da7.jpg',
      name: 'project4.jpg',
      md5: 'd1103d91e22a384ba41dcae3ec4a7da7',
    },
    {
      url: 'https://test-images.banksim.co/projects/695ebb22ccf8badeab7a22f92d0fc48f.jpg',
      name: 'project5.jpg',
      md5: '695ebb22ccf8badeab7a22f92d0fc48f',
    },
    {
      url: 'https://test-images.banksim.co/projects/e1773d9b95fbdfebed0cb81b9e82d4c0.jpg',
      name: 'project6.jpg',
      md5: 'e1773d9b95fbdfebed0cb81b9e82d4c0',
    },
    {
      url: 'https://test-images.banksim.co/projects/34514ce7ea0d4baaf283a0a41eaba08f.jpg',
      name: 'project7.jpg',
      md5: '34514ce7ea0d4baaf283a0a41eaba08f',
    },
    {
      url: 'https://test-images.banksim.co/projects/8d34f23751c3c8bc0fe10f5914a3f3ba.jpg',
      name: 'project8.jpg',
      md5: '8d34f23751c3c8bc0fe10f5914a3f3ba',
    },
    {
      url: 'https://test-images.banksim.co/projects/1c0b25d2726935b4315a218a4ea83f44.jpg',
      name: 'project9.jpg',
      md5: '1c0b25d2726935b4315a218a4ea83f44',
    },
    {
      url: 'https://test-images.banksim.co/projects/0ac5a994ad44f1128a46bd7252d6d8ca.jpg',
      name: 'project10.jpg',
      md5: '0ac5a994ad44f1128a46bd7252d6d8ca',
    },
    {
      url: 'https://test-images.banksim.co/projects/31050e49deb318d42d49887d1c03cd06.jpg',
      name: 'project11.jpg',
      md5: '31050e49deb318d42d49887d1c03cd06',
    },
    {
      url: 'https://test-images.banksim.co/projects/f68559b6b0f34e85ee031f943858efac.jpg',
      name: 'project12.jpg',
      md5: 'f68559b6b0f34e85ee031f943858efac',
    },
    {
      url: 'https://test-images.banksim.co/projects/5a2b3b61fcf0ef3a76c14676e1e3117d.jpg',
      name: 'project13.jpg',
      md5: '5a2b3b61fcf0ef3a76c14676e1e3117d',
    },
    {
      url: 'https://test-images.banksim.co/projects/61be8a83c4eb1e59a1fb7a344137682f.jpg',
      name: 'project14.jpg',
      md5: '61be8a83c4eb1e59a1fb7a344137682f',
    },
    {
      url: 'https://test-images.banksim.co/projects/5cc6f9d07b74677c4149d49ffcdf3380.jpg',
      name: 'project15.jpg',
      md5: '5cc6f9d07b74677c4149d49ffcdf3380',
    },
    {
      url: 'https://test-images.banksim.co/projects/305a2a2c3669f53df51c4cf28e11415d.jpg',
      name: 'project16.jpg',
      md5: '305a2a2c3669f53df51c4cf28e11415d',
    },
    {
      url: 'https://test-images.banksim.co/projects/9ed5ddfac6fbca0c6f87f8bf8ef70f56.jpg',
      name: 'project17.jpg',
      md5: '9ed5ddfac6fbca0c6f87f8bf8ef70f56',
    },
  ],
  results: [],
  numberOfHelpFiles: 32,
  automate: {
    testModeScreen: false,
    menuTimeMax: 0,
    menuTimeMin: 0,
    testModeWidgets: false,
    widgetTimeMin: 0,
    widgetTimeMax: 0,
    widgetIndex: 0,
    widgetMaxPricingChange: 0,
  },
  resultsCastUrl: null,
}
