import React, { FormEvent, useCallback } from 'react'
import LoginInput from './LoginInput'
import LoginButton from './LoginButton'

interface Props {
  email: string
  onInput: (name: string, val: string) => void
  onNext: () => void
  buttonEnabled: boolean
}

const EnterEmailStep: React.FC<Props> = ({
  email,
  onInput,
  onNext,
  buttonEnabled,
}) => {
  const handleNext = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      onNext()
    },
    [onNext],
  )

  const isDemo = process.env.REACT_APP_DEMO_MODE === 'true'

  return (
    <form className="mt-12 space-y-4" onSubmit={handleNext}>
      <input type="hidden" name="remember" defaultValue="true" />
      <LoginInput
        id="email-address"
        name="email"
        type={!isDemo ? 'email' : 'text'}
        bgColor="white"
        placeholder={!isDemo ? 'Email' : 'Username'}
        label={!isDemo ? 'Email' : 'Username'}
        onValueChange={onInput}
        value={email}
      />

      <LoginButton disabled={!buttonEnabled} text="Next" />
    </form>
  )
}

export default EnterEmailStep
