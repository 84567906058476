import React, { PropsWithChildren, useCallback, useMemo } from 'react'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import { useAppDispatch, useAppSelector } from '../../../store'
import { updateSelectedRound } from '../../../redux/game/gameSlice'
import DropDown, { MenuItem } from '../../atoms/DropDown/DropDown'
import classNames from 'classnames'
import InAppHelp from '../InAppHelp/InAppHelp'
import useMediaQuery from '../../../hooks/useMediaQuery'
import InAppHelpContent from '../InAppHelp/InAppHelpContent'
import BulletPoint from '../../atoms/BulletPoint/BulletPoint'

interface Props {
  className?: string
}

const MobilePageHeader: React.FC<PropsWithChildren<Props>> = ({
  className,
  children,
}) => {
  const { rounds, isWrapUp } = useAppSelector(state => ({
    rounds: {
      count: state.game.totalRounds,
      current: state.game.currentRound,
      selected: state.game.selectedRound,
    },
    isWrapUp: state.game.state === 'wrapup',
  }))

  const dispatch = useAppDispatch()

  const handleRoundSelect = useCallback(
    (round: number) => {
      dispatch(updateSelectedRound(round))
    },
    [dispatch],
  )

  const roundItems: MenuItem<number>[] = useMemo(() => {
    const items: MenuItem<number>[] = []
    if (rounds) {
      for (let r = 1; r <= rounds.count; r++) {
        items.push({
          label: `Round ${r}`,
          value: r,
          disabled: r > rounds.current,
        })
      }
      items.push({
        label: 'Wrap Up',
        value: rounds.count + 1,
        disabled: !isWrapUp,
      })
    }
    return items
  }, [isWrapUp, rounds])

  const isTabletOrLarger = useMediaQuery('tablet')

  return (
    <HorizontalGroup
      center
      gap={4}
      between
      verticalCenter
      className={classNames(
        'sticky top-0 z-10 mx-[-0.5rem] flex flex-row-reverse bg-gray-100 px-2 tablet:hidden',
        className,
      )}
    >
      {rounds && (
        <div className="dropdown-selector">
          <DropDown<number>
            items={roundItems}
            selectedItem={rounds.selected}
            onSelectedItemChange={item => handleRoundSelect(item.value)}
          />
        </div>
      )}
      {children}
      {!isTabletOrLarger && rounds.current === 2 && (
        <InAppHelp
          steps={[
            {
              target: '.dropdown-selector',
              content: (
                <InAppHelpContent heading=" You have started Round 2!">
                  <BulletPoint>
                    Click the dropdown to select previous rounds and view
                    previous decisions.
                  </BulletPoint>
                </InAppHelpContent>
              ),
            },
          ]}
          storageKey="previous-round-help"
        />
      )}
    </HorizontalGroup>
  )
}

export default MobilePageHeader
