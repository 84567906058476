import React, { FC, PropsWithChildren, useCallback } from 'react'
import Clickable from '../../atoms/Clickable/Clickable'
import { Icon } from '../../atoms/Icon/Icon'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import classNames from 'classnames'
import PrimaryText from '../../atoms/Text/PrimaryText'

export type ValueChangeInputType =
  | 'percentage'
  | 'dollar'
  | 'integer'
  | 'decimal'

export type ValueChangeModalProps = {
  heading: string
}

export type ButtonSizes = 'small' | 'medium' | 'long'

type Props = {
  increments?: number[]
  incrementFormatter?: (val: number) => string
  step?: number
  value: number
  onChange: (value: number) => void
  min?: number
  max?: number
  enableChanges: boolean
  buttonSize?: ButtonSizes
  hideButtons?: boolean
  idPrefix?: string
}
const buttonSizes: Record<ButtonSizes, string> = {
  small: 'w-6 h-6 laptop:w-8 laptop:h-8 desktop:w-10 desktop:h-10',
  medium: 'w-8 h-8 laptop:w-8 laptop:h-8 desktop:w-10 desktop:h-10',
  long: 'w-10 h-8 laptop:w-12 laptop:h-8 desktop:w-12 desktop:h-10',
}

const ValueChangerButtons: FC<PropsWithChildren<Props>> = ({
  increments,
  incrementFormatter,
  step = 1,
  value,
  onChange,
  min,
  max,
  enableChanges,
  children,
  buttonSize,
  hideButtons,
  idPrefix,
}) => {
  const buttonSizeClasses = buttonSizes[buttonSize ?? 'small']
  const minDisabled = !enableChanges || (min != null && value === min)
  const maxDisabled = !enableChanges || (max != null && value === max)

  const handleIncrement = useCallback(() => {
    if (!maxDisabled) {
      const newValue =
        max != null ? Math.min(value + 1 * step, max) : value + 1 * step
      if (newValue !== value) {
        onChange(newValue)
      }
    }
  }, [max, maxDisabled, onChange, step, value])

  const handleIncrementByMultiple = useCallback(
    (multiple: number) => () => {
      if (!maxDisabled) {
        const newValue =
          max != null
            ? Math.min(value + multiple * step, max)
            : value + multiple * step
        if (newValue != value) {
          onChange(newValue)
        }
      }
    },
    [max, maxDisabled, onChange, step, value],
  )

  const handleDecrement = useCallback(() => {
    if (!minDisabled) {
      const newValue =
        min != null ? Math.max(value - 1 * step, min) : value - 1 * step
      if (newValue !== value) {
        onChange(newValue)
      }
    }
  }, [min, minDisabled, onChange, step, value])

  const handleDecrementByMultiple = useCallback(
    (multiple: number) => () => {
      if (!minDisabled) {
        const newValue =
          min != null
            ? Math.max(value - multiple * step, min)
            : value - multiple * step
        if (newValue != value) {
          onChange(newValue)
        }
      }
    },
    [minDisabled, value, step, min, onChange],
  )

  return (
    <HorizontalGroup gap={2} className="">
      {!hideButtons &&
        enableChanges &&
        increments?.length &&
        [...increments].reverse().map((increment, i) => (
          <Clickable
            key={i}
            disabled={!enableChanges}
            onClick={handleDecrementByMultiple(increment)}
          >
            <div
              className={classNames(
                buttonSizeClasses,
                'flex items-center justify-center rounded-full',
                {
                  'bg-primary': !minDisabled,
                  'bg-gray-300': minDisabled,
                },
              )}
            >
              <PrimaryText colour="white" weight="bold" size="xs">
                -
                {incrementFormatter ? incrementFormatter(increment) : increment}
              </PrimaryText>
            </div>
          </Clickable>
        ))}
      {!hideButtons && enableChanges && !increments?.length && (
        <Clickable
          id={idPrefix ? `${idPrefix}minus-button` : undefined}
          disabled={!enableChanges}
          onClick={handleDecrement}
        >
          <Icon
            className={buttonSizeClasses}
            type="minus"
            disabled={minDisabled}
            colour="primary"
          />
        </Clickable>
      )}

      {children}

      {!hideButtons && enableChanges && !increments?.length && (
        <Clickable
          id={idPrefix ? `${idPrefix}plus-button` : undefined}
          disabled={!enableChanges}
          onClick={handleIncrement}
        >
          <Icon
            className={buttonSizeClasses}
            type="plus"
            disabled={maxDisabled}
            colour="primary"
          />
        </Clickable>
      )}

      {!hideButtons &&
        enableChanges &&
        increments?.length &&
        increments.map((increment, i) => (
          <Clickable
            key={i}
            disabled={!enableChanges}
            onClick={handleIncrementByMultiple(increment)}
          >
            <div
              className={classNames(
                buttonSizeClasses,
                'flex items-center justify-center rounded-full',
                {
                  'bg-primary': !maxDisabled,
                  'bg-gray-300': maxDisabled,
                },
              )}
            >
              <PrimaryText colour="white" weight="bold" size="xs">
                +
                {incrementFormatter ? incrementFormatter(increment) : increment}
              </PrimaryText>
            </div>
          </Clickable>
        ))}
    </HorizontalGroup>
  )
}

export default ValueChangerButtons
