import React, { useCallback, useMemo } from 'react'
import InAppHelpContent from '../../organisms/InAppHelp/InAppHelpContent'
import Text from '../../atoms/Text/Text'
import GuidedTour, {
  GuidedTourStep,
} from '../../organisms/InAppHelp/GuidedTour'
import { useAppDispatch, useAppSelector } from '../../../store'
import { finishTour } from '../../../redux/guidedTours/guidedToursSlice'
import BulletPoint from '../../atoms/BulletPoint/BulletPoint'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import { useRoutes } from '../../../hooks/useRoutes'
import GuidedTourStartModal from '../../organisms/InAppHelp/GuidedTourStartModal'
import { useMenuTranslations } from '../../../hooks/useMenuTranslations'

const RoundTwoInitialTour: React.FC = () => {
  const dispatch = useAppDispatch()
  const translations = useMenuTranslations()
  const {
    currentTour,
    eventId,
    round,
    dismissedInAppHelp,
    treasuryData,
    rounds,
  } = useAppSelector(state => ({
    currentTour: state.guidedTours.currentTour,
    eventId: state.event.details?.eventId,
    round: state.game.currentRound,
    dismissedInAppHelp: state.inAppHelp.dismissedInAppHelp,
    treasuryData: state.treasury.data,
    rounds: {
      count: state.game.totalRounds,
    },
  }))
  const { routes } = useRoutes()

  const costsEnabled = routes.some(route => route.id === 'costs')
  const specialProjectsEnabled = routes.some(
    route => route.id === 'special-projects',
  )
  const isTreasuryAutomatic = treasuryData?.[round]?.wsfMethod === 3

  const steps: Array<Partial<GuidedTourStep>> = [
    {
      target: '#summary',
      content: (
        <InAppHelpContent
          headingIcon="summary"
          heading={translations.summary}
          spendTime="2 mins"
        >
          <Text left>
            {
              'Review which metrics improved the most and which ones may still need attention. Are these changes as you expected based on your decisions last year? '
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#breaking-news',
      content: (
        <InAppHelpContent
          headingIcon="news"
          spendTime="4 mins"
          heading={translations.news}
        >
          <VerticalGroup gap={2}>
            <Text left>
              {
                'As a team, review the breaking news together. Discuss and understand the key points:'
              }
            </Text>
            <BulletPoint>{'What risks are we facing this year?'}</BulletPoint>
            <BulletPoint>{'What opportunities can we capture?'}</BulletPoint>
            <BulletPoint>
              {'Which products are experiencing the most growth?'}
            </BulletPoint>
            <BulletPoint>
              {'Are there any products where we anticipate significant losses?'}
            </BulletPoint>
            <BulletPoint>
              {
                'Compare the wholesale funding costs to our customer interest rates.'
              }
            </BulletPoint>
            <BulletPoint>
              {
                'Have cash rates changed and do we need to re-price any products as a result?'
              }
            </BulletPoint>
          </VerticalGroup>
        </InAppHelpContent>
      ),
    },
    {
      target: '#peer-insights',
      content: (
        <InAppHelpContent
          headingIcon="peer"
          heading={translations.peerInsights}
          spendTime="5 mins"
          tip="Compare your product rate last year to the average rate for the market. How different was it?"
        >
          <Text left>
            {
              "Review and understand what each bank did last year to win customers and assess who gained/lost market share as a result. Can you gain insights into any of the other bank's strategies?"
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#our-financials',
      content: (
        <InAppHelpContent
          headingIcon="financials"
          heading={translations.ourFinancials}
          spendTime="2 mins"
          tip="Ask team members or coaches for help if you need it."
        >
          <Text left>
            {
              ' Review and understand how each financial summary metric is changing. Review how your balance sheet changed. Did each asset and liability grow as you expected?'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    ...(specialProjectsEnabled
      ? [
          {
            target: '#special-projects',
            content: (
              <InAppHelpContent
                headingIcon="projects"
                spendTime="4 mins"
                heading={translations.specialProjects}
                tip={`Continue to select investments that improve your ${translations.summary} "winning" metrics. Try to time your investments so they can all be fully completed within the ${rounds.count} simulation rounds.`}
              >
                <Text left>
                  {`As a team review which of these investment opportunities will continue to best deliver your strategy and could significantly improve your ${translations.summary} "winning" metrics.`}
                </Text>
              </InAppHelpContent>
            ),
          },
        ]
      : []),
    ...(costsEnabled
      ? [
          {
            target: '#costs',
            content: (
              <InAppHelpContent
                headingIcon="cost"
                heading={translations.costs}
                spendTime="5 mins"
                tip="Consider whether the forecast improvements are worth the investment being made."
              >
                <Text left>
                  {
                    'Adjust investment and resource allocation in each division if required.'
                  }
                </Text>
              </InAppHelpContent>
            ),
          },
        ]
      : []),
    {
      target: '#businesses',
      placement: 'top',
      content: (
        <InAppHelpContent
          headingIcon="businesses"
          spendTime="10 mins"
          tip=" Review deposit interest rates compared to wholesale funding interest rates this year."
          heading={translations.businesses}
        >
          <Text left>
            {' Consider whether any pricing changes are required. Consider:'}
          </Text>
          <BulletPoint>
            {'How does your current pricing compare to your competitors?'}
          </BulletPoint>
          <BulletPoint>
            {
              'Is there a cash rate change and how much of this are your going to pass on to your customers?'
            }
          </BulletPoint>
        </InAppHelpContent>
      ),
    },
    ...(!isTreasuryAutomatic
      ? [
          {
            target: '#treasury',
            placement: 'top' as const,
            content: (
              <InAppHelpContent
                headingIcon="treasury"
                heading={translations.treasury}
                spendTime="5 mins"
              >
                <Text left>
                  {
                    'Raise enough wholesale funding from the market to support your lending commitments. Consider what combination of 1,2,3,4, and 5-year bonds you should raise.  Remember to maintain sufficient cash balances'
                  }
                </Text>
              </InAppHelpContent>
            ),
          },
        ]
      : []),
  ]

  const handleCloseTour = useCallback(() => {
    dispatch(finishTour())
  }, [dispatch])

  const shouldShowModal = useMemo(() => {
    return round === 2 && !!dismissedInAppHelp[`${eventId}-previous-round-help`]
  }, [dismissedInAppHelp, eventId, round])

  if (currentTour !== 'round-two-initial') {
    return (
      <GuidedTourStartModal
        tour="round-two-initial"
        storageKey="round-two-initial-modal"
        show={shouldShowModal}
        heading="Quick Tips?"
        description="Need tips for getting started in this second round?"
      />
    )
  }

  return <GuidedTour disableScrolling steps={steps} onClose={handleCloseTour} />
}

export default RoundTwoInitialTour
