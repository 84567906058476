import React from 'react'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import ValueChangeText from '../../../molecules/ValueChangeText/ValueChangeText'
import { formatPercentage } from '../../../../lib/formatters'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  heading: string
  headingDefault: string
  value: number
  valueChange: number
  isCost?: boolean
}

const TreasuryBalanceSheetChartCalc: React.FC<Props> = ({
  heading,
  headingDefault,
  value,
  valueChange,
  isCost,
  ...rest
}) => {
  return (
    <VerticalGroup {...rest} gap={2} verticalCenter center>
      <TranslatedText
        center
        size="sm"
        family="secondary"
        value={heading}
        default={headingDefault}
      />
      <div>
        <SecondaryText
          data-test="chart-calculations"
          center
          weight="bold"
          colour={isCost ? 'error' : undefined}
        >
          {isCost ? `(${formatPercentage(value)})` : formatPercentage(value)}
        </SecondaryText>
        <ValueChangeText
          size="sm"
          value={valueChange}
          dp={2}
          changeUnit="%"
          dashIfZero
          weight="bold"
          isCost={isCost}
          removeArrow
        />
      </div>
    </VerticalGroup>
  )
}

export default TreasuryBalanceSheetChartCalc
