import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

interface Props {
  className?: string
  noDivideX?: boolean
}

const TableFooter: React.FC<PropsWithChildren<Props>> = ({
  children,
  className,
  noDivideX,
}) => {
  return (
    <tr
      className={classNames(
        'divide-x divide-gray-300 whitespace-nowrap bg-gray-200',
        className,
        { 'divide-x-0': noDivideX },
      )}
    >
      {children}
    </tr>
  )
}

export default TableFooter
