import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store'
import { dismissBroadcastMessage } from '../../../redux/game/gameSlice'
import openNewTab from '../../../lib/openNewTab'
import Modal from '../../atoms/Modal/Modal'
import Text from '../../atoms/Text/Text'

const BroadcastAlert: React.FC = () => {
  const { broadcastMessage, dismissedBroadcastMessages } = useAppSelector(
    state => ({
      broadcastMessage: state.game.broadcastMessage,
      dismissedBroadcastMessages: state.game.dismissedBroadcastMessages,
    }),
  )
  const dispatch = useAppDispatch()
  const [timer, setTimer] = useState(0)

  const onClose = useCallback(() => {
    dispatch(dismissBroadcastMessage(broadcastMessage?.id ?? ''))
  }, [dispatch, broadcastMessage?.id])

  const onOk = useCallback(() => {
    if (broadcastMessage?.link) {
      openNewTab(broadcastMessage.link)
    } else {
      onClose()
    }
  }, [broadcastMessage?.link, onClose])

  const isVisible = !!(
    broadcastMessage && !dismissedBroadcastMessages[broadcastMessage.id]
  )

  useEffect(() => {
    if (isVisible) {
      setTimer(3)
    }
  }, [isVisible])

  useEffect(() => {
    if (isVisible && timer > 0) {
      const interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1)
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [isVisible, timer])
  const hasCTA = !!broadcastMessage?.linkCTA
  return (
    <Modal
      type="broadcast"
      show={isVisible}
      onClose={onClose}
      title={broadcastMessage?.title ?? ''}
      onOk={onOk}
      okButtonText={hasCTA ? broadcastMessage.linkCTA || undefined : 'Ok'}
      cancelButtonText="Close"
      onCancel={broadcastMessage?.link ? onClose : undefined}
      disableBackdropClick={true}
      disableButtons={timer !== 0}
      okDisabled={!hasCTA && timer !== 0}
    >
      <div className="pt-4">
        <p>
          <Text isHtml>{broadcastMessage?.message}</Text>
        </p>
      </div>
    </Modal>
  )
}

export default BroadcastAlert
