import React, { useState } from 'react'
import { ProjectImpact } from '../../../types/gameApi/projects'
import Button from '../../atoms/Button/Button'
import Modal from '../../atoms/Modal/Modal'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import { Icon } from '../../atoms/Icon/Icon'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import SecondaryText from '../../atoms/Text/SecondaryText'
import RadioInput from '../../atoms/RadioButtons/RadioInput'
import { reportFeatureUsage } from '../../../lib/googleTagManager'

interface SortModalProps {
  allImpacts: ProjectImpact[]
  selectedImpact: ProjectImpact | undefined
  setSelectedImpact: (impact: ProjectImpact | undefined) => void
  setSortByDeliveryTime: (sort: boolean) => void
}

const SortModal: React.FC<SortModalProps> = ({
  selectedImpact,
  setSelectedImpact,
  allImpacts,
  setSortByDeliveryTime,
}) => {
  const [isSortModalOpen, setIsSortModalOpen] = useState(false)
  const [tempSelectedImpact, setTempSelectedImpact] = useState<
    ProjectImpact | undefined
  >(selectedImpact)
  const [sortByDeliveryTime, setLocalSortByDeliveryTime] = useState(false)

  const handleButtonClick = () => {
    reportFeatureUsage({
      page: 'specialprojects',
      feature: 'sort',
      action: 'click',
    })
    setIsSortModalOpen(true)
    setTempSelectedImpact(selectedImpact)
  }

  const handleClearClick = () => {
    setSelectedImpact(undefined)
    setSortByDeliveryTime(false)
    setIsSortModalOpen(false)
    setTempSelectedImpact(undefined)
    setLocalSortByDeliveryTime(false)
  }

  const handleSaveClick = () => {
    reportFeatureUsage({
      page: 'specialprojects',
      feature: 'sort',
      action: 'select',
      value: sortByDeliveryTime ? 'delivery-time' : tempSelectedImpact?.name,
    })
    setSelectedImpact(tempSelectedImpact)
    setSortByDeliveryTime(sortByDeliveryTime)
    setIsSortModalOpen(false)
  }

  return (
    <>
      <HorizontalGroup className="sticky top-0 z-20">
        <Button
          className="w-fit bg-white px-2"
          rounded="full"
          onClick={handleButtonClick}
          buttonType="secondary"
          id="tour-sort-projects"
        >
          {selectedImpact
            ? `${selectedImpact.name} impact high to low`
            : sortByDeliveryTime
            ? 'Delivery Time (Longest First)'
            : 'Sort'}
          <Icon type="sorting" colour="black" />
        </Button>
      </HorizontalGroup>
      <Modal
        title="Sort (highest impact first)"
        show={isSortModalOpen}
        onClose={() => setIsSortModalOpen(false)}
        showFooter
      >
        <VerticalGroup gap={4} className="pt-2">
          <VerticalGroup gap={2}>
            {allImpacts.map(impact => (
              <HorizontalGroup verticalCenter gap={2} key={impact.id}>
                <RadioInput
                  id={`impact-${impact.id}`}
                  name="impact"
                  value={impact.name}
                  onChange={() => {
                    setTempSelectedImpact(impact)
                    setLocalSortByDeliveryTime(false)
                  }}
                  checked={tempSelectedImpact?.id === impact.id}
                />
                <label htmlFor={`impact-${impact.id}`}>{impact.name}</label>
              </HorizontalGroup>
            ))}
          </VerticalGroup>
          <hr></hr>
          <VerticalGroup gap={2}>
            <SecondaryText className="text-lg font-medium leading-6 text-gray-900">
              Sort by Delivery Time
            </SecondaryText>
            <HorizontalGroup verticalCenter gap={2}>
              <RadioInput
                id="sortByDeliveryTime"
                name="sort"
                value="Delivery Time"
                onClick={() => setLocalSortByDeliveryTime(!sortByDeliveryTime)}
                onChange={e => {
                  if (e.target.checked) {
                    setTempSelectedImpact(undefined)
                  }
                }}
                checked={sortByDeliveryTime}
              />
              <label htmlFor="sortByDeliveryTime">
                Delivery Time (Longest First)
              </label>
            </HorizontalGroup>
          </VerticalGroup>
          <HorizontalGroup className="pt-2" gap={4}>
            <Button
              rounded="full"
              buttonType="secondary"
              onClick={handleClearClick}
            >
              Clear
            </Button>
            <Button
              rounded="full"
              buttonType="primary"
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </HorizontalGroup>
        </VerticalGroup>
      </Modal>
    </>
  )
}

export default SortModal
