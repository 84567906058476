import React, { useCallback } from 'react'
import classNames from 'classnames'
import { Colour } from '../../../types/theme'
import { BACKGROUND_COLOUR_SHADE_CLASSES } from '../../../lib/colourClasses'

export interface Props
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  centerText?: boolean
  bgColor?: Colour
  fontWeight?: 'bold'
  noPadding?: boolean
  onValueChange?: (value: string, name: string) => void
  textSize?: 'sm' | 'md' | 'lg'
  hideArrows?: boolean
  fullHeight?: boolean
  height?: 'full' | 'screen' | '96' | '80' | '64' | '48' | '32'
}

const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      label,
      centerText,
      bgColor,
      fontWeight,
      noPadding,
      onValueChange,
      textSize,
      hideArrows,
      fullHeight,
      height,
      ...rest
    },
    ref,
  ) => {
    TextArea.displayName = 'TextArea'
    const onChange = useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onValueChange && onValueChange(event.target.value, event.target.name)
      },
      [onValueChange],
    )

    const backgroundClass = bgColor
      ? BACKGROUND_COLOUR_SHADE_CLASSES[bgColor]
      : ''
    const textSizeClass = textSize ? `text-${textSize}` : ''

    return (
      <>
        {label && (
          <label htmlFor={rest.name} className="sr-only">
            {label}
          </label>
        )}
        <textarea
          {...rest}
          ref={ref}
          data-lpignore="true"
          onChange={onChange}
          className={classNames(
            'relative block w-full resize-none appearance-none rounded-md border focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-primary',
            {
              'text-center': centerText,
              [backgroundClass]: backgroundClass,
              'font-bold': fontWeight === 'bold',
              'px-3 py-3': !noPadding,
              [textSizeClass]: textSizeClass,
              'hide-number-arrows': hideArrows,
              'border-primary-300 bg-primary-50':
                !rest.disabled && !backgroundClass,
              'border-gray-300 bg-lightgray-300': rest.disabled,
              'h-full': fullHeight,
              [`h-${height}`]: height,
            },
          )}
        />
      </>
    )
  },
)

export default TextArea
