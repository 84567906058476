import React from 'react'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import { DecisionMap } from '../TreasuryContainer'
import { TreasuryResponse } from '../../../../types/gameApi/treasury'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import { formatValue, percentage } from '../../../../lib/formatters'
import { calculateAssetsAndForecasts } from '../calculations'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  value: number
  decisions: DecisionMap
  data: TreasuryResponse
  highlightNewFunding?: boolean
}

const Row = ({
  label,
  value,
  highlight,
  supplementInfo,
}: {
  label: string
  value: string
  highlight?: boolean
  supplementInfo?: React.ReactNode
}) => (
  <HorizontalGroup fullWidth between>
    <SecondaryText
      size="xs"
      colour={highlight ? 'primary' : undefined}
      weight={highlight ? 'bold' : undefined}
    >
      {label}
    </SecondaryText>
    <HorizontalGroup right gap={2}>
      {supplementInfo}
      <SecondaryText
        size="xs"
        colour={highlight ? 'primary' : undefined}
        weight={highlight ? 'bold' : undefined}
      >
        {value}
      </SecondaryText>
    </HorizontalGroup>
  </HorizontalGroup>
)

const TreasuryBalanceSheetWSFDetails: React.FC<Props> = ({
  value,
  data,
  decisions,
  highlightNewFunding,
  ...rest
}) => {
  const wsfTotalPrev =
    decisions.wsf1 +
    decisions.wsf2 +
    decisions.wsf3 +
    decisions.wsf4 +
    decisions.wsf5
  const diff = value - wsfTotalPrev
  const wsfChange = decisions.wsfChange + diff
  const { cashForecastWithAutoWSF, depositsForecast } =
    calculateAssetsAndForecasts({
      data,
      decisions: { ...decisions, wsfChange },
    })
  // const newFundingTotal = 0
  return (
    <VerticalGroup {...rest} gap={3} fullWidth full between>
      <Row
        label="Current Wholesale Funding"
        value={formatValue(data.openingBalances.wholesaleFunding)}
      />
      <Row
        label="- Funding Due For Payment"
        value={formatValue(data.openingBalances.wsfRepayments)}
      />
      <Row
        label="+ New Funding Issued"
        value={formatValue(value)}
        highlight={highlightNewFunding}
      />
      <hr />
      <Row
        label="Forecast Wholesale Funding"
        value={formatValue(
          data.openingBalances.wholesaleFunding +
            data.openingBalances.wsfRepayments +
            value,
        )}
      />
      <Row
        label="Change In Total Funding"
        value={formatValue(data.openingBalances.wsfRepayments + value, {
          addPlusSign: true,
        })}
      />
      <Row
        label="Forecast Cash Balance"
        value={formatValue(cashForecastWithAutoWSF)}
        supplementInfo={
          <SecondaryText size="xs">
            ({percentage((cashForecastWithAutoWSF / depositsForecast) * 10000)}{' '}
            of Deposits)
          </SecondaryText>
        }
      />
    </VerticalGroup>
  )
}

export default TreasuryBalanceSheetWSFDetails
