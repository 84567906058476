import { useEffect, useState } from 'react'
import { Breakpoints } from '../types/theme'

const breakpoints: Record<Breakpoints, string> = {
  tablet: '640px',
  laptop: '1024px',
  desktop: '1280px',
}

const useMediaQuery = (breakpoint: Breakpoints): boolean => {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia(
      `(min-width: ${breakpoints[breakpoint]})`,
    )

    const handleChange = (event: MediaQueryListEvent) => {
      setMatches(event.matches)
    }

    mediaQuery.addEventListener('change', handleChange)

    setMatches(mediaQuery.matches)

    return () => {
      mediaQuery.removeEventListener('change', handleChange)
    }
  }, [breakpoint])

  return matches
}

export default useMediaQuery
