import React from 'react'
import Modal from '../../atoms/Modal/Modal'

interface ProjectSelectModalProps {
  isModalOpen: boolean
  onClose: () => void
  onCancel: () => void
  isSubmitting: boolean
  activeProjectIsTheSelectedProject: boolean
  handleOKClick: () => void
}

const ProjectSelectModal: React.FC<ProjectSelectModalProps> = ({
  isModalOpen,
  onClose,
  onCancel,
  isSubmitting,
  activeProjectIsTheSelectedProject,
  handleOKClick,
}) => {
  return (
    <Modal
      show={isModalOpen}
      onClose={onClose}
      onCancel={onCancel}
      title={
        activeProjectIsTheSelectedProject
          ? 'Unselect Project'
          : 'Change Project Selection'
      }
      showFooter
      onOk={handleOKClick}
      okInProgress={isSubmitting}
    >
      <div className="mt-2">
        <p className="text-sm text-gray-500">
          {activeProjectIsTheSelectedProject
            ? 'Are you sure you want to unselect this project?'
            : 'Are you sure you want to change the selected project?'}
        </p>
      </div>
    </Modal>
  )
}

export default ProjectSelectModal
