import React, { useCallback } from 'react'
import InAppHelpContent from '../../organisms/InAppHelp/InAppHelpContent'
import Text from '../../atoms/Text/Text'
import GuidedTour, {
  GuidedTourStep,
} from '../../organisms/InAppHelp/GuidedTour'
import { useAppDispatch, useAppSelector } from '../../../store'
import { finishTour } from '../../../redux/guidedTours/guidedToursSlice'
import {
  BusinessTabs,
  toggleShowAdvanced,
} from '../../../redux/businesses/businessSlice'
interface Props {
  setTab: (tab: BusinessTabs) => void
}

const BusinessesTour: React.FC<Props> = ({ setTab }) => {
  const dispatch = useAppDispatch()
  const currentTour = useAppSelector(state => state.guidedTours.currentTour)

  const AdvanceModeToggle = useCallback(
    (value: boolean) => {
      dispatch(toggleShowAdvanced(value))
    },
    [dispatch],
  )
  const businessTabChange = useCallback(() => {
    setTab('price-volume-trade-off')
  }, [setTab])
  const handleTourEnd = useCallback(() => {
    AdvanceModeToggle(false)
    dispatch(finishTour())
  }, [AdvanceModeToggle, dispatch])
  const steps: Array<Partial<GuidedTourStep>> = [
    {
      target: '#tour-business-decisions',
      content: (
        <InAppHelpContent heading="Business Decisions">
          <Text left>
            {'This screen is where your business decisions are made.'}
          </Text>
        </InAppHelpContent>
      ),
    },

    {
      target: '#tour-item-price',
      content: (
        <InAppHelpContent heading={'Price Decision'}>
          <Text left>
            {
              'Each product has a price which can be changed. This has the most impact but can also be the most expensive way to attract customers.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-item-secondary-decision',
      content: (
        <InAppHelpContent heading="Secondary Decision">
          <Text left>
            {
              'Each product also has a secondary lever which is more subtle than price, however can often be more enduring, not visible to competitors, and also cheaper than using price to compete for customers.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-item-price-info-popup',
      content: (
        <InAppHelpContent heading="Help & Info">
          <Text left>
            {
              'More detail on each business decision is available by using these info buttons.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-item-price-previous-price',
      content: (
        <InAppHelpContent heading="Previous Decision">
          <Text left>
            {'The decision in the previous period is shown here.'}
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#HOME_LOANS-price1--input',
      content: (
        <InAppHelpContent heading={'Current Decision'}>
          <Text left>
            {
              'Enter your current decision in the input box here. Larger changes are best entered here rather than using the plus and minus buttons.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#HOME_LOANS-price1-plus-button',
      content: (
        <InAppHelpContent heading={'Small Adjustments'}>
          <Text left>
            {
              'Use the plus and minus buttons to quickly make small changes to your decisions.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-change-value',
      content: (
        <InAppHelpContent heading={'Change'}>
          <Text left>
            {
              'The change in your decision compared to the previous period is shown here.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-price-volume-trade-off',
      content: (
        <InAppHelpContent heading={'Price/Volume Trade Off'}>
          <Text left>
            {
              'This screen helps you to understand the impact of your business decisions.'
            }
          </Text>
        </InAppHelpContent>
      ),
      onClick: businessTabChange,
    },
    {
      target: '#lastTopLabelValue',
      content: (
        <InAppHelpContent heading={'Previous Decision'}>
          <Text left>
            {'The decision in the previous period is shown here.'}
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#HOME_LOANS-price1Now-input',
      content: (
        <InAppHelpContent heading={'Current Decision'}>
          <Text left>
            {
              'Enter your current decision in the input box here. Big changes are best entered here.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#HOME_LOANS-volumeNow-percentage',
      content: (
        <InAppHelpContent heading={'Volume Estimate'}>
          <Text left>
            {
              'This is the estimated growth in balances you expect for this product this year based on your decisions.As each round begins, these are pre-populated with the expected market growth estimates.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#HOME_LOANS-estimated-income-percentage',
      content: (
        <InAppHelpContent heading={'Estimated Income'}>
          <Text left>
            {
              'The forecast income for each product is shown here and is based on your current pricing decision and volume estimate.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#tour-advanced-mode-toggle',
      content: (
        <InAppHelpContent heading={'Advanced Mode'}>
          <Text left>
            {
              'More detailed analysis on your forecast net interest margins can be viewed using this switch.'
            }
          </Text>
        </InAppHelpContent>
      ),
      onClick: () => {
        AdvanceModeToggle(true)
      },
    },
    {
      target: '#advanced-nim-all-percentage',
      content: (
        <InAppHelpContent heading={'Net Interest Margin'}>
          <Text left>
            {
              'Evaluate the impact of your decisions on the forecast Net Interest Margin (NIM). Make sure to finalise all pricing and Treasury decisions before assessing the impact.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#advanced-price1Now-percentage-normal',
      content: (
        <InAppHelpContent heading={'Current Price Decision'}>
          <Text left>
            {
              'Review and update your current decision here. This value is the same as the one displayed in "Simple Mode."'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#advanced-price1Now-percentage',
      content: (
        <InAppHelpContent heading={'Change'}>
          <Text left>
            {
              'The change in your price decision compared to the previous period is shown here.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#CASH-volumeNow-percentage-normal',
      content: (
        <InAppHelpContent heading={'Cash Balance'}>
          <Text left>
            {
              'If your cash balance is zero, it is because you have not raised any/enough wholesale funding yet to fund the growth in your business. '
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#WSF-volumeNow-percentage-normal',
      content: (
        <InAppHelpContent heading={'Wholesale Funding'}>
          <Text left>
            {
              'Existing wholesale funding due for repayment this year as already been repaid often causing your forecasts to begin with zero cash. Ensure your wholesale funding has been raised before evaluating the impact on your forecast NIM this year.'
            }
          </Text>
        </InAppHelpContent>
      ),
      onClick: handleTourEnd,
    },
  ]

  const handleCloseTour = useCallback(() => {
    handleTourEnd()
  }, [handleTourEnd])

  if (currentTour !== 'businesses') {
    return null
  }

  return <GuidedTour disableScrolling steps={steps} onClose={handleCloseTour} />
}

export default BusinessesTour
