import { PeerInsightsResponse } from '../../../../../types/gameApi/peerInsights'
import { data as round2Data } from './2'
import { data as round3Data } from './3'
import { data as round4Data } from './4'

const peerInsightsMockData: { [roundId: number]: PeerInsightsResponse } = {
  2: round2Data,
  3: round3Data,
  4: round4Data,
}

export const getPeerInsightsData = (roundId: number): PeerInsightsResponse => {
  if (peerInsightsMockData[roundId]) {
    return peerInsightsMockData[roundId]
  }
  throw new Error(`No mock peerInsights data for round ${roundId}`)
}
