import React, { HTMLAttributes } from 'react'
import { Switch } from '@headlessui/react'
import PrimaryText from '../Text/PrimaryText'
import classNames from 'classnames'
import HorizontalGroup from '../HorizontalGroup/HorizontalGroup'

type Props = {
  label?: string
  enabled: boolean
  toggleEnabled: (val: boolean) => void
} & HTMLAttributes<HTMLDivElement>

function Toggle({ label, enabled, toggleEnabled, ...rest }: Props) {
  return (
    <Switch.Group as="div" className="flex items-center">
      <HorizontalGroup {...rest} verticalCenter gap={2}>
        {label && (
          <Switch.Label as="span">
            <PrimaryText>{label}</PrimaryText>
          </Switch.Label>
        )}
        <Switch
          checked={enabled}
          onChange={toggleEnabled}
          className={classNames(
            enabled ? 'bg-primary' : 'bg-gray-200',
            'relative inline-flex h-4 w-9 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2 tablet:h-6 tablet:w-11',
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out tablet:h-5 tablet:w-5',
            )}
          />
        </Switch>
      </HorizontalGroup>
    </Switch.Group>
  )
}

export default Toggle
