import React, { useMemo } from 'react'
import { PeerInsightsMetricTabs } from '../../../../redux/peer-insights/peerInsightsSlice'
import { useAppSelector } from '../../../../store'
import { PeerInsightsData } from '../../../../types/gameApi/peerInsights'
import { SummaryResponse } from '../../../../types/gameApi/summary'
import HideIfMobileLandscape from '../../../atoms/HideIfMobile/HideIfMobileLandscape'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'

import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import { mapTypeToInsight } from './SummaryMetricMapper'
import SummaryMetricsMobileCarousel from './SummaryMetricsMobileCarousel'
import SummaryMetricTab from './SummaryMetricTab'
import { getMetricColours } from '../../../../lib/icons'

interface Props {
  data: PeerInsightsData[]
  teamId: number
  roundId: number
  summaries: SummaryResponse[]
  handleCloseMetrics: () => void
  setMetric: (metric: PeerInsightsMetricTabs) => void
}

const SummaryMetrics: React.FC<Props> = ({
  data,
  teamId,
  roundId,
  summaries,
  handleCloseMetrics,
  setMetric,
}) => {
  const { currentMetric, demoEnabled, demoEnabledSummaryMetrics } =
    useAppSelector(state => ({
      currentMetric: state.peerInsights.currentMetric,
      demoEnabled: state.demo.enabled,
      demoEnabledSummaryMetrics: state.demo.enabledSummaryMetrics,
    }))

  const activeMetrics = useMemo(
    () =>
      data
        .filter(s => {
          if (demoEnabled) {
            return demoEnabledSummaryMetrics.includes(s.type)
          }
          return s.enabled
        })
        .map(d =>
          mapTypeToInsight({
            type: d.type,
            data: d.data,
            summaries,
            teamId,
            roundId,
            businessTypes: d.businessTypes,
          }),
        ),
    [data, demoEnabled, demoEnabledSummaryMetrics, roundId, summaries, teamId],
  )

  const selectedMetric = useMemo(
    () => activeMetrics.find(d => d.type === currentMetric),
    [currentMetric, activeMetrics],
  )

  if (!selectedMetric) {
    return null
  }
  return (
    <>
      <HideIfMobileLandscape>
        <VerticalGroup className="hidden tablet:flex" gap={1}>
          <HorizontalGroup fullWidth gap={2} className="flex overflow-x-auto">
            {activeMetrics.map((metric, i) => (
              <div key={i} className="flex-grow">
                <SummaryMetricTab
                  id={i === 0 ? 'tour-metrics' : ''}
                  index={i}
                  metric={metric}
                  onClick={setMetric}
                  active={metric.type === currentMetric}
                  colour={getMetricColours(i)}
                />
              </div>
            ))}
          </HorizontalGroup>
          <selectedMetric.component
            data={selectedMetric.data}
            teamId={teamId}
            roundId={roundId}
            businessTypes={selectedMetric.businessTypes ?? []}
          />
        </VerticalGroup>
      </HideIfMobileLandscape>
      <SummaryMetricsMobileCarousel
        selectedMetric={selectedMetric}
        metrics={activeMetrics}
        handleCloseMetrics={handleCloseMetrics}
        teamId={teamId}
        roundId={roundId}
      />
    </>
  )
}

export default SummaryMetrics
