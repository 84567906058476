import { createAsyncThunk } from '@reduxjs/toolkit'
import gameAPI from '../../services/gameApi'
import APIError from '../../errors/APIError'
import { RootState } from '../../store'
import {
  TreasuryDecisionUpdate,
  TreasuryRequestParams,
  TreasuryResponse,
  TreasuryUpdateRequestParams,
} from '../../types/gameApi/treasury'
import ThunkCancelledError from '../../errors/ThunkCancelledError'

export const fetchTreasury = createAsyncThunk<
  { roundId: number; treasury: TreasuryResponse },
  TreasuryRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'treasury/fetch',
  async ({ teamId, roundId }, { rejectWithValue, getState, requestId }) => {
    const state = getState()
    try {
      if (state.treasury.requestInProgress !== requestId) {
        throw new ThunkCancelledError()
      }
      const treasury = await gameAPI.getTreasury({ teamId, roundId })

      return { roundId, treasury }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)

export const fetchTreasuryBackground = createAsyncThunk<
  { roundId: number; treasury: TreasuryResponse },
  TreasuryRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'treasury/fetchBackground',
  async ({ teamId, roundId }, { rejectWithValue, getState, requestId }) => {
    const state = getState()
    try {
      if (state.treasury.requestInProgress !== requestId) {
        throw new ThunkCancelledError()
      }
      const treasury = await gameAPI.getTreasury({ teamId, roundId })

      return { roundId, treasury }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)

export const updateTreasuryDecisions = createAsyncThunk<
  { roundId: number; decisions: TreasuryDecisionUpdate[] },
  TreasuryUpdateRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'treasury/updateDecision',
  async ({ teamId, roundId, decisions, deviceId }, { rejectWithValue }) => {
    try {
      await gameAPI.updateTreasuryDecisions({
        teamId,
        roundId,
        decisions,
        deviceId,
      })

      return { roundId, decisions }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)

export const updateTreasuryDecisionsBackground = createAsyncThunk<
  { roundId: number; decisions: TreasuryDecisionUpdate[] },
  TreasuryUpdateRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'treasury/updateDecisionBackground',
  async ({ teamId, roundId, decisions, deviceId }, { rejectWithValue }) => {
    try {
      await gameAPI.updateTreasuryDecisions({
        teamId,
        roundId,
        decisions,
        deviceId,
      })

      return { roundId, decisions }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)
