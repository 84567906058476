import { createAsyncThunk } from '@reduxjs/toolkit'
import gameAPI from '../../services/gameApi'
import APIError from '../../errors/APIError'
import { RootState } from '../../store'
import {
  CostsDecisionUpdate,
  CostsRequestParams,
  CostsResponse,
  CostsUpdateRequestParams,
} from '../../types/gameApi/costs'
import ThunkCancelledError from '../../errors/ThunkCancelledError'

export const fetchCosts = createAsyncThunk<
  { roundId: number; costs: CostsResponse },
  CostsRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'costs/fetch',
  async ({ teamId, roundId }, { rejectWithValue, getState, requestId }) => {
    const state = getState()
    try {
      if (state.costs.requestInProgress !== requestId) {
        throw new ThunkCancelledError()
      }
      const costs = await gameAPI.getCosts({ teamId, roundId })

      return { roundId, costs }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)

export const fetchCostsBackground = createAsyncThunk<
  { roundId: number; costs: CostsResponse },
  CostsRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'costs/fetchBackground',
  async ({ teamId, roundId }, { rejectWithValue, getState, requestId }) => {
    const state = getState()
    try {
      if (state.costs.requestInProgress !== requestId) {
        throw new ThunkCancelledError()
      }
      const costs = await gameAPI.getCosts({ teamId, roundId })

      return { roundId, costs }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)

export const updateCostsDecisions = createAsyncThunk<
  { roundId: number; decisions: CostsDecisionUpdate[] },
  CostsUpdateRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'costs/updateDecision',
  async ({ teamId, roundId, decisions, deviceId }, { rejectWithValue }) => {
    try {
      await gameAPI.updateCostsDecisions({
        teamId,
        roundId,
        decisions,
        deviceId,
      })

      return { roundId, decisions }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)

export const updateCostsDecisionsBackground = createAsyncThunk<
  { roundId: number; decisions: CostsDecisionUpdate[] },
  CostsUpdateRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'costs/updateDecisionBackground',
  async ({ teamId, roundId, decisions, deviceId }, { rejectWithValue }) => {
    try {
      await gameAPI.updateCostsDecisions({
        teamId,
        roundId,
        decisions,
        deviceId,
      })

      return { roundId, decisions }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)
