import React from 'react'
import classNames from 'classnames'
import { useTranslatedText } from '../../../hooks/useTranslatedText'
import { FontFamily } from '../../../types/theme'
import PrimaryText, { Props as TextProps } from '../Text/PrimaryText'
import SecondaryText from '../Text/SecondaryText'
import { titleCase } from '../../../lib/text'

interface Props extends Omit<TextProps, 'family'> {
  value: string
  default: string
  family?: FontFamily
  suffix?: string
  prefix?: string
}

const TranslatedText: React.FC<Props> = props => {
  const {
    className,
    value,
    default: defaultValue,
    family = 'primary',
    suffix,
    prefix,
    ...rest
  } = props

  const translatedText = useTranslatedText(value, defaultValue)
  const Component = family === 'secondary' ? SecondaryText : PrimaryText
  return (
    <Component className={classNames(className)} {...rest}>
      {prefix}
      {rest.transform === 'titleCase'
        ? titleCase(translatedText)
        : translatedText}
      {suffix}
    </Component>
  )
}

export default TranslatedText
