import React, { useMemo } from 'react'
import { SwiperSlide } from 'swiper/react'
import LandscapeMobilePage from '../../../atoms/LandscapeMobilePage/LandscapeMobilePage'
import { MappedMetric } from './SummaryMetricMapper'
import Swiper from '../../../atoms/Swiper/Swiper'

import SummaryMetricTab from './SummaryMetricTab'
import { useWindowSize } from '../../../../hooks/useWindowSize'
// import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
// import { XMarkIcon } from '@heroicons/react/20/solid'

interface Props {
  selectedMetric: MappedMetric
  metrics: MappedMetric[]
  handleCloseMetrics: () => void
  teamId: number
  roundId: number
}

const SummaryMetricsMobileCarousel: React.FC<Props> = ({
  selectedMetric,
  metrics,
  handleCloseMetrics,
  teamId,
  roundId,
}) => {
  const size = useWindowSize()

  const selectedMetricIndex = useMemo(() => {
    return metrics.findIndex(m => m.type === selectedMetric.type) ?? 0
  }, [metrics, selectedMetric.type])

  return (
    <LandscapeMobilePage flexRow handleClose={handleCloseMetrics}>
      <Swiper
        key={`swiper${size.width}`}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore for some reason the types are not working
        navigation={false}
        className="peerInsightsSwiper"
        style={{ width: size.width, height: '100%' }}
        initialSlide={selectedMetricIndex}
      >
        {metrics.map((metric, index) => (
          <SwiperSlide
            key={`${metric.type}${size.width}`}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'start',
              paddingTop: 20,
            }}
          >
            {/* <HorizontalGroup>
          <button
            type="button"
            className="float-right absolute top-0 right-0 p-2"
            onClick={handleCloseMetrics}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </HorizontalGroup> */}
            <metric.component
              data={metric.data}
              teamId={teamId}
              roundId={roundId}
              businessTypes={metric.businessTypes ?? []}
              isLandscapeMobile
            >
              <SummaryMetricTab
                index={index}
                metric={metric}
                isLandscapeMobile
              />
            </metric.component>
          </SwiperSlide>
        ))}
      </Swiper>
    </LandscapeMobilePage>
  )
}

export default SummaryMetricsMobileCarousel
