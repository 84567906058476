import axios, { AxiosRequestConfig } from 'axios'
import APIError from '../errors/APIError'
import { getAuthDetails, setAuthDetails } from './localStorage'
import AdminAPI, { HOST } from '../services/adminApi'
import GameAPI from '../services/gameApi'
import { LoginResponse } from '../types/adminApi/auth'

const DEFAULT_TIMEOUT = 10000
interface GetParams {
  url: string
  headers?: { [header: string]: string }
}

interface PostParams extends GetParams {
  body?: any
}

interface HttpResponse<data> {
  status: number
  headers?: { [header: string]: string }
  data: data
}

const doRequest = async <data>(
  params: AxiosRequestConfig,
  allow401Relogin = true,
): Promise<HttpResponse<data>> => {
  try {
    const { status, headers, data } = await axios.request<data>({
      timeout: DEFAULT_TIMEOUT,
      ...params,
    })
    // @ts-expect-error headers
    return { status, headers, data }
  } catch (e: any) {
    if (e.message === 'Network Error') {
      throw new Error(
        'You have lost connection to our servers. Please check your internet connection and then refresh this webpage.',
      )
    }
    if (e.message && (e.message as string).startsWith('timeout of ')) {
      throw new Error('Timeout error')
    }
    if (e.response) {
      if (e.response.status === 401) {
        const authDetails = getAuthDetails()
        if (
          allow401Relogin &&
          !params.url?.includes('login') &&
          authDetails &&
          authDetails.isTablet
        ) {
          const { status, data } = await axios.request<LoginResponse>({
            url: `${HOST}/api/events/${authDetails.eventId}/login`,
            method: 'post',
            data: {
              email: authDetails.email,
              password: authDetails.participantId,
            },
          })
          if (status === 200) {
            AdminAPI.setToken(data.token)
            GameAPI.setToken(data.token)
            setAuthDetails({ ...authDetails, token: data.token })
            return doRequest(
              {
                ...params,
                headers: {
                  ...params.headers,
                  Authorization: `Bearer ${data.token}`,
                },
              },
              false,
            )
          }
        }
        throw new APIError({
          status: 401,
          message: e.response.data.message,
          type: 'unauthenticated',
        })
      }
      // ascension-api and ascension-admin-api has two different ways of returning errors, will need to update once that is patched
      const message =
        (e.response.data &&
          (e.response.data.message ||
            (e.response.data.error && e.response.data.error.message))) ||
        e.message
      throw new APIError({
        status: e.response.status,
        message: message,
        errors: e.response.data && e.response.data.errors,
      })
    }
    throw e
  }
}

export const get = async <data>(
  params: GetParams,
): Promise<HttpResponse<data>> => {
  return doRequest<data>({
    method: 'get',
    url: params.url,
    headers: params.headers || {},
  })
}

export const post = async <data>(
  params: PostParams,
): Promise<HttpResponse<data>> => {
  return doRequest<data>({
    method: 'post',
    url: params.url,
    headers: params.headers || {},
    data: params.body || {},
  })
}

export const doDelete = async <data>(
  params: GetParams,
): Promise<HttpResponse<data>> => {
  return doRequest<data>({
    method: 'delete',
    url: params.url,
    headers: params.headers || {},
  })
}

export const put = async <data>(
  params: PostParams,
): Promise<HttpResponse<data>> => {
  return doRequest<data>({
    method: 'put',
    url: params.url,
    headers: params.headers || {},
    data: params.body || {},
  })
}

export const patch = async <data>(
  params: PostParams,
): Promise<HttpResponse<data>> => {
  return doRequest<data>({
    method: 'patch',
    url: params.url,
    headers: params.headers || {},
    data: params.body || {},
  })
}
