import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'

import { Colour } from '../../../types/theme'
import { BACKGROUND_COLOUR_SHADE_CLASSES } from '../../../lib/colourClasses'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  backgroundColour: Colour
}

const MetricChangePill: React.FC<PropsWithChildren<Props>> = ({
  className,
  backgroundColour,
  children,
  ...rest
}) => {
  return (
    <div
      className={classNames(
        className,
        'flex whitespace-nowrap rounded-3xl px-5 py-1 text-center',
        BACKGROUND_COLOUR_SHADE_CLASSES[backgroundColour],
      )}
      {...rest}
    >
      <div className="w-full">
        <div className="flex items-center justify-center gap-0.5">
          {children}
        </div>
      </div>
    </div>
  )
}

export default MetricChangePill
