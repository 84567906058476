import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'
import {
  FontFamily,
  FontSize,
  FontWeight,
  TextColour,
} from '../../../types/theme'
import {
  useCommonClasses,
  Props as CommonProps,
} from '../../../hooks/useCommonClasses'
import { titleCase } from '../../../lib/text'

export interface Props
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'color'>,
    CommonProps {
  className?: string
  italic?: boolean
  colour?: TextColour
  // shade?: Shade
  size?: FontSize
  tabletSize?: FontSize
  laptopSize?: FontSize
  desktopSize?: FontSize
  weight?: FontWeight
  family?: FontFamily
  center?: boolean

  whitespace?: 'nowrap' | 'wrap' | 'preline'
  transform?: 'upper' | 'lower' | 'capitalize' | 'titleCase'
  isHtml?: boolean
  noLeading?: boolean
  left?: boolean
}

export const TEXT_SIZE_CLASSES: Record<FontSize, string> = {
  xs: 'text-xs',
  sm: 'text-sm',
  base: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
  '2xl': 'text-2xl',
  '3xl': 'text-3xl',
  '4xl': 'text-4xl',
  '5xl': 'text-5xl',
}

export const TABLET_TEXT_SIZE_CLASSES: Record<FontSize, string> = {
  xs: 'tablet:text-xs',
  sm: 'tablet:text-sm',
  base: 'tablet:text-base',
  lg: 'tablet:text-lg',
  xl: 'tablet:text-xl',
  '2xl': 'tablet:text-2xl',
  '3xl': 'tablet:text-3xl',
  '4xl': 'tablet:text-4xl',
  '5xl': 'tablet:text-5xl',
}

export const LAPTOP_TEXT_SIZE_CLASSES: Record<FontSize, string> = {
  xs: 'laptop:text-xs',
  sm: 'laptop:text-sm',
  base: 'laptop:text-base',
  lg: 'laptop:text-lg',
  xl: 'laptop:text-xl',
  '2xl': 'laptop:text-2xl',
  '3xl': 'laptop:text-3xl',
  '4xl': 'laptop:text-4xl',
  '5xl': 'laptop:text-5xl',
}

export const DESKTOP_TEXT_SIZE_CLASSES: Record<FontSize, string> = {
  xs: 'desktop:text-xs',
  sm: 'desktop:text-sm',
  base: 'desktop:text-base',
  lg: 'desktop:text-lg',
  xl: 'desktop:text-xl',
  '2xl': 'desktop:text-2xl',
  '3xl': 'desktop:text-3xl',
  '4xl': 'desktop:text-4xl',
  '5xl': 'desktop:text-5xl',
}

export const TEXT_COLOUR_CLASSES: Record<TextColour, string> = {
  success: 'text-success',
  error: 'text-error',
  primary: 'text-primary',
  white: 'text-white',
  black: 'text-black',
  gray: 'text-gray-400',
  orange: 'text-orange',
  pink: 'text-pink',
  green: 'text-green',
  purple: 'text-purple',
  darkblue: 'text-darkblue',
  lightgray: 'text-gray',
}

export const FONT_FAMILY_CLASSES: Record<FontFamily, string> = {
  primary: 'font-primary',
  secondary: 'font-secondary',
}

export const WHITESPACE_CLASS = {
  nowrap: 'whitespace-nowrap',
  wrap: 'whitespace-normal',
  preline: 'whitespace-pre-line',
}

export const FONT_WEIGHT_CLASSES: Record<FontWeight, string> = {
  light: 'font-light',
  normal: 'font-normal',
  semibold: 'font-semibold',
  bold: 'font-bold',
}

export const TRANSFORM_CLASS = {
  upper: 'uppercase',
  lower: 'lowercase',
  capitalize: 'capitalize',
}
const Text: React.FC<PropsWithChildren<Props>> = props => {
  const {
    className,
    children,
    italic,
    colour,
    // shade,
    size,
    tabletSize,
    laptopSize,
    desktopSize,
    weight,
    family,
    whitespace,
    center,
    transform,
    isHtml,
    noLeading,
    left,

    ...rest
  } = props
  const {
    padding,
    paddingLeft,
    paddingRight,
    paddingY,
    ...withoutCommonProps
  } = rest
  const commonClasses = useCommonClasses({
    padding,
    paddingLeft,
    paddingRight,
    paddingY,
  })
  const colourClass = colour ? TEXT_COLOUR_CLASSES[colour] : ''
  const fontSizeClass = TEXT_SIZE_CLASSES[size ?? 'base']
  const tabletFontSizeClass = tabletSize
    ? TABLET_TEXT_SIZE_CLASSES[tabletSize]
    : ''
  const laptopFontSizeClass = laptopSize
    ? LAPTOP_TEXT_SIZE_CLASSES[laptopSize]
    : ''
  const desktopFontSizeClass = desktopSize
    ? DESKTOP_TEXT_SIZE_CLASSES[desktopSize]
    : ''
  const fontWeightClass = weight ? FONT_WEIGHT_CLASSES[weight] : ''
  const fontFamilyClass = family ? FONT_FAMILY_CLASSES[family] : ''
  const whitespaceClass = whitespace ? WHITESPACE_CLASS[whitespace] : ''
  const transformClass = transform
    ? transform === 'titleCase'
      ? ''
      : TRANSFORM_CLASS[transform]
    : ''
  const renderHtml = isHtml && typeof children === 'string'
  const titleCasedContent =
    transform === 'titleCase' && typeof children === 'string'
      ? titleCase(children)
      : null

  return (
    <div
      className={classNames(
        'flex',

        className,
        commonClasses,
        colourClass,
        fontSizeClass,
        tabletFontSizeClass,
        laptopFontSizeClass,
        desktopFontSizeClass,
        fontWeightClass,
        fontFamilyClass,
        whitespaceClass,
        transformClass,

        {
          italic,
          'items-end': !renderHtml,
          'justify-center': center,
          'flex-col': renderHtml,
          'leading-none': noLeading,
          'text-left': left,
        },
      )}
      {...withoutCommonProps}
      {...(renderHtml ? { dangerouslySetInnerHTML: { __html: children } } : {})}
    >
      {!renderHtml ? titleCasedContent ?? children : null}
    </div>
  )
}

export default Text
