import { LoginParams, LoginResponse } from '../../../../../types/adminApi/auth'

const valid = [
  {
    username: 'demo',
    password: 'demo',
  },
]

export const getLoginDetails = ({
  email,
  password,
}: LoginParams): LoginResponse => {
  const ok = valid.find(
    v =>
      v.username === email.toLowerCase() &&
      v.password === password.toLowerCase(),
  )
  if (!ok) {
    throw new Error('Invalid credentials')
  }
  return {
    id: '12345',
    token: 'abcdef',
  }
}
