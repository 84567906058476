import React, { useEffect, useState } from 'react'
import Page from '../../atoms/Page/Page'
import { CostsResponse } from '../../../types/gameApi/costs'
import { DecisionMap } from './CostsContainer'
import CostsDivision from './CostDivision/CostsDivision'
import { useGetData } from './Data'
import CostImpacts from './CostImpacts/CostImpacts'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import CostsTotalImpact from './CostsTotalImpact/CostsTotalImpact'
import CostCarousel from './CostDivision/CostsCarousel'
import CostsTour from './CostsTour'
import { useIsMobile } from '../../../hooks/useIsMobile'

interface Props {
  data: CostsResponse
  decisions: DecisionMap
  enableChanges: boolean
  onChangeValue: (val: Partial<DecisionMap>) => void
}

const Costs: React.FC<Props> = props => {
  const costsData = useGetData({ data: props.data, decisions: props.decisions })
  const isMobile = useIsMobile()
  const [selectedDivision, setSelectedDivision] = useState<string | null>(null)
  const [linkedDivisions, setLinkedDivisions] = useState<(keyof DecisionMap)[]>(
    [],
  )

  useEffect(() => {
    if (
      selectedDivision === null &&
      costsData.divisions &&
      costsData.divisions.length > 0
    ) {
      setSelectedDivision(costsData.divisions[0].type)
    }
  }, [selectedDivision, costsData])

  const handleSelectDivision = (divisionType: string) => {
    setSelectedDivision(divisionType)
  }

  return (
    <div className="h-full">
      <Page className="flex h-[94vh] desktop:h-full">
        <VerticalGroup gap={2} full fullWidth>
          <VerticalGroup full fullWidth className="desktop:hidden">
            <CostCarousel
              linkedDivisions={linkedDivisions}
              setLinkedDivisions={setLinkedDivisions}
              onSelectDivision={handleSelectDivision}
              selectedDivision={selectedDivision}
              {...props}
              costData={costsData}
              peopleAndCostImpactPerDivision={
                costsData.peopleAndCostImpactPerDivision
              }
            />
          </VerticalGroup>
          <div className="hidden desktop:block">
            <CostsDivision
              linkedDivisions={linkedDivisions}
              setLinkedDivisions={setLinkedDivisions}
              onSelectDivision={handleSelectDivision}
              selectedDivision={selectedDivision}
              {...props}
              costData={costsData}
              peopleAndCostImpactPerDivision={
                costsData.peopleAndCostImpactPerDivision
              }
            />
          </div>
          <VerticalGroup
            gap={2}
            fullWidth
            className="h-full desktop:flex-row desktop:gap-2"
          >
            <div
              id="tour-impacts"
              className="h-[100%] w-full desktop:h-full desktop:w-[70%]"
            >
              <CostImpacts
                costData={costsData}
                selectedDivision={selectedDivision}
                {...props}
              />
            </div>
            <div
              id="tour-total-impact"
              className="hidden desktop:block desktop:w-[30%]"
            >
              <CostsTotalImpact {...props} costData={costsData} />
            </div>
            <div className="h-20 desktop:hidden"></div>
          </VerticalGroup>
        </VerticalGroup>
      </Page>
      <div className="fixed bottom-0 w-full desktop:hidden">
        <CostsTotalImpact {...props} costData={costsData} />
      </div>
      {!isMobile && <CostsTour />}
    </div>
  )
}

export default Costs
