import {
  Business,
  BusinessInterest,
  BusinessRates,
  BusinessVolume,
} from '../../../../types/gameApi/business'
import { DecisionMap } from '../BusinessesContainer'
import {
  InterestIncome,
  calculateAbsolutePercentage,
  calculatePercentage,
  getInterestIncomeForecastFromBusinessDecisions,
  getInterestIncomeForecastFromInterest,
  getInterestIncomeForecastFromVolume,
} from './BusinessAdvancedModeCalcs'
import { ItemData } from './types'

const getItemRateData = (
  item: Business,
  decisions: DecisionMap,
): ItemData['rate'] => ({
  value: decisions[item.type].price1Now,
  min: item.slider1Min,
  max: item.slider1Max,
  percentageChange: calculateAbsolutePercentage(
    decisions[item.type].price1Now,
    item.price1Prev,
    null,
    2,
  ),
})

const getItemInterestData = (
  interestIncome: InterestIncome,
): ItemData['interest'] => ({
  value: interestIncome.interestNow,
  percentageChange: calculatePercentage(
    interestIncome.interestNow,
    interestIncome.interestPrev,
    undefined,
    1,
  ),
})

const getItemData = (
  item: Business,
  interestIncome: InterestIncome,
  decisions: DecisionMap,
): ItemData => {
  return {
    id: item.type,
    name: item.name,
    type: item.type,
    rate: getItemRateData(item, decisions),
    volume: {
      value: decisions[item.type].volumeNow,
      min: 0,
      prevValue: item.volumePrev,
      percentageChange: calculatePercentage(
        decisions[item.type].volumeNow,
        item.volumePrev,
        undefined,
        1,
      ),
    },
    interest: getItemInterestData(interestIncome),
  }
}

interface Params {
  roundId: number
  data: Business[]
  rates: BusinessRates
  volume: BusinessVolume
  interest: BusinessInterest
  decisions: DecisionMap
}

export const getData = ({
  data,
  roundId,
  decisions,
  volume,
  interest,
  rates,
}: Params) => {
  const hlItem = data.find(i => i.type === 'HOME_LOANS')
  if (!hlItem) {
    throw new Error('No HL item')
  }
  const hlInterestIncome = getInterestIncomeForecastFromBusinessDecisions(
    'HOME_LOANS',
    data,
    roundId,
    decisions,
  )

  if (!hlInterestIncome) {
    throw new Error('No HL item')
  }

  const homeLoanData: ItemData = getItemData(
    hlItem,
    hlInterestIncome,
    decisions,
  )

  const blItem = data.find(i => i.type === 'BUSINESS_LOANS')
  if (!blItem) {
    throw new Error('No BL item')
  }

  const blInterestIncome = getInterestIncomeForecastFromBusinessDecisions(
    'BUSINESS_LOANS',
    data,
    roundId,
    decisions,
  )

  if (!blInterestIncome) {
    throw new Error('No BL item')
  }

  const businessLoanData: ItemData = getItemData(
    blItem,
    blInterestIncome,
    decisions,
  )

  const depositsItem = data.find(i => i.type === 'DEPOSITS')
  if (!depositsItem) {
    throw new Error('No deposits item')
  }

  const savingsVolumeNow =
    volume.savings.volumePrev *
    (1 +
      (decisions['DEPOSITS'].volumeNow - depositsItem.volumePrev) /
        depositsItem.volumePrev)

  const savingsInterestIncome = getInterestIncomeForecastFromVolume(
    savingsVolumeNow,
    volume.savings,
    {
      ratePrev: depositsItem.price1Prev,
      rateNow: decisions['DEPOSITS'].price1Now,
    },
  )

  const savingsData: ItemData = {
    id: depositsItem.type,
    name: `${depositsItem.name} - SAVINGS`,
    type: depositsItem.type,
    isLiability: true,
    rate: getItemRateData(depositsItem, decisions),
    volume: {
      value: savingsVolumeNow,
      incrementValue: decisions[depositsItem.type].volumeNow,
      prevValue: depositsItem.volumePrev,
      percentageChange: calculatePercentage(
        savingsVolumeNow,
        volume.savings.volumePrev,
        undefined,
        1,
      ),
    },
    interest: getItemInterestData(savingsInterestIncome),
  }
  const transactionsVolumeNow =
    volume.transactions.volumePrev *
    (1 +
      (decisions['DEPOSITS'].volumeNow - depositsItem.volumePrev) /
        depositsItem.volumePrev)
  const transactionsInterestIncome = getInterestIncomeForecastFromVolume(
    transactionsVolumeNow,
    volume.transactions,
    {
      ratePrev: 0,
      rateNow: 0,
    },
  )

  const transactionsData: ItemData = {
    id: depositsItem.type,
    name: `${depositsItem.name} - TRANSACTIONS`,
    type: depositsItem.type,
    isLiability: true,
    rate: {
      disableChanges: true,
      value: 0,
      percentageChange: calculateAbsolutePercentage(0, 0, null, 2),
    },
    volume: {
      disableChanges: true,
      value: transactionsVolumeNow,
      incrementValue: decisions[depositsItem.type].volumeNow,
      prevValue: depositsItem.volumePrev,
      percentageChange: calculatePercentage(
        transactionsVolumeNow,
        volume.transactions.volumePrev,
        undefined,
        1,
      ),
    },
    interest: getItemInterestData(transactionsInterestIncome),
  }

  const wsfInterestIncome = getInterestIncomeForecastFromVolume(
    volume.wsf.volumeNow ?? 0,
    volume.wsf,
    rates.wsf,
  )
  const creditCardsItem = data.find(i => i.type === 'CREDIT_CARDS')
  let creditCardVolumeNow = 0
  let ccInterestIncome = null
  if (creditCardsItem != null) {
    creditCardVolumeNow =
      (volume.creditCards.groupBalanceSheetValue ?? 0) *
      (1 +
        (decisions['CREDIT_CARDS'].volumeNow - creditCardsItem.volumePrev) /
          creditCardsItem.volumePrev)
    ccInterestIncome = getInterestIncomeForecastFromVolume(
      creditCardVolumeNow,
      volume.creditCards,
      rates.creditCards,
    )
  }
  const creditCardData: ItemData | null =
    creditCardsItem != null && ccInterestIncome != null
      ? {
          id: creditCardsItem.type,
          name: creditCardsItem?.name,
          type: creditCardsItem?.type,
          rate: {
            disableChanges: true,
            value: rates.creditCards.rateNow,
            percentageChange: calculateAbsolutePercentage(
              rates.creditCards.rateNow,
              rates.creditCards.ratePrev,
              null,
              2,
            ),
          },
          volume: {
            value: creditCardVolumeNow,
            incrementValue: decisions[creditCardsItem.type].volumeNow,
            prevValue: creditCardsItem.volumePrev,
            percentageChange: calculatePercentage(
              creditCardVolumeNow,
              volume.creditCards.volumePrev,
              undefined,
              1,
            ),
          },
          interest: getItemInterestData(ccInterestIncome),
        }
      : null

  const ibItem = data.find(i => i.type === 'INSTITUTIONAL_BANKING')

  let ibInterestIncome = null
  if (ibItem != null) {
    ibInterestIncome = getInterestIncomeForecastFromBusinessDecisions(
      'INSTITUTIONAL_BANKING',
      data,
      roundId,
      decisions,
    )
  }

  const ibData: ItemData | null =
    ibItem != null && ibInterestIncome != null
      ? getItemData(ibItem, ibInterestIncome, decisions)
      : null

  const cashInterestIncome = getInterestIncomeForecastFromInterest(
    volume.cash.volumeNow ?? 0,
    volume.cash,
    rates.cash,
    interest.cash,
  )
  const cashData: ItemData = {
    id: 'CASH',
    name: 'CASH',
    rate: {
      disableChanges: true,
      value: rates.cash.rateNow,
      percentageChange: calculateAbsolutePercentage(
        rates.cash.rateNow,
        rates.cash.ratePrev,
        null,
        2,
      ),
    },
    volume: {
      disableChanges: true,
      value: volume.cash.volumeNow ?? 0,
      prevValue: 0,
      percentageChange: calculatePercentage(
        volume.cash.volumeNow ?? 0,
        volume.cash.volumePrev,
        undefined,
        1,
      ),
    },
    interest: getItemInterestData(cashInterestIncome),
  }

  const wsfData: ItemData = {
    id: 'WSF',
    name: 'WHOLESALE FUNDING',
    isLiability: true,
    rate: {
      disableChanges: true,
      value: rates.wsf.rateNow,
      percentageChange: calculateAbsolutePercentage(
        rates.wsf.rateNow,
        rates.wsf.ratePrev,
        null,
        2,
      ),
    },
    volume: {
      disableChanges: true,
      value: volume.wsf.volumeNow ?? 0,
      prevValue: 0,
      percentageChange: calculatePercentage(
        volume.wsf.volumeNow ?? 0,
        volume.wsf.volumePrev,
        undefined,
        1,
      ),
    },
    interest: getItemInterestData(wsfInterestIncome),
  }

  const totalInterestPrev =
    hlInterestIncome.interestPrev +
    blInterestIncome.interestPrev +
    cashInterestIncome.interestPrev +
    (ccInterestIncome ? ccInterestIncome.interestPrev : 0) +
    (ibInterestIncome ? ibInterestIncome.interestPrev : 0)
  const totalInterestNow =
    hlInterestIncome.interestNow +
    blInterestIncome.interestNow +
    cashInterestIncome.interestNow +
    (ccInterestIncome ? ccInterestIncome.interestNow : 0) +
    (ibInterestIncome ? ibInterestIncome.interestNow : 0)
  const totalExpensePrev =
    savingsInterestIncome.interestPrev +
    transactionsInterestIncome.interestPrev +
    wsfInterestIncome.interestPrev
  const totalExpenseNow =
    savingsInterestIncome.interestNow +
    transactionsInterestIncome.interestNow +
    wsfInterestIncome.interestNow
  const returnOnAssetsPrev = interest.returnOnAssets.interestPrev / 10000
  const returnOnAssetsNow =
    totalInterestNow /
    (hlInterestIncome.averageNow +
      blInterestIncome.averageNow +
      cashInterestIncome.averageNow +
      (ccInterestIncome ? ccInterestIncome.averageNow : 0) +
      (ibInterestIncome ? ibInterestIncome.averageNow : 0))
  const costOfFundingPrev = interest.costOfFunds.interestPrev / 10000
  const costOfFundingNow =
    totalExpenseNow /
    (hlInterestIncome.averageNow +
      blInterestIncome.averageNow +
      cashInterestIncome.averageNow +
      (ccInterestIncome ? ccInterestIncome.averageNow : 0) +
      (ibInterestIncome ? ibInterestIncome.averageNow : 0))
  const totalVolumePrev =
    hlItem.volumePrev +
    blItem.volumePrev +
    (creditCardsItem ? volume.creditCards.volumePrev || 0 : 0) +
    (ibItem ? ibItem.volumePrev : 0) +
    volume.cash.volumePrev
  const totalVolumeNow =
    decisions['HOME_LOANS'].volumeNow +
    decisions['BUSINESS_LOANS'].volumeNow +
    (ibItem ? decisions['INSTITUTIONAL_BANKING'].volumeNow : 0) +
    creditCardVolumeNow +
    (volume.cash.volumeNow ?? 0)
  const totalExpenseVolumePrev =
    volume.savings.volumePrev +
    volume.transactions.volumePrev +
    volume.wsf.volumePrev
  const totalExpenseVolumeNow =
    savingsVolumeNow + transactionsVolumeNow + (volume.wsf.volumeNow ?? 0)
  const netInterestIncomePrev = totalInterestPrev - totalExpensePrev
  const netInterestIncomeNow = totalInterestNow - totalExpenseNow
  const nimPrev = interest.nim.interestPrev / 10000
  const nimNow =
    netInterestIncomeNow /
    (hlInterestIncome.averageNow +
      blInterestIncome.averageNow +
      cashInterestIncome.averageNow +
      (ccInterestIncome ? ccInterestIncome.averageNow : 0) +
      (ibInterestIncome ? ibInterestIncome.averageNow : 0))

  const netInterestIncome = {
    prev: netInterestIncomePrev,
    now: netInterestIncomeNow,
    change: calculatePercentage(
      netInterestIncomeNow,
      netInterestIncomePrev,
      undefined,
      1,
    ),
  }
  const nim = {
    prev: nimPrev,
    now: nimNow * 10000,
    change: calculateAbsolutePercentage(
      nimNow * 10000,
      nimPrev * 10000,
      null,
      2,
    ),
  }

  const totalInterestIncomeData: ItemData = {
    id: 'TOTAL_INTEREST_INCOME',
    name: 'TOTAL INTEREST INCOME',
    rate: {
      disableChanges: true,
      value: returnOnAssetsNow * 10000,
      percentageChange: calculateAbsolutePercentage(
        returnOnAssetsNow * 10000,
        returnOnAssetsPrev * 10000,
        null,
        2,
      ),
    },
    volume: {
      disableChanges: true,
      value: totalVolumeNow,
      prevValue: 0,
      percentageChange: calculatePercentage(
        totalVolumeNow,
        totalVolumePrev,
        undefined,
        1,
      ),
    },
    interest: getItemInterestData({
      averagePrev: 0,
      averageNow: 0,
      interestPrev: totalInterestPrev,
      interestNow: totalInterestNow,
    }),
  }

  const totalInterestExpenseData: ItemData = {
    id: 'TOTAL_INTEREST_EXPENSE',
    name: 'TOTAL INTEREST EXPENSE',
    isLiability: true,
    rate: {
      disableChanges: true,
      value: costOfFundingNow * 10000,
      percentageChange: calculateAbsolutePercentage(
        costOfFundingNow * 10000,
        costOfFundingPrev * 10000,
        null,
        2,
      ),
    },
    volume: {
      disableChanges: true,
      value: totalExpenseVolumeNow,
      prevValue: 0,
      percentageChange: calculatePercentage(
        totalExpenseVolumeNow,
        totalExpenseVolumePrev,
        undefined,
        1,
      ),
    },
    interest: getItemInterestData({
      averagePrev: 0,
      averageNow: 0,
      interestPrev: totalExpensePrev,
      interestNow: totalExpenseNow,
    }),
  }

  const assetData = [
    homeLoanData,
    businessLoanData,
    creditCardData,
    ibData,
    cashData,
    totalInterestIncomeData,
  ].filter(Boolean) as ItemData[]

  const liabilityData = [
    savingsData,
    transactionsData,
    wsfData,
    totalInterestExpenseData,
  ]

  return { assetData, liabilityData, netInterestIncome, nim }
}
