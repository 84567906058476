import wait from '../lib/wait'
import store from '../store'
import { updateBusinessDecisions } from '../redux/businesses/businessActions'

import { v4 as uuidv4 } from '../lib/uuid'
import { changeBusiness } from '../redux/businesses/businessSlice'
import { RouteWithMeta } from '../hooks/useRoutes'
import { Automate } from '../types/gameApi/device'
import { changeAutomateRedirect } from '../redux/game/gameSlice'

const getRandomNumberBetween = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min)

class Automator {
  workingScreen: boolean
  intervalScreenMs: number
  workingWidgets: boolean
  menuTimeMax: number
  menuTimeMin: number
  widgetTimeMin: number
  widgetTimeMax: number
  intervalWidgets: number
  widgetIndex: number
  widgetMaxPricingChange: number
  intervalScreen: NodeJS.Timeout | null
  constructor() {
    this.workingScreen = false
    this.intervalScreenMs = 0
    this.workingWidgets = false
    this.menuTimeMax = 0
    this.menuTimeMin = 0
    this.widgetTimeMin = 0
    this.widgetTimeMax = 0
    this.intervalWidgets = 0
    this.widgetIndex = 0
    this.widgetMaxPricingChange = 0
    this.intervalScreen = null
  }

  start({
    testModeScreen,
    menuTimeMax,
    menuTimeMin,
    testModeWidgets,
    widgetTimeMin,
    widgetTimeMax,
    widgetIndex = 0,
    widgetMaxPricingChange,
  }: Automate) {
    if (!testModeScreen && !testModeWidgets) {
      this.workingScreen = false
      this.menuTimeMax = 0
      this.menuTimeMin = 0
      this.workingWidgets = false
      this.widgetTimeMin = 0
      this.widgetTimeMax = 0
      this.intervalScreenMs = 0
      this.intervalWidgets = 0
      this.widgetMaxPricingChange = 0
      if (this.intervalScreen) {
        clearInterval(this.intervalScreen)
        this.intervalScreen = null
      }
      return
    }

    if (testModeScreen && !this.workingScreen && !this.intervalScreen) {
      this.workingScreen = true
      this.workingWidgets = false
      this.widgetTimeMin = 0
      this.widgetTimeMax = 0
      this.widgetMaxPricingChange = 0

      this.menuTimeMax = menuTimeMax
      this.menuTimeMin = menuTimeMin
      this.intervalScreenMs = getRandomNumberBetween(menuTimeMin, menuTimeMax)
      this.automateScreen()
      this.intervalScreen = setInterval(
        this.automateScreen,
        this.intervalScreenMs * 1000,
      )
      return
    }

    if (
      testModeScreen &&
      (this.menuTimeMin !== menuTimeMin || this.menuTimeMax !== menuTimeMax)
    ) {
      this.menuTimeMax = menuTimeMax
      this.menuTimeMin = menuTimeMin
      this.intervalScreenMs = getRandomNumberBetween(menuTimeMin, menuTimeMax)
      if (this.intervalScreen) {
        clearInterval(this.intervalScreen)
      }
      this.intervalScreen = setInterval(
        this.automateScreen,
        this.intervalScreenMs * 1000,
      )
      return
    }

    if (testModeWidgets && !this.workingWidgets) {
      this.workingWidgets = true
      this.workingScreen = false
      this.menuTimeMax = 0
      this.menuTimeMin = 0

      this.widgetTimeMin = widgetTimeMin
      this.widgetTimeMax = widgetTimeMax
      this.widgetMaxPricingChange = widgetMaxPricingChange
      this.intervalWidgets = getRandomNumberBetween(
        widgetTimeMin,
        widgetTimeMax,
      )
      this.widgetIndex = widgetIndex ?? 0
      this.automateWidgets()
      return
    }

    if (
      testModeWidgets &&
      (this.widgetTimeMin !== widgetTimeMin ||
        this.widgetTimeMax !== widgetTimeMax ||
        this.widgetMaxPricingChange !== widgetMaxPricingChange)
    ) {
      this.widgetTimeMin = widgetTimeMin
      this.widgetTimeMax = widgetTimeMax
      this.widgetMaxPricingChange = widgetMaxPricingChange
      this.intervalWidgets = getRandomNumberBetween(
        widgetTimeMin,
        widgetTimeMax,
      )
    }
  }

  automateScreen = async () => {
    const {
      game: gameState,
      device: deviceState,
      team: teamState,
    } = store.getState()

    if (this.workingScreen) {
      if (deviceState.registered && teamState.id && teamState.registered) {
        const selectedRound = gameState.selectedRound
        const roundsSetup = gameState.roundsSetup
        const isWrapUp = gameState.state === 'wrapup'

        const roundSetup = roundsSetup.find(rs => rs.round === selectedRound)
        if (!roundSetup) {
          return
        }

        const {
          insightsEnabled,
          // surveyEnabled,
          dealsEnabled,
          excoEnabled,
          specialProjectsEnabled,
          operatingCostsEnabled,
        } = roundSetup

        const routes: RouteWithMeta[] = [
          {
            name: 'Summary',
            href: 'summary',
            iconType: 'summary',
            current: false,
            type: 'information',
            translateKey: 'mainMenu.summaryButton',
            orderKey: 'mainMenuOrder.summaryButton',
            enabled: true,
            id: 'summary',
          },
          {
            name: 'Breaking News',
            href: 'breaking-news',
            iconType: 'news',
            current: false,
            type: 'information',
            translateKey: 'mainMenu.newsButton',
            orderKey: 'mainMenuOrder.newsButton',
            enabled: !isWrapUp,
            id: 'breaking-news',
          },
          {
            name: 'Peer Insights',
            href: 'peer-insights',
            iconType: 'peer',
            current: false,
            type: 'information',
            translateKey: 'mainMenu.peerInsightsButton',
            orderKey: 'mainMenuOrder.peerInsightsButton',
            enabled: insightsEnabled,
            id: 'peer-insights',
          },
          {
            name: 'Our Financials',
            href: 'our-financials',
            iconType: 'financials',
            current: false,
            type: 'information',
            translateKey: 'mainMenu.ourFinancialsButton',
            orderKey: 'mainMenuOrder.ourFinancialsButton',
            enabled: true,
            id: 'our-financials',
          },
          {
            name: 'Help Manual',
            href: 'help-manual',
            iconType: 'info',
            current: false,
            type: 'information',
            translateKey: 'mainMenu.helpManualButton',
            orderKey: 'mainMenuOrder.helpManualButton',
            enabled: true,
            id: 'help-manual',
          },
          {
            name: 'Opex',
            href: 'costs',
            iconType: 'cost',
            current: false,
            type: 'decision',
            translateKey: 'mainMenu.costsButton',
            orderKey: 'mainMenuOrder.costsButton',
            enabled: !isWrapUp && operatingCostsEnabled,
            id: 'costs',
          },
          {
            name: 'Special Projects',
            href: 'special-projects',
            iconType: 'projects',
            current: false,
            type: 'decision',
            translateKey: 'mainMenu.specialProjectsButton',
            orderKey: 'mainMenuOrder.specialProjectsButton',
            enabled: !isWrapUp && specialProjectsEnabled,
            id: 'special-projects',
          },
          {
            name: 'Deal Room',
            href: 'deal-room',
            iconType: 'deal',
            current: false,
            type: 'decision',
            translateKey: 'mainMenu.dealRoomButton',
            orderKey: 'mainMenuOrder.dealRoomButton',
            enabled: !isWrapUp && dealsEnabled,
            id: 'deal-room',
          },
          {
            name: 'Treasury',
            href: 'treasury',
            iconType: 'treasury',
            current: false,
            type: 'decision',
            translateKey: 'mainMenu.treasuryButton',
            orderKey: 'mainMenuOrder.treasuryButton',
            enabled: !isWrapUp,
            id: 'treasury',
          },
          {
            name: 'Exco Decisions',
            href: 'exco-decisions',
            iconType: 'exco',
            current: false,
            type: 'decision',
            translateKey: 'mainMenu.excoButton',
            orderKey: 'mainMenuOrder.excoButton',
            enabled: !isWrapUp && excoEnabled,
            id: 'exco-decisions',
          },
          {
            name: 'Businesses',
            href: 'businesses',
            iconType: 'businesses',
            current: false,
            type: 'decision',
            translateKey: 'mainMenu.businessesButton',
            orderKey: 'mainMenuOrder.businessesButton',
            enabled: !isWrapUp,
            id: 'businesses',
          },
          // {
          //   name: 'Assessment',
          //   href: 'assessments',
          //   iconType: 'exco',
          //   current: false,
          //   type: 'decision',
          //   translateKey: 'mainMenu.assessmentButton',
          //   orderKey: 'mainMenuOrder.assessmentButton',
          //   enabled: !isWrapUp && surveyEnabled,
          // },
        ]

        const allSections = routes.filter(
          i => i.enabled && i.href !== `/${window.location.pathname}`,
        )

        const randomIndex = getRandomNumberBetween(0, allSections.length - 1)
        const randomSection = allSections[randomIndex]

        store.dispatch(changeAutomateRedirect(`/${randomSection.href}`))
      }
    }
  }

  automateWidgets = async (widgetIndexOverride?: number) => {
    const {
      game: gameState,
      device: deviceState,
      team: teamState,
      businesses: businessState,
    } = store.getState()

    if (this.workingWidgets) {
      if (deviceState.registered && teamState.id && teamState.registered) {
        if (window.location.pathname !== 'businesses') {
          store.dispatch(changeAutomateRedirect('/businesses'))
        }

        if (
          this.widgetIndex === -1 &&
          (widgetIndexOverride == null || widgetIndexOverride === 4)
        ) {
          widgetIndexOverride = 0
        }

        if (businessState.isLoaded) {
          const selectedRound = gameState.selectedRound
          const businessToChange =
            businessState.data[selectedRound]?.businesses[
              widgetIndexOverride != null
                ? widgetIndexOverride
                : this.widgetIndex
            ]

          if (businessToChange) {
            if (businessState.currentBusiness === businessToChange.type) {
              if (widgetIndexOverride != null) {
                widgetIndexOverride = widgetIndexOverride + 1
              }

              console.log({
                business: businessToChange.type,
                min:
                  this.widgetMaxPricingChange > 0
                    ? Math.max(
                        businessToChange.slider1Min,
                        businessToChange.price1NowOriginal -
                          this.widgetMaxPricingChange,
                      )
                    : businessToChange.slider1Min,
                max:
                  this.widgetMaxPricingChange > 0
                    ? Math.min(
                        businessToChange.slider1Max,
                        businessToChange.price1NowOriginal +
                          this.widgetMaxPricingChange,
                      )
                    : businessToChange.slider1Max,
                widgetMaxPricingChange: this.widgetMaxPricingChange,
              })

              store.dispatch(
                updateBusinessDecisions({
                  teamId: teamState.id,
                  roundId: selectedRound,
                  decisions: [
                    {
                      businessId: businessToChange.id,
                      price1Now: getRandomNumberBetween(
                        this.widgetMaxPricingChange > 0
                          ? Math.max(
                              businessToChange.slider1Min,
                              businessToChange.price1NowOriginal -
                                this.widgetMaxPricingChange,
                            )
                          : businessToChange.slider1Min,
                        this.widgetMaxPricingChange > 0
                          ? Math.min(
                              businessToChange.slider1Max,
                              businessToChange.price1NowOriginal +
                                this.widgetMaxPricingChange,
                            )
                          : businessToChange.slider1Max,
                      ),
                    },
                    {
                      businessId: businessToChange.id,
                      price2Now: getRandomNumberBetween(
                        businessToChange.slider2Min,
                        businessToChange.slider2Max,
                      ),
                    },
                  ],
                  deviceId: uuidv4(),
                }),
              )
            } else {
              store.dispatch(changeBusiness(businessToChange.type))
            }
          }
        }
      }
      await wait(this.intervalWidgets * 1000)
      this.automateWidgets(widgetIndexOverride)
    }
  }
}

export default Automator
