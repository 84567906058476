import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store'
import Modal from '../../../atoms/Modal/Modal'
import { formatParticipantName } from './formatters'
import { Icon } from '../../../atoms/Icon/Icon'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import { cancelCEOTransferRequest } from '../../../../redux/changeCeo/changeCeoActions'
import { clear } from '../../../../redux/changeCeo/changeCeoSlice'
import PrimaryText from '../../../atoms/Text/PrimaryText'
import SecondaryText from '../../../atoms/Text/SecondaryText'

interface Props {}

const WaitingRoomCeoTransfer: React.FC<Props> = () => {
  const {
    ceoTransferRequestId,
    ceoTransferRequestParticipantId,
    ceoTransferRequestInProgress,
    ceoTransferRequestComplete,
    ceoTransferRequestStatus,
    participants,
    participantId,
    cleared,
  } = useAppSelector(state => ({
    participants: state.changeCeo.participants,
    participantId: state.auth.participantId ?? '',
    ceoTransferRequestId: state.changeCeo.ceoTransferRequestId,
    ceoTransferRequestParticipantId:
      state.changeCeo.ceoTransferRequestParticipantId,
    ceoTransferRequestInProgress: state.changeCeo.ceoTransferRequestInProgress,
    ceoTransferRequestComplete: state.changeCeo.ceoTransferRequestComplete,
    ceoTransferRequestError: state.changeCeo.ceoTransferRequestError,
    ceoTransferRequestStatusInProgress:
      state.changeCeo.ceoTransferRequestStatusInProgress,
    ceoTransferRequestStatus: state.changeCeo.ceoTransferRequestStatus,
    ceoTransferRequestStatusError:
      state.changeCeo.ceoTransferRequestStatusError,
    cancelCeoTransferRequestInProgress:
      state.changeCeo.cancelCeoTransferRequestInProgress,
    cancelCeoTransferRequest: state.changeCeo.cancelCeoTransferRequest,
    cancelCeoTransferRequestError:
      state.changeCeo.cancelCeoTransferRequestError,
    cleared: state.changeCeo.cleared,
  }))

  const dispatch = useAppDispatch()

  const handleOnCancel = useCallback(() => {
    if (ceoTransferRequestId) {
      dispatch(
        cancelCEOTransferRequest({
          participantId,
          requestId: ceoTransferRequestId,
        }),
      )
    }
  }, [ceoTransferRequestId, dispatch, participantId])

  const handleOnClose = useCallback(() => {
    dispatch(clear())
  }, [dispatch])

  let modalContent: React.ReactNode | null = null
  let modalTitle = ''

  const clearedLessThan20SecondsAgo =
    cleared != null && Date.now() - cleared < 20000
  const isWaiting =
    !clearedLessThan20SecondsAgo &&
    (ceoTransferRequestInProgress ||
      (ceoTransferRequestComplete && !ceoTransferRequestStatus))
  const isConfirmation =
    !!ceoTransferRequestStatus && !clearedLessThan20SecondsAgo

  if (isWaiting) {
    const requestedPerson = participants.find(
      p => p.id === ceoTransferRequestParticipantId,
    )
    if (requestedPerson) {
      modalContent = (
        <VerticalGroup center verticalCenter className="h-36" gap={4}>
          <Icon
            type="hourglass"
            colour="primary"
            className="animate-spin-slow"
            size={16}
          />
          <PrimaryText>
            Waiting for {formatParticipantName(requestedPerson)} to accept
          </PrimaryText>
        </VerticalGroup>
      )
      modalTitle = `Changing CEO`
    }
  }
  if (isConfirmation) {
    const requestedPerson = participants.find(
      p => p.id === ceoTransferRequestParticipantId,
    )
    if (requestedPerson) {
      modalContent = (
        <SecondaryText>
          {formatParticipantName(requestedPerson)} has{' '}
          {ceoTransferRequestStatus} your request
        </SecondaryText>
      )
      modalTitle = `Request ${ceoTransferRequestStatus}`
    }
  }

  if (!modalContent) {
    return null
  }

  return (
    <Modal
      show
      onClose={
        isConfirmation
          ? handleOnClose
          : () => {
              console.log('closed')
            }
      }
      title={modalTitle}
      onCancel={isWaiting && !isConfirmation ? handleOnCancel : undefined}
      onOk={isConfirmation ? handleOnClose : undefined}
    >
      {modalContent}
    </Modal>
  )
}

export default WaitingRoomCeoTransfer
