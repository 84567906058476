import React, { FC } from 'react'

import VerticalGroup, {
  Props as VerticalGroupProps,
} from '../../../atoms/VerticalGroup/VerticalGroup'
import { useAppSelector } from '../../../../store'
import { selectBranding } from '../selectors'

interface Props extends VerticalGroupProps {}

const BrandedVerticalGroup: FC<Props> = props => {
  const branding = useAppSelector(selectBranding)
  const style: React.CSSProperties = props.style ?? {}
  if (branding.mainBackgroundColour) {
    style.backgroundColor = branding.mainBackgroundColour
  }
  return <VerticalGroup {...props} style={style} />
}

export default BrandedVerticalGroup
