import React from 'react'
import Button from '../../atoms/Button/Button'
import classNames from 'classnames'
import PrimaryText from '../../atoms/Text/PrimaryText'

interface Props {
  disabled: boolean
  text: string
}

const LoginButton: React.FC<Props> = ({ disabled, text }) => {
  return (
    <Button
      type="submit"
      buttonType="primary"
      disabled={disabled}
      full
      className={classNames({
        'bg-gray-500 hover:bg-gray-500': disabled,
      })}
    >
      <PrimaryText size="xs" colour="white">
        {text}
      </PrimaryText>
    </Button>
  )
}

export default LoginButton
