import React, { PropsWithChildren } from 'react'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import MetricCardIconBox from '../../../organisms/MetricCard/MetricCardIconBox'
import { IconType } from '../../../atoms/Icon/Icon'
import { Colour } from '../../../../types/theme'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import MetricCard from '../../../organisms/MetricCard/MetricCard'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'

interface Props {
  iconType: IconType
  backgroundColour: Colour
  title: string
  id?: string
}

const TreasuryBalanceSheetDecisionPanel: React.FC<PropsWithChildren<Props>> = ({
  iconType,
  backgroundColour,
  title,
  children,
  id,
}) => {
  return (
    <MetricCard id={id} alignContent="start" colour={'white'} className="p-4">
      <HorizontalGroup verticalCenter fullWidth gap={3} className=" ">
        <div className="h-16 w-24 laptop:h-20 laptop:w-20">
          <MetricCardIconBox
            size="responsive"
            iconType={iconType}
            iconColour="white"
            backgroundColour={backgroundColour}
            iconSize={10}
          />
        </div>
        <VerticalGroup verticalCenter full gap={2} fullWidth center>
          <SecondaryText weight="bold">{title}</SecondaryText>
          {children}
        </VerticalGroup>
      </HorizontalGroup>
    </MetricCard>
  )
}

export default TreasuryBalanceSheetDecisionPanel
