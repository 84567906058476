import React from 'react'
import { ProjectImpact } from '../../../types/gameApi/projects'
import PrimaryText from '../../atoms/Text/PrimaryText'

import ImpactValueChanger from './ImpactValueChanger'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'

interface Props {
  impact: ProjectImpact
}

const SpecialProjectImpact: React.FC<Props> = ({ impact }) => {
  return (
    <VerticalGroup center>
      <PrimaryText
        transform="upper"
        className="tracking-wider text-slate-800"
        size="xs"
      >
        {impact.name}
      </PrimaryText>
      <HorizontalGroup center gap={1}>
        <ImpactValueChanger value={impact.value} />
      </HorizontalGroup>
    </VerticalGroup>
  )
}

export default SpecialProjectImpact
