import React from 'react'
import { Assumption, Explanation } from '../configs/utils'

import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import TableText from '../../../atoms/Table/TableText'
import NumericInput from '../../../atoms/Input/NumericInput'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import Card from '../../../atoms/Card/Card'
import PrimaryText from '../../../atoms/Text/PrimaryText'

interface Props {
  explanation: Explanation
  formula: React.ReactNode
  assumption: Assumption | null
  index: number
  canBid: boolean
  totalCards: number
  onAssumptionUpdate: (index: number, value: number) => void
  resetAssumptions: () => void
}

const ValuationCard: React.FC<Props> = ({
  explanation,
  formula,
  assumption,
  index,
  canBid,
  onAssumptionUpdate,
}) => {
  return (
    <VerticalGroup fullWidth>
      <div>
        <VerticalGroup className="px-4">
          <Card className="p-4">
            <VerticalGroup gap={4} verticalCenter className="bg-white">
              <VerticalGroup>
                <PrimaryText size="sm" weight="bold">
                  {explanation.text}
                </PrimaryText>
              </VerticalGroup>
              <HorizontalGroup between fullWidth>
                <VerticalGroup className="w-full">
                  {/* because the formulas sometimes have a <sup> element we can't use our normal text component */}
                  <VerticalGroup>
                    <div className="text-xs text-gray-400">
                      {explanation.subtext}&nbsp;
                    </div>
                    <div className="flex h-10 items-center text-sm">
                      {formula}
                    </div>
                  </VerticalGroup>
                  {assumption?.range != null && (
                    <PrimaryText size="xs">&nbsp; </PrimaryText>
                  )}
                </VerticalGroup>
                {assumption != null && !isNaN(assumption.value) && (
                  <VerticalGroup className="w-48">
                    <VerticalGroup>
                      <PrimaryText colour="gray" size="xs" whitespace="nowrap">
                        {assumption.label}
                      </PrimaryText>
                      <HorizontalGroup gap={1} verticalCenter className="h-10">
                        <NumericInput
                          type="integer"
                          onError={() => null}
                          validateInput={() => undefined}
                          disabled={!canBid}
                          value={assumption.value}
                          onChange={(value: number) => {
                            if (value > 0) {
                              onAssumptionUpdate(index + 1, value)
                            }
                          }}
                        />
                        <TableText>{assumption.denomination}</TableText>
                      </HorizontalGroup>
                    </VerticalGroup>
                    {assumption.range != null && (
                      <HorizontalGroup gap={1} verticalCenter fullWidth>
                        <PrimaryText
                          className="flex-1"
                          size="xs"
                          colour="error"
                          center
                          notResponsive
                        >
                          ({assumption.range[0]}-{assumption.range[1]}
                          {assumption.denomination})
                        </PrimaryText>
                        <TableText className="text-transparent">
                          &nbsp;
                        </TableText>
                      </HorizontalGroup>
                    )}
                  </VerticalGroup>
                )}
              </HorizontalGroup>
            </VerticalGroup>
          </Card>
        </VerticalGroup>
      </div>
    </VerticalGroup>
  )
}

export default ValuationCard
