import { EventResponse } from '../../../../../types/adminApi/events'

export const getEventsForParticipantData = (): EventResponse[] => {
  return [
    {
      date: '2023-01-01',
      clientId: 'client123',
      name: 'Demo event',
      location: 'online',
      id: 'abcde',
      client: 'Demo client',
      url: 'https://demo.com',
      serverOnline: true,
      isGameSetup: true,
      closed: false,
      participantsCount: 1,
    },
  ]
}
