import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import TextArea, { Props as TextAreaProps } from '../../../atoms/Input/TextArea' // AssessmentTextArea.tsx

interface Props extends TextAreaProps {}

const AssessmentTextArea: React.FC<Props> = ({ onValueChange, ...rest }) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const adjustHeightForAssessments = useCallback(() => {
    const minHeight = 40

    if (textAreaRef.current) {
      const isTextAreaEmpty = textAreaRef.current.value === ''

      if (isTextAreaEmpty) {
        console.log('is empty')
        textAreaRef.current.style.height = `${minHeight}px`
      } else {
        if (textAreaRef.current.scrollHeight > minHeight) {
          textAreaRef.current.style.height = 'auto'
          textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
        } else {
          textAreaRef.current.style.height = `${minHeight}px`
        }
      }

      textAreaRef.current.className =
        'min-w-64 max-w-80vw h-10 w-full resize-none overflow-y-hidden border-b border-primary bg-transparent text-primary shadow-primary transition ease-in focus:border-t-transparent focus:border-r-transparent focus:border-l-transparent focus:outline-none focus:ring-0 tablet:text-xl laptop:text-xl desktop:text-2xl'
    }
  }, [])

  const onChangeHandler = useCallback(
    (value: string, name: string) => {
      adjustHeightForAssessments()
      if (onValueChange) {
        onValueChange(value, name)
      }
    },
    [adjustHeightForAssessments, onValueChange],
  )

  useLayoutEffect(() => {
    // Delay the initial height adjustment to ensure the component is rendered
    const timeoutId = setTimeout(() => {
      if (textAreaRef.current) {
        adjustHeightForAssessments()
      }
    }, 50)

    return () => clearTimeout(timeoutId)
  }, [adjustHeightForAssessments])

  useEffect(() => {
    if (textAreaRef.current) {
      adjustHeightForAssessments()
    }
  }, [adjustHeightForAssessments])

  return (
    <TextArea ref={textAreaRef} onValueChange={onChangeHandler} {...rest} />
  )
}

export default AssessmentTextArea
