import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  createCEOTransferRequest,
  fetchParticipants,
  acceptCEOTransferRequest,
  cancelCEOTransferRequest,
  getCEOTransferRequest,
} from './changeCeoActions'
import APIError from '../../errors/APIError'
import { reset } from '../game/gameSlice'

import { logout } from '../auth/authSlice'
import { ParticipantResponse } from '../../types/adminApi/events'
import { CEOTransfer, CEOTransferStatus } from '../../types/gameApi/ceoTransfer'

interface ChangeCEOState {
  isLoading: boolean
  isLoaded: boolean
  error: Error | APIError | null

  participants: ParticipantResponse[]

  ceoTransferRequestInProgress: boolean
  ceoTransferRequestComplete: boolean
  ceoTransferRequestError: Error | APIError | null

  ceoTransferRequestReceived: CEOTransfer | null

  ceoTransferRequestId: string | null

  ceoTransferRequestParticipantId: string | null

  acceptCeoTransferRequestInProgress: boolean
  declineCeoTransferRequestInProgress: boolean
  acceptDeclineCeoTransferRequestComplete: boolean
  acceptDeclineCeoTransferRequestError: Error | APIError | null

  ceoTransferRequestStatusInProgress: boolean
  ceoTransferRequestStatus: CEOTransferStatus | null
  ceoTransferRequestStatusError: Error | APIError | null

  cancelCeoTransferRequestInProgress: boolean
  cancelCeoTransferRequest: boolean | null
  cancelCeoTransferRequestError: Error | APIError | null

  cleared: null | number
}

const initialState: ChangeCEOState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  participants: [],

  ceoTransferRequestInProgress: false,
  ceoTransferRequestComplete: false,
  ceoTransferRequestError: null,

  ceoTransferRequestReceived: null,

  ceoTransferRequestId: null,

  ceoTransferRequestParticipantId: null,

  acceptCeoTransferRequestInProgress: false,
  declineCeoTransferRequestInProgress: false,
  acceptDeclineCeoTransferRequestComplete: false,
  acceptDeclineCeoTransferRequestError: null,

  ceoTransferRequestStatusInProgress: false,
  ceoTransferRequestStatus: null,
  ceoTransferRequestStatusError: null,

  cancelCeoTransferRequestInProgress: false,
  cancelCeoTransferRequest: null,
  cancelCeoTransferRequestError: null,
  cleared: null,
}

const changeCeoSlice = createSlice({
  name: 'changeCeo',
  initialState,
  reducers: {
    clear: () => {
      return {
        ...initialState,
        cleared: Date.now(),
      }
    },
    setCEOTransferRequestReceived: (
      state,
      action: PayloadAction<CEOTransfer | null | undefined>,
    ) => {
      state.ceoTransferRequestReceived = action.payload ?? null
    },
    setCEOTransferRequestPending: (
      state,
      action: PayloadAction<CEOTransfer>,
    ) => {
      state.ceoTransferRequestParticipantId = action.payload.to
      state.ceoTransferRequestInProgress = true
      state.ceoTransferRequestComplete = true
      state.ceoTransferRequestId = action.payload.id
      state.ceoTransferRequestStatusInProgress = true
      state.ceoTransferRequestStatus = null
      state.ceoTransferRequestStatusError = null
    },
  },
  extraReducers: builder => {
    builder
      .addCase(reset, () => {
        return initialState
      })
      .addCase(logout, () => {
        return initialState
      })
      .addCase(fetchParticipants.pending, state => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchParticipants.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isLoaded = true
        state.error = null
        state.participants = payload.participants
      })
      .addCase(fetchParticipants.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload ?? null
      })
      .addCase(createCEOTransferRequest.pending, (state, { meta }) => {
        state.ceoTransferRequestParticipantId = meta.arg.to
        state.ceoTransferRequestInProgress = true
        state.ceoTransferRequestError = null
        state.ceoTransferRequestStatusInProgress = false
        state.ceoTransferRequestStatus = null
        state.ceoTransferRequestStatusError = null
      })
      .addCase(createCEOTransferRequest.fulfilled, state => {
        state.ceoTransferRequestComplete = true
        state.ceoTransferRequestInProgress = false
        state.ceoTransferRequestError = null
      })
      .addCase(createCEOTransferRequest.rejected, (state, { payload }) => {
        state.ceoTransferRequestComplete = false
        state.ceoTransferRequestInProgress = false
        state.ceoTransferRequestError = payload ?? null
      })
      .addCase(acceptCEOTransferRequest.pending, state => {
        state.acceptCeoTransferRequestInProgress = true
        state.declineCeoTransferRequestInProgress = false
        state.acceptDeclineCeoTransferRequestError = null
      })
      .addCase(acceptCEOTransferRequest.fulfilled, state => {
        state.acceptDeclineCeoTransferRequestComplete = true
        state.acceptCeoTransferRequestInProgress = false
        state.declineCeoTransferRequestInProgress = false
        state.acceptDeclineCeoTransferRequestError = null
        state.ceoTransferRequestReceived = null
        state.ceoTransferRequestId = null
      })
      .addCase(acceptCEOTransferRequest.rejected, (state, { payload }) => {
        state.acceptDeclineCeoTransferRequestComplete = false
        state.acceptCeoTransferRequestInProgress = false
        state.declineCeoTransferRequestInProgress = false
        state.acceptDeclineCeoTransferRequestError = payload ?? null
      })
      .addCase(cancelCEOTransferRequest.pending, state => {
        state.ceoTransferRequestStatusInProgress = false
        state.ceoTransferRequestInProgress = false
        state.ceoTransferRequestComplete = false
        state.cancelCeoTransferRequestInProgress = true
        state.cancelCeoTransferRequest = null
        state.cancelCeoTransferRequestError = null
      })
      .addCase(cancelCEOTransferRequest.fulfilled, state => {
        state.cancelCeoTransferRequestInProgress = false
        ;(state.cancelCeoTransferRequest = true),
          (state.cancelCeoTransferRequestError = null)
      })
      .addCase(cancelCEOTransferRequest.rejected, (state, { payload }) => {
        state.cancelCeoTransferRequestInProgress = false
        state.cancelCeoTransferRequest = null
        state.cancelCeoTransferRequestError = payload ?? null
      })
      .addCase(getCEOTransferRequest.pending, (state, { meta }) => {
        ;(state.ceoTransferRequestId = meta.arg.requestId),
          (state.ceoTransferRequestStatusInProgress = true)
        state.ceoTransferRequestStatus = null
        state.ceoTransferRequestStatusError = null
      })
      .addCase(getCEOTransferRequest.fulfilled, (state, { payload }) => {
        state.ceoTransferRequestStatusInProgress = false
        ;(state.ceoTransferRequestStatus = payload.result.status),
          (state.ceoTransferRequestStatusError = null)
      })
      .addCase(getCEOTransferRequest.rejected, (state, { payload }) => {
        if (!state.ceoTransferRequestStatus) {
          state.ceoTransferRequestInProgress = false
          state.ceoTransferRequestComplete = false
          state.ceoTransferRequestStatusInProgress = false
          state.ceoTransferRequestStatus = null
          state.ceoTransferRequestStatusError = payload ?? null
        }
      })
  },
})

export const {
  clear,
  setCEOTransferRequestReceived,
  setCEOTransferRequestPending,
} = changeCeoSlice.actions

export default changeCeoSlice.reducer
