import { useMemo } from 'react'
import dot from 'dot-object'
import { useAppSelector } from '../store'

export const translate = (
  key: string,
  content: any,
  defaultValue?: string,
): string => dot.pick(key, content.content) || defaultValue

export const useTranslatedText = (
  key: string,
  defaultValue: string,
): string => {
  const content = useAppSelector(state => state.content)
  return useMemo(() => {
    return translate(key, content, defaultValue)
  }, [key, content, defaultValue])
}
