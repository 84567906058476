import classNames from 'classnames'
import React, { PropsWithChildren, forwardRef } from 'react'

interface Props {
  className?: string
}

const TableSection = forwardRef<
  HTMLTableSectionElement,
  PropsWithChildren<Props>
>(({ children, className }, ref) => {
  return (
    <tbody ref={ref} className={classNames('group', className)}>
      {children}
    </tbody>
  )
})

TableSection.displayName = 'TableSection'

export default TableSection
