import React, { useCallback, useEffect } from 'react'
import { useAppSelector } from '../../../store'
import TeamStrategy from './TeamStrategy/TeamStrategy'
import TeamWaiting from './TeamWaiting/TeamWaiting'
import TeamRegister from './TeamRegister/TeamRegister'
import BrandedPage from '../../organisms/Branding/BrandedPage/BrandedPage'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import BrandedHorizontalGroup from '../../organisms/Branding/BrandedHorizontalGroup/BrandedHorizontalGroup'
import Logo from '../../atoms/Logo/Logo'
import Clock from '../../organisms/Header/Clock/Clock'
import { selectBranding } from '../../organisms/Branding/selectors'
import { useNavigate } from 'react-router-dom'
import { usePrevious } from 'react-use'
import { extractQueryParams } from '../../../lib/extractQueryParams'
import Clickable from '../../atoms/Clickable/Clickable'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'

interface Props {}

const queryParams = extractQueryParams<{ skip: string }>()

const Team: React.FC<Props> = () => {
  const branding = useAppSelector(selectBranding)
  const navigate = useNavigate()
  const { registered, isCeo, isPreviewMode } = useAppSelector(state => ({
    registered: state.team.registered,
    isCeo: state.device.isCeo,
    isPreviewMode: state.auth.isPreviewMode,
  }))

  const previouslyRegistered = usePrevious(registered)

  useEffect(() => {
    if (previouslyRegistered === false && registered) {
      navigate('/summary')
    }
    if (isPreviewMode || queryParams.skip === 'true') {
      navigate('/summary')
    }
  }, [navigate, registered, previouslyRegistered, isPreviewMode])

  const handleLogoClick = useCallback(() => {
    navigate('/')
  }, [navigate])

  return (
    <VerticalGroup full fullWidth className="min-h-screen">
      <BrandedPage full noPadding className="flex h-full flex-1 flex-col">
        <BrandedHorizontalGroup center className="h-20 flex-shrink-0" fullWidth>
          <HorizontalGroup
            verticalCenter
            between
            center
            className="w-full px-4 laptop:container"
          >
            <HorizontalGroup className="tablet:w-1/2" gap={2} verticalCenter>
              <Clickable onClick={handleLogoClick}>
                <Logo
                  data-test="team-logo"
                  height={16}
                  textColour={branding.headingTextColour}
                />
              </Clickable>
            </HorizontalGroup>
            <Clock
              data-test="clock"
              textColour={branding.headingTextColour ?? 'white'}
            />
          </HorizontalGroup>
        </BrandedHorizontalGroup>
        <VerticalGroup full fullWidth className="flex-1">
          {registered ? (
            <TeamStrategy />
          ) : !isCeo ? (
            <TeamWaiting />
          ) : (
            <TeamRegister />
          )}
        </VerticalGroup>
      </BrandedPage>
    </VerticalGroup>
  )
}

export default Team
