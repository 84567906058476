import { camelCase, startCase } from '../../../../lib/text'
import { PeerInsightsMarketShareTeam } from '../../../../types/gameApi/peerInsights'
import { TextColour } from '../../../../types/theme'

export const types: Record<string, { value: string; change: string }> = {
  balances: {
    value: 'millions',
    change: 'percentage',
  },
  interestRate: {
    value: 'percentage',
    change: 'bps',
  },
  commission: {
    value: 'bps',
    change: 'bps',
  },
  npat: {
    value: 'millions',
    change: 'percentage',
  },
  annualFee: {
    value: 'dollars',
    change: 'dollars',
  },
  loyaltyPoints: {
    value: 'points',
    change: 'points',
  },
  customers: {
    value: 'thousands',
    change: 'percentage',
  },
  branches: {
    value: 'number',
    change: 'number',
  },
  marketShare: {
    value: 'percentage',
    change: 'bps',
  },
  fundsUnderManagement: {
    value: 'millions',
    change: 'percentage',
  },
  managementFees: {
    value: 'percentage',
    change: 'bps',
  },
}

export const formatUnitLabel = (type: string, millionsTranslation: string) => {
  switch (type) {
    case 'millions':
      return millionsTranslation
    case 'percentage':
      return '%'
    case 'thousands':
      return "(000's)"
    case 'bps':
      return 'BPS'
    case 'dollars':
      return '$'
    case 'number':
      return '#'
    case 'points':
      return 'Points'
  }
}

export const formatThousands = (n: number) => {
  const parts = n.toString().split('.')
  return (
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
    (parts[1] ? '.' + parts[1] : '')
  )
}

export const formatValue = (
  type: string,
  prop: 'change' | 'value',
  value: number,
) => {
  if (value === 0) return '-'
  if (type === 'npat' && prop === 'value') {
    return value < 0
      ? `(${formatThousands(Math.abs(value))})`
      : formatThousands(value)
  }
  switch (types[type][prop]) {
    case 'thousands':
      // @ts-expect-error it still works
      return formatThousands(parseInt(parseFloat(value / 1000).toFixed(0)))
    case 'millions':
      return formatThousands(value)
    case 'percentage':
      // @ts-expect-error it still works
      return Math.abs(parseFloat(value / 100).toFixed(2)) === 0
        ? '-'
        : // @ts-expect-error it still works
          parseFloat(value / 100).toFixed(2) + '%'
    case 'number':
      return formatThousands(
        // @ts-expect-error it still works
        value % 1 === 0 ? value : parseFloat(value).toFixed(2),
      )
    case 'dollars':
      // @ts-expect-error it still works
      return value % 1 === 0 ? value : parseFloat(value).toFixed(2)
    case 'points':
      // @ts-expect-error it still works
      return Math.abs(parseFloat(value / 100).toFixed(2)) === 0
        ? '-'
        : // @ts-expect-error it still works
          parseFloat(value / 100).toFixed(2)
    case 'bps':
      // @ts-expect-error it still works
      return Math.abs(parseFloat(value).toFixed(2)) === 0
        ? '-'
        : // @ts-expect-error it still works
          parseFloat(value).toFixed(0) + ' Bps'
  }
}

export const formatHeader = (val: string): string =>
  startCase(
    camelCase(val === 'commission' ? 'support costs' : val),
  ).toUpperCase()

export const calculateChange = (
  type: string,
  value: number,
  lastValue: number,
) => {
  switch (types[type].change) {
    case 'percentage':
      return (value / lastValue - 1) * 10000
    default:
      return value - lastValue
  }
}

export const getValue = (type: string, value: number) =>
  formatValue(type, 'value', value)
export const getChange = (type: string, value: number, lastValue: number) =>
  formatValue(type, 'change', calculateChange(type, value, lastValue))

export const getChangeColor = (
  type: string,
  value: number,
  lastValue: number,
): TextColour => {
  const change = calculateChange(type, value, lastValue)
  return parseFloat(change.toString()) < 0 ? 'error' : 'success'
}

export const displayChange = (change: string) => {
  if (change !== '-' && (parseFloat(change) > 999 || parseFloat(change) < -999))
    return 'Large'
  return change
}
export const getTypeUnitLabel = (type: string, millionsTranslation: string) =>
  formatUnitLabel(types[type].value, millionsTranslation)

export const getTotals = (
  type: string,
  data: PeerInsightsMarketShareTeam[],
) => {
  // @ts-expect-error types
  let value = data.reduce((acc, { values }) => acc + values[type], 0)
  if (
    ['annualFee', 'loyaltyPoints', 'managementFee'].includes(type) ||
    ['bps', 'percentage'].includes(types[type].value)
  ) {
    // do sumproduct
    value = data.reduce(
      (acc, { values }) =>
        // @ts-expect-error types
        acc + ((values.marketShare / 100) * values[type]) / 100,
      0,
    )
  }
  if (type === 'marketShare') {
    value = 10000
  }
  if (Object.prototype.hasOwnProperty.call(data[0], 'lastValues')) {
    const lastRound = data.reduce(
      // @ts-expect-error types
      (acc, { lastValues }) => acc + lastValues[type],
      0,
    )
    let change = value - lastRound
    if (
      ['annualFee', 'loyaltyPoints'].includes(type) ||
      ['percentage'].includes(types[type].change)
    ) {
      // do sumproduct using change
      change = data.reduce(
        (acc, { values, lastValues }) =>
          acc +
          // @ts-expect-error types
          ((values.marketShare / 100) *
            // @ts-expect-error types
            calculateChange(type, values[type], lastValues[type])) /
            100,
        0,
      )
    }
    if (types[type].change === 'bps') {
      change =
        data.reduce(
          (acc, { values }) =>
            // @ts-expect-error types
            acc + ((values.marketShare / 100) * values[type]) / 100,
          0,
        ) -
        data.reduce(
          (acc, { lastValues }) =>
            // @ts-expect-error types
            acc + ((lastValues.marketShare / 100) * lastValues[type]) / 100,
          0,
        )
    }
    if (['npat', 'balances'].includes(type)) {
      change = (value / lastRound - 1) * 10000
    }
    if (type === 'fundsUnderManagement') {
      change = ((value - lastRound) / lastRound) * 10000
    }
    if (type === 'marketShare') {
      // @ts-expect-error types
      change = null
    }
    return {
      value: formatValue(type, 'value', value),
      change: change ? formatValue(type, 'change', change) : '-',
    }
  } else {
    return {
      value: formatValue(type, 'value', value),
      change: '-',
    }
  }
}
