import React from 'react'
import { Icon } from '../../atoms/Icon/Icon'
import PrimaryText from '../../atoms/Text/PrimaryText'
import { FontSize, Spacing } from '../../../types/theme'

interface Props {
  value: number
  iconSize?: Spacing
  textSize?: FontSize
}

const ImpactValueChanger: React.FC<Props> = ({
  value,
  iconSize = 6,
  textSize = 'base',
}) => {
  if (value > 0) {
    return (
      <>
        {[...Array(value)].map((_, i) => (
          <Icon colour="success" key={i} type="positive" size={iconSize} />
        ))}
        {[...Array(5 - value)].map((_, i) => (
          <Icon
            colour="black"
            key={i + value}
            type="positive"
            className="opacity-20"
            size={iconSize}
          />
        ))}
      </>
    )
  } else if (value < 0) {
    return (
      <>
        {[...Array(-value)].map((_, i) => (
          <Icon colour="error" key={i} type="exclude" size={iconSize} />
        ))}
        {[...Array(5 + value)].map((_, i) => (
          <Icon
            colour="black"
            key={i - value}
            type="exclude"
            className="opacity-20"
            size={iconSize}
          />
        ))}
      </>
    )
  } else {
    return (
      <PrimaryText size={textSize} colour="gray" weight="bold">
        No Impact
      </PrimaryText>
    )
  }
}

export default ImpactValueChanger
