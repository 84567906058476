import classNames from 'classnames'
import { XMarkIcon } from '@heroicons/react/20/solid'
import React from 'react'

import LinkButton from '../LinkButton/LinkButton'
import VerticalGroup from '../VerticalGroup/VerticalGroup'
import HorizontalGroup from '../HorizontalGroup/HorizontalGroup'

type BannerType = 'info'

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  type: BannerType
  dismissText?: string
  onDismiss?: () => void
  className?: string
  active?: boolean
  onClose?: () => void
}

const COLOUR_CLASSES: Record<BannerType, { div: string; button: string }> = {
  info: {
    div: 'bg-primary-50 border-primary',
    button:
      'bg-primary-50 text-primary-500 hover:bg-primary-100 focus:ring-primary-600 focus:ring-offset-primary-50',
  },
}

const Banner: React.FC<Props> = ({
  className,
  children,
  type,
  dismissText,
  onDismiss,
  onClose,
  ...rest
}) => {
  return (
    <div
      className={classNames(
        className,
        'rounded-md border p-4',
        COLOUR_CLASSES[type].div,
      )}
      {...rest}
    >
      <VerticalGroup gap={2} className="tablet:flex-row tablet:items-center">
        {children}
        {onDismiss && (
          <HorizontalGroup gap={2} className="tablet:ml-auto tablet:pl-3">
            {dismissText && (
              <LinkButton
                onClick={e => {
                  e.preventDefault()
                  onDismiss()
                }}
                text={dismissText}
              />
            )}
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className={classNames(
                  'inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2',
                  COLOUR_CLASSES[type].button,
                )}
                onClick={onClose}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </HorizontalGroup>
        )}
      </VerticalGroup>
    </div>
  )
}
export default Banner
