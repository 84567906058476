import React, { PropsWithChildren, useEffect, useState } from 'react'
import { initialise } from './redux/common/commonActions'
import { useAppDispatch, useAppSelector } from './store'
import { extractQueryParams } from './lib/extractQueryParams'
import { userLogin } from './redux/auth/authActions'
import Page from './components/atoms/Page/Page'
import Logo from './components/atoms/Logo/Logo'
import { logout } from './redux/auth/authSlice'
import VerticalGroup from './components/atoms/VerticalGroup/VerticalGroup'
import InlineError from './components/atoms/InlineError/InlineError'

interface Props {}

interface QueryParams {
  participantId?: string
  eventId?: string
  email?: string
  isTablet?: string
  skip?: string
}

const queryParams = extractQueryParams<QueryParams>()

// if (params.participantId) {
//   this.props.login({
//     email: params.email,
//     password: params.participantId,
//     environment: params.environment,
//     eventId: params.eventId,
//     isTablet: params.isTablet === 'true'
//   })
// }

const Initialiser: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  const [isInit, setIsInit] = useState(false)
  const { auth, device } = useAppSelector(state => ({
    auth: state.auth,
    device: state.device,
  }))
  const dispatch = useAppDispatch()

  // check if there is a token on start up and try to resign in if there is
  useEffect(() => {
    if (isInit && auth.token && !device.registered) {
      setIsInit(false)
      return
    }
    if (isInit) {
      return
    }
    const hasQueryParams =
      queryParams.participantId && queryParams.email && queryParams.eventId
    const isPreviewMode = queryParams.skip === 'true'
    if (
      window.location.pathname === '/' &&
      hasQueryParams &&
      !auth.token &&
      !auth.isLoading &&
      !auth.error
    ) {
      dispatch(
        userLogin({
          email: queryParams.email ?? '',
          password: queryParams.participantId ?? '',
          eventId: queryParams.eventId ?? '',
          isTablet: queryParams.isTablet === 'true',
          isPreviewMode,
        }),
      )
      return
    }

    if (
      window.location.pathname === '/' &&
      hasQueryParams &&
      auth.token &&
      (auth.participantId != queryParams.participantId ||
        auth.eventId != queryParams.eventId ||
        auth.email != queryParams.email ||
        auth.isPreviewMode !== isPreviewMode) &&
      !auth.isLoading
    ) {
      dispatch(logout())
      dispatch(
        userLogin({
          email: queryParams.email ?? '',
          password: queryParams.participantId ?? '',
          eventId: queryParams.eventId ?? '',
          isTablet: queryParams.isTablet === 'true',
          isPreviewMode,
        }),
      )
      return
    }

    if (!auth.token && !auth.isLoading) {
      setIsInit(true)
      return
    }
    if (
      auth.token &&
      auth.participantId &&
      auth.eventId &&
      !device.registered
    ) {
      dispatch(
        initialise({
          participantId: auth.participantId,
          eventId: auth.eventId,
          isTablet: queryParams.isTablet === 'true',
        }),
      )
      return
    }
    if (auth.isLoggedIn && device.registered) {
      setIsInit(true)
      return
    }
  }, [
    auth.email,
    auth.eventId,
    auth.isLoading,
    auth.isLoggedIn,
    auth.isPreviewMode,
    auth.participantId,
    auth.token,
    auth.error,
    device.registered,
    dispatch,
    isInit,
  ])

  if (!isInit) {
    return (
      <Page className="h-screen bg-[#112540]">
        <div className="flex h-full w-full items-center justify-center">
          <VerticalGroup gap={2}>
            {auth.error && <InlineError message={auth.error.message} />}
            <Logo spinning hideText height={24} />
          </VerticalGroup>
        </div>
      </Page>
    )
  }
  return <>{children}</>
}

export default Initialiser
