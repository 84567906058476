import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import { colours } from '../../../../../constants/colours'
import Button from '../../../../atoms/Button/Button'
import DropDown from '../../../../atoms/DropDown/DropDown'
import HideIfMobileLandscape from '../../../../atoms/HideIfMobile/HideIfMobileLandscape'
import ShowIfMobileLandscape from '../../../../atoms/HideIfMobile/ShowIfMobileLandscape'
import HorizontalGroup from '../../../../atoms/HorizontalGroup/HorizontalGroup'
import PrimaryText from '../../../../atoms/Text/PrimaryText'
import VerticalGroup from '../../../../atoms/VerticalGroup/VerticalGroup'
import LineChart, {
  LineData,
  Props as LineChartProps,
} from '../../../../organisms/Charts/LineChart/LineChart'
import { LineChartData } from '../../../../organisms/Charts/LineChart/types'
import ChartContainer from './ChartContainer'

// If there are multiple lines with the same value and the active team is part of that group we have to render that line last
// this is so it will have a higher zindex and will show on top
const sortActiveItem = (
  selectedTeam: string,
  array: LineData[],
  data: LineChartData[],
): LineData[] => {
  const activeItem = array.find(item => item.key === selectedTeam) // find the active item
  if (!activeItem) return array // return the array as is if there's no active item
  const index = array.findIndex(item => item === activeItem) // get the index of the active item
  const activeValue = data[data.length - 1][activeItem.key] ?? 0
  let count = 0
  // count the number of items with the same value as the active item
  for (const value of Object.values(data[data.length - 1])) {
    if (value === activeValue) {
      count++
    }
  }
  // remove the active item from the array
  array.splice(index, 1)
  // insert the active item after the items with the same value and before the next item
  for (let i = index; i < index + count + 1; i++) {
    if (
      array[i] == null ||
      (data[data.length - 1][array[i].key] ?? 0) < activeValue
    ) {
      array.splice(i, 0, activeItem)
      break
    } else if (i === index + count) {
      array.splice(i + 1, 0, activeItem)
      break
    }
  }
  return array
}

interface Props extends LineChartProps {
  header?: ReactNode
  teamId: number
  secondaryNav?: ReactNode
  heightOffset?: number
}

const LineChartWithTeamSelector: React.FC<Props> = ({
  header,
  teamId,
  lines,
  secondaryNav,
  heightOffset,
  ...rest
}) => {
  const [selectedTeam, setSelectedTeam] = useState<string>(
    lines.find(l => l.key === `team${teamId}`)?.key ?? lines[0].key,
  )

  const getLabelId = useCallback(
    (index: number): string | null => {
      if (index === rest.data.length - 1) {
        return 'tour-metric-value-last'
      }
      return null
    },
    [rest.data.length],
  )

  const linesWithColour = useMemo(() => {
    return sortActiveItem(selectedTeam, [...lines], rest.data).map(l => ({
      ...l,
      colour: l.key === selectedTeam ? colours.orange : colours.primary,
      label: l.key === selectedTeam,
      getLabelId: l.key === selectedTeam ? getLabelId : null,
    }))
  }, [selectedTeam, lines, rest.data, getLabelId])

  const linesAsMenuItems = useMemo(() => {
    return lines.map(l => ({
      label: l.name,
      value: l.key,
    }))
  }, [lines])

  return (
    <VerticalGroup verticalStart gap={2}>
      <ShowIfMobileLandscape>
        <HorizontalGroup between fullWidth className="px-20">
          {header}
          <DropDown<string>
            items={linesAsMenuItems}
            selectedItem={selectedTeam}
            onSelectedItemChange={item => setSelectedTeam(item.value)}
          />
        </HorizontalGroup>
      </ShowIfMobileLandscape>
      <VerticalGroup gap={2}>
        {secondaryNav}
        <ChartContainer heightOffset={heightOffset}>
          <LineChart {...rest} lines={linesWithColour} />
        </ChartContainer>
        <HideIfMobileLandscape>
          <HorizontalGroup
            gap={2}
            className="hidden h-16 tablet:overflow-x-auto laptop:flex"
          >
            {lines.map((l, index) => (
              <Button
                id={index === 0 ? 'tour-first-team' : ''}
                buttonType="secondary"
                rounded="full"
                key={l.key}
                onClick={() => setSelectedTeam(l.key)}
                className={
                  selectedTeam === l.key
                    ? 'border-none bg-orange text-white'
                    : ''
                }
              >
                <PrimaryText
                  transform="upper"
                  padding={4}
                  className={
                    selectedTeam === l.key ? 'text-white' : 'text-black'
                  }
                  whitespace="nowrap"
                >
                  {l.name}
                </PrimaryText>
              </Button>
            ))}
          </HorizontalGroup>
          <div className="flex justify-center laptop:hidden">
            <DropDown<string>
              rounded
              bgColor="white"
              openUpwards
              items={linesAsMenuItems}
              selectedItem={selectedTeam}
              onSelectedItemChange={item => setSelectedTeam(item.value)}
            />
          </div>
        </HideIfMobileLandscape>
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default LineChartWithTeamSelector
