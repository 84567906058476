import React, { useCallback, useState } from 'react'
import { Icon } from '../../../atoms/Icon/Icon'
import Clickable from '../../../atoms/Clickable/Clickable'
import Text from '../../../atoms/Text/Text'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import { useRoutes } from '../../../../hooks/useRoutes'
import { startTour } from '../../../../redux/guidedTours/guidedToursSlice'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { useNavigate } from 'react-router-dom'
import Button from '../../../atoms/Button/Button'
import MinimalModal from '../../../molecules/MinimalModal/MinimalModal'
import ImageGallery from 'react-image-gallery'

interface Props {
  setSelectedItem: (item: string) => void
}

const HelpContentList: React.FC<Props> = ({ setSelectedItem }) => {
  const { routes, allRoutes } = useRoutes()
  const [showHelpManual, setShowHelpManual] = useState(false)
  const informationNav = routes.filter(r => r.type === 'information')
  const decisionNav = routes.filter(r => r.type === 'decision')
  const helpRoute = allRoutes.find(r => r.id === 'help-manual')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { currentRound, files } = useAppSelector(state => ({
    currentRound: state.game.currentRound,
    files: state.help.files,
  }))

  const handleItemClick = useCallback(
    (item: string) => {
      setSelectedItem(item)
    },
    [setSelectedItem],
  )
  const handleRoundOneInitialTour = useCallback(() => {
    dispatch(startTour('round-one-initial'))
    navigate('/summary')
  }, [dispatch, navigate])

  const handleRoundTwoInitialTour = useCallback(() => {
    dispatch(startTour('round-two-initial'))
    navigate('/summary')
  }, [dispatch, navigate])

  const openHelpManual = useCallback(() => {
    setShowHelpManual(true)
  }, [])

  const closeHelpManual = useCallback(() => {
    setShowHelpManual(false)
  }, [])

  const images = files.map(image => ({
    original: image,
    thumbnail: image,
  }))

  return (
    <VerticalGroup gap={2}>
      {showHelpManual && (
        <MinimalModal isOpen={showHelpManual} onClose={closeHelpManual}>
          <ImageGallery
            showPlayButton={false}
            items={images ?? []}
            showThumbnails
            showNav
          />
        </MinimalModal>
      )}
      <Text center size="lg">
        Select the area you need help with below.
      </Text>
      {currentRound === 1 ? (
        <Button
          buttonType="secondary"
          full
          size="xl"
          onClick={handleRoundOneInitialTour}
        >
          <Text colour="primary">Start Round 1 Walkthrough</Text>
          <Icon type="guide" colour="primary" responsiveSize="xs" size={4} />
        </Button>
      ) : (
        <Button
          buttonType="secondary"
          full
          size="xl"
          onClick={handleRoundTwoInitialTour}
        >
          <Icon type="guide" responsiveSize="xs" size={4} colour="primary" />
          <Text colour="primary">Start Round 2 Walkthrough</Text>
        </Button>
      )}

      <Text weight="semibold" size="lg">
        Information
      </Text>
      <div className="grid grid-cols-3 gap-4 p-2">
        {informationNav.map(item => (
          <VerticalGroup
            key={item.name}
            center
            verticalCenter
            className="h-14 monitor:h-16"
          >
            <Clickable onClick={() => handleItemClick(item.href)}>
              <VerticalGroup center>
                <Icon
                  type={item.iconType}
                  colour="primary"
                  aria-hidden="true"
                  size={10}
                />
                <Text size="sm" weight="light" colour="primary">
                  {item.name}
                </Text>
              </VerticalGroup>
            </Clickable>
          </VerticalGroup>
        ))}
      </div>
      <Text weight="semibold" size="lg">
        Decisions
      </Text>
      <div className="grid grid-cols-3 gap-4 p-2">
        {decisionNav.map(item => (
          <VerticalGroup
            key={item.name}
            center
            verticalCenter
            className="h-14 monitor:h-16"
          >
            <Clickable onClick={() => handleItemClick(item.href)}>
              <VerticalGroup center>
                <Icon
                  type={item.iconType}
                  colour="primary"
                  aria-hidden="true"
                  size={10}
                />
                <Text size="sm" weight="light" colour="primary">
                  {item.name}
                </Text>
              </VerticalGroup>
            </Clickable>
          </VerticalGroup>
        ))}
      </div>
      <div className="hidden tablet:inline">
        <Text weight="semibold" size="lg">
          Help Manual
        </Text>
        <div className="grid grid-cols-3 gap-4 p-2">
          {helpRoute && (
            <VerticalGroup
              key={helpRoute.name}
              center
              verticalCenter
              className="h-14 monitor:h-16"
            >
              <Clickable onClick={openHelpManual}>
                <VerticalGroup center>
                  <Icon
                    type={helpRoute.iconType}
                    colour="primary"
                    aria-hidden="true"
                    size={10}
                  />
                  <Text size="sm" weight="light" colour="primary">
                    {helpRoute.name}
                  </Text>
                </VerticalGroup>
              </Clickable>
            </VerticalGroup>
          )}
        </div>
      </div>
    </VerticalGroup>
  )
}

export default HelpContentList
