import { useEffect } from 'react'

interface Props {
  keymap: Record<string, () => void>
}
export const useKeypress = ({ keymap }: Props) => {
  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (keymap[event.key]) {
        keymap[event.key]()
      }
    }
    window.addEventListener('keydown', handleKeyPress)
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [keymap])
}
