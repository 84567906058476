import React from 'react'
import Modal from './Modal'

interface Props {
  showModal: boolean
  heading: string
  onClose: () => void
  message: string
}

const ErrorModal: React.FC<Props> = ({
  showModal,
  heading,
  onClose,
  message,
}) => {
  return (
    <Modal
      type="error"
      show={showModal}
      title={heading}
      onClose={onClose}
      onOk={onClose}
    >
      <div className="pt-4">
        <p>{message}</p>
      </div>
    </Modal>
  )
}

export default ErrorModal
