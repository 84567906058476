import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'
import { Spacing, TableBgColour } from '../../../types/theme'
import {
  BACKGROUND_COLOUR_LIGHT_SHADE_CLASSES,
  BACKGROUND_COLOUR_CLASSES,
} from '../../../lib/colourClasses'

interface Props extends React.ThHTMLAttributes<HTMLTableCellElement> {
  className?: string
  colour?: TableBgColour
  cellSelect?: boolean
  noPadding?: boolean
  noRightBorder?: boolean
  paddingY?: Spacing
}

const TableCell: React.FC<PropsWithChildren<Props>> = ({
  children,
  className,
  colour,
  cellSelect,
  align,
  noRightBorder = false,
  paddingY = 2, // Defaults to '2' if not passed

  ...rest
}) => {
  const colourClass = colour
    ? BACKGROUND_COLOUR_LIGHT_SHADE_CLASSES[colour]
    : ''
  const primarySelectClass = cellSelect ? BACKGROUND_COLOUR_CLASSES.primary : ''

  return (
    <td
      className={classNames(
        className,
        colourClass,
        primarySelectClass,
        `py-${paddingY}`, // Use paddingY here
        {
          'cursor-pointer': rest.onClick,
          'px-3': true, // Apply horizontal padding
          'border-r border-gray-300': !noRightBorder,
        },
      )}
      {...rest}
    >
      <div
        className={classNames('flex', {
          'justify-left': align === 'left',
          'justify-center': align === 'center',
          'justify-right': align === 'right',
        })}
      >
        {children}
      </div>
    </td>
  )
}

export default TableCell
