import React from 'react'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import { DealroomResponse } from '../../../../types/gameApi/dealroom'
import Image from '../../../atoms/Image/Image'
import PrimaryText from '../../../atoms/Text/PrimaryText'
import DealRoomBidInput from './DealRoomBidInput'
import DealRoomMetric from './DealRoomMetric'
import Page from '../../../atoms/Page/Page'
import Card from '../../../atoms/Card/Card'
import HtmlText from '../../../atoms/Text/HtmlText'

interface Props {
  dealroom: DealroomResponse
  estimatedReturn: number | null
  bid: number | null
  bidEnabled: boolean
  canBid: boolean
  bidError: string | null
  bidInProgress: boolean
  clearBidError: () => void
  onBidChange: (value: number, enabled: boolean) => void
  hideReturn?: boolean
}

const DealRoomBidding: React.FC<Props> = ({
  dealroom,
  estimatedReturn,
  onBidChange,
  bid,
  bidError,
  bidInProgress,
  clearBidError,
  bidEnabled,
  hideReturn = false,
  canBid,
}) => {
  return (
    <VerticalGroup gap={4}>
      <Image
        objectFit="cover"
        className="w-full laptop:h-56 laptop:object-top monitor:h-96"
        src={dealroom.imageUrl}
      />
      <Page>
        <VerticalGroup gap={4}>
          <PrimaryText size="xl" weight="bold">
            {dealroom.name}
          </PrimaryText>
          <VerticalGroup gap={4} className="laptop:flex-row laptop:gap-4">
            <div id="tour-deal-info" className="laptop:w-9/12">
              <HtmlText>{dealroom.description}</HtmlText>
            </div>

            <VerticalGroup
              className="tablet:w-full tablet:flex-row tablet:gap-2 laptop:w-2/5 laptop:flex-col"
              gap={4}
            >
              <Card className="p-4 tablet:w-1/5 laptop:w-full">
                <DealRoomBidInput
                  onValueChange={onBidChange}
                  value={bid ?? undefined}
                  enabled={bidEnabled}
                  canBid={canBid}
                  bidError={bidError}
                  bidInProgress={bidInProgress}
                  clearBidError={clearBidError}
                />
              </Card>
              <Card className="p-4 tablet:flex tablet:w-2/5 tablet:items-center laptop:w-full">
                <DealRoomMetric
                  heading="Current Shareholder Return"
                  icon="loan"
                  percentage={dealroom.tsr}
                />
              </Card>

              {!hideReturn && (
                <Card className="p-4 tablet:flex tablet:w-2/5 tablet:items-center laptop:w-full">
                  <DealRoomMetric
                    heading="Estimated Deal Return"
                    icon="deal"
                    percentage={
                      estimatedReturn != null
                        ? (estimatedReturn * 100).toFixed(1)
                        : null
                    }
                  />
                </Card>
              )}
            </VerticalGroup>
          </VerticalGroup>
        </VerticalGroup>
      </Page>
    </VerticalGroup>
  )
}

export default DealRoomBidding
