import sCase from 'lodash.startcase'
import cCase from 'lodash.camelcase'

export const titleCase = (val: string) => {
  return val
    .toLowerCase()
    .split(' ')
    .map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

export const camelCase = (val: string) => cCase(val)

export const startCase = (val: string) => sCase(val)
