import React from 'react'
import Card from '../../../atoms/Card/Card'
import { percentage } from '../../../../lib/formatters'
import { TreasuryResponse } from '../../../../types/gameApi/treasury'
import { DecisionMap } from '../TreasuryContainer'
import { CapitalManagmentData } from './data'
import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'
import ValueChangerWithInput from '../../../molecules/ValueChanger/ValueChangerWithInput'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'

import MetricCardIconBox from '../../../organisms/MetricCard/MetricCardIconBox'
import { useTranslatedText } from '../../../../hooks/useTranslatedText'
import TargetCapitalRatioModal from './TargetCapitalRatioModal'

interface TargetCapitalRatioProps {
  data: TreasuryResponse
  decisions: DecisionMap
  onChangeValue: (val: Partial<DecisionMap>) => void
  enableChanges: boolean
  capitalManagmentData: CapitalManagmentData
}

const TargetCapitalRatio: React.FC<TargetCapitalRatioProps> = ({
  capitalManagmentData,
  decisions,
  enableChanges,
  onChangeValue,
  data,
}) => {
  const { capitalSliderMin, capitalSliderMax } = capitalManagmentData

  const capitalRatioTitle = useTranslatedText(
    'treasury.screen1.slider4Label',
    'Target Capital Ratio',
  )
  const onCapitalRatioChange = (val: number) => {
    onChangeValue({
      capitalRatioChange: val,
    })
  }
  return (
    <Card className="px-6 py-3">
      <HorizontalGroup className="h-12" fullWidth between verticalCenter>
        <HorizontalGroup gap={2} verticalCenter>
          <MetricCardIconBox
            size="small"
            iconType={'capital'}
            iconColour={'white'}
            iconSize={10}
            backgroundColour={'orange'}
          />

          <div className="">
            <TranslatedText
              size="base"
              weight="bold"
              center
              value="treasury.screen4.table2Row9"
              default="Target Capital Ratio"
            />
          </div>
        </HorizontalGroup>
        <div>
          <ValueChangerWithInput
            inputSize="small"
            {...{
              modalProps: {
                heading: capitalRatioTitle,
                extraData: {
                  capitalManagmentData,
                  decisions,
                  data,
                },
              },
              type: 'percentage',
              value: decisions.capitalRatioChange,
              step: 10,
              formatValue: percentage,
              onChange: onCapitalRatioChange,
              min: capitalSliderMin,
              max: capitalSliderMax,
              enableChanges,
              increments: [1, 2.5],
              CustomModal: TargetCapitalRatioModal,
              buttonIdPrefix: 'tour-capital-ratio',
            }}
          />
        </div>
      </HorizontalGroup>
    </Card>
  )
}

export default TargetCapitalRatio
