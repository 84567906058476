import React, { PropsWithChildren, useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { Icon } from '../../atoms/Icon/Icon'
import { Link } from 'react-router-dom'
import PrimaryText from '../../atoms/Text/PrimaryText'
import { RouteWithMeta, useRoutes } from '../../../hooks/useRoutes'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import NewFeatureAlert from './NewFeatureAlert'
import { useAppDispatch, useAppSelector } from '../../../store'
import { finishInAppHelp } from '../../../redux/inAppHelp/inAppHelpSlice'

interface Props {}

const Heading: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <PrimaryText
      colour="white"
      size="lg"
      weight="light"
      paddingLeft={0}
      paddingY={1}
      notResponsive
    >
      {children}
    </PrimaryText>
  )
}

const Item: React.FC<{ item: RouteWithMeta }> = ({ item }) => {
  const { gameId } = useAppSelector(state => ({
    gameId: state.game.gameId,
  }))
  const dispatch = useAppDispatch()

  const handleClick = useCallback(() => {
    if (item.feature) {
      const storageKey = `${gameId}:${item.feature}`
      dispatch(finishInAppHelp(storageKey))
    }
  }, [dispatch, gameId, item.feature])

  const isNewFeature = item.feature && (
    <NewFeatureAlert feature={item.feature} />
  )

  return (
    <>
      <Link
        key={item.name}
        to={item.href}
        onClick={handleClick}
        className={classNames(
          item.current ? 'bg-black' : 'hover:bg-black',
          'group flex flex-grow items-center rounded-lg px-2',
        )}
        id={item.id}
      >
        <Icon
          type={item.iconType}
          colour={item.current ? 'primary' : undefined}
          className={classNames('mr-3 h-5 w-4 flex-shrink-0', {
            'fill-gray-400 stroke-gray-400': !item.current,
          })}
          aria-hidden="true"
        />
        <HorizontalGroup verticalCenter gap={4}>
          <PrimaryText
            colour={item.current ? 'primary' : 'white'}
            weight={item.current ? 'bold' : 'light'}
            notResponsive
          >
            {item.name}
          </PrimaryText>
          {isNewFeature}
        </HorizontalGroup>
      </Link>
    </>
  )
}

const SidebarContent: React.FC<Props> = () => {
  const { routes } = useRoutes()
  const informationNav = useMemo(
    () => routes.filter(r => r.type === 'information'),
    [routes],
  )
  const decisionNav = useMemo(
    () => routes.filter(r => r.type === 'decision'),
    [routes],
  )

  return (
    <>
      <Heading>INFORMATION</Heading>
      {informationNav.map(item => (
        <Item key={item.name} item={item} />
      ))}

      <Heading>DECISIONS</Heading>
      {decisionNav.map(item => (
        <Item key={item.name} item={item} />
      ))}
    </>
  )
}

export default SidebarContent
