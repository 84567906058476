import React, { PropsWithChildren } from 'react'
import { Navigate } from 'react-router-dom'
import { useAppSelector } from './store'

interface Props {}

// @ts-expect-error Return type is strange
export const ProtectedRoute: React.FC<PropsWithChildren<Props>> = ({
  children,
}) => {
  const data = useAppSelector(state => state.auth)
  if (!data.isLoggedIn) {
    // user is not authenticated
    return <Navigate to="/login" />
  }
  return children ?? null
}
