import { TextColour } from '../types/theme'
import { roundTo } from 'round-to'

const DEFAULT_DP = 2
export const round = (val: number, precision?: number): number => {
  return roundTo(val, precision ?? DEFAULT_DP)
}

export const roundToFixed = (val: number, precision?: number): string => {
  return round(val, precision).toFixed(precision ?? DEFAULT_DP)
}

export const reportingPercentage = (
  val: number,
  dp?: number,
  hidePercentage?: boolean,
) => {
  return `${roundToFixed(val, dp ?? 2)}${hidePercentage ? '' : '%'}`
}

export const thousandSeparator = (value: any) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const percentage = (
  value: number,
  dp?: number,
  hidePercentage?: boolean,
) => {
  return `${(value / 100).toFixed(dp ?? 2)}${hidePercentage ? '' : '%'}`
}

export const formatPercentage = (val: number) => {
  if (val === 0) {
    return '-'
  }
  return percentage(val)
}

interface FormatValueOpts {
  addPlusSign?: boolean
}

export const formatValue = (val: number, opts?: FormatValueOpts) => {
  if (val === 0) {
    return '-'
  }
  if (val < 0) {
    return `(${thousandSeparator(val * -1)})`
  }
  return `${opts?.addPlusSign ? '+' : ''}${thousandSeparator(val)}`
}

export const formatValueChange = ({
  valueChange,
  changeUnit,
  dashIfZero,
  dp = 2,
  prefix,
}: {
  valueChange: number | null
  changeUnit?: string
  dashIfZero?: boolean
  dp?: number
  prefix?: string
}) => {
  if (valueChange == null || Number(valueChange) === 0) {
    return dashIfZero ? '-' : 'No Change'
  }

  const prefixToUse = (valueChange > 0 ? '+' : '') + (prefix ?? '')
  switch (changeUnit) {
    case '%': {
      const percentageValue = percentage(valueChange, dp, false)
      if (Number(percentageValue.replace('%', '')) === 0) {
        return dashIfZero ? '-' : 'No Change'
      }
      return `${prefixToUse}${percentageValue}`
    }
    case 'bps':
      return `${prefixToUse}${valueChange}bps`
    default: {
      let returnVal = `${prefixToUse}${valueChange}${changeUnit || ''}`
      returnVal = returnVal.replace(`${prefix}-`, `-${prefix}`)
      return returnVal
    }
  }
}

export const getValueChangeColour = ({
  value,
  isCost,
}: {
  value: number | null
  isCost?: boolean
}): TextColour => {
  if (value == null || Number(value) === 0) {
    return 'black'
  }
  if (value > 0) {
    return isCost ? 'error' : 'success'
  }
  return isCost ? 'success' : 'error'
}

// export const FormatParticipantName = ({ participant }: Props) => {
//   let name = participant.firstName
//   if (participant.lastName) {
//     name += ` ${participant.lastName}`
//   }
//   return name
// }
