import { EconomicScenarioNewsResponse } from '../../../../../types/gameApi/news'

export const getNewsData = (): EconomicScenarioNewsResponse => {
  return {
    newsConfig: {
      mainSectionHeading: 'THE ECONOMY',
      mainSectionTitle: 'Economy Update',
      mainSectionSecondaryTitle: 'Higher Wisdom',
      marketViewSectionHeading: 'BULL VS. BEAR',
      marketView1Logo: 'bull',
      marketView1Heading: 'Bull',
      marketView2Logo: 'bear',
      marketView2Heading: 'Bear',
    },
    rounds: [
      {
        roundId: 1,
        mainSectionText:
          'The economy is performing well. Credit growth is strong in the banking sector, particularly in mortgages and business lending. Economic indicators show no sign of a slowdown. Investors appear optimistic about the future and are investing heavily in long-term debt instruments and equities meaning lower costs of wholesale funding for banks. The local share market has increased 12% in the past year, which is supporting business confidence. Unemployment levels are low and bank loan books show no signs of distress. Customers are managing to make repayments comfortably at the moment. Inflation is within the RBA target band. ',
        mainSectionSecondaryText:
          '"Someone’s sitting in the shade today because someone planted a tree a long time ago."',
        mainSectionSecondaryTextAuthor: 'Warren Buffett',
        marketView1Text:
          'There is no cause for concern, the system is flush with liquidity and the macro fundamentals are too good for impending worries. Anyone with a pessimistic view of the world holding their money in cash may not lose anything, but will certainly miss out on significant returns this year.',
        marketView1ResultsText:
          'There is no cause for concern, the system is flush with liquidity and the macro fundamentals are too good to mean impending worries.',
        marketView2Text:
          'We are in dangerous territory - a situation of cheap funding, high growth, and high asset appreciation spurred on by overly optimistic investors. This cannot go on forever.\\nIf rates rise and liquidity dries up, recent gains could quickly evaporate for those who don’t have the foresight to exit.',
        marketView2ResultsText:
          'We are in dangerous territory - a situation of low interest rates, high growth, significant asset value appreciation spurred on by overly optimistic investors.',
        resultsMainHeading: 'Full Steam Ahead!',
        resultsMainText:
          'The economy is performing well. Growth is strong in the Banking sector. Low unemployment - no signs of distress. Inflation is within the Central Bank target band.',
        marketOutlook: [
          {
            productType: 'HOME_LOANS',
            growth: {
              setFrom: 900,
              setTo: 1100,
            },
            losses: {
              setFrom: 1,
              setTo: 1,
            },
          },
          {
            productType: 'BUSINESS_LOANS',
            growth: {
              setFrom: 500,
              setTo: 700,
            },
            losses: {
              setFrom: 1,
              setTo: 1,
            },
          },
          {
            productType: 'DEPOSITS',
            growth: {
              setFrom: 300,
              setTo: 500,
            },
            losses: null,
          },
          {
            productType: 'CREDIT_CARDS',
            growth: {
              setFrom: -500,
              setTo: -300,
            },
            losses: {
              setFrom: 1,
              setTo: 1,
            },
          },
          {
            productType: 'WEALTH_MANAGEMENT',
            growth: {
              setFrom: 700,
              setTo: 900,
            },
            losses: null,
          },
          {
            productType: 'INSTITUTIONAL_BANKING',
            growth: {
              setFrom: 500,
              setTo: 700,
            },
            losses: {
              setFrom: 1,
              setTo: 1,
            },
          },
          {
            productType: 'OFFSHORE_BANK',
            growth: {
              setFrom: 680,
              setTo: 880,
            },
            losses: {
              setFrom: 1,
              setTo: 1,
            },
          },
        ],
        wholesaleFundingCosts: [
          {
            term: 1,
            AAA: 11.95,
            AA: 23.0875,
            A: 36.959375,
            BBB: 54.24921875,
            BB: 75.8115234375,
            B: 102.714404296875,
          },
          {
            term: 2,
            AAA: 30.02,
            AA: 41.677499999999995,
            A: 56.069375,
            BBB: 73.87921875,
            BB: 95.9615234375,
            B: 123.384404296875,
          },
          {
            term: 3,
            AAA: 48.089999999999996,
            AA: 60.2675,
            A: 75.179375,
            BBB: 93.50921875,
            BB: 116.11152343750001,
            B: 144.054404296875,
          },
          {
            term: 4,
            AAA: 66.16,
            AA: 78.85749999999999,
            A: 94.28937499999999,
            BBB: 113.13921875,
            BB: 136.2615234375,
            B: 164.724404296875,
          },
          {
            term: 5,
            AAA: 84.22999999999999,
            AA: 97.44749999999999,
            A: 113.39937499999999,
            BBB: 132.76921875,
            BB: 156.41152343750002,
            B: 185.394404296875,
          },
        ],
        referenceRate: {
          rateValue: 250,
          rateChange: 0,
        },
      },
      {
        roundId: 2,
        mainSectionText:
          'The market is going from strength to strength, and the mortgage juggernaut is expected to continue this year. Significant growth is also expected in home and business lending, meaning those banks augmenting pricing strategies with other levers should see market share gains. Wholesale funding spreads have increased as debt investors become cautious about the market outlook and banks’ over exposure to mortgages. The RBA has increased the official cash rate 25 basis points this year which many saw as a pre-emptive strike to curtail the threat of rising inflation. Unemployment levels are starting to rise.',
        mainSectionSecondaryText:
          '"Success is a lousy teacher. It seduces smart people into thinking they can’t lose."',
        mainSectionSecondaryTextAuthor: 'Bill Gates',
        marketView1Text:
          'We have entered a new normal where central bankers are astute enough to effectively manage any traditional economic thoughts on boom / bust cycles. This was seen in the magnificent handling of the Gloabl Financial Crisis, which in many years from now will be studied in economic schools as a piece of policy genius.',
        marketView1ResultsText:
          'We have entered a new normal where central bankers are astute enough to effectively manage any traditional economic thoughts on boom / bust cycles.',
        marketView2Text:
          'What goes up, must come down. Housing prices are over valued and unsustainable so we should expect a significant correction very soon. Those Banks with portfolios over weighted towards mortgages and risky asset classes should either re-weight or prepare for what’s coming.',
        marketView2ResultsText:
          'What goes up, must come down. Housing prices are over valued and unsustainable so we should expect a significant correction very soon.',
        resultsMainHeading: 'Steady As She Goes',
        resultsMainText:
          'Market growth is strong. Debt investors have concerns. Central Bank addressing inflation concerns. Unemployment levels are starting to rise.',
        marketOutlook: [
          {
            productType: 'HOME_LOANS',
            growth: {
              setFrom: 1100,
              setTo: 1300,
            },
            losses: {
              setFrom: 1,
              setTo: 1,
            },
          },
          {
            productType: 'BUSINESS_LOANS',
            growth: {
              setFrom: 700,
              setTo: 900,
            },
            losses: {
              setFrom: 1,
              setTo: 2,
            },
          },
          {
            productType: 'DEPOSITS',
            growth: {
              setFrom: 400,
              setTo: 600,
            },
            losses: null,
          },
          {
            productType: 'CREDIT_CARDS',
            growth: {
              setFrom: -700,
              setTo: -500,
            },
            losses: {
              setFrom: 1,
              setTo: 1,
            },
          },
          {
            productType: 'WEALTH_MANAGEMENT',
            growth: {
              setFrom: 900,
              setTo: 1100,
            },
            losses: null,
          },
          {
            productType: 'INSTITUTIONAL_BANKING',
            growth: {
              setFrom: 700,
              setTo: 900,
            },
            losses: {
              setFrom: 1,
              setTo: 1,
            },
          },
          {
            productType: 'OFFSHORE_BANK',
            growth: {
              setFrom: 898,
              setTo: 1098,
            },
            losses: {
              setFrom: 1,
              setTo: 1,
            },
          },
        ],
        wholesaleFundingCosts: [
          {
            term: 1,
            AAA: 17.95,
            AA: 26.3296,
            A: 38.226428,
            BBB: 55.15289203999999,
            BB: 79.2717356172,
            B: 113.67568193259599,
          },
          {
            term: 2,
            AAA: 38.8,
            AA: 47.7796,
            A: 60.276427999999996,
            BBB: 77.80289203999999,
            BB: 102.5217356172,
            B: 137.525681932596,
          },
          {
            term: 3,
            AAA: 59.65,
            AA: 69.2296,
            A: 82.32642799999999,
            BBB: 100.45289204,
            BB: 125.7717356172,
            B: 161.375681932596,
          },
          {
            term: 4,
            AAA: 80.5,
            AA: 90.6796,
            A: 104.376428,
            BBB: 123.10289204,
            BB: 149.0217356172,
            B: 185.225681932596,
          },
          {
            term: 5,
            AAA: 101.35,
            AA: 112.12960000000001,
            A: 126.42642799999999,
            BBB: 145.75289204,
            BB: 172.2717356172,
            B: 209.075681932596,
          },
        ],
        referenceRate: {
          rateValue: 275,
          rateChange: 25,
        },
      },
      {
        roundId: 3,
        mainSectionText:
          'All good things come to an end! A global recession has struck. The demand for local exports has evaporated and is placing stress on local businesses. Unemployment has sky rocketed and many borrowers are falling behind on repayments. Wholesale funding is hard to come by, with longer term debt carrying significant premiums. Customers are sheltering their cash in major banks so expect some funding relief there, however these funds will be tightly contested. In an effort to stimulate the local economy, the RBA has increased the official cash rate by 25 basis points but it is unclear how much of this banks will pass on to their customers.',
        mainSectionSecondaryText:
          '"Only when the tide goes out do you discover who’s been swimming naked"',
        mainSectionSecondaryTextAuthor: 'Warren Buffett',
        marketView1Text:
          'The recession was a necessary evil, companies who have funded short will suffer but strong and sustainable companies will prevail. This presents an opportunity to pick up market share of struggling banks and also reposition portfolios for future growth.',
        marketView1ResultsText:
          'The recession was a necessary evil but this presents an opportunity to pick up market share of struggling banks and also reposition portfolios for future growth.',
        marketView2Text:
          'The excessive leverage and risk taking that has built up in the system will take time to relieve. We’ve entered a period of low returns. Credit spreads will stay elevated for a while so there is no respite in sight.',
        marketView2ResultsText:
          'We’ve entered a period of low returns. Credit spreads will stay elevated for a while so there is no respite in site.',
        resultsMainHeading: 'Pop',
        resultsMainText:
          'A global recession has struck. Unemployment has sky rocketed. Borrowers are falling behind. Debt investors exiting the market. Central Bank cutting rates to relieve pressure.',
        marketOutlook: [
          {
            productType: 'HOME_LOANS',
            growth: {
              setFrom: 0,
              setTo: 200,
            },
            losses: {
              setFrom: 5,
              setTo: 10,
            },
          },
          {
            productType: 'BUSINESS_LOANS',
            growth: {
              setFrom: -400,
              setTo: -200,
            },
            losses: {
              setFrom: 1,
              setTo: 8,
            },
          },
          {
            productType: 'DEPOSITS',
            growth: {
              setFrom: 700,
              setTo: 900,
            },
            losses: null,
          },
          {
            productType: 'CREDIT_CARDS',
            growth: {
              setFrom: -1500,
              setTo: -1300,
            },
            losses: {
              setFrom: 2,
              setTo: 2,
            },
          },
          {
            productType: 'WEALTH_MANAGEMENT',
            growth: {
              setFrom: -500,
              setTo: -300,
            },
            losses: null,
          },
          {
            productType: 'INSTITUTIONAL_BANKING',
            growth: {
              setFrom: -500,
              setTo: -300,
            },
            losses: {
              setFrom: 5,
              setTo: 5,
            },
          },
          {
            productType: 'OFFSHORE_BANK',
            growth: {
              setFrom: -293,
              setTo: -93,
            },
            losses: {
              setFrom: 1,
              setTo: 1,
            },
          },
        ],
        wholesaleFundingCosts: [
          {
            term: 1,
            AAA: 131.95,
            AA: 172.15,
            A: 212.35,
            BBB: 252.55,
            BB: 292.75,
            B: 332.95,
          },
          {
            term: 2,
            AAA: 165.31,
            AA: 206.47,
            A: 247.63,
            BBB: 288.79,
            BB: 329.95,
            B: 371.11,
          },
          {
            term: 3,
            AAA: 198.67000000000002,
            AA: 240.79,
            A: 282.90999999999997,
            BBB: 325.03,
            BB: 367.15,
            B: 409.27,
          },
          {
            term: 4,
            AAA: 232.02999999999997,
            AA: 275.11,
            A: 318.19,
            BBB: 361.27,
            BB: 404.35,
            B: 447.43,
          },
          {
            term: 5,
            AAA: 265.39,
            AA: 309.43,
            A: 353.47,
            BBB: 397.51,
            BB: 441.55,
            B: 485.59000000000003,
          },
        ],
        referenceRate: {
          rateValue: 250,
          rateChange: -25,
        },
      },
      {
        roundId: 4,
        mainSectionText:
          'The economy appears to be recovering from the down turn. Optimism is creeping back into the markets.  Domestic spending is increasing, breathing life back into local businesses and reducing unemployment. Debt and equity investors are still cautious and credit spreads reflect this. Whilst customer default rates are easing, they remain elevated versus favourable conditions. Lending growth is once again positive but it now comes with higher losses and lower margins given current wholesale funding spreads. Maintaining responsible lending standards but balancing shareholder interests is critical in such a difficult environment.',
        mainSectionSecondaryText:
          '“There is only one boss. The customer. She can fire everybody in the company from the chairman on down, simply by spending their money somewhere else.”     ',
        mainSectionSecondaryTextAuthor: 'Sam Walton',
        marketView1Text:
          'Deleveraging has occurred, business confidence is starting to pick up, expect a comfortable bounce back.',
        marketView1ResultsText:
          'Deleveraging has occurred, business confidence is starting to pick up, the world needs commodity exports and so Australia will make a comfortable bounce back.',
        marketView2Text:
          'None of the problems we faced have been dealt with and the bubble like price gains haven’t returned to normal levels yet. It is often quietest in the eye of the hurricane.',
        marketView2ResultsText:
          'None of the problems we faced have been dealt with and the bubble like house price gains haven’t returned to normal levels yet. It is often quietest in the eye of the hurricane.',
        resultsMainHeading: 'Slowly Recovering',
        resultsMainText:
          'Economy recovering from the down turn. Optimism is creeping back. Unemployment levels decreasing slowly. Debt and equity investors still cautious.',
        marketOutlook: [
          {
            productType: 'HOME_LOANS',
            growth: {
              setFrom: 150,
              setTo: 350,
            },
            losses: {
              setFrom: 3,
              setTo: 6,
            },
          },
          {
            productType: 'BUSINESS_LOANS',
            growth: {
              setFrom: 200,
              setTo: 400,
            },
            losses: {
              setFrom: 1,
              setTo: 4,
            },
          },
          {
            productType: 'DEPOSITS',
            growth: {
              setFrom: 500,
              setTo: 700,
            },
            losses: null,
          },
          {
            productType: 'CREDIT_CARDS',
            growth: {
              setFrom: -700,
              setTo: -500,
            },
            losses: {
              setFrom: 1,
              setTo: 1,
            },
          },
          {
            productType: 'WEALTH_MANAGEMENT',
            growth: {
              setFrom: 200,
              setTo: 400,
            },
            losses: null,
          },
          {
            productType: 'INSTITUTIONAL_BANKING',
            growth: {
              setFrom: 100,
              setTo: 300,
            },
            losses: {
              setFrom: 3,
              setTo: 3,
            },
          },
          {
            productType: 'OFFSHORE_BANK',
            growth: {
              setFrom: 298,
              setTo: 498,
            },
            losses: {
              setFrom: 1,
              setTo: 1,
            },
          },
        ],
        wholesaleFundingCosts: [
          {
            term: 1,
            AAA: 84.95,
            AA: 107.24320000000002,
            A: 132.62944800000002,
            BBB: 161.54177072000005,
            BB: 194.47381862080005,
            B: 231.98835322771205,
          },
          {
            term: 2,
            AAA: 109.97,
            AA: 132.9832,
            A: 159.089448,
            BBB: 188.72177072000002,
            BB: 222.37381862080005,
            B: 260.6083532277121,
          },
          {
            term: 3,
            AAA: 134.99,
            AA: 158.72320000000002,
            A: 185.549448,
            BBB: 215.90177072000003,
            BB: 250.27381862080006,
            B: 289.2283532277121,
          },
          {
            term: 4,
            AAA: 160.01,
            AA: 184.4632,
            A: 212.00944800000002,
            BBB: 243.08177072000004,
            BB: 278.17381862080003,
            B: 317.8483532277121,
          },
          {
            term: 5,
            AAA: 185.02999999999997,
            AA: 210.2032,
            A: 238.469448,
            BBB: 270.26177072,
            BB: 306.07381862080007,
            B: 346.4683532277121,
          },
        ],
        referenceRate: {
          rateValue: 250,
          rateChange: 0,
        },
      },
      {
        roundId: 5,
        mainSectionText:
          'While the days of double digit growth remain elusive, the economy continues to recover. Unemployment rates are falling and GDP is slowly trending upwards. Lending asset growth has increased meaning banks should see a share of new business. Loss rates however continue to remain higher than is desirable, although far lower than losses during the recession. Investors remain somewhat cautious, although pockets of investor optimism are present. Analysts speculate that only the most robust and savvy banks can prosper in a time of moderate growth, medium to higher losses, and somewhat elevated funding costs.',
        mainSectionSecondaryText:
          ' “A brand for a company is like a reputation for a person. You earn reputation by trying to do hard things well.”',
        mainSectionSecondaryTextAuthor: 'Jeff Bezos',
        marketView1Text:
          'The economic fundamentals appear good. Astute participants will be positioning themselves to capitalise on the rebound. ',
        marketView1ResultsText:
          'The recession was a necessary evil but this presents an opportunity to pick up market share of struggling banks and also reposition portfolios for future growth.',
        marketView2Text:
          'This is the beginning of Armageddon, if a new crisis emerges the central bankers are not equipped to handle it given rates are so low and they’ve used so much of their ammunition already.',
        marketView2ResultsText:
          "If a new crisis emerges the central bankers are not equipped to handle it given rates are so low and they've used so much of their ammunition already.",
        resultsMainHeading: 'Moving Forward But Carefully',
        resultsMainText:
          'Economy is recovering. Debt and equity investors are still cautious and credit spreads reflect this. Customer default rates easing. Lending growth is again positive.',
        marketOutlook: [
          {
            productType: 'HOME_LOANS',
            growth: {
              setFrom: 200,
              setTo: 400,
            },
            losses: {
              setFrom: 2,
              setTo: 2,
            },
          },
          {
            productType: 'BUSINESS_LOANS',
            growth: {
              setFrom: 200,
              setTo: 400,
            },
            losses: {
              setFrom: 1,
              setTo: 2,
            },
          },
          {
            productType: 'DEPOSITS',
            growth: {
              setFrom: 300,
              setTo: 500,
            },
            losses: null,
          },
          {
            productType: 'CREDIT_CARDS',
            growth: {
              setFrom: -700,
              setTo: -500,
            },
            losses: {
              setFrom: 1,
              setTo: 1,
            },
          },
          {
            productType: 'WEALTH_MANAGEMENT',
            growth: {
              setFrom: 200,
              setTo: 400,
            },
            losses: null,
          },
          {
            productType: 'INSTITUTIONAL_BANKING',
            growth: {
              setFrom: 200,
              setTo: 400,
            },
            losses: {
              setFrom: 1,
              setTo: 1,
            },
          },
          {
            productType: 'OFFSHORE_BANK',
            growth: {
              setFrom: 389,
              setTo: 589,
            },
            losses: {
              setFrom: 1,
              setTo: 1,
            },
          },
        ],
        wholesaleFundingCosts: [
          {
            term: 1,
            AAA: 41.95,
            AA: 52.1346,
            A: 65.21473399999999,
            BBB: 82.03010686,
            BB: 103.66393784940001,
            B: 131.513579825726,
          },
          {
            term: 2,
            AAA: 62.8,
            AA: 73.5846,
            A: 87.264734,
            BBB: 104.68010686000001,
            BB: 126.91393784940001,
            B: 155.36357982572602,
          },
          {
            term: 3,
            AAA: 83.65,
            AA: 95.0346,
            A: 109.31473399999999,
            BBB: 127.33010686,
            BB: 150.1639378494,
            B: 179.21357982572601,
          },
          {
            term: 4,
            AAA: 104.5,
            AA: 116.4846,
            A: 131.364734,
            BBB: 149.98010686,
            BB: 173.4139378494,
            B: 203.063579825726,
          },
          {
            term: 5,
            AAA: 125.35,
            AA: 137.9346,
            A: 153.414734,
            BBB: 172.63010686,
            BB: 196.6639378494,
            B: 226.913579825726,
          },
        ],
        referenceRate: {
          rateValue: 250,
          rateChange: 0,
        },
      },
      {
        roundId: 6,
        mainSectionText: '',
        mainSectionSecondaryText: '',
        mainSectionSecondaryTextAuthor: '',
        marketView1Text: '',
        marketView1ResultsText: '',
        marketView2Text: '',
        marketView2ResultsText: '',
        resultsMainHeading: '',
        resultsMainText: '',
        marketOutlook: [
          {
            productType: 'HOME_LOANS',
            growth: {
              setFrom: 200,
              setTo: 400,
            },
            losses: {
              setFrom: 2,
              setTo: 2,
            },
          },
          {
            productType: 'BUSINESS_LOANS',
            growth: {
              setFrom: 200,
              setTo: 400,
            },
            losses: {
              setFrom: 1,
              setTo: 1,
            },
          },
          {
            productType: 'DEPOSITS',
            growth: {
              setFrom: 300,
              setTo: 500,
            },
            losses: null,
          },
          {
            productType: 'CREDIT_CARDS',
            growth: {
              setFrom: -300,
              setTo: -100,
            },
            losses: {
              setFrom: 1,
              setTo: 1,
            },
          },
          {
            productType: 'WEALTH_MANAGEMENT',
            growth: {
              setFrom: 200,
              setTo: 400,
            },
            losses: null,
          },
          {
            productType: 'INSTITUTIONAL_BANKING',
            growth: {
              setFrom: 200,
              setTo: 400,
            },
            losses: {
              setFrom: 1,
              setTo: 1,
            },
          },
          {
            productType: 'OFFSHORE_BANK',
            growth: {
              setFrom: 380,
              setTo: 580,
            },
            losses: {
              setFrom: 1,
              setTo: 1,
            },
          },
        ],
        wholesaleFundingCosts: [
          {
            term: 1,
            AAA: 11.95,
            AA: 23.0875,
            A: 36.959375,
            BBB: 54.24921875,
            BB: 75.8115234375,
            B: 102.714404296875,
          },
          {
            term: 2,
            AAA: 30.02,
            AA: 41.677499999999995,
            A: 56.069375,
            BBB: 73.87921875,
            BB: 95.9615234375,
            B: 123.384404296875,
          },
          {
            term: 3,
            AAA: 48.089999999999996,
            AA: 60.2675,
            A: 75.179375,
            BBB: 93.50921875,
            BB: 116.11152343750001,
            B: 144.054404296875,
          },
          {
            term: 4,
            AAA: 66.16,
            AA: 78.85749999999999,
            A: 94.28937499999999,
            BBB: 113.13921875,
            BB: 136.2615234375,
            B: 164.724404296875,
          },
          {
            term: 5,
            AAA: 84.22999999999999,
            AA: 97.44749999999999,
            A: 113.39937499999999,
            BBB: 132.76921875,
            BB: 156.41152343750002,
            B: 185.394404296875,
          },
        ],
        referenceRate: {
          rateValue: 250,
          rateChange: 0,
        },
      },
    ],
  }
}
