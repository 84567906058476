import React from 'react'
import { Business, ProductType } from '../../../../types/gameApi/business'
import { BusinessDecision, DecisionMap } from '../BusinessesContainer'
import BusinessPriceVolumeTable, {
  calculatePercentage,
  estimateIncome,
  renderPercentage,
} from './BusinessPriceVolumeTable'
import BusinessCard from './/BusinessCardItem'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import PrimaryText from '../../../atoms/Text/PrimaryText'
import Button from '../../../atoms/Button/Button'
import { Icon } from '../../../atoms/Icon/Icon'
import ValueChangerWithPercentageChange from '../../../molecules/ValueChanger/ValueChangerWithPercentageChange'
import { thousandSeparator } from '../../../../lib/formatters'
import Card from '../../../atoms/Card/Card'
import { useTranslatedText } from '../../../../hooks/useTranslatedText'
import Page from '../../../atoms/Page/Page'
interface Props {
  data: Business[]
  currentBusiness: ProductType
  decisions: DecisionMap
  onChangeValue: (
    type: string,
    field: keyof BusinessDecision,
    value: number,
  ) => void
  changeCurrentBusiness: (type: ProductType) => void
  enableChanges: boolean
  setShowModal: (val: boolean) => void
  handleReset: () => void
}

const BusinessPriceVolumeDetail: React.FC<Props> = ({
  data,
  currentBusiness,
  decisions,
  onChangeValue,
  changeCurrentBusiness,
  enableChanges,
  setShowModal,
  handleReset,
}) => {
  const millionsTranslation = useTranslatedText(
    'general.denominationShort',
    'm',
  )

  const volumeTranslation = useTranslatedText(
    'businessDecisions.screen2.volumeEstimate',
    'VOLUME ESTIMATE',
  )

  const estimatedIncomeTranslation = useTranslatedText(
    'businessDecisions.screen2.estimateIncome',
    'ESTIMATED INCOME',
  )

  const estimatedIncomes = data.map(it =>
    estimateIncome(
      decisions[it.type].price1Now,
      decisions[it.type].volumeNow,
      it.volumePrev,
      it.type,
    ),
  )
  const totalPreviousIncome = data.reduce((result, it) => {
    result += it.incomePrev
    return result
  }, 0)
  const totalEstimatedIncome = data.reduce((result, it) => {
    result += estimateIncome(
      decisions[it.type].price1Now,
      decisions[it.type].volumeNow,
      it.volumePrev,
      it.type,
    )
    return result
  }, 0)

  return (
    <VerticalGroup fullWidth gap={2}>
      <Page className="hidden tablet:flex">
        <HorizontalGroup fullWidth>
          <BusinessPriceVolumeTable
            data={data}
            currentBusiness={currentBusiness}
            decisions={decisions}
            millionsTranslation={millionsTranslation}
            volumeTranslation={volumeTranslation}
            estimatedIncomeTranslation={estimatedIncomeTranslation}
            onChangeValue={onChangeValue}
            changeCurrentBusiness={changeCurrentBusiness}
            setShowModal={setShowModal}
            enableChanges={enableChanges}
          />
        </HorizontalGroup>
      </Page>
      <VerticalGroup gap={4} className="px-4 tablet:hidden">
        <HorizontalGroup verticalCenter gap={2} between>
          <PrimaryText weight="bold">DETAIL</PrimaryText>
          <HorizontalGroup className="tablet:hidden" gap={4} verticalCenter>
            {enableChanges && (
              <Button rounded="full" buttonType="secondary">
                <HorizontalGroup
                  verticalCenter
                  gap={2}
                  className="row-reverse flex"
                >
                  <Icon type="reset" colour="black" />
                  <PrimaryText onClick={handleReset} colour="black" size="sm">
                    Reset Volumes
                  </PrimaryText>
                </HorizontalGroup>
              </Button>
            )}
          </HorizontalGroup>
        </HorizontalGroup>
        {data.map((item, i) => (
          <BusinessCard
            key={i}
            item={item}
            decisions={decisions}
            estimatedIncome={estimatedIncomes[i]}
            active={item.type === currentBusiness}
            millionsTranslation={millionsTranslation}
            volumeTranslation={volumeTranslation}
            estimatedIncomeTranslation={estimatedIncomeTranslation}
            onChangeValue={onChangeValue}
            changeCurrentBusiness={changeCurrentBusiness}
            enableChanges={enableChanges}
            idPrefix={`mobile-${item.type}-volumeNow`}
          />
        ))}
      </VerticalGroup>
      <Card className="fixed bottom-0 mx-[-1.0rem] w-full rounded-none tablet:hidden">
        <HorizontalGroup verticalCenter between className="p-2">
          <PrimaryText whitespace="nowrap" size="sm" weight="bold">
            TOTAL {estimatedIncomeTranslation}
          </PrimaryText>
          <PrimaryText weight="bold">
            <ValueChangerWithPercentageChange
              fontBold
              enableChanges={false}
              value={totalEstimatedIncome}
              formatValue={val => thousandSeparator(parseInt(String(val)))}
              percentageChange={() =>
                renderPercentage(
                  calculatePercentage(
                    totalEstimatedIncome,
                    totalPreviousIncome,
                    undefined,
                    1,
                  ),
                )
              }
              onIncrement={() => undefined}
              onDecrement={() => undefined}
            />
          </PrimaryText>
        </HorizontalGroup>
      </Card>
      <div className="h-10 tablet:hidden"></div>
    </VerticalGroup>
  )
}

export default BusinessPriceVolumeDetail
