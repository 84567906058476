import classNames from 'classnames'
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { NumericFormat } from 'react-number-format'
import { FontSize, Spacing } from '../../../types/theme'
import { useCommonClasses } from '../../../hooks/useCommonClasses'
import { TEXT_SIZE_CLASSES } from '../Text/Text'

export type ValueChangeInputType =
  | 'percentage'
  | 'dollar'
  | 'integer'
  | 'decimal'

type Props = {
  disabled?: boolean
  className?: string
  type: ValueChangeInputType
  value: number
  onClick?: (e: any) => void
  onChange: (value: number) => void
  onError: (error: string) => void
  validateInput: (value: number) => string | undefined
  submitTimeout?: number
  propogateOnChange?: boolean
  paddingY?: Spacing
  size?: FontSize
  suffix?: string
  allowNegative?: boolean
  id?: string
}

const NumericInput: FC<Props> = ({
  disabled,
  className,
  type,
  value,
  onClick,
  onChange,
  onError,
  validateInput,
  submitTimeout,
  paddingY,
  size,
  suffix,
  allowNegative,
  id,
}) => {
  const [rerender, setRerender] = useState(1)

  const [debouncedValue, setDebouncedValue] = useState('')
  const [hasFocused, setHasFocused] = useState(false)
  const timeoutRef = useRef<number | null>(null)
  const commonClasses = useCommonClasses({ paddingY })

  let numberFormatProps = {}
  if (suffix) {
    numberFormatProps = {
      ...numberFormatProps,
      suffix,
    }
  }
  if (type === 'percentage' || type === 'decimal') {
    numberFormatProps = {
      ...numberFormatProps,
      decimalScale: 2,
      fixedDecimalScale: true,
    }
  }
  if (type === 'percentage') {
    numberFormatProps = {
      ...numberFormatProps,
      suffix: '%',
    }
  }
  if (type === 'dollar') {
    numberFormatProps = {
      ...numberFormatProps,
      prefix: '$',
    }
  }
  if (type === 'integer') {
    numberFormatProps = {
      ...numberFormatProps,
      decimalScale: 0,
      thousandSeparator: ',',
      // fixedDecimalScale: true
    }
  }

  const valueToUse = useMemo(() => {
    if (type === 'percentage' || type === 'decimal') return value / 100
    return value
  }, [type, value])

  const handleInputValue = useCallback(
    (inputValue: string) => {
      setDebouncedValue('')
      setHasFocused(false)
      let parsedValue = parseFloat(
        inputValue
          .replace('%', '')
          .replace('$', '')
          .replace(',', '')
          .replace(/[a-z]/gi, ''),
      )
      if (isNaN(parsedValue)) {
        // set error
        onError('Input is invalid')
        setRerender(rerender + 1)
        return
      }
      if (type === 'percentage' || type === 'decimal') {
        parsedValue = parseInt(String(parsedValue * 100))
      }
      const validateError = validateInput(parsedValue)
      if (validateError != null) {
        onError(validateError)
        setRerender(rerender + 1)
        return
      }
      onError('')
      onChange(parsedValue)
    },
    [type, validateInput, onChange, rerender, onError],
  )

  useEffect(() => {
    if (hasFocused && debouncedValue !== '') {
      // Call your API here with the debounced value
      // @ts-expect-error not sure
      document.activeElement.blur()
      handleInputValue(debouncedValue)
    }
  }, [debouncedValue, handleInputValue, hasFocused])

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newValue = event.target.value
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current)
    }
    if (submitTimeout) {
      timeoutRef.current = window.setTimeout(() => {
        setDebouncedValue(newValue)
      }, submitTimeout)
    }
  }

  return (
    <NumericFormat
      id={id}
      {...numberFormatProps}
      allowNegative={allowNegative}
      disabled={disabled}
      key={`numerInput${rerender}`}
      className={classNames(
        className,
        commonClasses,
        size ? TEXT_SIZE_CLASSES[size] : 'tablet:text-sm',
        'relative block w-full appearance-none rounded-md border text-center font-bold focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary',
        { 'border-primary-300 bg-primary-50': !disabled },
        { 'border-gray-300 bg-lightgray-300': disabled },
      )}
      // bgColor="lightgray"
      // centerText
      // fontWeight="bold"
      // noPadding
      value={valueToUse}
      inputMode="decimal"
      // customInput={Input}
      onClick={onClick}
      onChange={handleChange}
      onFocus={() => {
        setHasFocused(true)
      }}
      onKeyUp={(e: any) => {
        if (e.key === 'Enter') {
          // @ts-expect-error not sure
          document.activeElement.blur()
          handleInputValue(e.target.value)
        }
      }}
      onBlur={e => {
        handleInputValue(e.target.value)
      }}
      data-test="input-modal"
    />
  )
}

export default NumericInput
