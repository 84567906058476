import { createSlice } from '@reduxjs/toolkit'
import APIError from '../../errors/APIError'
import { initialise } from '../common/commonActions'
import { userLogin } from '../auth/authActions'
import { reset } from '../game/gameSlice'
import { logout } from '../auth/authSlice'

interface ContentState {
  isLoading: boolean
  isLoaded: boolean
  error: Error | APIError | null
  content: any
}

const initialState: ContentState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  content: {},
}

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(reset, () => {
        return initialState
      })
      .addCase(logout, () => {
        return initialState
      })
      .addCase(initialise.fulfilled, (state, { payload }) => {
        state.isLoaded = true
        state.content = payload.content
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.isLoaded = true
        state.content = payload.content
      })
  },
})

export default contentSlice.reducer
