import React from 'react'
import Card from '../../../atoms/Card/Card'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import { Icon, IconType } from '../../../atoms/Icon/Icon'
import { Colour } from '../../../../types/theme'
import { CostsDivision, CostsResponse } from '../../../../types/gameApi/costs'
import { DecisionMap } from '../CostsContainer'
import { CostsData } from '../Data'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'

import ValueChangeText from '../../../molecules/ValueChangeText/ValueChangeText'

interface CostImpactsProps {
  data: CostsResponse
  decisions: DecisionMap
  enableChanges: boolean
  onChangeValue: (val: Partial<DecisionMap>) => void
  costData: CostsData
  selectedDivision: string | null
}
interface Impact {
  heading: string

  icon: IconType
  impactField:
    | 'complaints'
    | 'riskIncidents'
    | 'employeeEngagement'
    | 'customerSatisfaction'
  isCost?: boolean
  type?: 'percentage' | 'number'
  changeUnit?: string
  negativeIsGood?: boolean
}

const IMPACTS = {
  BAD: 'bad',
  GOOD: 'good',
  NONE: 'none',
}

const colors: Colour[] = [
  'green',
  'pink',
  'orange',
  'green',
  'primary',
  'purple',
]

const IMPACTS_ARRAY: Impact[] = [
  {
    heading: 'NPS',

    icon: 'nps',
    impactField: 'customerSatisfaction',
  },
  {
    heading: 'Complaints',

    icon: 'complaints',
    isCost: true,
    impactField: 'complaints',
    negativeIsGood: true,
  },
  {
    heading: 'Engagement',

    icon: 'engagement',
    type: 'percentage',
    changeUnit: '%',
    impactField: 'employeeEngagement',
  },
  {
    heading: 'Risk incidents',

    icon: 'danger',
    isCost: true,
    impactField: 'riskIncidents',
    negativeIsGood: true,
  },
  {
    heading: 'Reputation',

    icon: 'communityTrust',
    type: 'percentage',
    changeUnit: '%',
    impactField: 'riskIncidents',
    negativeIsGood: true,
  },
  {
    heading: 'Sanctions',

    icon: 'gavel',

    isCost: true,
    impactField: 'riskIncidents',
    negativeIsGood: true,
  },
]

const getImpactFromField = (
  division: CostsDivision,
  field:
    | 'complaints'
    | 'riskIncidents'
    | 'employeeEngagement'
    | 'customerSatisfaction',
  negativeIsGood = false,
) => {
  const val = division[field]
  if (val === 0) {
    return IMPACTS.NONE
  }

  if ((negativeIsGood && val < 0) || (!negativeIsGood && val > 0)) {
    return IMPACTS.GOOD
  }

  return IMPACTS.BAD
}
const formatFields = (changeField: number, dataField: number, type: string) => {
  if (changeField === 0) {
    return {
      formattedChangeField: 0,
      formattedDataField: 0,
    }
  }

  if (type === 'percentage') {
    return {
      formattedChangeField: changeField || 0,
      formattedDataField: dataField || 0,
    }
  }

  return {
    formattedChangeField: Number((changeField || 0).toFixed(0)),
    formattedDataField: Number((dataField || 0).toFixed(0)),
  }
}

const CostImpacts: React.FC<CostImpactsProps> = ({
  data,
  costData,
  selectedDivision,
}) => {
  const selectedDivisionDetails = data.divisions.find(
    d => d.type === selectedDivision,
  )
  const { impactValues } = costData

  const changeData = [
    impactValues.nps.change,
    impactValues.complaints.change,
    impactValues.employeeEngagement.change,
    impactValues.riskIncidents.change,
    impactValues.communityTrust.change,
    impactValues.regulatorActions.change,
  ]

  const valueData = [
    impactValues.nps.value,
    impactValues.complaints.value,
    impactValues.employeeEngagement.value,
    impactValues.riskIncidents.value,
    impactValues.communityTrust.value,
    impactValues.regulatorActions.value,
  ]

  return (
    <Card className="h-full flex-grow px-2 tablet:px-5">
      <div className="grid h-full grid-cols-[3fr,1fr,1fr,1fr] desktop:gap-4">
        <SecondaryText className="h-6" size="xs" weight="bold">
          Metric Name
        </SecondaryText>
        <div className="hidden desktop:flex">
          <SecondaryText
            className="h-6"
            size="xs"
            whitespace="nowrap"
            weight="bold"
          >
            Investment Impact
          </SecondaryText>
        </div>
        <div className="flex desktop:hidden">
          <SecondaryText
            className="h-6"
            size="xs"
            whitespace="nowrap"
            weight="bold"
          >
            Impact
          </SecondaryText>
        </div>
        <SecondaryText size="xs" className="h-6 justify-end" weight="bold">
          Change
        </SecondaryText>
        <SecondaryText
          size="xs"
          whitespace="nowrap"
          className="h-6 justify-end"
          justify-end
          weight="bold"
        >
          Year End#
        </SecondaryText>
        {IMPACTS_ARRAY.map((impact, index) => {
          const impactEffect = selectedDivisionDetails
            ? getImpactFromField(
                selectedDivisionDetails,
                impact.impactField,
                impact.negativeIsGood,
              )
            : IMPACTS.NONE
          const { formattedChangeField, formattedDataField } = formatFields(
            changeData[index],
            valueData[index],
            impact.type || 'number',
          )

          return (
            <React.Fragment key={index}>
              <HorizontalGroup className="h-6" verticalCenter gap={2}>
                <Icon
                  size={5}
                  type={impact.icon}
                  colour={colors[index % colors.length]}
                />
                <SecondaryText
                  size="sm"
                  transform={impact.heading === 'NPS' ? 'upper' : 'titleCase'}
                >
                  {impact.heading}
                </SecondaryText>
              </HorizontalGroup>
              <HorizontalGroup verticalCenter className="h-6">
                {impactEffect === IMPACTS.BAD && (
                  <HorizontalGroup
                    className="h-6 w-6 rounded-full bg-red-100 px-1 tablet:w-24"
                    gap={1}
                    verticalCenter
                  >
                    <Icon size={4} type="negative" />
                    <SecondaryText
                      className="hidden tablet:flex"
                      colour="error"
                    >
                      Negative
                    </SecondaryText>
                  </HorizontalGroup>
                )}
                {impactEffect === IMPACTS.GOOD && (
                  <HorizontalGroup
                    gap={1}
                    verticalCenter
                    className="h-6 w-6 rounded-full bg-green-50 px-1 tablet:w-24"
                  >
                    <Icon size={4} type="smile" />
                    <SecondaryText
                      className="hidden tablet:flex"
                      colour="success"
                    >
                      Positive
                    </SecondaryText>
                  </HorizontalGroup>
                )}
              </HorizontalGroup>
              <HorizontalGroup
                className="hidden h-6 tablet:flex"
                right
                fullWidth
              >
                <ValueChangeText
                  whitespace="nowrap"
                  size="sm"
                  changeUnit={impact.changeUnit}
                  dp={0}
                  removeArrow
                  isCost={impact.isCost}
                  value={formattedChangeField}
                ></ValueChangeText>
              </HorizontalGroup>
              <HorizontalGroup className="h-6 tablet:hidden" right fullWidth>
                <ValueChangeText
                  whitespace="nowrap"
                  size="sm"
                  changeUnit={impact.changeUnit}
                  dp={0}
                  removeArrow
                  isCost={impact.isCost}
                  value={formattedChangeField}
                  dashIfZero
                ></ValueChangeText>
              </HorizontalGroup>
              <HorizontalGroup className="h-6" right fullWidth>
                <ValueChangeText
                  whitespace="nowrap"
                  size="sm"
                  changeUnit={impact.changeUnit}
                  colour="black"
                  removeArrow
                  value={formattedDataField}
                  dp={0}
                  dashIfZero
                  removePlusSign
                ></ValueChangeText>
              </HorizontalGroup>
            </React.Fragment>
          )
        })}
      </div>
    </Card>
  )
}

export default CostImpacts
