import React, { PropsWithChildren } from 'react'
import { Breakpoints, FontSize } from '../../../types/theme'
import Text, { Props as TextProps } from './Text'

export interface Props extends TextProps {}

// xs 12px
// sm 14px
// base 16px
// lg 18px
// xl 20px
// 2xl 24px
// 3xl 30px
// 4xl 36px
// 5xl 48px

const RESPONSIVE_SIZE_MAP: Record<FontSize, Record<Breakpoints, FontSize>> = {
  xs: {
    tablet: 'xs',
    laptop: 'xs',
    desktop: 'sm',
  },
  sm: {
    tablet: 'sm',
    laptop: 'sm',
    desktop: 'base',
  },
  base: {
    tablet: 'base',
    laptop: 'base',
    desktop: 'lg',
  },
  lg: {
    tablet: 'lg',
    laptop: 'lg',
    desktop: 'xl',
  },
  xl: {
    tablet: 'xl',
    laptop: 'xl',
    desktop: '2xl',
  },
  '2xl': {
    tablet: '3xl',
    laptop: '3xl',
    desktop: '3xl',
  },
  '3xl': {
    tablet: '4xl',
    laptop: '4xl',
    desktop: '4xl',
  },
  '4xl': {
    tablet: '5xl',
    laptop: '5xl',
    desktop: '5xl',
  },
  '5xl': {
    tablet: '5xl',
    laptop: '5xl',
    desktop: '5xl',
  },
}

const ResponsiveText: React.FC<PropsWithChildren<Props>> = props => {
  const sizeProps: Partial<TextProps> = {}
  if (props.size) {
    const responsiveSizes = RESPONSIVE_SIZE_MAP[props.size]
    sizeProps.tabletSize = props.tabletSize ?? responsiveSizes.tablet
    sizeProps.laptopSize = props.laptopSize ?? responsiveSizes.laptop
    sizeProps.desktopSize = props.desktopSize ?? responsiveSizes.desktop
  }
  return <Text {...{ ...props, ...sizeProps }} />
}

export default ResponsiveText
