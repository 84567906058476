import React from 'react'
import Page from '../../../atoms/Page/Page'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import BrandedVerticalGroup from '../../../organisms/Branding/BrandedVerticalGroup/BrandedVerticalGroup'
import StrategyCard from '../StrategyCard'
import BrandedText from '../../../organisms/Branding/BrandedText/BrandedText'
import { useTranslatedText } from '../../../../hooks/useTranslatedText'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import { strategyPlaceholder } from '../TeamRegister/TeamRegister'

interface Props {}

const TeamWaiting: React.FC<Props> = () => {
  const defaultStrategy = useTranslatedText('team.defaultStrategy', '')
  const placeHolder = useTranslatedText(
    'team.placeholderStrategy',
    strategyPlaceholder,
  )

  const instructions = (
    <VerticalGroup gap={2} className="p-2">
      <BrandedText colourField="mainTextColour" family="secondary" size="sm">
        As a team, please use your CEO&apos;s tablet/interface to enter:
      </BrandedText>
      <VerticalGroup gap={2} className="list-inside list-disc">
        <BrandedText colourField="mainTextColour" family="secondary" size="sm">
          <li>Bank Name ( max 8 characters ) </li>
        </BrandedText>
        <BrandedText colourField="mainTextColour" family="secondary" size="sm">
          <li>
            A strategy which sets out your objectives in clear/concise bullet
            points.{' '}
          </li>
        </BrandedText>
      </VerticalGroup>
      <BrandedText colourField="mainTextColour" family="secondary" size="xs">
        This screen will disappear once these have been completed
      </BrandedText>
    </VerticalGroup>
  )

  return (
    <BrandedVerticalGroup full className="flex-1">
      <Page full>
        <VerticalGroup full fullWidth center>
          <VerticalGroup className="w-full pt-4 pb-5 tablet:max-w-2xl">
            <VerticalGroup gap={10}>
              <StrategyCard
                heading="Instructions"
                readOnly={instructions}
                themeColor="primary"
                icon="instructions"
                iconColour="primary"
                cardHeight="fit"
              />
              <StrategyCard
                heading="Below is the strategy provided on your CEO's interface"
                readOnly={
                  <div className="h-80 overflow-auto">
                    <SecondaryText whitespace="preline">
                      {defaultStrategy ?? placeHolder}
                    </SecondaryText>
                  </div>
                }
                themeColor="orange"
                icon="idea"
                iconColour="orange"
              />
            </VerticalGroup>
          </VerticalGroup>
        </VerticalGroup>
      </Page>
    </BrandedVerticalGroup>
  )
}

export default TeamWaiting
