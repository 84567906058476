import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store'
import RadioInput from '../../../atoms/RadioButtons/RadioInput'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { setWsfMethod } from '../../../../redux/demo/demoSlice'

interface Props {}

const FUNDING_OPTIONS: Array<{ label: string; value: number }> = [
  { label: 'Amount & Term', value: 1 },
  { label: 'Amount Only', value: 2 },
  { label: 'Auto-managed', value: 3 },
]

const DemoSettingsFundingOptions: React.FC<Props> = () => {
  const wsfMethod = useAppSelector(state => state.demo.wsfMethod)
  const dispatch = useAppDispatch()

  const updateFundingOption = useCallback(
    (value: number) => {
      dispatch(setWsfMethod(value))
    },
    [dispatch],
  )

  return (
    <VerticalGroup gap={2}>
      {FUNDING_OPTIONS.map(t => (
        <HorizontalGroup verticalCenter gap={2} key={t.value}>
          <RadioInput
            key={t.value}
            id={String(t.value)}
            name={t.label}
            value={String(t.value)}
            checked={t.value === wsfMethod}
            onClick={() => {
              updateFundingOption(t.value)
            }}
          />
          <label htmlFor={`${t.value}`}>{t.label}</label>
        </HorizontalGroup>
      ))}
    </VerticalGroup>
  )
}

export default DemoSettingsFundingOptions
