import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'
import {
  BACKGROUND_COLOUR_CLASSES,
  DARKER_BACKGROUND_COLOUR_SHADE_CLASSES,
} from '../../../lib/colourClasses'
import { Colour, Spacing } from '../../../types/theme'
import { useCommonClasses } from '../../../hooks/useCommonClasses'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  colour: Colour
  padding?: Spacing
  alignContent?: 'start' | 'center' | 'right'
  noShadow?: boolean
  useShade?: boolean
  noBorder?: boolean
  fullHeight?: boolean
  onClick?: () => void
}

const MetricCard: React.FC<PropsWithChildren<Props>> = ({
  className,
  children,
  colour,
  padding,
  alignContent = 'center',
  noShadow,
  useShade = false,
  noBorder = false,
  fullHeight = true,
  onClick,
  ...rest
}) => {
  const commonClasses = useCommonClasses({ padding })

  const colourClasses = useShade
    ? DARKER_BACKGROUND_COLOUR_SHADE_CLASSES
    : BACKGROUND_COLOUR_CLASSES

  return (
    <div
      {...rest}
      className={classNames(
        {
          'border border-gray-300': !noBorder,
          'flex flex-col justify-center gap-2 rounded-lg': true,
          'h-full': fullHeight,
          'items-start': alignContent === 'start',
          'items-center': alignContent === 'center',
          'items-end': alignContent === 'right',
          'shadow-md': !noShadow,
        },
        commonClasses,
        className,
        colourClasses[colour],
      )}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

export default MetricCard
