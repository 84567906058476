/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { WaitingRoom } from './WaitingRoom'
import { RootState, useAppSelector } from '../../../store'

interface Props {}

const WaitingRoomBrandingPreview: React.FC<Props> = () => {
  const {
    clientName,
    gameName,
    eventLogo,
    buttonBackgroundColour,
    buttonTextColour,
    mainBackgroundColour,
    mainTextColour,
    headingBackgroundColour,
    headingTextColour,
  } = useAppSelector(state => state.brandingPreview)

  const game: RootState['game'] = {
    appTitle: '',
    currentRound: 1,
    finishTime: '',
    gameDate: '',
    gameId: '',
    gameName: '',
    gameStartTime: '',
    nextRoundStartTime: '',
    roundType: 'facilitated',
    roundsSetup: [],
    selectedRound: 1,
    splashScreenTitle: '',
    state: 'inprogress',
    stream: '',
    submitError: null,
    totalRounds: 5,
    warningMessage: null,
    broadcastMessage: null,
    logo: eventLogo ?? null,
    shouldRotateCeo: false,
    maskParticipants: false,
    currentRoundSetup: {
      insightsEnabled: false,
      dealsEnabled: false,
      excoEnabled: false,
      specialProjectsEnabled: false,
      surveyEnabled: false,
      operatingCostsEnabled: false,
      startTime: null,
      finishTime: null,
      time: 0,
    },
    results: [
      {
        id: '123',
        roundId: 1,
        url: '',
      },
    ],
    automate: {
      testModeScreen: false,
      menuTimeMax: 0,
      menuTimeMin: 0,
      testModeWidgets: false,
      widgetTimeMin: 0,
      widgetTimeMax: 0,
      widgetMaxPricingChange: 0,
    },
    automateRedirectTo: null,
    resultsCastUrl: null,
    dismissedBroadcastMessages: {},
  }

  const device: RootState['device'] = {
    deviceStatusChecked: true,
    registered: true,
    error: null,
    registering: false,
    isCeo: false,
  }

  const event: RootState['event'] = {
    isLoading: false,
    isLoaded: true,
    error: null,
    details: {
      participantId: 'not',
      client: clientName ?? '',
      eventId: '123',
      eventDate: '2022-12-25',
      eventClosed: false,
      team: 1,
      isCeo: true,
      apiHost: '',
      firstName: 'Firstname',
      gameName: gameName ?? '',
      buttonBackgroundColour,
      headingBackgroundColour,
      mainBackgroundColour,
      headingTextColour,
      mainTextColour,
      buttonTextColour,
      eventLogo,
    },
  }
  const team: RootState['team'] = {
    id: 1,
    name: 'Peoples',
    registered: false,
    registering: false,
    error: null,
    strategy: null,
  }

  const resources: RootState['resources'] = {
    isLoading: false,
    isLoaded: true,
    error: null,
    resources: [
      {
        href: 'https://google.com',
        name: 'Players Guide',
        uploadDate: new Date().toISOString(),
      },
      {
        href: 'https://yahoo.com',
        name: 'Round 1 News',
        uploadDate: new Date().toISOString(),
      },
    ],
  }

  return (
    <WaitingRoom
      event={event.details}
      game={game}
      team={team}
      device={device}
      deviceStatusChecked
      showCeo={false}
      logoutAction={() => {}}
      onSettingsButtonPressed={() => {}}
      onEnterEventButtonPressed={() => {}}
      resources={resources.resources}
    />
  )
}

export default WaitingRoomBrandingPreview
