import React, { useState } from 'react'
import { ProductType } from '../../../../types/gameApi/business'
import { FinancialsResponse } from '../../../../types/gameApi/financials'
import { BottomTabType } from '../../../atoms/BottomTabs/BottomTab'
import BottomTabs from '../../../atoms/BottomTabs/BottomTabs'
import { IconType } from '../../../atoms/Icon/Icon'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import DivisionalProfitAndLossTable from './DivisionalProfitAndLossTable'
import { useIsMobile } from '../../../../hooks/useIsMobile'

interface Props {
  financials: FinancialsResponse
  roundId: number
}

type BusinessTab = BottomTabType<ProductType>

const BUSINESS_ICON_MAP: Record<ProductType, IconType> = {
  HOME_LOANS: 'mortgages',
  BUSINESS_LOANS: 'lending',
  DEPOSITS: 'deposits',
  CREDIT_CARDS: 'card',
  WEALTH_MANAGEMENT: 'businesses',
  INSTITUTIONAL_BANKING: 'businesses',
  OFFSHORE_BANK: 'businesses',
}

const DivisionalProfitAndLoss: React.FC<Props> = ({ financials, roundId }) => {
  const businessTabs: BusinessTab[] =
    financials.divisionProfitAndLoss.businesses.map<BusinessTab>(b => ({
      icon: BUSINESS_ICON_MAP[b.type],
      label: b.businessName,
      value: b.type,
    }))

  const [selectedBusiness, setSelectedBusiness] = useState<ProductType>(
    businessTabs[0].value,
  )
  const isMobile = useIsMobile()
  return (
    <VerticalGroup full>
      <VerticalGroup data-test="division-table" full className="h-full">
        <DivisionalProfitAndLossTable
          className="hidden h-full laptop:block"
          financials={financials}
          roundId={roundId}
        />
        {isMobile && (
          <DivisionalProfitAndLossTable
            key={selectedBusiness}
            className="h-full laptop:hidden"
            financials={financials}
            roundId={roundId}
            selectedBusiness={selectedBusiness}
          />
        )}
      </VerticalGroup>
      <BottomTabs
        className="laptop:hidden"
        tabs={businessTabs}
        selectedTab={selectedBusiness}
        onChange={setSelectedBusiness}
      />
    </VerticalGroup>
  )
}

export default DivisionalProfitAndLoss
