import React from 'react'
import { useIsMobileLandscape } from '../../../../../hooks/useIsMobileLandscapes'
import { useWindowSize } from '../../../../../hooks/useWindowSize'
import HorizontalGroup from '../../../../atoms/HorizontalGroup/HorizontalGroup'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  heightOffset?: number
}

const ChartContainer: React.FC<Props> = ({
  heightOffset,
  className,
  children,
}) => {
  const size = useWindowSize()
  const isMobileLandscape = useIsMobileLandscape()
  const width = !isMobileLandscape ? '100%' : (size.width ?? 0) - 100
  const height = !isMobileLandscape
    ? (size.height ? size.height - (heightOffset ?? 0) : 0) * (4 / 7)
    : (size.height ?? 0) * (3 / 4)

  return (
    <HorizontalGroup
      data-test="metric-chart"
      className={className}
      style={{ width, height }}
    >
      {children}
    </HorizontalGroup>
  )
}

export default ChartContainer
