import React from 'react'
import { useIsMobileLandscape } from '../../../hooks/useIsMobileLandscapes'
import { Icon } from '../Icon/Icon'
import PrimaryText from '../Text/PrimaryText'
import VerticalGroup from '../VerticalGroup/VerticalGroup'
import HorizontalGroup from '../HorizontalGroup/HorizontalGroup'
import { XMarkIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  handleClose: () => void
  flexRow?: boolean
}

const LandscapeMobilePage: React.FC<Props> = ({
  children,
  handleClose,
  flexRow,
}) => {
  const isMobileLandscape = useIsMobileLandscape()

  return (
    <>
      {isMobileLandscape && (
        <div className="fixed top-0 left-0 z-20 h-screen w-screen bg-gray-100 portrait:hidden">
          <div
            className={classNames(
              'flex h-full w-full items-center justify-center',
              { 'flex-row': flexRow, 'flex-col': !flexRow },
            )}
          >
            <HorizontalGroup className="h-fit">
              <button
                type="button"
                className="absolute top-0 right-0 z-10 float-right p-4"
                onClick={handleClose}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </HorizontalGroup>
            {children}
          </div>
        </div>
      )}

      <div className="fixed top-0 left-0 z-20 h-screen w-screen bg-gray-100 tablet:hidden landscape:hidden">
        <div className="flex h-full w-full flex-col items-center justify-center">
          <HorizontalGroup>
            <button
              type="button"
              className="absolute top-0 right-0 float-right p-4"
              onClick={handleClose}
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </HorizontalGroup>
          <VerticalGroup gap={4} center>
            <Icon type="rotate" className="h-20 w-20" colour="primary" />
            <PrimaryText size="lg">
              Rotate your device to landscape mode to view
            </PrimaryText>
          </VerticalGroup>
        </div>
      </div>
    </>
  )
}

export default LandscapeMobilePage
