import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  fetchTreasury,
  fetchTreasuryBackground,
  // updateTreasuryDecisions,
} from './treasuryActions'
import APIError from '../../errors/APIError'
import { reset } from '../game/gameSlice'
import { TreasuryResponse } from '../../types/gameApi/treasury'
import { logout } from '../auth/authSlice'
import ThunkCancelledError from '../../errors/ThunkCancelledError'

export type TreasuryTabs =
  | 'balance-sheet'
  | 'wsf-profile'
  | 'risk-weighted-assets'
  | 'capital-management'

interface TreasuryState {
  currentTab: TreasuryTabs
  isLoading: boolean
  isLoaded: boolean
  isLoadingBackground: boolean
  data: {
    [roundId: number]: TreasuryResponse
  }
  error: Error | APIError | null
  requestInProgress: string | null
}

const initialState: TreasuryState = {
  currentTab: 'balance-sheet',
  isLoading: false,
  isLoaded: false,
  isLoadingBackground: false,

  data: {},
  error: null,
  requestInProgress: null,
}

const treasurySlice = createSlice({
  name: 'treasury',
  initialState,
  reducers: {
    changeTab: (state, action: PayloadAction<TreasuryTabs>) => {
      state.currentTab = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(reset, () => {
        return initialState
      })
      .addCase(logout, () => {
        return initialState
      })
      .addCase(fetchTreasury.pending, (state, { meta }) => {
        if (!state.isLoading) {
          state.requestInProgress = meta.requestId
          state.isLoading = true
          state.error = null
        }
      })
      .addCase(fetchTreasury.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isLoaded = true
        state.error = null
        state.data[payload.roundId] = payload.treasury
        state.requestInProgress = null
      })
      .addCase(fetchTreasury.rejected, (state, { payload }) => {
        if (payload instanceof ThunkCancelledError) {
          return
        }
        state.isLoading = false
        state.error = payload ?? null
        state.requestInProgress = null
      })
      .addCase(fetchTreasuryBackground.pending, (state, { meta }) => {
        if (!state.isLoading && !state.isLoadingBackground) {
          state.requestInProgress = meta.requestId
          state.isLoadingBackground = true
        }
      })
      .addCase(fetchTreasuryBackground.fulfilled, (state, { payload }) => {
        state.isLoadingBackground = false
        state.data[payload.roundId] = payload.treasury
        state.requestInProgress = null
        state.error = null
      })
      .addCase(fetchTreasuryBackground.rejected, (state, { payload }) => {
        if (payload instanceof ThunkCancelledError) {
          return
        }
        state.isLoadingBackground = false
        state.requestInProgress = null
      })
    // .addCase(updateTreasuryDecisions.fulfilled, () => )
  },
})

export const { changeTab } = treasurySlice.actions

export default treasurySlice.reducer
