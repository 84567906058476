import gameApi from './gameApi'
// import wait from '../lib/wait'
import wait from '../lib/wait'
import store from '../store'
import {
  deviceStatusChecked,
  unregister,
  updateDeviceCeoStatus,
  updateDeviceStatus,
} from '../redux/device/deviceSlice'
import {
  registerTeamError,
  registerTeamSuccess,
  teamChanged,
} from '../redux/team/teamSlice'
import {
  changeFinishTime,
  changeGameState,
  reset,
} from '../redux/game/gameSlice'
import { setTotalPages, setFiles } from '../redux/help/helpSlice'
import { changeTab as changeFinancialsTab } from '../redux/financials/financialsSlice'
import {
  setCEOTransferRequestPending,
  setCEOTransferRequestReceived,
} from '../redux/changeCeo/changeCeoSlice'
import { getCEOTransferRequest } from '../redux/changeCeo/changeCeoActions'
import Automator from './automator'
import { changeTab as changeBusinessTab } from '../redux/businesses/businessSlice'
import { changeTab as changTreasuryTab } from '../redux/treasury/treasurySlice'
import { changeTab as changeInsightsTab } from '../redux/peer-insights/peerInsightsSlice'
import { changeTab as changeDealsTab } from '../redux/dealroom/dealroomSlice'

const SECONDS_IN_A_DAY = 86400
const HEARTBEATER_INTERVAL =
  parseInt(process.env.REACT_APP_HEARTBEATER_INTERVAL ?? '5') * 1000

const getFinishTime = (
  finishTime: string | null | undefined,
  currentServerTime: string,
) => {
  if (finishTime) {
    const nowVal = new Date().valueOf()
    const serverTime = new Date(currentServerTime).valueOf()
    const diffSeconds = (serverTime - nowVal) / 1000
    if (Math.abs(diffSeconds) > 1 && Math.abs(diffSeconds) < SECONDS_IN_A_DAY) {
      const d = new Date(finishTime)
      d.setSeconds(d.getSeconds() - diffSeconds)
      finishTime = d.toISOString()
    }
  }
  return finishTime
}
class HeartBeater {
  // store: AppStore
  working: boolean
  automator: Automator

  constructor() {
    // this.store = store
    this.working = false
    this.automator = new Automator()
  }

  start() {
    this.working = true
    this.doHeartbeat()
  }

  stop() {
    this.working = false
  }

  doHeartbeat = async () => {
    // const { device: deviceState, team: teamState, game: gameState, help: helpState, auth: authState } = this.store.getState()
    const {
      auth: authState,
      device: deviceState,
      team: teamState,
      game: gameState,
      help: helpState,
      changeCeo: changeCeoState,
    } = store.getState()
    if (
      this.working &&
      authState.isLoggedIn &&
      authState.participantId &&
      deviceState.registered
    ) {
      try {
        const response = await gameApi.getDeviceStatus({
          deviceId: authState.participantId,
          isTablet: false,
        })

        if (gameState.gameId && gameState.gameId !== response.gameId) {
          store.dispatch(reset())
        }
        store.dispatch(changeGameState(response))
        store.dispatch(updateDeviceStatus({ isCeo: response.isMaster }))

        if (!deviceState.deviceStatusChecked) {
          store.dispatch(deviceStatusChecked())
        }

        if (response.teamId !== teamState.id) {
          store.dispatch(teamChanged(response.teamId))
        }
        if (response.team && teamState.name !== response.team.name) {
          store.dispatch(
            registerTeamSuccess({
              teamId: response.team.id,
              name: response.team.name,
              strategy: response.team.strategy,
            }),
          )
        }
        if (!response.team && teamState.name) {
          store.dispatch(registerTeamError(null))
        }

        const finishTime = getFinishTime(
          response.finishTime,
          response.currentTime,
        )
        if (gameState.finishTime !== finishTime) {
          store.dispatch(changeFinishTime(finishTime))
        }
        if (response.numberOfHelpFiles !== helpState.totalPages) {
          store.dispatch(setTotalPages(response.numberOfHelpFiles))
          store.dispatch(
            setFiles(
              response.filesToDownload
                .filter(file => file.name.startsWith('help'))
                .map(file => file.url),
            ),
          )
        }
        if (deviceState.isCeo !== response.isMaster) {
          store.dispatch(updateDeviceCeoStatus(response.isMaster))
        }
        if (gameState.currentRound !== response.currentRound) {
          store.dispatch(changeBusinessTab('decisions'))
          store.dispatch(changeFinancialsTab('summary'))
          store.dispatch(changTreasuryTab('balance-sheet'))
          store.dispatch(changeInsightsTab('market-share'))
          store.dispatch(changeDealsTab('overview'))
        }
        //     this.store.dispatch(downloadFiles(response.filesToDownload))
        store.dispatch(
          setCEOTransferRequestReceived(response.ceoTransferRequest),
        )
        const clearedLessThan20SecondsAgo =
          changeCeoState.cleared != null &&
          Date.now() - changeCeoState.cleared < 20000
        if (
          response.ceoTransferRequestPending &&
          !changeCeoState.ceoTransferRequestStatusInProgress &&
          !changeCeoState.ceoTransferRequestStatus &&
          !clearedLessThan20SecondsAgo
        ) {
          store.dispatch(
            setCEOTransferRequestPending(response.ceoTransferRequestPending),
          )
          store.dispatch(
            getCEOTransferRequest({
              participantId: authState.participantId,
              requestId: response.ceoTransferRequestPending.id,
            }),
          )
        }
        this.automator.start(response.automate)
      } catch (err: any) {
        console.log(err)
        if (!deviceState.deviceStatusChecked) {
          store.dispatch(deviceStatusChecked())
        }
        if (
          err.message === 'This device is not registered yet' ||
          err.message === 'The game has not been setup yet' ||
          err.message === 'The game is closed' ||
          err.message === 'Your token has expired'
        ) {
          store.dispatch(unregister())
          store.dispatch(reset())
        }
        //     if (deviceState.connected) {
        //       this.store.dispatch(deviceConnectionUpdate(false))
        //     }
        //     this.store.dispatch(checkIfEventClosed())
        //     // this.automator.stop()
      }
    }
    await wait(HEARTBEATER_INTERVAL)
    this.doHeartbeat()
  }
}

export default HeartBeater
