import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store'
import RadioInput from '../../../atoms/RadioButtons/RadioInput'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { setEnabledImpacts } from '../../../../redux/demo/demoSlice'

interface Props {}

const IMPACTS: Array<{ label: string; value: number }> = [
  { label: 'Customer', value: 1 },
  { label: 'Revenue', value: 2 },
  { label: 'Cost', value: 3 },
  { label: 'Risk', value: 4 },
  { label: 'Capital', value: 5 },
  { label: 'Employee', value: 6 },
  { label: 'Community', value: 7 },
]

const DemoSettingsImpacts: React.FC<Props> = () => {
  const enabledImpacts = useAppSelector(state => state.demo.enabledImpacts)
  const dispatch = useAppDispatch()

  const updateImpacts = useCallback(
    (impact: number, enabled: boolean) => {
      const newEnabledImpacts = enabled
        ? [...enabledImpacts, impact]
        : [...enabledImpacts.filter(m => m !== impact)]
      dispatch(setEnabledImpacts(newEnabledImpacts))
    },
    [dispatch, enabledImpacts],
  )

  return (
    <VerticalGroup gap={2}>
      {IMPACTS.map(t => (
        <HorizontalGroup verticalCenter gap={2} key={t.value}>
          <RadioInput
            key={t.value}
            id={String(t.value)}
            name={t.label}
            value={String(t.value)}
            checked={enabledImpacts.includes(t.value)}
            onClick={() => {
              updateImpacts(t.value, !enabledImpacts.includes(t.value))
            }}
          />
          <label htmlFor={`${t.value}`}>{t.label}</label>
        </HorizontalGroup>
      ))}
    </VerticalGroup>
  )
}

export default DemoSettingsImpacts
