import React, { useCallback } from 'react'
import { FinancialsResponse } from '../../../../types/gameApi/financials'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'
import {
  formatPercentage,
  formatValue,
  percentage,
} from '../../../../lib/formatters'
import ValueChangeText from '../../../molecules/ValueChangeText/ValueChangeText'
import { FontWeight, Spacing, TextColour } from '../../../../types/theme'
import classNames from 'classnames'
import { BalanceSheetAndCapitalTabType } from './BalanceSheetAndCapital'
import { useTranslatedText } from '../../../../hooks/useTranslatedText'
import Table from '../../../atoms/Table/Table'
import TableSection from '../../../atoms/Table/TableSection'
import TableHeader from '../../../atoms/Table/TableHeader'
import TableHeaderCell from '../../../atoms/Table/TableHeaderCell'
import TableRow from '../../../atoms/Table/TableRow'
import TableCell from '../../../atoms/Table/TableCell'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import BalanceSheetAndCapitalTourWrapper from '../OurFinancialsTour/BalanceSheetAndCapitalTourWrapper'

interface Props {
  className?: string
  financials: FinancialsResponse
  roundId: number
  selectedTab?: BalanceSheetAndCapitalTabType
  noDivideX?: boolean
  paddingY?: Spacing
}

const HeaderRow: React.FC<{
  header: string
  subHeader?: string
  shouldShowBalanceSheet: boolean
  shouldShowCapital: boolean
  noDivideX?: boolean
  paddingY?: Spacing
}> = ({
  header,
  subHeader,
  shouldShowBalanceSheet,
  shouldShowCapital,
  noDivideX,
  paddingY,
}) => (
  <TableRow noDivideX={noDivideX}>
    <TableCell paddingY={paddingY} noRightBorder>
      <HorizontalGroup between fullWidth>
        <SecondaryText size="xs" weight="bold">
          {header}
        </SecondaryText>
        {subHeader && <SecondaryText size="xs">{subHeader}</SecondaryText>}
      </HorizontalGroup>
    </TableCell>
    {shouldShowBalanceSheet && (
      <TableCell paddingY={0} noRightBorder colour="orange"></TableCell>
    )}
    {shouldShowBalanceSheet && <TableCell></TableCell>}
    {shouldShowCapital && <TableCell></TableCell>}
    {shouldShowCapital && <TableCell></TableCell>}
  </TableRow>
)

interface RowProps {
  shouldShowBalanceSheet: boolean
  shouldShowCapital: boolean
  translate: string
  translateSuffix?: string
  defaultField: string
  value: string
  riskValue?: string
  riskHeader?: {
    translate: string
    defaultField: string
    colour?: TextColour
  }
  capitalValue?: string
  changeValue: number
  isCost?: boolean
  changeUnit?: string
  weight?: FontWeight
  colour?: TextColour
  capitalRowSpan?: number
  highlight?: boolean
  extraValue?: string
  noDivideX?: boolean
}

const Row: React.FC<RowProps> = ({
  shouldShowBalanceSheet,
  shouldShowCapital,
  translate,
  translateSuffix,
  defaultField,
  value,
  riskValue,
  riskHeader,
  capitalValue,
  changeValue,
  isCost,
  changeUnit,
  weight,
  colour,
  capitalRowSpan,
  highlight,
  extraValue,
  noDivideX,
}) => {
  return (
    <TableRow noDivideX={noDivideX} highlight={highlight}>
      <TableCell paddingY={0} noRightBorder>
        {shouldShowCapital && !shouldShowBalanceSheet && riskHeader ? (
          <TranslatedText
            size="xs"
            value={riskHeader.translate}
            default={riskHeader.defaultField}
            weight={weight}
            colour={riskHeader.colour ?? colour}
            family="secondary"
            suffix={translateSuffix}
            transform="titleCase"
          />
        ) : (
          <HorizontalGroup between fullWidth>
            <TranslatedText
              size="xs"
              value={translate}
              default={defaultField}
              weight={weight}
              colour={colour}
              family="secondary"
              suffix={translateSuffix}
              transform="titleCase"
            />
            <SecondaryText size="xs">{extraValue}</SecondaryText>
          </HorizontalGroup>
        )}
      </TableCell>
      {shouldShowBalanceSheet && (
        <>
          <TableCell
            paddingY={0}
            noRightBorder
            align="center"
            colour={!highlight ? 'orange' : undefined}
          >
            <SecondaryText size="xs" weight={weight} colour={colour}>
              {value}
            </SecondaryText>
          </TableCell>
          <TableCell paddingY={0} align="center">
            <ValueChangeText
              size="xs"
              removeArrow
              removePlusSign
              value={changeValue}
              dashIfZero
              dp={0}
              changeUnit={changeUnit ?? '%'}
              isCost={isCost}
              weight={weight}
              family="secondary"
              largeThreshold={10000}
            />
          </TableCell>
        </>
      )}
      {shouldShowCapital && (
        <>
          <TableCell paddingY={0} align="center" rowSpan={capitalRowSpan ?? 1}>
            {shouldShowBalanceSheet && shouldShowCapital && riskHeader ? (
              <TranslatedText
                size="xs"
                value={riskHeader.translate}
                default={riskHeader.defaultField}
                weight={'bold'}
                colour={riskHeader.colour ?? colour}
                family="secondary"
              />
            ) : (
              <SecondaryText size="xs" weight={weight} colour={colour}>
                {riskValue}
              </SecondaryText>
            )}
          </TableCell>
          <TableCell paddingY={0} align="center">
            <SecondaryText
              size="xs"
              weight={riskHeader ? 'bold' : weight}
              colour={riskHeader?.colour ?? colour}
            >
              {capitalValue}
            </SecondaryText>
          </TableCell>
        </>
      )}
    </TableRow>
  )
}

const BalanceSheetAndCapitalTable: React.FC<Props> = ({
  className,
  financials,
  roundId,
  selectedTab,
}) => {
  const {
    fundsMgmtIncomeEnabled,
    dealRoomEnabled,
    offshoreBankEnabled,
    creditCardsEnabled,
    institutionalBankingEnabled,
  } = financials
  const shouldShowBalanceSheet = selectedTab !== 'capital'
  const shouldShowCapital = selectedTab !== 'balance-sheet'
  const data = financials.groupBalanceSheet
  const deductionText = useTranslatedText(
    'ourFinancials.groupBalanceSheet.deduction',
    'Deduction',
  )

  const formatRisk = useCallback(
    (val: number) => {
      if (val === 0) {
        return '-'
      }
      if (val === -1) {
        return deductionText
      }
      return percentage(val, 0)
    },
    [deductionText],
  )

  return (
    <BalanceSheetAndCapitalTourWrapper>
      {({ columnInfo, tableRef }) => (
        <Table
          className={classNames(className)}
          style={{ position: 'relative' }}
          ref={tableRef}
        >
          <TableHeader noDivideX>
            {/* i wanth the div to hover over from here  */}
            <TableHeaderCell ref={columnInfo[0].ref} align="left" rowSpan={2}>
              <SecondaryText size="xs">{financials.denomination}</SecondaryText>
            </TableHeaderCell>
            {shouldShowBalanceSheet && (
              <>
                <TableHeaderCell ref={columnInfo[1].ref}>
                  <TranslatedText
                    size="xs"
                    value="general.year"
                    default="YEAR"
                    family="secondary"
                    suffix={` ${roundId - 1}`}
                  />
                </TableHeaderCell>
                <TableHeaderCell ref={columnInfo[2].ref}>
                  {/* to here  */}
                  <TranslatedText
                    size="xs"
                    value="general.change"
                    default="CHANGE"
                    family="secondary"
                  />
                </TableHeaderCell>
              </>
            )}
            {shouldShowCapital && (
              <>
                <TableHeaderCell ref={columnInfo[3].ref}>
                  <TranslatedText
                    size="xs"
                    center
                    whitespace="wrap"
                    value="ourFinancials.groupBalanceSheet.riskHeader"
                    default={'RISK WEIGHT /\n DEDUCTION'}
                    family="secondary"
                  />
                </TableHeaderCell>
                <TableHeaderCell ref={columnInfo[4].ref}>
                  <TranslatedText
                    size="xs"
                    whitespace="wrap"
                    value="ourFinancials.groupBalanceSheet.capitalHeader"
                    default="CAPITAL ALLOCATION"
                    family="secondary"
                  />
                </TableHeaderCell>
              </>
            )}
          </TableHeader>
          <TableSection>
            <HeaderRow
              paddingY={1}
              noDivideX
              header="ASSETS"
              shouldShowBalanceSheet={shouldShowBalanceSheet}
              shouldShowCapital={shouldShowCapital}
            />
            <Row
              noDivideX
              shouldShowBalanceSheet={shouldShowBalanceSheet}
              shouldShowCapital={shouldShowCapital}
              translate="businessDecisions.products.HOME_LOANS"
              defaultField="Mortgages"
              value={formatValue(data.homeLoans)}
              changeValue={data.homeLoansChange}
              riskValue={formatRisk(data.homeLoansCT)}
              capitalValue={formatValue(data.homeLoansCA)}
            />
            <Row
              noDivideX
              shouldShowBalanceSheet={shouldShowBalanceSheet}
              shouldShowCapital={shouldShowCapital}
              translate="businessDecisions.products.BUSINESS_LOANS"
              defaultField="Business Lending"
              value={formatValue(data.businessLoans)}
              changeValue={data.businessLoansChange}
              riskValue={formatRisk(data.businessLoansCT)}
              capitalValue={formatValue(data.businessLoansCA)}
            />
            {creditCardsEnabled && (
              <Row
                noDivideX
                shouldShowBalanceSheet={shouldShowBalanceSheet}
                shouldShowCapital={shouldShowCapital}
                translate="businessDecisions.products.CREDIT_CARDS"
                defaultField="Credit Cards"
                value={formatValue(data.creditCards)}
                changeValue={data.creditCardsChange}
                riskValue={formatRisk(data.creditCardsCT)}
                capitalValue={formatValue(data.creditCardsCA)}
              />
            )}
            {institutionalBankingEnabled && (
              <Row
                noDivideX
                shouldShowBalanceSheet={shouldShowBalanceSheet}
                shouldShowCapital={shouldShowCapital}
                translate="businessDecisions.products.INSTITUTIONAL_BANKING"
                defaultField="Institutional Banking"
                value={formatValue(data.institutionalBanking)}
                changeValue={data.institutionalBankingChange}
                riskValue={formatRisk(data.institutionalBankingCT)}
                capitalValue={formatValue(data.institutionalBankingCA)}
              />
            )}
          </TableSection>
          <TableSection className="">
            <Row
              noDivideX
              shouldShowBalanceSheet={shouldShowBalanceSheet}
              shouldShowCapital={shouldShowCapital}
              translate="ourFinancials.groupBalanceSheet.totalLendingAssets"
              defaultField="TOTAL LENDING ASSETS"
              value={formatValue(data.totalLendingAssets)}
              changeValue={data.totalLendingAssetsChange}
              capitalValue={formatValue(data.totalLendingAssetsCA)}
              weight="bold"
            />
          </TableSection>
          <TableSection>
            <Row
              noDivideX
              shouldShowBalanceSheet={shouldShowBalanceSheet}
              shouldShowCapital={shouldShowCapital}
              translate="ourFinancials.groupBalanceSheet.cash"
              defaultField="Cash"
              value={formatValue(data.cash)}
              changeValue={data.cashChange}
              riskValue={formatRisk(data.cashCT)}
              capitalValue={formatValue(data.cashCA)}
            />
            <Row
              noDivideX
              shouldShowBalanceSheet={shouldShowBalanceSheet}
              shouldShowCapital={shouldShowCapital}
              translate="ourFinancials.groupBalanceSheet.fixedAssets"
              defaultField="Fixed Assets"
              value={formatValue(data.fixedAssets)}
              changeValue={data.fixedAssetsChange}
              riskValue={formatRisk(data.fixedAssetsCT)}
              capitalValue={formatValue(data.fixedAssetsCA)}
            />
            {fundsMgmtIncomeEnabled && (
              <Row
                noDivideX
                shouldShowBalanceSheet={shouldShowBalanceSheet}
                shouldShowCapital={shouldShowCapital}
                translate="businessDecisions.products.WEALTH_MANAGEMENT"
                defaultField="Wealth Management"
                value={formatValue(data.wealthAssets)}
                changeValue={data.wealthAssetsChange}
                riskValue={formatRisk(data.wealthAssetsCT)}
                capitalValue={formatValue(data.wealthAssetsCA)}
              />
            )}
            {offshoreBankEnabled && (
              <Row
                noDivideX
                shouldShowBalanceSheet={shouldShowBalanceSheet}
                shouldShowCapital={shouldShowCapital}
                translate="businessDecisions.products.OFFSHORE_BANK"
                defaultField="Offshore Bank"
                value={formatValue(data.offshoreBank)}
                changeValue={data.offshoreBankChange}
                riskValue={formatRisk(data.offshoreBankCT)}
                capitalValue={formatValue(data.offshoreBankCA)}
              />
            )}
            {dealRoomEnabled && (
              <Row
                noDivideX
                shouldShowBalanceSheet={shouldShowBalanceSheet}
                shouldShowCapital={shouldShowCapital}
                translate="ourFinancials.groupBalanceSheet.investments"
                defaultField="Investments"
                value={formatValue(data.investments)}
                changeValue={data.investmentsChange}
                riskValue={formatRisk(data.investmentsCT)}
                capitalValue={formatValue(data.investmentsCA)}
              />
            )}
          </TableSection>
          <TableSection>
            <Row
              noDivideX
              shouldShowBalanceSheet={shouldShowBalanceSheet}
              shouldShowCapital={shouldShowCapital}
              translate="ourFinancials.groupBalanceSheet.totalOtherAssets"
              defaultField="TOTAL OTHER ASSETS"
              value={formatValue(data.totalOtherAssets)}
              changeValue={data.totalOtherAssetsChange}
              riskHeader={{
                translate: 'ourFinancials.groupBalanceSheet.creditRisk',
                defaultField: 'CREDIT RISK',
              }}
              capitalValue={formatValue(data.creditRisk)}
              weight="bold"
            />
          </TableSection>
          <TableSection>
            <Row
              noDivideX
              shouldShowBalanceSheet={shouldShowBalanceSheet}
              shouldShowCapital={shouldShowCapital}
              translate="ourFinancials.groupBalanceSheet.totalAssets"
              defaultField="TOTAL ASSETS"
              value={formatValue(data.totalAssets)}
              changeValue={data.totalAssetsChange}
              riskHeader={{
                translate: 'ourFinancials.groupBalanceSheet.operationalRisk',
                defaultField: 'OPERATIONAL RISK',
              }}
              capitalValue={formatValue(data.operationalRisk)}
              weight="bold"
            />
          </TableSection>
          {shouldShowBalanceSheet && (
            <TableSection>
              <HeaderRow
                paddingY={1}
                noDivideX
                header="LIABILITIES"
                subHeader="COST"
                shouldShowBalanceSheet={shouldShowBalanceSheet}
                shouldShowCapital={shouldShowCapital}
              />
              <Row
                noDivideX
                shouldShowBalanceSheet={shouldShowBalanceSheet}
                shouldShowCapital={shouldShowCapital}
                translate="businessDecisions.products.DEPOSITS"
                translateSuffix=" - Transaction"
                defaultField="Deposits - Transaction"
                value={formatValue(data.transactions)}
                changeValue={data.transactionsChange}
                extraValue={formatPercentage(data.costOfTransaction)}
              />
              <Row
                noDivideX
                shouldShowBalanceSheet={shouldShowBalanceSheet}
                shouldShowCapital={shouldShowCapital}
                translate="businessDecisions.products.DEPOSITS"
                translateSuffix=" - Savings"
                defaultField="Deposits - Savings"
                value={formatValue(data.savings)}
                changeValue={data.savingsChange}
                extraValue={formatPercentage(data.costOfSavings)}
              />
              <Row
                noDivideX
                shouldShowBalanceSheet={shouldShowBalanceSheet}
                shouldShowCapital={shouldShowCapital}
                translate="ourFinancials.groupBalanceSheet.wholesaleFunding"
                defaultField="Wholesale Funding"
                value={formatValue(data.wholesaleFunding)}
                changeValue={data.wholesaleFundingChange}
                extraValue={formatPercentage(data.costOfWSF)}
              />
              <Row
                noDivideX
                shouldShowBalanceSheet={shouldShowBalanceSheet}
                shouldShowCapital={shouldShowCapital}
                translate="ourFinancials.groupBalanceSheet.centralBank"
                defaultField="Central Bank Facility"
                value={formatValue(data.centralBankFunding)}
                changeValue={data.centralBankFundingChange}
                extraValue={formatPercentage(data.costOfCBF)}
              />
            </TableSection>
          )}
          {shouldShowBalanceSheet && (
            <TableSection>
              <Row
                noDivideX
                shouldShowBalanceSheet={shouldShowBalanceSheet}
                shouldShowCapital={shouldShowCapital}
                translate="ourFinancials.groupBalanceSheet.totalLiabilities"
                defaultField="TOTAL LIABILITIES"
                value={formatValue(data.totalLiabilities)}
                changeValue={data.totalLiabilitiesChange}
                weight="bold"
              />
            </TableSection>
          )}
          {shouldShowBalanceSheet && (
            <TableSection>
              <Row
                noDivideX
                shouldShowBalanceSheet={shouldShowBalanceSheet}
                shouldShowCapital={shouldShowCapital}
                translate="ourFinancials.groupBalanceSheet.netAssets"
                defaultField="NET ASSETS"
                value={formatValue(data.netAssets)}
                changeValue={data.netAssetsChange}
                weight="bold"
              />
            </TableSection>
          )}
          <TableSection>
            {shouldShowBalanceSheet && (
              <HeaderRow
                paddingY={1}
                noDivideX
                // translate="ourFinancials.groupBalanceSheet.shareholderEquity"
                header={"SHAREHOLDERS' EQUITY"}
                shouldShowBalanceSheet={shouldShowBalanceSheet}
                shouldShowCapital={shouldShowCapital}
              />
            )}
            {shouldShowBalanceSheet && (
              <Row
                noDivideX
                shouldShowBalanceSheet={shouldShowBalanceSheet}
                shouldShowCapital={shouldShowCapital}
                translate="ourFinancials.groupBalanceSheet.retainedEarnings"
                defaultField="Retained Earnings"
                value={formatValue(data.retainedEarnings)}
                changeValue={data.retainedEarningsChange}
              />
            )}
            <Row
              noDivideX
              shouldShowBalanceSheet={shouldShowBalanceSheet}
              shouldShowCapital={shouldShowCapital}
              translate="ourFinancials.groupBalanceSheet.shareCapital"
              defaultField="Share Capital"
              value={formatValue(data.shareCapital)}
              changeValue={data.shareCapitalChange}
              riskHeader={{
                translate: 'ourFinancials.groupBalanceSheet.capitalRatio',
                defaultField: 'CAPITAL RATIO',
                colour: 'primary',
              }}
              capitalValue={percentage(data.cet1Ratio, 2)}
            />
          </TableSection>
          <TableSection>
            <Row
              noDivideX
              shouldShowBalanceSheet={shouldShowBalanceSheet}
              shouldShowCapital={shouldShowCapital}
              translate="ourFinancials.groupBalanceSheet.totalShareholdersEquity"
              defaultField={"TOTAL SHAREHOLDERS' EQUITY"}
              value={formatValue(data.netAssets)}
              changeValue={data.totalShareHoldersEquityChange}
              riskHeader={{
                translate: 'ourFinancials.groupBalanceSheet.totalCapital',
                defaultField: 'TOTAL CAPITAL',
              }}
              capitalValue={formatValue(data.netAssets)}
              weight="bold"
              highlight
            />
          </TableSection>
        </Table>
      )}
    </BalanceSheetAndCapitalTourWrapper>
  )
}

export default BalanceSheetAndCapitalTable
