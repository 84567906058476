import React, { FC } from 'react'
import { Icon, Props as IconProps } from '../../../atoms/Icon/Icon'
import { useAppSelector } from '../../../../store'
import { selectBranding } from '../selectors'

interface Props extends IconProps {
  colourField?: keyof ReturnType<typeof selectBranding>
}

const BrandedIcon: FC<Props> = props => {
  const branding = useAppSelector(selectBranding)
  const style: React.CSSProperties = props.style ?? {}

  const fieldInBranding = branding[props.colourField ?? 'headingTextColour']
  if (fieldInBranding) {
    style.fill = fieldInBranding
    style.stroke = fieldInBranding
  }
  return <Icon {...props} style={style} />
}

export default BrandedIcon
