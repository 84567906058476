import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { registerTeam } from './teamActions'
import APIError from '../../errors/APIError'
import { initialise } from '../common/commonActions'
import { userLogin } from '../auth/authActions'
import { unregister } from '../device/deviceSlice'
import { RegisterTeamResponse } from '../../types/gameApi/team'
import { logout } from '../auth/authSlice'
import { reset } from '../game/gameSlice'

interface TeamState {
  id: number | null
  registered: boolean
  registering: boolean
  error: Error | APIError | null
  name: string | null
  strategy: string | null
}

const initialState: TeamState = {
  id: null,
  registered: false,
  registering: false,
  error: null,
  name: null,
  strategy: null,
}

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    registerTeamSuccess: (
      state,
      action: PayloadAction<RegisterTeamResponse>,
    ) => {
      state.registered = true
      state.name = action.payload.name
      state.strategy = action.payload.strategy
      state.id = action.payload.teamId
    },
    registerTeamError: (
      state,
      action: PayloadAction<Error | APIError | null>,
    ) => {
      state.registered = true
      state.registering = false
      state.error = action.payload
    },
    teamChanged: (state, action: PayloadAction<number | null>) => {
      state.name = null
      state.strategy = null
      state.id = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(unregister, state => {
        state.registered = false
        state.name = null
        state.strategy = null
      })
      .addCase(reset, () => {
        return initialState
      })
      .addCase(logout, () => {
        return initialState
      })
      .addCase(initialise.fulfilled, (state, { payload }) => {
        state.id = payload.device.teamId
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.id = payload.device.teamId
      })
      .addCase(registerTeam.pending, state => {
        state.registering = true
        state.error = null
      })
      .addCase(registerTeam.fulfilled, (state, { payload }) => {
        state.registering = false
        state.registered = true
        state.name = payload.name
        state.strategy = payload.strategy
        state.id = payload.teamId
      })
      .addCase(registerTeam.rejected, (state, { payload }) => {
        state.registering = false
        state.error = payload ?? null
      })
  },
})

export const { teamChanged, registerTeamSuccess, registerTeamError } =
  teamSlice.actions

export default teamSlice.reducer
