import { DealroomResponse } from '../../../../../types/gameApi/dealroom'

export const data: DealroomResponse = {
  bid: 801,
  bidType: 1,
  id: 2,
  name: 'Home Loan Broker',
  description:
    'Customers are increasingly looking for independent advice when it comes to making financial decisions. Many customers feel that brokers provide good advice and are great at getting competitive mortgage rates. Mortgage brokers control ~45% of the mortgages written in the market and one large broker in particular has announced that it is now for sale. If your bank acquires this broker, your operations team have advised that some broker staff could be eliminated, providing some cost synergies.\nBig Buck’s management have stated that integration with a major bank will make it easier and faster to write that bank’s loans. Customers seeking a fast turn-around are more likely to go with the bank who acquires Big Buck Home Loans.',
  type: 'home-loan-broker',
  imageUrl:
    'https://test-images.banksim.co/deals/deal-8b7e5a06f0f40a4a656817d3ddc500cc.jpg',
  tsr: '10.55',
  argx: null,
  argy: null,
  assumptionOverrides: [],
  enabled: true,
}
