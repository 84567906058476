import { useMemo } from 'react'
import { useRoutes } from './useRoutes'

export const useIsFeatureEnabledForRound = (href: string): boolean => {
  const { routes } = useRoutes()
  return useMemo(() => {
    const tab = routes.find(r => r.href === href)
    return tab?.enabled ?? false
  }, [href, routes])
}
