import { useMemo } from 'react'
import { CostsDivision, CostsResponse } from '../../../types/gameApi/costs'
import { DecisionMap } from './CostsContainer'
import {
  FTECostPerDivision,
  ImpactValues,
  fteCostPerDivision,
  getImpacts,
} from './calculations'

export interface CostsData {
  divisions: CostsDivision[]

  totalFTEChange: number
  totalChangeInCost: number
  impactValues: ImpactValues
  peopleAndCostImpactPerDivision: FTECostPerDivision[]
}

export const useGetData = ({
  data,
  decisions,
}: {
  data: CostsResponse
  decisions: DecisionMap
}): CostsData => {
  return useMemo(() => {
    const { divisions } = data

    const peopleAndCostImpactPerDivision = fteCostPerDivision(data, decisions)
    const totalFTEChange = peopleAndCostImpactPerDivision.reduce(
      (res, d) => res + d.fteChange,
      0,
    )
    const totalChangeInCost = peopleAndCostImpactPerDivision.reduce(
      (res, d) => res + d.changeInCost,
      0,
    )
    const impactValues = getImpacts(data, decisions)
    return {
      divisions,
      impactValues,
      totalFTEChange,
      totalChangeInCost,
      peopleAndCostImpactPerDivision,
    }
  }, [data, decisions])
}
