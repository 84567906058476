import { ExcoResponse } from '../../../../../types/gameApi/exco'

export const data: ExcoResponse = {
  id: 11,
  name: 'Credit Cards Washed Away',
  description:
    'The regulator has recently changed some of the requirements relating to reissuing credit cards. Historically banks have issued credit cards to dormant credit card customers when their cards expire. The regulator now requires banks to send written notices to these customers informing them since they are not using their cards, the customer must provide consent (via branch) if they still want their account and a new card issued. A positive impact of closing dormant accounts is the release of regulatory capital held against the dormant facilities which will help improve returns to shareholders, however lower annual fee revenue may result. This year floods have been severe. Homes in many postcodes have been destroyed, and many of these dormant card customers may no longer be living in their homes. Which course of action should you take:',
  options: [
    {
      id: 1,
      title: 'Call Customers',
      description:
        'Stop sending the letters to these customers and instead call them. You might be able to have a needs based conversation offering support at the same time.',
    },
    {
      id: 2,
      title: 'Support Customers',
      description:
        'Given the difficult time, you feel customers in flood effected areas may need their credit cards more than ever. You renew their dormant credit cards without disturbing them.',
    },
    {
      id: 3,
      title: 'Send Letters',
      description:
        'Write to customers as planned, rather than create ad-hoc processes. You feel the post offices will likely be holding all mail for flood impacted customers at post offices rather than attempting to deliver it to their homes.',
    },
  ],
  decision: 3,
  imageUrl:
    'https://test-images.banksim.co/exco/exco-48aa3d3f2ad24797b873c6710f44f9d3.jpg',
}
