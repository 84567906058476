import React from 'react'
import PrimaryText from '../../../atoms/Text/PrimaryText'
import { titleCase } from '../../../../lib/text'
import { useTranslatedText } from '../../../../hooks/useTranslatedText'

interface LegendItemProps {
  color: string
  label: string
}

const LegendItem: React.FC<LegendItemProps> = ({ color, label }) => {
  return (
    <div className="flex h-full items-center gap-1">
      <div className={`h-3 w-3 ${color}`}></div>
      <div>{label}</div>
    </div>
  )
}

const Legend: React.FC = () => {
  const depositsLabel = titleCase(
    useTranslatedText('businessDecisions.products.DEPOSITS', 'deposits'),
  )
  return (
    <div className="">
      <PrimaryText size="xs">
        <div className="flex w-full flex-col gap-7 whitespace-nowrap pb-4">
          <LegendItem color="bg-blue-500" label="New Funding" />
          <LegendItem color="bg-green-500" label="Existing Funding" />
          <LegendItem color="bg-purple-500" label={depositsLabel} />
          <LegendItem color="bg-yellow-500" label="Lending Assets" />
          <LegendItem color="bg-pink-500" label="Cash" />
        </div>
      </PrimaryText>
    </div>
  )
}

export default Legend
