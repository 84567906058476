import React from 'react'
import { CustomLabelProps } from './types'

export const createBarChartTopLabel = (
  labelFormatter: (val: number) => string,
  isPreviousYear: boolean,
) => {
  const BarChartTopLabel: React.FC<CustomLabelProps> = ({
    x,
    y,
    width,
    height,
    value,
  }) => {
    const xToUse = Number(x) ?? 0
    const yToUse = Number(y) ?? 0
    const widthToUse = Number(width) ?? 0
    const heightToUse = Number(height) ?? 0
    if (value == null) {
      return null
    }
    return (
      <g>
        <text
          x={xToUse + widthToUse / 2}
          y={
            (value < 0 ? yToUse + heightToUse : yToUse) +
            (value < 0 ? -10 : value < 200 ? -15 : -5)
          }
          fill="#000"
          textAnchor="middle"
          fontWeight="bold"
          fontSize="0.8rem"
          id={isPreviousYear ? 'lastTopLabelValue' : undefined}
        >
          {labelFormatter(value)}
        </text>
      </g>
    )
  }
  return BarChartTopLabel
}
