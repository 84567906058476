import { createAsyncThunk } from '@reduxjs/toolkit'
import gameAPI from '../../services/gameApi'
import APIError from '../../errors/APIError'
import { RootState } from '../../store'

import {
  DealroomRequestParams,
  DealroomResponse,
} from '../../types/gameApi/dealroom'
import ThunkCancelledError from '../../errors/ThunkCancelledError'

export const fetchDealRoom = createAsyncThunk<
  { roundId: number; dealroom: DealroomResponse },
  DealroomRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'dealroom/fetch',
  async ({ teamId, roundId }, { rejectWithValue, getState, requestId }) => {
    const state = getState()
    try {
      if (state.dealroom.requestInProgress !== requestId) {
        throw new ThunkCancelledError()
      }
      const dealroom = await gameAPI.getDealRoom({ teamId, roundId })

      return { roundId, dealroom }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)

export const fetchDealRoomBackground = createAsyncThunk<
  { roundId: number; dealroom: DealroomResponse },
  DealroomRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'dealroom/fetchBackground',
  async ({ teamId, roundId }, { rejectWithValue, getState, requestId }) => {
    const state = getState()
    try {
      if (state.dealroom.requestInProgress !== requestId) {
        throw new ThunkCancelledError()
      }
      const dealroom = await gameAPI.getDealRoom({ teamId, roundId })

      return { roundId, dealroom }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)
