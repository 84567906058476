import { thousandSeparator } from '../../../../lib/formatters'
import { ProductType } from '../../../../types/gameApi/business'
import { ValueChangeInputType } from '../../../molecules/ValueChanger/ValueChangerWithInput'

export const getPrice1Change = (
  valueNow: number,
  valuePrev: number,
  type: ProductType,
) => {
  const change = valueNow - valuePrev
  if (change === 0) {
    return { value: '-', units: '' }
  }
  let prefix = change < 0 ? '' : '+'
  let changeString = String(change)
  if (type === 'CREDIT_CARDS') {
    if (change < 0) {
      changeString = change.toString().replace('-', '')
      prefix = '-$'
    } else {
      prefix = '+$'
    }
  }
  return {
    value: `${prefix}${changeString}`,
    units: type === 'CREDIT_CARDS' ? '' : 'bps',
  }
}

export const getPrice2Change = (
  valueNow: number,
  valuePrev: number,
  type: ProductType,
) => {
  if (type === 'BUSINESS_LOANS') {
    return {
      value: valueNow === 0 ? 'Medium' : valueNow < 0 ? 'Loose' : 'Tight',
      units: null,
    }
  }
  if (type === 'WEALTH_MANAGEMENT') {
    return {
      value: valueNow === 0 ? 'Medium' : valueNow < 0 ? 'Low' : 'High',
      units: null,
    }
  }
  let change = valueNow - valuePrev
  if (change === 0) {
    return { value: '-', units: '' }
  }
  let prefix = change < 0 ? '' : '+'
  if (type === 'OFFSHORE_BANK') {
    prefix = `${prefix}$`
    change = thousandSeparator(change)
  }
  let changeString
  if (type === 'CREDIT_CARDS') {
    changeString = (change / 100).toFixed(2)
  } else {
    changeString = change.toString()
  }
  return {
    value: `${prefix}${changeString}`,
    units: type === 'HOME_LOANS' ? 'bps' : '',
  }
}
export const getDataRanges = (
  valueNow: number,
  valuePrev: number,
  min: number,
  max: number,
) => {
  const actualMin = min - valuePrev
  const actualMax = max - valuePrev
  let actualValue = valueNow - valuePrev
  if (actualValue < actualMin) {
    actualValue = actualMin
  }
  if (actualValue > actualMax) {
    actualValue = actualMax
  }
  return {
    min: actualMin,
    max: actualMax,
    value: actualValue,
  }
}

export const formatPrice1 = (value: number, type: ProductType) => {
  if (type === 'CREDIT_CARDS') {
    return `$${thousandSeparator(value)}`
  }
  return `${(value / 100).toFixed(2)}%`
}

export const formatPrice2 = (value: number, type: ProductType) => {
  switch (type) {
    case 'HOME_LOANS': {
      return `${(value / 100).toFixed(2)}%`
    }
    case 'BUSINESS_LOANS': {
      return value === 0 ? 'Medium' : value < 0 ? 'Loose' : 'Tight'
    }
    case 'DEPOSITS':
    case 'INSTITUTIONAL_BANKING': {
      return value
    }
    case 'CREDIT_CARDS': {
      return (value / 100).toFixed(2)
    }
    case 'WEALTH_MANAGEMENT': {
      return value === 0 ? 'Medium' : value < 0 ? 'Low' : 'High'
    }
    case 'OFFSHORE_BANK': {
      return `$${thousandSeparator(value)}`
    }
  }
}

export const getPrice2ValueChangerType = (
  type: ProductType,
): ValueChangeInputType => {
  switch (type) {
    case 'HOME_LOANS': {
      return 'percentage'
    }
    case 'BUSINESS_LOANS':
    case 'DEPOSITS':
    case 'WEALTH_MANAGEMENT':
    case 'INSTITUTIONAL_BANKING': {
      return 'integer'
    }
    case 'CREDIT_CARDS': {
      return 'decimal'
    }
    case 'OFFSHORE_BANK': {
      return 'dollar'
    }
  }
}
