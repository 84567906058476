import { ProjectsResponse } from '../../../../../types/gameApi/projects'

export const data: ProjectsResponse = [
  {
    id: 2,
    name: 'Project Right-Size',
    type: 'project-right-size',
    usedInRound: null,
    areaOfFocus: 'Cost Reduction',
    cost: '$ 150m',
    ongoingCost: null,
    description:
      'If you can’t grow revenue, why not take out costs? Project Right-Size will aggressively roll out cost reduction targets across all teams within your entire bank. Every team will need to continue to deliver, but with less people. There will likely be a negative impact on employees and customers. Some critical functions might struggle with a smaller team size, ultimately causing an increase in operational risk capital and risk incidents.',
    impacts: [
      {
        id: 1,
        enabled: true,
        name: 'Customer',
        value: -2,
      },
      {
        id: 2,
        enabled: true,
        name: 'Revenue',
        value: 0,
      },
      {
        id: 3,
        enabled: true,
        name: 'Costs',
        value: 4,
      },
      {
        id: 4,
        enabled: true,
        name: 'Risk',
        value: -1,
      },
      {
        id: 5,
        enabled: true,
        name: 'Capital',
        value: -1,
      },
      {
        id: 6,
        enabled: true,
        name: 'Employee',
        value: -2,
      },
      {
        id: 7,
        enabled: true,
        name: 'Community',
        value: 0,
      },
    ],
    imageUrl:
      'https://test-images.banksim.co/projects/dde53634a321f3c10602389c6f242aba.jpg',
  },
  {
    id: 5,
    name: 'Project Switch',
    type: 'project-switch',
    usedInRound: null,
    areaOfFocus: 'Capital Efficiency',
    cost: '$ 225m',
    ongoingCost: null,
    description:
      'Do your customers pay on time? Are they low risk? Invest heavily in data assets and a team of modelling gurus to more accurately assess lending risk and upgrade your capital framework to “Advanced”. Reduced capital levels expected are: prop mortgages -5%, credit cards -25%, institutional banking -25%, but broker mortgages will increase 2.5%. Higher business loan quality will result in lower business loan capital.',
    impacts: [
      {
        id: 1,
        enabled: true,
        name: 'Customer',
        value: 0,
      },
      {
        id: 2,
        enabled: true,
        name: 'Revenue',
        value: 0,
      },
      {
        id: 3,
        enabled: true,
        name: 'Costs',
        value: 0,
      },
      {
        id: 4,
        enabled: true,
        name: 'Risk',
        value: 0,
      },
      {
        id: 5,
        enabled: true,
        name: 'Capital',
        value: 3,
      },
      {
        id: 6,
        enabled: true,
        name: 'Employee',
        value: 0,
      },
      {
        id: 7,
        enabled: true,
        name: 'Community',
        value: 0,
      },
    ],
    imageUrl:
      'https://test-images.banksim.co/projects/695ebb22ccf8badeab7a22f92d0fc48f.jpg',
  },
  {
    id: 7,
    name: 'Project Digital',
    type: 'project-digital',
    usedInRound: 3,
    areaOfFocus: 'Digital Strategy',
    cost: '$ 350m',
    ongoingCost: '$ 50m',
    description:
      'Everything is done online these days. Grocery shopping, ride sharing, and food delivery to name a few. Your online banking experience is good, but it could be much better. Investment in digital platforms will allow a much wider range of self-service through online and mobile channels. Customers will be able to do more banking when and where they want, reducing complaints. This will allow you to reduce operational costs in all retail businesses. Most immediate benefits will be delivered over 2 years.',
    impacts: [
      {
        id: 1,
        enabled: true,
        name: 'Customer',
        value: 2,
      },
      {
        id: 2,
        enabled: true,
        name: 'Revenue',
        value: 0,
      },
      {
        id: 3,
        enabled: true,
        name: 'Costs',
        value: 3,
      },
      {
        id: 4,
        enabled: true,
        name: 'Risk',
        value: 0,
      },
      {
        id: 5,
        enabled: true,
        name: 'Capital',
        value: 0,
      },
      {
        id: 6,
        enabled: true,
        name: 'Employee',
        value: 0,
      },
      {
        id: 7,
        enabled: true,
        name: 'Community',
        value: 0,
      },
    ],
    imageUrl:
      'https://test-images.banksim.co/projects/34514ce7ea0d4baaf283a0a41eaba08f.jpg',
  },
  {
    id: 9,
    name: 'Project Nitro',
    type: 'project-nitro',
    usedInRound: null,
    areaOfFocus: 'Marketing',
    cost: '$ 100m',
    ongoingCost: null,
    description:
      'Think your bank’s product is the best? Spend big and tell everyone about it! Project Nitro will see you invest heavily in online, print and physical media to heavily promote 1 targeted product for just 1 year. It’s unlikely there will be any enduring acquisition benefit beyond the year. Customers get overwhelmed with too much marketing, so try not to market your product at the same time as your competitors.',
    impacts: [
      {
        id: 1,
        enabled: true,
        name: 'Customer',
        value: 0,
      },
      {
        id: 2,
        enabled: true,
        name: 'Revenue',
        value: 5,
      },
      {
        id: 3,
        enabled: true,
        name: 'Costs',
        value: 0,
      },
      {
        id: 4,
        enabled: true,
        name: 'Risk',
        value: 0,
      },
      {
        id: 5,
        enabled: true,
        name: 'Capital',
        value: 0,
      },
      {
        id: 6,
        enabled: true,
        name: 'Employee',
        value: 0,
      },
      {
        id: 7,
        enabled: true,
        name: 'Community',
        value: 0,
      },
    ],
    imageUrl:
      'https://test-images.banksim.co/projects/1c0b25d2726935b4315a218a4ea83f44.jpg',
    businessId: null,
    options: [
      {
        label: 'HOME LOANS',
        value: 1,
      },
      {
        label: 'BUSINESS LOANS',
        value: 2,
      },
      {
        label: 'DEPOSITS',
        value: 3,
      },
      {
        label: 'CREDIT CARDS',
        value: 4,
      },
    ],
  },
  {
    id: 10,
    name: 'Project Big Data',
    type: 'project-big-data',
    usedInRound: 1,
    areaOfFocus: 'Big Data',
    cost: '$ 500m',
    ongoingCost: null,
    description:
      'It’s time to make the most of all that rich customer information you’ve been collecting. Surprise and delight customers by deeply understanding them and their financial habits. Better data will help identify incorrect customer charges. You’ll be able to tailor products to suit them personally and really treat customers as more than just an account number. Customer engagement is expected to deepen, complaints should reduce and customer satisfaction is expected to increase in every line of business. Most immediate benefits will be delivered evenly over 3 years as milestones are delivered.',
    impacts: [
      {
        id: 1,
        enabled: true,
        name: 'Customer',
        value: 4,
      },
      {
        id: 2,
        enabled: true,
        name: 'Revenue',
        value: 0,
      },
      {
        id: 3,
        enabled: true,
        name: 'Costs',
        value: 0,
      },
      {
        id: 4,
        enabled: true,
        name: 'Risk',
        value: 0,
      },
      {
        id: 5,
        enabled: true,
        name: 'Capital',
        value: 0,
      },
      {
        id: 6,
        enabled: true,
        name: 'Employee',
        value: 0,
      },
      {
        id: 7,
        enabled: true,
        name: 'Community',
        value: 0,
      },
    ],
    imageUrl:
      'https://test-images.banksim.co/projects/0ac5a994ad44f1128a46bd7252d6d8ca.jpg',
  },
  {
    id: 11,
    name: 'Project Care Bear',
    type: 'project-care-bear',
    usedInRound: 2,
    areaOfFocus: 'Customer Service',
    cost: '$ 300m',
    ongoingCost: '$ 50m',
    description:
      'Customers have increasingly high expectations of what exceptional customer service looks like. Project Care Bear is a long-term approach to customer service that will upskill all staff in areas like dispute resolution, building trust and rapport, and needs analysis. This will deeply embed a customer service mindset across the entire organisation for years to come and improve employee engagement. This program will require ongoing support and training costs but will deliver ongoing improvements to service and customer satisfaction. Most immediate benefits will be delivered within 2 years.',
    impacts: [
      {
        id: 1,
        enabled: true,
        name: 'Customer',
        value: 5,
      },
      {
        id: 2,
        enabled: true,
        name: 'Revenue',
        value: 0,
      },
      {
        id: 3,
        enabled: true,
        name: 'Costs',
        value: 0,
      },
      {
        id: 4,
        enabled: true,
        name: 'Risk',
        value: 0,
      },
      {
        id: 5,
        enabled: true,
        name: 'Capital',
        value: 0,
      },
      {
        id: 6,
        enabled: true,
        name: 'Employee',
        value: 2,
      },
      {
        id: 7,
        enabled: true,
        name: 'Community',
        value: 0,
      },
    ],
    imageUrl:
      'https://test-images.banksim.co/projects/31050e49deb318d42d49887d1c03cd06.jpg',
  },
  {
    id: 12,
    name: 'Project Tighten',
    type: 'project-tighten',
    usedInRound: null,
    areaOfFocus: 'Credit Risk Reduction',
    cost: '$ 200m',
    ongoingCost: null,
    description:
      'It’s time to review your lending practices and assess whether all customers can really make repayments on the loans we offer. Tighten your lending criteria going forward to avoid “at risk” customers taking on debt they’ll never be able to repay. A better-quality loan portfolio will mean lower loss rates. Turning more customers away will require difficult conversations causing a small negative impact on customer satisfaction levels and increase complaints.',
    impacts: [
      {
        id: 1,
        enabled: true,
        name: 'Customer',
        value: -1,
      },
      {
        id: 2,
        enabled: true,
        name: 'Revenue',
        value: -1,
      },
      {
        id: 3,
        enabled: true,
        name: 'Costs',
        value: 1,
      },
      {
        id: 4,
        enabled: true,
        name: 'Risk',
        value: 3,
      },
      {
        id: 5,
        enabled: true,
        name: 'Capital',
        value: 0,
      },
      {
        id: 6,
        enabled: true,
        name: 'Employee',
        value: 0,
      },
      {
        id: 7,
        enabled: true,
        name: 'Community',
        value: 0,
      },
    ],
    imageUrl:
      'https://test-images.banksim.co/projects/f68559b6b0f34e85ee031f943858efac.jpg',
  },
  {
    id: 13,
    name: 'Project Fix-It',
    type: 'project-fix-it',
    usedInRound: null,
    areaOfFocus: 'Operational Risk Reduction',
    cost: '$ 250m',
    ongoingCost: null,
    description:
      'Your bank is large, with thousands of people performing a large number of manual processes. It is unclear if everything is operating as it should be. Undertake a deep review of all processes to identify any issues. Maybe you’ll find issues, maybe you won’t. Expect to find pockets of customers who are on incorrect interest rates and fees, pro-actively remediating them will build trust with your customers and reduce outstanding complaints and risk incidents.',
    impacts: [
      {
        id: 1,
        enabled: true,
        name: 'Customer',
        value: 2,
      },
      {
        id: 2,
        enabled: true,
        name: 'Revenue',
        value: 0,
      },
      {
        id: 3,
        enabled: true,
        name: 'Costs',
        value: 0,
      },
      {
        id: 4,
        enabled: true,
        name: 'Risk',
        value: 3,
      },
      {
        id: 5,
        enabled: true,
        name: 'Capital',
        value: 2,
      },
      {
        id: 6,
        enabled: true,
        name: 'Employee',
        value: 0,
      },
      {
        id: 7,
        enabled: true,
        name: 'Community',
        value: 0,
      },
    ],
    imageUrl:
      'https://test-images.banksim.co/projects/5a2b3b61fcf0ef3a76c14676e1e3117d.jpg',
  },
  {
    id: 15,
    name: 'Project Sustain',
    type: 'project-sustain',
    usedInRound: null,
    areaOfFocus: 'Process & Controls',
    cost: '$ 150m',
    ongoingCost: null,
    description:
      'Engage a dedicated team of specialists who will accelerate the process of documenting key processes using best practice methodologies. The team will also suggest effective controls for deficiencies found based on their global industry experience. This project will reduce the number of new risk incidents in subsequent years. This team are able deliver this project with minimal disruption to existing employees.',
    impacts: [
      {
        id: 1,
        enabled: true,
        name: 'Customer',
        value: 0,
      },
      {
        id: 2,
        enabled: true,
        name: 'Revenue',
        value: 0,
      },
      {
        id: 3,
        enabled: true,
        name: 'Costs',
        value: 0,
      },
      {
        id: 4,
        enabled: true,
        name: 'Risk',
        value: 4,
      },
      {
        id: 5,
        enabled: true,
        name: 'Capital',
        value: 0,
      },
      {
        id: 6,
        enabled: true,
        name: 'Employee',
        value: 0,
      },
      {
        id: 7,
        enabled: true,
        name: 'Community',
        value: 0,
      },
    ],
    imageUrl:
      'https://test-images.banksim.co/projects/5cc6f9d07b74677c4149d49ffcdf3380.jpg',
  },
  {
    id: 16,
    name: 'Project Shield',
    type: 'project-shield',
    usedInRound: null,
    areaOfFocus: 'Fraud',
    cost: '$ 75m',
    ongoingCost: null,
    description:
      'Upgrade security measures to make it harder for criminals to use fraud to access existing customer accounts and take out products in their name. The enhanced security measures will reduce fraud loses and protect customers but will cause some added friction in the customer experience with the bank. You should expect a short-term increase in complaints as customers adjust to this change.',
    impacts: [
      {
        id: 1,
        enabled: true,
        name: 'Customer',
        value: 1,
      },
      {
        id: 2,
        enabled: true,
        name: 'Revenue',
        value: 0,
      },
      {
        id: 3,
        enabled: true,
        name: 'Costs',
        value: 2,
      },
      {
        id: 4,
        enabled: true,
        name: 'Risk',
        value: 2,
      },
      {
        id: 5,
        enabled: true,
        name: 'Capital',
        value: 0,
      },
      {
        id: 6,
        enabled: true,
        name: 'Employee',
        value: 0,
      },
      {
        id: 7,
        enabled: true,
        name: 'Community',
        value: 0,
      },
    ],
    imageUrl:
      'https://test-images.banksim.co/projects/305a2a2c3669f53df51c4cf28e11415d.jpg',
  },
  {
    id: 17,
    imageUrl:
      'https://test-images.banksim.co/projects/25bdcd078101ea92241050ad5e2f69a0.jpg',
    name: 'Project Cross-Sell',
    description:
      'Refine the customer engagement process and boost cross-sell. By implementing advanced data analytics, the program identifies customer needs based on their transaction history and current financial products. It then suggests personalized product recommendations to bank staff at critical interaction points. Additionally, the program includes the development of a cutting-edge unified customer profile interface that provides a holistic view of each customer’s financial standing, enabling more targeted and timely product suggestions. Training modules are integrated to equip staff with effective cross-selling techniques and tools, fostering a proactive approach to customer service. This will enhance customer satisfaction and overall product uptake, driving significant growth in customer lifetime value.',
    areaOfFocus: 'Revenue',
    cost: '$ 300m',
    usedInRound: null,
    ongoingCost: null,
    type: 'project-fusion',
    impacts: [
      {
        id: 1,
        enabled: true,
        name: 'Customer',
        value: 2,
      },
      {
        id: 2,
        enabled: true,
        name: 'Revenue',
        value: 3,
      },
      {
        id: 3,
        enabled: true,
        name: 'Costs',
        value: 0,
      },
      {
        id: 4,
        enabled: true,
        name: 'Risk',
        value: 0,
      },
      {
        id: 5,
        enabled: true,
        name: 'Capital',
        value: 0,
      },
      {
        id: 6,
        enabled: true,
        name: 'Employee',
        value: 2,
      },
      {
        id: 7,
        enabled: true,
        name: 'Community',
        value: 0,
      },
    ],
  },
]
