import { ContentResponse } from '../../../../../types/gameApi/content'

export const getContentData = (): ContentResponse => {
  return {
    general: {
      amlName: 'AUSTRAC',
      country: 'Australia',
      economy: 'vanilla_5_rounds',
      amlNameLong: 'Australian Transaction Reports and Analysis Centre',
      centralBank: 'RBA',
      currencyCode: 'AUD',
      referenceRate: 'BBSW',
      regulatorName: 'APRA',
      currencySymbol: '$',
      centralBankLong: 'Reserve Bank of Australia',
      regulatorNameLong: 'Australian Prudential Regulation Authority',
      competitionRegulatorName: 'ACCC',
      competitionRegulatorNameLong:
        'Australian CompetitionRegulator and Consumer Commission',
      denomination: 'AUD $M',
    },
    mainMenu: {
      excoButton: 'Exco Decision',
      newsButton: 'Breaking News',
      costsButton: 'Costs',
      summaryButton: 'Summary',
      dealRoomButton: 'Deal Room',
      decisionsLabel: 'Decisions',
      treasuryButton: 'Treasury',
      assessmentButton: 'Actions',
      businessesButton: 'Businesses',
      helpManualButton: 'Help Manual',
      informationLabel: 'Information',
      peerInsightsButton: 'Peer Insights',
      ourFinancialsButton: 'Our Financials',
      specialProjectsButton: 'Special Projects',
    },
    mainMenuOrder: {
      excoButton: 7,
      newsButton: 2,
      costsButton: 9,
      summaryButton: 1,
      dealRoomButton: 12,
      decisionsLabel: 6,
      treasuryButton: 11,
      assessmentButton: 13,
      businessesButton: 10,
      helpManualButton: 5,
      informationLabel: 0,
      peerInsightsButton: 3,
      ourFinancialsButton: 4,
      specialProjectsButton: 8,
    },
    summaryScreen: {},
    breakingNews: {},
    peerInsights: {},
    ourFinancials: {},
    businessDecisions: {
      products: {
        DEPOSITS: 'DEPOSITS',
        HOME_LOANS: 'HOME LOANS',
        CREDIT_CARDS: 'CREDIT CARDS',
        OFFSHORE_BANK: 'OFFSHORE BANK',
        BUSINESS_LOANS: 'BUSINESS LOANS',
        WEALTH_MANAGEMENT: 'WEALTH MANAGEMENT',
        INSTITUTIONAL_BANKING: 'INSTITUTIONAL BANKING',
      },
    },
    operatingCosts: {},
    specialProjects: {},
    dealRoom: {},
    treasury: {},
    team: {
      defaultStrategy: '',
      placeholderStrategy:
        'Some examples you might want to consider are:\n✅ To be consistently #1 in customer satisfaction.\n✅ To focus on doing the right thing by all our customers (not just most of them). \n✅ To balance margin and volume outcomes for stable organic growth. \n✅ To deliver stable and increasing shareholder returns.\n✅ To invest prudently for the long term.  ',
    },
  }
}
