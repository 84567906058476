import { SummaryResponse } from '../../../../../types/gameApi/summary'
import { data as round1Data } from './1'
import { data as round2Data } from './2'
import { data as round3Data } from './3'
import { data as round4Data } from './4'

const summaryMockData: { [roundId: number]: { summaries: SummaryResponse[] } } =
  {
    1: round1Data,
    2: round2Data,
    3: round3Data,
    4: round4Data,
  }

export const getSummaryData = (
  roundId: number,
): { summaries: SummaryResponse[] } => {
  if (summaryMockData[roundId]) {
    return summaryMockData[roundId]
  }
  throw new Error(`No mock summary data for round ${roundId}`)
}
