import { useMemo } from 'react'
import { translate } from './useTranslatedText'
import { useAppSelector } from '../store'

export const useMenuTranslations = () => {
  const { content } = useAppSelector(state => ({
    content: state.content,
  }))

  return useMemo(() => {
    return {
      summary: translate('mainMenu.summaryButton', content, 'Summary'),
      news: translate('mainMenu.newsButton', content, 'Breaking News'),
      peerInsights: translate(
        'mainMenu.peerInsightsButton',
        content,
        'Peer Insights',
      ),
      ourFinancials: translate(
        'mainMenu.ourFinancialsButton',
        content,
        'Our Financials',
      ),
      helpManual: translate(
        'mainMenu.helpManualButton',
        content,
        'Help Manual',
      ),
      costs: translate('mainMenu.costsButton', content, 'Opex'),
      specialProjects: translate(
        'mainMenu.specialProjectsButton',
        content,
        'Special Projects',
      ),
      dealRoom: translate('mainMenu.dealRoomButton', content, 'Deal Room'),
      treasury: translate('mainMenu.treasuryButton', content, 'Treasury'),
      exco: translate('mainMenu.excoButton', content, 'Exco Decisions'),
      businesses: translate('mainMenu.businessesButton', content, 'Businesses'),
      assessment: translate('mainMenu.assessmentButton', content, 'Assessment'),
    }
  }, [content])
}
