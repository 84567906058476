import React, { useCallback } from 'react'
import { Listbox } from '@headlessui/react'
// import { CheckIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import Spinner from '../Spinner/Spinner'
import Clickable from '../Clickable/Clickable'

interface Props {
  id: string
  title: string
  description?: string
  onClick: (val: string) => void
  onClickInProgress: boolean
}

const SelectMenuOption: React.FC<Props> = ({
  id,
  title,
  description,
  onClick,
  onClickInProgress,
}) => {
  const handleClick = useCallback(() => {
    if (!onClickInProgress) {
      onClick(id)
    }
  }, [id, onClick, onClickInProgress])

  return (
    <Clickable className="w-full" onClick={handleClick}>
      <Listbox.Option
        key={title}
        className={({ active }) =>
          classNames(
            active ? 'bg-primary text-white' : 'text-gray-900',
            'cursor-pointer select-none p-4 text-sm',
          )
        }
        value={title}
      >
        {({ active }) => (
          <div className="flex flex-col">
            <div className="flex justify-between">
              <p>{title}</p>
              {onClickInProgress && <Spinner />}
              {/* {selected ? (
                  <span className={active ? 'text-white' : 'text-primary'}>
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                ) : null} */}
            </div>
            {description && (
              <p
                className={classNames(
                  active ? 'text-primary-100' : 'text-gray-500',
                  'mt-2',
                )}
              >
                {description}
              </p>
            )}
          </div>
        )}
      </Listbox.Option>
    </Clickable>
  )
}

export default SelectMenuOption
