import classNames from 'classnames'
import React from 'react'
import { Spacing } from '../../../types/theme'

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  gap?: Spacing
  center?: boolean
  verticalCenter?: boolean
  between?: boolean
  fullWidth?: boolean
  around?: boolean
  right?: boolean
  reverseRow?: boolean
  fullHeight?: boolean
}

const HorizontalGroup: React.FC<Props> = ({
  children,
  className,
  gap = 0,
  center,
  verticalCenter,
  between,
  fullWidth,
  around,
  right,
  reverseRow,
  fullHeight,
  ...rest
}) => {
  const gapClass = `gap-x-${gap}`

  return (
    <div
      className={classNames('flex', className, gapClass, {
        'items-center': verticalCenter,
        'justify-center': center,
        'justify-between': between,
        'justify-end': right,
        'w-full': fullWidth,
        'justify-around': around,
        'flex-row-reverse': reverseRow,
        'h-full': fullHeight,
      })}
      {...rest}
    >
      {children}
    </div>
  )
}

export default HorizontalGroup
