import React from 'react'
import { FinancialsResponse } from '../../../../types/gameApi/financials'
import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'
import { formatPercentage, formatValue } from '../../../../lib/formatters'
import ValueChangeText from '../../../molecules/ValueChangeText/ValueChangeText'
import { FontSize, FontWeight, TextColour } from '../../../../types/theme'
import Table from '../../../atoms/Table/Table'
import TableHeader from '../../../atoms/Table/TableHeader'
import TableSection from '../../../atoms/Table/TableSection'
import TableRow from '../../../atoms/Table/TableRow'
import TableCell from '../../../atoms/Table/TableCell'
import TableHeaderCell from '../../../atoms/Table/TableHeaderCell'
import TableText from '../../../atoms/Table/TableText'
import ProfitAndLossTableTourWrapper from '../OurFinancialsTour/ProfitAndLossTourWrapper'

interface Props {
  financials: FinancialsResponse
  roundId: number
}

interface RowProps {
  translate: string
  defaultField: string
  value: string
  changeValue: number
  isCost?: boolean
  changeUnit?: string
  weight?: FontWeight
  colour?: TextColour
  size?: FontSize
  highlight?: boolean
}

const Row: React.FC<RowProps> = ({
  translate,
  defaultField,
  value,
  changeValue,
  isCost,
  changeUnit,
  weight,
  colour,
  highlight,
}) => (
  <TableRow noDivideX highlight={highlight}>
    <TableCell paddingY={1} noRightBorder className="w-3/5">
      <TranslatedText
        value={translate}
        default={defaultField}
        weight={weight}
        colour={colour}
        family="secondary"
        size="xs"
      />
    </TableCell>
    <TableCell
      paddingY={1}
      colour={!highlight ? 'orange' : undefined}
      className="w-1/5"
      align="center"
    >
      <TableText center weight={weight} colour={colour}>
        {value}
      </TableText>
    </TableCell>
    <TableCell paddingY={1} className="w-1/5" align="center">
      <ValueChangeText
        center
        removeArrow
        removePlusSign
        value={changeValue}
        dashIfZero
        dp={1}
        changeUnit={changeUnit ?? '%'}
        isCost={isCost}
        weight={weight}
        family="secondary"
        size="xs"
      />
    </TableCell>
  </TableRow>
)

const ProfitAndLossTable: React.FC<Props> = ({ financials, roundId }) => {
  const data = financials.groupProfitAndLoss

  return (
    <ProfitAndLossTableTourWrapper>
      {({ columnInfo, tableRef }) => (
        <Table style={{ position: 'relative' }} ref={tableRef}>
          <TableHeader noDivideX>
            <TableHeaderCell className="w-3/5" align="left">
              <TableText weight="bold">
                PROFIT & LOSS ({financials.denomination})
              </TableText>
            </TableHeaderCell>
            <TableHeaderCell ref={columnInfo[0].ref} className="w-1/5">
              <TranslatedText
                center
                value="general.year"
                default="YEAR"
                family="secondary"
                suffix={` ${roundId - 1}`}
                weight="bold"
                size="xs"
              />
            </TableHeaderCell>
            <TableHeaderCell ref={columnInfo[1].ref} className="w-1/5">
              <TranslatedText
                center
                value="general.change"
                default="CHANGE"
                family="secondary"
                weight="bold"
                size="xs"
              />
            </TableHeaderCell>
          </TableHeader>
          <TableSection>
            <Row
              translate="ourFinancials.groupProfitLoss.interestIncome"
              defaultField="Interest Income"
              value={formatValue(data.interestIncome)}
              changeValue={data.interestIncomeChange}
            />

            <Row
              translate="ourFinancials.groupProfitLoss.interestExpense"
              defaultField="Interest Expense"
              value={formatValue(data.interestExpense)}
              changeValue={data.interestExpenseChange}
              isCost
            />
          </TableSection>

          <TableSection>
            <Row
              translate="ourFinancials.groupProfitLoss.netInterestIncome"
              defaultField="Net Interest Income"
              value={formatValue(data.netInterestIncome)}
              changeValue={data.netInterestIncomeChange}
              weight="bold"
            />

            <Row
              translate="ourFinancials.groupProfitLoss.netInterestMargin"
              defaultField="Net Interest Margin %"
              value={formatPercentage(data.netInterestMargin)}
              changeValue={data.netInterestMarginChange}
              changeUnit="bps"
              weight="bold"
              colour="primary"
            />
            <Row
              translate="ourFinancials.groupProfitLoss.otherBankingIncome"
              defaultField="Other Banking Income"
              value={formatValue(data.otherBankingIncome)}
              changeValue={data.otherBankingIncomeChange}
            />
            {financials.fundsMgmtIncomeEnabled && (
              <Row
                translate="ourFinancials.groupProfitLoss.fundsIncome"
                defaultField="Funds Management Income"
                value={formatValue(data.fundsMgmtIncome)}
                changeValue={data.fundsMgmtIncomeChange}
              />
            )}
          </TableSection>

          <TableSection>
            <Row
              translate="ourFinancials.groupProfitLoss.totalIncome"
              defaultField="TOTAL INCOME"
              value={formatValue(data.totalIncome)}
              changeValue={data.totalIncomeChange}
              weight="bold"
            />
            {financials.dealRoomEnabled && (
              <Row
                translate="ourFinancials.groupProfitLoss.investmentExperience"
                defaultField="Investment Experience"
                value={formatValue(data.investmentExperience)}
                changeValue={data.investmentExperienceChange}
              />
            )}
          </TableSection>
          <TableSection>
            <Row
              translate="ourFinancials.groupProfitLoss.totalOperatingIncome"
              defaultField="TOTAL OPERATING INCOME"
              value={formatValue(data.totalOperatingIncome)}
              changeValue={data.totalOperatingIncomeChange}
              weight="bold"
            />
            <Row
              translate="ourFinancials.groupProfitLoss.operatingExpenses"
              defaultField="Operating Expenses"
              value={formatValue(data.opex)}
              changeValue={data.opexChange}
              isCost
            />
            {financials.specialProjectsEnabled && (
              <Row
                translate="ourFinancials.groupProfitLoss.specialProjects"
                defaultField="Special Projects"
                value={formatValue(data.specialProjects)}
                changeValue={data.specialProjectsChange}
                isCost
              />
            )}
          </TableSection>
          <TableSection>
            <Row
              translate="ourFinancials.groupProfitLoss.totalOperatingExpenses"
              defaultField="TOTAL OPERATING EXPENSES"
              value={formatValue(data.totalOperatingExpenses)}
              changeValue={data.totalOperatingExpensesChange}
              weight="bold"
              isCost
            />
            <Row
              translate="ourFinancials.groupProfitLoss.costToIncomeRatio"
              defaultField="Cost to Income Ratio"
              value={formatPercentage(data.costToIncomeRatio)}
              changeValue={data.costToIncomeRatioChange}
              changeUnit="bps"
              isCost
              weight="bold"
              colour="primary"
            />
            <Row
              translate="ourFinancials.groupProfitLoss.loanImpairmentExpense"
              defaultField="Loan Impairment Expense"
              value={formatValue(data.lie)}
              changeValue={data.lieChange}
              isCost
            />
            <Row
              translate="ourFinancials.groupProfitLoss.loanImpairmentRatio"
              defaultField="Loan Impairment Expense %"
              value={formatPercentage(data.liePercentage)}
              changeValue={data.liePercentageChange}
              changeUnit="bps"
              isCost
              weight="bold"
              colour="primary"
            />
          </TableSection>
          <TableSection>
            <Row
              translate="ourFinancials.groupProfitLoss.netProfitBeforeTax"
              defaultField="NET PROFIT BEFORE TAX"
              value={formatValue(data.netProfitBeforeTax)}
              changeValue={data.netProfitBeforeTaxChange}
            />
            <Row
              translate="ourFinancials.groupProfitLoss.taxExpense"
              defaultField="Tax Expense"
              value={formatValue(data.tax)}
              changeValue={data.taxChange}
              isCost
            />
          </TableSection>
          <TableSection>
            <Row
              translate="ourFinancials.groupProfitLoss.netProfitAfterTax"
              defaultField="NET PROFIT AFTER TAX"
              value={formatValue(data.netProfitAfterTax)}
              changeValue={data.netProfitAfterTaxChange}
              weight="bold"
              highlight
            />
          </TableSection>
        </Table>
      )}
    </ProfitAndLossTableTourWrapper>
  )
}

export default ProfitAndLossTable
