import { PeerInsightsDataValue } from '../../../../../types/gameApi/peerInsights'
import { LineChartData } from '../../../../organisms/Charts/LineChart/types'

export const mapHistoricalData = (
  data: PeerInsightsDataValue[],
  formatter?: (val: any) => number,
) => {
  const mappedData: Array<LineChartData> = new Array(data[0].history?.length)
    .fill(0)
    .map((_, i) => ({ name: `Round ${data[0].history?.[i]?.round}` }))

  for (let i = 0; i < mappedData.length; i++) {
    for (const team of data) {
      mappedData[i][`team${team.team}`] =
        (formatter
          ? formatter(team.history?.[i]?.value)
          : team.history?.[i]?.value) ?? 0
    }
  }
  return mappedData
}
