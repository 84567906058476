import React from 'react'
import { formatPercentage, percentage } from '../../../lib/formatters'
import { CreditRating } from '../../../types/gameApi/enums'
import {
  EconomicScenarioNewsReferenceRate,
  EconomicScenarioNewsWholesaleFundingCostYear,
} from '../../../types/gameApi/news'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import Table from '../../atoms/Table/Table'
import PrimaryText from '../../atoms/Text/PrimaryText'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import TableHeader from '../../atoms/Table/TableHeader'
import TableHeaderCell from '../../atoms/Table/TableHeaderCell'

import SecondaryText from '../../atoms/Text/SecondaryText'
import TableRow from '../../atoms/Table/TableRow'
import TableCell from '../../atoms/Table/TableCell'
import { useTranslatedText } from '../../../hooks/useTranslatedText'
import TableSection from '../../atoms/Table/TableSection'

interface RenderRowProps {
  rating: CreditRating
  wholesaleFundingCost: EconomicScenarioNewsWholesaleFundingCostYear[]
  colourClasses: Record<CreditRating, string[]>
  isSelected: boolean
}

const colourClasses: Record<CreditRating, string[]> = {
  AAA: [
    'bg-[#06A882]',
    'bg-[#2ABE9A]',
    'bg-[#67D0B6]',
    'bg-[#92DEA3]',
    'bg-[#C3E9A6]',
  ],
  AA: [
    'bg-[#2ABE9A]',
    'bg-[#67D0B6]',
    'bg-[#92DEA3]',
    'bg-[#C3E9A6]',
    'bg-[#FBF19B]',
  ],
  A: [
    'bg-[#67D0B6]',
    'bg-[#92DEA3]',
    'bg-[#C3E9A6]',
    'bg-[#FBF19B]',
    'bg-[#FFE089]',
  ],
  BBB: [
    'bg-[#92DEA3]',
    'bg-[#C3E9A6]',
    'bg-[#FBF19B]',
    'bg-[#FFE089]',
    'bg-[#FFBF85]',
  ],
  BB: [
    'bg-[#C3E9A6]',
    'bg-[#FBF19B]',
    'bg-[#FFE089]',
    'bg-[#FFBF85]',
    'bg-[#FF9876]',
  ],
  B: [
    'bg-[#FBF19B]',
    'bg-[#FFE089]',
    'bg-[#FFBF85]',
    'bg-[#FF9876]',
    'bg-[#FA6A50]',
  ],
}

const renderRow = ({
  rating,
  wholesaleFundingCost,
  colourClasses,
  isSelected,
}: RenderRowProps) => {
  const borderClass = isSelected ? 'border-2 border-primary ' : ''
  const bgClass = isSelected ? 'bg-primary text-white ' : 'bg-gray-200  '

  return (
    <TableRow noDivideX className={borderClass} key={rating}>
      <TableCell
        paddingY={1}
        align="center"
        className={`tablet:w-32 ${bgClass}`}
      >
        <SecondaryText weight="bold" center size="sm">
          {rating}
        </SecondaryText>
      </TableCell>
      {wholesaleFundingCost.map((cost, i) => (
        <TableCell
          paddingY={1}
          align="center"
          key={i}
          className={`px-1 ${colourClasses[rating][i]}`}
        >
          <SecondaryText center size="sm">
            {formatPercentage(cost[rating])}
          </SecondaryText>
        </TableCell>
      ))}
    </TableRow>
  )
}

interface Props {
  wholesaleFundingCost: EconomicScenarioNewsWholesaleFundingCostYear[]
  currentCreditRating: CreditRating
  referenceRate: EconomicScenarioNewsReferenceRate
  roundId: number
  defaultReferenceRateName?: string
}

const BreakingNewsWholesaleFunding: React.FC<Props> = ({
  wholesaleFundingCost,
  currentCreditRating,
  referenceRate,
  roundId,
  defaultReferenceRateName,
}) => {
  const referenceRateName = useTranslatedText(
    'general.referenceRate',
    defaultReferenceRateName ?? 'referenceRate',
  )

  return (
    <VerticalGroup full>
      <VerticalGroup data-test="wholesale-table" full>
        <PrimaryText paddingY={1} weight="bold" colour="primary" size="base">
          WHOLESALE FUNDING COSTS
        </PrimaryText>
        <VerticalGroup gap={2}>
          <HorizontalGroup
            id="wholesale-funding-reference-rate"
            className="h-5 w-fit border-l-8 border-orange bg-orange-100 p-0.5"
          >
            <HorizontalGroup verticalCenter gap={2}>
              <PrimaryText size="sm" weight="bold">
                {`Round ${roundId} ${referenceRateName}`}:
              </PrimaryText>
              <PrimaryText security="sm" weight="bold">
                {percentage(referenceRate.rateValue)} +
              </PrimaryText>
              <div className="h-4 w-fit border-l-2 border-orange opacity-50"></div>
              <PrimaryText weight="bold" size="sm">
                Change:
              </PrimaryText>
              <PrimaryText weight="bold">
                {referenceRate.rateChange > 0 ? '+' : ''}
                {percentage(referenceRate.rateChange)}
              </PrimaryText>
            </HorizontalGroup>
          </HorizontalGroup>
          <HorizontalGroup gap={1}></HorizontalGroup>
        </VerticalGroup>

        <Table className="flex-grow">
          <TableHeader noBorder noDivideX>
            <TableHeaderCell>
              <SecondaryText size="xs" whitespace="nowrap">
                Credit Spreads
              </SecondaryText>
            </TableHeaderCell>
            {wholesaleFundingCost.map((_, i) => (
              <TableHeaderCell key={i} className="py-1 px-1">
                <SecondaryText size="sm"> {i + 1} Year</SecondaryText>
              </TableHeaderCell>
            ))}
          </TableHeader>
          <TableSection>
            {Object.keys(colourClasses).map(rating =>
              renderRow({
                rating: rating as CreditRating,
                wholesaleFundingCost,
                colourClasses,
                isSelected: rating === currentCreditRating,
              }),
            )}
          </TableSection>
        </Table>
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default BreakingNewsWholesaleFunding
