import { SummaryResponse } from '../../../../../types/gameApi/summary'

export const data: { summaries: SummaryResponse[] } = {
  summaries: [
    {
      enabled: true,
      team: 1,
      type: 'NPS',
      value: {
        value: '57',
        suffix: 'NPS',
      },
      value_change: '+23',
      place: {
        value: '1',
        ordinal: 'st',
      },
      place_change: {
        value: 'No Change',
      },
      heading: 'Customer Satisfaction',
      helpHeading: 'What Is Customer Satisfaction?',
      helpBody:
        "\n\nCustomer satisfaction is a way to measure how loyal your customers are, and it's represented by the Net Promoter Score (NPS). The NPS can range from 100 (where all your customers highly recommend your business) to -100 (where none of your customers would recommend your business). Higher scores mean fewer customers leaving.\n\n",
      winningPercentage: 30,
    },
    {
      enabled: true,
      team: 1,
      type: 'Complaints',
      value: {
        value: '522',
        suffix: null,
      },
      value_change: '-63',
      place: {
        value: '3',
        ordinal: 'rd',
      },
      place_change: {
        value: 'No Change',
      },
      heading: 'Customer Complaints',
      helpHeading: 'Outstanding Customer Complaints?',
      helpBody:
        'Outstanding customer complaints are a measure of unhappy customers who have made complaints that remain unresolved. To reduce outstanding complaints, businesses can address the root causes of complaints or allocate more resources to resolving them.',
      winningPercentage: 30,
    },
    {
      enabled: true,
      team: 1,
      type: 'TSR',
      value: {
        value: '12.0',
        suffix: '%',
      },
      value_change: '+0.3%',
      place: {
        value: '1',
        ordinal: 'st',
      },
      place_change: {
        value: '+1',
        suffix: 'place',
      },
      heading: 'Shareholder Returns',
      helpHeading: 'What Is Shareholder Return?',
      helpBody:
        "\n\nTotal Shareholder Return (TSR) measures the financial performance of a company's shares over time by combining dividends and share price appreciation. It is expressed as an annual percentage and is calculated by dividing the shareholder's investment by the total return. In other words, it shows how much money a shareholder has made from owning shares in a company over a certain period, taking into account both capital gains and income received from dividends.\n\n",
      winningPercentage: 40,
    },
    {
      enabled: true,
      team: 1,
      type: 'EmployeeEngagement',
      value: {
        value: '63',
        suffix: '%',
      },
      value_change: '+4%',
      place: {
        value: '4',
        ordinal: 'th',
      },
      place_change: {
        value: '-1',
        suffix: 'place',
      },
      heading: 'Employee Engagement',
      helpHeading: 'What Is Employee Engagement?',
      helpBody:
        "\n\n\n\n\n\n\n\n\n\n\n\n\n\n \n \n\n  Employee\n  engagement refers to how much employees care about their jobs and the company\n  they work for, and how much effort they put into their work beyond what's\n  required of them. This is influenced by the type of work they do,\n  opportunities for growth and learning, and how fairly they are compensated.\n\n \n\n\n\n\n\n",
      winningPercentage: 0,
    },
    {
      enabled: true,
      team: 1,
      type: 'Community',
      value: {
        value: '58',
        suffix: '%',
      },
      value_change: '+5%',
      place: {
        value: '1',
        ordinal: 'st',
      },
      place_change: {
        value: 'No Change',
      },
      heading: 'Reputation Index',
      helpHeading: 'What Is The Reputation Index?',
      helpBody:
        "A Reputation Index is a gauge of how much trust the community has in a bank's reliability, honesty, effectiveness, and commitment to doing the right thing for its customers and communities. It reflects the bank's standing in the eyes of its stakeholders, including customers, shareholders, regulators, and the public.",
      winningPercentage: 0,
    },
    {
      enabled: true,
      team: 1,
      type: 'Rating',
      value: {
        value: 'A',
        suffix: null,
      },
      value_change: '+1 rating',
      place: {
        value: null,
        ordinal: null,
      },
      place_change: {},
      heading: 'Credit Rating',
      helpHeading: 'What Is Credit Rating?',
      helpBody:
        "\n\nA Credit Rating assesses a company's ability to pay back debts. In this simulation a bank's Credit Rating is based on its CET1 ratio. A higher rating (AAA to B) means lower borrowing costs for the bank.\n\n",
      winningPercentage: 0,
    },
    {
      enabled: false,
      team: 1,
      type: 'Customers',
      value: {
        value: '3.4',
        suffix: 'm',
      },
      value_change: '-0.1m',
      place: {
        value: '1',
        ordinal: 'st',
      },
      place_change: {
        value: 'No Change',
      },
      heading: 'Customers',
      helpHeading: 'What Are Customer Numbers?',
      helpBody:
        "\n\n\n\n\n\n\n\n\n\n\n\n\n\n \n \n\n  The\n  number of customers your bank serves at the end of each year is one measure\n  of a bank's growth. Since the number of customers in the market is finite,\n  attracting new customers often means getting them from other banks in the\n  market. Therefore, to increase your bank's customer base, you need to compete\n  with other banks and offer better services or benefits that would entice\n  customers to switch to your bank.\n\n \n\n\n\n\n\n",
      winningPercentage: 0,
    },
    {
      enabled: false,
      team: 1,
      type: 'LIE',
      value: {
        value: '0.54',
        suffix: '%',
      },
      value_change: '+0.33%',
      place: {
        value: '3',
        ordinal: 'rd',
      },
      place_change: {
        value: 'No Change',
      },
      heading: 'Loan Impairment',
      helpHeading: 'What Is Loan Impairment?',
      helpBody:
        '\n\nLoan Impairment Expense refers to the cost incurred by a lender when borrowers are unable to repay their loans. This cost is expressed as a percentage of the total amount of loans outstanding. The expense is likely to increase when lending to customers with poor creditworthiness or during periods of economic stress.\n\n',
      winningPercentage: 0,
    },
    {
      enabled: false,
      team: 1,
      type: 'RegulatorActions',
      value: {
        value: '0',
        suffix: null,
      },
      value_change: 'No Change',
      place: {
        value: '1',
        ordinal: 'st',
      },
      place_change: {
        value: 'No Change',
      },
      heading: 'Regulator Sanctions',
      helpHeading: 'What Are Regulator Sanctions?',
      helpBody:
        '\n\nRegulator Sanctions refer to actions taken by regulatory authorities against a bank due to misconduct or violation of laws. These actions may include imposing financial penalties along with other measures to ensure compliance with regulations and prevent such incidents from occurring in the future.\n\n',
      winningPercentage: 0,
    },
    {
      enabled: false,
      team: 1,
      type: 'RiskIncidents',
      value: {
        value: '534',
        suffix: null,
      },
      value_change: '-98',
      place: {
        value: '2',
        ordinal: 'nd',
      },
      place_change: {
        value: '-1',
        suffix: 'place',
      },
      heading: 'Risk Incidents',
      helpHeading: 'What Are Unresolved Risk Incidents?',
      helpBody:
        '\n\nUnresolved Risk Incidents are incidents that have been identified and recorded, but have not yet been resolved. To reduce the number of unresolved incidents, it is important to address the root causes of incidents and allocate sufficient resources to resolving them in a timely manner. By doing so, the number of outstanding incidents can be reduced, which can help to minimize potential risks and improve overall organizational performance.\n\n',
      winningPercentage: 0,
    },
    {
      enabled: false,
      team: 1,
      type: 'ROE',
      value: {
        value: '13.7',
        suffix: '%',
      },
      value_change: '-2.7%',
      place: {
        value: '4',
        ordinal: 'th',
      },
      place_change: {
        value: '+3',
        suffix: 'places',
      },
      heading: 'Return On Equity',
      helpHeading: 'What Is Return On Equity?',
      helpBody:
        "\n\nReturn On Equity (ROE) is a financial metric that indicates a company's profitability in relation to the amount of money its shareholders have invested. It represents the percentage of profit that a company generates per dollar of shareholder equity. ROE is an annual percentage that helps investors evaluate a company's ability to generate profits and make efficient use of its shareholders' funds.\n\n",
      winningPercentage: 0,
    },
  ],
}
