import React from 'react'

import { useAppSelector } from '../../../store'
import PrimaryText from '../../atoms/Text/PrimaryText'

export type FeatureKey =
  | 'insightsEnabled'
  | 'dealsEnabled'
  | 'excoEnabled'
  | 'specialProjectsEnabled'
  | 'operatingCostsEnabled'
  | 'surveyEnabled'

interface SidebarTourBeaconProps {
  feature: FeatureKey
}

const NewFeatureAlert: React.FC<SidebarTourBeaconProps> = ({ feature }) => {
  const { selectedRound, roundsSetup, gameId, dismissedInAppHelp } =
    useAppSelector(state => ({
      roundsSetup: state.game.roundsSetup,
      selectedRound: state.game.selectedRound,
      gameId: state.game.gameId,
      dismissedInAppHelp: state.inAppHelp.dismissedInAppHelp,
    }))

  const storageKey = `${gameId}:${feature}`
  const featureClicked = dismissedInAppHelp[storageKey]
  const firstRoundWithFeature = roundsSetup.find(round => round[feature])

  const isNewFeature =
    firstRoundWithFeature?.round === selectedRound &&
    selectedRound > 1 &&
    !featureClicked

  return isNewFeature ? (
    <PrimaryText size="xs" colour="primary" className="animate-pulse">
      new!
    </PrimaryText>
  ) : null
}
export default NewFeatureAlert
