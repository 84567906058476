import React, { useCallback, useState } from 'react'

import Page from '../../../atoms/Page/Page'
import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'

import { useTranslatedText } from '../../../../hooks/useTranslatedText'

import { registerTeam } from '../../../../redux/team/teamActions'
import { useAppDispatch, useAppSelector } from '../../../../store'
import InlineError from '../../../atoms/InlineError/InlineError'
import StrategyCard from '../StrategyCard'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import BrandedVerticalGroup from '../../../organisms/Branding/BrandedVerticalGroup/BrandedVerticalGroup'
import BrandedHorizontalGroup from '../../../organisms/Branding/BrandedHorizontalGroup/BrandedHorizontalGroup'
import BrandedButton from '../../../organisms/Branding/BrandedButton/BrandedButton'
import BrandedText from '../../../organisms/Branding/BrandedText/BrandedText'
import Modal from '../../../atoms/Modal/Modal'

export const strategyPlaceholder = `Example strategy:
1. To consistently be the #1 in customer satisfaction
2. To grow profitably by managing both margin and volume.
etc
`

interface Props {}

const TeamRegister: React.FC<Props> = () => {
  const defaultStrategy = useTranslatedText('team.defaultStrategy', '')
  const placeHolder = useTranslatedText(
    'team.placeholderStrategy',
    strategyPlaceholder,
  )
  const [name, setName] = useState('')
  const [strategy, setStrategy] = useState(defaultStrategy)
  const [showModal, setShowModal] = useState(false)

  const { registering, error, teamId } = useAppSelector(state => ({
    registered: state.team.registered,
    registering: state.team.registering,
    error: state.team.error,
    teamId: state.event.details?.team ?? 0,
  }))
  const dispatch = useAppDispatch()

  const onSubmit = useCallback(() => {
    setShowModal(true)
  }, [])

  const handleModalConfirm = () => {
    dispatch(registerTeam({ teamId, name, strategy }))
    setShowModal(false)
  }
  const handleModalCancel = () => {
    setShowModal(false)
  }

  const canSubmit = !registering && name.length > 0 && strategy.length > 0
  const teamBankName = useTranslatedText('team.bankName', 'Bank Name')
  const strategyLabel = useTranslatedText('team.strategyLabel', 'Strategy')
  const letsGoButtonLabel = useTranslatedText('team.startButton', "Let's Go")

  return (
    <BrandedVerticalGroup full className="flex-1">
      <Page>
        <VerticalGroup fullWidth full center>
          <VerticalGroup className="w-full pt-4 pb-5 tablet:max-w-2xl">
            {error && (
              <InlineError data-test="inline-error" message={error?.message} />
            )}
            <VerticalGroup gap={10}>
              <VerticalGroup gap={1}>
                <StrategyCard
                  heading={teamBankName}
                  themeColor="primary"
                  inputBox
                  cardHeight="fit"
                  onValueChange={setName}
                  icon="bank"
                  iconColour="primary"
                />
                <BrandedText center colourField="mainTextColour">
                  <TranslatedText
                    size="xs"
                    center
                    value="team.bankNameHelp"
                    default="Please give your bank a name of 8 characters or less"
                  />
                </BrandedText>
              </VerticalGroup>
              <VerticalGroup gap={1}>
                <StrategyCard
                  heading={strategyLabel}
                  strategy={strategy}
                  themeColor="orange"
                  textArea
                  placeholder={placeHolder}
                  onValueChange={setStrategy}
                  icon="idea"
                  iconColour="orange"
                />
                <BrandedText center colourField="mainTextColour">
                  <TranslatedText
                    size="xs"
                    center
                    value="team.strategyLabelHelp"
                    default="Please limit your strategy to 1000 characters"
                  />
                </BrandedText>
              </VerticalGroup>
            </VerticalGroup>
          </VerticalGroup>
          <BrandedHorizontalGroup
            verticalCenter
            center
            className="fixed bottom-0 z-10 h-20 w-full bg-gray-900"
          >
            <BrandedButton
              className="border border-white"
              onClick={onSubmit}
              disabled={!canSubmit}
            >
              {letsGoButtonLabel}
            </BrandedButton>
          </BrandedHorizontalGroup>
        </VerticalGroup>
        <div className="h-20"></div>
      </Page>
      <Modal
        show={showModal}
        title="Confirm Strategy"
        onOk={handleModalConfirm}
        onClose={handleModalCancel}
        okButtonText="Confirm"
        cancelButtonText="Cancel"
        onCancel={handleModalCancel}
      >
        Are you sure you want to commit to your strategy? It cannot be changed
        during the simulation.
      </Modal>
    </BrandedVerticalGroup>
  )
}

export default TeamRegister
