import React from 'react'
import { TreasuryResponse } from '../../../../types/gameApi/treasury'
import { DecisionMap } from '../TreasuryContainer'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import TreasuryWSFProfileManageFunding from './TreasuryWSFProfileManageFunding'
import TreasuryWSFProfileChart from './TreasuryWSFProfileChart'
import Legend from './Legend'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'

interface Props {
  data: TreasuryResponse
  decisions: DecisionMap
  enableChanges: boolean
  onChangeValue: (val: Partial<DecisionMap>) => void
}

const TreasuryWSFProfileDesktop: React.FC<Props> = props => {
  return (
    <div className="h-full w-full">
      <VerticalGroup full fullWidth>
        <TreasuryWSFProfileManageFunding {...props} />
        <HorizontalGroup verticalCenter className="h-full pl-10">
          <TreasuryWSFProfileChart {...props} />
          <div className="w-48">
            <Legend />
          </div>
        </HorizontalGroup>
      </VerticalGroup>
    </div>
  )
}

export default TreasuryWSFProfileDesktop
