import { TreasuryResponse } from '../../../../../types/gameApi/treasury'
import { data as round1Data } from './1'
import { data as round2Data } from './2'
import { data as round3Data } from './3'
import { data as round4Data } from './4'

const treasuryMockData: { [roundId: number]: TreasuryResponse } = {
  1: round1Data,
  2: round2Data,
  3: round3Data,
  4: round4Data,
}

export const getTreasuryData = (roundId: number): TreasuryResponse => {
  if (treasuryMockData[roundId]) {
    return treasuryMockData[roundId]
  }
  throw new Error(`No mock treasury data for round ${roundId}`)
}
