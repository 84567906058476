import { CostsResponse } from '../../../../../types/gameApi/costs'

export const data: CostsResponse = {
  totalPeopleCosts: 1678,
  defaultPayrise: 200,
  divisions: [
    {
      id: 1,
      name: 'IT & OPERATIONS',
      type: 'IT_OPERATIONS',
      enabled: true,
      maxValue: 25,
      minValue: -25,
      costSplit: 3500,
      complaints: -200,
      riskIncidents: -175,
      employeeEngagement: 0,
      customerSatisfaction: 300,
      fteCosts: 200,
      fteNewCosts: 408,
      value_change: 1300,
      value_change_original: 0,
    },
    {
      id: 2,
      name: 'FRONT LINE',
      type: 'FRONT_LINE',
      enabled: true,
      maxValue: 25,
      minValue: -25,
      costSplit: 4000,
      complaints: -200,
      riskIncidents: 0,
      employeeEngagement: 0,
      customerSatisfaction: 500,
      fteCosts: 100,
      fteNewCosts: 153,
      value_change: 1200,
      value_change_original: 0,
    },
    {
      id: 3,
      name: 'RISK & COMPLIANCE',
      type: 'RISK_COMPLIANCE',
      enabled: true,
      maxValue: 25,
      minValue: -25,
      costSplit: 1500,
      complaints: 0,
      riskIncidents: -250,
      employeeEngagement: -1000,
      customerSatisfaction: 0,
      fteCosts: 220,
      fteNewCosts: 1020,
      value_change: 1200,
      value_change_original: 0,
    },
    {
      id: 4,
      name: 'HR & TRAINING',
      type: 'HR_TRAINING',
      enabled: true,
      maxValue: 25,
      minValue: -25,
      costSplit: 1000,
      complaints: 0,
      riskIncidents: 0,
      employeeEngagement: 1500,
      customerSatisfaction: 0,
      fteCosts: 210,
      fteNewCosts: 1122,
      value_change: 1200,
      value_change_original: 0,
    },
    {
      id: 5,
      name: 'SALARY GROWTH',
      type: 'SALARY_GROWTH',
      enabled: true,
      maxValue: 10,
      minValue: -10,
      costSplit: 1,
      complaints: 0,
      riskIncidents: 0,
      employeeEngagement: 1,
      customerSatisfaction: 0,
      fteCosts: null,
      fteNewCosts: null,
      value_change: 200,
      value_change_original: 200,
    },
  ],
  npsChange: 0,
  npsOpening: 0,
  npsClosingScore: 600,
  employeeEngagementChange: 0,
  employeeEngagementOpening: 5000,
  employeeEngagementClosingScore: 5367,
  employeeEngagementSalaryIncrease: 1000,
  employeeEngagementSalaryDecrease: 2000,
  openingRoundEmployeeEngagement: 5000,
  outputGain: 700,
  outputLoss: 1200,
  complaintsChange: 300,
  complaintsOpening: 700,
  complaintsClosing: 699,
  openingRoundRiskIncidents: 750,
  riskIncidentsChange: 300,
  riskIncidentsOpening: 750,
  riskIncidentsClosing: 731,
  communityTrustChange: 0,
  communityTrustChangeSetup: 250,
  communityTrustChangeThreshold: 100,
  communityTrustOpening: 5000,
  communityTrustClosing: 5000,
  regulatorActions: 0,
  regulatorActionsOpening: 0,
  regulatorActionsClosing: 0,
  regulatorImpact: 1500,
  fteChangeDivision1: 294,
  fteChangeDivision2: 671,
  fteChangeDivision3: 114,
  fteChangeDivision4: 80,
  salaryChange: 0,
  version: 23,
  updatedBy: '6d36202b-5bb7-42a6-aec9-2d904e500ada',
}
