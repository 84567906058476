import React from 'react'
import { Project } from '../../../types/gameApi/projects'

import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import Page from '../../atoms/Page/Page'
import HtmlText from '../../atoms/Text/HtmlText'
import PrimaryText from '../../atoms/Text/PrimaryText'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import SpecialProjectImpact from './SpecialProjectImpact'
import Image from '../../atoms/Image/Image'
import { PROJECT_TYPE_DURATION } from './numberOfYears'
interface ProjectDetailsProps {
  project: Project
  demoEnabled: boolean
  demoEnabledImpacts: number[]
}

const ProjectDetails: React.FC<ProjectDetailsProps> = ({
  project,
  demoEnabled,
  demoEnabledImpacts,
}) => {
  const numberOfYears = PROJECT_TYPE_DURATION[project.type]
  return (
    <VerticalGroup fullWidth className="">
      <Image
        objectFit="cover"
        className="h-52 w-full monitor:h-96 monitor:object-top"
        src={project.imageUrl}
      />
      <Page>
        <VerticalGroup gap={2}>
          <div className="flex-row items-center justify-between gap-2 laptop:flex">
            <VerticalGroup id="tour-project-name">
              <PrimaryText transform="upper" size="xl" weight="bold">
                {project.name}
              </PrimaryText>
              <PrimaryText colour="gray" size="sm">
                <HorizontalGroup className="" verticalCenter gap={2}>
                  {project.areaOfFocus}
                  <HorizontalGroup className="border-gray bg-gray h-0.5 w-2 rotate-90 transform border border-solid border-opacity-0 bg-opacity-50"></HorizontalGroup>
                  {numberOfYears} Year{numberOfYears > 1 && 's'}
                </HorizontalGroup>
              </PrimaryText>
            </VerticalGroup>
            <HorizontalGroup
              className="laptop:flex-col laptop:items-end"
              gap={3}
              id="tour-project-costs"
            >
              <PrimaryText size="2xl" weight="bold">
                {project.cost}
              </PrimaryText>
              {!!project.ongoingCost && (
                <PrimaryText colour="gray">
                  +{project.ongoingCost} p.a
                </PrimaryText>
              )}
            </HorizontalGroup>
          </div>
          <HtmlText id="tour-project-description">
            {project.description}
          </HtmlText>
          <div id="tour-project-impacts">
            <PrimaryText
              paddingY={1}
              center
              transform="upper"
              weight="bold"
              size="lg"
            >
              Project Impacts
            </PrimaryText>
            <VerticalGroup gap={4}>
              <VerticalGroup
                gap={4}
                full
                center
                className="tablet:grid tablet:grid-cols-4 tablet:grid-rows-3 tablet:items-center monitor:gap-8"
              >
                {project.impacts
                  .filter(impact => {
                    if (demoEnabled) {
                      return demoEnabledImpacts.includes(impact.id)
                    }
                    return impact.enabled
                  })
                  .map((impact, i) => (
                    <SpecialProjectImpact key={i} impact={impact} />
                  ))}
              </VerticalGroup>
            </VerticalGroup>
          </div>
        </VerticalGroup>
      </Page>
    </VerticalGroup>
  )
}
export default ProjectDetails
