import { useMemo } from 'react'
import { MAX_MOBILE_WIDTH } from '../constants/screens'
import { useWindowSize } from './useWindowSize'
import { useAppSelector } from '../store'

export const useIsMobileLandscape = (): boolean => {
  const isTablet = useAppSelector(state => state.auth.isTablet)
  const windowSize = useWindowSize()
  return useMemo(() => {
    if (isTablet) {
      return false
    }
    const isHeightLessThanMaxMobileWidth =
      windowSize.height != null && windowSize.height <= MAX_MOBILE_WIDTH
    const isHeightLessThanWidth =
      windowSize.height != null &&
      windowSize.width != null &&
      windowSize.height < windowSize.width
    return isHeightLessThanMaxMobileWidth && isHeightLessThanWidth
  }, [windowSize, isTablet])
}
