import { createAsyncThunk } from '@reduxjs/toolkit'
import gameAPI from '../../services/gameApi'
import APIError from '../../errors/APIError'
import {
  RegisterTeamRequestParams,
  RegisterTeamResponse,
} from '../../types/gameApi/team'

export const registerTeam = createAsyncThunk<
  RegisterTeamResponse,
  RegisterTeamRequestParams,
  { rejectValue: Error | APIError }
>('team/register', async ({ teamId, name, strategy }, { rejectWithValue }) => {
  try {
    const response = await gameAPI.registerTeam({ teamId, name, strategy })

    return response
  } catch (error: any) {
    return rejectWithValue(error)
  }
})
