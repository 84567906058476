import { createSlice } from '@reduxjs/toolkit'
import { fetchEventDetails } from './eventActions'
import APIError from '../../errors/APIError'
import { ParticipantDetailsResponse } from '../../types/adminApi/events'
import { logout } from '../auth/authSlice'
import { initialise } from '../common/commonActions'
import { userLogin } from '../auth/authActions'
import { reset } from '../game/gameSlice'

interface EventState {
  isLoading: boolean
  isLoaded: boolean
  error: Error | APIError | null
  details: Omit<ParticipantDetailsResponse, 'token'> | null
}

const initialState: EventState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  details: null,
}

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(reset, () => {
        return initialState
      })
      .addCase(fetchEventDetails.pending, state => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchEventDetails.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isLoaded = true
        state.details = payload
      })
      .addCase(initialise.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isLoaded = true
        state.error = null
        state.details = payload.event
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isLoaded = true
        state.details = payload.event
      })
      .addCase(fetchEventDetails.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload ?? null
      })
      .addCase(logout, state => {
        state.isLoading = false
        state.isLoaded = false
        state.error = null
        state.details = null
      })
  },
})

export default eventSlice.reducer
