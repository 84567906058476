import React, { useCallback, useMemo } from 'react'
import InAppHelpContent from '../../organisms/InAppHelp/InAppHelpContent'
import Text from '../../atoms/Text/Text'
import GuidedTour, {
  GuidedTourStep,
} from '../../organisms/InAppHelp/GuidedTour'
import { useAppDispatch, useAppSelector } from '../../../store'
import { finishTour } from '../../../redux/guidedTours/guidedToursSlice'
import BulletPoint from '../../atoms/BulletPoint/BulletPoint'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import { useRoutes } from '../../../hooks/useRoutes'
import GuidedTourStartModal from '../../organisms/InAppHelp/GuidedTourStartModal'
import { useMenuTranslations } from '../../../hooks/useMenuTranslations'

const RoundOneInitialTour: React.FC = () => {
  const dispatch = useAppDispatch()
  const translations = useMenuTranslations()
  const { currentTour, eventId, round, dismissedInAppHelp, treasuryData } =
    useAppSelector(state => ({
      currentTour: state.guidedTours.currentTour,
      eventId: state.event.details?.eventId,
      round: state.game.currentRound,
      dismissedInAppHelp: state.inAppHelp.dismissedInAppHelp,
      treasuryData: state.treasury.data,
    }))
  const { routes } = useRoutes()

  const costsEnabled = routes.some(route => route.id === 'costs')
  const specialProjectsEnabled = routes.some(
    route => route.id === 'special-projects',
  )
  const isTreasuryAutomatic = treasuryData?.[round]?.wsfMethod === 3

  const steps: Array<Partial<GuidedTourStep>> = [
    {
      target: '#summary',
      content: (
        <InAppHelpContent
          headingIcon="summary"
          heading={translations.summary}
          spendTime="1-2 mins"
        >
          <Text left>
            {
              'All banks start with the same metrics. Aim to improve the metrics marked with a 🏆 symbol to outperform other banks.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#breaking-news',
      content: (
        <InAppHelpContent
          headingIcon="news"
          spendTime="3-4 mins"
          heading={translations.news}
        >
          <VerticalGroup gap={2}>
            <Text left>
              {
                'As a team, review the breaking news together. Discuss and understand the key points:'
              }
            </Text>
            <BulletPoint>{'What risks are we facing this year?'}</BulletPoint>
            <BulletPoint>{'What opportunities can we capture?'}</BulletPoint>
            <BulletPoint>
              {'Which products are experiencing the most growth?'}
            </BulletPoint>
            <BulletPoint>
              {'Are there any products where we anticipate significant losses?'}
            </BulletPoint>
            <BulletPoint>
              {
                'Compare the wholesale funding costs to our customer interest rates.'
              }
            </BulletPoint>
          </VerticalGroup>
        </InAppHelpContent>
      ),
    },
    {
      target: '#our-financials',
      content: (
        <InAppHelpContent
          headingIcon="financials"
          heading={translations.ourFinancials}
        >
          <Text left>
            {
              'Feel free to skip this screen and return later if you need information to inform your decisions.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    ...(specialProjectsEnabled
      ? [
          {
            target: '#special-projects',
            content: (
              <InAppHelpContent
                headingIcon="projects"
                spendTime="10 mins"
                heading={translations.specialProjects}
              >
                <Text left>
                  {
                    'Review these investment opportunities with your team. Discuss which projects best align with your strategy and could significantly improve your summary "winning" metrics.'
                  }
                </Text>
              </InAppHelpContent>
            ),
          },
        ]
      : []),
    ...(costsEnabled
      ? [
          {
            target: '#costs',
            content: (
              <InAppHelpContent
                headingIcon="cost"
                heading={translations.costs}
                spendTime="10 mins"
              >
                <Text left>
                  {
                    "Increase investment and resource allocation in each division with your team until your bank's issues and summary 'winning' metrics have begun to improve."
                  }
                </Text>
              </InAppHelpContent>
            ),
          },
        ]
      : []),
    {
      target: '#businesses',
      placement: 'top',
      content: (
        <InAppHelpContent
          headingIcon="businesses"
          spendTime="10 mins"
          tip={`Remember to look at the ${translations.news} to understand which products have the most growth.`}
          heading={translations.businesses}
        >
          <Text left>
            {
              'Discuss with your team whether any pricing changes are required. Focusing on your lending products first will allow you to better understand how much funding you need. (e.g aggressive home loan growth will require more deposits or wholesale funding or both.)'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    ...(!isTreasuryAutomatic
      ? [
          {
            target: '#treasury',
            placement: 'top' as const,
            content: (
              <InAppHelpContent
                headingIcon="treasury"
                heading={translations.treasury}
                spendTime="5 mins"
              >
                <Text left>
                  {
                    'Raise enough wholesale funding from the markets to support your lending commitments. Remember to maintain enough cash on hand—aim to keep about 10% of your deposits in cash.'
                  }
                </Text>
              </InAppHelpContent>
            ),
          },
        ]
      : []),
  ]

  const handleCloseTour = useCallback(() => {
    dispatch(finishTour())
  }, [dispatch])

  const shouldShowModal = useMemo(() => {
    return round === 1 && !!dismissedInAppHelp[`${eventId}-clock-help`]
  }, [dismissedInAppHelp, eventId, round])

  if (currentTour !== 'round-one-initial') {
    return (
      <GuidedTourStartModal
        tour="round-one-initial"
        storageKey="round-one-initial-modal"
        show={shouldShowModal}
        heading="Quick Tips?"
        description="Need tips for getting started in this first round?"
      />
    )
  }

  return <GuidedTour disableScrolling steps={steps} onClose={handleCloseTour} />
}

export default RoundOneInitialTour
