import React, { useCallback } from 'react'
import { TreasuryResponse } from '../../../../types/gameApi/treasury'
import { DecisionMap } from '../TreasuryContainer'
import HideIfMobileLandscape from '../../../atoms/HideIfMobile/HideIfMobileLandscape'
import TreasuryWSFProfileDesktop from './TreasuryWSFProfileDesktop'
import TreasuryWSFProfileMobile from './TreasuryWSFProfileMobile'

interface Props {
  data: TreasuryResponse
  decisions: DecisionMap
  enableChanges: boolean
  onChangeValue: (val: Partial<DecisionMap>) => void
  onCloseMobile: () => void
}

const TreasuryWSFProfile: React.FC<Props> = ({ onCloseMobile, ...props }) => {
  const handleCloseMobile = useCallback(() => {
    onCloseMobile()
  }, [onCloseMobile])

  return (
    <>
      <HideIfMobileLandscape>
        <TreasuryWSFProfileDesktop {...props} />
      </HideIfMobileLandscape>

      <TreasuryWSFProfileMobile handleClose={handleCloseMobile} {...props} />
    </>
  )
}

export default TreasuryWSFProfile
