import { colours } from '../../../../constants/colours'
import {
  Business,
  BusinessHistoryResponse,
} from '../../../../types/gameApi/business'
import { createBarChartTopLabel } from '../../../organisms/Charts/BarChart/BarChartTopLabel'
import { BarChartData } from '../../../organisms/Charts/BarChart/types'
import { getYAxisDataV2 } from '../../../organisms/Charts/yAxis'

const getMinValueForYAxis = (
  sliderMin: number,
  values: Array<{ value: number }>,
  buffer = 25,
) => {
  const minBarValue = Math.min(...values.map(v => v.value))
  let minVal = minBarValue
  if (sliderMin != null) {
    minVal = Math.min(minBarValue, sliderMin)
  }
  return Math.max(minVal - buffer, 0)
}

export const getChartData = ({
  selectedItem,
  field,
  currentValue,
  labelFormatter,
  chartMin,
}: {
  selectedItem: Business
  field: keyof BusinessHistoryResponse
  currentValue: number
  labelFormatter: (val: number) => string
  chartMin: number
}): {
  minValue: number
  maxValue: number
  numberOfTicks: number
  data: BarChartData[]
} => {
  const thisYear = selectedItem.history.length

  const yearResults = selectedItem.history.map(h => {
    const result: BarChartData = {
      name: `Year ${h.year}`,
      value: h[field],
      // [`year${thisYear}`]: null,
      key: `year${h.year}`,
      fill: colours.primary,
      topLabel: createBarChartTopLabel(labelFormatter, true),
    }
    // for (const history of selectedItem.history) {
    //   if (history.year !== h.year) {
    //     result[`year${history.year}`] = null
    //   }
    // }
    return result
  })

  const thisYearResult: BarChartData = {
    name: `Year ${thisYear}`,
    value: currentValue,
    key: `year${thisYear}`,
    fill: colours.orange,
    topLabel: createBarChartTopLabel(labelFormatter, false),
  }

  // for (const history of selectedItem?.history ?? []) {
  //   thisYearResult[`year${history.year}`] = null
  // }

  yearResults.push(thisYearResult)

  const data = yearResults.map(h => ({ name: 'dddd', value: Number(h.value) }))
  const minToUse = getMinValueForYAxis(chartMin, data)

  const { minValue, maxValue, numberOfTicks } = getYAxisDataV2({
    pastRound1: true,
    min: minToUse,
    data: [{ name: 'fake0', value: minToUse }].concat(data),
  })

  return { minValue, maxValue, numberOfTicks, data: yearResults }
}
