import MockGameAPI from './demo/gameApi/gameApi'

import { get, put, doDelete, post } from '../lib/request'
import {
  AssessmentGetAnswersRequest,
  AssessmentGetAnswersResponse,
  AssessmentRequest,
  AssessmentResponse,
  SubmitAnswerForQuestionRequest,
  SubmitAnswerForQuestionResponse,
} from '../types/gameApi/assessments'
import {
  BusinessRequestParams,
  BusinessResponse,
  BusinessUpdateRequestParams,
  BusinessUpdateResponse,
} from '../types/gameApi/business'
import {
  AcceptEOTransferRequestParams,
  AcceptEOTransferResponse,
  CancelEOTransferRequestParams,
  CancelEOTransferResponse,
  CreateCEOTransferRequestParams,
  CreateCEOTransferResponse,
  GetCEOTransferRequestParams,
  GetCEOTransferResponse,
} from '../types/gameApi/ceoTransfer'
import { ContentResponse } from '../types/gameApi/content'
import {
  CostsRequestParams,
  CostsResponse,
  CostsUpdateRequestParams,
  CostsUpdateResponse,
} from '../types/gameApi/costs'
import {
  DealroomRequestParams,
  DealroomResponse,
  DealroomUpdateRequestParams,
} from '../types/gameApi/dealroom'
import {
  DeviceResponse,
  DeviceStateParams,
  DeviceStatusResponse,
  RegisterDeviceParams,
} from '../types/gameApi/device'
import {
  ExcoRequestParams,
  ExcoResponse,
  ExcoSubmitRequestParams,
  ExcoSubmitResponse,
} from '../types/gameApi/exco'
import {
  FinancialsRequestParams,
  FinancialsResponse,
} from '../types/gameApi/financials'
import {
  PeerInsightsRequestParams,
  PeerInsightsResponse,
} from '../types/gameApi/peerInsights'
import {
  ProjectDeleteRequestParams,
  ProjectDeleteResponse,
  ProjectsRequestParams,
  ProjectsResponse,
  ProjectUpdateRequestParams,
  ProjectUpdateResponse,
} from '../types/gameApi/projects'
import { SummaryRequestParams, SummaryResponse } from '../types/gameApi/summary'
import {
  RegisterTeamRequestParams,
  RegisterTeamResponse,
  SubmitRoundRequestParams,
  SubmitRoundResponse,
} from '../types/gameApi/team'
import {
  TreasuryRequestParams,
  TreasuryResponse,
  TreasuryUpdateRequestParams,
  TreasuryUpdateResponse,
} from '../types/gameApi/treasury'
import { EconomicScenarioNewsResponse } from '../types/gameApi/news'

const HOST = process.env.REACT_APP_GAME_API_URL?.includes('localhost')
  ? process.env.REACT_APP_GAME_API_URL.replace(
      'localhost',
      process.env.REACT_APP_LOCAL_IP ?? 'localhost',
    )
  : process.env.REACT_APP_GAME_API_URL

class GameAPI {
  token: string | null
  gameId: string | null

  constructor() {
    this.token = null
    this.gameId = null
  }

  headers = () => ({
    Authorization: `Bearer ${this.token}`,
  })

  setToken = (token: string | null) => {
    this.token = token
  }

  setGameId = (gameId: string | null) => {
    this.gameId = gameId
  }

  getDevice = async (deviceId: string) => {
    const response = await get<DeviceResponse>({
      url: `${HOST}/api/games/${this.gameId}/setup/device/${deviceId}`,
      headers: this.headers(),
    })
    return response.data
  }

  getContent = async () => {
    const response = await get<ContentResponse>({
      url: `${HOST}/api/games/${this.gameId}/content`,
      headers: this.headers(),
    })
    return response.data
  }

  registerDevice = async ({
    deviceId,
    team,
    isMaster,
  }: RegisterDeviceParams) => {
    const response = await put<DeviceResponse>({
      url: `${HOST}/api/games/${this.gameId}/setup/device`,
      body: {
        deviceId,
        teamId: team,
        isMaster,
        sideBarFontSize: 'm',
        batteryStatus: 50,
      },
      headers: this.headers(),
    })

    return response.data
  }

  getDeviceStatus = async ({ deviceId, isTablet }: DeviceStateParams) => {
    const response = await get<DeviceStatusResponse>({
      url: `${HOST}/api/games/${this.gameId}/setup/device/${deviceId}/status?batteryStatus=50&isTablet=${isTablet}`,
      headers: this.headers(),
    })
    return response.data
  }

  getSummary = async ({ teamId, roundId }: SummaryRequestParams) => {
    const response = await get<{ summaries: SummaryResponse[] }>({
      url: `${HOST}/api/games/${this.gameId}/teams/${teamId}/rounds/${roundId}/summary`,
      headers: this.headers(),
    })
    return response.data
  }

  getFinancials = async ({ teamId, roundId }: FinancialsRequestParams) => {
    const response = await get<FinancialsResponse>({
      url: `${HOST}/api/games/${this.gameId}/teams/${teamId}/rounds/${roundId}/financials`,
      headers: this.headers(),
    })
    return response.data
  }

  getExco = async ({ teamId, roundId }: ExcoRequestParams) => {
    const response = await get<ExcoResponse>({
      url: `${HOST}/api/games/${this.gameId}/teams/${teamId}/rounds/${roundId}/exco`,
      headers: this.headers(),
    })
    return response.data
  }

  submitExco = async ({
    teamId,
    roundId,
    decision,
  }: ExcoSubmitRequestParams) => {
    const response = await put<ExcoSubmitResponse>({
      url: `${HOST}/api/games/${this.gameId}/teams/${teamId}/rounds/${roundId}/exco`,
      headers: this.headers(),
      body: { decision },
    })
    return response.data
  }

  getBusinesses = async ({ teamId, roundId }: BusinessRequestParams) => {
    const response = await get<BusinessResponse>({
      url: `${HOST}/api/games/${this.gameId}/teams/${teamId}/rounds/${roundId}/businesses`,
      headers: this.headers(),
    })
    return response.data
  }

  updateBusinessDecisions = async ({
    teamId,
    roundId,
    decisions,
    deviceId,
  }: BusinessUpdateRequestParams) => {
    const response = await put<BusinessUpdateResponse>({
      url: `${HOST}/api/games/${this.gameId}/teams/${teamId}/rounds/${roundId}/businesses`,
      body: { decisions, deviceId },
      headers: this.headers(),
    })
    return response.data
  }

  getTreasury = async ({ teamId, roundId }: TreasuryRequestParams) => {
    const response = await get<TreasuryResponse>({
      url: `${HOST}/api/games/${this.gameId}/teams/${teamId}/rounds/${roundId}/treasury`,
      headers: this.headers(),
    })
    return response.data
  }

  updateTreasuryDecisions = async ({
    teamId,
    roundId,
    decisions,
    deviceId,
  }: TreasuryUpdateRequestParams) => {
    const response = await put<TreasuryUpdateResponse>({
      url: `${HOST}/api/games/${this.gameId}/teams/${teamId}/rounds/${roundId}/treasury`,
      body: { decisions, deviceId },
      headers: this.headers(),
    })
    return response.data
  }

  getCosts = async ({ teamId, roundId }: CostsRequestParams) => {
    const response = await get<CostsResponse>({
      url: `${HOST}/api/games/${this.gameId}/teams/${teamId}/rounds/${roundId}/opex`,
      headers: this.headers(),
    })
    return response.data
  }

  updateCostsDecisions = async ({
    teamId,
    roundId,
    decisions,
    deviceId,
  }: CostsUpdateRequestParams) => {
    const response = await put<CostsUpdateResponse>({
      url: `${HOST}/api/games/${this.gameId}/teams/${teamId}/rounds/${roundId}/opex`,
      body: { decisions, deviceId },
      headers: this.headers(),
    })
    return response.data
  }

  getProjects = async ({ teamId, roundId }: ProjectsRequestParams) => {
    const response = await get<ProjectsResponse>({
      url: `${HOST}/api/games/${this.gameId}/teams/${teamId}/rounds/${roundId}/projects`,
      headers: this.headers(),
    })
    return response.data
  }

  updateProjectDecision = async ({
    teamId,
    roundId,
    projectId,
    businessId,
  }: ProjectUpdateRequestParams) => {
    const response = await put<ProjectUpdateResponse>({
      url: `${HOST}/api/games/${this.gameId}/teams/${teamId}/rounds/${roundId}/projects`,
      body: { projectId, businessId },
      headers: this.headers(),
    })
    return response.data
  }

  deleteProjectDecision = async ({
    teamId,
    roundId,
  }: ProjectDeleteRequestParams) => {
    const response = await doDelete<ProjectDeleteResponse>({
      url: `${HOST}/api/games/${this.gameId}/teams/${teamId}/rounds/${roundId}/projects`,
      headers: this.headers(),
    })
    return response.data
  }

  getPeerInsights = async ({ teamId, roundId }: PeerInsightsRequestParams) => {
    const response = await get<PeerInsightsResponse>({
      url: `${HOST}/api/games/${this.gameId}/teams/${teamId}/rounds/${roundId}/peer-insights`,
      headers: this.headers(),
    })
    return response.data
  }

  registerTeam = async ({
    teamId,
    name,
    strategy,
  }: RegisterTeamRequestParams) => {
    const response = await put<RegisterTeamResponse>({
      url: `${HOST}/api/games/${this.gameId}/setup/team`,
      headers: this.headers(),
      body: { teamId, name, strategy },
    })
    return response.data
  }

  submitRound = async ({ teamId, roundId }: SubmitRoundRequestParams) => {
    const response = await put<SubmitRoundResponse>({
      url: `${HOST}/api/games/${this.gameId}/rounds/${roundId}/teams/${teamId}/submit`,
      headers: this.headers(),
      body: {},
    })
    return response.data
  }

  getDealRoom = async ({ teamId, roundId }: DealroomRequestParams) => {
    const response = await get<DealroomResponse>({
      url: `${HOST}/api/games/${this.gameId}/teams/${teamId}/rounds/${roundId}/deal-room`,
      headers: this.headers(),
    })
    return response.data
  }

  bidOnDeal = async ({
    teamId,
    roundId,
    bid,
    argx,
    argy,
    enabled,
    assumptionOverrides,
  }: DealroomUpdateRequestParams) => {
    const response = await put<unknown>({
      url: `${HOST}/api/games/${this.gameId}/teams/${teamId}/rounds/${roundId}/deals`,
      body: { bid, argx, argy, enabled, assumptionOverrides },
      headers: this.headers(),
    })
    return response.data
  }

  getAssessment = async ({ roundId }: AssessmentRequest) => {
    const response = await get<AssessmentResponse>({
      url: `${HOST}/api/games/${this.gameId}/rounds/${roundId}/assessment`,
      headers: this.headers(),
    })

    return response.data
  }

  submitAnswerForQuestion = async ({
    gameId,
    roundId,
    questionId,
    body,
  }: SubmitAnswerForQuestionRequest) => {
    const response = await post<SubmitAnswerForQuestionResponse>({
      url: `${HOST}/api/games/${gameId}/rounds/${roundId}/assessment/question/${questionId}`,
      headers: this.headers(),
      body,
    })

    return response.data
  }

  getCurrentAnwers = async ({ roundId }: AssessmentGetAnswersRequest) => {
    const response = await get<AssessmentGetAnswersResponse>({
      url: `${HOST}/api/games/${this.gameId}/rounds/${roundId}/answers`,
      headers: this.headers(),
    })

    return response.data
  }

  createCEOTransferRequest = async ({
    participantId,
    to,
  }: CreateCEOTransferRequestParams) => {
    const response = await post<CreateCEOTransferResponse>({
      url: `${HOST}/api/games/${this.gameId}/device/${participantId}/ceotransfer`,
      body: { to },
      headers: this.headers(),
    })
    return response.data
  }

  acceptDeclineCEOTransferRequest = async ({
    participantId,
    requestId,
    action,
  }: AcceptEOTransferRequestParams) => {
    const response = await put<AcceptEOTransferResponse>({
      url: `${HOST}/api/games/${this.gameId}/device/${participantId}/ceotransfer/${requestId}`,
      body: { action },
      headers: this.headers(),
    })
    return response.data
  }

  getCEOTransferRequest = async ({
    participantId,
    requestId,
  }: GetCEOTransferRequestParams) => {
    const response = await get<GetCEOTransferResponse>({
      url: `${HOST}/api/games/${this.gameId}/device/${participantId}/ceotransfer/${requestId}`,
      headers: this.headers(),
    })

    return response.data
  }

  cancelCEOTransferRequest = async ({
    participantId,
    requestId,
  }: CancelEOTransferRequestParams) => {
    const response = await doDelete<CancelEOTransferResponse>({
      url: `${HOST}/api/games/${this.gameId}/device/${participantId}/ceotransfer/${requestId}`,
      headers: this.headers(),
    })

    return response.data
  }

  getBreakingNews = async () => {
    const response = await get<EconomicScenarioNewsResponse>({
      url: `${HOST}/api/games/${this.gameId}/news`,
      headers: this.headers(),
    })

    return response.data
  }

  ping = async () => {
    const response = await get<any>({
      url: `${HOST}/`,
    })
    if (response.data.ok !== true) {
      throw new Error('Server responded, but status is not ok.')
    }

    return response.data
  }
}

const api =
  process.env.REACT_APP_DEMO_MODE === 'true' ? MockGameAPI : new GameAPI()

export default api
