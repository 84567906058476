import React, { useCallback, useState } from 'react'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import Text from '../../../atoms/Text/Text'
import { Icon } from '../../../atoms/Icon/Icon'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../../store'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import { startTour } from '../../../../redux/guidedTours/guidedToursSlice'
import SubheadingDropDown from '../Components/SubheadingDropDown'
import { useMenuTranslations } from '../../../../hooks/useMenuTranslations'
import Button from '../../../atoms/Button/Button'

interface Props {}

const CostsHelp: React.FC<Props> = () => {
  const [expandedSubheading, setExpandedSubheading] = useState('')
  const translations = useMenuTranslations()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleCostsHelpTour = useCallback(() => {
    dispatch(startTour('costs'))
    navigate('/costs')
  }, [dispatch, navigate])

  return (
    <VerticalGroup gap={2}>
      <Text center size="lg">
        {translations.costs} Guide
      </Text>{' '}
      <VerticalGroup gap={6}>
        <HorizontalGroup center>
          <Button
            buttonType="secondary"
            full
            size="xl"
            onClick={handleCostsHelpTour}
          >
            <Icon type="guide" colour="primary" size={4} />
            <Text colour="primary">Start Walkthrough</Text>
          </Button>
        </HorizontalGroup>
        <VerticalGroup gap={6}>
          <SubheadingDropDown
            title={'OPERATING DIVISIONS'}
            content={
              <>
                The amount of investment and resourcing in each of the four
                Operating Divisions can be adjusted to increase/decrease the
                amount of output in each of these areas. Salary growth can also
                be adjusted, which will have an impact on the Employee
                Engagement of the people in each of the four Operating
                Divisions. There are diminishing returns that occur with
                increased investment due to inefficiencies with heavy investment
                programs.
                <br />
                <br />
                The impacts shown are always relative to the {
                  '"maintenance"'
                }{' '}
                levels of expenditure of a bank of your current size.
              </>
            }
            hint={
              "Experiment with different resource allocations to find the optimal balance for your bank's strategy. Keep an eye on the Metric Impacts tile to see how your changes are expected to impact key bank metrics. Changes in Employee Engagement will impact productivity and the level of commitment to the execution of your strategy."
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />

          <SubheadingDropDown
            title={'METRIC IMPACTS'}
            content={
              'This section shows the expected change in key bank metrics based on your current decisions. Note that these forecasts include the impact of projects selected in previous years; they do not include the project selected in the current year.'
            }
            hint={
              'Use this information to guide your resourcing decisions. If a metric is underperforming, consider allocating more resources to the relevant division.'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />

          <SubheadingDropDown
            title={'TOTAL IMPACT'}
            content={
              'This section shows the total additional operating costs and people you will require this year as a result of your decisions. These amounts are incremental to the maintenance levels your bank would expect in a normal "maintenance" year of operations.'
            }
            hint={
              'Keep an eye on these total impacts to ensure your decisions are sustainable for your bank. Are the benefits and changes that are expected worth the amount of expenditure?'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
        </VerticalGroup>
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default CostsHelp
