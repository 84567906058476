import React from 'react'
import HorizontalGroup from '../../../../atoms/HorizontalGroup/HorizontalGroup'
import SecondaryText from '../../../../atoms/Text/SecondaryText'
import classNames from 'classnames'

type Field = {
  id: string
  type: string
  config: {
    type: string
    label: string
    rating?: number | undefined
  }
}

interface Props {
  field: Field
  selected: Field['id'] | null
  onClick: (field: Field) => void
}

export const RatingField = ({ field, selected, onClick }: Props) => {
  const check = selected === field.id

  return (
    <HorizontalGroup
      className="self-start tablet:flex-col"
      verticalCenter
      onClick={() => onClick(field)}
      gap={4}
    >
      <div
        className={classNames('h-8 w-2 tablet:h-2 tablet:w-full', {
          'bg-gray-300': !check,
          'bg-primary': check,
        })}
      />
      <HorizontalGroup gap={2}>
        <input
          type="radio"
          id={field.id}
          onChange={() => onClick(field)}
          checked={check}
        />
        <SecondaryText size="sm">
          <label htmlFor={field.id}>{field.config.label}</label>
        </SecondaryText>
      </HorizontalGroup>
    </HorizontalGroup>
  )
}
