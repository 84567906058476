import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { reset } from '../game/gameSlice'
import { logout } from '../auth/authSlice'

export type Tours =
  | 'news'
  | 'summary'
  | 'peer-insights'
  | 'special-projects'
  | 'costs'
  | 'our-financials'
  | 'exco-decisions'
  | 'deal-room'
  | 'round-one-initial'
  | 'round-two-initial'
  | 'businesses'
  | 'treasury'
  | null

interface TourState {
  currentTour: Tours
}

const initialState: TourState = {
  currentTour: null,
}

const guidedToursSlice = createSlice({
  name: 'guidedTours',
  initialState,
  reducers: {
    startTour: (state, action: PayloadAction<Tours>) => {
      state.currentTour = action.payload
    },
    finishTour: state => {
      state.currentTour = null
    },
    setInitialModalClosed: (state, action: PayloadAction<string>) => {
      localStorage.setItem(`${action.payload}-initial-modal-closed`, 'true')
    },
  },
  extraReducers: builder => {
    builder
      .addCase(reset, () => {
        return initialState
      })
      .addCase(logout, () => {
        return initialState
      })
  },
})

export const { startTour, finishTour, setInitialModalClosed } =
  guidedToursSlice.actions

export default guidedToursSlice.reducer
