/* eslint-disable react/jsx-key */
import React from 'react'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'

import {
  argRange,
  Args,
  ArgsWithBid,
  DealRoomMathConfig,
  formatDollar,
  PlayerDeterminedText,
} from './utils'

const ARG_X_MIN = 0
const ARG_X_MAX = 20
const ARG_X_STEP = 2
const ARG_Y_MIN = 11
const ARG_Y_MAX = 18
const ARG_Y_STEP = 0.5

function calculateBroker(input: Args): { result: string; results: string[] }
function calculateBroker(input: ArgsWithBid): {
  result: string
  estimatedReturn: number
  results: string[]
}
function calculateBroker(input: ArgsWithBid | Args) {
  const { getAssumptions } = broker
  const assumptions = getAssumptions(input).map(a => a.value)

  const Step1 = assumptions[1]
  const Step2 = (Step1 * assumptions[2]) / 100
  const Step3 = (Step2 * assumptions[3]) / 100
  const Step4 = Step3 * (1 - assumptions[4] / 100)
  const Step5 = Step4 * (1 - assumptions[5] / 100)
  const Step6 = Step5 * (1 + assumptions[6] / 100)
  const Step7 = (Step6 * assumptions[7]) / 100
  const Step8 = Step7 / ((assumptions[8] - 10) / 100)
  const Step9 = (Step8 * assumptions[9]) / 100

  const results = [
    formatDollar(Step1),
    formatDollar(Step2),
    formatDollar(Step3),
    formatDollar(Step4),
    formatDollar(Step5),
    formatDollar(Step6),
    formatDollar(Step7),
    formatDollar(Step8),
    formatDollar(Step9),
  ]

  if ('bid' in input) {
    const { bid } = input
    const estimatedReturn = (Step7 * assumptions[9]) / 100 / bid + 10 / 100
    return { result: Step9.toFixed(0), estimatedReturn, results }
  }

  return { result: Step9.toFixed(0), results }
}

export const broker: DealRoomMathConfig = {
  defaultArgX: 10,
  defaultArgY: 15,
  calculate: calculateBroker,
  presetX: argRange(ARG_X_MIN, ARG_X_MAX, ARG_X_STEP),
  presetY: argRange(ARG_Y_MIN, ARG_Y_MAX, ARG_Y_STEP),
  titleX: 'ESTIMATED SYNERGIES ACHIEVED',
  titleY: 'REQUIRED RATE OF RETURN %',
  header1: 'Valuation Range: Synergies vs. Required Returns',
  header2: 'BROKER BANK BID CALCULATOR',
  getAssumptions({ argX, argY, assumptions }) {
    return [
      { label: '', denomination: '', value: assumptions?.[0]?.value ?? NaN },
      {
        label: 'Forecast Loan Book:',
        denomination: 'm',
        value: assumptions?.[1]?.value ?? 120000,
        numberFormat: 'integer',
      },
      {
        label: 'Average Commission:',
        denomination: '%',
        value: assumptions?.[2]?.value ?? 0.25,
        numberFormat: 'decimal',
      },
      {
        label: 'Commission Retained:',
        denomination: '%',
        value: assumptions?.[3]?.value ?? 50,
      },
      {
        label: 'Cost To Income Ratio:',
        denomination: '%',
        value: assumptions?.[4]?.value ?? 50,
        numberFormat: 'integer',
      },
      {
        label: 'Tax Rate:',
        denomination: '%',
        value: assumptions?.[5]?.value ?? 30,
        numberFormat: 'integer',
      },
      {
        label: 'Synergies (% of NPAT):',
        range: [ARG_X_MIN, ARG_X_MAX],
        denomination: '%',
        value: argX ?? assumptions?.[6]?.value ?? this.defaultArgX,
        numberFormat: 'integer',
        isKeyAssumption1: true,
      },
      {
        label: 'Dividend Payout Ratio:',
        denomination: '%',
        value: assumptions?.[7]?.value ?? 100,
        numberFormat: 'integer',
      },
      {
        label: 'Discount Rate:',
        range: [ARG_Y_MIN, ARG_Y_MAX],
        denomination: '%',
        value: argY ?? assumptions?.[8]?.value ?? this.defaultArgY,
        numberFormat: 'integer',
        isKeyAssumption2: true,
      },
      {
        label: 'Stake Acquired:',
        denomination: '%',
        value: assumptions?.[9]?.value ?? 100,
        numberFormat: 'integer',
      },
    ]
  },
  explanations: [
    {
      text: 'Commission Earned From Loan Book',
    },
    { text: 'Commission Retained' },
    { text: 'Total Revenue' },
    {
      text: 'Calculate Net Profit Before Tax',
      subtext: 'Revenue - Operating Expenses',
    },
    {
      text: 'Calculate Net Profit After Tax (NPAT)',
      subtext: 'Net Profit Before Tax x (1 - Tax Rate)',
    },
    {
      text: 'Calculate NPAT Including Synergies',
      subtext: (
        <>
          NPAT x (1 +{' '}
          <PlayerDeterminedText>Estimated Synergies*</PlayerDeterminedText>)
        </>
      ),
    },
    {
      text: 'Calculate Dividend Payable (Forcast)',
    },
    {
      text: 'Calculate Value Of 100% Of The Company Today',
      subtext: (
        <>
          Forcast Dividend / (
          <PlayerDeterminedText>Discount Rate*</PlayerDeterminedText> - Growth
          Rate)
        </>
      ),
    },
    {
      text: 'Calculate Value Of Stake Required',
      subtext: 'Company Value X Stake Acquired',
    },
  ],
  getFormulas(input) {
    const { results } = this.calculate(input)
    const assumptions = (input.assumptions ?? this.getAssumptions(input)).map(
      a => a.value,
    )
    results.unshift('') // Insert an '' at the beginning so that the index aligns
    return [
      <p>{formatDollar(assumptions[1])}</p>,
      <p>
        {results[1]} x {assumptions[2]}% = {results[2]}
      </p>,
      <p>
        {results[2]} x {assumptions[3]}% = {results[3]}
      </p>,
      <p>
        {results[3]} x (1 - {assumptions[4]}%) = {results[4]}
      </p>,
      <p>
        {results[4]} x (1 - {assumptions[5]}%) = {results[5]}
      </p>,
      <p>
        {results[5]} x (1 +{' '}
        <PlayerDeterminedText>{assumptions[6]}%</PlayerDeterminedText>) ={' '}
        {results[6]}
      </p>,
      <p>
        {results[6]} x {assumptions[7]}% = {results[7]}
      </p>,
      <p>
        {results[7]} / (
        <PlayerDeterminedText>{assumptions[8]}%</PlayerDeterminedText> - 10%) ={' '}
        {results[8]}
      </p>,
      <p>
        {results[8]} x {assumptions[9]}% = {results[9]}
      </p>,
      <p>
        <strong>{results[9]}</strong>
      </p>,
    ]
  },
  tips: [
    'Decide the amount of synergies that will be achieved?',
    'Determine the appropriate level of return required for the company (12% in example)',
  ],
  latestFinancials: [
    {
      key: 'Loan Book Managed',
      value: '120,000m',
    },
    {
      key: 'Average Commission',
      value: '0.25%',
    },
    {
      key: 'Commission Retention',
      value: '50%',
    },
    {
      key: 'Cost To Income Ratio',
      value: '50%',
    },
    {
      key: 'Tax Rate',
      value: '30%',
    },
    {
      key: 'Synergies (% of NPAT)',
      value: '0-15%',
    },
    {
      key: 'Dividend Payout Ratio',
      value: '100%',
    },
    {
      key: 'Average Growth Rate',
      value: '10%',
    },
  ],
  valuation: [
    <HorizontalGroup verticalCenter gap={8} fullWidth>
      <SecondaryText>Value =</SecondaryText>
      <VerticalGroup center>
        <SecondaryText>Next Year&apos;s Dividend</SecondaryText>
        <hr />
        <SecondaryText>Required Return - Growth Rate</SecondaryText>
      </VerticalGroup>
    </HorizontalGroup>,
  ],
}
