import React, { Fragment, useMemo } from 'react'
import { TreasuryResponse } from '../../../../types/gameApi/treasury'
import { DecisionMap } from '../TreasuryContainer'

import { Bar, LabelList } from 'recharts'
import { colours } from '../../../../constants/colours'
import BarChart from '../../../organisms/Charts/BarChart/BarChart'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { formatValue } from '../../../../lib/formatters'
import { calculateAssetsAndForecasts } from '../calculations'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { useIsMobileLandscape } from '../../../../hooks/useIsMobileLandscapes'

interface Props {
  data: TreasuryResponse
  decisions: DecisionMap
  enableChanges: boolean
  onChangeValue: (val: Partial<DecisionMap>) => void
}

const YAXIS_TICK_SPACING = 20000

const AssetsLabel = (props: any) => {
  const {
    x,
    y,
    width,
    height,
    value: { assets, bar, deposits, total, newFunding, cash },
  } = props
  if (
    bar !== 'assets' &&
    bar !== 'deposits' &&
    bar !== 'total' &&
    bar !== 'newFunding' &&
    bar !== 'cash'
  ) {
    return null
  }
  if (
    assets === 0 &&
    deposits === 0 &&
    total === 0 &&
    newFunding === 0 &&
    cash === 0
  ) {
    return null
  }
  // const threshold = 0.1
  // const cashAsPercentageOfTotalAssets = cash / (cash + allOtherAssets)
  // const cashDrawnOnAssetsBar = cashAsPercentageOfTotalAssets < threshold / 2

  return (
    <g>
      {bar == 'cash' && cash != 0 && (
        <text
          x={x + width / 2}
          y={y + 5 + height / 2}
          fill="#fff"
          textAnchor="middle"
          fontSize={12}
          fontFamily="Open Sans"
        >
          <tspan fontWeight="bold">{formatValue(cash)}</tspan>
        </text>
      )}
      {bar == 'assets' && assets != 0 && (
        <text
          x={x + width / 2}
          y={y + 5 + height / 2}
          fill="#fff"
          textAnchor="middle"
          fontSize={12}
          fontFamily="Open Sans"
        >
          <tspan fontWeight="bold">{formatValue(assets)}</tspan>
        </text>
      )}
      {bar == 'deposits' && deposits != 0 && (
        <text
          x={x + width / 2}
          y={y + 5 + height / 2}
          fill="#fff"
          textAnchor="middle"
          fontSize={12}
          fontFamily="Open Sans"
        >
          <tspan fontWeight="bold">{formatValue(deposits)}</tspan>
        </text>
      )}
      {bar == 'newFunding' && newFunding != 0 && (
        <text
          x={x + width / 2}
          y={y + 5 + height / 2}
          fill="#fff"
          textAnchor="middle"
          fontSize={12}
          fontFamily="Open Sans"
        >
          <tspan fontWeight="bold">{formatValue(newFunding)}</tspan>
        </text>
      )}
      {bar == 'total' && total != 0 && (
        <text
          x={x + width / 2}
          y={y - 12}
          fill="#000"
          textAnchor="middle"
          fontSize={12}
          fontFamily="Open Sans"
        >
          <tspan fontWeight="bold">{formatValue(total)}</tspan>
        </text>
      )}
    </g>
  )
}

const TreasuryWSFProfileChart: React.FC<Props> = ({ data, decisions }) => {
  const chartData = useMemo(() => {
    const { fundingProfile } = data
    const { cashForecastWithAutoWSF } = calculateAssetsAndForecasts({
      data,
      decisions,
    })
    const depositsYr1 = parseInt(
      (
        fundingProfile.depositsYr1 *
        (1 + decisions.depositGrowthChangePercentage / 10000)
      ).toFixed(0),
    )
    const depositsYr2 = parseInt(
      (
        fundingProfile.depositsYr2 *
        (1 + decisions.depositGrowthChangePercentage / 10000)
      ).toFixed(0),
    )
    const depositsYr3 = parseInt(
      (
        fundingProfile.depositsYr3 *
        (1 + decisions.depositGrowthChangePercentage / 10000)
      ).toFixed(0),
    )
    const depositsYr4 = parseInt(
      (
        fundingProfile.depositsYr4 *
        (1 + decisions.depositGrowthChangePercentage / 10000)
      ).toFixed(0),
    )
    const depositsYr5 = parseInt(
      (
        fundingProfile.depositsYr5 *
        (1 + decisions.depositGrowthChangePercentage / 10000)
      ).toFixed(0),
    )

    const cData = [
      {
        index: 1,
        label: '1 YEAR',
        newFunding: decisions.wsf1,
        deposits: depositsYr1,
        existingFunding: fundingProfile.wsfYr1,
        total: decisions.wsf1 + depositsYr1 + fundingProfile.wsfYr1,
        cash: -1 * cashForecastWithAutoWSF,
        assets:
          -1 *
          parseInt(
            (
              fundingProfile.assetsYr1 *
              (1 + decisions.fixedAssetsGrowth / 10000)
            ).toFixed(0),
          ),
      },
      {
        index: 2,
        label: '2 YEAR',
        newFunding: decisions.wsf2,
        deposits: depositsYr2,
        existingFunding: fundingProfile.wsfYr2,
        total: decisions.wsf2 + depositsYr2 + fundingProfile.wsfYr2,
        cash: 0,
        assets:
          -1 *
          parseInt(
            (
              fundingProfile.assetsYr2 *
              (1 + decisions.fixedAssetsGrowth / 10000)
            ).toFixed(0),
          ),
      },
      {
        index: 3,
        label: '3 YEAR',
        newFunding: decisions.wsf3,
        deposits: depositsYr3,
        existingFunding: fundingProfile.wsfYr3,
        total: decisions.wsf3 + depositsYr3 + fundingProfile.wsfYr3,
        cash: 0,
        assets:
          -1 *
          parseInt(
            (
              fundingProfile.assetsYr3 *
              (1 + decisions.fixedAssetsGrowth / 10000)
            ).toFixed(0),
          ),
      },
      {
        index: 4,
        label: '4 YEAR',
        newFunding: decisions.wsf4,
        deposits: depositsYr4,
        existingFunding: fundingProfile.wsfYr4,
        total: decisions.wsf4 + depositsYr4 + fundingProfile.wsfYr4,
        cash: 0,
        assets:
          -1 *
          parseInt(
            (
              fundingProfile.assetsYr4 *
              (1 + decisions.fixedAssetsGrowth / 10000)
            ).toFixed(0),
          ),
      },
      {
        index: 5,
        label: '5+ YEAR',
        newFunding: decisions.wsf5,
        deposits: depositsYr5,
        existingFunding: fundingProfile.wsfYr5,
        total: decisions.wsf5 + depositsYr5 + fundingProfile.wsfYr5,
        cash: 0,
        assets:
          -1 *
          parseInt(
            (
              fundingProfile.assetsYr5 *
              (1 + decisions.fixedAssetsGrowth / 10000)
            ).toFixed(0),
          ),
      },
    ]

    const actualMaxValue = Math.max(...cData.map(d => d.total))
    const maxValue =
      Math.ceil(Math.abs(actualMaxValue) / YAXIS_TICK_SPACING) *
      YAXIS_TICK_SPACING

    const actualMinValue = Math.max(...cData.map(d => d.assets * -1))
    const minValue =
      -1 *
      Math.ceil(Math.abs(actualMinValue) / YAXIS_TICK_SPACING) *
      YAXIS_TICK_SPACING
    return {
      data: cData,
      minValue,
      maxValue,
      numberOfTicks: (maxValue - minValue) / YAXIS_TICK_SPACING + 1,
    }
  }, [data, decisions])

  const barsComponent = (
    <Fragment>
      <Bar
        dataKey="assets"
        stackId="a"
        fill={colours.orange}
        isAnimationActive={false}
      >
        <LabelList
          position="middle"
          content={AssetsLabel}
          valueAccessor={(data: any) => ({
            assets: data.payload.assets,
            bar: 'assets',
          })}
        ></LabelList>
      </Bar>
      <Bar
        dataKey="cash"
        stackId="a"
        fill={colours.pink}
        isAnimationActive={false}
      >
        <LabelList
          position="middle"
          content={AssetsLabel}
          valueAccessor={(data: any) => ({
            cash: data.payload.cash,
            bar: 'cash',
          })}
        ></LabelList>
      </Bar>
      <Bar
        dataKey="deposits"
        stackId="a"
        fill={colours.purple}
        isAnimationActive={false}
      >
        <LabelList
          position="middle"
          content={AssetsLabel}
          valueAccessor={(data: any) => ({
            deposits: data.payload.deposits,
            bar: 'deposits',
          })}
        ></LabelList>
      </Bar>
      <Bar
        dataKey="existingFunding"
        stackId="a"
        fill={colours.green}
        isAnimationActive={false}
      ></Bar>
      <Bar
        dataKey="newFunding"
        stackId="a"
        fill={colours.primary}
        isAnimationActive={false}
      >
        <LabelList
          position="top"
          content={AssetsLabel}
          valueAccessor={(data: any) => ({
            total: data.payload.total,
            bar: 'total',
          })}
        />
        <LabelList
          position="middle"
          content={AssetsLabel}
          valueAccessor={(data: any) => ({
            newFunding: data.payload.newFunding,
            bar: 'newFunding',
          })}
        />
      </Bar>
    </Fragment>
  )

  const size = useWindowSize()
  const isMobileLandscape = useIsMobileLandscape()
  const width = !isMobileLandscape ? '100%' : (size.width ?? 0) - 100
  const height = !isMobileLandscape
    ? (size.height ?? 0) / 2
    : (size.height ?? 0) * (3 / 4)

  return (
    <HorizontalGroup
      verticalCenter
      fullHeight
      fullWidth
      style={{ width, height }}
    >
      <BarChart
        stackOffset="sign"
        barsComponent={barsComponent}
        data={chartData.data}
        minYAxisvalue={chartData.minValue}
        maxYAxisvalue={chartData.maxValue}
        numberOfTicks={chartData.numberOfTicks}
        xPadding={20}
        // hideYAxis
        formatter={formatValue}
        // hideGrid
      />
    </HorizontalGroup>
  )
}

export default TreasuryWSFProfileChart
