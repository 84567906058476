import { createAsyncThunk } from '@reduxjs/toolkit'
import gameAPI from '../../services/gameApi'
import APIError from '../../errors/APIError'
import { RootState } from '../../store'
import {
  PeerInsightsRequestParams,
  PeerInsightsResponse,
} from '../../types/gameApi/peerInsights'
import ThunkCancelledError from '../../errors/ThunkCancelledError'

export const fetchPeerInsights = createAsyncThunk<
  { roundId: number; insights: PeerInsightsResponse },
  PeerInsightsRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'peerInsights/fetch',
  async ({ teamId, roundId }, { rejectWithValue, getState, requestId }) => {
    const state = getState()
    if (state.peerInsights.insights[roundId]) {
      return { roundId, insights: state.peerInsights.insights[roundId] }
    }
    try {
      if (state.peerInsights.requestInProgress !== requestId) {
        throw new ThunkCancelledError()
      }
      const insights = await gameAPI.getPeerInsights({ teamId, roundId })

      return { roundId, insights }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)
