import React, { useCallback } from 'react'
import Card from '../../../atoms/Card/Card'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import ValueChangerWithPercentageChange from '../../../molecules/ValueChanger/ValueChangerWithPercentageChange'
import { BusinessDecision } from '../BusinessesContainer'
import { formatPrice1, formatVolume } from '../formatters'
import { renderPercentage } from './BusinessAdvancedModeTable'
import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'
import PrimaryText from '../../../atoms/Text/PrimaryText'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { ItemData } from './types'

interface BusinessAdvanceModeCardProps {
  data: ItemData
  onChangeValue: (
    type: string,
    field: keyof BusinessDecision,
    value: number,
  ) => void
  enableChanges: boolean
  idPrefix?: string
}

const BusinessAdvanceModeCard: React.FC<BusinessAdvanceModeCardProps> = ({
  data,
  onChangeValue,
  enableChanges,
}) => {
  const handleDecrementVolume = useCallback(
    (priceType: keyof BusinessDecision) => (val: number) => {
      if (data.type) {
        onChangeValue(
          data.type,
          priceType,
          (data.volume.incrementValue ?? val) - data.volume.prevValue * 0.01,
        )
      }
    },
    [
      onChangeValue,
      data.type,
      data.volume.incrementValue,
      data.volume.prevValue,
    ],
  )

  const handleIncrementVolume = useCallback(
    (priceType: keyof BusinessDecision) => (val: number) => {
      if (data.type) {
        onChangeValue(
          data.type,
          priceType,
          (data.volume.incrementValue ?? val) + data.volume.prevValue * 0.01,
        )
      }
    },
    [
      onChangeValue,
      data.type,
      data.volume.incrementValue,
      data.volume.prevValue,
    ],
  )
  const handleDecrement = useCallback(
    (priceType: keyof BusinessDecision) => (val: number) => {
      if (data.type) {
        onChangeValue(data.type, priceType, val - 1)
      }
    },
    [onChangeValue, data.type],
  )

  const handleIncrement = useCallback(
    (priceType: keyof BusinessDecision) => (val: number) => {
      if (data.type) {
        onChangeValue(data.type, priceType, val + 1)
      }
    },
    [onChangeValue, data.type],
  )

  return (
    <Card className="py-3 px-4">
      <VerticalGroup gap={3}>
        <VerticalGroup gap={3} center>
          <PrimaryText size="sm" weight="bold">
            {data.name}
          </PrimaryText>

          <VerticalGroup>
            <TranslatedText
              weight="bold"
              value="businessDecisions.screen3.table1Column4Header"
              default="FORECAST INTEREST"
              family="secondary"
              size="xs"
              transform="upper"
              center
              colour="gray"
            />
            <VerticalGroup className="">
              <ValueChangerWithPercentageChange
                idPrefix={`${data.type}-volumeNow`}
                verticalText
                fontBold
                enableChanges={false}
                value={data.interest.value}
                formatValue={val => formatVolume(val)}
                percentageChange={() =>
                  renderPercentage(
                    data.interest.percentageChange,
                    data.isLiability,
                  )
                }
              />
            </VerticalGroup>
          </VerticalGroup>

          <HorizontalGroup around fullWidth className="h-16">
            <VerticalGroup className="w-1/2" center>
              <TranslatedText
                weight="bold"
                value="businessDecisions.screen3.table1Column2Header"
                default="RATE"
                size="xs"
                transform="upper"
                colour="gray"
              />
              <HorizontalGroup
                gap={4}
                center
                fullWidth
                className="flex items-center"
              >
                <ValueChangerWithPercentageChange
                  fontBold
                  verticalText
                  enableChanges={!data.rate.disableChanges && enableChanges}
                  value={data.rate.value}
                  min={data.rate.min}
                  max={data.rate.max}
                  formatValue={val => formatPrice1(val)}
                  onIncrement={handleIncrement('price1Now')}
                  onDecrement={handleDecrement('price1Now')}
                  percentageChange={() => {
                    return renderPercentage(
                      data.rate.percentageChange,
                      data.isLiability,
                    )
                  }}
                />
              </HorizontalGroup>
            </VerticalGroup>
            <VerticalGroup className="w-1/2" center>
              <TranslatedText
                weight="bold"
                value="businessDecisions.screen3.table1Column3Header"
                default="FORECAST VOLUME"
                family="secondary"
                size="xs"
                transform="upper"
                center
                colour="gray"
              />
              <VerticalGroup className="" center verticalCenter>
                <ValueChangerWithPercentageChange
                  fontBold
                  verticalText
                  enableChanges={!data.volume.disableChanges && enableChanges}
                  value={data.volume.value}
                  min={data.volume.min ?? 0}
                  formatValue={val => formatVolume(val)}
                  percentageChange={() =>
                    renderPercentage(data.volume.percentageChange)
                  }
                  onIncrement={handleIncrementVolume('volumeNow')}
                  onDecrement={handleDecrementVolume('volumeNow')}
                  idPrefix={`mobile-${data.type}-volumeNow`}
                />
              </VerticalGroup>
            </VerticalGroup>
          </HorizontalGroup>
        </VerticalGroup>
      </VerticalGroup>
    </Card>
  )
}

export default BusinessAdvanceModeCard
