import classNames from 'classnames'
import React from 'react'
import { Spacing } from '../../../types/theme'

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  gap?: Spacing
  full?: boolean
  center?: boolean
  start?: boolean
  verticalCenter?: boolean
  verticalStart?: boolean
  between?: boolean
  fullWidth?: boolean
  around?: boolean
}

const VerticalGroup: React.FC<Props> = ({
  children,
  className,
  gap = 0,
  full,
  center,
  start,
  verticalCenter,
  verticalStart,
  between,
  fullWidth,
  around,
  ...rest
}) => {
  const gapClass = `gap-y-${gap}`
  return (
    <div
      {...rest}
      className={classNames('flex flex-col', className, gapClass, {
        'h-full': full,
        'items-center': center,
        'items-start': start,
        'justify-start': verticalStart,
        'justify-center': verticalCenter,
        'justify-between': between,
        'w-full': fullWidth,
        'justify-around': around,
      })}
    >
      {children}
    </div>
  )
}

export default VerticalGroup
