import React from 'react'

import { formatPercentage } from '../../../../lib/formatters'

import { DecisionMap } from '../TreasuryContainer'

import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'

import SecondaryText from '../../../atoms/Text/SecondaryText'
import { CapitalManagmentData } from './data'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'

interface CapitalRatioEsitmatedModalDetailProps {
  decisions: DecisionMap
  capitalManagmentData: CapitalManagmentData
}

const CapitalRatioEsitmatedModalDetail: React.FC<
  CapitalRatioEsitmatedModalDetailProps
> = ({ decisions }) => {
  return (
    <VerticalGroup center fullWidth between verticalCenter>
      <div>
        <TranslatedText
          size="sm"
          center
          value="treasury.screen4.table2Row9"
          default="Current Capital Ratio"
        />
      </div>

      <SecondaryText size="base" weight="bold" colour="green">
        {formatPercentage(Number(decisions.capitalRatioChange))}
      </SecondaryText>
    </VerticalGroup>
  )
}

export default CapitalRatioEsitmatedModalDetail
