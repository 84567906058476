import classNames from 'classnames'
import React from 'react'
export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  active?: boolean
}

const Card: React.FC<Props> = ({ className, children, active, ...rest }) => {
  return (
    <div
      className={classNames(
        {
          'cursor-pointer': !!rest.onClick,
          'no-tap-highlight': true, // Add this line
        },
        'rounded-lg border bg-white shadow-md',
        active ? 'border-2 border-primary' : 'border-gray-300',
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  )
}
export default Card
