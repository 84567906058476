import classNames from 'classnames'
import React, { PropsWithChildren, forwardRef } from 'react'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  noDivideY?: boolean
}

const Table = forwardRef<HTMLTableElement, PropsWithChildren<Props>>(
  ({ children, className, noDivideY, ...rest }, ref) => {
    return (
      <div
        {...rest}
        className={classNames(
          'h-full w-full overflow-hidden rounded-lg shadow ring-1 ring-gray-300',
          className,
        )}
      >
        <table
          ref={ref}
          className={classNames('h-full w-full flex-grow', {
            'divide-y divide-gray-300': !noDivideY,
          })}
        >
          {children}
        </table>
      </div>
    )
  },
)
Table.displayName = 'Table'
export default Table
