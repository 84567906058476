import React, { PropsWithChildren } from 'react'
import { useIsMobileLandscape } from '../../../hooks/useIsMobileLandscapes'

export interface Props {
  className?: string
}

const HideIfMobileLandscape: React.FC<PropsWithChildren<Props>> = ({
  children,
}) => {
  const isMobileLandscape = useIsMobileLandscape()
  if (isMobileLandscape) {
    return null
  }
  return <>{children}</>
}

export default HideIfMobileLandscape
