const DAY_SECONDS = 86400
const HOUR_SECONDS = 3600
const MINUTE_SECONDS = 60

const pad = (val: number) => {
  if (val < 10) {
    return `0${val}`
  }
  return val
}
export const parseTimeLeft = (finishTime: string) => {
  const finishDate = new Date(finishTime)
  const finishVal = finishDate.valueOf()
  const nowVal = new Date().valueOf()
  const diffSeconds = (finishVal - nowVal) / 1000

  if (!finishTime || diffSeconds <= 0) {
    return { time: '00:00', displayType: 'H', diffSeconds: 0 }
  }
  if (diffSeconds >= DAY_SECONDS) {
    return { diffSeconds, ...secondsToDHMS(finishDate) }
  } else if (diffSeconds >= HOUR_SECONDS) {
    return { diffSeconds, ...secondsToHMS(diffSeconds) }
  } else {
    return { diffSeconds, ...secondsToMS(diffSeconds) }
  }
}

const secondsToDHMS = (date: Date) => {
  const { weekday, month, day, year, time } = splitDate(date)
  return {
    time: `${time} ${weekday}\n ${day}-${month}-${year}`,
    displayType: 'D',
  }
}

const splitDate = (date: Date) => {
  const dateArray = date.toString().split(' ')
  const timeArray = dateArray[4].split(':')
  const hour = parseInt(timeArray[0])
  const time = `${hour !== 12 ? hour % 12 : 12}:${timeArray[1]}${
    hour < 12 ? 'am' : 'pm'
  }`
  return {
    weekday: dateArray[0],
    month: dateArray[1],
    day: dateArray[2],
    year: dateArray[3].substring(2, 4),
    time: time,
  }
}

const secondsToHMS = (diffSeconds: number) => {
  const hours = parseInt(`${diffSeconds / HOUR_SECONDS}`)
  const minutes = parseInt(
    `${(diffSeconds - hours * HOUR_SECONDS) / MINUTE_SECONDS}`,
  )
  const seconds = parseInt(
    `${diffSeconds - hours * HOUR_SECONDS - minutes * MINUTE_SECONDS}`,
  )
  return {
    time: `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`,
    displayType: 'H',
  }
}

const secondsToMS = (diffSeconds: number) => {
  const minutes = parseInt(`${diffSeconds / MINUTE_SECONDS}`)
  const seconds = parseInt(`${diffSeconds - minutes * MINUTE_SECONDS}`)
  return {
    time: `${pad(minutes)}:${pad(seconds)}`,
    displayType: 'M',
  }
}
