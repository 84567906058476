import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string
  alt?: string
  className?: string
  objectFit?: 'cover'
}

const OBJECT_FIT_CLASSES: Record<string, string> = {
  cover: 'object-cover',
}

const Skeleton = () => (
  <div className="h-full w-full animate-pulse bg-gray-300"></div>
)

const Image: React.FC<ImageProps> = ({
  src,
  alt,
  className,
  objectFit,
  ...rest
}) => {
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    const img = new window.Image()
    img.src = src
    img.onload = () => setImageLoaded(true)
  }, [src])

  const objectFitClass = objectFit ? OBJECT_FIT_CLASSES[objectFit] : ''

  return (
    <div className={classNames(className, objectFitClass)}>
      {!imageLoaded && <Skeleton />}
      <img
        src={src}
        alt={alt}
        className={classNames(className, objectFitClass)}
        style={imageLoaded ? {} : { display: 'none' }}
        {...rest}
      />
    </div>
  )
}

export default Image
