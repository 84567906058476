import { createAsyncThunk } from '@reduxjs/toolkit'
import gameAPI from '../../services/gameApi'
import APIError from '../../errors/APIError'
import {
  FinancialsRequestParams,
  FinancialsResponse,
} from '../../types/gameApi/financials'
import { RootState } from '../../store'
import ThunkCancelledError from '../../errors/ThunkCancelledError'

export const fetchFinancials = createAsyncThunk<
  { roundId: number; financials: FinancialsResponse },
  FinancialsRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'financials/fetch',
  async ({ teamId, roundId }, { rejectWithValue, getState, requestId }) => {
    const state = getState()
    if (state.financials.financials[roundId]) {
      return { roundId, financials: state.financials.financials[roundId] }
    }
    try {
      if (state.financials.requestInProgress !== requestId) {
        throw new ThunkCancelledError()
      }
      const financials = await gameAPI.getFinancials({ teamId, roundId })

      return { roundId, financials }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)
