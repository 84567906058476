import { RadioGroup } from '@headlessui/react'
import React, { useCallback, useEffect } from 'react'
import {
  fetchExco,
  fetchExcoBackground,
  submitExco,
} from '../../../redux/exco/excoActions'
import { useAppDispatch, useAppSelector } from '../../../store'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import Image from '../../atoms/Image/Image'
import Page from '../../atoms/Page/Page'
import PrimaryText from '../../atoms/Text/PrimaryText'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'

import CentredSpinner from '../../molecules/CentredSpinner/CentredSpinner'
import MobilePageHeader from '../../organisms/MobilePageHeader/MobilePageHeader'
import ExcoDecisionsOption from './ExcoDecisionOption'
import HtmlText from '../../atoms/Text/HtmlText'
import InlineError from '../../atoms/InlineError/InlineError'
import { useIsFeatureEnabledForRound } from '../../../hooks/useIsFeatureEnabled'
import ExcoTour from './ExcoTour'
import ErrorModal from '../../atoms/Modal/ErrorModal'
import { clearError } from '../../../redux/exco/excoSlice'

const ExcoDecisions = () => {
  const {
    isSubmitting,
    submittingOption,
    isLoading,
    exco,
    error,
    submitError,
    teamId,
    roundId,
    canSelect,
  } = useAppSelector(state => ({
    isSubmitting: state.exco.isSubmitting,
    submittingOption: state.exco.submittingOption,
    isLoading: state.exco.isLoading,
    error: state.exco.error,
    submitError: state.exco.submitError,
    exco: state.exco.exco,
    teamId: state.event.details?.team ?? 0,
    roundId: state.game.selectedRound,
    canSelect: state.game.selectedRound === state.game.currentRound,
  }))

  const isExcoEnabled = useIsFeatureEnabledForRound('exco-decisions')

  const dispatch = useAppDispatch()

  const fetchExcoData = useCallback(() => {
    dispatch(fetchExco({ roundId, teamId }))
  }, [dispatch, roundId, teamId])

  useEffect(() => {
    if (isExcoEnabled && !isLoading && !error && !exco[roundId]) {
      fetchExcoData()
    }

    const interval = setInterval(() => {
      dispatch(fetchExcoBackground({ teamId, roundId }))
    }, 5000)
    return () => clearInterval(interval)
  }, [
    dispatch,
    error,
    exco,
    fetchExcoData,
    isLoading,
    roundId,
    isExcoEnabled,
    teamId,
  ])

  const submitDecision = useCallback(
    (decision: number) => {
      if (!isSubmitting) {
        dispatch(submitExco({ roundId, teamId, decision }))
      }
    },
    [dispatch, roundId, teamId, isSubmitting],
  )

  if (error) {
    return (
      <Page full>
        <InlineError message={error.message} onRetry={fetchExcoData} />
      </Page>
    )
  }

  if (isLoading || !exco[roundId]) {
    return <CentredSpinner />
  }

  const data = exco[roundId]
  return (
    <Page noPadding>
      {submitError && (
        <ErrorModal
          showModal={!!submitError}
          heading="An error occurred"
          message={`An error occurred while submitting your decision. (${submitError.message}). Please try again`}
          onClose={() => dispatch(clearError())}
        />
      )}
      <VerticalGroup>
        <MobilePageHeader className="p-4" />
        <div className="laptop:flex">
          <VerticalGroup className="pt-4" gap={2}>
            <Image
              data-test="exco-decisions-image"
              className="h-48 w-full border border-l-0 tablet:rounded-tr-lg tablet:rounded-br-lg tablet:shadow-lg laptop:h-80"
              src={data.imageUrl}
              objectFit="cover"
            />
            <VerticalGroup id="tour-scenario" gap={2} className="p-4">
              <PrimaryText data-test="exco-title" size="xl" weight="bold">
                {data.name}
              </PrimaryText>
              <HtmlText family="primary" size="sm">
                {data.description}
              </HtmlText>
            </VerticalGroup>
          </VerticalGroup>
          <HorizontalGroup className="p-4 laptop:max-w-[40%]">
            <RadioGroup value={data.decision} disabled={!canSelect}>
              <VerticalGroup gap={4}>
                {data.options.map((o, index) => (
                  <RadioGroup.Option key={o.id} value={o.id}>
                    {() => (
                      <ExcoDecisionsOption
                        id={index === 0 ? 'tour-exco-decision' : ''}
                        key={o.id}
                        data={o}
                        canSelect={canSelect && !isSubmitting}
                        onSelect={submitDecision}
                        active={data.decision === o.id}
                        isSubmitting={isSubmitting && o.id === submittingOption}
                      />
                    )}
                  </RadioGroup.Option>
                ))}
              </VerticalGroup>
            </RadioGroup>
          </HorizontalGroup>
        </div>
      </VerticalGroup>
      <ExcoTour />
    </Page>
  )
}

export default ExcoDecisions
