import React, { Fragment, PropsWithChildren, useMemo } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import Button, { ButtonSize } from '../Button/Button'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import PrimaryText from '../Text/PrimaryText'
import Spinner from '../Spinner/Spinner'
import { Icon } from '../Icon/Icon'
import { useKeypress } from '../../../hooks/useKeyPress'

export interface Props {
  type?: 'error' | 'warning' | 'broadcast'
  show: boolean
  onClose: () => void
  title?: string
  showFooter?: boolean
  cancelButtonText?: string
  onOk?: () => void
  onCancel?: () => void
  okButtonText?: string
  secondaryButton?: {
    onPress: () => void
    label: string
  }
  buttonPosition?: 'start' | 'center' | 'end'
  titlePosition?: 'start' | 'center' | 'end'
  buttonSize?: ButtonSize
  preventDefault?: boolean
  okInProgress?: boolean
  disableBackdropClick?: boolean
  disableButtons?: boolean
  okDisabled?: boolean
  keymap?: Record<string, () => void>
}

const Modal: React.FC<PropsWithChildren<Props>> = ({
  keymap,
  type,
  show,
  onClose,
  title,
  children,

  cancelButtonText = 'Cancel',
  onOk,
  onCancel,
  okButtonText = 'Ok',
  buttonPosition,
  titlePosition,
  secondaryButton,
  buttonSize,
  preventDefault = false,
  okInProgress,
  disableBackdropClick = false,
  disableButtons = false,
  okDisabled = false,
}) => {
  const preventDefaultOnClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (preventDefault) {
      event.stopPropagation()
    }
  }

  const keyMapToUse = useMemo(() => {
    if (show && keymap) {
      return keymap
    }
    return {}
  }, [show, keymap])

  useKeypress({
    keymap: keyMapToUse,
  })

  return (
    <Transition.Root show={show} as={Fragment} appear>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={onClose}
        onClick={preventDefaultOnClick}
      >
        <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center tablet:block tablet:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden tablet:inline-block tablet:h-screen tablet:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
            enterTo="opacity-100 translate-y-0 tablet:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 tablet:scale-100"
            leaveTo="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
          >
            <div className="relative z-40 inline-block transform overflow-hidden rounded-xl bg-white p-6 text-left align-bottom shadow-xl transition-all tablet:my-8 tablet:max-w-md tablet:p-6 tablet:align-middle">
              {onClose && (
                <div className="absolute right-0 top-0 pr-4 pt-4">
                  <button
                    type="button"
                    className={classNames(
                      'rounded-md bg-white text-gray-400 hover:text-gray-500',
                      { 'cursor-not-allowed opacity-50': disableButtons },
                    )}
                    onClick={onClose}
                    disabled={disableButtons}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              )}
              <div className="gap-x-3 tablet:flex tablet:items-start">
                {(type === 'error' ||
                  type === 'warning' ||
                  type === 'broadcast') && (
                  <div
                    className={classNames(
                      'sm:mx-0 sm:h-10 sm:w-10 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full',
                      {
                        'bg-red-100': type === 'error',
                        'bg-orange-100': type === 'warning',
                        'bg-primary-100': type === 'broadcast',
                      },
                    )}
                  >
                    {type !== 'broadcast' && (
                      <ExclamationTriangleIcon
                        className={classNames('h-6 w-6', {
                          'text-red-600': type === 'error',
                          'text-orange-600': type === 'warning',
                        })}
                        aria-hidden="true"
                      />
                    )}
                    {type === 'broadcast' && (
                      <Icon type="broadcast" colour="primary" size={6} />
                    )}
                  </div>
                )}
                <div
                  className={classNames('mt-3 w-full tablet:mt-0', {
                    'text-left tablet:ml-4':
                      !titlePosition || titlePosition === 'start',
                    'text-center': titlePosition === 'center',
                    'text-right': titlePosition === 'end',
                  })}
                >
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                  <div>{children}</div>
                </div>
              </div>
              {(!!onCancel || !!onOk) && (
                <div
                  className={classNames(
                    'mt-5 flex gap-4 tablet:mt-5 tablet:flex',
                    {
                      'justify-end':
                        !buttonPosition || buttonPosition === 'end',
                      'justify-start': buttonPosition === 'start',
                      'justify-center': buttonPosition === 'center',
                    },
                  )}
                >
                  {onCancel && (
                    <Button
                      rounded="full"
                      buttonType="secondary"
                      size={buttonSize ?? 'small'}
                      text={cancelButtonText}
                      onClick={onCancel}
                      disabled={disableButtons}
                    />
                  )}
                  {secondaryButton && (
                    <Button
                      rounded="full"
                      buttonType="primary"
                      size={buttonSize ?? 'small'}
                      text={secondaryButton.label}
                      onClick={secondaryButton.onPress}
                    />
                  )}
                  {onOk && (
                    <Button
                      data-test="ok-button"
                      rounded="full"
                      buttonType="primary"
                      size={buttonSize ?? 'small'}
                      onClick={onOk}
                      disabled={okDisabled || okInProgress}
                    >
                      {!okInProgress && (
                        <PrimaryText>{okButtonText}</PrimaryText>
                      )}
                      {okInProgress && <Spinner />}
                    </Button>
                  )}
                </div>
              )}
            </div>
          </Transition.Child>

          {disableBackdropClick && (
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 z-10"
                onClick={e => e.stopPropagation()}
              />
            </Transition.Child>
          )}
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Modal
