import {
  DeviceResponse,
  DeviceStatusResponse,
} from '../../../../../types/gameApi/device'
import { data as deviceStatusData } from './status'

export const getDeviceStatusData = (): DeviceStatusResponse => {
  const nextClosestHour = new Date()
  nextClosestHour.setHours(nextClosestHour.getHours() + 1)
  nextClosestHour.setMinutes(0)
  nextClosestHour.setSeconds(0)
  return {
    ...deviceStatusData,
    finishTime: nextClosestHour.toISOString(),
    currentTime: new Date().toISOString(),
  }
}

export const getDeviceData = (deviceId: string): DeviceResponse => {
  return {
    deviceId,
    teamId: 1,
    isMaster: true,
    sideBarFontSize: 'm',
    batteryStatus: 50,
  }
}

export const getRegisterDeviceData = (deviceId: string): DeviceResponse => {
  return getDeviceData(deviceId)
}
