import React from 'react'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import Donut from '../../../atoms/Donut/Donut'
import { Icon, IconType } from '../../../atoms/Icon/Icon'
import { Colour } from '../../../../types/theme'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import ValueChangerWithInput, {
  ValueChangeInputType,
  ValueChangeModalProps,
} from '../../../molecules/ValueChanger/ValueChangerWithInput'
import Slider from '../../../atoms/Slider/Slider'
import InfoPopup from '../../../atoms/InfoPopup/InfoPopup'
interface Props {
  modalChanger: ValueChangeModalProps
  valueChangeType: ValueChangeInputType
  donut: {
    min: number
    max: number
    value: number
  }
  min: number
  max: number
  value: number
  changeValue: string
  changeUnits: string | null
  colour: Colour
  icon?: IconType
  priceType: string
  previousPrice: string | number
  previousPriceRaw: number
  formatter: (val: number) => string | number
  showSlider: boolean
  onChange: (val: number) => void
  enableChanges: boolean
  increments?: number[]
  infoBody: string
  buttonIdPrefix?: string
  analyticsLabel: string
  id?: string
}

const BusinessDecisionitemPrice: React.FC<Props> = ({
  modalChanger,
  valueChangeType,
  donut,
  min,
  max,
  value,
  changeValue,
  changeUnits,
  colour,
  icon,
  priceType,
  previousPrice,
  previousPriceRaw,
  formatter,
  showSlider,
  onChange,
  enableChanges,
  increments,
  infoBody,
  buttonIdPrefix,
  analyticsLabel,
  id,
}) => {
  return (
    <VerticalGroup
      id={id}
      verticalCenter
      gap={1}
      fullWidth
      center
      className="h-full flex-grow px-5 pb-4 monitor:gap-8"
    >
      <HorizontalGroup gap={2} verticalCenter center fullWidth>
        <SecondaryText weight="bold" size="sm">
          {priceType === 'Broker Commissions' ? 'Broker Support' : priceType}
        </SecondaryText>
        <InfoPopup
          id={id ? `${id}-info-popup` : undefined}
          title={
            priceType === 'Broker Commissions' ? 'Broker Support' : priceType
          }
          body={infoBody}
          iconColour={'primary'}
          iconSize={4}
          analyticsParams={{
            category: 'business',
            action: 'open',
            label: analyticsLabel,
          }}
        />
      </HorizontalGroup>

      <VerticalGroup center className="w-full">
        <HorizontalGroup verticalCenter center fullWidth>
          <div className="relative w-28 hugemonitor:w-64">
            <Donut
              colour={colour}
              min={donut.min}
              max={donut.max}
              value={donut.value}
            >
              <VerticalGroup center>
                <HorizontalGroup className="items-baseline" gap={1}>
                  <SecondaryText id="tour-change-value" weight="bold" size="lg">
                    {changeValue}
                  </SecondaryText>
                  <SecondaryText>{changeUnits}</SecondaryText>
                </HorizontalGroup>
              </VerticalGroup>
            </Donut>
          </div>
          <div className="bottom-30 absolute left-1/2 -translate-x-1/2 transform bg-white">
            {icon && (
              <Icon className="" type={icon} colour={colour} size={14} />
            )}
          </div>
        </HorizontalGroup>
      </VerticalGroup>

      <VerticalGroup gap={1} center fullWidth>
        <VerticalGroup center>
          <SecondaryText size="xs">Previously</SecondaryText>
          <SecondaryText id={id ? `${id}-previous-price` : undefined} size="sm">
            {previousPrice}
          </SecondaryText>
        </VerticalGroup>
        {!showSlider && (
          <div className="value-changer">
            <ValueChangerWithInput
              data-test="business-input"
              modalProps={modalChanger}
              min={min}
              max={max}
              value={Number(value)}
              previousValue={Number(previousPriceRaw)}
              onChange={onChange}
              enableChanges={enableChanges}
              formatValue={val => String(formatter(val))}
              type={valueChangeType}
              increments={increments}
              buttonIdPrefix={buttonIdPrefix}
            />
          </div>
        )}
        {showSlider && enableChanges && (
          <div className="flex h-6 w-full max-w-xs items-center justify-center px-2 laptop:h-8 desktop:h-10">
            <Slider
              min={min}
              max={max}
              step={1}
              value={value}
              onChange={onChange}
              disabled={!enableChanges}
            />
          </div>
        )}
        {showSlider && !enableChanges && (
          <div className="h-6 w-full laptop:h-8 desktop:h-10"></div>
        )}
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default BusinessDecisionitemPrice
