import { FinancialsResponse } from '../../../../../types/gameApi/financials'
import { data as round1Data } from './1'
import { data as round2Data } from './2'
import { data as round3Data } from './3'
import { data as round4Data } from './4'

const financialsMockData: { [roundId: number]: FinancialsResponse } = {
  1: round1Data,
  2: round2Data,
  3: round3Data,
  4: round4Data,
}

export const getFinancialsData = (roundId: number): FinancialsResponse => {
  if (financialsMockData[roundId]) {
    return financialsMockData[roundId]
  }
  throw new Error(`No mock financials data for round ${roundId}`)
}
