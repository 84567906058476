import { range } from 'lodash'
import React, { PropsWithChildren } from 'react'

export type Args = {
  argX?: number
  argY?: number
  assumptions?: ReadonlyArray<Assumption>
}

export interface ArgsWithBid extends Args {
  bid: number
}

export type CalculateFunc = <T extends ArgsWithBid | Args>(
  input: T,
) => T extends ArgsWithBid
  ? { result: string; estimatedReturn: number; results: string[] }
  : { result: string; results: string[] }

export interface Assumption {
  label: string
  value: number
  denomination: string
  range?: number[]
  numberFormat?: 'decimal' | 'integer'
  isKeyAssumption1?: boolean
  isKeyAssumption2?: boolean
}

export interface Explanation {
  subtext?: string | React.ReactNode
  text: string
}
export interface DealRoomMathConfig {
  defaultArgX: number
  defaultArgY: number
  calculate: CalculateFunc
  presetX: number[]
  presetY: number[]
  titleX: string
  titleY: string
  header1: string
  header2: string
  /**
   * An array of assumption with starting index of 1 so that
   * it's easier to align with step number
   */
  getAssumptions: (input: Args) => ReadonlyArray<Assumption>
  explanations: Explanation[]
  getFormulas: (input: Args) => React.ReactNode[]
  tips: string[]
  latestFinancials: Array<{
    key: string
    value: string
  }>
  valuation: React.ReactNode[]
}

export const formatDollar = (value: number, dp?: number): string => {
  const maximumFractionDigits = Number.isInteger(value) ? 0 : dp ?? 0
  const nf = Intl.NumberFormat('en-AU', {
    maximumFractionDigits,
    style: 'currency',
    currency: 'AUD',
  })
  return `${nf.format(value)}m`
}

export const PlayerDeterminedText: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return <span className="text-error">{children}</span>
}

export const argRange = (start: number, end: number, step: number) => {
  return range(start, end + step, step)
}
