import React, { useMemo } from 'react'
import { DealroomTabs } from '../../../redux/dealroom/dealroomSlice'
import DealRoomBidding from './DealRoomBidding/DealRoomBidding'
import DealRoomValuationRange from './DealRoomValuationRange/DealRoomValuationRange'
import DealRoomValuationCalculation from './DealRoomValuationCalculation/DealRoomValuationCalculation'
import { State } from './state'
import { BidChangeParams, NewArgsSelectedParams } from './types'
import { configs, useDealRoomBidAndRateMapping } from './configs'
import { DealroomResponse } from '../../../types/gameApi/dealroom'

interface Props {
  currentTab: DealroomTabs
  dealroom: DealroomResponse
  state: State
  canBid: boolean
  bidError: string | null
  bidInProgress: boolean
  clearBidError: () => void
  onBidChange: (params: BidChangeParams) => Promise<void>
  onNewArgsSelected?: (params: NewArgsSelectedParams) => void
  onAssumptionUpdate: (index: number, value: number) => void
  onResetAssumptions: () => void
}

const DealRoom: React.FC<Props> = ({
  currentTab,
  canBid,
  dealroom,
  state,
  bidError,
  bidInProgress,
  clearBidError,
  onBidChange,
  onNewArgsSelected,
  onAssumptionUpdate,
  onResetAssumptions,
}) => {
  const assumptions = useMemo(() => {
    if (!configs[dealroom.type]) {
      return []
    }

    const config = configs[dealroom?.type]
    return config
      .getAssumptions({
        argX: config.defaultArgX,
        argY: config.defaultArgY,
      })
      .map((assumption, index) => {
        return {
          ...assumption,
          value:
            state.assumptionOverrides[index] != null &&
            state.assumptionOverrides[index] !== -1
              ? state.assumptionOverrides[index]
              : assumption.value,
        }
      })
  }, [dealroom, state.assumptionOverrides])

  // const { bidToRate } = useDealRoomBidAndRateMapping(dealroom?.type, assumptions)

  const estimatedReturn = useMemo(() => {
    const config = configs[dealroom.type]
    if (!config || !state.bid) {
      return null
    }

    // const [argX, argY] = bidToRate.get(state.bid.toString()) ?? []

    const { estimatedReturn } = config.calculate({
      bid: state.bid,
      // argX: argX,
      // argY: argY,
      assumptions,
    })

    return estimatedReturn
  }, [assumptions, dealroom, state.bid])

  const formulas = useMemo(() => {
    if (!configs[dealroom.type]) {
      return []
    }

    const config = configs[dealroom?.type]
    return config.getFormulas({
      assumptions,
    })
  }, [assumptions, dealroom])

  const { bidToRate } = useDealRoomBidAndRateMapping(dealroom.type, assumptions)

  return (
    <>
      {currentTab === 'overview' && (
        <DealRoomBidding
          dealroom={dealroom}
          estimatedReturn={estimatedReturn}
          canBid={canBid}
          bidError={bidError}
          bidInProgress={bidInProgress}
          clearBidError={clearBidError}
          onBidChange={(bid, enabled) => {
            let argx: number | undefined = undefined
            let argy: number | undefined = undefined
            if (bid && bidToRate.has(bid.toString())) {
              // if user hasn't select anything and we can compare the bid value to check which one is selected
              const val = bidToRate.get(bid.toString())
              if (val) {
                const [selectedX, selectedY] = val
                argx = selectedX
                argy = selectedY
              }
            }
            onBidChange({
              bid,
              argx,
              argy,
              enabled,
            })
          }}
          bid={state.bid}
          bidEnabled={state.bidEnabled ?? true}
          hideReturn={configs[dealroom.type] == null || !state.bidEnabled}
        />
      )}
      {currentTab === 'pricing-matrix' && (
        <DealRoomValuationRange
          onDataSelected={onNewArgsSelected}
          config={configs[dealroom.type]}
          bid={state.bid}
          dealRoomType={dealroom.type}
          argx={state.argx ?? null}
          argy={state.argy ?? null}
          assumptions={assumptions}
          canBid={canBid}
        />
      )}
      {currentTab === 'valuation' && (
        <DealRoomValuationCalculation
          formulas={formulas}
          explanations={configs[dealroom.type].explanations}
          assumptions={assumptions}
          tips={configs[dealroom.type].tips}
          latestFinancials={configs[dealroom.type].latestFinancials}
          valuation={configs[dealroom.type].valuation}
          onAssumptionUpdate={onAssumptionUpdate}
          canBid={canBid}
          resetAssumptions={onResetAssumptions}
        />
      )}
    </>
  )
}

export default DealRoom
