import { CostsResponse } from '../../../../../types/gameApi/costs'

export const data: CostsResponse = {
  totalPeopleCosts: 1738,
  defaultPayrise: 200,
  divisions: [
    {
      id: 1,
      name: 'IT & OPERATIONS',
      type: 'IT_OPERATIONS',
      enabled: true,
      maxValue: 25,
      minValue: -25,
      costSplit: 3500,
      complaints: -200,
      riskIncidents: -175,
      employeeEngagement: 0,
      customerSatisfaction: 300,
      fteCosts: 204,
      fteNewCosts: 416,
      value_change: 1300,
      value_change_original: 1300,
    },
    {
      id: 2,
      name: 'FRONT LINE',
      type: 'FRONT_LINE',
      enabled: true,
      maxValue: 25,
      minValue: -25,
      costSplit: 4000,
      complaints: -200,
      riskIncidents: 0,
      employeeEngagement: 0,
      customerSatisfaction: 500,
      fteCosts: 102,
      fteNewCosts: 156,
      value_change: 1100,
      value_change_original: 1200,
    },
    {
      id: 3,
      name: 'RISK & COMPLIANCE',
      type: 'RISK_COMPLIANCE',
      enabled: true,
      maxValue: 25,
      minValue: -25,
      costSplit: 1500,
      complaints: 0,
      riskIncidents: -250,
      employeeEngagement: -1000,
      customerSatisfaction: 0,
      fteCosts: 224,
      fteNewCosts: 1040,
      value_change: 1700,
      value_change_original: 1200,
    },
    {
      id: 4,
      name: 'HR & TRAINING',
      type: 'HR_TRAINING',
      enabled: true,
      maxValue: 25,
      minValue: -25,
      costSplit: 1000,
      complaints: 0,
      riskIncidents: 0,
      employeeEngagement: 1500,
      customerSatisfaction: 0,
      fteCosts: 214,
      fteNewCosts: 1144,
      value_change: 1100,
      value_change_original: 1200,
    },
    {
      id: 5,
      name: 'SALARY GROWTH',
      type: 'SALARY_GROWTH',
      enabled: true,
      maxValue: 10,
      minValue: -10,
      costSplit: 1,
      complaints: 0,
      riskIncidents: 0,
      employeeEngagement: 1,
      customerSatisfaction: 0,
      fteCosts: null,
      fteNewCosts: null,
      value_change: 200,
      value_change_original: 200,
    },
  ],
  npsChange: 8,
  npsOpening: 1300,
  npsClosingScore: 1949,
  employeeEngagementChange: 0,
  employeeEngagementOpening: 5394,
  employeeEngagementClosingScore: 5650,
  employeeEngagementSalaryIncrease: 1000,
  employeeEngagementSalaryDecrease: 2000,
  openingRoundEmployeeEngagement: 5000,
  outputGain: 700,
  outputLoss: 1200,
  complaintsChange: 270,
  complaintsOpening: 671,
  complaintsClosing: 618,
  openingRoundRiskIncidents: 750,
  riskIncidentsChange: 300,
  riskIncidentsOpening: 706,
  riskIncidentsClosing: 635,
  communityTrustChange: 0,
  communityTrustChangeSetup: 250,
  communityTrustChangeThreshold: 100,
  communityTrustOpening: 5000,
  communityTrustClosing: 5250,
  regulatorActions: 0,
  regulatorActionsOpening: 0,
  regulatorActionsClosing: 0,
  regulatorImpact: 1500,
  fteChangeDivision1: 388,
  fteChangeDivision2: 750,
  fteChangeDivision3: 198,
  fteChangeDivision4: 89,
  salaryChange: 0,
  version: 25,
  updatedBy: 'ca059e4a-9272-420a-aeda-de8479105580',
}
