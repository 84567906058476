import React, { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store'
import {
  finishInAppHelp,
  startInAppHelp,
} from '../../../redux/inAppHelp/inAppHelpSlice'
import { Tours, startTour } from '../../../redux/guidedTours/guidedToursSlice'
import Modal from '../../atoms/Modal/Modal'
import Text from '../../atoms/Text/Text'
import Image from '../../atoms/Image/Image'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import tips from '../../../assets/need-tips-logo.png'
export type Props = {
  storageKey: string
  tour: Tours
  show: boolean
  onOk?: () => void
  onCancel?: () => void
  onClose?: () => void
  heading: string
  description: string
}

const GuidedTourStartModal: React.FC<Props> = ({
  storageKey,
  tour,
  show,
  onOk,
  onCancel,
  onClose,
  heading,
  description,
}) => {
  const { eventId, currentInAppHelp, dismissedInAppHelp, currentTour } =
    useAppSelector(state => ({
      eventId: state.event.details?.eventId,
      currentInAppHelp: state.inAppHelp.currentInAppHelp,
      dismissedInAppHelp: state.inAppHelp.dismissedInAppHelp,
      currentTour: state.guidedTours.currentTour,
    }))

  const dispatch = useAppDispatch()

  const fullStorageKey = useMemo(
    () => `${eventId}-${storageKey}`,
    [eventId, storageKey],
  )

  const shouldShow = useMemo(() => {
    return (
      show &&
      dismissedInAppHelp[fullStorageKey] !== true &&
      currentTour !== tour
    )
  }, [currentTour, dismissedInAppHelp, fullStorageKey, show, tour])

  useEffect(() => {
    if (shouldShow && currentInAppHelp !== fullStorageKey) {
      dispatch(startInAppHelp(fullStorageKey))
    }
  }, [currentInAppHelp, dispatch, fullStorageKey, shouldShow])

  const handleCancel = useCallback(() => {
    dispatch(finishInAppHelp(fullStorageKey))
    onCancel?.()
  }, [dispatch, fullStorageKey, onCancel])

  const handleClose = useCallback(() => {
    dispatch(finishInAppHelp(fullStorageKey))
    onClose?.()
  }, [dispatch, fullStorageKey, onClose])

  const handleOk = useCallback(() => {
    dispatch(finishInAppHelp(fullStorageKey))
    dispatch(startTour(tour))
    onOk?.()
  }, [dispatch, fullStorageKey, onOk, tour])

  return (
    <Modal
      keymap={{
        Enter: handleOk,
      }}
      cancelButtonText="No"
      okButtonText="Yes"
      onOk={handleOk}
      onCancel={handleCancel}
      show={shouldShow}
      onClose={handleClose}
    >
      <Text size="2xl" center weight="bold">
        {heading}
      </Text>
      <HorizontalGroup fullWidth gap={4} verticalCenter>
        <Image src={tips} />
        <VerticalGroup>
          <Text className="w-56" size="xl">
            {description}
          </Text>
        </VerticalGroup>
      </HorizontalGroup>
    </Modal>
  )
}

export default GuidedTourStartModal
