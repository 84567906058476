import React, { useCallback, useEffect, useRef } from 'react'
import Input, { Props as InputAreaProps } from '../../../atoms/Input/Input'

interface Props extends InputAreaProps {}

const AssessmentInputArea: React.FC<Props> = ({ onValueChange, ...rest }) => {
  const InputRef = useRef<HTMLInputElement>(null)

  const adjustHeightForAssessments = useCallback(() => {
    const minHeight = 40

    if (InputRef.current) {
      const isTextAreaEmpty = InputRef.current.value === ''

      if (isTextAreaEmpty) {
        InputRef.current.style.height = `${minHeight}px`
      } else {
        if (InputRef.current.scrollHeight > minHeight) {
          InputRef.current.style.height = 'auto'
          InputRef.current.style.height = `${InputRef.current.scrollHeight}px`
        } else {
          InputRef.current.style.height = `${minHeight}px`
        }
      }

      InputRef.current.className =
        'min-w-64 max-w-80vw h-10 w-full resize-none overflow-y-hidden border-b border-primary bg-transparent text-primary shadow-primary transition ease-in focus:border-t-transparent focus:border-r-transparent focus:border-l-transparent focus:outline-none focus:ring-0 tablet:text-xl laptop:text-xl desktop:text-2xl'
    }
  }, [])

  const onChangeHandler = useCallback(
    (value: string, name: string) => {
      adjustHeightForAssessments()
      if (onValueChange) {
        onValueChange(value, name)
      }
    },
    [adjustHeightForAssessments, onValueChange],
  )

  useEffect(() => {
    if (InputRef.current) {
      adjustHeightForAssessments()
    }
  }, [adjustHeightForAssessments])

  return <Input ref={InputRef} onValueChange={onChangeHandler} {...rest} />
}

export default AssessmentInputArea
