import { useMemo } from 'react'
import {
  TreasuryOpeningBalances,
  TreasuryResponse,
} from '../../../../types/gameApi/treasury'
import { DecisionMap } from '../TreasuryContainer'
import {
  calculateForecastDeductions,
  calculateForecastValues,
  calculateRiskWeightedAssets,
} from '../calculations'
import { AssetOrDeductionItem } from './types'
import { useTranslatedText } from '../../../../hooks/useTranslatedText'

interface CalculateAssetForecastParams {
  openingBalances: TreasuryOpeningBalances
  decisions: DecisionMap
  onlyAssets: boolean
  type?: keyof DecisionMap
  val?: number | string
}

const calculateAssetForecast = ({
  openingBalances,
  decisions,
  type,
  val,
  onlyAssets,
}: CalculateAssetForecastParams) => {
  const decisionsAndGrowth = { ...decisions }
  if (type) {
    // @ts-expect-error no idea
    decisionsAndGrowth[type] = val
  }
  return calculateForecastValues(
    onlyAssets,
    openingBalances,
    decisionsAndGrowth,
  )
}

const isDeduction = (val: number) => val === -1
const isAsset = (val: number) => val >= 0

const getAssetData = ({
  decisions,
  currentBalance,
  riskField,
  riskFieldOriginal,
  growthField,
  forecastBalance,
  name,
  total,
  deduction,
}: {
  decisions: DecisionMap
  currentBalance: number
  riskField: keyof DecisionMap
  riskFieldOriginal: number | undefined
  growthField: keyof DecisionMap
  forecastBalance: number
  name: string
  total: number
  deduction?: boolean
}): AssetOrDeductionItem | null => {
  if (
    riskField === 'desiredCreditRating' ||
    growthField === 'desiredCreditRating'
  ) {
    return null
  }
  const riskWeight = decisions[riskField]

  if (
    (deduction && !isDeduction(riskWeight)) ||
    (!deduction && !isAsset(riskWeight))
  ) {
    return null
  }
  const growth = decisions[growthField]
  return {
    name,
    currentBalance,
    growth,
    growthField,
    forecastBalance,
    riskWeight: deduction ? undefined : riskWeight,
    riskField,
    riskFieldOriginal: riskFieldOriginal,

    total,
  }
}

export const useGetData = ({
  data,
  decisions,
}: {
  data: TreasuryResponse
  decisions: DecisionMap
}) => {
  const homeLoansName = useTranslatedText(
    'businessDecisions.products.HOME_LOANS',
    'HOME_LOANS',
  )
  const businessLoansName = useTranslatedText(
    'businessDecisions.products.BUSINESS_LOANS',
    'BUSINESS_LOANS',
  )
  // const depositsName = useTranslatedText('businessDecisions.products.DEPOSITS', 'DEPOSITS')
  const creditCardsName = useTranslatedText(
    'businessDecisions.products.CREDIT_CARDS',
    'CREDIT_CARDS',
  )
  const wealthName = useTranslatedText(
    'businessDecisions.products.WEALTH_MANAGEMENT',
    'WEALTH_MANAGEMENT',
  )
  const institutionalName = useTranslatedText(
    'businessDecisions.products.INSTITUTIONAL_BANKING',
    'INSTITUTIONAL_BANKING',
  )
  const offshoreBankName = useTranslatedText(
    'businessDecisions.products.OFFSHORE_BANK',
    'OFFSHORE_BANK',
  )
  return useMemo(() => {
    const {
      openingBalances,
      creditCardsEnabled,
      institutionalBankingEnabled,
      offshoreBankEnabled,
      wealthManagementEnabled,
    } = data

    const assetForecasts = calculateAssetForecast({
      openingBalances,
      decisions,
      onlyAssets: false,
    })
    const {
      homeLoansForecast,
      businessLoansForecast,
      creditCardsForecast,
      institutionalBankingForecast,
      offshoreBankForecast,
      wealthForecast,
      investmentsForecast,
      fixedAssetsForecast,
    } = assetForecasts

    const riskWeightedAssets = calculateRiskWeightedAssets(
      assetForecasts,
      decisions,
    )

    const {
      homeLoansRiskWeightedAsset,
      businessLoansRiskWeightedAsset,
      creditCardsRiskWeightedAsset,
      institutionalBankingRiskWeightedAsset,
      offshoreBankRiskWeightedAsset,
      wealthRiskWeightedAsset,
      investmentsRiskWeightedAsset,
      fixedAssetsRiskWeightedAsset,
    } = riskWeightedAssets

    const forecastDeductions = calculateForecastDeductions(
      assetForecasts,
      decisions,
    )

    const {
      homeLoansForecastDeduction,
      businessLoansForecastDeduction,
      creditCardsForecastDeduction,
      institutionalBankingForecastDeduction,
      offshoreBankForecastDeduction,
      wealthForecastDeduction,
      investmentsForecastDeduction,
      fixedAssetsForecastDeduction,
    } = forecastDeductions

    const assets: AssetOrDeductionItem[] = [
      getAssetData({
        name: homeLoansName,
        decisions,
        currentBalance: openingBalances.homeLoans,
        growthField: 'homeLoansGrowth',
        forecastBalance: homeLoansForecast,
        riskField: 'homeLoansRisk',
        riskFieldOriginal: data.homeLoansRiskOriginal,
        total: homeLoansRiskWeightedAsset,
      }),
      getAssetData({
        name: businessLoansName,
        decisions,
        currentBalance: openingBalances.businessLoans,
        growthField: 'businessLoansGrowth',
        forecastBalance: businessLoansForecast,
        riskField: 'businessLoansRisk',
        riskFieldOriginal: data.businessLoansRiskOriginal,
        total: businessLoansRiskWeightedAsset,
      }),
      creditCardsEnabled
        ? getAssetData({
            name: creditCardsName,
            decisions,
            currentBalance: openingBalances.creditCards,
            growthField: 'creditCardsGrowth',
            forecastBalance: creditCardsForecast,
            riskField: 'creditCardsRisk',
            riskFieldOriginal: data.creditCardsRiskOriginal,
            total: creditCardsRiskWeightedAsset,
          })
        : null,
      institutionalBankingEnabled
        ? getAssetData({
            name: institutionalName,
            decisions,
            currentBalance: openingBalances.institutionalBanking,
            growthField: 'institutionalBankingGrowth',
            forecastBalance: institutionalBankingForecast,
            riskField: 'institutionalBankingRisk',
            riskFieldOriginal: data.institutionalBankingRiskOriginal,
            total: institutionalBankingRiskWeightedAsset,
          })
        : null,
      offshoreBankEnabled
        ? getAssetData({
            name: offshoreBankName,
            decisions,
            currentBalance: openingBalances.offshoreBank,
            growthField: 'offshoreBankGrowth',
            forecastBalance: offshoreBankForecast,
            riskField: 'offshoreBankRisk',
            riskFieldOriginal: data.offshoreBankRiskOriginal,
            total: offshoreBankRiskWeightedAsset,
          })
        : null,
      wealthManagementEnabled
        ? getAssetData({
            name: wealthName,
            decisions,
            currentBalance: openingBalances.wealthAssets,
            growthField: 'wealthGrowth',
            forecastBalance: wealthForecast,
            riskField: 'wealthRisk',
            riskFieldOriginal: data.wealthRiskOriginal,
            total: wealthRiskWeightedAsset,
          })
        : null,
      getAssetData({
        name: 'INVESTMENTS',
        decisions,
        currentBalance: openingBalances.investments,
        growthField: 'investmentsGrowth',
        forecastBalance: investmentsForecast,
        riskField: 'investmentsRisk',
        riskFieldOriginal: data.investmentsRiskOriginal,
        total: investmentsRiskWeightedAsset,
      }),
      getAssetData({
        name: 'OTHER FIXED ASSETS',
        decisions,
        currentBalance: openingBalances.fixedAssets,
        growthField: 'fixedAssetsGrowth',
        forecastBalance: fixedAssetsForecast,
        riskField: 'fixedAssetsRisk',
        riskFieldOriginal: data.fixedAssetsRiskOriginal,
        total: fixedAssetsRiskWeightedAsset,
      }),
    ].filter(Boolean) as AssetOrDeductionItem[]

    const deductions: AssetOrDeductionItem[] = [
      getAssetData({
        name: homeLoansName,
        decisions,
        currentBalance: openingBalances.homeLoans,
        growthField: 'homeLoansGrowth',
        forecastBalance: homeLoansForecast,
        riskField: 'homeLoansRisk',
        riskFieldOriginal: data.homeLoansRiskOriginal,
        total: homeLoansForecastDeduction,
        deduction: true,
      }),
      getAssetData({
        name: businessLoansName,
        decisions,
        currentBalance: openingBalances.businessLoans,
        growthField: 'businessLoansGrowth',
        forecastBalance: businessLoansForecast,
        riskField: 'businessLoansRisk',
        riskFieldOriginal: data.businessLoansRiskOriginal,
        total: businessLoansForecastDeduction,
        deduction: true,
      }),
      creditCardsEnabled
        ? getAssetData({
            name: creditCardsName,
            decisions,
            currentBalance: openingBalances.creditCards,
            growthField: 'creditCardsGrowth',
            forecastBalance: creditCardsForecast,
            riskField: 'creditCardsRisk',
            riskFieldOriginal: data.creditCardsRiskOriginal,

            total: creditCardsForecastDeduction,
            deduction: true,
          })
        : null,
      institutionalBankingEnabled
        ? getAssetData({
            name: institutionalName,
            decisions,
            currentBalance: openingBalances.institutionalBanking,
            growthField: 'institutionalBankingGrowth',
            forecastBalance: institutionalBankingForecast,
            riskField: 'institutionalBankingRisk',
            riskFieldOriginal: data.institutionalBankingRiskOriginal,
            total: institutionalBankingForecastDeduction,
            deduction: true,
          })
        : null,
      offshoreBankEnabled
        ? getAssetData({
            name: offshoreBankName,
            decisions,
            currentBalance: openingBalances.offshoreBank,
            growthField: 'offshoreBankGrowth',
            forecastBalance: offshoreBankForecast,
            riskField: 'offshoreBankRisk',
            riskFieldOriginal: data.offshoreBankRiskOriginal,
            total: offshoreBankForecastDeduction,
            deduction: true,
          })
        : null,
      wealthManagementEnabled
        ? getAssetData({
            name: wealthName,
            decisions,
            currentBalance: openingBalances.wealthAssets,
            growthField: 'wealthGrowth',
            forecastBalance: wealthForecast,
            riskField: 'wealthRisk',
            riskFieldOriginal: data.wealthRiskOriginal,
            total: wealthForecastDeduction,
            deduction: true,
          })
        : null,
      getAssetData({
        name: 'INVESTMENTS',
        decisions,
        currentBalance: openingBalances.investments,
        growthField: 'investmentsGrowth',
        forecastBalance: investmentsForecast,
        riskField: 'investmentsRisk',
        riskFieldOriginal: data.investmentsRiskOriginal,
        total: investmentsForecastDeduction,
        deduction: true,
      }),
      getAssetData({
        name: 'OTHER FIXED ASSETS',
        decisions,
        currentBalance: openingBalances.fixedAssets,
        growthField: 'fixedAssetsGrowth',
        forecastBalance: fixedAssetsForecast,
        riskField: 'fixedAssetsRisk',
        riskFieldOriginal: data.fixedAssetsRiskOriginal,
        total: fixedAssetsForecastDeduction,
        deduction: true,
      }),
    ].filter(Boolean) as AssetOrDeductionItem[]

    return { assets, deductions, riskWeightedAssets, forecastDeductions }
  }, [
    data,
    decisions,
    homeLoansName,
    businessLoansName,
    creditCardsName,
    institutionalName,
    offshoreBankName,
    wealthName,
  ])
}
