import React from 'react'
import { Colour } from '../../../types/theme'
import { BACKGROUND_COLOUR_CLASSES } from '../../../lib/colourClasses'
import PrimaryText from '../../atoms/Text/PrimaryText'
import classNames from 'classnames'

interface StatusLabelProps {
  label: string
  colour: Colour
  position?: 'bottom'
}

const StatusLabel: React.FC<StatusLabelProps> = ({
  label,
  colour,
  position = 'top',
}) => {
  return (
    <div
      className={classNames(
        'absolute',
        position === 'bottom'
          ? 'bottom-0 rounded-tr rounded-bl-lg'
          : 'top-0 rounded-tl rounded-br-lg',
        'left-0 z-10 py-2 px-8 text-white',
        BACKGROUND_COLOUR_CLASSES[colour],
      )}
    >
      <PrimaryText weight="bold" size="xl" className="mr-2">
        {label}
      </PrimaryText>
    </div>
  )
}

export default StatusLabel
