import React, { FC, useCallback, useState } from 'react'
// import Clickable from '../../atoms/Clickable/Clickable'
// import { Icon } from '../../atoms/Icon/Icon'
// import Input from '../../atoms/Input/Input'
// import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
// import ErrorModal from '../../atoms/Modal/ErrorModal'
// import NumericInput from '../../atoms/Input/NumericInput'
import Modal from '../../../atoms/Modal/Modal'
import NumericInput from '../../../atoms/Input/NumericInput'
import InlineError from '../../../atoms/InlineError/InlineError'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import ValueChangerButtons from '../../../molecules/ValueChanger/ValueChangerButtons'
import { Props as ValueChangeProps } from '../../../molecules/ValueChanger/ValueChangerModal'
import { DecisionMap } from '../TreasuryContainer'
import { TreasuryResponse } from '../../../../types/gameApi/treasury'
import TreasuryBalanceSheetWSFDetails from './TreasuryBalanceSheetWSFDetails'

interface Props extends ValueChangeProps {}

const TreasuryBalanceSheetWSFModal: FC<Props> = ({
  heading,
  onClose,
  type,
  value,
  // formatValue,
  // onDecrement,
  // onIncrement,
  onChange,
  min,
  max,
  step,
  enableChanges,
  validateInput,
  extraData,
}) => {
  const { data, decisions } = extraData as {
    decisions: DecisionMap
    data: TreasuryResponse
  }
  const [error, setError] = useState<string | null>()
  const [valueToUse, setValueToUse] = useState(value)

  const onValueChange = useCallback((val: number) => {
    setValueToUse(val)
  }, [])

  const onSave = useCallback(() => {
    onChange(valueToUse)
    onClose()
  }, [onChange, onClose, valueToUse])

  // let changeUnit = ''
  // if (type === 'percentage' || type === 'decimal') {
  //   changeUnit = '%'
  // }

  return (
    <Modal
      show
      title={heading}
      titlePosition="center"
      onClose={onClose}
      onOk={onSave}
      okButtonText="Save"
      buttonPosition="center"
    >
      <VerticalGroup gap={4} center className="w-full py-4 tablet:w-96">
        {error && <InlineError message={error} />}
        {/* <VerticalGroup gap={1} center>
          <SecondaryText>
            Previously: {formatValue(previousValue)}
          </SecondaryText>
          <ValueChangeText
            family="secondary"
            value={valueToUse - previousValue}
            textColour="primary"
            changeUnit={changeUnit}
            prefix={type === 'dollar' ? '$' : undefined}
            removeArrow
            removePercentage={type === 'decimal'}
          />
        </VerticalGroup> */}
        <ValueChangerButtons
          value={valueToUse}
          onChange={onValueChange}
          min={min}
          max={max}
          step={step}
          enableChanges={enableChanges}
          increments={[1, 5]}
          incrementFormatter={(val: number) => `${val}B`}
          buttonSize="medium"
        >
          <div className="w-24">
            <NumericInput
              className="h-10 laptop:flex laptop:text-base"
              type={type}
              value={valueToUse}
              onChange={onValueChange}
              onError={setError}
              validateInput={validateInput}
              submitTimeout={5000}
              suffix="m"
              // propogateOnChange
            />
          </div>
        </ValueChangerButtons>
        <TreasuryBalanceSheetWSFDetails
          value={valueToUse}
          data={data}
          decisions={decisions}
          highlightNewFunding
        />
      </VerticalGroup>
    </Modal>
  )
}

export default TreasuryBalanceSheetWSFModal
