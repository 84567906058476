import React from 'react'
import classNames from 'classnames'

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string

  as?: 'div' | 'button'
}

const Clickable: React.FC<Props> = ({ children, className, as, ...rest }) => {
  if (
    rest.onClick == null &&
    rest.onMouseDown == null &&
    rest.onTouchStart == null
  ) {
    return <>{children}</>
  }
  if (as === 'div') {
    return (
      <div
        // @ts-expect-error button props are a bit different
        onClick={rest.onClick}
        className={classNames(
          'appearance-none bg-none',
          {
            'cursor-pointer': !rest.disabled,
            'cursor-not-allowed': rest.disabled,
          },
          className,
        )}
      >
        {children}
      </div>
    )
  }
  return (
    <button
      className={classNames(
        'appearance-none bg-none',
        {
          'cursor-pointer': !rest.disabled,
          'cursor-not-allowed': rest.disabled,
        },
        className,
      )}
      type="button"
      {...rest}
    >
      {children}
    </button>
  )
}

export default Clickable
