import React, { useState } from 'react'
import { FinancialsResponse } from '../../../../types/gameApi/financials'
import { BottomTabType } from '../../../atoms/BottomTabs/BottomTab'
import BottomTabs from '../../../atoms/BottomTabs/BottomTabs'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import BalanceSheetAndCapitalTable from './BalanceSheetAndCapitalTable'
import { useIsMobile } from '../../../../hooks/useIsMobile'

interface Props {
  financials: FinancialsResponse
  roundId: number
}

export type BalanceSheetAndCapitalTabType = 'balance-sheet' | 'capital'

type BalanceSheetAndCapitalTab = BottomTabType<BalanceSheetAndCapitalTabType>

const TABS: BalanceSheetAndCapitalTab[] = [
  {
    icon: 'changes',
    label: 'Balance Sheet',
    value: 'balance-sheet',
  },
  {
    icon: 'dollar',
    label: 'Capital',
    value: 'capital',
  },
]

const BalanceSheetAndCapital: React.FC<Props> = ({ financials, roundId }) => {
  const [selectedTab, setSelectedTab] = useState<BalanceSheetAndCapitalTabType>(
    TABS[0].value,
  )
  const isMobile = useIsMobile()

  return (
    <VerticalGroup full>
      <VerticalGroup data-test="balance-table" full>
        <BalanceSheetAndCapitalTable
          className="hidden laptop:block"
          financials={financials}
          roundId={roundId}
        />
        {isMobile && (
          <BalanceSheetAndCapitalTable
            key={selectedTab}
            className="block laptop:hidden"
            financials={financials}
            roundId={roundId}
            selectedTab={selectedTab}
          />
        )}
      </VerticalGroup>
      <BottomTabs
        className="laptop:hidden"
        tabs={TABS}
        selectedTab={selectedTab}
        onChange={setSelectedTab}
      />
    </VerticalGroup>
  )
}
export default BalanceSheetAndCapital
