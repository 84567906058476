import React, { useMemo } from 'react'
import { Business } from '../../../../types/gameApi/business'
import BusinessDecisionItem from './BusinessDecisionItem'
import { DecisionMap, BusinessDecision } from '../BusinessesContainer'
import InAppHelp from '../../../organisms/InAppHelp/InAppHelp'
import InAppHelpContent from '../../../organisms/InAppHelp/InAppHelpContent'
import BulletPoint from '../../../atoms/BulletPoint/BulletPoint'
import { percentage } from '../../../../lib/formatters'
interface Props {
  data: Business[]
  teamId: number
  decisions: DecisionMap
  onChangeValue: (
    type: string,
    field: keyof BusinessDecision,
    value: number,
  ) => void
  enableChanges: boolean
  roundId: number
  cashRateChange?: number
}

const BusinessDecisions: React.FC<Props> = ({
  data,
  decisions,
  onChangeValue,
  enableChanges,
  roundId,
  cashRateChange = 0,
}) => {
  const interestRateProduct = data.find(d => d.price1Type === 'Interest Rate')
  const shouldShowCashRateChangeHelp =
    cashRateChange !== 0 && !!interestRateProduct
  const steps = useMemo(() => {
    if (!shouldShowCashRateChangeHelp) {
      return []
    }
    return [
      {
        target: `#${
          cashRateChange < 0
            ? `${interestRateProduct.type}-price1-minus-button`
            : `${interestRateProduct.type}-price1-plus-button`
        }`,
        content: (
          <InAppHelpContent isWarning heading="Cash Rate Change">
            <BulletPoint>
              {`The cash rate has
    ${cashRateChange > 0 ? 'increased' : 'decreased'} by
    ${percentage(Math.abs(cashRateChange))}.`}
            </BulletPoint>
            <BulletPoint>
              {`Please consider ${
                cashRateChange > 0 ? 'increasing' : 'decreasing'
              } interest rates on your products.`}
            </BulletPoint>
          </InAppHelpContent>
        ),
        placement: cashRateChange < 0 ? ('left' as const) : ('right' as const),
      },
    ]
  }, [cashRateChange, interestRateProduct, shouldShowCashRateChangeHelp])

  return (
    <div className="grid h-full grid-cols-1 gap-3 gap-y-1 laptop:grid-cols-2 monitor:gap-y-3">
      {data.map((d, i) => (
        <div key={i} className={`business-decision-item-${d.type}`}>
          <BusinessDecisionItem
            key={i}
            index={i}
            data={d}
            decisions={decisions[d.type]}
            onChangeValue={onChangeValue}
            enableChanges={enableChanges}
            isFirstItem={i === 0}
          />
        </div>
      ))}
      {shouldShowCashRateChangeHelp && (
        <InAppHelp
          isWarning
          mode="beacon"
          steps={steps}
          storageKey={`round-${roundId}business-cash-rate-help`}
          analytics={{
            type: 'static',
            params: {
              category: 'business',
              label: 'cash-rate-help',
              action: 'open',
            },
          }}
        />
      )}
    </div>
  )
}

export default BusinessDecisions
