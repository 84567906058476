import React, { FC } from 'react'

import { useAppSelector } from '../../../../store'
import { selectBranding } from '../selectors'
import Page, { Props as PageProps } from '../../../atoms/Page/Page'

interface Props extends PageProps {}

const BrandedPage: FC<Props> = props => {
  const branding = useAppSelector(selectBranding)
  const style: React.CSSProperties = props.style ?? {}
  if (branding.mainBackgroundColour) {
    style.backgroundColor = branding.mainBackgroundColour
  }
  return <Page {...props} style={style} />
}

export default BrandedPage
