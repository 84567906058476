import React, { useCallback, useState } from 'react'
import { Business, ProductType } from '../../../../types/gameApi/business'
import { BusinessDecision, DecisionMap } from '../BusinessesContainer'
import BusinessPriceVolumeCharts, {
  ChartType,
} from './BusinessPriceVolumeCharts'
import BusinessPriceVolumeDetail from './BusinessPriceVolumeDetail'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
interface Props {
  data: Business[]
  currentBusiness: Business['type']
  decisions: DecisionMap
  onChangeValue: (
    type: string,
    field: keyof BusinessDecision,
    value: number,
  ) => void
  changeCurrentBusiness: (type: ProductType) => void
  enableChanges: boolean

  setShowModal: (val: boolean) => void
  handleReset: () => void
}

const BusinessPriceVolumeTradeOff: React.FC<Props> = ({
  data,
  currentBusiness,
  decisions,
  onChangeValue,
  changeCurrentBusiness,
  enableChanges,
  setShowModal,
  handleReset,
}) => {
  // Add a state to track the selected chart
  const [selectedChart, setSelectedChart] = useState<ChartType>('price')

  const handleChangeValue = useCallback(
    (type: string, field: keyof BusinessDecision, value: number) => {
      if (
        ['price1Now', 'price2Now'].includes(field) &&
        selectedChart !== 'price'
      ) {
        setSelectedChart('price')
      }
      if (['volumeNow'].includes(field) && selectedChart !== 'volume') {
        setSelectedChart('volume')
      }
      onChangeValue(type, field, value)
    },
    [onChangeValue, selectedChart],
  )

  return (
    <VerticalGroup fullWidth full>
      <div className="h-64 tablet:hidden"></div>
      <div className="top-15 fixed z-10 w-full tablet:hidden">
        <BusinessPriceVolumeCharts
          data={data}
          currentBusiness={currentBusiness}
          decisions={decisions}
          selectedChart={selectedChart}
          setSelectedChart={setSelectedChart}
        />
      </div>
      <HorizontalGroup fullHeight fullWidth className="hidden tablet:block">
        <BusinessPriceVolumeCharts
          data={data}
          currentBusiness={currentBusiness}
          decisions={decisions}
          selectedChart={selectedChart}
          setSelectedChart={setSelectedChart}
        />
      </HorizontalGroup>

      <div>
        <BusinessPriceVolumeDetail
          handleReset={handleReset}
          setShowModal={setShowModal}
          data={data}
          currentBusiness={currentBusiness}
          decisions={decisions}
          onChangeValue={handleChangeValue}
          changeCurrentBusiness={changeCurrentBusiness}
          enableChanges={enableChanges}
        />
      </div>
    </VerticalGroup>
  )
}

export default BusinessPriceVolumeTradeOff
