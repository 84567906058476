import React, { useCallback, useMemo } from 'react'
import InAppHelpContent from '../../../organisms/InAppHelp/InAppHelpContent'
import Text from '../../../atoms/Text/Text'
import GuidedTour, {
  GuidedTourStep,
} from '../../../organisms/InAppHelp/GuidedTour'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { finishTour } from '../../../../redux/guidedTours/guidedToursSlice'
import {
  PeerInsightsMetricTabs,
  PeerInsightsTabs,
} from '../../../../redux/peer-insights/peerInsightsSlice'
import { PeerInsightsData } from '../../../../types/gameApi/peerInsights'

interface Props {
  setTab: (tab: PeerInsightsTabs) => void
  setMetric: (tab: PeerInsightsMetricTabs) => void
  metrics: PeerInsightsData[]
}

const PeerInsightsTour: React.FC<Props> = ({ setTab, setMetric, metrics }) => {
  const dispatch = useAppDispatch()
  const currentTour = useAppSelector(state => state.guidedTours.currentTour)

  const changeToMetricTab = useCallback(() => {
    setTab('metrics')
  }, [setTab])

  const changeToNPSMetric = useCallback(() => {
    setMetric('NPS')
  }, [setMetric])

  const isNPSEnabled = useMemo(() => {
    const nps = metrics.find(m => m.type === 'NPS')
    if (nps && nps.enabled) {
      return true
    }
    return false
  }, [metrics])

  const steps: Array<Partial<GuidedTourStep>> = useMemo(() => {
    const possibleSteps: Array<Partial<GuidedTourStep> | undefined> = [
      {
        target: '#tour-market-share-pricing',
        content: (
          <InAppHelpContent heading="Market Share & Pricing">
            <Text left>
              {
                'This screen displays the pricing, balances, market share, and profitability for each bank in the market.'
              }
            </Text>
          </InAppHelpContent>
        ),
      },

      {
        target: '#tour-product',
        content: (
          <InAppHelpContent heading={'Product Level Information'}>
            <Text left>
              {
                'View details for each banking product. Select a product to explore its specific information.'
              }
            </Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-balances',
        content: (
          <InAppHelpContent heading="Balances">
            <Text left>
              {
                "This table is ranked by balances, from highest to lowest. View each bank's balance for the product and its annual change."
              }
            </Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-market-share',
        content: (
          <InAppHelpContent heading="Market Share">
            <Text left>
              {
                'This indicates the percentage of the market each bank holds and shows the change over the past year.'
              }
            </Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-interest-rate',
        content: (
          <InAppHelpContent heading="Interest Rate">
            <Text left>
              {
                'This displays the rate offered by each bank last year and the change compared to the previous year.'
              }
            </Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-secondary-lever',
        content: (
          <InAppHelpContent heading="Secondary Lever">
            <Text left>
              {
                'This section provides additional insights into how competitors influence customer decisions.'
              }
            </Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-profit-after-tax',
        content: (
          <InAppHelpContent heading="Net Profit After Tax ">
            <Text left>
              {
                'This shows the profit each bank earned from this product last year, including changes from the previous year. Note that this figure is influenced by internal transfer pricing, a more important measurement of is the total bank NPAT'
              }
            </Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-market-average',
        content: (
          <InAppHelpContent heading="Total Market Average">
            <Text left>
              {
                "Compare your bank's rates to the market average. More competitive rates can help attract customers."
              }
            </Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-summary-metrics',
        content: (
          <InAppHelpContent heading="Summary Metrics">
            <Text left>
              {
                'Select this button to see more detail on each of the summary metrics.'
              }
            </Text>
          </InAppHelpContent>
        ),
        onClick: changeToMetricTab,
      },
      {
        target: '#tour-metrics',
        content: (
          <InAppHelpContent heading="Summary Metric">
            <Text left>
              {'Choose the metrics you want to explore in more detail.'}
            </Text>
          </InAppHelpContent>
        ),
        onClick: isNPSEnabled ? changeToNPSMetric : undefined,
      },
      isNPSEnabled
        ? {
            target: '#tour-metric-breakdown',
            content: (
              <InAppHelpContent heading="Metric Breakdown">
                <Text left>
                  {'Metrics such as NPS are detailed by product.'}
                </Text>
              </InAppHelpContent>
            ),
          }
        : undefined,
      {
        target: '#tour-first-team',
        content: (
          <InAppHelpContent heading="Highlight Competitors">
            <Text left>
              {'Choose a competitor bank to highlight in the chart.'}
            </Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-metric-value-last',
        content: (
          <InAppHelpContent heading="Metric Value">
            <Text left>
              Displays the current value of the selected metric for your chosen
              bank.
            </Text>
          </InAppHelpContent>
        ),
      },
    ]

    const enabledSteps: Array<Partial<GuidedTourStep>> = possibleSteps.filter(
      s => s !== undefined,
    ) as Array<Partial<GuidedTourStep>>

    return enabledSteps
  }, [changeToMetricTab, changeToNPSMetric, isNPSEnabled])

  const handleCloseTour = useCallback(() => {
    dispatch(finishTour())
  }, [dispatch])

  if (currentTour !== 'peer-insights') {
    return null
  }

  return <GuidedTour disableScrolling steps={steps} onClose={handleCloseTour} />
}

export default PeerInsightsTour
