import React, { useMemo } from 'react'
import { Step } from 'react-joyride'
import Button from '../../../atoms/Button/Button'
import Clickable from '../../../atoms/Clickable/Clickable'
import Text from '../../../atoms/Text/Text'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { useKeypress } from '../../../../hooks/useKeyPress'

export const CustomNextButton: React.FC<{
  onClick: () => void
  isLastStep: boolean
}> = ({ onClick, isLastStep }) => (
  <Button
    buttonType="secondary"
    size="small"
    rounded="full"
    onClick={onClick}
    autoFocus
  >
    {isLastStep ? 'Got It' : 'Next'}
  </Button>
)

export const CustomSkipButton: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => (
  <Clickable onClick={onClick}>
    <Text>Skip</Text>
  </Clickable>
)

export const CustomProgress: React.FC<{
  currentStep: number
  totalSteps: number
}> = ({ currentStep, totalSteps }) => (
  <>
    {totalSteps > 1 && (
      <Text>
        {currentStep + 1}/{totalSteps}
      </Text>
    )}
  </>
)

export const CustomStep: React.FC<{
  step: Partial<Step>
  steps: Array<Partial<Step>>
  stepIndex: number
  handleSkipClick: () => void
  handleNextClick: () => void
}> = ({ step, steps, stepIndex, handleNextClick, handleSkipClick }) => {
  useKeypress({
    keymap: {
      ArrowRight: handleNextClick,
      ArrowDown: handleNextClick,
      Enter: handleNextClick,
    },
  })
  return (
    <VerticalGroup gap={4}>
      {step.content}
      <HorizontalGroup between fullWidth verticalCenter>
        <HorizontalGroup
          verticalCenter
          between={steps.length > 1}
          right={steps.length === 1}
          fullWidth
        >
          {steps.length > 1 && (
            <>
              <CustomProgress
                currentStep={stepIndex}
                totalSteps={steps.length}
              />
              <CustomSkipButton onClick={handleSkipClick} />
            </>
          )}
          <CustomNextButton
            onClick={handleNextClick}
            isLastStep={stepIndex === steps.length - 1}
          />
        </HorizontalGroup>
      </HorizontalGroup>
    </VerticalGroup>
  )
}

interface CustomTourStepsProps {
  steps: Array<Partial<Step>>
  stepIndex: number
  handleSkipClick: () => void
  handleNextClick: () => void
  mode: 'floater' | 'beacon'
  isWarning?: boolean
}

const commonStepProps = {
  hideFooter: true,
}

export const CustomTourSteps = ({
  steps,
  stepIndex,
  handleSkipClick,
  handleNextClick,
  mode,
  isWarning = false,
}: CustomTourStepsProps): Step[] => {
  const stepsWithCustomButton = useMemo(
    () =>
      steps.map(step => ({
        ...commonStepProps,
        ...step,
        target: step.target || '',
        disableBeacon: mode === 'floater',
        placement: step.placement || ('auto' as const),
        floaterProps:
          mode === 'floater'
            ? {
                offset: 10,
                styles: {
                  floater: { transform: 'translate(-30px, 40px)' },
                },
              }
            : undefined,
        styles: {
          beacon: {
            margin: 17,
          },
          tooltip: {
            margin: -3,
            borderRadius: 25,
            border: isWarning ? '2px solid #F9AA3C' : '2px solid #28A4F2',
          },
        },
        content: (
          <CustomStep
            step={step}
            steps={steps}
            stepIndex={stepIndex}
            handleSkipClick={handleSkipClick}
            handleNextClick={handleNextClick}
          />
        ),
      })),
    [steps, handleSkipClick, handleNextClick, stepIndex, mode, isWarning],
  )

  return stepsWithCustomButton
}
