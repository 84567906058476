import React from 'react'
import Modal, { Props as ModalProps } from './Modal'

interface Props extends Omit<ModalProps, 'show' | 'type' | 'title'> {
  showModal: boolean
  heading: string
  onClose: () => void
  message: string
}

const WarningModal: React.FC<Props> = ({
  showModal,
  heading,
  onClose,
  message,
  ...rest
}) => {
  return (
    <Modal
      {...rest}
      type="warning"
      show={showModal}
      title={heading}
      onClose={onClose}
      onOk={rest.onOk ?? onClose}
    >
      <div className="pt-4">
        <p>{message}</p>
      </div>
    </Modal>
  )
}

export default WarningModal
