import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchSummary } from './summaryActions'
import APIError from '../../errors/APIError'
import { SummaryResponse } from '../../types/gameApi/summary'
import { reset } from '../game/gameSlice'
import { logout } from '../auth/authSlice'
import ThunkCancelledError from '../../errors/ThunkCancelledError'

interface SummaryState {
  currentPage: number
  isLoading: boolean
  isLoaded: boolean
  summaries: {
    [roundId: number]: SummaryResponse[]
  }
  requestInProgress: string | null
  error: Error | APIError | null
}

const initialState: SummaryState = {
  currentPage: 0,
  isLoading: false,
  isLoaded: false,
  summaries: {},
  requestInProgress: null,
  error: null,
}

const summarySlice = createSlice({
  name: 'summary',
  initialState,
  reducers: {
    changePage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(reset, () => {
        return initialState
      })
      .addCase(logout, () => {
        return initialState
      })
      .addCase(fetchSummary.pending, (state, { meta }) => {
        if (!state.isLoading) {
          state.requestInProgress = meta.requestId
          state.isLoading = true
          state.error = null
        }
      })
      .addCase(fetchSummary.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isLoaded = true
        state.summaries[payload.roundId] = payload.summaries
        state.requestInProgress = null
      })
      .addCase(fetchSummary.rejected, (state, { payload }) => {
        if (payload instanceof ThunkCancelledError) {
          return
        }
        state.isLoading = false
        state.error = payload ?? null
        state.requestInProgress = null
      })
  },
})

export default summarySlice.reducer
