import classNames from 'classnames'
import React from 'react'
import { Icon, IconType } from '../Icon/Icon'

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'default'
  | 'bordered'
  | 'orange'
  | 'success'
export type ButtonSize = 'xs' | 'small' | 'large' | 'xl' | 'responsive'

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: ButtonType
  className?: string
  text?: string
  rounded?: 'md' | 'lg' | 'full'
  size?: ButtonSize
  full?: boolean
  children?: React.ReactNode
  iconType?: IconType | null
}

const buttonTypeStylings: Record<ButtonType, string> = {
  primary: 'bg-primary text-white hover:bg-primary-700 focus:ring-primary-500',
  secondary: 'border border-primary text-primary',
  error: 'bg-error text-white hover:bg-error-700 focus:ring-error-500',
  success: 'bg-success text-white hover:bg-success-700 focus:ring-success-500',
  orange: 'bg-orange text-white hover:bg-error-700 focus:ring-error-500',
  default: 'bg-white ',
  bordered: 'border border-gray-400',
}

const Button: React.FC<Props> = ({
  buttonType = 'primary',
  className,
  text,
  rounded = 'md',
  size = 'large',
  full,
  children,
  iconType = null,
  ...rest
}) => {
  const isDisabled = rest.disabled
  const disabledStyles = isDisabled
    ? buttonType === 'primary'
      ? 'bg-gray-200 hover:bg-gray-200 text-gray-800'
      : buttonType === 'secondary'
      ? 'border border-gray-300 text-gray-300'
      : ''
    : ''

  return (
    <button
      {...rest}
      type="submit"
      className={classNames(
        className,
        'group relative flex flex-row-reverse items-center justify-center gap-2 focus:outline-none',
        disabledStyles === '' ? buttonTypeStylings[buttonType] : disabledStyles,
        {
          'h-10 w-40': size === 'large',
          'h-12 w-44': size === 'xl',
          'h-10 w-28': size === 'small',
          'h-10 px-4': size === 'responsive',
          'p-2': size === 'xs',
          'w-full': full,
          'rounded-md': rounded === 'md',
          'rounded-lg': rounded === 'lg',
          'rounded-full': rounded === 'full',
        },
      )}
    >
      {iconType ? <Icon colour="black" type={iconType}></Icon> : null}
      {children}
      {text}
    </button>
  )
}

export default Button
