import gameAPI from '../../services/gameApi'
import { RegisterDeviceParams } from '../../types/gameApi/device'

export const getOrRegisterDevice = async ({
  deviceId,
  team,
  isMaster,
}: RegisterDeviceParams) => {
  try {
    const data = await gameAPI.getDevice(deviceId)

    return data
  } catch (e: any) {
    // Device might not be setup yet, thats ok we ignore and move on and try to register it
    try {
      const data = await gameAPI.registerDevice({ deviceId, team, isMaster })
      return data
    } catch (e: any) {
      if (
        e.status === 422 &&
        e.message === 'This team already has a master device'
      ) {
        const data = await gameAPI.registerDevice({
          deviceId,
          team,
          isMaster: false,
        })
        return data
      } else {
        throw e
      }
    }
  }
}
