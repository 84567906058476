import React, { useCallback } from 'react'

import { useAppSelector } from '../../../../store'
import { useNavigate } from 'react-router-dom'
import Page from '../../../atoms/Page/Page'
import StrategyCard from '../StrategyCard'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import BrandedVerticalGroup from '../../../organisms/Branding/BrandedVerticalGroup/BrandedVerticalGroup'
import BrandedHorizontalGroup from '../../../organisms/Branding/BrandedHorizontalGroup/BrandedHorizontalGroup'
import BrandedButton from '../../../organisms/Branding/BrandedButton/BrandedButton'
import { useTranslatedText } from '../../../../hooks/useTranslatedText'

interface Props {}

const TeamStrategy: React.FC<Props> = () => {
  const { strategy } = useAppSelector(state => ({
    strategy: state.team.strategy,
  }))

  const letsGoButtonLabel = useTranslatedText('team.startButton', "Let's Go")

  const navigate = useNavigate()

  const onSubmit = useCallback(() => {
    navigate('/summary')
  }, [navigate])

  return (
    <BrandedVerticalGroup full className="flex-1">
      <Page>
        <VerticalGroup
          fullWidth
          full
          verticalCenter
          center
          className="pt-4 tablet:h-[75vh]"
        >
          <VerticalGroup fullWidth className="tablet:max-w-2xl">
            <StrategyCard
              data-test="team-strategy"
              iconColour="orange"
              heading={'Team Strategy Reminder'}
              themeColor={'orange'}
              icon={'idea'}
              strategy={strategy || ''}
            />
          </VerticalGroup>
        </VerticalGroup>
      </Page>
      <BrandedHorizontalGroup
        verticalCenter
        center
        className="fixed bottom-0 h-20 w-full bg-gray-900"
      >
        <BrandedButton className="border border-white" onClick={onSubmit}>
          {letsGoButtonLabel}
        </BrandedButton>
      </BrandedHorizontalGroup>
    </BrandedVerticalGroup>
  )
}

export default TeamStrategy
