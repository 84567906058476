import { BusinessResponse } from '../../../../../types/gameApi/business'

export const data: BusinessResponse = {
  businesses: [
    {
      history: [
        {
          year: 0,
          price1: 488,
          volume: 162304,
        },
        {
          year: 1,
          price1: 476,
          volume: 182855,
        },
      ],
      id: 1,
      price1Prev: 476,
      price1Now: 504,
      price1NowOriginal: 476,
      slider1Min: 376,
      slider1Max: 576,
      price2Prev: 18,
      price2Now: 18,
      price2NowOriginal: 18,
      slider2Min: 13,
      slider2Max: 23,
      volumePrev: 182855,
      volumeNow: 204753,
      volumeNowOriginal: 204753,
      incomePrev: 8215,
      type: 'HOME_LOANS',
      name: 'HOME LOANS',
      price1Type: 'Interest Rate',
      price1TypeInfoBody:
        'Lower interest rates might attract more customers, but may also mean less income. The reverse is also true',
      price2Type: 'Broker Commissions',
      price2TypeInfoBody:
        'The annual investment aims to simplify broker interactions with your bank. It includes dedicated broker channel support, ongoing process simplification, transparent credit decision-making, clear commission structures, and ongoing education. Increased investment in brokers will raise operating costs. Customers acquired through brokers tend to be price-sensitive, potentially less loyal, and prone to higher loss rates during stressful periods.',
      version: 1,
      updatedBy: '942620dd-e983-4fb1-995e-621f0c7c88d6',
    },
    {
      history: [
        {
          year: 0,
          price1: 618,
          volume: 56814,
        },
        {
          year: 1,
          price1: 643,
          volume: 57974,
        },
      ],
      id: 2,
      price1Prev: 643,
      price1Now: 667,
      price1NowOriginal: 643,
      slider1Min: 543,
      slider1Max: 743,
      price2Prev: 1,
      price2Now: 1,
      price2NowOriginal: 1,
      slider2Min: -1,
      slider2Max: 1,
      volumePrev: 57974,
      volumeNow: 62432,
      volumeNowOriginal: 62432,
      incomePrev: 3690,
      type: 'BUSINESS_LOANS',
      name: 'BUSINESS LOANS',
      price1Type: 'Interest Rate',
      price1TypeInfoBody:
        'Lower interest rates might attract more customers, but may also mean less income. The reverse is also true',
      price2Type: 'Lending Criteria',
      price2TypeInfoBody:
        'Tighter lending criteria restricts lending to better quality customers, which may reduce future losses. This may also limit growth. The opposite is true for loose lending criteria.',
      version: 1,
      updatedBy: '942620dd-e983-4fb1-995e-621f0c7c88d6',
    },
    {
      history: [
        {
          year: 0,
          price1: 263,
          volume: 116600,
        },
        {
          year: 1,
          price1: 274,
          volume: 125142,
        },
      ],
      id: 3,
      price1Prev: 274,
      price1Now: 299,
      price1NowOriginal: 274,
      slider1Min: 174,
      slider1Max: 374,
      price2Prev: 815,
      price2Now: 815,
      price2NowOriginal: 815,
      slider2Min: 765,
      slider2Max: 865,
      volumePrev: 125142,
      volumeNow: 131399,
      volumeNowOriginal: 131399,
      incomePrev: -3312,
      type: 'DEPOSITS',
      name: 'DEPOSITS',
      price1Type: 'Interest Rate',
      price1TypeInfoBody:
        'Higher interest rates attract more customers as customers often like to leave their money where it is earning them good rates. Paying higher interest rates costs your bank more.',
      price2Type: 'Branches',
      price2TypeInfoBody:
        'Improving customers’ access through a larger network may attract more customers. Each new branch costs 1m to fit-out and 500k p.a. to maintain (leasing). Reducing the size of the physical network reduces ongoing leasing costs, however expect 1 additional customer complaint per branch closed.',
      version: 1,
      updatedBy: '942620dd-e983-4fb1-995e-621f0c7c88d6',
    },
    {
      history: [
        {
          year: 0,
          price1: 250,
          volume: 1796523,
        },
        {
          year: 1,
          price1: 275,
          volume: 1675718,
        },
      ],
      id: 4,
      price1Prev: 275,
      price1Now: 275,
      price1NowOriginal: 275,
      slider1Min: 175,
      slider1Max: 375,
      price2Prev: 150,
      price2Now: 150,
      price2NowOriginal: 150,
      slider2Min: 100,
      slider2Max: 200,
      volumePrev: 1675718,
      volumeNow: 1579364,
      volumeNowOriginal: 1579364,
      incomePrev: 469,
      type: 'CREDIT_CARDS',
      name: 'CREDIT CARDS',
      price1Type: 'Annual Card Fee',
      price1TypeInfoBody:
        'Lower annual fees attract more customers. Lower fees may generate less income.',
      price2Type: 'Reward Points',
      price2TypeInfoBody:
        'Paying higher reward points attracts more customers however paying higher reward points will increase costs.',
      version: 0,
      updatedBy: null,
    },
  ],
  rates: {
    creditCards: {
      ratePrev: 1235,
      rateNow: 1235,
    },
    cash: {
      ratePrev: 250,
      rateNow: 275,
    },
    wsf: {
      ratePrev: 366,
      rateNow: 391.8742501960117,
    },
  },
  volume: {
    creditCards: {
      volumePrev: 12127,
      groupBalanceSheetValue: 12127,
    },
    cash: {
      volumePrevPrev: 15547,
      volumePrev: 18464,
      volumeNow: 14996,
    },
    savings: {
      volumePrevPrev: 116600,
      volumePrev: 125142,
    },
    transactions: {
      volumePrevPrev: 38867,
      volumePrev: 41714,
    },
    wsf: {
      volumePrevPrev: 86512,
      volumePrev: 99311,
      volumeNow: 112411,
    },
  },
  interest: {
    cash: {
      interestPrevPrev: 416,
      interestPrev: 443,
    },
    nim: {
      interestPrev: 276,
    },
    returnOnAssets: {
      interestPrev: 535,
    },
    costOfFunds: {
      interestPrev: 264,
    },
  },
}
