import React, { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store'
import { fetchFinancials } from '../../../redux/financials/financialsActions'
import CentredSpinner from '../../molecules/CentredSpinner/CentredSpinner'
import FinancialSummary from './FinancialSummary/FinancialSummary'
import ProfitAndLoss from './ProfitAndLoss/ProfitAndLoss'
import Tabs from '../../atoms/Tabs/Tabs'
import {
  changeTab,
  FinancialsTabs,
} from '../../../redux/financials/financialsSlice'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'

import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import DropDown from '../../atoms/DropDown/DropDown'
import DivisionalProfitAndLoss from './DivisionalProfitAndLoss/DivisionalProfitAndLoss'
import BalanceSheetAndCapital from './BalanceSheetAndCapital/BalanceSheetAndCapital'
import Page from '../../atoms/Page/Page'
import Tab from '../../atoms/Tabs/Tab'
import MobilePageHeader from '../../organisms/MobilePageHeader/MobilePageHeader'
import InlineError from '../../atoms/InlineError/InlineError'
import TabChanger from '../../atoms/TabChanger/TabChanger'
import { useTranslatedText } from '../../../hooks/useTranslatedText'
import { useSearchParams } from 'react-router-dom'
import OurFinancialsTour from './OurFinancialsTour/OurFinancialsTour'
import { useIsMobile } from '../../../hooks/useIsMobile'

interface Props {}

const OurFinancials: React.FC<Props> = () => {
  const {
    currentTab,
    isLoading,
    financials,
    error,
    teamId,
    roundId,
    demoEnabled,
    demoEnabledFinancialMetrics,
  } = useAppSelector(state => ({
    currentTab: state.financials.currentTab,
    isLoading: state.financials.isLoading,
    financials: state.financials.financials,
    error: state.financials.error,
    teamId: state.event.details?.team ?? 0,
    roundId: state.game.selectedRound,
    demoEnabled: state.demo.enabled,
    demoEnabledFinancialMetrics: state.demo.enabledFinancialMetrics,
  }))

  const financialSummaryHeading = useTranslatedText(
    'ourFinancials.summaryMetrics.screenHeader',
    'FINANCIAL SUMMARY',
  )
  const profitAndLossHeading = useTranslatedText(
    'ourFinancials.groupProfitLoss.screenHeader',
    'PROFIT & LOSS',
  )
  const divisionalProfitAndLossHeading = useTranslatedText(
    'ourFinancials.divisionalProfitLoss.screenHeader',
    'PROFIT & LOSS BY DIVISION',
  )
  const balanceSheetHeading = useTranslatedText(
    'ourFinancials.ourFinancials.groupBalanceSheet.screenHeader.screenHeader',
    'BALANCE SHEET & CAPITAL',
  )

  const TABS: Array<{ label: string; value: FinancialsTabs; id?: string }> =
    useMemo(
      () => [
        {
          label: financialSummaryHeading,
          value: 'summary',
          id: 'tour-summary',
        },
        {
          label: profitAndLossHeading,
          value: 'profit-and-loss',
          id: 'tour-profit-loss',
        },
        {
          label: divisionalProfitAndLossHeading,
          value: 'division-profit-and-loss',
          id: 'tour-profit-loss-division',
        },
        {
          label: balanceSheetHeading,
          value: 'balance-sheet',
          id: 'tour-balance-sheet',
        },
      ],
      [
        financialSummaryHeading,
        profitAndLossHeading,
        divisionalProfitAndLossHeading,
        balanceSheetHeading,
      ],
    )
  const isMobile = useIsMobile()
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const fetchFinancialsData = useCallback(() => {
    dispatch(fetchFinancials({ roundId, teamId }))
  }, [dispatch, roundId, teamId])

  useEffect(() => {
    if (!isLoading && !error && !financials[roundId]) {
      fetchFinancialsData()
    }
  }, [dispatch, error, fetchFinancialsData, financials, isLoading, roundId])

  useEffect(() => {
    const queryTab = searchParams.get('tab')
    if (queryTab && TABS.some(tab => tab.value === queryTab)) {
      dispatch(changeTab(queryTab as FinancialsTabs))
    }
  }, [dispatch, TABS, searchParams])

  const setTab = useCallback(
    (tab: FinancialsTabs) => {
      dispatch(changeTab(tab))
      setSearchParams({ tab })
    },
    [dispatch, setSearchParams],
  )

  if (error) {
    return (
      <Page full>
        <InlineError message={error.message} onRetry={fetchFinancialsData} />
      </Page>
    )
  }

  if (isLoading || !financials[roundId]) {
    return <CentredSpinner />
  }

  const enabledMetrics = (financials[roundId].summary ?? []).filter(s => {
    if (demoEnabled) {
      return demoEnabledFinancialMetrics.includes(s.type)
    }
    return s.enabled
  })

  return (
    <Page full>
      <TabChanger
        currentTab={currentTab as string}
        tabs={TABS.map(t => t.value as string)}
        setTab={tab => setTab(tab as FinancialsTabs)}
      />
      <VerticalGroup full gap={4}>
        <MobilePageHeader>
          <DropDown<FinancialsTabs>
            items={TABS}
            selectedItem={currentTab}
            onSelectedItemChange={item => setTab(item.value as FinancialsTabs)}
          />
        </MobilePageHeader>
        <HorizontalGroup className="hidden tablet:block" fullWidth>
          <Tabs>
            {TABS.map(t => (
              <Tab
                key={t.value as string}
                tab={t}
                onClick={() => setTab(t.value)}
                active={currentTab === t.value}
                id={t.id}
              />
            ))}
          </Tabs>
        </HorizontalGroup>

        {currentTab === 'summary' && (
          <FinancialSummary summaryItems={enabledMetrics} />
        )}
        {currentTab === 'profit-and-loss' && (
          <ProfitAndLoss financials={financials[roundId]} roundId={roundId} />
        )}
        {currentTab === 'division-profit-and-loss' && (
          <DivisionalProfitAndLoss
            financials={financials[roundId]}
            roundId={roundId}
          />
        )}
        {currentTab === 'balance-sheet' && (
          <BalanceSheetAndCapital
            financials={financials[roundId]}
            roundId={roundId}
          />
        )}
      </VerticalGroup>
      {!isMobile && <OurFinancialsTour setTab={setTab} />}
    </Page>
  )
}

export default OurFinancials
