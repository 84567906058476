import { PeerInsightsResponse } from '../../../../../types/gameApi/peerInsights'

export const data: PeerInsightsResponse = {
  marketShare: [
    {
      type: 'HOME_LOANS',
      name: 'HOME LOANS',
      teams: [
        {
          team: 5,
          type: 'HOME_LOANS',
          name: 'Sigma Bank',
          values: {
            balances: 225526,
            marketShare: 1395,
            interestRate: 441,
            commission: 6,
            npat: -3823,
          },
          lastValues: {
            balances: 209215,
            marketShare: 1307,
            interestRate: 493,
            commission: 11,
            npat: -1537,
          },
        },
        {
          team: 1,
          type: 'HOME_LOANS',
          name: 'Alpha Bank',
          values: {
            balances: 221396,
            marketShare: 1370,
            interestRate: 484,
            commission: 18,
            npat: 1074,
          },
          lastValues: {
            balances: 211667,
            marketShare: 1323,
            interestRate: 504,
            commission: 18,
            npat: 1092,
          },
        },
        {
          team: 6,
          type: 'HOME_LOANS',
          name: 'Omega Bank',
          values: {
            balances: 210377,
            marketShare: 1301,
            interestRate: 497,
            commission: 27,
            npat: 956,
          },
          lastValues: {
            balances: 202881,
            marketShare: 1268,
            interestRate: 522,
            commission: 25,
            npat: 1200,
          },
        },
        {
          team: 3,
          type: 'HOME_LOANS',
          name: 'Gamma Bank',
          values: {
            balances: 208559,
            marketShare: 1290,
            interestRate: 478,
            commission: 18,
            npat: 540,
          },
          lastValues: {
            balances: 210040,
            marketShare: 1312,
            interestRate: 498,
            commission: 18,
            npat: 1056,
          },
        },
        {
          team: 7,
          type: 'HOME_LOANS',
          name: 'PC Bank 1',
          values: {
            balances: 199717,
            marketShare: 1235,
            interestRate: 482,
            commission: 16,
            npat: 907,
          },
          lastValues: {
            balances: 201352,
            marketShare: 1258,
            interestRate: 508,
            commission: 17,
            npat: 1129,
          },
        },
        {
          team: 2,
          type: 'HOME_LOANS',
          name: 'Beta Bank',
          values: {
            balances: 190202,
            marketShare: 1177,
            interestRate: 482,
            commission: 17,
            npat: 999,
          },
          lastValues: {
            balances: 192884,
            marketShare: 1205,
            interestRate: 507,
            commission: 17,
            npat: 1180,
          },
        },
        {
          team: 4,
          type: 'HOME_LOANS',
          name: 'Delta Bank',
          values: {
            balances: 181552,
            marketShare: 1123,
            interestRate: 509,
            commission: 10,
            npat: 1022,
          },
          lastValues: {
            balances: 187289,
            marketShare: 1170,
            interestRate: 523,
            commission: 10,
            npat: 1203,
          },
        },
        {
          team: 8,
          type: 'HOME_LOANS',
          name: 'PC Bank 2',
          values: {
            balances: 178572,
            marketShare: 1105,
            interestRate: 513,
            commission: 12,
            npat: 1284,
          },
          lastValues: {
            balances: 184539,
            marketShare: 1153,
            interestRate: 533,
            commission: 13,
            npat: 1385,
          },
        },
      ],
    },
    {
      type: 'BUSINESS_LOANS',
      name: 'BUSINESS LOANS',
      teams: [
        {
          team: 3,
          type: 'BUSINESS_LOANS',
          name: 'Gamma Bank',
          values: {
            balances: 78221,
            marketShare: 1545,
            interestRate: 608,
            npat: 374,
          },
          lastValues: {
            balances: 77363,
            marketShare: 1485,
            interestRate: 628,
            npat: 946,
          },
        },
        {
          team: 5,
          type: 'BUSINESS_LOANS',
          name: 'Sigma Bank',
          values: {
            balances: 69534,
            marketShare: 1374,
            interestRate: 628,
            npat: -857,
          },
          lastValues: {
            balances: 71282,
            marketShare: 1368,
            interestRate: 654,
            npat: 124,
          },
        },
        {
          team: 7,
          type: 'BUSINESS_LOANS',
          name: 'PC Bank 1',
          values: {
            balances: 68274,
            marketShare: 1349,
            interestRate: 631,
            npat: 501,
          },
          lastValues: {
            balances: 68924,
            marketShare: 1323,
            interestRate: 653,
            npat: 943,
          },
        },
        {
          team: 6,
          type: 'BUSINESS_LOANS',
          name: 'Omega Bank',
          values: {
            balances: 63434,
            marketShare: 1253,
            interestRate: 625,
            npat: 394,
          },
          lastValues: {
            balances: 62867,
            marketShare: 1206,
            interestRate: 650,
            npat: 817,
          },
        },
        {
          team: 4,
          type: 'BUSINESS_LOANS',
          name: 'Delta Bank',
          values: {
            balances: 58543,
            marketShare: 1157,
            interestRate: 633,
            npat: 416,
          },
          lastValues: {
            balances: 60954,
            marketShare: 1170,
            interestRate: 653,
            npat: 820,
          },
        },
        {
          team: 1,
          type: 'BUSINESS_LOANS',
          name: 'Alpha Bank',
          values: {
            balances: 57809,
            marketShare: 1142,
            interestRate: 652,
            npat: 539,
          },
          lastValues: {
            balances: 61122,
            marketShare: 1173,
            interestRate: 667,
            npat: 879,
          },
        },
        {
          team: 2,
          type: 'BUSINESS_LOANS',
          name: 'Beta Bank',
          values: {
            balances: 55430,
            marketShare: 1095,
            interestRate: 643,
            npat: 617,
          },
          lastValues: {
            balances: 58300,
            marketShare: 1119,
            interestRate: 668,
            npat: 968,
          },
        },
        {
          team: 8,
          type: 'BUSINESS_LOANS',
          name: 'PC Bank 2',
          values: {
            balances: 54725,
            marketShare: 1081,
            interestRate: 633,
            npat: 527,
          },
          lastValues: {
            balances: 60064,
            marketShare: 1153,
            interestRate: 653,
            npat: 856,
          },
        },
      ],
    },
    {
      type: 'DEPOSITS',
      name: 'DEPOSITS',
      teams: [
        {
          team: 1,
          type: 'DEPOSITS',
          name: 'Alpha Bank',
          values: {
            balances: 199008,
            marketShare: 1356,
            interestRate: 269,
            branches: 820,
            npat: 1179,
          },
          lastValues: {
            balances: 180005,
            marketShare: 1325,
            interestRate: 299,
            branches: 815,
            npat: 952,
          },
        },
        {
          team: 2,
          type: 'DEPOSITS',
          name: 'Beta Bank',
          values: {
            balances: 191947,
            marketShare: 1308,
            interestRate: 278,
            branches: 815,
            npat: 900,
          },
          lastValues: {
            balances: 174085,
            marketShare: 1281,
            interestRate: 303,
            branches: 815,
            npat: 890,
          },
        },
        {
          team: 6,
          type: 'DEPOSITS',
          name: 'Omega Bank',
          values: {
            balances: 191921,
            marketShare: 1308,
            interestRate: 271,
            branches: 838,
            npat: 950,
          },
          lastValues: {
            balances: 172376,
            marketShare: 1269,
            interestRate: 296,
            branches: 818,
            npat: 844,
          },
        },
        {
          team: 5,
          type: 'DEPOSITS',
          name: 'Sigma Bank',
          values: {
            balances: 188823,
            marketShare: 1287,
            interestRate: 270,
            branches: 792,
            npat: 4574,
          },
          lastValues: {
            balances: 172053,
            marketShare: 1266,
            interestRate: 296,
            branches: 817,
            npat: 2864,
          },
        },
        {
          team: 7,
          type: 'DEPOSITS',
          name: 'PC Bank 1',
          values: {
            balances: 182857,
            marketShare: 1246,
            interestRate: 267,
            branches: 811,
            npat: 1042,
          },
          lastValues: {
            balances: 169877,
            marketShare: 1250,
            interestRate: 293,
            branches: 811,
            npat: 915,
          },
        },
        {
          team: 3,
          type: 'DEPOSITS',
          name: 'Gamma Bank',
          values: {
            balances: 177245,
            marketShare: 1208,
            interestRate: 263,
            branches: 820,
            npat: 1400,
          },
          lastValues: {
            balances: 167253,
            marketShare: 1231,
            interestRate: 283,
            branches: 820,
            npat: 1017,
          },
        },
        {
          team: 8,
          type: 'DEPOSITS',
          name: 'PC Bank 2',
          values: {
            balances: 168236,
            marketShare: 1146,
            interestRate: 248,
            branches: 785,
            npat: 1175,
          },
          lastValues: {
            balances: 161708,
            marketShare: 1190,
            interestRate: 278,
            branches: 790,
            npat: 1088,
          },
        },
        {
          team: 4,
          type: 'DEPOSITS',
          name: 'Delta Bank',
          values: {
            balances: 166773,
            marketShare: 1136,
            interestRate: 248,
            branches: 780,
            npat: 1346,
          },
          lastValues: {
            balances: 160801,
            marketShare: 1183,
            interestRate: 278,
            branches: 780,
            npat: 1148,
          },
        },
      ],
    },
    {
      type: 'CREDIT_CARDS',
      name: 'CREDIT CARDS',
      teams: [
        {
          team: 6,
          type: 'CREDIT_CARDS',
          name: 'Omega Bank',
          values: {
            customers: 1451030,
            marketShare: 1356,
            annualFee: 251,
            loyaltyPoints: 166,
            npat: 38,
          },
          lastValues: {
            customers: 1615480,
            marketShare: 1280,
            annualFee: 271,
            loyaltyPoints: 146,
            npat: 223,
          },
        },
        {
          team: 1,
          type: 'CREDIT_CARDS',
          name: 'Alpha Bank',
          values: {
            customers: 1392344,
            marketShare: 1301,
            annualFee: 275,
            loyaltyPoints: 150,
            npat: 147,
          },
          lastValues: {
            customers: 1597671,
            marketShare: 1266,
            annualFee: 275,
            loyaltyPoints: 150,
            npat: 227,
          },
        },
        {
          team: 3,
          type: 'CREDIT_CARDS',
          name: 'Gamma Bank',
          values: {
            customers: 1390443,
            marketShare: 1299,
            annualFee: 265,
            loyaltyPoints: 125,
            npat: 196,
          },
          lastValues: {
            customers: 1665864,
            marketShare: 1320,
            annualFee: 265,
            loyaltyPoints: 145,
            npat: 273,
          },
        },
        {
          team: 5,
          type: 'CREDIT_CARDS',
          name: 'Sigma Bank',
          values: {
            customers: 1383399,
            marketShare: 1293,
            annualFee: 238,
            loyaltyPoints: 116,
            npat: -35,
          },
          lastValues: {
            customers: 1628450,
            marketShare: 1291,
            annualFee: 264,
            loyaltyPoints: 137,
            npat: 99,
          },
        },
        {
          team: 2,
          type: 'CREDIT_CARDS',
          name: 'Beta Bank',
          values: {
            customers: 1311240,
            marketShare: 1225,
            annualFee: 270,
            loyaltyPoints: 145,
            npat: 204,
          },
          lastValues: {
            customers: 1553349,
            marketShare: 1231,
            annualFee: 270,
            loyaltyPoints: 145,
            npat: 318,
          },
        },
        {
          team: 7,
          type: 'CREDIT_CARDS',
          name: 'PC Bank 1',
          values: {
            customers: 1307721,
            marketShare: 1222,
            annualFee: 263,
            loyaltyPoints: 136,
            npat: 139,
          },
          lastValues: {
            customers: 1553384,
            marketShare: 1231,
            annualFee: 271,
            loyaltyPoints: 140,
            npat: 245,
          },
        },
        {
          team: 8,
          type: 'CREDIT_CARDS',
          name: 'PC Bank 2',
          values: {
            customers: 1234684,
            marketShare: 1154,
            annualFee: 265,
            loyaltyPoints: 110,
            npat: 219,
          },
          lastValues: {
            customers: 1501558,
            marketShare: 1190,
            annualFee: 260,
            loyaltyPoints: 115,
            npat: 315,
          },
        },
        {
          team: 4,
          type: 'CREDIT_CARDS',
          name: 'Delta Bank',
          values: {
            customers: 1228121,
            marketShare: 1147,
            annualFee: 280,
            loyaltyPoints: 115,
            npat: 197,
          },
          lastValues: {
            customers: 1496092,
            marketShare: 1186,
            annualFee: 280,
            loyaltyPoints: 115,
            npat: 318,
          },
        },
      ],
    },
  ],
  data: [
    {
      enabled: true,
      type: 'NPS',
      data: [
        {
          team: 1,
          name: 'Alpha Bank',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 13,
            },
            {
              round: 3,
              value: 34,
            },
            {
              round: 4,
              value: 57,
            },
          ],
        },
        {
          team: 5,
          name: 'Sigma Bank',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 4,
            },
            {
              round: 3,
              value: 19,
            },
            {
              round: 4,
              value: 46,
            },
          ],
        },
        {
          team: 6,
          name: 'Omega Bank',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 6,
            },
            {
              round: 3,
              value: 23,
            },
            {
              round: 4,
              value: 41,
            },
          ],
        },
        {
          team: 7,
          name: 'PC Bank 1',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 6,
            },
            {
              round: 3,
              value: 13,
            },
            {
              round: 4,
              value: 17,
            },
          ],
        },
        {
          team: 3,
          name: 'Gamma Bank',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 5,
            },
            {
              round: 3,
              value: 12,
            },
            {
              round: 4,
              value: 15,
            },
          ],
        },
        {
          team: 4,
          name: 'Delta Bank',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 1,
            },
            {
              round: 3,
              value: 6,
            },
            {
              round: 4,
              value: 8,
            },
          ],
        },
        {
          team: 8,
          name: 'PC Bank 2',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 0,
            },
            {
              round: 3,
              value: 4,
            },
            {
              round: 4,
              value: 7,
            },
          ],
        },
        {
          team: 2,
          name: 'Beta Bank',
          history: [
            {
              round: 1,
              value: 0,
            },
            {
              round: 2,
              value: 1,
            },
            {
              round: 3,
              value: 2,
            },
            {
              round: 4,
              value: 3,
            },
          ],
        },
      ],
      businessTypes: [
        {
          type: 'HOME_LOANS',
          name: 'HOME LOANS',
          data: [
            {
              team: 1,
              name: 'Alpha Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 15,
                },
                {
                  round: 3,
                  value: 40,
                },
                {
                  round: 4,
                  value: 66,
                },
              ],
            },
            {
              team: 5,
              name: 'Sigma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 5,
                },
                {
                  round: 3,
                  value: 21,
                },
                {
                  round: 4,
                  value: 51,
                },
              ],
            },
            {
              team: 6,
              name: 'Omega Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 6,
                },
                {
                  round: 3,
                  value: 24,
                },
                {
                  round: 4,
                  value: 45,
                },
              ],
            },
            {
              team: 7,
              name: 'PC Bank 1',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 7,
                },
                {
                  round: 3,
                  value: 14,
                },
                {
                  round: 4,
                  value: 18,
                },
              ],
            },
            {
              team: 3,
              name: 'Gamma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 5,
                },
                {
                  round: 3,
                  value: 12,
                },
                {
                  round: 4,
                  value: 17,
                },
              ],
            },
            {
              team: 4,
              name: 'Delta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 1,
                },
                {
                  round: 3,
                  value: 8,
                },
                {
                  round: 4,
                  value: 9,
                },
              ],
            },
            {
              team: 8,
              name: 'PC Bank 2',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 0,
                },
                {
                  round: 3,
                  value: 2,
                },
                {
                  round: 4,
                  value: 5,
                },
              ],
            },
            {
              team: 2,
              name: 'Beta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 1,
                },
                {
                  round: 3,
                  value: 1,
                },
                {
                  round: 4,
                  value: 3,
                },
              ],
            },
          ],
        },
        {
          type: 'BUSINESS_LOANS',
          name: 'BUSINESS LOANS',
          data: [
            {
              team: 1,
              name: 'Alpha Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 14,
                },
                {
                  round: 3,
                  value: 36,
                },
                {
                  round: 4,
                  value: 61,
                },
              ],
            },
            {
              team: 5,
              name: 'Sigma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 6,
                },
                {
                  round: 3,
                  value: 21,
                },
                {
                  round: 4,
                  value: 50,
                },
              ],
            },
            {
              team: 6,
              name: 'Omega Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 6,
                },
                {
                  round: 3,
                  value: 25,
                },
                {
                  round: 4,
                  value: 46,
                },
              ],
            },
            {
              team: 3,
              name: 'Gamma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 6,
                },
                {
                  round: 3,
                  value: 14,
                },
                {
                  round: 4,
                  value: 20,
                },
              ],
            },
            {
              team: 7,
              name: 'PC Bank 1',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 7,
                },
                {
                  round: 3,
                  value: 15,
                },
                {
                  round: 4,
                  value: 19,
                },
              ],
            },
            {
              team: 4,
              name: 'Delta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 2,
                },
                {
                  round: 3,
                  value: 8,
                },
                {
                  round: 4,
                  value: 10,
                },
              ],
            },
            {
              team: 8,
              name: 'PC Bank 2',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 0,
                },
                {
                  round: 3,
                  value: 4,
                },
                {
                  round: 4,
                  value: 8,
                },
              ],
            },
            {
              team: 2,
              name: 'Beta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 0,
                },
                {
                  round: 3,
                  value: -1,
                },
                {
                  round: 4,
                  value: -1,
                },
              ],
            },
          ],
        },
        {
          type: 'DEPOSITS',
          name: 'DEPOSITS',
          data: [
            {
              team: 1,
              name: 'Alpha Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 11,
                },
                {
                  round: 3,
                  value: 28,
                },
                {
                  round: 4,
                  value: 47,
                },
              ],
            },
            {
              team: 5,
              name: 'Sigma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 1,
                },
                {
                  round: 3,
                  value: 13,
                },
                {
                  round: 4,
                  value: 37,
                },
              ],
            },
            {
              team: 6,
              name: 'Omega Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 6,
                },
                {
                  round: 3,
                  value: 20,
                },
                {
                  round: 4,
                  value: 33,
                },
              ],
            },
            {
              team: 7,
              name: 'PC Bank 1',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 3,
                },
                {
                  round: 3,
                  value: 8,
                },
                {
                  round: 4,
                  value: 13,
                },
              ],
            },
            {
              team: 3,
              name: 'Gamma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 4,
                },
                {
                  round: 3,
                  value: 7,
                },
                {
                  round: 4,
                  value: 9,
                },
              ],
            },
            {
              team: 8,
              name: 'PC Bank 2',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 1,
                },
                {
                  round: 3,
                  value: 5,
                },
                {
                  round: 4,
                  value: 8,
                },
              ],
            },
            {
              team: 2,
              name: 'Beta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 3,
                },
                {
                  round: 3,
                  value: 4,
                },
                {
                  round: 4,
                  value: 4,
                },
              ],
            },
            {
              team: 4,
              name: 'Delta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: -2,
                },
                {
                  round: 3,
                  value: 1,
                },
                {
                  round: 4,
                  value: 3,
                },
              ],
            },
          ],
        },
        {
          type: 'CREDIT_CARDS',
          name: 'CREDIT CARDS',
          data: [
            {
              team: 1,
              name: 'Alpha Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 13,
                },
                {
                  round: 3,
                  value: 33,
                },
                {
                  round: 4,
                  value: 57,
                },
              ],
            },
            {
              team: 5,
              name: 'Sigma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 4,
                },
                {
                  round: 3,
                  value: 19,
                },
                {
                  round: 4,
                  value: 44,
                },
              ],
            },
            {
              team: 6,
              name: 'Omega Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 5,
                },
                {
                  round: 3,
                  value: 22,
                },
                {
                  round: 4,
                  value: 40,
                },
              ],
            },
            {
              team: 7,
              name: 'PC Bank 1',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 6,
                },
                {
                  round: 3,
                  value: 14,
                },
                {
                  round: 4,
                  value: 17,
                },
              ],
            },
            {
              team: 3,
              name: 'Gamma Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 5,
                },
                {
                  round: 3,
                  value: 12,
                },
                {
                  round: 4,
                  value: 15,
                },
              ],
            },
            {
              team: 4,
              name: 'Delta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 1,
                },
                {
                  round: 3,
                  value: 8,
                },
                {
                  round: 4,
                  value: 9,
                },
              ],
            },
            {
              team: 8,
              name: 'PC Bank 2',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 0,
                },
                {
                  round: 3,
                  value: 4,
                },
                {
                  round: 4,
                  value: 8,
                },
              ],
            },
            {
              team: 2,
              name: 'Beta Bank',
              history: [
                {
                  round: 1,
                  value: 0,
                },
                {
                  round: 2,
                  value: 2,
                },
                {
                  round: 3,
                  value: 2,
                },
                {
                  round: 4,
                  value: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      enabled: true,
      type: 'Complaints',
      data: [
        {
          team: 2,
          name: 'Beta Bank',
          value: 838,
          last: 763,
        },
        {
          team: 8,
          name: 'PC Bank 2',
          value: 838,
          last: 794,
        },
        {
          team: 4,
          name: 'Delta Bank',
          value: 725,
          last: 697,
        },
        {
          team: 7,
          name: 'PC Bank 1',
          value: 697,
          last: 667,
        },
        {
          team: 3,
          name: 'Gamma Bank',
          value: 650,
          last: 595,
        },
        {
          team: 1,
          name: 'Alpha Bank',
          value: 522,
          last: 585,
        },
        {
          team: 6,
          name: 'Omega Bank',
          value: 516,
          last: 554,
        },
        {
          team: 5,
          name: 'Sigma Bank',
          value: 459,
          last: 549,
        },
      ],
    },
    {
      enabled: true,
      type: 'TSR',
      data: [
        {
          team: 1,
          name: 'Alpha Bank',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 1055,
            },
            {
              round: 3,
              value: 1169,
            },
            {
              round: 4,
              value: 1198,
            },
          ],
        },
        {
          team: 6,
          name: 'Omega Bank',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 1122,
            },
            {
              round: 3,
              value: 1193,
            },
            {
              round: 4,
              value: 1170,
            },
          ],
        },
        {
          team: 7,
          name: 'PC Bank 1',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 1000,
            },
            {
              round: 3,
              value: 982,
            },
            {
              round: 4,
              value: 948,
            },
          ],
        },
        {
          team: 4,
          name: 'Delta Bank',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 897,
            },
            {
              round: 3,
              value: 938,
            },
            {
              round: 4,
              value: 934,
            },
          ],
        },
        {
          team: 2,
          name: 'Beta Bank',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 945,
            },
            {
              round: 3,
              value: 858,
            },
            {
              round: 4,
              value: 893,
            },
          ],
        },
        {
          team: 8,
          name: 'PC Bank 2',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 784,
            },
            {
              round: 3,
              value: 795,
            },
            {
              round: 4,
              value: 837,
            },
          ],
        },
        {
          team: 3,
          name: 'Gamma Bank',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 794,
            },
            {
              round: 3,
              value: 800,
            },
            {
              round: 4,
              value: 775,
            },
          ],
        },
        {
          team: 5,
          name: 'Sigma Bank',
          history: [
            {
              round: 1,
              value: 1000,
            },
            {
              round: 2,
              value: 898,
            },
            {
              round: 3,
              value: 494,
            },
            {
              round: 4,
              value: 483,
            },
          ],
        },
      ],
    },
    {
      enabled: true,
      type: 'EmployeeEngagement',
      data: [
        {
          team: 6,
          name: 'Omega Bank',
          value: 7310,
        },
        {
          team: 5,
          name: 'Sigma Bank',
          value: 7265,
        },
        {
          team: 7,
          name: 'PC Bank 1',
          value: 6464,
        },
        {
          team: 1,
          name: 'Alpha Bank',
          value: 6342,
        },
        {
          team: 4,
          name: 'Delta Bank',
          value: 5913,
        },
        {
          team: 2,
          name: 'Beta Bank',
          value: 5393,
        },
        {
          team: 3,
          name: 'Gamma Bank',
          value: 5236,
        },
        {
          team: 8,
          name: 'PC Bank 2',
          value: 3906,
        },
      ],
    },
    {
      enabled: true,
      type: 'Community',
      data: [
        {
          team: 1,
          name: 'Alpha Bank',
          value: 5750,
        },
        {
          team: 7,
          name: 'PC Bank 1',
          value: 5250,
        },
        {
          team: 6,
          name: 'Omega Bank',
          value: 5150,
        },
        {
          team: 2,
          name: 'Beta Bank',
          value: 4750,
        },
        {
          team: 5,
          name: 'Sigma Bank',
          value: 4650,
        },
        {
          team: 4,
          name: 'Delta Bank',
          value: 4500,
        },
        {
          team: 8,
          name: 'PC Bank 2',
          value: 4500,
        },
        {
          team: 3,
          name: 'Gamma Bank',
          value: 4400,
        },
      ],
    },
    {
      enabled: true,
      type: 'Rating',
      data: [
        {
          team: 2,
          name: 'Beta Bank',
          value: 1110,
          rating: 'A',
        },
        {
          team: 1,
          name: 'Alpha Bank',
          value: 1065,
          rating: 'A',
        },
        {
          team: 6,
          name: 'Omega Bank',
          value: 1064,
          rating: 'A',
        },
        {
          team: 7,
          name: 'PC Bank 1',
          value: 1060,
          rating: 'A',
        },
        {
          team: 8,
          name: 'PC Bank 2',
          value: 1060,
          rating: 'A',
        },
        {
          team: 4,
          name: 'Delta Bank',
          value: 1051,
          rating: 'A',
        },
        {
          team: 5,
          name: 'Sigma Bank',
          value: 1021,
          rating: 'BBB',
        },
        {
          team: 3,
          name: 'Gamma Bank',
          value: 965,
          rating: 'BBB',
        },
      ],
    },
    {
      enabled: false,
      type: 'Customers',
      data: [
        {
          team: 1,
          name: 'Alpha Bank',
          value: 3434999,
        },
        {
          team: 6,
          name: 'Omega Bank',
          value: 3379013,
        },
        {
          team: 5,
          name: 'Sigma Bank',
          value: 3368688,
        },
        {
          team: 3,
          name: 'Gamma Bank',
          value: 3267348,
        },
        {
          team: 7,
          name: 'PC Bank 1',
          value: 3201014,
        },
        {
          team: 2,
          name: 'Beta Bank',
          value: 3200921,
        },
        {
          team: 4,
          name: 'Delta Bank',
          value: 2959860,
        },
        {
          team: 8,
          name: 'PC Bank 2',
          value: 2954478,
        },
      ],
    },
    {
      enabled: false,
      type: 'LIE',
      data: [
        {
          team: 3,
          name: 'Gamma Bank',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 20,
            },
            {
              round: 3,
              value: 22,
            },
            {
              round: 4,
              value: 60,
            },
          ],
        },
        {
          team: 7,
          name: 'PC Bank 1',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 20,
            },
            {
              round: 3,
              value: 21,
            },
            {
              round: 4,
              value: 58,
            },
          ],
        },
        {
          team: 5,
          name: 'Sigma Bank',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 20,
            },
            {
              round: 3,
              value: 21,
            },
            {
              round: 4,
              value: 57,
            },
          ],
        },
        {
          team: 4,
          name: 'Delta Bank',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 20,
            },
            {
              round: 3,
              value: 21,
            },
            {
              round: 4,
              value: 56,
            },
          ],
        },
        {
          team: 6,
          name: 'Omega Bank',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 20,
            },
            {
              round: 3,
              value: 21,
            },
            {
              round: 4,
              value: 56,
            },
          ],
        },
        {
          team: 1,
          name: 'Alpha Bank',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 20,
            },
            {
              round: 3,
              value: 21,
            },
            {
              round: 4,
              value: 54,
            },
          ],
        },
        {
          team: 2,
          name: 'Beta Bank',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 19,
            },
            {
              round: 3,
              value: 20,
            },
            {
              round: 4,
              value: 50,
            },
          ],
        },
        {
          team: 8,
          name: 'PC Bank 2',
          history: [
            {
              round: 1,
              value: 21,
            },
            {
              round: 2,
              value: 19,
            },
            {
              round: 3,
              value: 20,
            },
            {
              round: 4,
              value: 50,
            },
          ],
        },
      ],
    },
    {
      enabled: false,
      type: 'RegulatorActions',
      data: [
        {
          team: 1,
          name: 'Alpha Bank',
          value: 0,
        },
        {
          team: 2,
          name: 'Beta Bank',
          value: 0,
        },
        {
          team: 3,
          name: 'Gamma Bank',
          value: 0,
        },
        {
          team: 4,
          name: 'Delta Bank',
          value: 0,
        },
        {
          team: 5,
          name: 'Sigma Bank',
          value: 0,
        },
        {
          team: 6,
          name: 'Omega Bank',
          value: 0,
        },
        {
          team: 7,
          name: 'PC Bank 1',
          value: 0,
        },
        {
          team: 8,
          name: 'PC Bank 2',
          value: 0,
        },
      ],
    },
    {
      enabled: false,
      type: 'RiskIncidents',
      data: [
        {
          team: 8,
          name: 'PC Bank 2',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 737,
            },
            {
              round: 3,
              value: 732,
            },
            {
              round: 4,
              value: 705,
            },
          ],
        },
        {
          team: 4,
          name: 'Delta Bank',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 747,
            },
            {
              round: 3,
              value: 738,
            },
            {
              round: 4,
              value: 701,
            },
          ],
        },
        {
          team: 3,
          name: 'Gamma Bank',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 742,
            },
            {
              round: 3,
              value: 733,
            },
            {
              round: 4,
              value: 700,
            },
          ],
        },
        {
          team: 5,
          name: 'Sigma Bank',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 702,
            },
            {
              round: 3,
              value: 644,
            },
            {
              round: 4,
              value: 662,
            },
          ],
        },
        {
          team: 2,
          name: 'Beta Bank',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 727,
            },
            {
              round: 3,
              value: 706,
            },
            {
              round: 4,
              value: 636,
            },
          ],
        },
        {
          team: 7,
          name: 'PC Bank 1',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 718,
            },
            {
              round: 3,
              value: 669,
            },
            {
              round: 4,
              value: 590,
            },
          ],
        },
        {
          team: 1,
          name: 'Alpha Bank',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 706,
            },
            {
              round: 3,
              value: 633,
            },
            {
              round: 4,
              value: 534,
            },
          ],
        },
        {
          team: 6,
          name: 'Omega Bank',
          history: [
            {
              round: 1,
              value: 750,
            },
            {
              round: 2,
              value: 708,
            },
            {
              round: 3,
              value: 637,
            },
            {
              round: 4,
              value: 499,
            },
          ],
        },
      ],
    },
    {
      enabled: false,
      type: 'ROE',
      data: [
        {
          team: 8,
          name: 'PC Bank 2',
          value: 1863,
          last: 2161,
        },
        {
          team: 4,
          name: 'Delta Bank',
          value: 1744,
          last: 2136,
        },
        {
          team: 2,
          name: 'Beta Bank',
          value: 1435,
          last: 1829,
        },
        {
          team: 1,
          name: 'Alpha Bank',
          value: 1370,
          last: 1642,
        },
        {
          team: 7,
          name: 'PC Bank 1',
          value: 1298,
          last: 1781,
        },
        {
          team: 3,
          name: 'Gamma Bank',
          value: 1243,
          last: 1783,
        },
        {
          team: 6,
          name: 'Omega Bank',
          value: 1208,
          last: 1731,
        },
        {
          team: 5,
          name: 'Sigma Bank',
          value: -207,
          last: 855,
        },
      ],
    },
  ],
}
