import React from 'react'
import Button from '../Button/Button'
import { Icon } from '../Icon/Icon'
import { useIsMobileLandscape } from '../../../hooks/useIsMobileLandscapes'
interface TabChangerProps<T> {
  currentTab: T
  tabs: Array<T>
  setTab: (tab: T) => void
}

const TabChanger: React.FC<TabChangerProps<any>> = ({
  currentTab,
  tabs,
  setTab,
}) => {
  const isMobileLandscape = useIsMobileLandscape()
  const currentIndex = tabs.findIndex(tab => tab === currentTab)
  const canMoveLeft = currentIndex > 0 && !isMobileLandscape
  const canMoveRight = currentIndex < tabs.length - 1 && !isMobileLandscape

  const moveLeft = () => {
    if (canMoveLeft) {
      setTab(tabs[currentIndex - 1])
    }
  }

  const moveRight = () => {
    if (canMoveRight) {
      setTab(tabs[currentIndex + 1])
    }
  }

  return (
    <div className="pointer-events-none absolute top-0 left-0 flex h-full w-full items-center justify-between">
      <div className="desktop:ml-64">
        {canMoveLeft && (
          <Button
            onClick={moveLeft}
            disabled={!canMoveLeft}
            className="border-gray pointer-events-auto z-30 ml-1 border opacity-60"
            size="xs"
            rounded="full"
          >
            <Icon size={7} colour="white" type={'chevronLeft'}></Icon>
          </Button>
        )}
      </div>

      {canMoveRight && (
        <Button
          onClick={moveRight}
          disabled={!canMoveRight}
          className="border-gray pointer-events-auto z-30 mr-1 border opacity-60"
          size="xs"
          rounded="full"
        >
          <Icon size={7} colour="white" type={'chevronRight'}></Icon>
        </Button>
      )}
    </div>
  )
}

export default TabChanger
