import React, { FormEvent, useCallback, useMemo } from 'react'
import { EventResponse } from '../../../types/adminApi/events'
import LinkButton from '../../atoms/LinkButton/LinkButton'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import EventOptionsAlert from './EventOptionsAlert'
import LoginInput from './LoginInput'
import LoginButton from './LoginButton'

interface Props {
  email: string
  password: string
  events: EventResponse[]
  eventId: string | null
  onInput: (name: string, val: string) => void
  onLogin: () => void
  onSelectEvent: (eventId: string | null) => void
  onSignInWithDifferentAccount: () => void
  buttonEnabled: boolean
}

const EnterEmailAndPasswordStep: React.FC<Props> = ({
  email,
  password,
  events,
  eventId,
  onInput,
  onSelectEvent,
  onLogin,
  onSignInWithDifferentAccount,
  buttonEnabled,
}) => {
  const handleNext = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      onLogin()
    },
    [onLogin],
  )

  const selectedEvent = useMemo(() => {
    return events.find(e => e.id === eventId)
  }, [eventId, events])

  return (
    <form className="mt-6 space-y-4" onSubmit={handleNext}>
      {!selectedEvent && (
        <EventOptionsAlert
          events={events}
          onClose={() => {
            onSignInWithDifferentAccount()
          }}
          onSelectEvent={onSelectEvent}
        />
      )}
      {selectedEvent && (
        <div className="text-center text-sm text-white">
          Signing into {selectedEvent.gameName}
        </div>
      )}
      <input type="hidden" name="remember" defaultValue="true" />
      <LoginInput
        disabled
        id="email-address"
        placeholder="Email address"
        label="Email Address"
        onValueChange={onInput}
        value={email}
      />

      <LoginInput
        id="password"
        name="password"
        type="password"
        placeholder="Password"
        label="Password"
        onValueChange={onInput}
        value={password}
      />

      <LoginButton disabled={!buttonEnabled} text="Login" />

      <VerticalGroup gap={1}>
        <LinkButton
          onClick={e => {
            e.preventDefault()
            onSelectEvent(null)
          }}
          text="Sign into a different event"
          size="xs"
        />

        <LinkButton
          onClick={e => {
            e.preventDefault()
            onSignInWithDifferentAccount()
          }}
          text="Sign in with a different account"
          size="xs"
        />
      </VerticalGroup>
    </form>
  )
}

export default EnterEmailAndPasswordStep
