import { createSlice } from '@reduxjs/toolkit'
import { fetchNews } from './newsActions'
import APIError from '../../errors/APIError'
import { EconomicScenarioNewsResponse } from '../../types/gameApi/news'
import { reset } from '../game/gameSlice'
import { logout } from '../auth/authSlice'
import ThunkCancelledError from '../../errors/ThunkCancelledError'

interface NewsState {
  isLoading: boolean
  isLoaded: boolean
  news: EconomicScenarioNewsResponse | null
  requestInProgress: string | null
  error: Error | APIError | null
}

const initialState: NewsState = {
  isLoading: false,
  isLoaded: false,
  news: null,
  requestInProgress: null,
  error: null,
}

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(reset, () => {
        return initialState
      })
      .addCase(logout, () => {
        return initialState
      })
      .addCase(fetchNews.pending, (state, { meta }) => {
        if (!state.isLoading) {
          state.requestInProgress = meta.requestId
          state.isLoading = true
          state.error = null
        }
      })
      .addCase(fetchNews.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isLoaded = true
        state.error = null
        state.news = payload
        state.requestInProgress = null
      })
      .addCase(fetchNews.rejected, (state, { payload }) => {
        if (payload instanceof ThunkCancelledError) {
          return
        }
        state.isLoading = false
        state.error = payload ?? null
        state.requestInProgress = null
      })
  },
})

export default newsSlice.reducer
