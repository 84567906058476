import React, { useCallback } from 'react'
import InAppHelpContent from '../../organisms/InAppHelp/InAppHelpContent'
import Text from '../../atoms/Text/Text'
import GuidedTour, {
  GuidedTourStep,
} from '../../organisms/InAppHelp/GuidedTour'
import { useAppDispatch, useAppSelector } from '../../../store'
import { finishTour } from '../../../redux/guidedTours/guidedToursSlice'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'

const SummaryTour: React.FC = () => {
  const dispatch = useAppDispatch()
  const currentTour = useAppSelector(state => state.guidedTours.currentTour)

  const steps: Array<Partial<GuidedTourStep>> = [
    {
      target: '#key-metric',
      content: (
        <InAppHelpContent heading="Key Metric">
          <Text left>
            {
              'This is your simulation scorecard, which consists of key metrics. Monitor and improve these metrics consistently throughout the simulation.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },

    {
      target: '#metric-heading',
      content: (
        <InAppHelpContent heading={'Metric Name'}>
          <Text left>{'The name of the metric.'}</Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#metric-help-icon',
      content: (
        <InAppHelpContent heading="Want An explanation?">
          <Text left>{'Click/tap here for more details on this metric.'}</Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#metric-score',
      content: (
        <InAppHelpContent heading="Current Score">
          <Text left>
            {
              "This is your bank's current score for this metric, reflecting the impact of your decisions."
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#metric-score-change',
      content: (
        <InAppHelpContent heading="Score Change">
          <Text left>
            {
              'This shows the change in your metric score since the last year/round.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#metric-ranking',
      content: (
        <InAppHelpContent heading={'Current Placing'}>
          <Text left>
            {
              'This ranking shows how your bank compares to competitors. Your goal is to rank as high as possible, ideally first.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#metric-place-change',
      content: (
        <InAppHelpContent heading={'Placing Change'}>
          <Text left>
            {
              'This indicates how your ranking has changed since the last year/round. A positive value signifies improvement, while a negative value indicates a decline.'
            }
          </Text>
        </InAppHelpContent>
      ),
    },
    {
      target: '#metric-indicator',
      content: (
        <InAppHelpContent heading={'Winning Metric Indicator'}>
          <VerticalGroup gap={0}>
            <Text left>
              {
                'Metrics with this symbol determine the winning bank each round. Click/tap here to view how much this metric counts towards winning.'
              }
            </Text>
          </VerticalGroup>
        </InAppHelpContent>
      ),
    },
  ]

  const handleCloseTour = useCallback(() => {
    dispatch(finishTour())
  }, [dispatch])

  if (currentTour !== 'summary') {
    return null
  }

  return <GuidedTour disableScrolling steps={steps} onClose={handleCloseTour} />
}

export default SummaryTour
