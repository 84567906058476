import React, { Fragment, useCallback } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Icon } from '../../atoms/Icon/Icon'
import SidebarContent from './SideBarContent'
import PrimaryText from '../../atoms/Text/PrimaryText'
import { useAppSelector } from '../../../store'
import Logo from '../../atoms/Logo/Logo'
import Clickable from '../../atoms/Clickable/Clickable'
import { useNavigate } from 'react-router-dom'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import Button from '../../atoms/Button/Button'

interface Props {
  open: boolean
  toggleOpen: (value: boolean) => void
  onHelpClick: () => void
}

const Sidebar: React.FC<Props> = ({ open, toggleOpen, onHelpClick }) => {
  const navigate = useNavigate()

  const { teamId, teamName, isCeo } = useAppSelector(state => ({
    teamId: state.team.id ?? 0,
    teamName: state.team.name,
    isCeo: state.device.isCeo,
  }))

  const formattedTeamName = `Team ${teamId}: ${teamName?.toUpperCase()} BANK`

  const handleLogoClick = useCallback(() => {
    navigate('/')
  }, [navigate])

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 desktop:hidden"
          onClose={toggleOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex max-w-xs flex-1 flex-col">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => toggleOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex grow flex-col gap-y-2 overflow-y-auto bg-gray-900 py-2 px-6 pb-4 ring-1 ring-white/10">
                  {/* <div className="flex h-16 shrink-0 items-center"> */}
                  <Clickable onClick={handleLogoClick}>
                    <Logo />
                  </Clickable>

                  <HorizontalGroup verticalCenter gap={2}>
                    {isCeo && <Icon size={4} type="ceo" colour="primary" />}
                    <PrimaryText
                      colour="white"
                      weight="semibold"
                      paddingY={2}
                      notResponsive
                    >
                      {formattedTeamName}
                    </PrimaryText>
                  </HorizontalGroup>
                  <div className="flex flex-1 flex-col gap-2 overflow-y-auto">
                    <nav className="flex flex-1 flex-col space-y-1">
                      <SidebarContent />
                    </nav>
                    {isCeo && (
                      <div className="flex">
                        <HorizontalGroup between fullWidth>
                          <Button
                            onClick={onHelpClick}
                            buttonType="secondary"
                            full
                          >
                            Help Center
                            <Icon size={8} type="help" colour="primary" />
                          </Button>
                        </HorizontalGroup>
                      </div>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden desktop:fixed desktop:inset-y-0 desktop:flex desktop:w-64 desktop:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex min-h-0 flex-1 flex-col bg-gray-800 p-6">
          <Clickable onClick={handleLogoClick}>
            <Logo />
          </Clickable>

          <HorizontalGroup verticalCenter gap={2}>
            <PrimaryText
              colour="white"
              weight="semibold"
              paddingY={2}
              notResponsive
            >
              {formattedTeamName}
            </PrimaryText>
            {isCeo && <Icon size={4} type="ceo" colour="primary" />}
          </HorizontalGroup>

          <div className="flex flex-1 flex-col gap-2 overflow-y-auto">
            <nav className="flex flex-1 flex-col space-y-1">
              <SidebarContent />
            </nav>
            <HorizontalGroup between fullWidth>
              <Button onClick={onHelpClick} buttonType="secondary" full>
                Help Center
                <Icon size={8} type="help" colour="primary" />
              </Button>
            </HorizontalGroup>
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar
