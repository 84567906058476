import { createAsyncThunk } from '@reduxjs/toolkit'
import gameAPI from '../../services/gameApi'
import APIError from '../../errors/APIError'
import { RootState } from '../../store'
import {
  BusinessDecisionUpdate,
  BusinessRequestParams,
  BusinessResponse,
  BusinessUpdateRequestParams,
} from '../../types/gameApi/business'
import ThunkCancelledError from '../../errors/ThunkCancelledError'

export const fetchBusinesses = createAsyncThunk<
  { roundId: number; business: BusinessResponse },
  BusinessRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'business/fetch',
  async ({ teamId, roundId }, { rejectWithValue, getState, requestId }) => {
    const state = getState()
    try {
      if (state.businesses.requestInProgress !== requestId) {
        throw new ThunkCancelledError()
      }
      const business = await gameAPI.getBusinesses({ teamId, roundId })

      return { roundId, business }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)

export const fetchBusinessesBackground = createAsyncThunk<
  { roundId: number; business: BusinessResponse },
  BusinessRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'business/fetchBackground',
  async ({ teamId, roundId }, { rejectWithValue, getState, requestId }) => {
    const state = getState()
    try {
      if (state.businesses.requestInProgress !== requestId) {
        throw new ThunkCancelledError()
      }
      const business = await gameAPI.getBusinesses({ teamId, roundId })

      return { roundId, business }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)

export const updateBusinessDecisions = createAsyncThunk<
  { roundId: number; decisions: BusinessDecisionUpdate[] },
  BusinessUpdateRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'business/updateDecision',
  async ({ teamId, roundId, decisions, deviceId }, { rejectWithValue }) => {
    try {
      await gameAPI.updateBusinessDecisions({
        teamId,
        roundId,
        decisions,
        deviceId,
      })

      return { roundId, decisions }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)

export const updateBusinessDecisionsBackground = createAsyncThunk<
  { roundId: number; decisions: BusinessDecisionUpdate[] },
  BusinessUpdateRequestParams,
  { rejectValue: Error | APIError; state: RootState }
>(
  'business/updateDecisionBackground',
  async ({ teamId, roundId, decisions, deviceId }, { rejectWithValue }) => {
    try {
      await gameAPI.updateBusinessDecisions({
        teamId,
        roundId,
        decisions,
        deviceId,
      })

      return { roundId, decisions }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)
