import React, { PropsWithChildren } from 'react'
import ResponsiveText from './ResponsiveText'
import Text, { Props as TextProps } from './Text'
import { FontFamily } from '../../../types/theme'

export interface Props extends Omit<TextProps, 'family' | 'children'> {
  notResponsive?: boolean
  children: string
  family?: FontFamily
}

const HtmlText: React.FC<PropsWithChildren<Props>> = ({
  notResponsive,
  family,
  ...props
}) => {
  if (notResponsive) {
    return <Text {...props} family={family ?? 'secondary'} isHtml />
  }
  return <ResponsiveText {...props} family={family ?? 'secondary'} isHtml />
}

export default HtmlText
