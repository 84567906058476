import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { fetchDealRoom, fetchDealRoomBackground } from './dealroomActions'
import APIError from '../../errors/APIError'
import { reset } from '../game/gameSlice'
import { DealroomResponse } from '../../types/gameApi/dealroom'
import { logout } from '../auth/authSlice'
import ThunkCancelledError from '../../errors/ThunkCancelledError'

export type DealroomTabs = 'overview' | 'pricing-matrix' | 'valuation'

interface DealroomState {
  currentTab: DealroomTabs
  isLoading: boolean
  isLoadingBackground: boolean
  isLoaded: boolean
  isSubmitting: boolean
  data: {
    [roundId: number]: DealroomResponse
  }
  error: Error | APIError | null
  hideBanner: false
  requestInProgress: string | null
}

const initialState: DealroomState = {
  currentTab: 'overview',
  isLoading: false,
  isLoaded: false,
  isLoadingBackground: false,
  isSubmitting: false,
  data: {},
  error: null,
  hideBanner: false,
  requestInProgress: null,
}

const dealroomSlice = createSlice({
  name: 'dealroom',
  initialState,
  reducers: {
    changeTab: (state, action: PayloadAction<DealroomTabs>) => {
      state.currentTab = action.payload
    },
    changeBannerVisibility: (state, action) => {
      state.hideBanner = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(reset, () => {
        return initialState
      })
      .addCase(logout, () => {
        return initialState
      })
      .addCase(fetchDealRoom.pending, (state, { meta }) => {
        if (!state.isLoading) {
          state.requestInProgress = meta.requestId
          state.isLoading = true
          state.error = null
        }
      })
      .addCase(fetchDealRoom.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isLoaded = true
        state.error = null
        state.data[payload.roundId] = payload.dealroom
        state.requestInProgress = null
      })
      .addCase(fetchDealRoom.rejected, (state, { payload }) => {
        if (payload instanceof ThunkCancelledError) {
          return
        }
        state.isLoading = false
        state.error = payload ?? null
        state.requestInProgress = null
      })
      .addCase(fetchDealRoomBackground.pending, (state, { meta }) => {
        if (!state.isLoading) {
          state.requestInProgress = meta.requestId
          state.isLoadingBackground = true
        }
      })
      .addCase(fetchDealRoomBackground.fulfilled, (state, { payload }) => {
        state.isLoadingBackground = false
        if (process.env.REACT_APP_DEMO_MODE !== 'true') {
          state.data[payload.roundId] = payload.dealroom
        }
        state.requestInProgress = null
      })
      .addCase(fetchDealRoomBackground.rejected, (state, { payload }) => {
        if (payload instanceof ThunkCancelledError) {
          return
        }
        state.isLoadingBackground = false
        state.requestInProgress = null
      })
  },
})

export const { changeTab, changeBannerVisibility } = dealroomSlice.actions

export default dealroomSlice.reducer
