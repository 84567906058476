import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { reset } from '../game/gameSlice'
import { logout } from '../auth/authSlice'

interface ResultsState {
  currentResultsUrl: string | null
}

const initialState: ResultsState = {
  currentResultsUrl: null,
}

const resultsSlice = createSlice({
  name: 'results',
  initialState,
  reducers: {
    openPublishedResults: (state, action: PayloadAction<string>) => {
      state.currentResultsUrl = action.payload
    },
    closePublishedResults: state => {
      state.currentResultsUrl = null
    },
  },
  extraReducers: builder => {
    builder
      .addCase(reset, () => {
        return initialState
      })
      .addCase(logout, () => {
        return initialState
      })
  },
})

export const { openPublishedResults, closePublishedResults } =
  resultsSlice.actions

export default resultsSlice.reducer
