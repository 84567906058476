import React, { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store'
import Tabs from '../../atoms/Tabs/Tabs'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'

import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import Page from '../../atoms/Page/Page'
import Tab from '../../atoms/Tabs/Tab'
import MobilePageHeader from '../../organisms/MobilePageHeader/MobilePageHeader'
import DropDown from '../../atoms/DropDown/DropDown'

import { TreasuryTabs, changeTab } from '../../../redux/treasury/treasurySlice'
import TreasuryBalanceSheet from './TreasuryBalanceSheet/TreasuryBalanceSheet'
import TreasuryWSFProfile from './TreasuryWSFProfile/TreasuryWSFProfile'
import TreasuryRiskWeightedAssets from './TreasuryRiskWeightedAssets/TreasuryRiskWeightedAssets'

import { TreasuryResponse } from '../../../types/gameApi/treasury'
import { DecisionMap } from './TreasuryContainer'

import TreasuryCapitalManagement from './TreasuryCapitalManagement/TreasuryCapitalManagement'
import TabChanger from '../../atoms/TabChanger/TabChanger'
import { useSearchParams } from 'react-router-dom'
import TreasuryTour from './TreasuryTour'
import { useIsMobile } from '../../../hooks/useIsMobile'

interface Props {
  data: TreasuryResponse
  decisions: DecisionMap
  enableChanges: boolean
  onChangeValue: (val: Partial<DecisionMap>) => void
  selectedRound: number
  // rates: BusinessRates
  // volume: BusinessVolume
  // interest: BusinessInterest
  // decisions: DecisionMap
  // onChangeValue: (
  //   type: string,
  //   field: keyof BusinessDecision,
  //   value: number
  // ) => void
}

const Treasury: React.FC<Props> = ({
  data,
  decisions,
  onChangeValue,
  enableChanges,
  selectedRound,
}) => {
  const { currentTab, demoEnabled, demoWsfMethod, demoCapitalMethod } =
    useAppSelector(state => ({
      currentTab: state.treasury.currentTab,
      demoEnabled: state.demo.enabled,
      demoWsfMethod: state.demo.wsfMethod,
      demoCapitalMethod: state.demo.capitalMethod,
    }))

  const treasuryData = useMemo(() => {
    return {
      ...data,
      wsfMethod: demoEnabled ? demoWsfMethod : data.wsfMethod,
      capitalMethod: demoEnabled ? demoCapitalMethod : data.capitalMethod,
    }
  }, [data, demoCapitalMethod, demoEnabled, demoWsfMethod])

  const TABS: Array<{ label: string; value: TreasuryTabs; id?: string }> =
    useMemo(() => {
      const tabs: Array<{
        label: string
        value: TreasuryTabs
        enabled: boolean
        id?: string
      }> = [
        {
          label: 'TREASURY BALANCE SHEET',
          value: 'balance-sheet',
          enabled: true,
        },
        {
          label: 'WSF PROFILE VS ASSETS',
          value: 'wsf-profile',
          enabled: treasuryData.wsfMethod === 1,
          id: 'tour-wsf-profile',
        },
        {
          label: 'RWAs & DEDUCTIONS',
          value: 'risk-weighted-assets',
          enabled: treasuryData.capitalMethod === 1,
          id: 'tour-risk-weighted-assets',
        },
        {
          label: 'CAPITAL MANAGEMENT',
          value: 'capital-management',
          enabled: treasuryData.capitalMethod === 1,
          id: 'tour-capital-management',
        },
      ]
      return tabs
        .filter(t => t.enabled)
        .map(({ label, value, id }) => ({ label, value, id }))
    }, [treasuryData.capitalMethod, treasuryData.wsfMethod])

  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const queryTab = searchParams.get('tab')
    if (queryTab && TABS.some(tab => tab.value === queryTab)) {
      dispatch(changeTab(queryTab as TreasuryTabs))
    }
  }, [TABS, dispatch, searchParams])

  const setTab = useCallback(
    (tab: TreasuryTabs) => {
      dispatch(changeTab(tab))
      setSearchParams({ tab })
    },
    [dispatch, setSearchParams],
  )
  const handleCloseMobile = useCallback(() => {
    setTab('balance-sheet')
    setSearchParams({ tab: 'balance-sheet' })
  }, [setSearchParams, setTab])
  const isMobile = useIsMobile()

  return (
    <Page full>
      <TabChanger
        currentTab={currentTab as string}
        tabs={TABS.map(t => t.value as string)}
        setTab={tab => setTab(tab as TreasuryTabs)}
      />
      <VerticalGroup gap={4} full>
        <MobilePageHeader>
          <DropDown<TreasuryTabs>
            items={TABS}
            selectedItem={currentTab}
            onSelectedItemChange={item => {
              setTab(item.value)
            }}
          />
        </MobilePageHeader>
        <HorizontalGroup between className="hidden tablet:flex" fullWidth>
          <Tabs>
            {TABS.map(t => (
              <Tab
                key={t.value as string}
                tab={t}
                onClick={() => setTab(t.value)}
                active={currentTab === t.value}
                id={t.id}
              />
            ))}
          </Tabs>
        </HorizontalGroup>
        {currentTab === 'balance-sheet' && (
          <TreasuryBalanceSheet
            data={treasuryData}
            decisions={decisions}
            onChangeValue={onChangeValue}
            enableChanges={enableChanges}
            selectedRound={selectedRound}
            businessDecisions={data.businessDecisions}
          />
        )}
        {currentTab === 'wsf-profile' && (
          <TreasuryWSFProfile
            data={treasuryData}
            decisions={decisions}
            onChangeValue={onChangeValue}
            enableChanges={enableChanges}
            onCloseMobile={handleCloseMobile}
          />
        )}
        {currentTab === 'risk-weighted-assets' && (
          <TreasuryRiskWeightedAssets
            data={treasuryData}
            decisions={decisions}
            onChangeValue={onChangeValue}
            enableChanges={enableChanges}
          />
        )}
        {currentTab === 'capital-management' && (
          <TreasuryCapitalManagement
            data={treasuryData}
            decisions={decisions}
            enableChanges={enableChanges}
            onChangeValue={onChangeValue}
          />
        )}
      </VerticalGroup>
      {!isMobile && <TreasuryTour data={treasuryData} setTab={setTab} />}
    </Page>
  )
}

export default Treasury
