import { ExcoResponse } from '../../../../../types/gameApi/exco'
import { data as round1Data } from './1'
import { data as round3Data } from './3'
import { data as round4Data } from './4'

const excoMockData: { [roundId: number]: ExcoResponse } = {
  1: round1Data,
  3: round3Data,
  4: round4Data,
}

export const getExcoData = (roundId: number): ExcoResponse => {
  if (excoMockData[roundId]) {
    return excoMockData[roundId]
  }
  throw new Error(`No mock exco data for round ${roundId}`)
}
