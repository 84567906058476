import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

interface Props {
  className?: string
  noDivideX?: boolean
  noBorder?: boolean
  id?: string
}

const TableHeader: React.FC<PropsWithChildren<Props>> = ({
  children,
  className,
  noDivideX,
  noBorder,
  id,
}) => {
  return (
    <tr
      id={id}
      className={classNames(
        'divide-gray-300 whitespace-nowrap bg-gray-200',
        { 'divide-x': !noDivideX },
        { 'border-b': noBorder },
        className,
      )}
    >
      {children}
    </tr>
  )
}

export default TableHeader
