import React from 'react'
import Card from '../../../atoms/Card/Card'
import { formatPercentage } from '../../../../lib/formatters'
import { TreasuryResponse } from '../../../../types/gameApi/treasury'
import { DecisionMap } from '../TreasuryContainer'
import { CapitalManagmentData } from './data'
import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import SecondaryText from '../../../atoms/Text/SecondaryText'

interface CapitalRatioEstimatedProps {
  data: TreasuryResponse
  decisions: DecisionMap
  onChangeValue: (val: Partial<DecisionMap>) => void
  enableChanges?: boolean
  capitalManagmentData: CapitalManagmentData
}

const CapitalRatioEstimated: React.FC<CapitalRatioEstimatedProps> = ({
  capitalManagmentData,
  decisions,
}) => {
  const { band } = capitalManagmentData

  return (
    <Card className="px-6 py-3">
      <HorizontalGroup fullWidth between verticalCenter>
        <HorizontalGroup gap={2} verticalCenter>
          <div>
            <TranslatedText
              size="base"
              weight="bold"
              center
              value="treasury.screen4.table2Row9"
              default="Capital Ratio (Estimated)"
            />
          </div>
        </HorizontalGroup>
        <HorizontalGroup verticalCenter gap={6}>
          <SecondaryText weight="bold" colour="green">
            {formatPercentage(Number(decisions.capitalRatioChange))}
          </SecondaryText>
          <div className="rounded-lg bg-green py-0.5 px-2">
            <SecondaryText weight="bold" colour="white">
              {band}
            </SecondaryText>
          </div>
        </HorizontalGroup>
      </HorizontalGroup>
    </Card>
  )
}

export default CapitalRatioEstimated
