import React, { ReactNode } from 'react'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import Clickable from '../../../atoms/Clickable/Clickable'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import Text from '../../../atoms/Text/Text'
import { Icon } from '../../../atoms/Icon/Icon'

interface SubheadingProps {
  title: string
  content: string | ReactNode
  hint?: string
  expandedSubheading: string
  setExpandedSubheading: (subheading: string) => void
}

const SubheadingDropDown: React.FC<SubheadingProps> = ({
  title,
  content,
  hint,
  expandedSubheading,
  setExpandedSubheading,
}) => {
  const isExpanded = expandedSubheading === title

  const handleSubHeadingClick = () => {
    setExpandedSubheading(isExpanded ? '' : title)
  }

  if (!isExpanded && expandedSubheading !== '') {
    return null
  }

  return (
    <VerticalGroup
      fullWidth
      verticalCenter
      gap={2}
      className={`rounded-md border border-solid border-primary p-2 desktop:w-80 ${
        isExpanded ? 'h-full' : 'h-14'
      }`}
    >
      <Clickable onClick={handleSubHeadingClick}>
        <HorizontalGroup fullWidth verticalCenter between>
          <Text colour="primary" left>
            {title}
          </Text>
          {!isExpanded && <Icon type="chevron" colour="black" size={8} />}
        </HorizontalGroup>
      </Clickable>
      {isExpanded && (
        <VerticalGroup between full>
          <VerticalGroup gap={4}>
            <Text left size="sm">
              {content}
            </Text>
            {hint && (
              <Text left size="sm" className="bg-orange-100 p-2">
                Hint: {hint}
              </Text>
            )}
          </VerticalGroup>
          <Clickable onClick={handleSubHeadingClick}>
            <HorizontalGroup center>
              <Icon type="chevronUp" colour="black" size={10} />
            </HorizontalGroup>
          </Clickable>
        </VerticalGroup>
      )}
    </VerticalGroup>
  )
}

export default SubheadingDropDown
