/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useMemo } from 'react'
import { extractSearchParams } from '../../../../lib/extractQueryParams'
import useAPIRequest from '../../../../hooks/useAPIRequest'
import AdminAPI from '../../../../services/adminApi'
import { mapData } from './mapData'
import { AnimatedQuestions } from '../AnimatedQuestions'
import Page from '../../../atoms/Page/Page'
import CentredSpinner from '../../../molecules/CentredSpinner/CentredSpinner'

const { assessmentId, groupId, token } = extractSearchParams<{
  assessmentId: string
  groupId: string
  token: string
}>()

interface Props {}

const AssessmentPreview: React.FC<Props> = () => {
  const callback = useCallback(() => {
    return AdminAPI.getAssessmentPreview({ assessmentId, token })
  }, [])

  const [{ inProgress, data }, doAPIRequest] = useAPIRequest<any>(
    callback,
    null,
  )

  useEffect(() => {
    doAPIRequest()
  }, [doAPIRequest])

  const assessment = useMemo(() => {
    if (!data) return null
    return mapData(assessmentId, groupId, data)
  }, [data])

  if (inProgress || !assessment) {
    return <CentredSpinner />
  }

  return (
    <div className="flex tablet:h-screen">
      <main className="flex-1 overflow-y-auto">
        <Page full>
          <AnimatedQuestions
            assessmentId={assessment.assessmentId}
            currentAnswers={[]}
            questions={assessment.questions}
            onSubmit={() => {}}
            onSubmitLastQuest={() => {}}
          />
        </Page>
      </main>
    </div>
  )
}

export default AssessmentPreview
