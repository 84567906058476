import React, { useCallback, useMemo } from 'react'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import TreasuryBalanceSheetDecisionPanel from './TreasuryBalanceSheetDecisionPanel'
import { useTranslatedText } from '../../../../hooks/useTranslatedText'
import {
  formatValue,
  percentage,
  roundToFixed,
  thousandSeparator,
} from '../../../../lib/formatters'
import { DecisionMap } from '../TreasuryContainer'
import { TreasuryResponse } from '../../../../types/gameApi/treasury'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import MetricCard from '../../../organisms/MetricCard/MetricCard'
import TreasuryBalanceSheetWSFModal from './TreasuryBalanceSheetWSFModal'
import {
  calculateForecastValues,
  calculateGrowthPerBusiness,
  calculateTotalOpeningBalance,
} from '../calculations'
import ValueChangerWithInput from '../../../molecules/ValueChanger/ValueChangerWithInput'
import Button from '../../../atoms/Button/Button'
import classNames from 'classnames'
import TreasuryBalanceSheetWSFDetails from './TreasuryBalanceSheetWSFDetails'
import { titleCase } from '../../../../lib/text'
import { useGetData } from '../TreasuryCapitalManagement/data'
import TargetCapitalRatioModal from '../TreasuryCapitalManagement/TargetCapitalRatioModal'

interface Props {
  enableChanges: boolean
  decisions: DecisionMap
  data: TreasuryResponse
  targetShareCapitalMovement: number
  onChangeValue: (val: Partial<DecisionMap>) => void
}

const calculateWsfValue = (
  previousVal: number,
  previousTotal: number,
  movementsAndRepayments: number,
  defaultValue: number,
) => {
  if (previousTotal * movementsAndRepayments === 0) {
    return defaultValue
  }
  return parseInt(
    ((previousVal / previousTotal) * movementsAndRepayments).toFixed(0),
  )
}

const TreasuryBalanceSheetDecisions: React.FC<Props> = ({
  enableChanges,
  decisions,
  data,
  targetShareCapitalMovement,
  onChangeValue,
}) => {
  const capitalManagmentData = useGetData({
    data: data,
    decisions: decisions,
  })
  const depositsLabel = titleCase(
    useTranslatedText('businessDecisions.products.DEPOSITS', 'deposits'),
  )
  const assetGrowthTitle = useTranslatedText(
    'treasury.screen1.slider1Label',
    'Asset Growth',
  )
  const depositGrowthTitle = useTranslatedText(
    'treasury.screen1.slider2Label',
    `${depositsLabel} Growth`,
  )
  const wsfTitle = useTranslatedText(
    'treasury.screen1.slider3Label',
    'New Wholesale Funding Raised',
  )
  const capitalRatioTitle = useTranslatedText(
    'treasury.screen1.slider4Label',
    'Estimated Capital Ratio',
  )

  const creditRatingTitle = useTranslatedText(
    'treasury.screen1.slider5Label',
    'Credit Rating',
  )

  const onAssetGrowthChange = useCallback(
    (value: number) => {
      const { openingBalances } = data
      const totalOpeningValue = calculateTotalOpeningBalance(openingBalances)
      const forecastValues = calculateForecastValues(
        false,
        openingBalances,
        decisions,
      )
      const totalGrowth = totalOpeningValue * (value / 10000)

      onChangeValue({
        allOtherAssetsPercentage: value,
        ...calculateGrowthPerBusiness(
          totalOpeningValue,
          totalGrowth,
          forecastValues,
          openingBalances,
        ),
      })
    },
    [data, decisions, onChangeValue],
  )

  const onDepositChange = useCallback(
    (value: number) => {
      onChangeValue({
        depositGrowthChangePercentage: value,
      })
    },
    [onChangeValue],
  )

  const onWsfChange = useCallback(
    (value: number) => {
      const wsfTotalPrev =
        decisions.wsf1 +
        decisions.wsf2 +
        decisions.wsf3 +
        decisions.wsf4 +
        decisions.wsf5
      const diff = value - wsfTotalPrev
      const wsfChange = decisions.wsfChange + diff
      const wsfMovementAndRepayments =
        wsfChange + Math.abs(data.openingBalances.wsfRepayments)

      onChangeValue({
        wsfChange,
        wsf1: calculateWsfValue(
          decisions.wsf1,
          wsfTotalPrev,
          wsfMovementAndRepayments,
          0,
        ),
        wsf2: calculateWsfValue(
          decisions.wsf2,
          wsfTotalPrev,
          wsfMovementAndRepayments,
          0,
        ),
        wsf3: calculateWsfValue(
          decisions.wsf3,
          wsfTotalPrev,
          wsfMovementAndRepayments,
          0,
        ),
        wsf4: calculateWsfValue(
          decisions.wsf4,
          wsfTotalPrev,
          wsfMovementAndRepayments,
          0,
        ),
        wsf5: calculateWsfValue(
          decisions.wsf5,
          wsfTotalPrev,
          wsfMovementAndRepayments,
          wsfMovementAndRepayments,
        ),
      })
    },
    [
      data.openingBalances.wsfRepayments,
      decisions.wsf1,
      decisions.wsf2,
      decisions.wsf3,
      decisions.wsf4,
      decisions.wsf5,
      decisions.wsfChange,
      onChangeValue,
    ],
  )

  const onCapitalRatioChange = useCallback(
    (value: number) => {
      onChangeValue({
        capitalRatioChange: value,
      })
    },
    [onChangeValue],
  )

  const wsfTotalPrev = useMemo(
    () =>
      decisions.wsf1 +
      decisions.wsf2 +
      decisions.wsf3 +
      decisions.wsf4 +
      decisions.wsf5,
    [
      decisions.wsf1,
      decisions.wsf2,
      decisions.wsf3,
      decisions.wsf4,
      decisions.wsf5,
    ],
  )

  const showWSF = data.wsfMethod !== 3
  const showCapitalRatio = data.capitalMethod === 1
  const showCreditRatingButtons = data.capitalMethod === 2

  return (
    <VerticalGroup fullWidth full gap={2} className="pb-2 tablet:pb-0">
      <VerticalGroup
        full
        gap={3}
        className="grid-cols-2 grid-rows-3 gap-x-4 gap-y-2 tablet:grid laptop:flex laptop:flex-col"
      >
        <TreasuryBalanceSheetDecisionPanel
          id="tour-asset-growth"
          iconType="asset"
          backgroundColour="orange"
          title={assetGrowthTitle}
        >
          <ValueChangerWithInput
            id="treasury-balance-sheet-asset-growth"
            data-test="asset-growth"
            inputSize="large"
            {...{
              modalProps: {
                heading: assetGrowthTitle,
              },
              type: 'percentage',
              value: decisions.allOtherAssetsPercentage,
              step: 100,
              formatValue: percentage,
              onChange: onAssetGrowthChange,
              min: -5000,
              max: 5000,
              enableChanges,
              increments: [1, 5],
              previousValue: data.allOtherAssetsPercentageOriginal,
            }}
          />
        </TreasuryBalanceSheetDecisionPanel>
        <TreasuryBalanceSheetDecisionPanel
          id="tour-deposits-growth"
          iconType="depo"
          backgroundColour="purple"
          title={depositGrowthTitle}
        >
          <ValueChangerWithInput
            id="treasury-balance-sheet-deposit-growth"
            data-test="deposits-growth"
            inputSize="large"
            {...{
              modalProps: {
                heading: depositGrowthTitle,
              },
              type: 'percentage',
              value: decisions.depositGrowthChangePercentage,
              step: 100,
              formatValue: percentage,
              onChange: onDepositChange,
              min: -5000,
              max: 5000,
              enableChanges,
              increments: [1, 5],
              previousValue: data.depositGrowthChangePercentageOriginal,
            }}
          />
        </TreasuryBalanceSheetDecisionPanel>
        {showWSF && (
          <TreasuryBalanceSheetDecisionPanel
            id="tour-wsf"
            iconType="wsf"
            backgroundColour="primary"
            title={wsfTitle}
          >
            <ValueChangerWithInput
              inputSize="large"
              {...{
                modalProps: {
                  heading: wsfTitle,
                  extraData: {
                    data,
                    decisions,
                  },
                },
                type: 'integer',
                value: wsfTotalPrev,
                step: 1000,
                formatValue: (val: number) => `${thousandSeparator(val)}m`,
                onChange: onWsfChange,
                min: 0,
                max: 100000,
                enableChanges,
                CustomModal: TreasuryBalanceSheetWSFModal,
              }}
            />
          </TreasuryBalanceSheetDecisionPanel>
        )}
        {showCapitalRatio && (
          <TreasuryBalanceSheetDecisionPanel
            id="tour-capital-ratio"
            iconType="capital"
            backgroundColour="green"
            title={capitalRatioTitle}
          >
            <ValueChangerWithInput
              inputSize="large"
              {...{
                modalProps: {
                  heading: capitalRatioTitle,
                  extraData: {
                    capitalManagmentData,
                    decisions,
                    data,
                  },
                },
                type: 'percentage',
                value: decisions.capitalRatioChange,
                step: 10,
                formatValue: percentage,
                onChange: onCapitalRatioChange,
                min: data.capitalSliderMin,
                max: data.capitalSliderMax,
                enableChanges,
                increments: [1, 2.5],
                CustomModal: TargetCapitalRatioModal,
                incrementFormatter: (val: number) => roundToFixed(val / 10, 2),
                modalButtonSize: 'long',
              }}
            />
          </TreasuryBalanceSheetDecisionPanel>
        )}

        {showCreditRatingButtons && (
          <div>
            <div className="h-fit">
              <TreasuryBalanceSheetDecisionPanel
                iconType="creditrating"
                backgroundColour="darkblue"
                title={creditRatingTitle}
              >
                <div className="grid w-48 grid-cols-3 gap-2 gap-y-2 tablet:w-56">
                  {data.capitalBands.map(cb => {
                    const valueIsInBand =
                      cb.bandName === decisions.desiredCreditRating
                    return (
                      <Button
                        size="xs"
                        key={cb.bandName}
                        disabled={!enableChanges}
                        className={classNames({
                          'bg-primary': valueIsInBand,
                          'border border-primary bg-white': !valueIsInBand,
                        })}
                        onClick={() =>
                          onChangeValue({
                            capitalRatioChange:
                              cb.bandValue + data.capitalBuffer,
                            desiredCreditRating: cb.bandName,
                          })
                        }
                      >
                        <SecondaryText
                          colour={valueIsInBand ? 'white' : 'black'}
                        >
                          {cb.bandName}
                        </SecondaryText>
                      </Button>
                    )
                  })}
                </div>
              </TreasuryBalanceSheetDecisionPanel>
            </div>
          </div>
        )}

        {!showWSF && (
          <MetricCard
            alignContent="start"
            colour={'white'}
            className="col-span-2 p-4"
          >
            <HorizontalGroup verticalCenter fullWidth gap={3} className=" ">
              <TreasuryBalanceSheetWSFDetails
                data-test="wsf-details"
                value={wsfTotalPrev}
                decisions={decisions}
                data={data}
              />
            </HorizontalGroup>
          </MetricCard>
        )}

        {(showCapitalRatio || showCreditRatingButtons) && (
          <div className="col-span-2 h-20">
            <MetricCard colour={'white'} className="">
              <HorizontalGroup verticalCenter fullWidth className="p-4" between>
                <div>
                  <TranslatedText
                    weight="bold"
                    value="treasury.screen1.capitalMovement"
                    default="Estimated Share Capital Movement"
                  />
                </div>
                <SecondaryText weight="bold">
                  {formatValue(Number(targetShareCapitalMovement.toFixed(0)))}
                </SecondaryText>
              </HorizontalGroup>
            </MetricCard>
          </div>
        )}
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default TreasuryBalanceSheetDecisions
