import React, { PropsWithChildren } from 'react'
import { ChartProps } from '../SummaryMetricMapper'
import StackedBarChart from './StackedBarChart'

const legend = [
  { title: 'Increase', isCost: true },
  { title: 'Reduction', isCost: false },
]

const CustomerComplaintsChart: React.FC<PropsWithChildren<ChartProps>> = ({
  children,
  ...rest
}) => {
  return (
    <StackedBarChart
      header={children}
      formatter={val => String(val)}
      isCost
      showTotal
      legend={legend}
      {...rest}
    />
  )
}

export default CustomerComplaintsChart
