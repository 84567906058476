import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import {
  fetchTreasury,
  fetchTreasuryBackground,
  updateTreasuryDecisionsBackground,
} from '../../../redux/treasury/treasuryActions'

import InlineError from '../../atoms/InlineError/InlineError'
import CentredSpinner from '../../molecules/CentredSpinner/CentredSpinner'
import Treasury from './Treasury'
import { v4 as uuidv4 } from '../../../lib/uuid'
import { RootState } from '../../../store'
import {
  TreasuryDecisionUpdate,
  TreasuryResponse,
} from '../../../types/gameApi/treasury'
import Page from '../../atoms/Page/Page'

const anyVersionsChanged = (
  newData: TreasuryResponse,
  oldData: TreasuryResponse,
  deviceId: string,
) => {
  let hasChange = false
  if (
    newData.version !== oldData.version &&
    newData.version > oldData.version &&
    newData.updatedBy &&
    newData.updatedBy !== deviceId
  ) {
    hasChange = true
  }
  return hasChange
}

const checkChange = (
  fieldName: keyof DecisionMap,
  decisions: DecisionMap,
  lastSynced: DecisionMap,
  changes: TreasuryDecisionUpdate[],
  apiFieldName?: string,
) => {
  const newVal = parseInt(String(decisions[fieldName]))
  if (newVal !== lastSynced[fieldName]) {
    changes.push({ [apiFieldName || fieldName]: newVal })
    // @ts-expect-error this is ok
    lastSynced[fieldName] = newVal
  }
}

const mapState = (state: RootState) => {
  const selectedRound = state.game.selectedRound
  return {
    currentTab: state.treasury.currentTab,
    enableChanges: state.game.selectedRound === state.game.currentRound,
    teamNumber: state.team.id ?? 0,
    selectedRound,
    roundNumber: state.game.currentRound,
    isLoading: state.treasury.isLoading,
    isLoaded: state.treasury.isLoaded,
    error: state.treasury.error,
    treasury: state.treasury.data[selectedRound] ?? null,
  }
}

const mapDispatch = {
  fetchTreasury,
  updateTreasuryDecisionsBackground,
  fetchTreasuryBackground,
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {}

type State = {
  decisions: DecisionMap | null
  readyForDisplay: boolean
  uniqueDeviceId: string
  forceRerender?: number
}

export interface DecisionMap {
  allOtherAssetsPercentage: number
  depositGrowthChangePercentage: number
  wsfChange: number
  capitalRatioChange: number
  wsf1: number
  wsf2: number
  wsf3: number
  wsf4: number
  wsf5: number
  homeLoansRisk: number
  businessLoansRisk: number
  creditCardsRisk: number
  institutionalBankingRisk: number
  offshoreBankRisk: number
  wealthRisk: number
  investmentsRisk: number
  fixedAssetsRisk: number
  opexRisk: number
  homeLoansGrowth: number
  businessLoansGrowth: number
  creditCardsGrowth: number
  institutionalBankingGrowth: number
  offshoreBankGrowth: number
  wealthGrowth: number
  investmentsGrowth: number
  fixedAssetsGrowth: number
  desiredCreditRating: string
  dividendsReinvested: number
  groupNpat: number
  additionalSharesIssued: number
}

class TreasuryContainer extends Component<Props, State> {
  lastSynced: DecisionMap | null = null
  private interval: NodeJS.Timeout | null = null
  constructor(props: Props) {
    super(props)
    this.state = {
      decisions: null,
      readyForDisplay: false,
      uniqueDeviceId: uuidv4(),
    }
  }

  componentDidMount() {
    if (this.props.treasury) {
      this.setReadyForDisplay()
      this.props.fetchTreasuryBackground({
        roundId: this.props.selectedRound,
        teamId: this.props.teamNumber,
      })
    } else {
      this.props.fetchTreasury({
        roundId: this.props.selectedRound,
        teamId: this.props.teamNumber,
      })
    }
    this.pollChanges()
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
      this.checkAndUpdate()
      this.lastSynced = null
    }
  }

  pollChanges = () => {
    this.interval = setInterval(() => {
      this.checkAndUpdate()
      this.props.fetchTreasuryBackground({
        roundId: this.props.selectedRound,
        teamId: this.props.teamNumber,
      })
    }, 5000)
  }

  setReadyForDisplay = () => {
    this.lastSynced = {
      allOtherAssetsPercentage: this.props.treasury.allOtherAssetsPercentage,
      depositGrowthChangePercentage:
        this.props.treasury.depositGrowthChangePercentage,
      wsfChange: this.props.treasury.wsfChange,
      capitalRatioChange: this.props.treasury.capitalRatioChange,
      wsf1: this.props.treasury.wsf1,
      wsf2: this.props.treasury.wsf2,
      wsf3: this.props.treasury.wsf3,
      wsf4: this.props.treasury.wsf4,
      wsf5: this.props.treasury.wsf5,
      homeLoansRisk: this.props.treasury.homeLoansRisk,
      businessLoansRisk: this.props.treasury.businessLoansRisk,
      creditCardsRisk: this.props.treasury.creditCardsRisk,
      institutionalBankingRisk: this.props.treasury.institutionalBankingRisk,
      offshoreBankRisk: this.props.treasury.offshoreBankRisk,
      wealthRisk: this.props.treasury.wealthRisk,
      investmentsRisk: this.props.treasury.investmentsRisk,
      fixedAssetsRisk: this.props.treasury.fixedAssetsRisk,
      opexRisk: this.props.treasury.opexRisk,
      homeLoansGrowth: this.props.treasury.homeLoansGrowth,
      businessLoansGrowth: this.props.treasury.businessLoansGrowth,
      creditCardsGrowth: this.props.treasury.creditCardsGrowth,
      institutionalBankingGrowth:
        this.props.treasury.institutionalBankingGrowth,
      offshoreBankGrowth: this.props.treasury.offshoreBankGrowth,
      wealthGrowth: this.props.treasury.wealthGrowth,
      investmentsGrowth: this.props.treasury.investmentsGrowth,
      fixedAssetsGrowth: this.props.treasury.fixedAssetsGrowth,
      desiredCreditRating: this.props.treasury.desiredCreditRating,
      dividendsReinvested: this.props.treasury.dividendsReinvested,
      groupNpat: this.props.treasury.groupNpat,
      additionalSharesIssued: this.props.treasury.additionalSharesIssued,
    }
    this.setState({
      readyForDisplay: true,
      forceRerender: new Date().valueOf(),
      decisions: {
        allOtherAssetsPercentage: this.props.treasury.allOtherAssetsPercentage,
        depositGrowthChangePercentage:
          this.props.treasury.depositGrowthChangePercentage,
        wsfChange: this.props.treasury.wsfChange,
        capitalRatioChange: this.props.treasury.capitalRatioChange,
        wsf1: this.props.treasury.wsf1,
        wsf2: this.props.treasury.wsf2,
        wsf3: this.props.treasury.wsf3,
        wsf4: this.props.treasury.wsf4,
        wsf5: this.props.treasury.wsf5,
        homeLoansRisk: this.props.treasury.homeLoansRisk,
        businessLoansRisk: this.props.treasury.businessLoansRisk,
        creditCardsRisk: this.props.treasury.creditCardsRisk,
        institutionalBankingRisk: this.props.treasury.institutionalBankingRisk,
        offshoreBankRisk: this.props.treasury.offshoreBankRisk,
        wealthRisk: this.props.treasury.wealthRisk,
        investmentsRisk: this.props.treasury.investmentsRisk,
        fixedAssetsRisk: this.props.treasury.fixedAssetsRisk,
        opexRisk: this.props.treasury.opexRisk,
        homeLoansGrowth: this.props.treasury.homeLoansGrowth,
        businessLoansGrowth: this.props.treasury.businessLoansGrowth,
        creditCardsGrowth: this.props.treasury.creditCardsGrowth,
        institutionalBankingGrowth:
          this.props.treasury.institutionalBankingGrowth,
        offshoreBankGrowth: this.props.treasury.offshoreBankGrowth,
        wealthGrowth: this.props.treasury.wealthGrowth,
        investmentsGrowth: this.props.treasury.investmentsGrowth,
        fixedAssetsGrowth: this.props.treasury.fixedAssetsGrowth,
        desiredCreditRating: this.props.treasury.desiredCreditRating,
        dividendsReinvested: this.props.treasury.dividendsReinvested,
        groupNpat: this.props.treasury.groupNpat,
        additionalSharesIssued: this.props.treasury.additionalSharesIssued,
      },
    })
  }

  checkAndUpdate = () => {
    const changes: TreasuryDecisionUpdate[] = []
    if (this.state.decisions == null) {
      return
    }
    const decisions: DecisionMap = { ...this.state.decisions }

    if (this.lastSynced == null) {
      return
    }

    // console.log('decisions', decisions)
    // console.log('synced', this.lastSynced)

    checkChange(
      'allOtherAssetsPercentage',
      decisions,
      this.lastSynced,
      changes,
      'assetGrowthChange',
    )
    checkChange(
      'depositGrowthChangePercentage',
      decisions,
      this.lastSynced,
      changes,
      'depositGrowthChange',
    )
    checkChange('wsfChange', decisions, this.lastSynced, changes)
    checkChange('capitalRatioChange', decisions, this.lastSynced, changes)
    checkChange('wsf1', decisions, this.lastSynced, changes)
    checkChange('wsf2', decisions, this.lastSynced, changes)
    checkChange('wsf3', decisions, this.lastSynced, changes)
    checkChange('wsf4', decisions, this.lastSynced, changes)
    checkChange('wsf5', decisions, this.lastSynced, changes)

    checkChange('homeLoansRisk', decisions, this.lastSynced, changes)
    checkChange('businessLoansRisk', decisions, this.lastSynced, changes)
    checkChange('creditCardsRisk', decisions, this.lastSynced, changes)
    checkChange('institutionalBankingRisk', decisions, this.lastSynced, changes)
    checkChange('offshoreBankRisk', decisions, this.lastSynced, changes)
    checkChange('wealthRisk', decisions, this.lastSynced, changes)
    checkChange('investmentsRisk', decisions, this.lastSynced, changes)
    checkChange('fixedAssetsRisk', decisions, this.lastSynced, changes)
    checkChange('opexRisk', decisions, this.lastSynced, changes)

    checkChange('homeLoansGrowth', decisions, this.lastSynced, changes)
    checkChange('businessLoansGrowth', decisions, this.lastSynced, changes)
    checkChange('creditCardsGrowth', decisions, this.lastSynced, changes)
    checkChange(
      'institutionalBankingGrowth',
      decisions,
      this.lastSynced,
      changes,
    )
    checkChange('offshoreBankGrowth', decisions, this.lastSynced, changes)
    checkChange('wealthGrowth', decisions, this.lastSynced, changes)
    checkChange('investmentsGrowth', decisions, this.lastSynced, changes)
    checkChange('fixedAssetsGrowth', decisions, this.lastSynced, changes)

    checkChange('dividendsReinvested', decisions, this.lastSynced, changes)
    checkChange('groupNpat', decisions, this.lastSynced, changes)
    checkChange('additionalSharesIssued', decisions, this.lastSynced, changes)

    const newdesiredCreditRating = decisions.desiredCreditRating
    if (newdesiredCreditRating !== this.lastSynced.desiredCreditRating) {
      changes.push({ desiredCreditRating: newdesiredCreditRating })
      this.lastSynced.desiredCreditRating = newdesiredCreditRating
    }

    if (changes.length) {
      // console.log(changes)
      this.props.updateTreasuryDecisionsBackground({
        teamId: this.props.teamNumber,
        roundId: this.props.selectedRound,
        decisions: changes,
        deviceId: this.state.uniqueDeviceId,
      })
    }
  }

  retry = () => {
    this.props.fetchTreasury({
      roundId: this.props.selectedRound,
      teamId: this.props.teamNumber,
    })
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.selectedRound !== prevProps.selectedRound ||
      this.props.roundNumber !== prevProps.roundNumber ||
      this.props.teamNumber !== prevProps.teamNumber
    ) {
      this.lastSynced = null
      return this.props.fetchTreasury({
        roundId: this.props.selectedRound,
        teamId: this.props.teamNumber,
      })
    }
    const shouldUpdateDecisions =
      (this.props.treasury && !prevProps.treasury) ||
      (this.props.treasury &&
        !prevState.readyForDisplay &&
        !this.state.readyForDisplay) ||
      (this.props.treasury &&
        prevProps.treasury &&
        anyVersionsChanged(
          this.props.treasury,
          prevProps.treasury,
          prevState.uniqueDeviceId,
        )) ||
      (this.props.treasury && !this.lastSynced)

    if (shouldUpdateDecisions) {
      this.setReadyForDisplay()
    }
  }

  handleChangeValue = (
    changes: Partial<DecisionMap>,
    forceRerender?: number,
  ) => {
    if (this.state.decisions == null) {
      return
    }
    const stateToUpdate: Partial<State> = {
      decisions: {
        ...this.state.decisions,
        ...changes,
      },
    }

    if (forceRerender) {
      stateToUpdate.forceRerender = forceRerender
    }
    // @ts-expect-error not sure how to make this typesafe
    this.setState(stateToUpdate)
  }

  render() {
    if (this.props.error) {
      return (
        <Page full>
          <InlineError
            message={this.props.error.message}
            onRetry={!this.props.treasury ? this.retry : undefined}
          />
        </Page>
      )
    }
    if (
      this.props.isLoading ||
      !this.state.readyForDisplay ||
      !this.state.decisions
    ) {
      return <CentredSpinner />
    }
    if (!this.props.treasury) {
      return (
        <InlineError
          message={`No treasury data has been uploaded for round ${this.props.selectedRound}`}
        />
      )
    }
    return (
      <Treasury
        data={this.props.treasury}
        selectedRound={this.props.selectedRound}
        decisions={this.state.decisions}
        onChangeValue={this.handleChangeValue}
        // currentPage={this.props.currentPage}
        // updatePage={this.props.updatePage}
        enableChanges={this.props.enableChanges}
        // forceRerender={this.state.forceRerender}
      />
    )
  }
}

export default connector(TreasuryContainer)
