import React, { useMemo, useCallback, useEffect } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { ChoiceField, Field } from './Field/ChoiceField'
import {
  useCharacterLimitValidator,
  WrittenResponseField as WrittenResponseFieldComponent,
} from './Field/WrittenResponseField'
import {
  Answer,
  AssessmentQuestion,
  CheckboxesField,
  CurrentAnswer,
  WrittenResponseField,
} from '../../../../types/gameApi/assessments'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import QuestionTitle from './QuestionTitle'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import QuestionContainer from './QuestionContainer'

interface Props {
  questionIndex: number
  question: AssessmentQuestion
  onNext: (e: any) => void
  currentAnswer?: CurrentAnswer
}

const setFieldValue = (
  currentValue: Answer[],
  field: { id: string },
  value: string,
  onChange: (values: Answer[]) => void,
) => {
  if (!currentValue || currentValue.length === 0) {
    return
  }

  onChange(
    currentValue.map(answer => {
      if (answer.fieldId === field.id) {
        return { fieldId: field.id, value }
      }

      return answer
    }),
  )
}

const getFieldValue = (currentValue: Answer[], field: { id: string }) => {
  if (!currentValue || currentValue.length === 0) {
    return ''
  }

  const answer = currentValue.find(answer => answer.fieldId === field.id)
  return answer ? answer.value : ''
}

export const Checkboxes: React.FC<Props> = ({
  question,
  questionIndex,
  currentAnswer,
  onNext,
}) => {
  const { control, setValue, getValues } = useFormContext()
  const { fields, id } = question

  const choiceFields = useMemo(() => {
    return fields.filter(field => field.type === 'checkboxes')
  }, [fields]) as CheckboxesField[]

  const otherField = useMemo(() => {
    return fields.find(field => field.type === 'written_response')
  }, [fields]) as WrittenResponseField

  useEffect(() => {
    if (
      typeof currentAnswer !== 'undefined' &&
      typeof getValues()[id] === 'undefined'
    ) {
      setValue(id, currentAnswer.answer)
    }
  }, [currentAnswer, id, setValue, getValues])

  const onChangeHandler = (
    currentValue: Answer[],
    field: Field,
    onChange: (values: Answer[]) => void,
  ) => {
    if (currentValue && currentValue.length > 0) {
      if (currentValue.find(answer => answer.fieldId === field.id)) {
        onChange(currentValue.filter(answer => answer.fieldId !== field.id))
      } else {
        onChange([{ fieldId: field.id }])
      }
    } else {
      onChange([{ fieldId: field.id }])
    }

    if (field.id !== otherField?.id) {
      onNext({
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        preventDefault: () => {},
      })
    }
  }

  const hasField = (currentValue: Answer[], field: { id: string }) => {
    if (!currentValue || currentValue.length === 0) {
      return false
    }

    return !!currentValue.find(answer => answer.fieldId === field.id)
  }

  const atLeastOneSelected = useCallback(
    (value: Answer[]) => {
      if (!question.mandatory) {
        return true
      }

      if (value.length === 0) {
        return 'Should at least have one choice selected'
      }
      return true
    },
    [question.mandatory],
  )

  const otherFieldWithinLimit = useCharacterLimitValidator(
    otherField,
    getFieldValue,
  )

  return (
    <Controller
      name={id}
      control={control}
      shouldUnregister={false}
      rules={{
        validate: {
          required: atLeastOneSelected,
          otherFieldWithinLimit: otherFieldWithinLimit,
        },
      }}
      render={({ field: { onChange, value }, fieldState }) => {
        return (
          <QuestionContainer>
            <QuestionTitle
              hasQuestionIndex
              questionIndex={questionIndex}
              title={question.question}
            />
            <VerticalGroup gap={2}>
              {choiceFields.map((field, index) => (
                <ChoiceField
                  key={field.id}
                  index={index}
                  field={field}
                  selected={hasField(value, field)}
                  onClick={field => {
                    onChangeHandler(value, field, onChange)
                  }}
                />
              ))}
              {otherField && (
                <ChoiceField
                  key={otherField.id}
                  index={choiceFields.length}
                  field={{
                    id: otherField.id,
                    type: 'checkboxes',
                    config: {
                      type: 'checkboxes',
                      label: 'Other',
                    },
                  }}
                  selected={hasField(value, otherField)}
                  onClick={field => {
                    onChangeHandler(value, field, onChange)
                  }}
                />
              )}
              {value && otherField && hasField(value, otherField) && (
                <WrittenResponseFieldComponent
                  placeholder="Type your other answer here"
                  onChange={newValue => {
                    setFieldValue(value, otherField, newValue, onChange)
                  }}
                  value={getFieldValue(value, otherField) || ''}
                  characterLimit={otherField.config.characterLimit}
                />
              )}
              {fieldState.error && (
                <SecondaryText colour="error" size="xs">
                  {fieldState.error.message}
                </SecondaryText>
              )}
            </VerticalGroup>
          </QuestionContainer>
        )
      }}
    />
  )
}
