import React, { useCallback, useState } from 'react'
import { TreasuryResponse } from '../../../../types/gameApi/treasury'
import { DecisionMap } from '../TreasuryContainer'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import ShowIfMobileLandscape from '../../../atoms/HideIfMobile/ShowIfMobileLandscape'
import { formatValue, thousandSeparator } from '../../../../lib/formatters'

import HideIfMobileLandscape from '../../../atoms/HideIfMobile/HideIfMobileLandscape'
import { Icon } from '../../../atoms/Icon/Icon'
import ValueChangerWithInput from '../../../molecules/ValueChanger/ValueChangerWithInput'
import TreasuryWSFProfileModal from './TreasuryWSFProfileModal'
import Button from '../../../atoms/Button/Button'
import Modal from '../../../atoms/Modal/Modal'

interface Props {
  data: TreasuryResponse
  decisions: DecisionMap
  enableChanges: boolean
  onChangeValue: (val: Partial<DecisionMap>) => void
  id?: string
}

const WSFYear = ({
  year,
  newValue,
  existing,
  enableChanges,
  data,
  decisions,
  onChangeValue,
  id,
}: {
  year: 1 | 2 | 3 | 4 | 5
  newValue: number
  existing: number
  enableChanges: boolean
  data: TreasuryResponse
  decisions: DecisionMap
  id?: string
  onChangeValue: (val: Partial<DecisionMap>) => void
}) => {
  const onChange = useCallback(
    (val: number) => {
      const total =
        decisions.wsf1 +
        decisions.wsf2 +
        decisions.wsf3 +
        decisions.wsf4 +
        decisions.wsf5 -
        decisions[`wsf${year}`] +
        val
      const wsfChange = total - Math.abs(data.openingBalances.wsfRepayments)
      onChangeValue({
        wsfChange,
        [`wsf${year}`]: val,
      })
    },
    [data.openingBalances.wsfRepayments, decisions, onChangeValue, year],
  )

  const onChangeAndMove = useCallback(
    (val: number) => {
      const diff = val - decisions[`wsf${year}`]
      onChangeValue({
        wsf5: decisions.wsf5 - diff,
        [`wsf${year}`]: val,
      })
    },
    [decisions, onChangeValue, year],
  )

  const [showModal, setShowModal] = useState(false)

  const resetFunding = () => {
    onChangeValue({
      wsf1: 0,
      wsf2: 0,
      wsf3: 0,
      wsf4: 0,
      wsf5:
        decisions.wsf1 +
        decisions.wsf2 +
        decisions.wsf3 +
        decisions.wsf4 +
        decisions.wsf5,
    })
    setShowModal(false)
  }

  return (
    <VerticalGroup gap={3} center>
      <HorizontalGroup>
        <TranslatedText
          size="xs"
          value="general.year"
          default="Year"
          prefix={`${year} `}
          suffix={`${year === 5 ? '+' : ''}`}
        />

        <ShowIfMobileLandscape>
          <ValueChangerWithInput
            hideButtons
            modalProps={{
              heading: `${year} Year${year === 5 ? '+' : ''}`,
              extraData: {
                existingFunding: existing,
                data,
                decisions,
                year,
                onChangeAndMove,
              },
            }}
            type={'integer'}
            value={newValue}
            formatValue={thousandSeparator}
            onChange={onChange}
            enableChanges={enableChanges}
            CustomModal={TreasuryWSFProfileModal}
            showEditIcon
          />
        </ShowIfMobileLandscape>
      </HorizontalGroup>
      <VerticalGroup className="w-20" gap={1}>
        <HideIfMobileLandscape>
          <HorizontalGroup gap={2}>
            <ValueChangerWithInput
              hideButtons
              modalProps={{
                heading: `${year} Year${year === 5 ? '+' : ''}`,
                extraData: {
                  existingFunding: existing,
                  data,
                  decisions,
                  year,
                  onChangeAndMove,
                },
              }}
              type={'integer'}
              value={newValue}
              formatValue={thousandSeparator}
              onChange={onChange}
              enableChanges={enableChanges}
              CustomModal={TreasuryWSFProfileModal}
              buttonIdPrefix={id}
            />
            {year === 5 && (
              <>
                {enableChanges && (
                  <Button
                    className="rounded-full"
                    buttonType="bordered"
                    size="xs"
                    onClick={() => setShowModal(true)}
                  >
                    <Icon colour="black" type="reset"></Icon>
                  </Button>
                )}
                <Modal
                  show={showModal}
                  title="Confirm Reset"
                  onOk={resetFunding}
                  onCancel={() => setShowModal(false)}
                  okButtonText="Yes"
                  cancelButtonText="Cancel"
                  onClose={() => setShowModal(false)}
                >
                  <p>Are you sure you want to move all funding into Year5?</p>
                </Modal>
              </>
            )}
          </HorizontalGroup>
          {/* <NumericInput
            size="base"
            paddingY={2}
            type={'integer'}
            value={newValue}
            onChange={function (): void {
              throw new Error('Function not implemented.')
            }}
            onError={function (): void {
              throw new Error('Function not implemented.')
            }}
            validateInput={() => undefined}
          /> */}
          <SecondaryText center weight="bold">
            {formatValue(existing)}
          </SecondaryText>
        </HideIfMobileLandscape>
        <ShowIfMobileLandscape>
          <SecondaryText size="xs" weight="bold">
            {formatValue(existing)}
          </SecondaryText>
        </ShowIfMobileLandscape>
      </VerticalGroup>
    </VerticalGroup>
  )
}

const TreasuryWSFProfileManageFunding: React.FC<Props> = ({
  data,
  decisions,
  enableChanges,
  onChangeValue,
}) => {
  const newFundingTotal =
    decisions.wsf1 +
    decisions.wsf2 +
    decisions.wsf3 +
    decisions.wsf4 +
    decisions.wsf5
  const existingFundingTotal =
    data.fundingProfile.wsfYr1 +
    data.fundingProfile.wsfYr2 +
    data.fundingProfile.wsfYr3 +
    data.fundingProfile.wsfYr4 +
    data.fundingProfile.wsfYr5

  const yearsData: Array<{
    year: 1 | 2 | 3 | 4 | 5
    newValue: number
    existing: number
  }> = [
    { year: 1, newValue: decisions.wsf1, existing: data.fundingProfile.wsfYr1 },
    { year: 2, newValue: decisions.wsf2, existing: data.fundingProfile.wsfYr2 },
    { year: 3, newValue: decisions.wsf3, existing: data.fundingProfile.wsfYr3 },
    { year: 4, newValue: decisions.wsf4, existing: data.fundingProfile.wsfYr4 },
    { year: 5, newValue: decisions.wsf5, existing: data.fundingProfile.wsfYr5 },
  ]
  return (
    <VerticalGroup fullWidth gap={3}>
      <HorizontalGroup fullWidth center>
        <ShowIfMobileLandscape>
          <div className="">
            <TranslatedText
              weight="bold"
              size="sm"
              value="treasury.screen2.screenHeader"
              default="WSF PROFILE VS ASSETS"
              suffix=":"
            />
          </div>

          <HorizontalGroup className="mr-20" gap={1} verticalCenter>
            <TranslatedText
              size="sm"
              weight="bold"
              center
              value="treasury.screen2.screenSubHeader"
              default="MANAGE FUNDING"
            />
            <SecondaryText size="xs">({data.denomination})</SecondaryText>
          </HorizontalGroup>
        </ShowIfMobileLandscape>
        <HideIfMobileLandscape>
          <HorizontalGroup fullWidth center gap={1} verticalCenter>
            <TranslatedText
              weight="bold"
              center
              value="treasury.screen2.screenSubHeader"
              default="MANAGE FUNDING"
            />
            <SecondaryText colour="gray" size="xs">
              ({data.denomination})
            </SecondaryText>
          </HorizontalGroup>
        </HideIfMobileLandscape>
      </HorizontalGroup>
      <HorizontalGroup fullWidth between>
        <HideIfMobileLandscape>
          <VerticalGroup gap={3} className="pt-1 pl-5 text-right">
            <div>&nbsp;</div>

            <HorizontalGroup fullWidth className="justify-end">
              <TranslatedText
                className="text-right"
                value="treasury.screen2.newLabel"
                default="New"
              />
            </HorizontalGroup>

            <TranslatedText
              value="treasury.screen2.existingLabel"
              default="Existing"
            />
          </VerticalGroup>
        </HideIfMobileLandscape>
        <HideIfMobileLandscape>
          <HorizontalGroup fullWidth around className="mx-8 pl-1">
            {yearsData.map((yearData, index) => (
              <HorizontalGroup className="" key={yearData.year}>
                <WSFYear
                  {...yearData}
                  enableChanges={enableChanges}
                  data={data}
                  decisions={decisions}
                  onChangeValue={onChangeValue}
                  id={
                    index === yearsData.length - 1
                      ? 'tour-last-wsf-year'
                      : index === yearsData.length - 3
                      ? 'tour-wsf-third-year'
                      : undefined
                  }
                />

                <ShowIfMobileLandscape>
                  <Icon className="ml-[-1rem]" type="pencil"></Icon>
                </ShowIfMobileLandscape>
              </HorizontalGroup>
            ))}
          </HorizontalGroup>
        </HideIfMobileLandscape>
        <ShowIfMobileLandscape>
          <VerticalGroup className="w-28" between>
            <SecondaryText size="xs" noLeading weight="bold">
              &nbsp;
            </SecondaryText>
            <VerticalGroup>
              <TranslatedText
                value="treasury.screen2.existingLabel"
                default="Existing"
                size="xs"
                weight="bold"
              />
            </VerticalGroup>
          </VerticalGroup>
          <HorizontalGroup between className="ml-0.5 w-full pl-3">
            {yearsData.map(yearData => (
              <HorizontalGroup className="" key={yearData.year}>
                <WSFYear
                  {...yearData}
                  enableChanges={enableChanges}
                  data={data}
                  decisions={decisions}
                  onChangeValue={onChangeValue}
                />
              </HorizontalGroup>
            ))}
          </HorizontalGroup>

          <VerticalGroup className="ml-10 mr-2 w-40" between>
            <SecondaryText size="xs" noLeading weight="bold">
              TOTAL
            </SecondaryText>
            <VerticalGroup>
              <SecondaryText size="xs" weight="bold">
                {formatValue(existingFundingTotal)}
              </SecondaryText>
            </VerticalGroup>
          </VerticalGroup>
        </ShowIfMobileLandscape>

        <HideIfMobileLandscape>
          <VerticalGroup className="w-56" gap={5}>
            <SecondaryText
              paddingRight={24}
              className="leading-tight"
              weight="bold"
            >
              TOTAL
            </SecondaryText>

            <VerticalGroup gap={3}>
              <SecondaryText weight="bold">
                {formatValue(newFundingTotal)}
              </SecondaryText>

              <SecondaryText weight="bold">
                {formatValue(existingFundingTotal)}
              </SecondaryText>
            </VerticalGroup>
          </VerticalGroup>
        </HideIfMobileLandscape>
      </HorizontalGroup>
    </VerticalGroup>
  )
}

export default TreasuryWSFProfileManageFunding
