import React, { useCallback, useEffect } from 'react'
import { Bars3Icon } from '@heroicons/react/24/outline'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import { useAppDispatch, useAppSelector } from '../../../store'
import RoundSelectButton, { RoundButtonState } from './RoundSelectButton'
import { updateSelectedRound } from '../../../redux/game/gameSlice'
import Clock from './Clock/Clock'
import { useRoutes } from '../../../hooks/useRoutes'
import PrimaryText from '../../atoms/Text/PrimaryText'
import { useNavigate } from 'react-router-dom'
import useMediaQuery from '../../../hooks/useMediaQuery'
import InAppHelp from '../InAppHelp/InAppHelp'
import InAppHelpContent from '../InAppHelp/InAppHelpContent'
import Text from '../../atoms/Text/Text'

type Props = {
  toggleSidebar: (value: boolean) => void
}

const Header: React.FC<Props> = ({ toggleSidebar }) => {
  const { rounds, isWrapUp } = useAppSelector(state => ({
    rounds: {
      count: state.game.totalRounds,
      current: state.game.currentRound,
      selected: state.game.selectedRound,
    },
    isWrapUp: state.game.state === 'wrapup',
  }))

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleRoundSelect = useCallback(
    (round: number) => {
      dispatch(updateSelectedRound(round))
    },
    [dispatch],
  )

  const { current } = useRoutes()

  useEffect(() => {
    if (!current) {
      navigate('/summary')
    }
  }, [current, navigate])

  const roundButtons = []
  if (rounds) {
    for (let r = 1; r <= rounds.count; r++) {
      let state: RoundButtonState = 'next'
      if (rounds.selected === r) {
        state = 'curr'
      } else if (r <= rounds.current) {
        state = 'prev'
      }
      roundButtons.push(
        <RoundSelectButton
          key={r}
          label={`Round ${r}`}
          state={state}
          round={r}
          onButtonPress={handleRoundSelect}
          className={
            rounds.current === 2 && r === 1 ? 'tour-step-previous-round' : ''
          }
        />,
      )
    }
    roundButtons.push(
      <RoundSelectButton
        key={rounds.count + 1}
        label={'Wrap Up'}
        state={isWrapUp ? 'curr' : 'wrap'}
        round={rounds.count + 1}
        onButtonPress={handleRoundSelect}
      />,
    )
  }

  const isTabletOrLarger = useMediaQuery('tablet')

  return (
    <HorizontalGroup
      center
      gap={4}
      className="sticky top-0 z-20 h-12 flex-shrink-0 items-center bg-white p-4 laptop:h-20"
    >
      <button
        type="button"
        className="px-2 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 desktop:hidden"
        onClick={() => toggleSidebar(true)}
      >
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      {current && (
        <HorizontalGroup className="flex-grow-1 flex w-full laptop:hidden">
          <PrimaryText whitespace="nowrap" weight="bold" size="xl">
            {current.name}
          </PrimaryText>
        </HorizontalGroup>
      )}
      <HorizontalGroup between fullWidth>
        <HorizontalGroup className="laptop:gap-3 desktop:gap-10">
          {roundButtons}
        </HorizontalGroup>
        <Clock currentRound={rounds.current} />
      </HorizontalGroup>
      {isTabletOrLarger && rounds.current == 2 && (
        <InAppHelp
          steps={[
            {
              target: '.tour-step-previous-round',
              content: (
                <InAppHelpContent heading="Previous Round Decisions">
                  <Text left>
                    {"To view previous decisions click on that round's button."}
                  </Text>
                </InAppHelpContent>
              ),
            },
          ]}
          storageKey="previous-round-help"
        />
      )}
    </HorizontalGroup>
  )
}

export default Header
