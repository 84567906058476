import React, { useCallback } from 'react'
import classNames from 'classnames'
import { SummaryResponse } from '../../../types/gameApi/summary'
import { Colour } from '../../../types/theme'
import { Icon } from '../../atoms/Icon/Icon'
import { useNavigate } from 'react-router-dom'
import PrimaryText from '../../atoms/Text/PrimaryText'
import MetricCard from '../../organisms/MetricCard/MetricCard'
import MetricCardIconBox from '../../organisms/MetricCard/MetricCardIconBox'
import MetricChangePill from '../../organisms/MetricCard/MetricChangePill'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import { getIconForSummaryMetric, getMetricColours } from '../../../lib/icons'
import { useAppDispatch, useAppSelector } from '../../../store'
import {
  changeMetric,
  changeTab,
} from '../../../redux/peer-insights/peerInsightsSlice'
import InfoPopup from '../../atoms/InfoPopup/InfoPopup'
import BulletPoint from '../../atoms/BulletPoint/BulletPoint'
import Modal from '../../atoms/Modal/Modal'
import Clickable from '../../atoms/Clickable/Clickable'
import { addInfoClickEvent } from '../../../lib/googleTagManager'

interface Props {
  index: number
  item: SummaryResponse
  numTiles: number
  showExplanation: boolean
}

const SummaryItem: React.FC<Props> = ({ item, index, showExplanation }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [showModal, setShowModal] = React.useState(false)

  const { selectedRound, roundsSetup } = useAppSelector(state => ({
    roundsSetup: state.game.roundsSetup,
    selectedRound: state.game.selectedRound,
  }))

  const handleMetricChangeClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      setShowModal(true)
      if (showExplanation) {
        addInfoClickEvent({
          category: 'summary',
          action: 'open',
          label: item.type,
          value: 'change-explanation',
        })
      }
    },
    [item.type, showExplanation],
  )

  const selectedRoundSetup = roundsSetup.find(rs => rs.round === selectedRound)

  const clickable = selectedRoundSetup && selectedRoundSetup.insightsEnabled

  const onHandleClick = useCallback(() => {
    if (clickable) {
      dispatch(changeMetric(item.type))
      dispatch(changeTab('metrics'))
      navigate('/peer-insights')
    }
  }, [clickable, dispatch, item.type, navigate])

  const { value: placeChange, suffix: placeChangeSuffix } = item.place_change
  const { value, suffix: valueSuffix } = item.value
  const { value: place, ordinal } = item.place
  const { value_change: valueChange } = item
  const colour: Colour = getMetricColours(index)

  const showMedal = ['1', '2', '3'].includes(place ?? '')
  return (
    <MetricCard
      id={index === 0 ? 'key-metric' : undefined}
      data-test={`summary-item-${item.type}`}
      fullHeight={false}
      className={classNames('h-full w-full', {
        'cursor-pointer': clickable,
      })}
      useShade
      colour={colour}
      onClick={onHandleClick}
    >
      <HorizontalGroup reverseRow between fullWidth className="h-7 p-2">
        <InfoPopup
          id={index === 0 ? 'metric-help-icon' : undefined}
          iconSize={6}
          iconColour="white"
          title={item.helpHeading}
          body={item.helpBody}
          analyticsParams={{
            category: 'summary',
            action: 'open',
            label: item.type,
          }}
        />

        {item.winningPercentage > 0 && (
          <InfoPopup
            id="metric-indicator"
            iconType="trophy"
            iconSize={6}
            iconColour="white"
            title="Winning Metric"
            body={`This metric has a ${item.winningPercentage}% weighting towards your
            overall score in this simulation`}
          />
        )}
      </HorizontalGroup>
      <VerticalGroup verticalCenter className="h-full" fullWidth>
        <VerticalGroup fullWidth gap={2} verticalCenter center className=" ">
          <MetricCardIconBox
            useShades
            size="large"
            iconType={getIconForSummaryMetric(item.type)}
            iconColour="white"
            backgroundColour={colour}
            iconSize={14}
          />
          <div>
            <HorizontalGroup center verticalCenter gap={1.5}>
              <PrimaryText
                colour="white"
                size="xl"
                weight="semibold"
                whitespace="nowrap"
                notResponsive
                id={index === 0 ? 'metric-heading' : undefined}
              >
                {item.heading}
              </PrimaryText>
            </HorizontalGroup>
            <div className="h-8 whitespace-nowrap font-bold">
              {place && ordinal && (
                <div
                  className={classNames(
                    'flex items-center justify-center gap-0.5',
                  )}
                >
                  <HorizontalGroup
                    gap={0.5}
                    verticalCenter
                    id={index === 0 ? 'metric-ranking' : undefined}
                  >
                    {showMedal && (
                      <Icon className="h-4 w-4" type="medal" colour="white" />
                    )}
                    <PrimaryText size="lg" colour="white" notResponsive>
                      {place}
                    </PrimaryText>

                    <PrimaryText
                      whitespace="nowrap"
                      size="sm"
                      colour="white"
                      notResponsive
                    >
                      {ordinal}
                    </PrimaryText>
                  </HorizontalGroup>
                  <div className="mx-2 flex h-0.5 w-2 rotate-90 transform items-center justify-center border border-solid border-white border-opacity-0 bg-white bg-opacity-50"></div>
                  <HorizontalGroup
                    id={index === 0 ? 'metric-place-change' : undefined}
                    center
                    verticalCenter
                    gap={0.5}
                    data-test="place-change"
                  >
                    {placeChange != null && placeChange.includes('-') ? (
                      <>
                        <Icon className="h-4 w-4" type="down" colour="white" />
                        <PrimaryText colour="white" notResponsive>
                          {placeChange.replace('-', '')}
                        </PrimaryText>
                      </>
                    ) : placeChange != null && placeChange.includes('+') ? (
                      <>
                        <Icon className="h-4 w-4" type="up" colour="white" />
                        <PrimaryText colour="white" notResponsive>
                          {placeChange.replace('+', '')}
                        </PrimaryText>
                      </>
                    ) : (
                      <PrimaryText colour="white" notResponsive>
                        {placeChange}
                      </PrimaryText>
                    )}
                    <PrimaryText colour="white" notResponsive>
                      {placeChangeSuffix}
                    </PrimaryText>
                  </HorizontalGroup>
                </div>
              )}
            </div>
          </div>
        </VerticalGroup>
      </VerticalGroup>

      <HorizontalGroup
        className={classNames(
          `bg-${colour} h-4/5 w-full rounded-none rounded-b-lg`,
        )}
      >
        <VerticalGroup
          verticalCenter
          full
          center
          gap={2}
          className="w-full p-4"
        >
          <div
            id={index === 0 ? 'metric-score' : undefined}
            className="flex items-end"
            data-test="value"
          >
            <PrimaryText colour="white" size="5xl" weight="bold" notResponsive>
              {value}
            </PrimaryText>
            <PrimaryText colour="white" weight="bold" notResponsive>
              {valueSuffix}
            </PrimaryText>
          </div>
          <Clickable
            id={item.type === 'NPS' ? 'place-change-info' : undefined}
            onClick={handleMetricChangeClick}
          >
            <MetricChangePill
              id={index === 0 ? 'metric-score-change' : undefined}
              backgroundColour={colour}
              data-test="value-change"
            >
              {valueChange != null && valueChange.includes('-') ? (
                <>
                  <Icon className="h-4 w-4" type="down" colour="white" />
                  <PrimaryText colour="white" size="2xl" notResponsive>
                    {valueChange.replace('-', '')}
                  </PrimaryText>
                </>
              ) : valueChange != null && valueChange.includes('+') ? (
                <>
                  <Icon className="h-4 w-4" type="up" colour="white" />
                  <PrimaryText colour="white" size="2xl" notResponsive>
                    {valueChange.replace('+', '')}
                  </PrimaryText>
                </>
              ) : (
                <PrimaryText
                  paddingY={1}
                  colour="white"
                  size="lg"
                  notResponsive
                >
                  {valueChange}
                </PrimaryText>
              )}
            </MetricChangePill>
          </Clickable>
        </VerticalGroup>
      </HorizontalGroup>
      <Modal
        show={showModal}
        title={`What changed ${item.heading}?`}
        onClose={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        cancelButtonText="Close"
        preventDefault={true}
      >
        <div className="pt-4" style={{ width: 384 }}>
          <div>
            {showExplanation &&
            item.explanation &&
            item.explanation.length > 0 ? (
              item.explanation?.map((explanation, index) => (
                <div key={index}>
                  <BulletPoint>{explanation}</BulletPoint>
                </div>
              ))
            ) : (
              <div>There is no detailed breakdown for this metric</div>
            )}
          </div>
        </div>
      </Modal>
    </MetricCard>
  )
}

export default SummaryItem
