import React from 'react'
import { useAppSelector } from '../../../store'
import HorizontalGroup from '../HorizontalGroup/HorizontalGroup'
import PrimaryText from '../Text/PrimaryText'
import excelerateLogo from '../../../assets/logo.png'
import { Spacing } from '../../../types/theme'
import classNames from 'classnames'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  spinning?: boolean
  hideText?: boolean
  textColour?: string
  height?: Spacing
}

const Logo: React.FC<Props> = ({
  spinning,
  hideText,
  height,
  textColour,
  ...rest
}) => {
  const { appTitle, logo, insideAppText } = useAppSelector(state => ({
    appTitle: state.game.appTitle,
    logo: state.event.details ? state.event.details.eventLogo : state.game.logo,
    insideAppText: state.event.details
      ? state.event.details.insideAppText
      : null,
  }))

  const text = insideAppText || appTitle || 'Excelerate'

  return (
    <HorizontalGroup {...rest} gap={3} verticalCenter>
      <img
        className={classNames(
          { 'animate-spin-slow': spinning },
          height ? `h-${height}` : 'w-10',
        )}
        src={logo ?? excelerateLogo}
        alt={text}
      />
      {!hideText && (
        <PrimaryText
          colour="white"
          size="2xl"
          weight="bold"
          notResponsive
          style={{ color: textColour ?? undefined }}
        >
          {text}
        </PrimaryText>
      )}
    </HorizontalGroup>
  )
}

export default Logo
