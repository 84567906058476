import classNames from 'classnames'
import React from 'react'
import { FontSize } from '../../../types/theme'
import PrimaryText from '../Text/PrimaryText'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  text: string
  size?: FontSize
}

const LinkButton: React.FC<Props> = ({ className, text, size, ...rest }) => {
  return (
    <button {...rest} className={classNames(className)}>
      <PrimaryText colour="primary" notResponsive size={size ?? 'sm'} center>
        {text}
      </PrimaryText>
    </button>
  )
}

export default LinkButton
