import {
  TreasuryBusinessDecision,
  TreasuryCapitalBand,
  TreasuryMarginCalculation,
  TreasuryOpeningBalances,
  TreasuryRates,
  TreasuryResponse,
} from '../../../types/gameApi/treasury'
import { DecisionMap } from './TreasuryContainer'
import {
  ForecastDeductions,
  RiskWeightedAssets,
} from './TreasuryRiskWeightedAssets/types'

const calculateForecastBalance = (
  onlyAssets: boolean,
  openingVal: number,
  growth: number,
  risk: number,
) => {
  if (onlyAssets && risk <= 0) {
    return 0
  }
  return openingVal * (1 + growth / 10000)
}

export const calculateAutoWSF = ({
  depositsForecast,
  cashForecast,
  wsfMethod,
  totalAssetsForecast,
  capitalForecast,
  wholesaleFundingOpeningBalance,
  wholesaleFundingMovements,
}: {
  depositsForecast: number
  cashForecast: number
  wsfMethod: number
  totalAssetsForecast: number
  capitalForecast: number
  wholesaleFundingOpeningBalance: number
  wholesaleFundingMovements: number
}) => {
  const targetCashPercentage = 0.1
  const targetCash = depositsForecast * targetCashPercentage
  if (wsfMethod !== 3) {
    return { autoWsf1: 0, autoWsf2: 0, totalAutoWsf: 0 }
  }
  const autoWsf1 = Math.max(targetCash - cashForecast, 0)
  const autoWsf2 = Math.max(
    totalAssetsForecast -
      depositsForecast -
      (wholesaleFundingOpeningBalance + wholesaleFundingMovements) -
      capitalForecast,
    0,
  )
  return { autoWsf1, autoWsf2, totalAutoWsf: autoWsf1 + autoWsf2 }
}

interface ForecastValues {
  totalForecast: number
  homeLoansForecast: number
  businessLoansForecast: number
  creditCardsForecast: number
  institutionalBankingForecast: number
  offshoreBankForecast: number
  wealthForecast: number
  investmentsForecast: number
  fixedAssetsForecast: number
}

export const calculateForecastValues = (
  onlyAssets: boolean,
  openingBalances: TreasuryOpeningBalances,
  growthsAndRisks: DecisionMap,
): ForecastValues => {
  const {
    homeLoansGrowth,
    businessLoansGrowth,
    creditCardsGrowth,
    institutionalBankingGrowth,
    offshoreBankGrowth,
    wealthGrowth,
    investmentsGrowth,
    fixedAssetsGrowth,
  } = growthsAndRisks

  const homeLoansForecast = calculateForecastBalance(
    onlyAssets,
    openingBalances.homeLoans,
    homeLoansGrowth,
    growthsAndRisks.homeLoansRisk,
  )
  const businessLoansForecast = calculateForecastBalance(
    onlyAssets,
    openingBalances.businessLoans,
    businessLoansGrowth,
    growthsAndRisks.businessLoansRisk,
  )
  const creditCardsForecast = calculateForecastBalance(
    onlyAssets,
    openingBalances.creditCards,
    creditCardsGrowth,
    growthsAndRisks.creditCardsRisk,
  )
  const institutionalBankingForecast = calculateForecastBalance(
    onlyAssets,
    openingBalances.institutionalBanking,
    institutionalBankingGrowth,
    growthsAndRisks.institutionalBankingRisk,
  )
  const offshoreBankForecast = calculateForecastBalance(
    onlyAssets,
    openingBalances.offshoreBank,
    offshoreBankGrowth,
    growthsAndRisks.offshoreBankRisk,
  )
  const wealthForecast = calculateForecastBalance(
    onlyAssets,
    openingBalances.wealthAssets,
    wealthGrowth,
    growthsAndRisks.wealthRisk,
  )
  const investmentsForecast = calculateForecastBalance(
    onlyAssets,
    openingBalances.investments,
    investmentsGrowth,
    growthsAndRisks.investmentsRisk,
  )
  const fixedAssetsForecast = calculateForecastBalance(
    onlyAssets,
    openingBalances.fixedAssets,
    fixedAssetsGrowth,
    growthsAndRisks.fixedAssetsRisk,
  )
  const totalForecast =
    homeLoansForecast +
    businessLoansForecast +
    creditCardsForecast +
    institutionalBankingForecast +
    offshoreBankForecast +
    wealthForecast +
    investmentsForecast +
    fixedAssetsForecast

  return {
    totalForecast,
    homeLoansForecast,
    businessLoansForecast,
    creditCardsForecast,
    institutionalBankingForecast,
    offshoreBankForecast,
    wealthForecast,
    investmentsForecast,
    fixedAssetsForecast,
  }
}

export const calculateTotalOpeningBalance = (
  openingBalances: TreasuryOpeningBalances,
) => {
  return [
    openingBalances.homeLoans,
    openingBalances.businessLoans,
    openingBalances.creditCards,
    openingBalances.institutionalBanking,
    openingBalances.offshoreBank,
    openingBalances.wealthAssets,
    openingBalances.investments,
    openingBalances.fixedAssets,
  ].reduce((result, balance) => {
    result += balance
    return result
  }, 0)
}

interface Vals {
  openingBalanceName: keyof TreasuryOpeningBalances
  forecastValuesName: keyof ForecastValues
  growthName: keyof GrowthPerBusiness
}

interface GrowthPerBusiness {
  homeLoansGrowth: number
  businessLoansGrowth: number
  creditCardsGrowth: number
  institutionalBankingGrowth: number
  offshoreBankGrowth: number
  wealthGrowth: number
  investmentsGrowth: number
  fixedAssetsGrowth: number
}

export const calculateGrowthPerBusiness = (
  totalOpeningValue: number,
  totalGrowthPercentage: number,
  forecastValues: ForecastValues,
  openingBalances: TreasuryOpeningBalances,
): GrowthPerBusiness => {
  const vals: Vals[] = [
    {
      openingBalanceName: 'homeLoans',
      forecastValuesName: 'homeLoansForecast',
      growthName: 'homeLoansGrowth',
    },
    {
      openingBalanceName: 'businessLoans',
      forecastValuesName: 'businessLoansForecast',
      growthName: 'businessLoansGrowth',
    },
    {
      openingBalanceName: 'creditCards',
      forecastValuesName: 'creditCardsForecast',
      growthName: 'creditCardsGrowth',
    },
    {
      openingBalanceName: 'institutionalBanking',
      forecastValuesName: 'institutionalBankingForecast',
      growthName: 'institutionalBankingGrowth',
    },
    {
      openingBalanceName: 'offshoreBank',
      forecastValuesName: 'offshoreBankForecast',
      growthName: 'offshoreBankGrowth',
    },
    {
      openingBalanceName: 'wealthAssets',
      forecastValuesName: 'wealthForecast',
      growthName: 'wealthGrowth',
    },
    {
      openingBalanceName: 'investments',
      forecastValuesName: 'investmentsForecast',
      growthName: 'investmentsGrowth',
    },
    {
      openingBalanceName: 'fixedAssets',
      forecastValuesName: 'fixedAssetsForecast',
      growthName: 'fixedAssetsGrowth',
    },
  ]
  return vals.reduce<GrowthPerBusiness>(
    (result, { openingBalanceName, forecastValuesName, growthName }) => {
      const growthApportioned =
        (totalOpeningValue + totalGrowthPercentage) *
          (forecastValues[forecastValuesName] / forecastValues.totalForecast) -
        forecastValues[forecastValuesName]
      let growthPercentage = 0
      try {
        growthPercentage =
          (forecastValues[forecastValuesName] + growthApportioned) /
            openingBalances[openingBalanceName] -
          1
        growthPercentage =
          Math.floor(Number((growthPercentage * 100).toFixed(0)) * 100) || 0
      } catch (err) {
        /* empty */
      }
      result[growthName] = growthPercentage
      return result
    },
    {
      homeLoansGrowth: 0,
      businessLoansGrowth: 0,
      creditCardsGrowth: 0,
      institutionalBankingGrowth: 0,
      offshoreBankGrowth: 0,
      wealthGrowth: 0,
      investmentsGrowth: 0,
      fixedAssetsGrowth: 0,
    },
  )
}

export const calculateCapitalMovement = (
  totalRWA: number,
  totalCapitalDeductions: number,
  decisions: DecisionMap,
  openingBalances: TreasuryOpeningBalances,
) => {
  const rwaCapital = totalRWA * (decisions.capitalRatioChange / 10000)
  const totalShareHoldersFundsRequired =
    rwaCapital + Math.abs(totalCapitalDeductions)
  const capitalMovement =
    totalShareHoldersFundsRequired - openingBalances.shareholdersFunds
  return capitalMovement
}

const calculateRWA = (forecastBalance: number, riskWeight: number) => {
  if (riskWeight <= 0) {
    return 0
  }
  return forecastBalance * (riskWeight / 10000)
}

export const calculateRiskWeightedAssets = (
  forecasts: ForecastValues,
  decisions: DecisionMap,
): RiskWeightedAssets => {
  const homeLoansRiskWeightedAsset = calculateRWA(
    forecasts.homeLoansForecast,
    decisions.homeLoansRisk,
  )
  const businessLoansRiskWeightedAsset = calculateRWA(
    forecasts.businessLoansForecast,
    decisions.businessLoansRisk,
  )
  const creditCardsRiskWeightedAsset = calculateRWA(
    forecasts.creditCardsForecast,
    decisions.creditCardsRisk,
  )
  const institutionalBankingRiskWeightedAsset = calculateRWA(
    forecasts.institutionalBankingForecast,
    decisions.institutionalBankingRisk,
  )
  const offshoreBankRiskWeightedAsset = calculateRWA(
    forecasts.offshoreBankForecast,
    decisions.offshoreBankRisk,
  )
  const wealthRiskWeightedAsset = calculateRWA(
    forecasts.wealthForecast,
    decisions.wealthRisk,
  )
  const investmentsRiskWeightedAsset = calculateRWA(
    forecasts.investmentsForecast,
    decisions.investmentsRisk,
  )
  const fixedAssetsRiskWeightedAsset = calculateRWA(
    forecasts.fixedAssetsForecast,
    decisions.fixedAssetsRisk,
  )
  const totalCreditRiskWeightedAsset =
    homeLoansRiskWeightedAsset +
    businessLoansRiskWeightedAsset +
    creditCardsRiskWeightedAsset +
    institutionalBankingRiskWeightedAsset +
    offshoreBankRiskWeightedAsset +
    wealthRiskWeightedAsset +
    investmentsRiskWeightedAsset +
    fixedAssetsRiskWeightedAsset

  const operationalRiskWeightedAsset = calculateRWA(
    totalCreditRiskWeightedAsset,
    decisions.opexRisk,
  )

  const totalRiskWeightedAsset =
    totalCreditRiskWeightedAsset + operationalRiskWeightedAsset

  return {
    homeLoansRiskWeightedAsset,
    businessLoansRiskWeightedAsset,
    creditCardsRiskWeightedAsset,
    institutionalBankingRiskWeightedAsset,
    offshoreBankRiskWeightedAsset,
    wealthRiskWeightedAsset,
    investmentsRiskWeightedAsset,
    fixedAssetsRiskWeightedAsset,
    totalCreditRiskWeightedAsset,
    operationalRiskWeightedAsset,
    totalRiskWeightedAsset,
  }
}

const calculateDeduction = (openingVal: number, riskWeight: number) => {
  if (riskWeight !== -1) {
    return 0
  }
  return openingVal * -1
}

export const calculateForecastDeductions = (
  forecasts: ForecastValues,
  decisions: DecisionMap,
): ForecastDeductions => {
  const homeLoansForecastDeduction = calculateDeduction(
    forecasts.homeLoansForecast,
    decisions.homeLoansRisk,
  )
  const businessLoansForecastDeduction = calculateDeduction(
    forecasts.businessLoansForecast,
    decisions.businessLoansRisk,
  )
  const creditCardsForecastDeduction = calculateDeduction(
    forecasts.creditCardsForecast,
    decisions.creditCardsRisk,
  )
  const institutionalBankingForecastDeduction = calculateDeduction(
    forecasts.institutionalBankingForecast,
    decisions.institutionalBankingRisk,
  )
  const offshoreBankForecastDeduction = calculateDeduction(
    forecasts.offshoreBankForecast,
    decisions.offshoreBankRisk,
  )
  const wealthForecastDeduction = calculateDeduction(
    forecasts.wealthForecast,
    decisions.wealthRisk,
  )
  const investmentsForecastDeduction = calculateDeduction(
    forecasts.investmentsForecast,
    decisions.investmentsRisk,
  )
  const fixedAssetsForecastDeduction = calculateDeduction(
    forecasts.fixedAssetsForecast,
    decisions.fixedAssetsRisk,
  )
  const totalCapitalDeductions =
    homeLoansForecastDeduction +
    businessLoansForecastDeduction +
    creditCardsForecastDeduction +
    institutionalBankingForecastDeduction +
    offshoreBankForecastDeduction +
    wealthForecastDeduction +
    investmentsForecastDeduction +
    fixedAssetsForecastDeduction

  return {
    homeLoansForecastDeduction,
    businessLoansForecastDeduction,
    creditCardsForecastDeduction,
    institutionalBankingForecastDeduction,
    offshoreBankForecastDeduction,
    wealthForecastDeduction,
    investmentsForecastDeduction,
    fixedAssetsForecastDeduction,
    totalCapitalDeductions,
  }
}

const sum = (...args: number[]) => {
  return args.reduce((a, b) => a + b)
}

const average = (...args: number[]) => {
  return sum(...args) / args.length
}

export const calculateTreasuryMargins = ({
  nim,
  costOfFunds,
  decisions,
  businessDecisions,
  marginCalculation,
  rates,
  cashForecast,
  openingBalances,
  wholesaleFundingForecast,
  assetForecasts,
  savingsDepositsForecast,
  transDepositsForecast,
  totalAutoWsf,
}: {
  nim: number
  costOfFunds: number
  decisions: DecisionMap
  businessDecisions: TreasuryBusinessDecision[]
  marginCalculation: TreasuryMarginCalculation
  rates: TreasuryRates
  cashForecast: number
  openingBalances: TreasuryOpeningBalances
  wholesaleFundingForecast: number
  assetForecasts: ForecastValues
  savingsDepositsForecast: number
  transDepositsForecast: number
  totalAutoWsf: number
}) => {
  const playerDecisions = {
    homeLoans: businessDecisions.find(bd => bd.business_type === 'HOME_LOANS'),
    businessLoans: businessDecisions.find(
      bd => bd.business_type === 'BUSINESS_LOANS',
    ),
    deposits: businessDecisions.find(bd => bd.business_type === 'DEPOSITS'),
    institutionalBanking: businessDecisions.find(
      bd => bd.business_type === 'INSTITUTIONAL_BANKING',
    ),
  }

  const averageBalances = {
    cash: average(openingBalances.cash, cashForecast),
    homeLoans: average(
      openingBalances.homeLoans,
      assetForecasts.homeLoansForecast,
    ),
    businessLoans: average(
      openingBalances.businessLoans,
      assetForecasts.businessLoansForecast,
    ),
    creditCards: average(
      openingBalances.creditCards,
      assetForecasts.creditCardsForecast,
    ),
    savingsAccounts: average(
      openingBalances.savingAccounts,
      savingsDepositsForecast,
    ),
    transactionAccounts: average(
      openingBalances.transactionAccounts,
      transDepositsForecast,
    ),
    institutionalBanking: average(
      openingBalances.institutionalBanking,
      assetForecasts.institutionalBankingForecast,
    ),
  }

  const interestIncome = {
    cash: (averageBalances.cash * rates.cashYield) / 10000,
    homeLoans:
      averageBalances.homeLoans *
      (playerDecisions.homeLoans
        ? playerDecisions.homeLoans.price1_now / 10000
        : 0),
    businessLoans:
      averageBalances.businessLoans *
      (playerDecisions.businessLoans
        ? playerDecisions.businessLoans.price1_now / 10000
        : 0),
    institutionalBanking:
      averageBalances.institutionalBanking *
      (playerDecisions.institutionalBanking
        ? playerDecisions.institutionalBanking.price1_now / 10000
        : 0),
    creditCards:
      averageBalances.creditCards *
      (marginCalculation.creditCardInterestRate / 10000),
    total: 0,
  }

  interestIncome.total = sum(...Object.values(interestIncome))

  const interestExpense = {
    savingsAccounts:
      averageBalances.savingsAccounts *
      (playerDecisions.deposits
        ? playerDecisions.deposits.price1_now / 10000
        : 0),
    transactionAccounts: 0,
    wsfExistingSpreadAndReferenceRate: marginCalculation.existingFundingCosts,
    wsfNewReferenceRate:
      (sum(
        decisions.wsf1,
        decisions.wsf2,
        decisions.wsf3,
        decisions.wsf4,
        decisions.wsf5 || totalAutoWsf,
      ) *
        (rates.cashYield / 10000)) /
      2,
    wsfNewSpreadOnly:
      sum(
        (marginCalculation.wsfRateYr1 / 10000) * decisions.wsf1,
        (marginCalculation.wsfRateYr2 / 10000) * decisions.wsf2,
        (marginCalculation.wsfRateYr3 / 10000) * decisions.wsf3,
        (marginCalculation.wsfRateYr4 / 10000) * decisions.wsf4,
        (marginCalculation.wsfRateYr5 / 10000) *
          (decisions.wsf5 || totalAutoWsf),
      ) / 2,
    total: 0,
  }

  const wsfAverage = average(
    openingBalances.wholesaleFunding,
    wholesaleFundingForecast,
  )

  const interestExpenseWsfTotal = sum(
    interestExpense.wsfExistingSpreadAndReferenceRate,
    interestExpense.wsfNewReferenceRate,
    interestExpense.wsfNewSpreadOnly,
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const wsfRate = interestExpenseWsfTotal / wsfAverage

  interestExpense.total = sum(
    interestExpense.savingsAccounts,
    interestExpense.transactionAccounts,
    interestExpenseWsfTotal,
  )

  const forecastEirAverageBalanceTotal = sum(
    averageBalances.cash,
    averageBalances.homeLoans,
    averageBalances.businessLoans,
    averageBalances.creditCards,
    averageBalances.institutionalBanking,
  )

  const forecastEirAssetPercentage =
    (interestIncome.total / forecastEirAverageBalanceTotal) * 10000
  const forecastEirAssetPercentageChange =
    forecastEirAssetPercentage - (rates.assetYield || 0 / 10000)
  const forecastEirMargin =
    ((interestIncome.total - interestExpense.total) /
      forecastEirAverageBalanceTotal) *
    10000
  const forecastEirCostPercentage =
    (interestExpense.total / forecastEirAverageBalanceTotal) * 10000
  const forecastEirCostPercentageChange =
    forecastEirCostPercentage - costOfFunds
  const marginChange = forecastEirMargin - nim

  // console.log({ averageBalances, interestIncome, interestExpense })

  return {
    marginChange,
    forecastEirAssetPercentageChange,
    forecastEirAssetPercentage,
    forecastEirMargin,
    forecastEirCostPercentage,
    forecastEirCostPercentageChange,
  }
}

const getBand = (possibleBands: TreasuryCapitalBand[], value: number) => {
  for (let i = 0; i < possibleBands.length; i++) {
    const potentialBand = possibleBands[i]

    if (potentialBand.bandValue <= value) {
      return potentialBand.bandName
    }
  }
  return null
}

export const calculateCapitalRatioEstimate = ({
  openingBalances,
  targetShareCapitalMovement,
  totalRiskWeightedAsset,
  totalCapitalDeductions,
  capitalBands,
  decisions,
}: {
  openingBalances: TreasuryOpeningBalances
  targetShareCapitalMovement: number
  totalRiskWeightedAsset: number
  totalCapitalDeductions: number
  capitalBands: TreasuryCapitalBand[]
  decisions: DecisionMap
}) => {
  const retainedNpat =
    decisions.groupNpat < 0 ? decisions.groupNpat : decisions.groupNpat * 0.25
  const dividendsReinvestedValue =
    (decisions.groupNpat - retainedNpat) *
    (decisions.dividendsReinvested / 10000)
  const totalNpatRetained = retainedNpat + dividendsReinvestedValue
  const additionalShareIssues = targetShareCapitalMovement - totalNpatRetained

  const closingShareholdersFunds =
    openingBalances.shareholdersFunds +
    totalNpatRetained +
    additionalShareIssues
  const regulatoryDeductions = totalCapitalDeductions
  const netRegulatoryCapital = closingShareholdersFunds + regulatoryDeductions

  const capitalRatioEstimate =
    (netRegulatoryCapital / totalRiskWeightedAsset) * 10000
  const band = getBand(capitalBands, capitalRatioEstimate)
  return {
    retainedNpat,
    dividendsReinvestedValue,
    totalNpatRetained,
    additionalShareIssues,
    closingShareholdersFunds,
    regulatoryDeductions,
    netRegulatoryCapital,
    capitalRatioEstimate,
    band,
  }
}

export const calculateAssetsAndForecasts = ({
  data,
  decisions,
}: {
  data: TreasuryResponse
  decisions: DecisionMap
}) => {
  const {
    nim,
    costOfFunds,
    businessDecisions,
    marginCalculation,
    openingBalances,
    wsfMethod,
    rates,
  } = data

  // const openingDepositsBalance = openingBalances.savingAccounts + openingBalances.transactionAccounts
  // const showWholesaleFundingSlider = wsfMethod !== 3
  // const showCapitalRatioSlider = capitalMethod === 1
  // const showCreditRatingButtons = capitalMethod === 2
  const allOtherAssetsMovements =
    openingBalances.allOtherAssets *
    (decisions.allOtherAssetsPercentage / 100 / 100)
  const allOtherAssetsForecast = Math.floor(
    openingBalances.allOtherAssets + allOtherAssetsMovements,
  )

  const savingsDepositsMovement =
    openingBalances.savingAccounts *
    (decisions.depositGrowthChangePercentage / 100 / 100)
  const savingsDepositsForecast = Math.floor(
    openingBalances.savingAccounts + savingsDepositsMovement,
  )

  const transDepositsMovement =
    openingBalances.transactionAccounts *
    (decisions.depositGrowthChangePercentage / 100 / 100)
  const transDepositsForecast = Math.floor(
    openingBalances.transactionAccounts + transDepositsMovement,
  )

  const depositsMovement = savingsDepositsMovement + transDepositsMovement
  const depositsForecast = savingsDepositsForecast + transDepositsForecast

  const assetForecasts = calculateForecastValues(
    false,
    openingBalances,
    decisions,
  )

  const { totalRiskWeightedAsset } = calculateRiskWeightedAssets(
    assetForecasts,
    decisions,
  )

  const { totalCapitalDeductions } = calculateForecastDeductions(
    assetForecasts,
    decisions,
  )
  const targetShareCapitalMovement = calculateCapitalMovement(
    totalRiskWeightedAsset,
    -1 * totalCapitalDeductions,
    decisions,
    openingBalances,
  )

  const shareholdersFundsForecast =
    openingBalances.shareholdersFunds + targetShareCapitalMovement
  const cashForecast = Math.max(
    0,
    Math.floor(
      openingBalances.cash +
        depositsMovement +
        decisions.wsfChange +
        targetShareCapitalMovement -
        allOtherAssetsMovements,
    ),
  )
  const { totalAutoWsf } = calculateAutoWSF({
    depositsForecast,
    cashForecast,
    wsfMethod,
    totalAssetsForecast: allOtherAssetsForecast,
    capitalForecast: shareholdersFundsForecast,
    wholesaleFundingOpeningBalance: openingBalances.wholesaleFunding,
    wholesaleFundingMovements: decisions.wsfChange,
  })

  const cashForecastWithAutoWSF = Math.max(
    0,
    Math.floor(
      openingBalances.cash +
        depositsMovement +
        decisions.wsfChange +
        targetShareCapitalMovement +
        totalAutoWsf -
        allOtherAssetsMovements,
    ),
  )
  const wholesaleFundingForecast = Math.floor(
    openingBalances.wholesaleFunding + decisions.wsfChange + totalAutoWsf,
  )

  const {
    marginChange,
    forecastEirAssetPercentage,
    forecastEirAssetPercentageChange,
    forecastEirMargin,
    forecastEirCostPercentage,
    forecastEirCostPercentageChange,
  } = calculateTreasuryMargins({
    nim,
    costOfFunds,
    decisions,
    rates,
    marginCalculation,
    cashForecast: cashForecastWithAutoWSF,
    openingBalances,
    wholesaleFundingForecast,
    assetForecasts,
    savingsDepositsForecast,
    transDepositsForecast,
    businessDecisions,
    totalAutoWsf,
  })

  return {
    allOtherAssetsForecast,
    cashForecastWithAutoWSF,
    wholesaleFundingForecast,
    depositsForecast,
    shareholdersFundsForecast,
    forecastEirAssetPercentage,
    forecastEirAssetPercentageChange,
    forecastEirMargin,
    marginChange,
    forecastEirCostPercentage,
    forecastEirCostPercentageChange,
    targetShareCapitalMovement,
  }
}
