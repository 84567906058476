import React, { ReactNode } from 'react'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import PrimaryText from '../Text/PrimaryText'
import VerticalGroup from '../VerticalGroup/VerticalGroup'
import classNames from 'classnames'
import { Colour } from '../../../types/theme'
import { BACKGROUND_COLOUR_CLASSES } from '../../../lib/colourClasses'
export type MenuItem<T> = {
  label: string
  itemRow?: ReactNode
  value: T
  disabled?: boolean
}

type DropDownProps<T> = {
  menuItemsClassname?: string
  openUpwards?: boolean
  items: MenuItem<T>[]
  selectedItem: T
  onSelectedItemChange: (item: MenuItem<T>) => void
  bgColor?: Colour
  rounded?: boolean
  paddingLeft?: boolean
}

function DropDown<T>({
  menuItemsClassname,
  openUpwards = false,
  items,
  selectedItem,
  onSelectedItemChange,
  bgColor,
  rounded = false,
  paddingLeft = false,
}: DropDownProps<T>) {
  const handleItemClick = (item: MenuItem<T>) => {
    onSelectedItemChange(item)
  }

  const selected = items.find(i => i.value === selectedItem)
  const backgroundClass = bgColor ? BACKGROUND_COLOUR_CLASSES[bgColor] : ''

  return (
    <Menu
      as="div"
      className={classNames('relative inline-block text-left', {
        [backgroundClass]: backgroundClass,
        'rounded-full': rounded,
      })}
    >
      <div>
        <PrimaryText size="sm" weight="semibold" colour="black">
          <Menu.Button
            className={classNames(
              'inline-flex w-full justify-center gap-x-1.5 rounded-md bg-transparent py-2 pr-3',
              {
                'pl-4': paddingLeft,
              },
            )}
          >
            {!!selected && selected.label}
            <ChevronDownIcon className="-mr-1 h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </PrimaryText>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            menuItemsClassname,
            'absolute z-20 mt-2 max-h-80 overflow-y-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
            {
              'bottom-0 mb-12 origin-bottom-right': openUpwards,
              'left-0 origin-top-right': !openUpwards,
            },
          )}
        >
          <VerticalGroup>
            {items.map(item => (
              <Menu.Item key={item.value as string} disabled={item.disabled}>
                {({ active }) => (
                  <a
                    href="#"
                    onClick={() => handleItemClick(item)}
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'block w-full whitespace-nowrap px-4 py-2',
                    )}
                  >
                    <PrimaryText
                      colour={
                        item.disabled
                          ? 'gray'
                          : selected?.value === item.value
                          ? 'primary'
                          : 'black'
                      }
                      size="sm"
                    >
                      {item.itemRow ?? item.label}
                    </PrimaryText>
                  </a>
                )}
              </Menu.Item>
            ))}
          </VerticalGroup>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default DropDown
