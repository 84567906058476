import { DealroomResponse } from '../../../../../types/gameApi/dealroom'

export const data: DealroomResponse = {
  bid: 0,
  bidType: 1,
  id: 3,
  name: 'NeoBank',
  description:
    'A promising NeoBank has built up a good reputation in the market and made good progress in attracting transaction customers which provide EXCEPTIONAL MARGINS when lent out. Whilst the NeoBank has been building momentum, due to the current economic conditions it is unable to raise further funding to support its ongoing operating losses and the parent company has chosen to sell NeoBank as they also have cashflow problems. This business is growing fast but requires all of its profits to be reinvested so don’t expect any dividends for the next 10 years. Preliminary due diligence has given your transactional banking team a high level of confidence that NeoBank’s platform and brand would provide a GREAT BRAND for attracting transaction customers, however it has also highlighted some cultural differences and a lack of maturity in robust internal processes.  Assess the financials and discuss:',
  type: 'neobank',
  imageUrl:
    'https://test-images.banksim.co/deals/deal-bab919d23c3f5c9db64d88f641c29d30.jpg',
  tsr: '11.98',
  argx: null,
  argy: null,
  assumptionOverrides: [],
  enabled: null,
}
