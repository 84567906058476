import React, { useCallback, useState } from 'react'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import Text from '../../../atoms/Text/Text'
import { Icon } from '../../../atoms/Icon/Icon'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../store'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import { startTour } from '../../../../redux/guidedTours/guidedToursSlice'
import SubheadingDropDown from '../Components/SubheadingDropDown'
import { useMenuTranslations } from '../../../../hooks/useMenuTranslations'
import Button from '../../../atoms/Button/Button'

interface Props {}

const SpecialProjectsHelp: React.FC<Props> = () => {
  const [expandedSubheading, setExpandedSubheading] = useState('')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const translations = useMenuTranslations()
  const { rounds } = useAppSelector(state => ({
    rounds: state.game.totalRounds,
  }))

  const handleSpecialProjectsHelpTour = useCallback(() => {
    dispatch(startTour('special-projects'))
    navigate('/special-projects')
  }, [dispatch, navigate])

  return (
    <VerticalGroup full gap={2}>
      <Text center size="lg">
        {translations.specialProjects} Guide
      </Text>
      <VerticalGroup gap={4} className="flex-grow overflow-y-auto">
        <HorizontalGroup center>
          <Button
            buttonType="secondary"
            full
            size="xl"
            onClick={handleSpecialProjectsHelpTour}
          >
            <Icon type="guide" colour="primary" size={4} />
            <Text colour="primary">Start Walkthrough</Text>
          </Button>
        </HorizontalGroup>
        <VerticalGroup center gap={4}>
          <SubheadingDropDown
            title={'How Many Projects Can We Choose?'}
            content={`Each round, your bank can start one new project. With ${rounds} rounds in the game, you can choose up to ${rounds} projects in total. Remember, you can only initiate one project per round.`}
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
          <SubheadingDropDown
            title={'Which Project Should We Select?'}
            content={`Consider your bank's strategy and the ${translations.summary} metrics that determine the winning team (indicated by trophy icons on the ${translations.summary} screen). Select projects that have the most benefit on these metrics and will deliver your strategy.`}
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
          <SubheadingDropDown
            title={'When Do We Get The Benefits?'}
            content={
              "The benefits from projects are spread over their duration. For example, a two-year project might yield half its benefits in the first year and the rest upon completion. Some projects may even continue to provide further benefits even after they end. Check each project's description for more insights."
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
          <SubheadingDropDown
            title={'What Are Ongoing Costs?'}
            content={
              'After a project is completed, some may incur ongoing costs. These costs could be for additional staff, maintenance, or other operational expenses. They will commence in the year post project completion.'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
          <Text left size="sm" className="bg-orange-100 p-2">
            {`Tip: Early in the simulation, collaborate as a team to make a short-list of the top ${rounds} projects. Plan the investment order of these projects to ensure they all complete by the end of final round. To do this pay attention to each project's duration. `}
          </Text>
        </VerticalGroup>
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default SpecialProjectsHelp
