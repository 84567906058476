import React, { FC, useCallback, HTMLAttributes, useState } from 'react'
// import Clickable from '../../atoms/Clickable/Clickable'
// import { Icon } from '../../atoms/Icon/Icon'
// import Input from '../../atoms/Input/Input'
// import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
// import ErrorModal from '../../atoms/Modal/ErrorModal'
// import NumericInput from '../../atoms/Input/NumericInput'
import Modal from '../../atoms/Modal/Modal'
import NumericInput from '../../atoms/Input/NumericInput'
import InlineError from '../../atoms/InlineError/InlineError'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import SecondaryText from '../../atoms/Text/SecondaryText'
import ValueChangeText from '../ValueChangeText/ValueChangeText'
import ValueChangerButtons, { ButtonSizes } from './ValueChangerButtons'

export type ValueChangeInputType =
  | 'percentage'
  | 'dollar'
  | 'integer'
  | 'decimal'

export type Props = {
  type: ValueChangeInputType
  previousValue?: number
  value: number
  // formatValue: (value: number) => string
  // onDecrement: (value: number) => void
  // onIncrement: (value: number) => void
  onChange: (value: number) => void
  min?: number
  max?: number
  step?: number
  enableChanges: boolean
  formatValue: (value: number) => string
  onClose: () => void
  heading: string
  validateInput: (input: number) => string | undefined
  increments?: number[]
  incrementFormatter?: (val: number) => string
  extraData?: any
  buttonSize?: ButtonSizes
} & Omit<HTMLAttributes<HTMLDivElement>, 'onChange'>

const ValueChangerModal: FC<Props> = ({
  heading,
  onClose,
  type,
  value,
  previousValue,
  // formatValue,
  // onDecrement,
  // onIncrement,
  onChange,
  formatValue,
  min,
  max,
  step,
  enableChanges,
  validateInput,
  increments,
  incrementFormatter,
  buttonSize,
  ...rest
}) => {
  const [error, setError] = useState<string | null>()
  const [valueToUse, setValueToUse] = useState(value)

  const onValueChange = useCallback((val: number) => {
    setValueToUse(val)
  }, [])

  const onSave = useCallback(() => {
    onChange(valueToUse)
    onClose()
  }, [onChange, onClose, valueToUse])

  let changeUnit = ''
  if (type === 'percentage' || type === 'decimal') {
    changeUnit = '%'
  }

  return (
    <Modal
      show
      title={heading}
      titlePosition="center"
      onClose={onClose}
      onOk={onSave}
      okButtonText="Save"
      buttonPosition="center"
    >
      <VerticalGroup
        data-test="value-changer-modal"
        {...rest}
        gap={4}
        fullWidth
        center
        className="w-96 py-4 px-16"
      >
        {error && <InlineError message={error} />}
        {previousValue != null && (
          <VerticalGroup gap={1} center>
            <SecondaryText>
              Previously: {formatValue(previousValue)}
            </SecondaryText>
            <ValueChangeText
              family="secondary"
              value={valueToUse - previousValue}
              textColour="primary"
              changeUnit={changeUnit}
              prefix={type === 'dollar' ? '$' : undefined}
              removeArrow
              removePercentage={type === 'decimal'}
            />
          </VerticalGroup>
        )}
        <ValueChangerButtons
          value={valueToUse}
          onChange={onValueChange}
          min={min}
          max={max}
          step={step}
          enableChanges={enableChanges}
          increments={increments ?? [1, 10]}
          incrementFormatter={incrementFormatter}
          buttonSize={buttonSize ?? 'medium'}
        >
          <div className="w-24">
            <NumericInput
              className="h-10 laptop:flex laptop:text-base"
              type={type}
              value={valueToUse}
              onChange={onValueChange}
              onError={setError}
              validateInput={validateInput}
              submitTimeout={5000}
              // propogateOnChange
            />
          </div>
        </ValueChangerButtons>
      </VerticalGroup>
    </Modal>
  )
}

export default ValueChangerModal
