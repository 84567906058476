import React from 'react'
import Text from '../Text/Text'
import HorizontalGroup from '../HorizontalGroup/HorizontalGroup'
import { Colour } from '../../../types/theme'

const BULLET_COLOUR_CLASSES: Record<Colour, string> = {
  primary: 'bg-primary',
  orange: 'bg-orange',
  pink: 'bg-pink',
  green: 'bg-green',
  purple: 'bg-purple',
  darkblue: 'bg-darkblue',
  gray: 'bg-gray',
  white: 'bg-white',
  black: 'bg-black',
  lightgray: 'bg-lightgray',
  success: 'bg-success',
  error: 'bg-error',
}

interface BulletPointProps {
  children: React.ReactNode
  colour?: Colour
}

const BulletPoint: React.FC<BulletPointProps> = ({
  children,
  colour = 'primary',
}) => {
  const bulletColourClass = BULLET_COLOUR_CLASSES[colour]

  return (
    <HorizontalGroup gap={2}>
      <div
        className={`mt-1.5 h-2.5 w-2.5 flex-shrink-0 rounded-full ${bulletColourClass}`}
      />
      <Text left>{children}</Text>
    </HorizontalGroup>
  )
}

export default BulletPoint
