import { createAsyncThunk } from '@reduxjs/toolkit'
import gameAPI from '../../services/gameApi'
import APIError from '../../errors/APIError'
import {
  SubmitRoundRequestParams,
  SubmitRoundResponse,
} from '../../types/gameApi/team'

export const submitRound = createAsyncThunk<
  SubmitRoundResponse,
  SubmitRoundRequestParams,
  { rejectValue: Error | APIError }
>('game/submit', async ({ teamId, roundId }, { rejectWithValue }) => {
  try {
    const response = await gameAPI.submitRound({ teamId, roundId })

    return response
  } catch (error: any) {
    return rejectWithValue(error)
  }
})
