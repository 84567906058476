import React, { useState } from 'react'
import {
  AssetOrDeductionItem,
  ForecastDeductions,
  RiskWeightedAssets,
  TreasuryRiskWeightedAssetsTranslations,
} from './types'
import { DecisionMap } from '../TreasuryContainer'
import Card from '../../../atoms/Card/Card'
import { formatValue, percentage } from '../../../../lib/formatters'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import PrimaryText from '../../../atoms/Text/PrimaryText'
import ValueChangerWithInput from '../../../molecules/ValueChanger/ValueChangerWithInput'
import TranslatedText from '../../../atoms/TranslatedText/TranslatedText'
import Tab from '../../../atoms/Tabs/Tab'
import Button from '../../../atoms/Button/Button'
import { Icon } from '../../../atoms/Icon/Icon'

import { TreasuryResponse } from '../../../../types/gameApi/treasury'

interface Props {
  data: TreasuryResponse
  decisions: DecisionMap
  translations: TreasuryRiskWeightedAssetsTranslations
  riskWeightedAssets: RiskWeightedAssets
  forecastDeductions: ForecastDeductions
  assets: AssetOrDeductionItem[]
  deductions: AssetOrDeductionItem[]
  enableChanges: boolean
  onGrowthChange: (params: {
    growthField: keyof DecisionMap
    value: number
  }) => void
  onChangeValue: (val: Partial<DecisionMap>) => void
  handleOpenResetModal: () => void
}

interface CardProps {
  item: AssetOrDeductionItem
  translations: TreasuryRiskWeightedAssetsTranslations
  enableChanges: boolean
  onGrowthChange: (params: {
    growthField: keyof DecisionMap
    value: number
  }) => void
  onChangeValue: (val: Partial<DecisionMap>) => void
}

const AssetDeductionCard: React.FC<CardProps> = ({
  item,
  translations,
  enableChanges,
  onChangeValue,
  onGrowthChange,
}) => {
  return (
    <Card className="p-4">
      <VerticalGroup center gap={2}>
        <PrimaryText center size="sm" weight="bold">
          {item.name}
        </PrimaryText>

        <HorizontalGroup around fullWidth className=" ">
          <VerticalGroup className="w-1/3 px-4" center>
            <PrimaryText
              colour="gray"
              className="text-center"
              size="xs"
              weight="normal"
              noLeading
            >
              {translations.assets.currentBalance}
            </PrimaryText>
            <PrimaryText size="lg">
              {formatValue(item.currentBalance)}
            </PrimaryText>
          </VerticalGroup>
          <VerticalGroup className="w-1/3 px-4" center>
            <PrimaryText
              colour="gray"
              className="text-center"
              size="xs"
              weight="normal"
              noLeading
            >
              {translations.assets.forecastBalance}
            </PrimaryText>
            <PrimaryText size="lg">
              {formatValue(Math.floor(item.forecastBalance))}
            </PrimaryText>
          </VerticalGroup>
          <VerticalGroup className="w-1/3" center>
            <PrimaryText
              colour="gray"
              className="text-center"
              size="xs"
              weight="normal"
              noLeading
            >
              {translations.assets.riskWeightedAssets}*{' '}
            </PrimaryText>
            <PrimaryText size="lg">
              {formatValue(Math.floor(item.total))}
            </PrimaryText>
          </VerticalGroup>
        </HorizontalGroup>
        <HorizontalGroup fullWidth around className="">
          <VerticalGroup center>
            <PrimaryText size="xs" weight="normal" colour="gray">
              {translations.assets.growth}:
            </PrimaryText>
            <ValueChangerWithInput
              modalProps={{
                heading: 'GROWTH %',
              }}
              type="percentage"
              enableChanges={enableChanges}
              value={item.growth}
              formatValue={percentage}
              onChange={(val: number) => {
                onGrowthChange({ growthField: item.growthField, value: val })
              }}
              increments={[1, 5]}
              step={100}
            />
          </VerticalGroup>

          {item.riskWeight !== undefined && (
            <VerticalGroup center>
              <PrimaryText size="xs" weight="normal" colour="gray">
                {translations.assets.riskWeight}:
              </PrimaryText>
              <ValueChangerWithInput
                modalProps={{
                  heading: 'RISK WEIGHT %',
                }}
                type="percentage"
                value={item.riskWeight}
                formatValue={percentage}
                onChange={value => {
                  onChangeValue({ [item.riskField]: value })
                }}
                enableChanges={enableChanges}
                increments={[1, 5]}
                step={100}
                previousValue={item.riskFieldOriginal}
                min={0}
                max={10000}
              />
            </VerticalGroup>
          )}
        </HorizontalGroup>
      </VerticalGroup>
    </Card>
  )
}

const TreasuryRiskWeightedAssetsCards: React.FC<Props> = ({
  assets,
  deductions,
  translations,
  riskWeightedAssets,
  decisions,
  forecastDeductions,
  enableChanges,
  onChangeValue,
  onGrowthChange,
  handleOpenResetModal,
}) => {
  const opexRisk = decisions.opexRisk
  const [selectedTab, setSelectedTab] = useState<
    'RISK_WEIGHTED_ASSETS' | 'CAPITAL_DEDUCTIONS'
  >('RISK_WEIGHTED_ASSETS')
  return (
    <VerticalGroup gap={2}>
      <HorizontalGroup between>
        <Tab
          tab={{ value: 'RISK_WEIGHTED_ASSETS', label: 'RISK WEIGHTED ASSETS' }}
          active={selectedTab === 'RISK_WEIGHTED_ASSETS'}
          onClick={setSelectedTab}
        />
        <Tab
          tab={{ value: 'CAPITAL_DEDUCTIONS', label: 'CAPITAL DEDUCTIONS' }}
          active={selectedTab === 'CAPITAL_DEDUCTIONS'}
          onClick={setSelectedTab}
        />
      </HorizontalGroup>
      <HorizontalGroup fullWidth className="justify-end">
        <Button
          onClick={handleOpenResetModal}
          rounded="full"
          size="xs"
          buttonType="bordered"
        >
          <HorizontalGroup verticalCenter gap={2} className="row-reverse flex">
            <Icon type="reset" colour="black" />
            <PrimaryText size="sm" colour="black">
              Reset risk weights
            </PrimaryText>
          </HorizontalGroup>
        </Button>
      </HorizontalGroup>
      {selectedTab === 'RISK_WEIGHTED_ASSETS' && (
        <>
          {assets.map((asset, i) => (
            <AssetDeductionCard
              key={i}
              item={asset}
              translations={translations}
              enableChanges={enableChanges}
              onChangeValue={onChangeValue}
              onGrowthChange={onGrowthChange}
            />
          ))}
          <Card className="p-4">
            <HorizontalGroup verticalCenter between>
              <TranslatedText
                weight="bold"
                size="sm"
                whitespace="wrap"
                value="treasury.screen3.table1Total1Label"
                default="Total Credit Risk Weighted Assets"
                suffix=" *"
              />
              <PrimaryText size="sm" center>
                {formatValue(
                  Math.floor(riskWeightedAssets.totalCreditRiskWeightedAsset),
                )}
              </PrimaryText>
            </HorizontalGroup>
          </Card>
          <Card className="p-4">
            <VerticalGroup center className="text-center" gap={2}>
              <PrimaryText center weight="bold">
                <TranslatedText
                  size="sm"
                  className="w-48 leading-none"
                  value="treasury.screen3.table1Total2Label"
                  default="OPERATIONAL RISK (AS % OF TOTAL CREDIT RISK RWA)"
                  suffix=" *"
                />
              </PrimaryText>
              <VerticalGroup gap={2} center>
                <VerticalGroup className="text-center" center>
                  <PrimaryText
                    center
                    className="w-28 leading-none"
                    whitespace="wrap"
                    size="xs"
                    colour="gray"
                  >
                    {translations.assets.riskWeightedAssets}*
                  </PrimaryText>
                  <PrimaryText size="lg">
                    {formatValue(
                      Math.floor(
                        riskWeightedAssets.operationalRiskWeightedAsset,
                      ),
                    )}
                  </PrimaryText>
                  <PrimaryText
                    className="text-center"
                    size="xs"
                    weight="normal"
                  ></PrimaryText>
                </VerticalGroup>
                <VerticalGroup center>
                  <PrimaryText size="xs" weight="normal" colour="gray">
                    {translations.assets.riskWeight}:
                  </PrimaryText>

                  <ValueChangerWithInput
                    modalProps={{
                      heading: 'RISK WEIGHT %',
                    }}
                    type="percentage"
                    value={opexRisk}
                    previousValue={0}
                    formatValue={percentage}
                    onChange={value => {
                      onChangeValue({ opexRisk: value })
                    }}
                    enableChanges={enableChanges}
                    increments={[1, 5]}
                    step={100}
                    min={0}
                    max={10000}
                  />
                </VerticalGroup>
              </VerticalGroup>
            </VerticalGroup>
          </Card>
          <Card className="p-4">
            <HorizontalGroup verticalCenter between>
              <TranslatedText
                colour="primary"
                size="sm"
                weight="bold"
                value="treasury.screen3.table1Total3Label"
                default="TOTAL RISK WEIGHTED ASSETS (ESTIMATED)"
                suffix="*"
              />

              <PrimaryText size="sm" center weight="bold" colour="primary">
                {formatValue(
                  Math.floor(riskWeightedAssets.totalRiskWeightedAsset),
                )}
              </PrimaryText>
            </HorizontalGroup>
          </Card>
        </>
      )}
      {selectedTab === 'CAPITAL_DEDUCTIONS' && (
        <>
          <VerticalGroup gap={2}>
            {deductions.map((deduction, i) => (
              <AssetDeductionCard
                key={i}
                item={deduction}
                translations={translations}
                enableChanges={enableChanges}
                onChangeValue={onChangeValue}
                onGrowthChange={onGrowthChange}
              />
            ))}
            <Card className="p-4">
              <HorizontalGroup between>
                <TranslatedText
                  size="sm"
                  colour="primary"
                  weight="bold"
                  value="treasury.screen3.table2Total1Label"
                  default="TOTAL CAPITAL DEDUCTIONS"
                  suffix="*"
                />

                <PrimaryText weight="bold" colour="primary">
                  {formatValue(
                    Math.floor(forecastDeductions.totalCapitalDeductions),
                  )}
                </PrimaryText>
              </HorizontalGroup>
            </Card>
          </VerticalGroup>
        </>
      )}

      <TranslatedText
        size="sm"
        colour="gray"
        value="treasury.screen3.table2FootNote"
        default="indicates estimates only which are subject to actual performance"
        prefix="* "
      />
    </VerticalGroup>
  )
}

export default TreasuryRiskWeightedAssetsCards
